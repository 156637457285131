import { Card, Button } from "react-bootstrap";

const BrandCardShimmer = () => {
  const shimmerStyle = {
    background:
      "linear-gradient(to right, #f6f6f6 0%, #eaeaea 50%, #f6f6f6 100%)",
    backgroundSize: "200px 100%",
    animation: "shimmer 1.5s infinite linear",
  };

  return (
    <Card
      className="card-primary-hover d-flex p-4 flex-row text-start my-4 border w-100 position-relative"
      style={{ boxShadow: "none" }}
    >
      {/* Image Placeholder */}
      <Card.Img
        className="bg-white w-50 p-md-5"
        variant="top"
        style={{
          ...shimmerStyle,
          height: "200px",
          objectFit: "contain",
          maxWidth: "300px",
        }}
      />

      {/* Action Buttons Placeholder */}
      <div
        className="d-flex position-absolute"
        style={{ zIndex: 999, right: 15, top: 10 }}
      >
        <Button
          className="d-flex justify-content-center align-items-center share-like-btn p-0"
          style={{
            ...shimmerStyle,
            width: "30px",
            height: "30px",
            border: "none",
          }}
        />
        <Button
          className="d-flex justify-content-center align-items-center share-like-btn ms-2 p-0"
          style={{
            ...shimmerStyle,
            width: "30px",
            height: "30px",
            border: "none",
          }}
        />
      </div>

      {/* Content Placeholder */}
      <Card.Body className="w-100">
        <Card.Title
          className="h4"
          style={{
            ...shimmerStyle,
            width: "80%",
            height: "20px",
            borderRadius: "4px",
          }}
        ></Card.Title>

        <div
          className="body-text2"
          style={{
            ...shimmerStyle,
            width: "60%",
            height: "16px",
            marginBottom: "5px",
            borderRadius: "4px",
          }}
        ></div>
        <div
          className="body-text2"
          style={{
            ...shimmerStyle,
            width: "50%",
            height: "16px",
            marginBottom: "5px",
            borderRadius: "4px",
          }}
        ></div>
        <div
          className="body-text2"
          style={{
            ...shimmerStyle,
            width: "40%",
            height: "16px",
            borderRadius: "4px",
          }}
        ></div>
      </Card.Body>
    </Card>
  );
};

export default BrandCardShimmer;
