import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FiDownload } from 'react-icons/fi';
import { notify } from 'utilities/notifications/Notify';
import axios from 'axios';

const ExcelTemplateGenerator = ({ 
  brandInfo, 
  productCategory, 
  productType, 
  productSubType,
  productCategories,
  productTypes,
  productSubTypes
}) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const handleDownloadTemplate = async () => {
    try {
      // Only proceed if we have at least a category
      if (!productCategory) {
        notify("Please select a Product Category first", "warning");
        return;
      }

      setIsGenerating(true);
      notify("Generating template...", "info");
      
      // Construct query parameters
      const params = {};
      if (productCategory) params.product_category = productCategory;
      if (productType) params.product_type = productType;
      if (productSubType) params.product_sub_type = productSubType;
      if (brandInfo?.id) params.brand = brandInfo.id;
      
      // Generate a nice filename based on selected options
      let filename = 'product_template';
      
      if (productSubType && productSubTypes) {
        const subTypeName = productSubTypes.find(st => st.id === productSubType)?.title;
        if (subTypeName) {
          filename += `_${subTypeName.replace(/\s+/g, '_').toLowerCase()}`;
        }
      } else if (productType && productTypes) {
        const typeName = productTypes.find(t => t.id === productType)?.title;
        if (typeName) {
          filename += `_${typeName.replace(/\s+/g, '_').toLowerCase()}`;
        }
      } else if (productCategory && productCategories) {
        const categoryName = productCategories.find(c => c.id === productCategory)?.title;
        if (categoryName) {
          filename += `_${categoryName.replace(/\s+/g, '_').toLowerCase()}`;
        }
      }
      
      // Make API request with responseType: 'blob' to handle binary file download
      const response = await axios({
        url: '/product/products/generate_template/',
        method: 'GET',
        params: params,
        responseType: 'blob', // Important for handling file downloads
      });
      
      // Create a blob URL from the response data
      const blob = new Blob([response.data], { 
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
      });
      const url = window.URL.createObjectURL(blob);
      
      // Create temporary link and trigger download
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${filename}.xlsx`;
      
      // Add to DOM, click, and remove
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      
      // Small delay before cleanup
      await new Promise(resolve => setTimeout(resolve, 300));
      document.body.removeChild(a);
      
      notify("Template downloaded successfully!", "success");
    } catch (error) {
      console.error('Error downloading template:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notify(`Error: ${error.response.status} - ${error.response.statusText}`, "error");
      } else if (error.request) {
        // The request was made but no response was received
        notify("Server not responding. Please try again later.", "error");
      } else {
        // Something happened in setting up the request that triggered an Error
        notify("An error occurred while generating the template", "error");
      }
    } finally {
      setIsGenerating(false);
    }
  };

  // Get display names for selected values (for info message)
  const getCategoryName = () => {
    if (!productCategory || !productCategories) return '';
    const category = productCategories.find(c => c.id === productCategory);
    return category?.title || '';
  };

  const getTypeName = () => {
    if (!productType || !productTypes) return '';
    const type = productTypes.find(t => t.id === productType);
    return type?.title || '';
  };

  const getSubTypeName = () => {
    if (!productSubType || !productSubTypes) return '';
    const subType = productSubTypes.find(st => st.id === productSubType);
    return subType?.title || '';
  };

  // Generate info message based on selections
  const getInfoMessage = () => {
    if (productSubType) {
      return `Template will include all fields for ${getSubTypeName()}`;
    } else if (productType) {
      return `Select a product sub-type for a more specific template`;
    } else if (productCategory) {
      return `Select a product type and sub-type for a more specific template`;
    } else {
      return `Select a product category to generate a template`;
    }
  };

  return (
    <div className="mb-3">
      <Button
        onClick={handleDownloadTemplate}
        className="btn-sm d-flex align-items-center mb-2"
        variant="outline-primary"
        disabled={!productCategory || isGenerating}
      >
        {isGenerating ? (
          <>
            <Spinner animation="border" size="sm" className="me-2" />
            Generating Template...
          </>
        ) : (
          <>
            <FiDownload className="me-1" />
            Download Template
          </>
        )}
      </Button>
      <div className="small text-muted">
        {getInfoMessage()}
      </div>
    </div>
  );
};

export default ExcelTemplateGenerator;