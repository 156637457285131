import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Button, Container, Spinner, Form } from "react-bootstrap";
import * as authActions from "reduxFiles/actions/authActions";

const AddBrandsModelForEditProject = ({
  show,
  setShow,
  toggleBrandSelection,
  selectedBrands,
  setDisplaySelectedBrands,
  setAllBrands,
}) => {
  const [brandCategories, setBrandCategories] = useState([]);
  const [brandsByCategory, setBrandsByCategory] = useState({});
  const [loading, setLoading] = useState(false);
  // sortBy values: "" (default), "asc", "desc"
  const [sortBy, setSortBy] = useState("");
  const [brandCategory, setBrandCategory] = useState("");
  const [brandSlug, setBrandSlug] = useState("");

  const fetchBrandCategories = async () => {
    try {
      const response = await authActions.fetchBrandCategoriesNoPagination();
      if (response?.data) {
        setBrandCategories(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Fetch Brands for all Categories
  const fetchAllBrands = async () => {
    setLoading(true);
    try {
      const brandData = await Promise.all(
        brandCategories.map(async (category) => {
          const response = await axios.get(
            `/user/rest/brand-info/?brand_category=${category.id}`
          );
          return {
            categoryId: category.id,
            brands: response.data?.results || [],
          };
        })
      );

      const brandsMap = brandData.reduce((acc, item) => {
        setAllBrands((prev) => [...prev, ...item.brands]);
        acc[item.categoryId] = item.brands;
        return acc;
      }, {});

      setBrandsByCategory(brandsMap);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBrandCategories();
  }, []);

  useEffect(() => {
    if (brandCategories.length > 0) {
      fetchAllBrands();
    }
  }, [brandCategories]);

  // Prepare the list of categories to display.
  // If a specific category is selected, only that one is shown.
  let displayCategories = brandCategory
    ? brandCategories.filter((cat) => cat.id === brandCategory)
    : [...brandCategories];

  // If sorting is active, sort the outer list (by category name)
  if (sortBy === "asc" || sortBy === "desc") {
    displayCategories.sort((a, b) =>
      sortBy === "asc"
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name)
    );
  }

  return (
    <Modal show={show} fullscreen onHide={() => setShow(false)}>
      <Container fluid className="p-4">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <Modal.Title>Select Brands Used</Modal.Title>
            <Button variant="primary" onClick={() => setShow(false)}>
              Save and Next
            </Button>
          </div>

          {loading && <Spinner animation="border" />}

          <div>
            <p>Brand Selected: {selectedBrands.length}</p>
          </div>

          <div className="d-flex flex-wrap gap-3 mb-4">
            <Form.Select
              style={{
                maxWidth: "180px",
                border: "1px solid #dee2e6",
                borderRadius: "0.25rem",
                padding: "0.375rem 2rem 0.375rem 0.75rem",
                fontSize: "0.9rem",
                backgroundColor: "white",
              }}
              onChange={(e) => setBrandCategory(e.target.value)}
            >
              <option value="">Product Category</option>
              {brandCategories?.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Select>

            <Form.Control
              type="text"
              placeholder="Search by brand name."
              style={{
                maxWidth: "400px",
                flex: "1",
                border: "1px solid #dee2e6",
                borderRadius: "0.25rem",
                padding: "0.375rem 0.75rem 0.375rem 2.25rem",
                fontSize: "0.9rem",
                backgroundImage:
                  'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23888" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>\')',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "10px center",
                backgroundSize: "16px",
              }}
              onChange={(e) => setBrandSlug(e.target.value)}
            />

            <div
              className="d-flex align-items-center"
              style={{ border: "1px solid #dee2e6", borderRadius: "0.25rem" }}
            >
              <span style={{ padding: "0.375rem 0.5rem", fontSize: "0.9rem" }}>
                Sort By
              </span>
              <Form.Select
                style={{
                  border: "none",
                  width: "auto",
                  paddingLeft: "0.25rem",
                  boxShadow: "none",
                  fontSize: "0.9rem",
                  backgroundPosition: "right 0.5rem center",
                }}
                onChange={(e) => setSortBy(e.target.value)}
              >
                <option value="">Default</option>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </Form.Select>
            </div>
          </div>

          <div
            style={{
              maxHeight: "70vh",
              overflowY: "auto",
              paddingRight: "10px",
            }}
          >
            {displayCategories.map((category) => {
              // Filter brands based on the search query (brandSlug)
              const filteredBrands = (
                brandsByCategory[category.id] || []
              ).filter(
                (brand) =>
                  !brandSlug ||
                  brand.slug.toLowerCase().includes(brandSlug.toLowerCase())
              );
              // If sorting is active, sort the brands (by slug)
              const sortedBrands =
                sortBy === "asc" || sortBy === "desc"
                  ? [...filteredBrands].sort((a, b) =>
                      sortBy === "asc"
                        ? a.slug.localeCompare(b.slug)
                        : b.slug.localeCompare(a.slug)
                    )
                  : filteredBrands;

              return (
                <div key={category.id} className="my-3">
                  <h5>{category.name}</h5>
                  <div className="d-flex flex-wrap">
                    {sortedBrands.length > 0 ? (
                      sortedBrands.map((brand) => (
                        <div
                          key={brand.id}
                          style={{
                            cursor: "pointer",
                            border: selectedBrands.includes(brand.id)
                              ? "1px solid #c53d3d"
                              : "1px solid #dee2e6",
                            borderRadius: "5px",
                            margin: "1rem",
                            transition: "border 0.2s ease-in-out",
                          }}
                          onClick={() => toggleBrandSelection(brand.id)}
                        >
                          <div className="p-2 border rounded m-2">
                            <img
                              src={brand.image}
                              alt={brand.name}
                              style={{
                                width: "180px",
                                height: "80px",
                                objectFit: "contain",
                                backgroundColor: "#f8f9fa",
                                borderRadius: "5px",
                              }}
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-muted">No brands available</p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Container>
    </Modal>
  );
};

export default AddBrandsModelForEditProject;
