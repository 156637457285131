import DropFileInputProjectNew from "components/DragInput/DropFileInputProjectNew";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import ReactSelect from "react-select";
import useFetchMedia from "utilities/customHooks/useFetchMedia";
import RoomMediaCard from "./RoomImageCard";

const EditProjectRoomSelector = ({
  selectedRoomtypes,
  setSelectedRoomtypes,
  setLoading,
  setProcessing,
  setRooms,
  selectorRooms,
  roomtypeIndex,
  setRoomTypeIndex,
  roomTypes,
  addSelectedRoomtype,
  rooms,
  handleRoomSelect,
  onSelectRoomFiles,
  selectedRoomSlug,
  setSelectedRoomSlug,
  newFiles,
  setTemps,
  setNewFiles,
  roomId,
}) => {
  const [roomMediaMap, setRoomMediaMap] = useState({});
  const [mediaForDeleteButton, setMediaForDeleteButton] = useState([]);

  useEffect(() => {
    if (roomTypes?.length > 0 && !selectedRoomSlug && rooms.length > 0) {
      setSelectedRoomSlug(rooms[0]?.room_type_data?.id);
    }
  }, [roomTypes, selectedRoomSlug, setSelectedRoomSlug, rooms]);

  // Monitor changes in selectedRoomtypes to ensure focus is maintained
  useEffect(() => {
    // If rooms changed and current index is invalid, set to the first available room
    if (selectedRoomtypes.length > 0 && roomtypeIndex >= selectedRoomtypes.length) {
      setRoomTypeIndex(0);
      const firstRoom = selectedRoomtypes[0];
      if (firstRoom?.room_type_data?.id) {
        setSelectedRoomSlug(firstRoom.room_type_data.id);
      }
    }
    // If all rooms were removed, reset the state
    else if (selectedRoomtypes.length === 0) {
      setRoomTypeIndex(0);
      setSelectedRoomSlug("");
    }
  }, [selectedRoomtypes, roomtypeIndex, setRoomTypeIndex, setSelectedRoomSlug]);

  const handleSelectRoomFiles = (files) => {
    setTemps((prevTemp) => {
      const updatedTemps = [...prevTemp, ...files];
      return updatedTemps;
    });

    setNewFiles((prevFiles) => ({
      ...prevFiles,
      [selectedRoomSlug + "_" + roomId]: [
        ...(prevFiles[selectedRoomSlug + "_" + roomId] || []),
        ...files,
      ],
    }));

    let roomKey = selectedRoomSlug + "_" + roomId;

    setRoomMediaMap(prev => ({
      ...prev,
      [roomKey]: [
        ...(prev[roomKey] || []),
        ...files.map(file => ({
          url: URL.createObjectURL(file),
          isVideo: file.type.startsWith('video/') ||
            file.name.match(/\.(mp4|webm|mov|avi|wmv)$/i)
        }))
      ]
    }));
  };

  const filteredRoomsBySlug = selectedRoomtypes.filter(
    (room) =>
      room.room_type_data.id + "_" + roomId === selectedRoomSlug + "_" + roomId
  );

  const newFilesArray = newFiles[selectedRoomSlug + "_" + roomId] || [];

  const handleRoomTypeSelect = (index, item) => {
    // First, store the current room's media state if needed
    const currentRoomKey = selectedRoomSlug + "_" + roomId;

    // Call the original handler to update room selection
    handleRoomSelect(index, item);

    // Update room ID and slug
    const newRoomId = item?.id || roomId;
    const newRoomSlug = item?.room_type_data?.id;

    // After changing rooms, we load the appropriate set of media
    const newRoomKey = newRoomSlug + "_" + newRoomId;

    // This will update the mediaForDeleteButton with the correct set for the new room
    if (roomMediaMap[newRoomKey]) {
      setMediaForDeleteButton(roomMediaMap[newRoomKey]);
    } else {
      // If we don't have stored media for this room yet, start with an empty array
      setMediaForDeleteButton([]);
    }
  };

  const handleRoomTypeSelection = (newValue) => {
    // First, get the current room IDs for comparison
    const currentRoomIds = new Set(selectedRoomtypes.map(room => room?.room_type_data?.id));

    // Call the original addSelectedRoomtype function to update the array
    addSelectedRoomtype(newValue);

    // Check if any new rooms were added
    const newlyAddedRooms = newValue.filter(room => !currentRoomIds.has(room?.room_type_data?.id));

    if (newlyAddedRooms.length > 0) {
      // New room(s) were added - focus on the last one added
      const lastNewRoom = newlyAddedRooms[newlyAddedRooms.length - 1];
      const newRoomIndex = newValue.findIndex(room =>
        room?.room_type_data?.id === lastNewRoom?.room_type_data?.id
      );

      if (newRoomIndex !== -1) {
        // Focus on this room
        setRoomTypeIndex(newRoomIndex);
        setSelectedRoomSlug(lastNewRoom?.room_type_data?.id);

        // Call handleRoomSelect to ensure roomId is set correctly
        handleRoomSelect(newRoomIndex, lastNewRoom);
      }
    }
    // If rooms were removed
    else if (newValue.length < selectedRoomtypes.length) {
      // Check if current index is now invalid
      if (roomtypeIndex >= newValue.length && newValue.length > 0) {
        // Set focus to the first available room
        setRoomTypeIndex(0);
        const firstRoom = newValue[0];
        setSelectedRoomSlug(firstRoom?.room_type_data?.id);

        // Call handleRoomSelect to ensure roomId is set correctly
        handleRoomSelect(0, firstRoom);
      }
    }
  };

  // Function to determine if a file is a video
  const isVideoFile = (file) => {
    return file.type?.startsWith('video/') ||
      file.name?.match(/\.(mp4|webm|mov|avi|wmv)$/i);
  };

  return (
    <div style={{ minHeight: 300 }}>
      <div className="my-3">
        <ReactSelect
          name="type_of_space"
          defaultValue={[
            ...new Map(
              (selectedRoomtypes || []).map((item) => [
                item?.room_type_data?.id,
                item,
              ])
            ).values(),
          ]}
          options={[
            ...new Map(
              roomTypes.map((item) => [item?.room_type_data?.id, item])
            ).values(),
          ]}
          isMulti
          getOptionLabel={(e) => e?.room_type_data?.title}
          getOptionValue={(e) => e?.room_type_data?.id}
          onChange={handleRoomTypeSelection}
          isClearable
          placeholder="Select type of space"
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderColor: "#BDC1C6",
            }),
          }}
        />
      </div>

      <div
        className="d-flex align-items-start mb-3 flex-wrap"
        style={{ width: "100%" }}
      >
        {selectedRoomtypes?.length > 0 &&
          selectedRoomtypes.map((item, index) => (
            <div key={index}>
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "8px",
                  borderRadius: "5px",
                  border:
                    index === roomtypeIndex
                      ? "1px solid black"
                      : "1px solid #dee2e6",
                  backgroundColor:
                    index === roomtypeIndex ? "#f8f9fa" : "white",
                  padding: "8px 12px",
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: index === roomtypeIndex ? "black" : "#212529",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  marginRight: "8px",
                  marginBottom: "10px",
                }}
                onClick={() => handleRoomTypeSelect(index, item)}
              >
                <span>{item?.room_type_data?.title}</span>
              </button>
            </div>
          ))}
      </div>

      <div
        className="position-relative d-flex justify-content-center align-items-center border border-dashed"
        style={{ width: "100%", borderRadius: 8 }}
      >
        <Row
          xs={2}
          sm={2}
          md={3}
          lg={3}
          xl={3}
          className="pt-2 pb-2 g-2 room-images-row"
        >
          {selectedRoomtypes[roomtypeIndex]?.room_type_data && (
            <>
              {filteredRoomsBySlug.map((item, index) => (
                <RoomMediaCard
                  item={item}
                  index={index}
                  setImagesForDeleteButton={setMediaForDeleteButton}
                  imagesForDeleteButton={mediaForDeleteButton}
                  newFilesArray={newFilesArray}
                  selectedRoomtypes={selectedRoomtypes}
                />
              ))}
            </>
          )}

          {(newFiles[selectedRoomSlug + "_" + roomId] || []).map(
            (file, idx) => {
              const previewUrl = URL.createObjectURL(file);
              const filesArray =
                newFiles[selectedRoomSlug + "_" + roomId] || [];
              const showDeleteButton =
                mediaForDeleteButton.length + filesArray.length > 1;
              const isVideo = isVideoFile(file);

              return (
                <Col key={`new-${idx}`} className="p-2 mb-2">
                  <div
                    className="d-flex flex-column rounded-start bg-white border border-grey position-relative"
                    style={{
                      height: "100%",
                      overflow: "hidden",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                    }}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        height: "100%",
                        width: "100%",
                        overflow: "hidden",
                      }}
                    >
                      {isVideo ? (
                        <video
                          src={previewUrl}
                          controls
                          className="img-fluid"
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                            objectPosition: "center",
                            minHeight: "160px",
                            maxHeight: "300px",
                          }}
                        />
                      ) : (
                        <img
                          src={previewUrl}
                          alt={`New Room Preview ${idx}`}
                          className="img-fluid"
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                            objectPosition: "center",
                          }}
                        />
                      )}
                    </div>
                    <div
                      className="position-absolute"
                      style={{
                        top: 1,
                        right: 1,
                        zIndex: 1,
                      }}
                    >
                      <div
                        className="d-flex justify-content-center align-items-center bg-white rounded-circle cursor-pointer"
                        style={{
                          width: 36,
                          height: 36,
                          boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                        }}
                        onClick={() => {
                          setNewFiles((prev) => ({
                            ...prev,
                            [selectedRoomSlug + "_" + roomId]: prev[
                              selectedRoomSlug + "_" + roomId
                            ].filter((_, i) => i !== idx),
                          }));

                          setTemps((prevTemps) =>
                            prevTemps.filter((tempFile) => tempFile !== file)
                          );

                          // Update roomMediaMap to reflect deletion
                          setRoomMediaMap(prev => {
                            const roomKey = selectedRoomSlug + "_" + roomId;
                            return {
                              ...prev,
                              [roomKey]: (prev[roomKey] || []).filter((_, i) => i !== idx)
                            };
                          });

                          URL.revokeObjectURL(previewUrl);
                        }}
                      >
                        <MdDelete
                          className="cursor-pointer"
                          size={22}
                          color="#5A5A5A"
                        />
                      </div>
                    </div>

                  </div>
                </Col>
              );
            }
          )}

          {selectedRoomtypes?.map((item, index) => (
            <>
              {index === roomtypeIndex && (
                <Col key={`drop-${index}`} className="p-2 mb-2">
                  <DropFileInputProjectNew
                    onFileChange={handleSelectRoomFiles}
                    allowMultiple
                    className={`${index === roomtypeIndex ? "d-block" : "d-none"
                      }`}
                  />
                </Col>
              )}
            </>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default EditProjectRoomSelector;