import { createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";

const initialState = {
  propertyId: "",
  requirement: "",
  floorPlan: "",
  selectedRoomType: [],
  area: 0,
  fullName: "",
  email: "",
  mobile: "",
  requirementsMsg: "",
  rooms: [
    { id: 1, label: "Bedroom", value: "bedroom", quantity: 0 },
    { id: 2, label: "Bathroom", value: "bathroom", quantity: 0 },
    { id: 3, label: "Kitchen", value: "kitchen", quantity: 0 },
    { id: 4, label: "Dining Room", value: "diningroom", quantity: 0 },
    { id: 5, label: "Living Room", value: "livingroom", quantity: 0 },
  ],
};

const consultationSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setRequirement: (state, action) => {
      state.requirement = action.payload;
      state.propertyId = "";
    },
    setFloorPlan: (state, action) => {
      state.floorPlan = action.payload;
      state.propertyId = "";
    },
    setSelectedRoomType: (state, action) => {
      state.selectedRoomType = action.payload;
      state.propertyId = "";
    },
    setArea: (state, action) => {
      state.area = action.payload;
    },
    setPropertyId: (state, action) => {
      state.propertyId = action.payload;
      state.requirement = "";
      state.floorPlan = "";
      state.rooms = [
        { id: 1, label: "Bedroom", value: "bedroom", quantity: 0 },
        { id: 2, label: "Bathroom", value: "bathroom", quantity: 0 },
        { id: 3, label: "Kitchen", value: "kitchen", quantity: 0 },
        { id: 4, label: "Dining Room", value: "diningroom", quantity: 0 },
        { id: 5, label: "Living Room", value: "livingroom", quantity: 0 },
      ];
    },
    setFullName: (state, action) => {
      state.fullName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setMobile: (state, action) => {
      state.mobile = action.payload;
    },
    setRequirementsMsg: (state, action) => {
      state.requirementsMsg = action.payload;
    },
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    resetConsultation: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      return {
        propertyId: "",
        requirement: "",
        floorPlan: "",
        selectedRoomType: [],
        area: 0,
        fullName: "",
        email: "",
        mobile: "",
        requirementsMsg: "",
        rooms: [
          { id: 1, label: "Bedroom", value: "bedroom", quantity: 0 },
          { id: 2, label: "Bathroom", value: "bathroom", quantity: 0 },
          { id: 3, label: "Kitchen", value: "kitchen", quantity: 0 },
          { id: 4, label: "Dining Room", value: "diningroom", quantity: 0 },
          { id: 5, label: "Living Room", value: "livingroom", quantity: 0 },
        ],
      };
    });
  },
});

export const {
  setRequirement,
  setFloorPlan,
  setSelectedRoomType,
  setArea,
  setPropertyId,
  setFullName,
  setEmail,
  setMobile,
  setRequirementsMsg,
  setRooms,
  resetConsultation
} = consultationSlice.actions;

export default consultationSlice.reducer;
