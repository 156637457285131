import ProductListCard from "components/Cards/ProductListCard";
import ProjectCardShimmer from "components/Cards/ProjectCardShimmer";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";

const SavedProducts = ({
  showAllRecords = false,
  showProducts = true,
  context = "default", // Add context prop
}) => {
  const navigate = useNavigate();

  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);

  const [products, setProducts] = useState([]);
  const [singleProductsRow, setSingleProductsRow] = useState([]);
  const [productLength, setProductLength] = useState(0);

  const [loading, setLoading] = useState(false);

  const itemsInRow = xlarge ? 3 : large ? 3 : medium ? 2 : 1;

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchSavedPropertiesByArchitect({
        user: user.id,
        products: showProducts,
      });
      if (response.data) {
        if (showProducts) {
          setProductLength(response.data["saved_products"].length);
          setProducts(response.data["saved_products"]);
          setSingleProductsRow(response.data["saved_products"].slice(0, itemsInRow));
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [showProducts]);

  const getNavigationState = () => {
    if (context === "editProfile") {
      return { state: { fromEditProfile: true, fromSavedSection: "products" } };
    } else if (context === "profile") {
      return { state: { fromProfile: true, fromSavedSection: "products" } };
    }
    return { state: { fromSavedSection: "products" } };
  };

  return (
    <Container className="py-4 px-0">
      {loading ? (
        <div className="d-flex flex-column align-items-center w-100">
          <Row className="w-100">
            {[...Array(3)].map((_, index) => (
              <Col key={index} lg={4} md={6} sm={12} className="py-2 mb-2">
                <ProjectCardShimmer />
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <>
          <div>
            {showProducts && (
              <div className="mb-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="h4 inter-600 mb-0">
                    Saved Products ({!loading && products.length})
                  </div>
                  {!showAllRecords && products.length > 3 && (
                    <div
                      className="body-text2 text-gray-900 inter-500 cursor-pointer"
                      onClick={() => navigate(`/user/saved#products`, getNavigationState())}
                    >
                      View all
                    </div>
                  )}
                </div>
                <Row className={`${singleProductsRow.length > 0 ? "px-3" : "px-3"}`}>
                  {singleProductsRow?.length > 0 ? (
                    singleProductsRow?.map((product, index) => (
                      <Col
                        key={index}
                        lg={4}
                        md={6}
                        sm={12}
                        className={`d-flex flex-column py-2 mb-2 justify-content-start ${
                          large
                            ? index % 3 === 0
                              ? "ps-0"
                              : index % 3 === 2
                              ? "pe-0"
                              : ""
                            : medium
                            ? index % 2 === 0
                              ? "ps-0"
                              : "pe-0"
                            : "p-0"
                        }`}
                      >
                        <ProductListCard
                          {...product?.saved_product_data}
                          productId={product?.product}
                          product_type={product?.saved_product_data?.product_type_data?.title}
                          product_sub_type={product?.saved_product_data?.product_sub_type_data?.title}
                          setSingleRowProducts={setSingleProductsRow}
                          products={products}
                          setProducts={setProducts}
                          setProductLength={setProductLength}
                          className="cursor-pointer"
                          context={context} // Pass context to card
                        />
                      </Col>
                    ))
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center bg-gray-25 mt-4"
                      style={{ height: "40vh" }}
                    >
                      No saved products!
                    </div>
                  )}
                </Row>
              </div>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default SavedProducts;