import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { FiArrowUpRight } from "react-icons/fi";
import userImg from "../../assets/navbar/user.webp";

const ProjectBrandCard = ({ brand }) => {
  return (
    <a
      href={`/brands/${brand?.slug}/` || "#"}
      target="_blank"
      rel="noopener noreferrer"
      className="text-decoration-none text-dark"
    >
      <Card className="px-2 shadow-sm border-0 brand-card">
        <Row className="g-2 align-items-center">
          <Col xs={3} className="d-flex justify-content-center align-items-center">
            <div className="brand-image-wrapper">
              <Card.Img
                variant="top"
                src={brand?.image || userImg}
                alt={brand?.company_name}
                className="brand-image"
              />
            </div>
          </Col>
          <Col xs={9}>
            <Card.Body className="p-0 d-flex flex-column justify-content-center">
              <div className="d-flex justify-content-between align-items-center">
                <Card.Title as="h6" className="mb-1">
                  {brand?.company_name}
                </Card.Title>
                <FiArrowUpRight size={30} color={"#3C4043"}/>
              </div>
              <Card.Text className="text-muted brand-thoughts">
                {brand?.brand_thoughts}
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </a>
  );
};

export default ProjectBrandCard;
