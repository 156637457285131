import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import { IoArrowBack } from "react-icons/io5";
import Review from "components/Review/Review";
import ProjectCard from "components/Cards/ProjectCard";
import TeamMember from "components/TeamMember/TeamMember";
import Gallery from "components/Gallery/Gallery";
import userImg from "../../assets/navbar/user.webp";
import builderPageImage from "../../assets/builderpageimage.png";
import * as authActions from "reduxFiles/actions/authActions";
import { useMediaQuery } from "react-responsive";
import useVideoPlayer from "utilities/customHooks/useVideoPlayer";
import { notify } from "utilities/notifications/Notify";

const BuilderProfilePreview = ({ user, onBack, isPreview = true, previewUser = null }) => {
    const small = useMediaQuery({ query: "(min-width: 576px)" });

    // Use our custom video player hook to manage videos
    const { registerVideoRef, handleVideoPlay } = useVideoPlayer();

    const [loading, setLoading] = useState(true);
    const [builderDetails, setBuilderDetails] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [media, setMedia] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [projects, setProjects] = useState([]);
    const [activeTab, setActiveTab] = useState("about-us");
    const [showAll, setShowAll] = useState(false);
    const [properties, setProperties] = useState([]);

    const aboutUsRef = useRef(null);
    const projectsRef = useRef(null);
    const teamRef = useRef(null);
    const galleryRef = useRef(null);
    const reviewsRef = useRef(null);

    // Fetch builder details
    useEffect(() => {
        const fetchDetails = async () => {
            setLoading(true);
            try {
                // Use the appropriate user ID depending on preview mode
                const userId = isPreview ? previewUser?.id : user?.id;
                
                if (!userId) {
                    throw new Error("No user ID available");
                }

                // Fetch builder data
                const response = await authActions.fetchBuildersDataByUser(userId);

                if (response?.data?.results && response.data.results[0]) {
                    setBuilderDetails(response.data.results[0]);
                } else {
                    // If no specific details found, create a minimal object using user data
                    const userObj = isPreview ? previewUser : user;
                    setBuilderDetails({
                        id: userObj.id,
                        company_name: userObj.company_name,
                        builder_thoughts: "No details available yet. Please complete your profile information.",
                        user_data: userObj,
                        user: userObj.id
                    });
                }
            } catch (error) {
                console.error("Error fetching builder details:", error);
                // Create a fallback object to avoid null errors
                const userObj = isPreview ? previewUser : user;
                setBuilderDetails({
                    id: userObj?.id,
                    company_name: userObj?.company_name,
                    builder_thoughts: "Unable to load profile details.",
                    user_data: userObj,
                    user: userObj?.id
                });
            }
            setLoading(false);
        };

        fetchDetails();
    }, [isPreview, previewUser, user]);

    // Fetch team members
    useEffect(() => {
        const fetchTeamData = async () => {
            try {
                const userId = builderDetails?.user_data?.id || builderDetails?.user;
                const response = await authActions.fetchTeamMembers({ user_id: userId });
                if (response) setTeamMembers(response.data);
            } catch (error) {
                console.error("Error fetching team members:", error);
            }
        };

        if (builderDetails) {
            fetchTeamData();
        }
    }, [builderDetails]);

    // Fetch gallery media
    useEffect(() => {
        const fetchGalleryData = async () => {
            try {
                const userId = builderDetails?.user_data?.id || builderDetails?.user;
                const response = await authActions.fetchGallery({ user_id: userId });
                if (response) setMedia(response.data);
            } catch (error) {
                console.error("Error fetching gallery:", error);
            }
        };

        if (builderDetails) {
            fetchGalleryData();
        }
    }, [builderDetails]);

    // Fetch reviews
    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const userId = builderDetails?.user_data?.id || builderDetails?.user;
                const response = await authActions.fetchReviewsByArchitectUser(userId);
                if (response?.data?.results) {
                    setReviews(response.data.results);
                }
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        };

        if (builderDetails) {
            fetchReviews();
        }
    }, [builderDetails]);

    // Fetch properties/projects
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const userId = builderDetails?.user_data?.id || builderDetails?.user;
                // Use the existing fetchProjectsByBuilderUser function instead
                const response = await authActions.fetchProjectsByBuilderUser(userId);
                if (response?.data?.results) {
                    setProjects(response.data.results);
                }
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        if (builderDetails) {
            fetchProjects();
        }
    }, [builderDetails]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);

        let sectionRef;
        switch (tab) {
            case "about-us":
                sectionRef = aboutUsRef;
                break;
            case "properties-created":
                sectionRef = projectsRef;
                break;
            case "team":
                sectionRef = teamRef;
                break;
            case "gallery":
                sectionRef = galleryRef;
                break;
            case "reviews-and-ratings":
                sectionRef = reviewsRef;
                break;
            default:
                sectionRef = null;
        }

        if (sectionRef?.current) {
            window.scrollTo({
                top: sectionRef.current.offsetTop - 100,
                behavior: "smooth",
            });
        }
    };

    const handleDownloadBrochure = () => {
        if (isPreview) {
            notify("Download brochure feature is disabled in preview mode", "info");
        }
    };

    const handleSendEnquiry = () => {
        if (isPreview) {
            notify("Send enquiry feature is disabled in preview mode", "info");
        }
    };

    // Determine which data is available
    const hasAboutUs = builderDetails?.builder_thoughts || builderDetails?.architect_thoughts;
    const hasTeam = teamMembers?.length > 0;
    const hasGallery = media?.length > 0;
    const hasProjects = projects?.length > 0;

    // Get thoughts field based on user type
    const aboutThoughts = builderDetails?.builder_thoughts || builderDetails?.architect_thoughts;
    const aboutImage = builderDetails?.builder_image || builderDetails?.architect_image;

    // Create tabs based on available data
    const availableTabs = [
        hasAboutUs && "about-us",
        hasProjects && "properties-created",
        hasTeam && "team",
        hasGallery && "gallery",
        "reviews-and-ratings" // Always show reviews tab
    ].filter(Boolean); // Remove falsy values

    const getTabLabel = (tab) => {
        switch (tab) {
            case "about-us":
                return "About Us";
            case "properties-created":
                return `Properties Created (${projects?.length || 0})`;
            case "team":
                return "Team";
            case "gallery":
                return "Gallery";
            case "reviews-and-ratings":
                return "Reviews and Ratings";
            default:
                return tab;
        }
    };

    const visibleReviews = showAll ? reviews : reviews.slice(0, 5);

    if (loading) {
        return (
            <Container fluid className="mt-5 pt-4 px-0">
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "70vh" }}>
                    <Spinner animation="border" variant="primary" />
                </div>
            </Container>
        );
    }

    return (
        <Container fluid className="mt-5 pt-4 px-0">
            {/* Header with back button */}
            <Container className="my-4">
                <div className="d-flex justify-content-between align-items-center">
                    <Button
                        variant="outline-secondary"
                        className="d-flex align-items-center gap-2"
                        onClick={onBack}
                    >
                        <IoArrowBack /> Back to Settings
                    </Button>
                    <h3 className="inter-600 text-gray-900 mb-0">Profile Preview</h3>
                </div>
            </Container>

            {/* Banner Image */}
            <div>
                <img
                    src={builderDetails?.builder_banner || builderPageImage}
                    alt="Builder Banner"
                    className="img-fluid d-block w-100"
                    style={{
                        objectFit: "contain",
                    }}
                />
            </div>

            <Container className="pb-4">
                <div className="d-flex justify-content-center bg-gray-25 geist w-100 m-0 py-3 px-xl-3 px-0 titletextbar mt-4">
                    <Col lg={2} xl={2} className="p-0">
                        <div
                            className="d-flex justify-content-start align-items-start"
                            style={{ height: "100%" }}
                        >
                            <div className="d-flex justify-content-start align-items-start bg-white rounded overflow-hidden titleimg">
                                <img
                                    className="border img-fluid w-100 h-100"
                                    style={{
                                        border: "1px solid #D0D5DD",
                                        borderRadius: "12px",
                                        objectFit: "contain",
                                    }}
                                    src={
                                        builderDetails?.user_data?.profile_picture || userImg
                                    }
                                    alt="Builder Profile"
                                />
                            </div>
                        </div>
                    </Col>

                    <Col lg={10} xl={10}>
                        <div className="titletextbtnbox w-100">
                            <div className="titletext">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="text-uppercase fw-semibold fs-4 lh-sm text-dark">
                                        {builderDetails?.company_name || (isPreview ? previewUser?.company_name : "Your Company Name")}
                                    </div>
                                    <div
                                        className="d-flex align-items-center bg-danger text-white gap-1 rounded-1 px-2 py-1"
                                        style={{ height: "30px" }}
                                    >
                                        <FaStar style={{ width: "16px", height: "16px" }} />
                                        <span className="fs-6">
                                            {reviews?.length > 0
                                                ? (
                                                    reviews.reduce(
                                                        (acc, review) => acc + review.rating,
                                                        0
                                                    ) / reviews.length
                                                ).toFixed(1)
                                                : 0}
                                        </span>
                                    </div>
                                </div>
                                <div className="body-text inter-500 fs-6 mt-2">
                                    {builderDetails?.user_data?.user_subtype_data?.title || "Builder"}
                                </div>

                                <div>
                                    {[
                                        builderDetails?.user_data?.city,
                                        builderDetails?.user_data?.state,
                                        builderDetails?.user_data?.country,
                                    ]
                                        .filter(Boolean)
                                        .join(", ") || "Location N/A"}{" "}
                                    {builderDetails?.user_data?.pincode ? `- ${builderDetails?.user_data?.pincode}` : ""}
                                </div>

                                <div className="d-flex gap-3 mt-3 flex-wrap">
                                    <div
                                        className="d-flex align-items-center p-2 border"
                                        style={{
                                            backgroundColor: "#F1F3F4",
                                            color: "#2E3134",
                                        }}
                                    >
                                        {builderDetails?.years_of_experience || 0} Years Experience
                                    </div>
                                    {builderDetails?.expertise_type && (
                                        <div
                                            className="d-flex align-items-center p-2 border"
                                            style={{
                                                backgroundColor: "#F1F3F4",
                                                color: "#2E3134",
                                            }}
                                        >
                                            Expertise: {builderDetails.expertise_type}
                                        </div>
                                    )}
                                    <div
                                        className="d-flex align-items-center p-2 border"
                                        style={{
                                            backgroundColor: "#F1F3F4",
                                            color: "#2E3134",
                                        }}
                                    >
                                        {builderDetails?.project_count || 0} Properties Created
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </div>

                <Container fluid className="p-0 m-0 interotabssection">
                    <Row className="gx-5">
                        {/* Left Side: About Us & Projects/Properties */}
                        <Col xs={12} xl={8} className="mt-3">
                            {/* Conditionally render navigation if any section has data */}
                            {availableTabs.length > 0 && (
                                <div
                                    className="d-flex border-bottom"
                                    style={{
                                        borderBottom: "1px solid #DADCE0",
                                        background: "#fff",
                                        position: "sticky",
                                        zIndex: "999",
                                        top: "70px",
                                    }}
                                >
                                    {availableTabs
                                        .filter(tab => tab !== "reviews-and-ratings" || window.innerWidth < 1200)
                                        .map((tab) => (
                                            <button
                                                key={tab}
                                                className="border-0 bg-transparent px-sm-3 py-2 position-relative"
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    lineHeight: "18px",
                                                    textAlign: "center",
                                                    color: activeTab === tab ? "#C53D3D" : "#80868B",
                                                    borderBottom: "2px solid transparent",
                                                }}
                                                onClick={() => handleTabClick(tab)}
                                            >
                                                {getTabLabel(tab)}
                                                {activeTab === tab && (
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            bottom: "0",
                                                            left: "0",
                                                            width: "100%",
                                                            height: "3px",
                                                            background: "#C53D3D",
                                                        }}
                                                    />
                                                )}
                                            </button>
                                        ))}
                                </div>
                            )}

                            {/* About Us Section */}
                            {hasAboutUs && (
                                <div ref={aboutUsRef} className="mt-3">
                                    <Row className="border rounded-2 m-0 overflow-hidden">
                                        <Col xs={12} className="px-0">
                                            {aboutImage && /\.(mp4|webm|ogg)$/i.test(aboutImage) ? (
                                                <video
                                                    ref={(el) => {
                                                        // Register this video with our hook
                                                        if (el) registerVideoRef('about-us-video', el);
                                                    }}
                                                    src={aboutImage}
                                                    className="img-fluid w-100"
                                                    style={{
                                                        objectFit: "cover",
                                                        borderRadius: "6px 6px 0 0",
                                                        height: "418px",
                                                    }}
                                                    controls
                                                    onPlay={() => handleVideoPlay('about-us-video')}
                                                    muted
                                                />
                                            ) : (
                                                <img
                                                    src={aboutImage || "/default-image.jpg"}
                                                    alt="About Builder"
                                                    className="img-fluid w-100"
                                                    style={{
                                                        objectFit: "cover",
                                                        borderRadius: "6px 6px 0 0",
                                                        height: "418px",
                                                    }}
                                                />
                                            )}
                                        </Col>
                                        <Col
                                            xs={12}
                                            className="py-3 text-start d-flex flex-column justify-content-center"
                                        >
                                            <div className="ms-3">
                                                <div className="body-text1 inter-400 text-gray-600">
                                                    {aboutThoughts || "No description provided yet."}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )}

                            {/* Properties Created Section */}
                            {hasProjects && (
                                <div ref={projectsRef} className="mb-4 pt-4">
                                    <div className="h4 inter-600">Properties Created</div>
                                    <div className="py-3">
                                        {projects?.length > 0 ? (
                                            <div className="row">
                                                {projects.map((project, index) => (
                                                    <div
                                                        key={index}
                                                        className="col-12 col-md-6 mb-3"
                                                    >
                                                        <ProjectCard
                                                            project={project}
                                                            isPreview={true}
                                                            videoRefCallback={(id, element) => {
                                                                registerVideoRef(`project-video-${id}`, element);
                                                            }}
                                                            onVideoPlay={(id) => handleVideoPlay(`project-video-${id}`)}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div
                                                className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                                                style={{ height: 160 }}
                                            >
                                                No properties available
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Team Section */}
                            {hasTeam && (
                                <div ref={teamRef} className="mb-4 pt-4">
                                    <div className="h4 inter-600">Meet the Team!</div>
                                    <div className="py-3">
                                        {teamMembers?.length > 0 ? (
                                            <div className="row">
                                                {teamMembers.map((teamMember, index) => (
                                                    <div
                                                        key={index}
                                                        className="col-5 col-sm-6 col-md-2 mb-3"
                                                    >
                                                        {/* Pass an empty user prop to hide edit/delete functions */}
                                                        <TeamMember
                                                            {...teamMember}
                                                            user={null}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div
                                                className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                                                style={{ height: 160 }}
                                            >
                                                No Team Members available
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Gallery Section */}
                            {hasGallery && (
                                <div ref={galleryRef} className="mb-4">
                                    <div className="h4 inter-600 pb-2 mt-3">Get to Know Us!</div>
                                    <Gallery
                                        items={media}
                                        enableEdit={false}
                                        videoRefCallback={(id, element) => {
                                            registerVideoRef(`gallery-video-${id}`, element);
                                        }}
                                        onVideoPlay={(id) => handleVideoPlay(`gallery-video-${id}`)}
                                    />
                                </div>
                            )}
                        </Col>

                        {/* Right Side: Reviews and Ratings */}
                        <Col xs={12} xl={4} className="pt-5" ref={reviewsRef}>
                            <Container
                                className="pt-4 pb-3 border reviewpart"
                                style={{
                                    borderColor: "#E8EAED",
                                    borderWidth: "1px",
                                    position: "sticky",
                                    top: "110px",
                                }}
                            >
                                {/* Review Count & Title */}
                                <div
                                    className="h4 inter-600 text-center mb-4"
                                    style={{ color: "#1A1C1F" }}
                                >
                                    <span className="text-danger">
                                        {reviews?.length ?? 0}
                                    </span>{" "}
                                    Reviews and Ratings
                                </div>

                                {/* Average Rating */}
                                <div className="h4 inter-600 text-center text-danger d-flex justify-content-center align-items-center gap-2">
                                    <FaStar className="text-danger" />
                                    {reviews?.length > 0
                                        ? (
                                            reviews.reduce(
                                                (acc, review) => acc + review.rating,
                                                0
                                            ) / reviews.length
                                        ).toFixed(1)
                                        : 0}
                                </div>

                                <div
                                    className="h6 inter-600 text-center pt-1 pb-1"
                                    style={{ color: "#5F6368" }}
                                >
                                    Average Rating
                                </div>

                                {/* Add Review Button (disabled in preview) */}
                                <div className="w-100 my-3 d-flex justify-content-center">
                                    <Button className="text-white" disabled>
                                        Add Your Review
                                    </Button>
                                </div>

                                {/* Display Reviews */}
                                {visibleReviews.length > 0 ? (
                                    visibleReviews.map(
                                        (review, index) => <Review key={index} {...review} />
                                    )
                                ) : (
                                    <div
                                        className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                                        style={{ height: 160 }}
                                    >
                                        No reviews available
                                    </div>
                                )}

                                {/* "See All" Button */}
                                {reviews?.length > 5 && !showAll && (
                                    <div className="text-center mt-3">
                                        <button
                                            className="btn"
                                            onClick={() => setShowAll(true)}
                                            style={{
                                                color: "#C53D3D",
                                                border: "none",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            See All
                                        </button>
                                    </div>
                                )}

                                {/* "See Less" Button */}
                                {showAll && reviews?.length > 5 && (
                                    <div className="text-center mt-3">
                                        <button
                                            className="btn"
                                            onClick={() => setShowAll(false)}
                                            style={{
                                                color: "#C53D3D",
                                                border: "none",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            See Less
                                        </button>
                                    </div>
                                )}
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Container>
    );
};

export default BuilderProfilePreview;