import SavedProducts from "pages/ProfessionalSpecific/SavedProducts";
import SavedProperties from "pages/ProfessionalSpecific/SavedProperties";
import Library from "pages/User/Library";
import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";

const FavouritePage = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Container fluid className="mt-5 pt-4 px-0" style={{ minHeight: "100vh" }}>
      <Container>
        <h3 className="inter-600 text-gray-900 py-5 mb-0">Favourites</h3>
        {user?.user_type !== "customer" && (<><SavedProperties context="profile" showAllRecords={true} />
          <SavedProducts context="profile" showAllRecords={true} /></>)}
        {user?.user_type === "customer" &&
          <Library context="profile" />}
      </Container>
    </Container>
  );
};

export default FavouritePage;