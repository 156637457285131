import { AccountEditForm, ChangePasswordForm } from "components";
import { Container } from "react-bootstrap";
import Library from "./Library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as authActions from "reduxFiles/actions/authActions";
import AccountEditFormCustomer from "components/Forms/AccountEditFormCustomer";

const UserProfile = () => {
  const user = useSelector((state) => state.auth.user);
  const [userDetails, setUserDetails] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const response = await authActions.fetchCustomerById(user.id);
      setUserDetails(response.data.results[0]);
    };

    fetchData();
  }, [user.id]);

  return (
    <Container
      fluid
      className="mt-5 pt-4 px-0"
      style={{ minHeight: "100vh" }}
    >
      <Container>
        <h3 className="inter-600 text-gray-900 py-5 mb-0">My Profile</h3>
        <AccountEditFormCustomer user={userDetails} />
        {/* <ChangePasswordForm /> */}
        {/* <Library context="profile" /> */}
      </Container>
    </Container>
  );
};

export default UserProfile;
