import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Modal,
} from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import Review from "components/Review/Review";
import * as authActions from "reduxFiles/actions/authActions";
import { useSelector } from "react-redux";
import RatingStarsView from "components/General/RatingStarsView";
import ShareModal from "components/General/ShareModal";
import { notify } from "utilities/notifications/Notify";
import LoginModal from "components/Auth/LoginModal";
import { useMediaQuery } from "react-responsive";
import BrochureModal from "components/Professionals/BrochureModal";
import ProjectCard from "components/Cards/ProjectCard";
import testImage from "../../assets/brandimage.png";
import userImg from "../../assets/navbar/user.webp";
import BrandEnquiryForm from "components/Forms/BrandEnquiryForm";
import TeamMember from "components/TeamMember/TeamMember";
import Gallery from "components/Gallery/Gallery";

const BrandDetails = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const categoryId = queryParams.get("product_category");
  const category_name = queryParams.get("category_name");
  const small = useMediaQuery({ query: "(min-width: 576px)" });

  const form = useRef();
  const [brandDetails, setBrandDetails] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [showShare, setShowShare] = useState(false);
  const [activeFilter, setActiveFilter] = useState(category_name || "all_products");
  const [sortBy, setSortBy] = useState(queryParams.get("sort_by") || "");
  const [status, setStatus] = useState(queryParams.get("status") || "");
  const [rating, setRating] = useState(5);
  const [isLiked, setIsLiked] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [next, setNext] = useState("");
  const [brandRelatedInspirations, setBrandRelatedInspirations] = useState([]);
  const [taggedInspirations, setTaggedInspirations] = useState([]);
  const [taggedInspirationsNext, setTaggedInspirationsNext] = useState("");
  const [showBrochureModal, setShowBrochureModal] = useState(false);
  const [brochureFileUrl, setBrochureFileUrl] = useState(null); // New state to store the file URL
  const [brochureFileName, setBrochureFileName] = useState(null); // New state to store the file name
  const [teamMembers, setTeamMembers] = useState([]);
  const [media, setMedia] = useState([]);
  const userBrandId = brandDetails?.user;
  const BrandID = brandDetails?.id;
  const [loading, setLoading] = useState(false);
  const [taggedInspirationsLoading, setTaggedInspirationsLoading] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [activeTab, setActiveTab] = useState("about-us");
  const [showAll, setShowAll] = useState(false);
  const aboutUsRef = useRef(null);
  const projectsRef = useRef(null);
  const taggedInspirationsRef = useRef(null);
  const teamRef = useRef(null);
  const galleryRef = useRef(null);
  const reviewsRef = useRef(null);
  const tabsRef = useRef(null);
  const [show, setShow] = useState(false);
  const [showEnquiry, setShowEnquiry] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const isProfileRoute = location.pathname === "/brand/account/profile";
  const [pendingReview, setPendingReview] = useState({
    text: "",
    rating: 5,
    shouldReopen: false
  });

  const [isSubmittingReview, setIsSubmittingReview] = useState(false);

  const tabsData = [
    {
      key: "about-us",
      label: "About Us",
      data: !!(brandDetails?.brand_image || brandDetails?.brand_thoughts),
      ref: aboutUsRef,
    },
    {
      key: "properties-created",
      label: `Inspirations Created (${brandRelatedInspirations?.length})`,
      data: brandRelatedInspirations?.length > 0,
      ref: projectsRef,
    },
    {
      key: "tagged-inspirations",
      label: `Tagged Inspirations (${taggedInspirations?.length})`,
      data: taggedInspirations?.length > 0,
      ref: taggedInspirationsRef,
    },
    { key: "team", label: "Team", data: teamMembers?.length > 0, ref: teamRef },
    { key: "gallery", label: "Gallery", data: media?.length > 0, ref: galleryRef },
    { key: "reviews-and-ratings", label: "Reviews and Ratings", data: true, ref: reviewsRef },
  ];

  const visibleTabs = tabsData.filter((tab) => tab.data);

  const fetchBrand = async () => {
    try {
      const response = await authActions.fetchBrandsBySlug(slug);
      if (response.data) {
        setBrandDetails(response.data.results[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBrandProfile = async () => {
    if (!user?.id || user?.user_type !== "brand") {
      navigate("/login");
      return;
    }
    setLoading(true);
    try {
      const response = await authActions.fetchBrandByUser(user.id);
      if (response.data && response.data.results && response.data.results.length > 0) {
        setBrandDetails(response.data.results[0]);
      } else {
        notify("Failed to load brand profile", "error");
      }
    } catch (error) {
      console.log("Error fetching brand profile:", error);
      notify("Error loading profile", "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchReviews = async (id) => {
    try {
      const resReviews = await authActions.fetchReviewsByArchitectUser(id);
      if (resReviews?.data?.results) {
        setReviews(resReviews.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTeamMembers = async (id) => {
    try {
      const response = await authActions.fetchTeamMembers({ user_id: id });
      if (response?.data) {
        setTeamMembers(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGalleries = async (id) => {
    try {
      const response = await authActions.fetchGallery({ user_id: id });
      if (response?.data) {
        setMedia(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Updated function to fetch projects using fetchProjectsByBrand
  const fetchProjectsByBrandId = async (brandId, sortBy = "", status = "") => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjectsByBrand(brandId, sortBy, status);
      if (response?.data) {
        setBrandRelatedInspirations(response.data.results || []);
        setNext(response.data.next || null);
      } else {
        notify("Error while loading project details", "error");
      }
    } catch (error) {
      console.log("fetchProjectsByBrand error:", error);
      notify("Error while fetching projects for this brand", "error");
    } finally {
      setLoading(false);
    }
  };

  // Updated function to load more projects
  const loadProjectsByBrand = async (next) => {
    try {
      const response = await authActions.loadProjects(next);
      setNext(response?.data?.next || null);
      if (Array.isArray(response?.data?.results)) {
        setBrandRelatedInspirations((prev) => [...prev, ...response.data.results]);
      }
    } catch (error) {
      console.log("loadProjectsByBrand error:", error);
      notify("Error while loading more projects", "error");
    }
  };

  const fetchTaggedInspirations = async (brandId) => {
    setTaggedInspirationsLoading(true);
    try {
      const response = await authActions.fetchTaggedProjectsByBrand(brandId);
      if (response?.data) {
        const inspirationList = response.data.results.map((item) => item.project_data);
        console.log("Tagged Inspirations:", inspirationList); // Debug log
        setTaggedInspirations(inspirationList || []);
        setTaggedInspirationsNext(response.data.next || null);
      } else {
        notify("Error while loading tagged inspirations", "error");
      }
    } catch (error) {
      console.log("fetchTaggedInspirationsByBrand error:", error);
      notify("Error while fetching tagged inspirations", "error");
    } finally {
      setTaggedInspirationsLoading(false);
    }
  };

  const loadMoreTaggedInspirations = async (next) => {
    try {
      const response = await authActions.loadProjects(next);
      setTaggedInspirationsNext(response?.data?.next || null);
      if (Array.isArray(response?.data?.results)) {
        const newInspirations = response.data.results.map((item) => item.project_data);
        setTaggedInspirations((prev) => [...prev, ...newInspirations]);
      }
    } catch (error) {
      console.log("loadMoreTaggedInspirations error:", error);
      notify("Error while loading more tagged inspirations", "error");
    }
  };

  const submitReview = async (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      // Save the current review state
      const reviewText = form.current.elements.review.value;
      setPendingReview({
        text: reviewText,
        rating: rating,
        shouldReopen: true
      });

      // Close review modal and open login modal
      setShow(false);
      setTimeout(() => setShowLoginModal(true), 200);
      return;
    }

    setIsSubmittingReview(true);

    const formData = new FormData(form.current);
    formData.append("for_user", brandDetails?.user_data?.id);
    formData.append("by_user", user?.id);
    formData.append("rating", rating);
    if (formData.get("review")) {
      try {
        const response = await authActions.addReview(formData);
        if (response.data) {
          notify("Review submitted successfully!", "success");
          if (brandDetails?.user_data?.id) {
            fetchReviews(brandDetails?.user_data?.id);
          }
          form.current.reset();
          setShow(false);
          setIsSubmittingReview(false);
          setPendingReview({
            text: "",
            rating: 5,
            shouldReopen: false
          });
          setRating(5);
        }
      } catch (error) {
        console.log(error);
        notify("Failed to submit review", "error");
        setIsSubmittingReview(false);
      }
    } else {
      setIsSubmittingReview(false);
      notify("Review cannot be empty!", "error");
    }
  };

  const checkBrandIfSaved = async () => {
    try {
      const response = await authActions.checkIsSavedBrand(user?.id, brandDetails?.user);
      setIsLiked(response.status);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveBrand = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      const response = await authActions.saveBrand(user?.id, brandDetails?.user);
      if (response.status) {
        setIsLiked(true);
        notify("Brand saved!", "success");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUnsaveBrand = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      const response = await authActions.unsaveBrand(user?.id, brandDetails?.user);
      if (response) {
        setIsLiked(false);
        notify("Brand unsaved!", "success");
      }
      await checkBrandIfSaved();
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const downloadBrochure = (fileUrl) => {
    // Check if fileUrl exists
    if (!fileUrl) {
      notify("No brochure available for download", "error");
      return;
    }

    // Extract the file extension from the URL
    const fileExtension = fileUrl.split(".").pop().toLowerCase();

    // Check if the file is a PDF or ZIP
    if (!["pdf", "zip"].includes(fileExtension)) {
      notify("Brochure download is only available for PDF or ZIP files", "error");
      return;
    }

    // Extract the file name from the URL (last segment after the last "/")
    const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);

    // Store the file URL and file name in state
    setBrochureFileUrl(fileUrl);
    setBrochureFileName(fileName);

    // Show the brochure modal
    setShowBrochureModal(true);
  };

  const handleDownloadBrochureClick = (fileUrl) => {
    if (!isAuthenticated) {
      setPendingAction(() => () => downloadBrochure(fileUrl));
      setShowLoginModal(true);
    } else {
      downloadBrochure(fileUrl);
    }
  };

  const handleLoginSuccess = () => {
    if (pendingAction) {
      pendingAction();
      setPendingAction(null);
    }
  };

  const handleLoadmore = async () => {
    if (next) {
      loadProjectsByBrand(next);
    }
  };

  const handleLoadmoreTaggedInspirations = async () => {
    if (taggedInspirationsNext) {
      loadMoreTaggedInspirations(taggedInspirationsNext);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    const targetRef =
      tab === "about-us"
        ? aboutUsRef
        : tab === "properties-created"
          ? projectsRef
          : tab === "tagged-inspirations"
            ? taggedInspirationsRef
            : tab === "team"
              ? teamRef
              : tab === "gallery"
                ? galleryRef
                : reviewsRef;

    if (targetRef.current) {
      window.scrollTo({
        top: targetRef.current.offsetTop - 90,
        behavior: "smooth",
      });
    }
  };

  const handleSortBy = (ordering) => {
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set("sort_by", ordering);
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
    setSortBy(ordering);
  };

  const handleStatusFilter = (status) => {
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set("status", status);
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
    setStatus(status);
  };

  const handleClearFilters = () => {
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.delete("status");
    updatedSearchParams.delete("sort_by");
    setSortBy("");
    setStatus("");
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
  };

  useEffect(() => {
    if (isProfileRoute) {
      fetchBrandProfile();
    } else if (slug && slug !== "undefined") {
      fetchBrand();
    } else {
      navigate(-1);
    }
  }, [slug, isProfileRoute, user]);

  useEffect(() => {
    if (userBrandId) {
      fetchReviews(userBrandId);
      fetchTeamMembers(userBrandId);
      fetchGalleries(userBrandId);
    }
  }, [userBrandId]);

  useEffect(() => {
    if (BrandID) {
      fetchProjectsByBrandId(BrandID, sortBy, status);
      fetchTaggedInspirations(BrandID);
    }
  }, [BrandID, sortBy, status]);

  useEffect(() => {
    if (user?.id && brandDetails?.user && !isProfileRoute) {
      checkBrandIfSaved();
    }
  }, [user?.id, brandDetails?.user, isProfileRoute]);

  useEffect(() => {
    const handleScroll = () => {
      const aboutUsTop = aboutUsRef.current?.offsetTop - 90;
      const projectsTop = projectsRef.current?.offsetTop - 90;
      const taggedInspirationsTop = taggedInspirationsRef.current?.offsetTop - 90;
      const teamTop = teamRef.current?.offsetTop - 90;
      const galleryTop = galleryRef.current?.offsetTop - 90;
      const reviewsTop = reviewsRef.current?.offsetTop - 90;
      const scrollPosition = window.scrollY;

      if (scrollPosition >= reviewsTop) {
        setActiveTab("reviews-and-ratings");
      } else if (scrollPosition >= galleryTop) {
        setActiveTab("gallery");
      } else if (scrollPosition >= teamTop) {
        setActiveTab("team");
      } else if (scrollPosition >= taggedInspirationsTop) {
        setActiveTab("tagged-inspirations");
      } else if (scrollPosition >= projectsTop) {
        setActiveTab("properties-created");
      } else if (scrollPosition >= aboutUsTop) {
        setActiveTab("about-us");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    // Check if user just logged in and there's a pending review
    if (isAuthenticated && pendingReview.shouldReopen) {
      // Reopen the review modal
      setShow(true);

      // We need to wait for the modal to open and the form to be in the DOM
      setTimeout(() => {
        if (form.current) {
          // Set the form values from the saved state
          form.current.elements.review.value = pendingReview.text;
          setRating(pendingReview.rating);
        }

        // Reset the "shouldReopen" flag but keep the text and rating
        setPendingReview(prev => ({
          ...prev,
          shouldReopen: false
        }));
      }, 300);
    }
  }, [isAuthenticated, pendingReview.shouldReopen]);

  return (
    <Container fluid className="pt-5 mt-4 p-0">
      <div>
        <img
          src={brandDetails?.brand_banner || testImage}
          alt="Test Image"
          className="img-fluid d-block w-100"
          style={{
            objectFit: "contain",
          }}
        />
      </div>
      <div className="titleimgtext">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center geist w-100 m-0 p-0 sddadsadada">
                <div className="w-100 d-flex align-items-start py-3 titletextbar mt-4">
                  <div className="d-flex justify-content-start flex-wrap w-100 mt-3 mt-lg-0">
                    <div
                      className="d-flex justify-content-center align-items-center overflow-hidden titleimg"
                      style={{ border: "1px solid #DADCE0" }}
                    >
                      <img
                        className="img-fluid w-100 h-100"
                        style={{ objectFit: "contain" }}
                        src={brandDetails?.image || userImg}
                        alt="Brand Image"
                      />
                    </div>
                    <div className="titletextbtnbox interiotitlebox">
                      <div className="titletext">
                        <div className="h2 inter-600 text-capitalize d-flex align-items-center flex-wrap">
                          <div className="me-3 text-uppercase fw-semibold fs-4 lh-sm text-dark">
                            {brandDetails?.company_name}
                          </div>
                          <div
                            className="d-flex align-items-center bg-danger text-white abs starbtn gap-2 rounded-1"
                            style={{
                              height: "26px",
                              padding: "0 8px",
                              justifyContent: "center",
                            }}
                          >
                            <FaStar style={{ width: "16px", height: "16px" }} />
                            <span style={{ fontSize: "16px", lineHeight: "16px" }}>
                              {reviews?.length > 0
                                ? (reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length).toFixed(1)
                                : 0}
                            </span>
                          </div>
                        </div>
                        <div className="body-text inter-500 fs-6">
                          {brandDetails?.brand_category_data?.name}
                        </div>
                        {brandDetails?.user_data?.city && (
                          <div>
                            {brandDetails?.user_data?.city} {", "} {brandDetails?.user_data?.state}
                          </div>
                        )}
                        <div className="d-flex gap-3 mt-3 flex-wrap">
                          <div
                            className="d-flex align-items-center p-2 border"
                            style={{ backgroundColor: "#F1F3F4", color: "#2E3134" }}
                          >
                            {brandDetails?.warranty ?? 0} {brandDetails?.warranty_duration ?? ""} Warranty
                          </div>
                          {brandDetails?.expertise_type && (
                            <div
                              className="d-flex align-items-center p-2 border"
                              style={{ backgroundColor: "#F1F3F4", color: "#2E3134" }}
                            >
                              Expertise : {brandDetails?.expertise_type}
                            </div>
                          )}
                          {brandDetails?.min_pricing && brandDetails?.max_pricing && (
                            <div
                              className="d-flex align-items-center p-2 border"
                              style={{ backgroundColor: "#F1F3F4", color: "#2E3134" }}
                            >
                              ₹{Math.round(brandDetails?.min_pricing)} - ₹{Math.round(brandDetails?.max_pricing)}{" "}
                              per sq. ft.
                            </div>
                          )}
                          {brandDetails?.established_year && (
                            <div
                              className="d-flex align-items-center p-2 border"
                              style={{ backgroundColor: "#F1F3F4", color: "#2E3134" }}
                            >
                              {brandDetails?.established_year} Established Year
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-start align-items-center titletextbtn">
                        <Button
                          className="d-flex justify-content-center align-items-center social-btn py-2"
                          style={{ backgroundColor: "#F1F3F4", border: "none" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!isProfileRoute) setShowShare(true);
                          }}
                          disabled={isProfileRoute}
                        >
                          <FiSend color="#000000" size={18} />
                        </Button>
                        <Button
                          className={`d-flex justify-content-center align-items-center social-btn py-2 ms-2 ${isLiked ? "bg-primary text-white" : ""
                            }`}
                          style={{ backgroundColor: "#F1F3F4", border: "none" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (isProfileRoute) return;
                            if (!user) {
                              setShowLoginModal(true);
                            } else {
                              isLiked ? handleUnsaveBrand() : handleSaveBrand();
                            }
                          }}
                          disabled={isProfileRoute}
                        >
                          {isProcessing ? <Spinner size="sm" animation="border" /> : <FaRegHeart size={20} color="#000000" />}
                        </Button>
                        <div className="enqbtn">
                          <Button
                            className="btn text-white bg-danger border-danger py-2 px-4 ms-2 rounded fw-bold d-inline-flex justify-content-center align-items-center"
                            onClick={() => {
                              if (!isProfileRoute) handleDownloadBrochureClick(brandDetails?.brochure);
                            }}
                            disabled={isProfileRoute}
                          >
                            Download Brochures
                          </Button>
                          <Button
                            className="btn text-white bg-danger border-danger py-2 px-4 rounded fw-bold d-inline-flex justify-content-center align-items-center"
                            onClick={() => {
                              if (isProfileRoute) return;
                              if (!isAuthenticated) {
                                setPendingAction(() => () => setShowEnquiry(true));
                                setShowLoginModal(true);
                              } else {
                                setShowEnquiry(true);
                              }
                            }}
                            disabled={isProfileRoute}
                          >
                            Send Enquiry
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Container fluid className="p-0 m-0 mb-3 interotabssection">
            <Row className="">
              <Col xs={12} xl={8}>
                <div className="my-3 interotabspart">
                  {visibleTabs.length > 0 && (
                    <div
                      ref={tabsRef}
                      className="d-flex border-bottom bg-white"
                      style={{
                        borderBottom: "1px solid #DADCE0",
                        position: "sticky",
                        top: "70px",
                        zIndex: "999",
                      }}
                    >
                      {visibleTabs
                        .filter((tab) => tab.key !== "reviews-and-ratings" || window.innerWidth < 1200)
                        .map(({ key, label }) => (
                          <button
                            key={key}
                            className="border-0 bg-transparent px-sm-3 py-2 position-relative"
                            style={{
                              fontFamily: "Inter",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "18px",
                              textAlign: "center",
                              color: activeTab === key ? "#C53D3D" : "#80868B",
                              borderBottom: "2px solid transparent",
                            }}
                            onClick={() => handleTabClick(key)}
                          >
                            {label}
                            {activeTab === key && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "3px",
                                  background: "#C53D3D",
                                }}
                              />
                            )}
                          </button>
                        ))}
                    </div>
                  )}

                  {(brandDetails?.brand_image || brandDetails?.brand_thoughts) && (
                    <div ref={aboutUsRef} className="mt-3">
                      <Row style={{ border: "1px solid #D1D1D1", borderRadius: "6px", margin: "0" }}>
                        <Col xs={12} md={2} className="mb-4 mb-md-0 px-0 w-100">
                          <div style={{ height: "418px" }}>
                            {/\.(mp4|webm|ogg)$/i.test(brandDetails?.brand_image) ? (
                              <video
                                src={brandDetails?.brand_image}
                                className="img-fluid"
                                style={{ width: "100%", height: "100%", borderRadius: "6px" }}
                                controls
                                autoPlay
                                muted
                              />
                            ) : (
                              <img
                                src={brandDetails?.brand_image || userImg}
                                alt="Brand Media"
                                className="img-fluid"
                                style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "6px" }}
                              />
                            )}
                          </div>
                        </Col>
                        <Col xs={12} md={10} className="py-2 text-start d-flex flex-column justify-content-center">
                          <div className="body-text1 inter-400 text-gray-600">
                            {brandDetails?.brand_thoughts || "No description available."}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {brandRelatedInspirations?.length > 0 && (
                    <div ref={projectsRef} className="pt-3 mt-4 pb-1">
                      <h3 className="inter-600 mb-1 pt-4">
                        Inspirations Created ({brandRelatedInspirations?.length})
                      </h3>
                      {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "200px" }}>
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        <Row>
                          {brandRelatedInspirations.map((item, index) => (
                            <Col key={index} lg={6} md={6} sm={12} className="py-2 mb-2">
                              <ProjectCard
                                {...item}
                                id={item.id}
                                project_name={item?.project_name}
                                roomId={item?.rooms?.[0]?.id}
                                // architect = {}
                                zIndex = { 99 }
                                brand={brandDetails?.company_name}
                              />
                            </Col>
                          ))}
                        </Row>
                      )}
                      {next && (
                        <div className="text-center mt-4">
                          <Button onClick={handleLoadmore} disabled={loading || isProfileRoute}>
                            {loading ? "Loading..." : "Load More"}
                          </Button>
                        </div>
                      )}
                    </div>
                  )}

                  {taggedInspirations?.length > 0 && (
                    <div ref={taggedInspirationsRef} className="pt-3 mt-4 pb-3">
                      <h3 className="inter-600 mb-1 pt-4">
                        Tagged Inspirations ({taggedInspirations?.length})
                      </h3>
                      {taggedInspirationsLoading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "200px" }}>
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        <Row>
                          {taggedInspirations.map((item, index) => (
                            <Col key={index} lg={6} md={6} sm={12} className="py-2 mb-2">
                              <ProjectCard
                                {...item}
                                id={item?.id}
                                project_name={item?.project_name}
                                roomId={item?.rooms?.[0]?.id}
                                architect={null}
                                zIndex = { 99 }
                                brand={item?.brand_name}
                              />
                            </Col>
                          ))}
                        </Row>
                      )}
                      {taggedInspirationsNext && (
                        <div className="text-center mt-4">
                          <Button onClick={handleLoadmoreTaggedInspirations} disabled={taggedInspirationsLoading || isProfileRoute}>
                            {taggedInspirationsLoading ? "Loading..." : "Load More"}
                          </Button>
                        </div>
                      )}
                    </div>
                  )}

                  {teamMembers?.length > 0 && (
                    <div ref={teamRef} className="mb-4 pt-4">
                      <div className="h4 inter-600">Meet the Team!</div>
                      <div className="py-3">
                        <div className="row">
                          {teamMembers.map((teamMember, index) => (
                            <div key={index} className="col-5 col-sm-6 col-md-2 mb-3">
                              <TeamMember {...teamMember} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                  {media?.length > 0 && (
                    <div ref={galleryRef} className="mb-4">
                      <div className="h4 inter-600 pb-2">Get to Know Us!</div>
                      <Gallery items={media} fetchGalleries={() => fetchGalleries(userBrandId)} />
                    </div>
                  )}
                </div>
              </Col>

              <Col xs={12} xl={4} className="pt-5">
                <div
                  ref={reviewsRef}
                  className="pt-4 pb-3 border reviewpart"
                  style={{ borderColor: "#E8EAED", borderWidth: "1px", position: "sticky", top: "110px" }}
                >
                  <div className="h4 inter-600 text-center mb-4" style={{ color: "#1A1C1F" }}>
                    <span className="text-danger">{reviews?.length ?? 0}</span> Reviews and Ratings
                  </div>
                  <div className="h4 inter-600 text-center text-danger d-flex justify-content-center align-items-center gap-2">
                    <FaStar className="text-danger" />
                    {reviews?.length > 0
                      ? (reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length).toFixed(1)
                      : 0}
                  </div>
                  <div className="h6 inter-600 text-center pt-1 pb-0" style={{ color: "#5F6368" }}>
                    Average Rating
                  </div>
                  <div className="w-100 my-3 d-flex justify-content-center">
                    <Button className="text-white" onClick={handleShow} disabled={isProfileRoute}>
                      Add Your Review
                    </Button>
                  </div>

                  <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                      <Modal.Title className="h3 inter-600 text-center w-100">Add Review</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="d-flex justify-content-center mb-3">
                        <div style={{ transform: "scale(0.85)" }}>
                          <RatingStarsView rating={rating} setRating={setRating} />
                        </div>
                      </div>
                      <Form ref={form} onSubmit={submitReview}>
                        <Form.Group>
                          <Form.Control
                            name="review"
                            as="textarea"
                            rows={5}
                            placeholder="Give your feedback for brand.."
                            style={{ resize: "none" }}
                            defaultValue={pendingReview.text}
                          />
                        </Form.Group>
                        <div className="w-100 my-3 d-flex justify-content-center">
                          <Button className="text-white" type="submit" disabled={isSubmittingReview} style={{ fontSize: "0.875rem", padding: "6px 12px" }}>
                            {isSubmittingReview ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className="me-1"
                                />
                                {" "}Submitting...
                              </>
                            ) : (
                              "Submit Review"
                            )}
                          </Button>
                        </div>
                      </Form>
                    </Modal.Body>
                  </Modal>

                  {(showAll ? reviews : reviews.slice(0, 2)).length > 0 ? (
                    (showAll ? reviews : reviews.slice(0, 2)).map((review, index) => <Review key={index} {...review} for_user={brandDetails?.user_data?.id}
                      onReviewUpdate={() => fetchReviews(brandDetails?.user_data?.id)} />)
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                      style={{ height: 160 }}
                    >
                      No reviews available
                    </div>
                  )}

                  {reviews?.length > 2 && !showAll && (
                    <div className="text-center mt-3">
                      <button
                        className="btn"
                        onClick={() => setShowAll(true)}
                        style={{ color: "#C53D3D", border: "none", textDecoration: "underline" }}
                      >
                        See All
                      </button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <BrandEnquiryForm brandId={brandDetails?.id} show={showEnquiry} onHide={() => setShowEnquiry(false)} />
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={brandDetails?.id}
        image={brandDetails?.image}
        title={brandDetails?.company_name}
        urlPath={isProfileRoute ? "/brand/account/profile" : slug !== "undefined" ? `/brands/${slug}` : null}
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
        onLoginSuccess={handleLoginSuccess}
        currentPathname={location.pathname}

      />
      <BrochureModal
        brandDetails={brandDetails}
        showBrochureModal={showBrochureModal}
        setShowBrochureModal={setShowBrochureModal}
        fileUrl={brochureFileUrl} // Pass the file URL
        fileName={brochureFileName} // Pass the file name with correct extension
      />
    </Container>
  );
};

export default BrandDetails;