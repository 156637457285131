import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import { IoArrowBack } from "react-icons/io5";
import Review from "components/Review/Review";
import ProjectCard from "components/Cards/ProjectCard";
import TeamMember from "components/TeamMember/TeamMember";
import Gallery from "components/Gallery/Gallery";
import userImg from "../../assets/navbar/user.webp";
import ArchitectImage from "../../assets/architech.png";
import * as authActions from "reduxFiles/actions/authActions";
import { useMediaQuery } from "react-responsive";
import useVideoPlayer from "utilities/customHooks/useVideoPlayer";

const ProfilePreview = ({ user, onBack }) => {
    // Media query
    const small = useMediaQuery({ query: "(min-width: 576px)" });

    // Refs
    const aboutUsRef = useRef(null);
    const teamRef = useRef(null);
    const galleryRef = useRef(null);
    const projectsRef = useRef(null);
    const taggedInspirationsRef = useRef(null);
    const reviewsRef = useRef(null);

    // States
    const [loading, setLoading] = useState(true);
    const [professionalDetails, setProfessionalDetails] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [media, setMedia] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [activeTab, setActiveTab] = useState(null); // Start with no active tab
    const [showAll, setShowAll] = useState(false);
    const [isManualTabChange, setIsManualTabChange] = useState(false);
    
    // Brand-specific states
    const [brandRelatedInspirations, setBrandRelatedInspirations] = useState([]);
    const [taggedInspirations, setTaggedInspirations] = useState([]);
    const [next, setNext] = useState(null);
    const [taggedInspirationsNext, setTaggedInspirationsNext] = useState(null);
    const [taggedInspirationsLoading, setTaggedInspirationsLoading] = useState(false);

    // Use custom video player hook
    const { registerVideoRef, handleVideoPlay } = useVideoPlayer();

    // Define derived properties based on state
    const hasAboutUs = (
        (professionalDetails?.architect_thoughts && professionalDetails?.architect_image) ||
        (professionalDetails?.brand_thoughts && professionalDetails?.brand_image)
    );
    const aboutImage = professionalDetails?.architect_image || professionalDetails?.brand_image;
    const aboutThoughts = professionalDetails?.architect_thoughts || professionalDetails?.brand_thoughts;
    const hasTeam = teamMembers?.length > 0;
    const hasGallery = media?.length > 0;
    const isBrandUser = user?.user_type === "brand";

    // Function to fetch professional details
    useEffect(() => {
        const fetchDetails = async () => {
            setLoading(true);
            try {
                // Fetch thoughts based on user type
                const response = user?.user_type === "brand"
                    ? await authActions.fetchBrandsThought(user.id)
                    : await authActions.fetchThoughts(user.id);

                if (response?.data?.results && response?.data?.results[0]) {
                    setProfessionalDetails(response.data.results[0]);
                } else {
                    // If no specific details found, create a minimal object using user data
                    setProfessionalDetails({
                        id: user.id,
                        company_name: user.company_name,
                        architect_thoughts: "No details available yet. Please complete your profile information.",
                        user: user.id
                    });
                }
            } catch (error) {
                console.error("Error fetching professional details:", error);
                // Create a fallback object to avoid null errors
                setProfessionalDetails({
                    id: user.id,
                    company_name: user.company_name,
                    architect_thoughts: "Unable to load profile details.",
                    user: user.id
                });
            }
            setLoading(false);
        };

        fetchDetails();
    }, [user]);

    // Fetch team members
    useEffect(() => {
        const fetchTeamData = async () => {
            try {
                const response = await authActions.fetchTeamMembers({ user_id: user.id });
                if (response) setTeamMembers(response.data);
            } catch (error) {
                console.error("Error fetching team members:", error);
            }
        };

        if (professionalDetails) {
            fetchTeamData();
        }
    }, [professionalDetails, user.id]);

    // Fetch gallery media
    useEffect(() => {
        const fetchGalleryData = async () => {
            try {
                const response = await authActions.fetchGallery({ user_id: user.id });
                if (response) setMedia(response.data);
            } catch (error) {
                console.error("Error fetching gallery:", error);
            }
        };

        if (professionalDetails) {
            fetchGalleryData();
        }
    }, [professionalDetails, user.id]);

    // Fetch reviews
    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await authActions.fetchReviewsByArchitectUser(user.id);
                if (response?.data?.results) {
                    setReviews(response.data.results);
                }
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        };

        if (professionalDetails) {
            fetchReviews();
        }
    }, [professionalDetails, user.id]);

    // Function to fetch projects by brand ID
    const fetchProjectsByBrandId = async (brandId, sortBy = "", status = "") => {
        setLoading(true);
        try {
            // Using the correct API call format as shown in BrandDetails
            const response = await authActions.fetchProjectsByBrand(brandId, sortBy, status);
            if (response?.data) {
                setBrandRelatedInspirations(response.data.results || []);
                setNext(response.data.next || null);
            } else {
                console.error("Error while loading project details");
            }
        } catch (error) {
            console.log("fetchProjectsByBrand error:", error);
        } finally {
            setLoading(false);
        }
    };

    // Function to load more projects
    const loadProjectsByBrand = async (nextPage) => {
        try {
            const response = await authActions.loadProjects(nextPage);
            setNext(response?.data?.next || null);
            if (Array.isArray(response?.data?.results)) {
                setBrandRelatedInspirations((prev) => [...prev, ...response.data.results]);
            }
        } catch (error) {
            console.log("loadProjectsByBrand error:", error);
        }
    };

    // Function to fetch tagged inspirations
    const fetchTaggedInspirations = async (brandId) => {
        setTaggedInspirationsLoading(true);
        try {
            // Using the correct API call format as shown in BrandDetails with brand_id parameter
            const response = await authActions.fetchTaggedProjectsByBrand(brandId);
            if (response?.data) {
                // Process the data according to the example from BrandDetails
                const inspirationList = response.data.results.map((item) => item.project_data);
                console.log("Tagged Inspirations:", inspirationList);
                setTaggedInspirations(inspirationList || []);
                setTaggedInspirationsNext(response.data.next || null);
            } else {
                console.error("Error while loading tagged inspirations");
            }
        } catch (error) {
            console.log("fetchTaggedInspirationsByBrand error:", error);
        } finally {
            setTaggedInspirationsLoading(false);
        }
    };

    // Function to load more tagged inspirations
    const loadMoreTaggedInspirations = async (nextPage) => {
        try {
            const response = await authActions.loadProjects(nextPage);
            setTaggedInspirationsNext(response?.data?.next || null);
            if (Array.isArray(response?.data?.results)) {
                const newInspirations = response.data.results.map((item) => item.project_data);
                setTaggedInspirations((prev) => [...prev, ...newInspirations]);
            }
        } catch (error) {
            console.log("loadMoreTaggedInspirations error:", error);
        }
    };

    // Fetch brand-related projects for brand users
    useEffect(() => {
        const fetchProjectsData = async () => {
            if (isBrandUser && professionalDetails?.id) {
                try {
                    // Use the brand ID (professionalDetails.id) instead of user.id
                    await fetchProjectsByBrandId(professionalDetails.id);
                } catch (error) {
                    console.error("Error fetching brand projects:", error);
                }
            }
        };

        if (professionalDetails) {
            fetchProjectsData();
        }
    }, [professionalDetails, isBrandUser]);

    // Fetch tagged inspirations for brand users
    useEffect(() => {
        const fetchTaggedInspirationsData = async () => {
            if (isBrandUser && professionalDetails?.id) {
                try {
                    // Use the brand ID (professionalDetails.id) instead of user.id
                    await fetchTaggedInspirations(professionalDetails.id);
                } catch (error) {
                    console.error("Error fetching tagged inspirations:", error);
                }
            }
        };

        if (professionalDetails) {
            fetchTaggedInspirationsData();
        }
    }, [professionalDetails, isBrandUser]);

    // Define all tabs configuration
    const tabsData = [
        {
            key: "about-us",
            label: "About Us",
            ref: aboutUsRef,
            show: hasAboutUs
        },
        {
            key: "properties-created",
            label: `Inspirations Created (${brandRelatedInspirations?.length})`,
            ref: projectsRef,
            show: isBrandUser
        },
        {
            key: "tagged-inspirations",
            label: `Tagged Inspirations (${taggedInspirations?.length})`,
            ref: taggedInspirationsRef,
            show: isBrandUser
        },
        {
            key: "team",
            label: "Team",
            ref: teamRef,
            show: hasTeam
        },
        {
            key: "gallery",
            label: "Gallery",
            ref: galleryRef,
            show: hasGallery
        }
        // Removed Reviews and Ratings tab as requested
    ];

    // Get available tabs based on what should be shown
    const availableTabs = tabsData.filter(tab => tab.show).map(tab => tab.key);

    // Handle tab click - Scroll to the section
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setIsManualTabChange(true); // Set flag to prevent scroll event from changing tab
        
        const tabItem = tabsData.find(item => item.key === tab);
        if (tabItem?.ref?.current) {
            window.scrollTo({
                top: tabItem.ref.current.offsetTop - 100,
                behavior: "smooth",
            });
            
            // Reset the flag after scrolling completes
            setTimeout(() => {
                setIsManualTabChange(false);
            }, 1000); // 1 second should be enough for the scroll to complete
        }
    };

    // Set default tab if current active tab is not available - removed as we want no tab active by default
    // useEffect(() => {
    //    if (availableTabs.length > 0 && !availableTabs.includes(activeTab)) {
    //        setActiveTab(availableTabs[0]);
    //    }
    // }, [availableTabs, activeTab]);

    // Update active tab on scroll - improved version
    useEffect(() => {
        const handleScroll = () => {
            // Skip if the tab change was triggered by a manual click
            if (isManualTabChange) return;
            
            const scrollPosition = window.scrollY + 150; // Add offset for header and tabs
            
            // Find the section that is currently in view
            let currentSectionIndex = -1;
            
            // Get all visible section refs and their top positions
            const visibleSections = tabsData
                .filter(tab => tab.show && tab.ref.current)
                .map(tab => ({
                    key: tab.key,
                    top: tab.ref.current.offsetTop
                }))
                .sort((a, b) => a.top - b.top); // Sort by position top to bottom
            
            // Find the last section that starts before the current scroll position
            for (let i = 0; i < visibleSections.length; i++) {
                if (scrollPosition >= visibleSections[i].top) {
                    currentSectionIndex = i;
                } else {
                    break;
                }
            }
            
            // Only update if we found a section and it's different from current active tab
            if (currentSectionIndex !== -1 && visibleSections[currentSectionIndex]?.key !== activeTab) {
                setActiveTab(visibleSections[currentSectionIndex].key);
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [tabsData, activeTab, isManualTabChange]);

    if (loading) {
        return (
            <Container fluid className="mt-5 pt-4 px-0">
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "70vh" }}>
                    <Spinner animation="border" variant="primary" />
                </div>
            </Container>
        );
    }

    return (
        <Container fluid className="mt-5 pt-4 px-0">
            {/* Header with back button */}
            <Container className="mb-4">
                <div className="d-flex justify-content-between align-items-center">
                    <Button
                        variant="outline-secondary"
                        className="d-flex align-items-center gap-2"
                        onClick={onBack}
                    >
                        <IoArrowBack /> Back to Settings
                    </Button>
                    <h3 className="inter-600 text-gray-900 mb-0">Profile Preview</h3>
                </div>
            </Container>

            {/* Banner Image */}
            <div>
                <img
                    src={professionalDetails?.architect_banner || ArchitectImage}
                    alt="Profile Banner"
                    className="img-fluid d-block w-100"
                    style={{
                        objectFit: "contain",
                    }}
                />
            </div>

            <Container className="pb-4">
                <div className="d-flex justify-content-center bg-gray-25 geist w-100 m-0 py-3 px-xl-3 px-0 titletextbar mt-4">
                    <Col lg={2} xl={2} className="p-0">
                        <div
                            className="d-flex justify-content-start align-items-start"
                            style={{ height: "100%" }}
                        >
                            <div className="d-flex justify-content-start align-items-start bg-white rounded overflow-hidden titleimg">
                                <img
                                    className="border img-fluid w-100 h-100"
                                    style={{
                                        border: "1px solid #D0D5DD",
                                        borderRadius: "12px",
                                        objectFit: "contain",
                                    }}
                                    src={
                                        user?.profile_picture || userImg
                                    }
                                    alt="Profile"
                                />
                            </div>
                        </div>
                    </Col>

                    <Col lg={10} xl={10}>
                        <div className="titletextbtnbox w-100">
                            <div className="titletext">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="text-uppercase fw-semibold fs-4 lh-sm text-dark">
                                        {professionalDetails?.company_name || user?.company_name || "Your Company Name"}
                                    </div>
                                    <div
                                        className="d-flex align-items-center bg-danger text-white gap-1 rounded-1 px-2 py-1"
                                        style={{ height: "30px" }}
                                    >
                                        <FaStar style={{ width: "16px", height: "16px" }} />
                                        <span className="fs-6">
                                            {reviews?.length > 0
                                                ? (
                                                    reviews.reduce(
                                                        (acc, review) => acc + review.rating,
                                                        0
                                                    ) / reviews.length
                                                ).toFixed(1)
                                                : 0}
                                        </span>
                                    </div>
                                </div>
                                <div className="body-text inter-500 fs-6 mt-2">
                                    {user?.user_subtype_data?.title || user?.user_type}
                                </div>

                                <div>
                                    {[
                                        user?.city,
                                        user?.state,
                                        user?.country,
                                    ]
                                        .filter(Boolean)
                                        .join(", ") || "Location N/A"}{" "}
                                    {user?.pincode ? `- ${user.pincode}` : ""}
                                </div>

                                <div className="d-flex gap-3 mt-3 flex-wrap">
                                    <div
                                        className="d-flex align-items-center p-2 border"
                                        style={{
                                            backgroundColor: "#F1F3F4",
                                            color: "#2E3134",
                                        }}
                                    >
                                        {professionalDetails?.delivered_projects || 0} Project Done
                                    </div>
                                    {professionalDetails?.expertise_type && (
                                        <div
                                            className="d-flex align-items-center p-2 border"
                                            style={{
                                                backgroundColor: "#F1F3F4",
                                                color: "#2E3134",
                                            }}
                                        >
                                            Expertise: {professionalDetails?.expertise_type}
                                        </div>
                                    )}
                                    {professionalDetails?.interest_of_service === "turnkey" && (
                                        <div
                                            className="d-flex align-items-center p-2 border"
                                            style={{
                                                backgroundColor: "#F1F3F4",
                                                color: "#2E3134",
                                            }}
                                        >
                                            Turnkey: ₹ {professionalDetails?.job_cost || 0} per sq. ft.
                                        </div>
                                    )}
                                    {professionalDetails?.interest_of_service === "design" && (
                                        <div
                                            className="d-flex align-items-center p-2 border"
                                            style={{
                                                backgroundColor: "#F1F3F4",
                                                color: "#2E3134",
                                            }}
                                        >
                                            Only Design: ₹ {professionalDetails?.job_cost || 0} per sq. ft.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </div>

                <Container fluid className="p-0 m-0 interotabssection">
                    <Row className="gx-5">
                        {/* Left Side: Content with tabs */}
                        <Col xs={12} xl={8} className="mt-3">
                            {/* Navigation tabs - shown if any tabs are available */}
                            {availableTabs.length > 0 && (
                                <div
                                    className="d-flex border-bottom overflow-auto"
                                    style={{
                                        borderBottom: "1px solid #DADCE0",
                                        background: "#fff",
                                        position: "sticky",
                                        zIndex: "1000", // Increased z-index to ensure it stays above project cards
                                        top: "70px",
                                    }}
                                >
                                    {tabsData.filter(tab => tab.show).map((tab) => (
                                        <button
                                            key={tab.key}
                                            className={`border-0 bg-transparent px-sm-3 py-2 position-relative ${activeTab === tab.key ? "active-tab" : ""}`}
                                            style={{
                                                fontFamily: "Inter",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                lineHeight: "18px",
                                                textAlign: "center",
                                                color: activeTab === tab.key ? "#C53D3D" : "#80868B",
                                                whiteSpace: "nowrap",
                                            }}
                                            onClick={() => handleTabClick(tab.key)}
                                        >
                                            {tab.label}
                                            {activeTab === tab.key && (
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        bottom: "-1px",
                                                        left: "0",
                                                        width: "100%",
                                                        height: "3px",
                                                        background: "#C53D3D",
                                                    }}
                                                />
                                            )}
                                        </button>
                                    ))}
                                </div>
                            )}

                            {/* All sections are visible and stacked one below another */}
                            
                            {/* About Us Section */}
                            {hasAboutUs && (
                                <div ref={aboutUsRef} id="about-us-section" className="mb-4 pt-4">
                                    <div className="h4 inter-600 mb-3">About Us</div>
                                    <Row className="border rounded-2 m-0 overflow-hidden">
                                        <Col xs={12} className="px-0">
                                            {aboutImage && /\.(mp4|webm|ogg)$/i.test(aboutImage) ? (
                                                <video
                                                    ref={(el) => {
                                                        // Register this video with our hook
                                                        if (el) registerVideoRef('about-us-video', el);
                                                    }}
                                                    src={aboutImage}
                                                    className="img-fluid w-100"
                                                    style={{
                                                        objectFit: "cover",
                                                        borderRadius: "6px 6px 0 0",
                                                        height: "418px",
                                                    }}
                                                    controls
                                                    onPlay={() => handleVideoPlay('about-us-video')}
                                                    muted
                                                />
                                            ) : (
                                                <img
                                                    src={aboutImage || "/default-image.jpg"}
                                                    alt="About Us"
                                                    className="img-fluid w-100"
                                                    style={{
                                                        objectFit: "cover",
                                                        borderRadius: "6px 6px 0 0",
                                                        height: "418px",
                                                    }}
                                                />
                                            )}
                                        </Col>
                                        <Col
                                            xs={12}
                                            className="py-3 text-start d-flex flex-column justify-content-center"
                                        >
                                            <div className="ms-3">
                                                <div className="body-text1 inter-400 text-gray-600">
                                                    {aboutThoughts ||
                                                        "Collaborating with Interiofy has been an inspiring journey..."}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )}

                            {/* Properties Created Section (only shown for Brand users) */}
                            {isBrandUser && (
                                <div ref={projectsRef} id="properties-created-section" className="mb-4 pt-4">
                                    <div className="h4 inter-600 mb-3">Inspirations Created</div>
                                    {loading ? (
                                        <div className="d-flex justify-content-center my-4">
                                            <Spinner animation="border" variant="primary" />
                                        </div>
                                    ) : brandRelatedInspirations?.length > 0 ? (
                                        <div className="row">
                                            {brandRelatedInspirations.map((project, index) => (
                                                <div key={index} className="col-12 col-md-6 mb-4">
                                                    <ProjectCard
                                                        project={project}
                                                        videoRefCallback={(element) => {
                                                            registerVideoRef(`project-video-${project.id}`, element);
                                                        }}
                                                        onVideoPlay={() => handleVideoPlay(`project-video-${project.id}`)}
                                                        style={{ zIndex: "1" }} // Ensure project cards have lower z-index
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div
                                            className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                                            style={{ height: 160 }}
                                        >
                                            No inspirations created yet
                                        </div>
                                    )}
                                    {next && (
                                        <div className="text-center my-3">
                                            <Button
                                                variant="outline-primary"
                                                onClick={() => loadProjectsByBrand(next)}
                                            >
                                                Load More
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}

                            {/* Tagged Inspirations Section (only shown for Brand users) */}
                            {isBrandUser && (
                                <div ref={taggedInspirationsRef} id="tagged-inspirations-section" className="mb-4 pt-4">
                                    <div className="h4 inter-600 mb-3">Tagged Inspirations</div>
                                    {taggedInspirationsLoading ? (
                                        <div className="d-flex justify-content-center my-4">
                                            <Spinner animation="border" variant="primary" />
                                        </div>
                                    ) : taggedInspirations?.length > 0 ? (
                                        <div className="row">
                                            {taggedInspirations.map((project, index) => (
                                                <div key={index} className="col-12 col-md-6 mb-4">
                                                    <ProjectCard
                                                        project={project}
                                                        videoRefCallback={(element) => {
                                                            registerVideoRef(`tagged-video-${project.id}`, element);
                                                        }}
                                                        onVideoPlay={() => handleVideoPlay(`tagged-video-${project.id}`)}
                                                        style={{ zIndex: "1" }} // Ensure project cards have lower z-index
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div
                                            className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                                            style={{ height: 160 }}
                                        >
                                            No tagged inspirations yet
                                        </div>
                                    )}
                                    {taggedInspirationsNext && (
                                        <div className="text-center my-3">
                                            <Button
                                                variant="outline-primary"
                                                onClick={() => loadMoreTaggedInspirations(taggedInspirationsNext)}
                                            >
                                                Load More
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}

                            {/* Team Section */}
                            {hasTeam && (
                                <div ref={teamRef} id="team-section" className="mb-4 pt-4">
                                    <div className="h4 inter-600 mb-3">Meet the Team!</div>
                                    <div className="py-3">
                                        {teamMembers?.length > 0 ? (
                                            <div className="row">
                                                {teamMembers.map((teamMember, index) => (
                                                    <div
                                                        key={index}
                                                        className="col-5 col-sm-6 col-md-2 mb-3"
                                                    >
                                                        {/* Pass an empty user prop to hide edit/delete functions */}
                                                        <TeamMember
                                                            {...teamMember}
                                                            user={null}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div
                                                className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                                                style={{ height: 160 }}
                                            >
                                                No Team Members available
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Gallery Section */}
                            {hasGallery && (
                                <div ref={galleryRef} id="gallery-section" className="mb-4 pt-4">
                                    <div className="h4 inter-600 mb-3">Get to Know Us!</div>
                                    <Gallery
                                        items={media}
                                        enableEdit={false}
                                        videoRefCallback={(id, element) => {
                                            registerVideoRef(`gallery-video-${id}`, element);
                                        }}
                                        onVideoPlay={(id) => handleVideoPlay(`gallery-video-${id}`)}
                                    />
                                </div>
                            )}

                            {/* Reviews section is kept in the right column but removed from the tabs */}
                        </Col>

                        {/* Right Side: Reviews and Ratings */}
                        <Col xs={12} xl={4} className="pt-5">
                            <Container
                                className="pt-4 pb-3 border reviewpart"
                                style={{
                                    borderColor: "#E8EAED",
                                    borderWidth: "1px",
                                    position: "sticky",
                                    top: "110px",
                                }}
                            >
                                {/* Review Count & Title */}
                                <div
                                    className="h4 inter-600 text-center mb-4"
                                    style={{ color: "#1A1C1F" }}
                                >
                                    <span className="text-danger">
                                        {reviews?.length ?? 0}
                                    </span>{" "}
                                    Reviews and Ratings
                                </div>

                                {/* Average Rating */}
                                <div className="h4 inter-600 text-center text-danger d-flex justify-content-center align-items-center gap-2">
                                    <FaStar className="text-danger" />
                                    {reviews?.length > 0
                                        ? (
                                            reviews.reduce(
                                                (acc, review) => acc + review.rating,
                                                0
                                            ) / reviews.length
                                        ).toFixed(1)
                                        : 0}
                                </div>

                                <div
                                    className="h6 inter-600 text-center pt-1 pb-1"
                                    style={{ color: "#5F6368" }}
                                >
                                    Average Rating
                                </div>

                                {/* Add Review Button (disabled in preview) */}
                                <div className="w-100 my-3 d-flex justify-content-center">
                                    <Button className="text-white" disabled>
                                        Add Your Review
                                    </Button>
                                </div>

                                {/* Display Reviews */}
                                {(showAll ? reviews : reviews.slice(0, 2)).length > 0 ? (
                                    (showAll ? reviews : reviews.slice(0, 2)).map(
                                        (review, index) => <Review key={index} {...review} />
                                    )
                                ) : (
                                    <div
                                        className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                                        style={{ height: 160 }}
                                    >
                                        No reviews available
                                    </div>
                                )}

                                {/* "See All" Button */}
                                {reviews?.length > 2 && !showAll && (
                                    <div className="text-center mt-3">
                                        <button
                                            className="btn"
                                            onClick={() => setShowAll(true)}
                                            style={{
                                                color: "#C53D3D",
                                                border: "none",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            See All
                                        </button>
                                    </div>
                                )}
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Container>
    );
};

export default ProfilePreview;