import React, { useState } from "react";
import { Button, Card, Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { choose_inspiration, book_consultation, give_requirements, execute_project } from "assets";
import { Breakpoint } from "react-socks";

const HowITWorksCard = ({ step, hoveredCard, setHoveredCard }) => {
    const medium = useMediaQuery({ query: "(min-width: 768px)" });

    return (
        <Card
            className={`shadow-none mb-3 bg-white d-flex justify-content-center 
                ${hoveredCard === step.id ? "border-gray-300" : "border-gray-100"} 
                ${medium ? "mx-2 py-3 align-items-center" : "mx-0 align-items-start"}
                `}
            style={medium ?
                { maxWidth: "358px", borderRadius: 6 }
                :
                { width: "100%", borderRadius: 6 }
            }
            onMouseEnter={() => { hoveredCard >= 0 && setHoveredCard(step.id) }}
            onMouseLeave={() => { hoveredCard >= 0 && setHoveredCard(0) }}
        >
            <div className="body-title inter-600">Step {step.id + ": " + step.title}</div>
            <div className={`body-text2 text-gray-600 inter-400 mt-1 ${medium ? "mx-2" : "mx-0"}`}>{step.description}</div>
            <Image className={`mt-3 ${medium ? "px-2" : "px-0"} w-100 border-top`} src={step.image} alt={step.title} />
        </Card>
    )
}

const HowItWorks = () => {

    const navigate = useNavigate();

    const [activeCard, setActiveCard] = useState(1);
    const [hoveredCard, setHoveredCard] = useState(1);

    const stepsToWork = [
        {
            id: 1,
            title: "Select Inspiration",
            image: choose_inspiration,
            description: "Check our inspiration library to find your dream interior ideas"
        },
        {
            id: 2,
            title: "Book Consultation",
            image: book_consultation,
            description: "Select your service type and book a free consultation"
        },
        {
            id: 3,
            title: "Give Requirements",
            image: give_requirements,
            description: "Connect with designers and share your requirements. "
        },
        {
            id: 4,
            title: "Execution or Only Drawings",
            image: execute_project,
            // description: "You will be given final files or we will execute project as per your request",
            description: "Obtain final files or fully executed project as per request"
        },
    ]

    return (
        <Container fluid className="bg-gray-25 py-5">
            <Container className="d-flex justify-content-center align-items-center">
                <div className="text-center w-100">
                    <div className="h2 inter-600">How it Works!</div>
                    <Breakpoint large up>
                        <div className={`my-4 d-flex`}>
                            {stepsToWork.map((step) => {
                                return (
                                    <HowITWorksCard step={step} hoveredCard={hoveredCard} setHoveredCard={setHoveredCard} />
                                )
                            })}
                        </div>
                    </Breakpoint>
                    <Breakpoint medium down>
                        <div className="d-flex flex-column w-100 my-4">
                            <div>
                                {activeCard > 0 &&
                                    <HowITWorksCard step={stepsToWork[activeCard - 1]} />
                                }
                            </div>
                            <div className="d-flex">
                                {stepsToWork.map((step) => {
                                    return (
                                        <Card
                                            className={`w-100 shadow-none cursor-pointer body-title 
                                                ${step.id === 4 ? "" : "me-2"} 
                                                ${activeCard === step.id ? "border-gray-300" : "border-gray-100"}
                                                ${hoveredCard === step.id ? "border-gray-200" : ""}
                                                `}
                                            style={{ borderRadius: 3 }}
                                            onClick={() => setActiveCard(step.id)}
                                            onMouseEnter={() => setHoveredCard(step.id)}
                                            onMouseLeave={() => setHoveredCard(0)}
                                        >
                                            <span className={`${hoveredCard === step.id ? "text-primary-500" : "text-gray-900"}`}>Step {step.id} </span>
                                        </Card>
                                    )
                                })}
                            </div>
                        </div>
                    </Breakpoint>
                    <Button
                        className="body-text2 inter-600 text-white py-2 px-4"
                        style={{ borderRadius: 3 }}
                        onClick={() => navigate("/consultation")}
                    >
                        Get Started with InterioKeys!
                    </Button>
                </div>
            </Container>
            {/* <Container className="d-flex justify-content-center align-items-center">
                <div className="text-center w-100">
                    <div className="h2 inter-600">How it Works!</div>
                    <div className={`my-5 d-flex ${medium ? "" : "flex-column align-items-center"}`}>
                        {stepsToWork.map((step) => {
                            return (
                                <Card
                                    className="border shadow-none py-3 mx-2 mb-3 bg-transparent d-flex justify-content-center align-items-center"
                                    style={medium ? { maxWidth: "358px" } : { width: "100%" }}
                                >
                                    <div className="body-text2 inter-600">{step.id + ". " + step.title}</div>
                                    <Image className="my-3" src={step.image} alt={step.title} style={{ maxWidth: 120, maxHeight: 120 }} />
                                    <div className="body-text2 text-gray-800 inter-400">{step.description}</div>
                                </Card>
                            )
                        })}
                    </div>
                    <Button
                        className="body-title inter-600 text-white py-2 px-4"
                        style={{ borderRadius: 3 }}
                        onClick={() => navigate("/consultation")}
                    >
                        Get Started with Interiokeys!
                    </Button>
                </div>
            </Container> */}
        </Container>
    )
}

export default HowItWorks