import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { LuEye, LuEyeOff, LuMail } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtpAndLogin } from "reduxFiles/reducer/authSlices";
import * as authActions from "reduxFiles/actions/authActions";
import { useMediaQuery } from "react-responsive";
import { notify } from "utilities/notifications/Notify";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import FacebookGoogleLogin from "components/Auth/FacebookGoogleLogin";
import { validateEmail } from "utilities/validateEmail";
import {
  resetConsultation,
  setEmail,
  setFullName,
  setMobile,
  setRequirementsMsg,
} from "reduxFiles/reducer/consultationSlice";

const BookConsultationForm = ({
  form,
  // loading,
  showLoginForm,
  setShowLoginForm,
  otpInput,
  handleSignUp,
  handleSubmitRequest,
  handleLogin,
}) => {
  const dispatch = useDispatch();
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  // const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);
  const reviewForm = useRef();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { fullName, mobile, email, requirementsMsg } = useSelector(
    (state) => state.consultaion
  );
  const [otp, setOtp] = useState("");
  const [resendTimer, setResendTimer] = useState(120);
  const [loading, setLoading] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [otpSent, setOtpSent] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);

  const handleFullNameChange = (e) => {
    dispatch(setFullName(e.target.value));
  };

  const handleEmailChange = (e) => {
    dispatch(setEmail(e.target.value));
  };

  const handleMobileChange = (e) => {
    dispatch(setMobile(e.target.value));
  };

  const handleRequirementsMsgChange = (e) => {
    dispatch(setRequirementsMsg(e.target.value));
  };

  // const handleSendOtp = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   const formData = new FormData(form.current);
  //   const email = formData.get("email");
  //   const fullName = formData.get("full_name");
  //   const phone = formData.get("mobile");

  //   if (validateEmail(email)) {
  //     try {
  //       const response = await authActions.signup({
  //         email,
  //         full_name: fullName,
  //         mobile: phone,
  //       });
  //       if (response?.status === "SUCCESSFUL") {
  //         setOtpSent(true);
  //         setResendTimer(response.time_left); // Reset timer
  //         setIsResendDisabled(true); // Disable resend button
  //         notify("OTP sent successfully!", "success");
  //       } else {
  //         notify(response?.msg || "Failed to send OTP. Try again.", "error");
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       notify("Error while sending OTP. Please try again.", "error");
  //     }
  //   } else {
  //     notify("Please enter a valid email ID!", "error");
  //   }
  //   setLoading(false);
  // };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(form.current);
    const email = formData.get("email");
    const fullName = formData.get("full_name");
    const phone = formData.get("mobile");

    if (validateEmail(email)) {
      try {
        // First check if the user exists
        const checkUserResponse = await authActions.checkUserExists(email);

        if (checkUserResponse.exists) {
          // User exists, send login OTP instead of showing error
          setIsExistingUser(true);
          const loginResponse = await authActions.sendResetOtp({ email: email.toLowerCase(), isHomeOwner: true });

          if (loginResponse.status === "SUCCESSFUL" || loginResponse.data) {
            setOtpSent(true);
            setResendTimer(loginResponse.time_left); // Reset timer to 2 minutes
            setIsResendDisabled(true);
            notify("OTP sent to your email. Please verify to continue.", "success");
          } else {
            notify(loginResponse.msg || "Failed to send OTP. Try again.", "error");
          }
        } else {
          // New user, proceed with signup
          setIsExistingUser(false);
          const response = await authActions.signup({
            email,
            full_name: fullName,
            mobile: phone,
            user_type: "customer"
          });

          if (response.status === "SUCCESSFUL" || response.data) {
            setOtpSent(true);
            setResendTimer(120); // Reset timer to 2 minutes
            setIsResendDisabled(true);
            notify("OTP sent to your email. Please verify to continue.", "success");
          } else {
            notify(response.msg || "Failed to send OTP. Try again.", "error");
          }
        }
      } catch (error) {
        console.error(error);
        notify("Error while processing your request. Please try again.", "error");
      }
    } else {
      notify("Please enter a valid email ID!", "error");
    }
    setLoading(false);
  };

  const verifyOtpLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(form.current);
    const data = {
      username: formData.get("email"),
      email_verified_otp: formData.get("email_verified_otp"),
    };
    try {
      const response = await authActions.verfiyOtpLogin(data);
      if (response.data) {
        if (response.data?.user?.is_active) {
          dispatch(verifyOtpAndLogin(response.data));
          notify("Consultation Booked", "success");
        } else {
          notify("User is not active", "error");
        }
        if (response.data?.user.id) {
          setOtp("");
          setOtpSent(false);
          handleSubmitRequest(response.data.user.id);
          dispatch(resetConsultation());
        }
        // navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    let timer;
    if (otpSent && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setIsResendDisabled(false);
    }
    return () => clearInterval(timer);
  }, [otpSent, resendTimer]);

  const handleSubmitOnLogin = async (e) => {
    // e.preventDefault();
    // setLoading(true);
    // handleSubmitRequest(user.id);
    // setLoading(false);
    e.preventDefault();
    setLoading(true);
    try {
      await handleSubmitRequest(user.id);
    } catch (error) {
      console.error("Error submitting request:", error);
      notify("Failed to submit request. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && user && reviewForm?.current) {
      reviewForm.current.elements.full_name.value = user.full_name || "";
      reviewForm.current.elements.email.value = user.email || "";
      reviewForm.current.elements.mobile.value = user.mobile || "";
    }
  }, [isAuthenticated, user, reviewForm]);

  useEffect(() => {
    console.log("OTPSent State:" + otpSent);
  }, [otpSent]);

  console.log(user, "user");

  return (
    <div style={{ minWidth: medium ? 400 : 300 }}>
      {isAuthenticated ? (
        // Logged-in user form for consultation
        <Form ref={form} onSubmit={handleSubmitOnLogin}>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Full Name<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              required
              className="py-2 body-text2"
              type="text"
              name="full_name"
              value={fullName || user?.full_name}
              onChange={handleFullNameChange}
              readOnly
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
                backgroundColor: "rgb(233, 236, 240)",
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Email<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                style={{
                  backgroundColor: "white",
                  border: "1px solid #BDC1C6",
                  borderRadius: 3,
                  backgroundColor: "rgb(233, 236, 240)",
                }}
              >
                <LuMail size={20} color="#1A1C1F" />
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2 body-text2"
                type="email"
                name="email"
                value={email || user?.email}
                onChange={handleEmailChange}
                readOnly
                style={{
                  border: "1px solid #BDC1C6",
                  borderRadius: "0px 3px 3px 0px",
                  backgroundColor: "rgb(233, 236, 240)",
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Phone Number<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                className="body-text2 inter-600"
                style={{
                  backgroundColor: "rgb(233, 236, 240)",
                  border: "1px solid #BDC1C6",
                  borderRadius: 3,
                }}
              >
                +91
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2 body-text2"
                type="text"
                name="mobile"
                value={mobile || user?.mobile}
                onChange={handleMobileChange}
                readOnly
                style={{
                  backgroundColor: "rgb(233, 236, 240)",
                  border: "1px solid #BDC1C6",
                  borderRadius: "0px 3px 3px 0px",
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Requirements<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              className="p-2 body-text2"
              as="textarea"
              rows={3}
              name="requirements_msg"
              value={requirementsMsg}
              onChange={handleRequirementsMsgChange}
              required
              // onChange={handleInputChange}
              placeholder="Please enter any requests you have for us."
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
                resize: "none",
              }}
            />
          </Form.Group>
          <Button
            type="submit"
            disabled={loading}
            className="text-white w-100 mb-2"
            style={{ borderRadius: 3 }}
          >
            {loading && (
              <Spinner animation="border" size="sm" className="me-2" />
            )}
            Request a Free Call Back
          </Button>
        </Form>
      ) : (
        <Form ref={form} onSubmit={otpSent ? verifyOtpLogin : handleSendOtp}>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Full Name<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              required
              className="py-2 body-text2"
              type="text"
              name="full_name"
              value={fullName || user?.full_name}
              onChange={handleFullNameChange}
              placeholder="Enter your Full Name"
              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Email<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                style={{
                  backgroundColor: "white",
                  border: "1px solid #BDC1C6",
                  borderRadius: 3,
                }}
              >
                <LuMail size={20} color="#1A1C1F" />
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2 body-text2"
                type="email"
                name="email"
                value={email || user?.email}
                onChange={handleEmailChange}
                required
                placeholder="example@gmail.com"
                style={{
                  border: "1px solid #BDC1C6",
                  borderRadius: "0px 3px 3px 0px",
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Phone Number<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                className="body-text2 inter-600"
                style={{
                  backgroundColor: "white",
                  border: "1px solid #BDC1C6",
                  borderRadius: 3,
                }}
              >
                +91
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2 body-text2"
                type="text"
                name="mobile"
                required
                value={mobile || user?.mobile}
                onChange={handleMobileChange}
                placeholder="9090909090"
                maxLength={10}
                pattern="[0-9]{10}" // Ensures exactly 6 digits
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, ""); // Removes non-numeric characters
                }}
                style={{
                  border: "1px solid #BDC1C6",
                  borderRadius: "0px 3px 3px 0px",
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Requirements<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              className="p-2 body-text2"
              as="textarea"
              rows={3}
              value={requirementsMsg}
              onChange={handleRequirementsMsgChange}
              name="requirements_msg"
              required
              placeholder="Please enter any requests you have for us."
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
                resize: "none",
              }}
            />
          </Form.Group>
          {otpSent && (
            <>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-600">
                  Enter OTP<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  className="p-2"
                  type="text"
                  name="email_verified_otp"
                  required
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  pattern="[0-9]{4}" // Ensures exactly 6 digits
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, ""); // Removes non-numeric characters
                  }}
                  maxLength={4}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
              </Form.Group>

              <div className="text-center mb-3">
                {isResendDisabled ? (
                  <span className="text-muted">
                    Resend OTP in {resendTimer} seconds
                  </span>
                ) : (
                  <span
                    className="text-primary cursor-pointer"
                    onClick={handleSendOtp}
                    style={{ textDecoration: "underline" }}
                  >
                    Resend OTP
                  </span>
                )}
              </div>
            </>
          )}
          <div className="text-center mb-2">
            <Button
              type="submit"
              disabled={loading}
              className="text-white w-100 mb-2"
              style={{ borderRadius: 3 }}
            // onClick={otpSent ? verifyOtpLogin : handleSendOtp}
            >
              {loading && (
                <Spinner animation="border" size="sm" className="me-2" />
              )}
              {otpSent ? "Verify OTP and Submit" : "Request a Free Call Back"}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default BookConsultationForm;
