import { useState } from "react";
import { project_drawing_only, turnkey_solution } from "assets";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { FaArrowRight, FaXmark } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Breakpoint } from "react-socks";

const ConsultationCard = ({ solution, large }) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);

  return (
    <Card
      className={`d-flex flex-column p-0 ${large ? "" : "mb-3"}`}
      style={{
        boxShadow: hover ? "" : "none",
        border: hover ? "1px solid #BDC1C6" : "1px solid #E8EAED",
        borderRadius: 6,
        minHeight: large ? 640 : 600
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Image
        src={solution.image}
        alt={solution.title}
        style={{ borderRadius: "6px 6px 0 0", objectFit: "cover", height: 150, width: "auto" }}
      />
      <div className="d-flex flex-column flex-grow-1 p-3">
        <h3 className="inter-600 text-gray-900">{solution.title}</h3>
        <div className="inter-400 text-gray-600">{solution.description}</div>
        <div className="d-flex flex-column justify-content-between flex-grow-1">
          <div className="text-start inter-500 body-text1 mt-3">
            <div className="d-flex align-items-center py-2">
              <FaArrowRight className="me-2" color="#5DC067" />
              Step 1: {solution.step1}
            </div>
            <div className="d-flex align-items-center py-2">
              <FaArrowRight className="me-2" color="#5DC067" />
              Step 2: {solution.step2}
            </div>
            <div className="d-flex align-items-center py-2">
              <FaArrowRight className="me-2" color="#5DC067" />
              Step 3: {solution.step3}
            </div>
            <div className="d-flex align-items-center py-2">
              <FaArrowRight className="me-2" color="#5DC067" />
              Step 4: {solution.step4}
            </div>
            <div className="d-flex align-items-center py-2">
              <FaArrowRight className="me-2" color="#5DC067" />
              Step 5: {solution.step5}
            </div>
            <div className="py-2">
              <div className="d-flex align-items-center">
                {solution.includes ? (
                  <FaArrowRight className="me-2" color="#5DC067" />
                ) : (
                  <FaXmark className="me-2" color="#C53D3D" />
                )}
                <span className={`${solution.includes ? "text-grey-900" : "text-error-500"}`}>
                  Step 6: {solution.step6}
                </span>
              </div>
              {solution.includes && (
                <div className="inter-400 body-text1 ms-4">
          <div className="inter-400 ms-4" style={{marginTop: "5px" }}>
          <div style={{ marginBottom: "4px" }}>a. Material and Product Purchase Assistance</div>
          <div style={{ marginBottom: "4px" }}>b. Vendors and Suppliers</div>
          <div>c. End-to-End Execution</div>
        </div>
                </div>
              )}
            </div>
          </div>
          <Button
            className={`body-text2 mt-auto p-2 ${solution.includes ? `text-white bg-primary-500` : `bg-brand-50 border-0 ${buttonHover ? 'text-white' : 'text-primary-500'}`}`}
            style={{ borderRadius: 3 }}
            onMouseEnter={() => setButtonHover(true)} 
            onMouseLeave={() => setButtonHover(false)} 
            onClick={() => {
              if (solution.includes) {
                navigate("/consultation#turnkey");
              } else {
                navigate("/consultation#customised");
              }
            }
            }
          >
            Book Free Consultation
          </Button>
        </div>
      </div>
    </Card>
  );
};

const ServiceCard = () => {

  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const [activeCard, setActiveCard] = useState(1);
  const [hoveredCard, setHoveredCard] = useState(1);

  const solutions = [
    {
      id: 1,
      image: turnkey_solution,
      title: "Turnkey (Complete) Interior Solution",
      description: "Complete hassle-free execution of your home interiors from ideation to handover, while maintaining transparency every step of the way.",
      step1: "Requirement Gathering and Consultation",
      step2: "2D and 3D Interior Design Inspiration",
      step3: "Architect Assistance",
      step4: "Product Details (BOQ) and Estimates",
      step5: "Customized Interior Drawings",
      step6: "Turnkey Site Execution (Included)",
      
      includes: (
        <div className="inter-400 ms-4" style={{ fontSize: "1.1rem", lineHeight: "1.8", marginTop: "8px" }}>
          <div style={{ marginBottom: "6px" }}>a. Material and Product Purchase Assistance</div>
          <div style={{ marginBottom: "6px" }}>b. Vendors and Suppliers</div>
          <div>c. End-to-End Execution</div>
        </div>
      ),
      
    },
    {
      id: 2,
      image: project_drawing_only,
      title: "Project Drawings Only",
      description: "Final construction drawings and architect support till release of final drawings. Project execution not included.",
      step1: "Requirement Gathering and Consultation",
      step2: "2D and 3D Interior Design Inspiration",
      step3: "Architect Assistance",
      step4: "Product Details (BOQ) and Estimates",
      step5: "Customized Interior Drawings",
      step6: "Turnkey Site Execution (Included)",
      
      includes: null,
    }
  ];

  return (
    <Container>
      <Breakpoint large up>
        <Row className={`my-4 row-cols-2`}>
          {solutions.map((solution) => {
            return (
              <Col className="mb-md-0">
                <ConsultationCard solution={solution} large={large} />
              </Col>
            );
          })}
        </Row>
      </Breakpoint>
      <Breakpoint medium down>
        <div className="d-flex flex-column">
          <div className="d-flex mb-3">
            {solutions.map((solution) => {
              return (
                <Card
                  className={`w-100 shadow-none cursor-pointer body-title 
                    ${solution.id === 1 ? "me-2" : ""} 
                    ${activeCard === solution.id ? "border-gray-300" : "border-gray-100"}
                    ${hoveredCard === solution.id ? "border-gray-200" : ""}
                    `}
                  style={{ borderRadius: 3 }}
                  onClick={() => setActiveCard(solution.id)}
                  onMouseEnter={() => setHoveredCard(solution.id)}
                  onMouseLeave={() => setHoveredCard(0)}
                >
                  <span className={`text-center ${hoveredCard === solution.id ? "text-primary-500" : "text-gray-900"} ${activeCard === solution.id ? "inter-600" : "inter-400"}`}>
                    {solution.id === 1 ? "Turnkey Solution" : solution.title}
                  </span>
                </Card>
              );
            })}
          </div>
          <div>
            {activeCard > 0 &&
              <ConsultationCard solution={solutions[activeCard - 1]} large={large} />
            }
          </div>
        </div>
      </Breakpoint>
    </Container>
  );
};

export default ServiceCard;
