import React, { useEffect, useState } from "react";
import axios from "axios";

import { notify } from "utilities/notifications/Notify";
import * as authActions from "reduxFiles/actions/authActions";

import DesignerSignup from "./DesignerSignup";
import BrandSignup from "./BrandSignup";
import BuilderSignup from "./BuilderSignup";
import OtherSignup from "./OtherSignup";
import { validateEmail } from "utilities/validateEmail";

function PartnerSignup({
  form,
  step,
  setStep,
  serviceType,
  verifyOtpLogin,
  otp,
  setOtp,
  setUsername,
}) {
  const [userSubtypes, setUserSubtypes] = useState([]);
  const [requiredSubtypes, setRequiredSubtypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [otpInput, setOtpInput] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [showOtpFields, setShowOtpFields] = useState(false);
  const [resendTimer, setResendTimer] = useState(false);

  // const handleSignUp = async () => {
  //   setLoading(true);
  //   const formData = new FormData(form.current);
  //   if (validateEmail(formData.get("email"))) {
  //     if (formData.get("confirm_password") !== formData.get("password")) {
  //       setPasswordError('Passwords do not match. Retry.');
  //       setLoading(false);
  //       return;
  //     }
  //     formData.append("user_type", serviceType);
  //     let data = {};
  //     formData.forEach((value, key) => (data[key] = value));
  //     console.log(data);
  //     try {
  //       const response = await authActions.signup(data);
  //       if (response.data) {
  //         setOtpInput(true);
  //         setLoading(false);
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       console.log(error);
  //     }
  //   } else {
  //     setLoading(false);
  //     setEmailError('Please enter a valid email address.');
  //   }
  // };

  const handleSignUp = async (formData) => {
    // e.preventDefault();
    setLoading(true);
    // const formData = new FormData(form.current);
    // const email = formData.get("email");
    // formData.append("user_type", serviceType);
    // let data = {};
    // formData.forEach((value, key) => (data[key] = value));
    console.log(formData);
    const email = formData.email.toLowerCase()
    let data = {...formData,email:email, "user_type": serviceType};
    if(formData.brand_category){
      data["brand_category"] = formData.brand_category.map((item) => item.value);
    }
    if (validateEmail(email)) {
      try {
        const response = await authActions.signup(data);
        if (response?.status === "SUCCESSFUL") {
          setOtpInput(true);
          setShowOtpFields(true);
          setIsResendDisabled(true);
          setResendTimer(response.time_left);
          notify("OTP sent successfully!", "success");
          // setLoading(false);
          console.log(response?.error, "response?.error");
        } else if (response?.error === "Email already exists.") {
          notify("This email is already registered. Please log in.", "error");
        } else {
          notify(response?.msg || "Failed to send OTP. Try again.", "error");
        }
      } catch (error) {
        // setLoading(false);
        console.log(error);
      }
    } else {
      // setLoading(false);
      notify("Please enter a valid email id!", "error");
    }
    setLoading(false);
  };

  const fetchUserSubtypes = async () => {
    try {
      const response = await axios.get(`/user/rest/user-subtypes/`);
      if (response.data) {
        setUserSubtypes(response?.data);
      } else {
        notify(
          "Error while fetching user subtypes. Please try again later",
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (serviceType !== "others") {
      setRequiredSubtypes(
        userSubtypes.filter((item) => item.user_type === serviceType)
      );
    } else {
      setRequiredSubtypes(
        userSubtypes.filter((item) => (item.user_type !== "architect" && item.user_type !== "brand" && item.user_type !== "builder"))
      );
      // alert();
    }
  }, [userSubtypes, serviceType]);

  useEffect(() => {
    fetchUserSubtypes();
  }, []);

  return (
    <>
      {serviceType === "architect" ? (
        <DesignerSignup
          form={form}
          requiredSubtypes={requiredSubtypes}
          step={step}
          setStep={setStep}
          otpInput={otpInput}
          handleSignUp={handleSignUp}
          verifyOtpLogin={verifyOtpLogin}
          loading={loading}
          emailError={emailError}
          setEmailError={setEmailError}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
          setUsername={setUsername}
          otp={otp}
          setOtp={setOtp}
          isResendDisabled={isResendDisabled}
          setIsResendDisabled={setIsResendDisabled}
          showOtpFields={showOtpFields}
          setShowOtpFields={setShowOtpFields}
          resendTimer={resendTimer}
          setResendTimer={setResendTimer}
        />
      ) : serviceType === "brand" ? (
        <BrandSignup
          form={form}
          step={step}
          setStep={setStep}
          otpInput={otpInput}
          handleSignUp={handleSignUp}
          verifyOtpLogin={verifyOtpLogin}
          loading={loading}
          emailError={emailError}
          setEmailError={setEmailError}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
          setUsername={setUsername}
          otp={otp}
          setOtp={setOtp}
          isResendDisabled={isResendDisabled}
          setIsResendDisabled={setIsResendDisabled}
          showOtpFields={showOtpFields}
          setShowOtpFields={setShowOtpFields}
          resendTimer={resendTimer}
          setResendTimer={setResendTimer}
        />
      ) : serviceType === "builder" ? (
        <BuilderSignup
          form={form}
          step={step}
          setStep={setStep}
          otpInput={otpInput}
          handleSignUp={handleSignUp}
          verifyOtpLogin={verifyOtpLogin}
          loading={loading}
          emailError={emailError}
          setEmailError={setEmailError}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
          setUsername={setUsername}
          otp={otp}
          setOtp={setOtp}
          isResendDisabled={isResendDisabled}
          setIsResendDisabled={setIsResendDisabled}
          showOtpFields={showOtpFields}
          setShowOtpFields={setShowOtpFields}
          resendTimer={resendTimer}
          setResendTimer={setResendTimer}
        />
      ) : serviceType === "oem" ? (
        <OtherSignup
          requiredSubtypes={requiredSubtypes}
          step={step}
          setStep={setStep}
          isResendDisabled={isResendDisabled}
          setIsResendDisabled={setIsResendDisabled}
          showOtpFields={showOtpFields}
          setShowOtpFields={setShowOtpFields}
          resendTimer={resendTimer}
          setResendTimer={setResendTimer}
        />
      ) : serviceType === "project-management" ? (
        <OtherSignup
          requiredSubtypes={requiredSubtypes}
          step={step}
          setStep={setStep}
          isResendDisabled={isResendDisabled}
          setIsResendDisabled={setIsResendDisabled}
          showOtpFields={showOtpFields}
          setShowOtpFields={setShowOtpFields}
          resendTimer={resendTimer}
          setResendTimer={setResendTimer}
        />
      ) : serviceType === "turnkey-contractors" ? (
        <OtherSignup
          requiredSubtypes={requiredSubtypes}
          step={step}
          setStep={setStep}
          isResendDisabled={isResendDisabled}
          setIsResendDisabled={setIsResendDisabled}
          showOtpFields={showOtpFields}
          setShowOtpFields={setShowOtpFields}
          resendTimer={resendTimer}
          setResendTimer={setResendTimer}
        />
      ) : serviceType === "others" ? (
        <OtherSignup
          requiredSubtypes={requiredSubtypes}
          step={step}
          setStep={setStep}
          isResendDisabled={isResendDisabled}
          setIsResendDisabled={setIsResendDisabled}
          showOtpFields={showOtpFields}
          setShowOtpFields={setShowOtpFields}
          resendTimer={resendTimer}
          setResendTimer={setResendTimer}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default PartnerSignup;
