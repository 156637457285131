import { builder_partner_1, builder_partner_2, builder_partner_3, builder_partner_4, century_ply, decowood, dexarte, jivanjor } from "assets";
import React from "react";
import { Card, Carousel, Col, Row } from "react-bootstrap";

const CustomSliderForBrand = () => {
    const builderData = [
        {
            id: 1,
            image: `${century_ply}`,

        },
        {
            id: 2,
            image: `${decowood}`,

        },
        {
            id: 3,
            image: `${jivanjor}`,

        },
        {
            id: 4,
            image: `${dexarte}`,

        }
    ];

    const pairedData = [];
    for (let i = 0; i < builderData.length; i += 2) {
        pairedData.push(builderData.slice(i, i + 2));
    }

    return (
        <Carousel
            controls={false}
            indicators={false}
            interval={null}
            touch={true}
            className="builder-carousel py-2"
        >
            {pairedData.map((pair, index) => (
                <Carousel.Item key={index} className="py-2">
                    <Row className="">
                        {pair.map((item) => (
                            <Col xs={6} key={item.id}>
                                <Card className="p-0" style={{
                                    maxWidth: '170px'
                                }}>
                                    <Card.Img
                                        variant="top"
                                        src={item.image}
                                        style={{
                                            height: '80px',
                                            objectFit: 'contain'
                                        }}
                                    />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Carousel.Item>
            ))}
        </Carousel>
    );

};
export default CustomSliderForBrand;