import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { CgClose } from "react-icons/cg";
import { useLocation } from "react-router-dom";
import { uploadThought } from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";

const ArchitectThoughtForm = ({ thoughts, user }) => {
  const [formData, setFormData] = useState({
    thoughts: thoughts?.architect_thoughts || "",
    media: null,
  });

  const [mediaPreview, setMediaPreview] = useState(
    thoughts?.architect_image || null
  );
  const [isNewMedia, setIsNewVideo] = useState(false);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [mediaName, setMediaName] = useState("");

  // Sync only on first render or when `thoughts` change
  useEffect(() => {
    if (thoughts) {
      setFormData((prev) => ({
        ...prev,
        thoughts: thoughts.architect_thoughts || "",
        media: thoughts.architect_image || null,
      }));
      setMediaPreview(thoughts.architect_image || null);
      setIsNewVideo(false);
      // Extract filename from URL if it exists
      if (thoughts.architect_image) {
        const urlParts = thoughts.architect_image.split('/');
        setMediaName(urlParts[urlParts.length - 1]);
      }
    }
  }, [thoughts]);

  /*  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "media") {
      const file = files[0];
      if (!file) return;

      const fileURL = URL.createObjectURL(file);
      setFormData((prev) => ({ ...prev, media: file }));
      setMediaPreview(URL.createObjectURL(file));
      setIsNewVideo(file.type.startsWith("video/"));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  }; */

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "media") {
      const file = files[0];
      if (!file) return;

      const imageTypes = ["image/jpeg", "image/jpg", "image/png"];
      const videoTypes = ["video/mp4", "video/quicktime"]; // MOV (QuickTime)
      const maxImageSize = 2 * 1024 * 1024; // 2MB
      const maxVideoSize = 50 * 1024 * 1024; // 50MB

      // Validate file type
      if (![...imageTypes, ...videoTypes].includes(file.type)) {
        notify(
          "Only JPG, JPEG, PNG, MP4, and MOV files are allowed",
          "error"
        );
        e.target.value = null; // Clear the file input
        return;
      }

      // Validate file size
      if (imageTypes.includes(file.type) && file.size > maxImageSize) {
        notify("Image size must be less than 2MB", "error");
        e.target.value = null; // Clear the file input
        return;
      }

      if (videoTypes.includes(file.type) && file.size > maxVideoSize) {
        notify("Video size must be less than 50MB", "error");
        e.target.value = null; // Clear the file input
        return;
      }

      const fileURL = URL.createObjectURL(file);
      setFormData((prev) => ({ ...prev, media: file }));
      setMediaPreview(fileURL);
      setIsNewVideo(videoTypes.includes(file.type));
      setMediaName(file.name);
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleMediaClick = (e) => {
    if (mediaPreview) {
      e.preventDefault();
      notify(
        "Please remove the existing media before uploading a new one.",
        "error"
      );
    }
  };

  const uploadThoughts = async () => {
    if (!formData.thoughts || !formData.media) {
      notify("Please fill out both the thoughts and image fields.", "error");
      return;
    }

    try {
      const formPayload = new FormData();

      formPayload.append("thoughts", formData.thoughts);
      formPayload.append("media", formData.media);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      await uploadThought(user.id, formPayload, "architects");
      // notify(
      //   "Thank you for sharing your thoughts! Your feedback has been received.",
      //   "success"
      // );
    } catch (error) {
      console.error("Error:", error);
      notify("An error occurred while uploading. Please try again.", "error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await uploadThoughts();
    } catch (error) {
      console.error("Error in submission:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveMedia = () => {
    setFormData((prev) => ({ ...prev, media: null }));
    setMediaPreview(null);
    setIsNewVideo(false);
    setMediaName("");
  };

  return (
    <Form
      className="border border-gray-100 mb-4"
      onSubmit={handleSubmit}
      style={{ borderRadius: 6 }}
    >
      <div className="p-3">
        <div className={`h4 inter-600 mt-2`}>
          {pathname.includes("brand")
            ? "Brand Thought Form"
            : "Architect Thought Form"}
        </div>
      </div>
      <Row className="px-3 pb-3">
        <Col lg={5}>
          {/* Thoughts Field */}
          <Form.Group className="mb-3 user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Thoughts<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              required
              as="textarea"
              rows={4}
              className="body-text2 py-2 border border-gray-300 text-gray-900"
              name="thoughts"
              value={formData.thoughts}
              onChange={handleChange}
              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
            />
          </Form.Group>

          {/* Image/Video Field */}
          <Form.Group className="mb-3 user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Image (Max. 2MB) / Video (Max. 50MB)<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              required={formData.media === null ? true : false}
              type="file"
              className="body-text2 py-2 border border-gray-300"
              name="media"
              accept="image/jpeg,image/jpg,image/png,video/mp4,video/quicktime" // Restrict file types
              onChange={handleChange}
              onClick={handleMediaClick}
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
                resize: "none",
              }}
            />
            {/* Display the media name */}
            {mediaName && (
              <div className="mt-2 body-text2 text-gray-600">
                Selected: {mediaName}
              </div>
            )}
          </Form.Group>

          {/* Submit Button */}
          <Form.Group className="d-flex justify-content-end mb-2 mb-lg-0">
            <button
              type="submit"
              className="btn btn-primary body-text2 inter-500"
              style={{
                backgroundColor: "#a53030",
                borderRadius: 3,
              }}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />{" "}
                  {/* Spinner */}
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </Form.Group>
        </Col>

        <Col
          lg={5}
          className="d-flex align-items-center justify-content-center"
        >
          {mediaPreview ? (
            isNewMedia ? (
              <div style={{ position: "relative", maxHeight: "300px" }}>
                <video
                  controls
                  style={{
                    maxWidth: "100%",
                    maxHeight: "300px",
                    borderRadius: "3px",
                  }}
                >
                  <source src={mediaPreview} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <button
                  onClick={handleRemoveMedia}
                  type="button"
                  style={{
                    position: "absolute",
                    top: "3%",
                    right: "3%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    border: "none",
                    borderRadius: "50%",
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <CgClose size={32} />
                </button>
              </div>
            ) : typeof mediaPreview === "string" && mediaPreview.match(/\.(mp4|webm|ogg)$/i) ? (
              <div style={{ position: "relative", maxHeight: "300px" }}>
                <video
                  controls
                  style={{
                    maxWidth: "100%",
                    maxHeight: "300px",
                    borderRadius: "3px",
                    position: "relative",
                  }}
                >
                  <source src={mediaPreview} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <button
                  onClick={handleRemoveMedia}
                  type="button"
                  style={{
                    position: "absolute",
                    top: "3%",
                    right: "3%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    border: "none",
                    borderRadius: "50%",
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <CgClose size={32} />
                </button>
              </div>
            ) : (
              <div style={{ position: "relative", maxHeight: "300px" }}>
                {/* Image Preview */}
                <img
                  src={mediaPreview}
                  alt="Preview"
                  style={{
                    position: "relative",
                    maxWidth: "100%",
                    maxHeight: "300px",
                    border: "1px solid #BDC1C6",
                    borderRadius: "3px",
                    display: "block",
                  }}
                />

                {/* Cancel Button */}
                <button
                  onClick={handleRemoveMedia}
                  type="button"
                  style={{
                    position: "absolute",
                    top: "3%",
                    right: "3%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    border: "none",
                    borderRadius: "50%",
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <CgClose size={32} />
                </button>
              </div>
            )
          ) : (
            <div
              className="text-center body-text2 text-gray-600"
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: "3px",
                padding: "10px",
                maxWidth: "100%",
                maxHeight: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No image selected
            </div>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default ArchitectThoughtForm;