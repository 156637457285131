// components/MediaCard.jsx
import React from "react";
import { Image } from "react-bootstrap";
import { MdDelete } from "react-icons/md";

const MediaCard = ({ file, onRemove, index }) => {
  const isVideo = file?.type?.startsWith("video/") || 
                  file?.name?.match(/\.(mp4|webm|mov|avi|wmv)$/i);

  return (
    <div
      className="d-flex flex-column rounded-start bg-white w-100 border border-grey position-relative"
      style={{
        overflow: "hidden",
        boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
      }}
    >
      <div className="d-flex justify-content-center align-items-center">
        {file && isVideo ? (
          <video
            src={URL.createObjectURL(file)}
            controls
            className="img-fluid w-100"
            style={{
              objectFit: "contain",
              minHeight: "160px",
              maxHeight: "300px",
            }}
          />
        ) : (
          <Image
            src={URL.createObjectURL(file)}
            alt=""
            className="img-fluid w-100"
            style={{
              objectFit: "contain",
              minHeight: "160px",
              maxHeight: "300px",
            }}
          />
        )}
      </div>

      <div
        className="position-absolute"
        style={{
          top: 1,
          right: 1,
          zIndex: 1,
        }}
      >
        <div
          className="d-flex justify-content-center align-items-center bg-white rounded-circle cursor-pointer"
          style={{
            width: 36,
            height: 36,
            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
          }}
          onClick={() => onRemove(index)}
        >
          <MdDelete
            className="cursor-pointer"
            size={22}
            color="#5A5A5A"
          />
        </div>
      </div>
    </div>
  );
};

export default MediaCard;