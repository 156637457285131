import React from "react";

const shimmerStyle = {
  background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
  backgroundSize: "200% 100%",
  animation: "shimmer 1.5s infinite linear",
};

const shimmerKeyframes = `
  @keyframes shimmer {
    from { background-position: -200% 0; }
    to { background-position: 200% 0; }
  }
`;

const ArchitectProjectShimmer = () => {
  return (
    <>
      <style>{shimmerKeyframes}</style>
      <div className="container mt-4">
        <div
          className="card p-3"
          style={{
            border: "none",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <div className="d-flex align-items-start">
            <div
              style={{
                ...shimmerStyle,
                width: "96px",
                height: "96px",
                borderRadius: "8px",
              }}
            ></div>
            <div className="ms-3 w-100">
              <div
                style={{
                  ...shimmerStyle,
                  width: "80%",
                  height: "20px",
                  borderRadius: "4px",
                  marginBottom: "8px",
                }}
              ></div>
              <div
                style={{
                  ...shimmerStyle,
                  width: "60%",
                  height: "18px",
                  borderRadius: "4px",
                  marginBottom: "8px",
                }}
              ></div>
              <div className="d-flex mt-2">
                <div
                  style={{
                    ...shimmerStyle,
                    width: "120px",
                    height: "30px",
                    borderRadius: "15px",
                    marginRight: "8px",
                  }}
                ></div>
                <div
                  style={{
                    ...shimmerStyle,
                    width: "120px",
                    height: "30px",
                    borderRadius: "15px",
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div
              style={{
                ...shimmerStyle,
                width: "90%",
                height: "16px",
                borderRadius: "4px",
                marginBottom: "8px",
              }}
            ></div>
            <div
              style={{
                ...shimmerStyle,
                width: "70%",
                height: "16px",
                borderRadius: "4px",
                marginBottom: "8px",
              }}
            ></div>
            <div
              style={{
                ...shimmerStyle,
                width: "80%",
                height: "16px",
                borderRadius: "4px",
                marginBottom: "8px",
              }}
            ></div>
          </div>
          {/* <div className="d-flex justify-content-end mt-3">
            <div
              style={{
                ...shimmerStyle,
                width: "120px",
                height: "30px",
                borderRadius: "15px",
              }}
            ></div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ArchitectProjectShimmer;
