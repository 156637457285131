import React from "react";

const ShimmerSlider = ({ properties }) => {
  if (!properties || properties.length <= 1) {
    return (
      <div
        className="d-flex align-items-center position-relative"
        style={{ gap: "16px", paddingBottom: "10px" }}
      >
        <div className="d-flex flex-nowrap overflow-auto gap-3 py-2 px-4">
          {Array.from({ length: 10 }).map((_, index) => (
            <div
              key={index}
              className="d-flex flex-column align-items-center body-text3 nav-link cursor-pointer"
            >
              <div
                className="shimmer-box"
                style={{
                  position: "relative",
                  width: "132px",
                  height: "124px",
                  borderRadius: "8px",
                  background:
                    "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                  backgroundSize: "200% 100%",
                  animation: "shimmer 1.5s infinite linear",
                }}
              ></div>
              <div
                className="shimmer-text mt-2"
                style={{
                  width: "80px",
                  height: "16px",
                  borderRadius: "4px",
                  background:
                    "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                  backgroundSize: "200% 100%",
                  animation: "shimmer 1.5s infinite linear",
                }}
              ></div>
            </div>
          ))}
        </div>
        <style>{`
          @keyframes shimmer {
            0% { background-position: -200% 0; }
            100% { background-position: 200% 0; }
          }
        `}</style>
      </div>
    );
  }
  return null;
};

export default ShimmerSlider;
