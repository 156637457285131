import React from 'react';
import { Table, Placeholder } from 'react-bootstrap';

const ProductListShimmer = () => {
  return (
    <div className="w-100">
      <Table striped bordered hover responsive className="w-100">
        
        <tbody>
          {[...Array(6)].map((_, index) => (
            <tr key={index}>
              <td>
                <Placeholder animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
              </td>
              <td>
                <Placeholder animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
              </td>
              <td>
                <Placeholder animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
              </td>
              <td>
                <Placeholder animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
              </td>
              <td>
                <Placeholder animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ProductListShimmer;