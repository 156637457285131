import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { MdArrowOutward } from "react-icons/md";
import { HiXMark } from "react-icons/hi2";
import { FiSend } from "react-icons/fi";
import { FaRegHeart } from "react-icons/fa6";
import { useEffect, useState } from "react";
import ProductCard from "components/Cards/ProductCard";
import { useLocation, useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import ShareModal from "components/General/ShareModal";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import CustomSlider from "components/CustomSlider/CustomSlider";
import { notify } from "utilities/notifications/Notify";
import useFetchMedia from "utilities/customHooks/useFetchMedia";

const SpacesSliderView = ({
  ativeRoomIndex = 0,
  setActiveRoomIndex,
  show,
  setShow,
  roomData,
  specificRooms,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  // const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);

  const [selectedOption, setSelectedOption] = useState(null);
  const [rooms, setRooms] = useState(specificRooms ?? []);
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchProject = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProject(roomData.projectId);
      if (response.data) {
        console.log(response.data.rooms);
        setRooms(response.data.rooms);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  console.log(roomData);

  const fetchRoomProducts = async () => {
    try {
      const response = await authActions.fetchRoomProductsByRoom(roomData.id);
      if (response.data) {
        console.log(response.data?.results);
        setProducts(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (roomData?.projectId) {
      fetchProject();
    }
    if (roomData?.id) {
      fetchRoomProducts();
    }
  }, [roomData]);
  // useEffect(() => {
  //   checkRoomIfSaved();
  // }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // console.log(selectedOption);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className="spaces-slider-custom-modal p-0 overflow-hidden"
      dialogClassName="spaces-slider-modal"
      fullscreen={true}
      style={{ borderRadius: 16, height: "100vh", margin: "8px" }}
    >
      <Modal.Body className="p-0 overflow-hidden" style={{ borderRadius: 16 }}>
        <Row className="flex-wrap p-0 overflowY-scroll">
          <Col lg={8} className="mb-0 pe-0">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center h-100"
                // style={{ minHeight: "50vh" }}
              >
                <Spinner />
              </div>
            ) : (
              <div className="spaces-slider-modal-content h-100">
                <CustomSlider
                  items={rooms}
                  setShow={setShow}
                  currentItemIndex={ativeRoomIndex}
                  setCurrentIndex={setActiveRoomIndex}
                />
              </div>
            )}
          </Col>
          <Col lg={4} className="ps-0 py-3" style={{ maxHeight: "100vh" }}>
            <div className="px-4">
              {large && (
                <div className="text-end">
                  <HiXMark
                    size={24}
                    className="cursor-pointer"
                    onClick={() => setShow(false)}
                  />
                </div>
              )}
              {user?.user_type !== "builder" &&
                user?.user_type !== "architect" &&
                user?.user_type !== "brand" && (
                  <div>
                    <div className="h4 inter-600 mt-2">
                      Want these interiors for your home?
                    </div>
                    <div className="body-text2 geist">
                      At InterioKeys, you can choose from the 2 types of
                      services provided. We recommend going Turnkey!
                    </div>
                    <Form>
                      <div className="mt-4 pb-4 border-2 border-bottom">
                        <div className="mb-3">
                          <label
                            className="cursor-pointer d-block d-flex bg-info text-gray-900 p-2 rounded inter-600 mb-2"
                            onClick={() => setSelectedOption("turnkey")}
                          >
                            <Form.Check
                              type="radio"
                              name="requirement_type"
                              // label={`Complete Home Interiors`} value="complete_home"
                              defaultChecked={selectedOption === "turnkey"}
                              // label="Turnkey Solution"
                              value="turnkey"
                              // onChange={() => setSelectedOption("turnkey")}
                              className="cursor-pointer"
                            />
                            <span className="ms-2">Turnkey Solution</span>
                          </label>
                          <div className="body-text2 geist">
                            Final construction drawings, architect support with
                            site execution (Recommended!)
                          </div>
                        </div>
                        <label
                          className="cursor-pointer d-flex d-block bg-info text-gray-900 p-2 rounded inter-600 mb-2"
                          onClick={() => setSelectedOption("customised")}
                        >
                          <Form.Check
                            type="radio"
                            name="requirement_type"
                            // label={`Only Selected Rooms`} value="selected_rooms"
                            // defaultChecked={selectedOption === "customised"}
                            // label="Project Files and Consultation"
                            value="customised"
                            // onClick={handleOptionChange}
                            // onChange={() => setSelectedOption("customised")}
                            className="cursor-pointer"
                          />
                          <span className="ms-2">
                            Project Files and Consultation
                          </span>
                        </label>
                        <div className="body-text2 geist">
                          Final construction drawings and architect support
                          without site execution
                        </div>
                        <Button
                          className="text-white py-2 w-100 mt-5"
                          disabled={!selectedOption}
                          onClick={() => {
                            navigate("/consultation", {
                              state: {
                                servicetype: selectedOption,
                                room: rooms[ativeRoomIndex],
                              },
                            });
                          }}
                        >
                          Continue
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
              <div className="d-flex flex-column mt-2">
                <div className="mb-3 inter-600">Products Used in Image</div>
                <div className="d-flex flex-column flex-wrap">
                  {productsLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <Spinner />
                    </div>
                  ) : (
                    <div className="h-100 overflowY-scroll scrollbar-none flex-grow">
                      {products?.length > 0 ? (
                        products?.map((item, index) => (
                          <ProductCard
                            key={index}
                            id={item?.id}
                            brand_slug={item?.brand_slug}
                            product_data={item?.product_data}
                            productId={item?.product}
                            title={item?.product_data?.title}
                            brand_name={item?.product_data?.brand_name}
                            description={item?.product_data?.description}
                            productDataId={item?.product_data?.id}
                            productTypeImage={
                              item?.product_data?.product_category_data?.image
                            }
                            isLast={index + 1 === products.length}
                            // image={item?.media[0]?.file}
                          />
                        ))
                      ) : (
                        <div>No Products Available</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SpacesSliderView;
