import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Image, Spinner } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import userImg from "../../assets/navbar/user.webp";

const BuildersMegaMenu = ({
  showBuilders,
  setShowBuilders,
  toggled,
  setToggled,
}) => {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const [builders, setBuilders] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchBuilders = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/user/rest/builders/");
      if (response?.data) {
        setBuilders(response.data?.results);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBuilders();
  }, []);

  return (
    <>
      {small && (
        <Card
          className={`${
            showBuilders
              ? "position-absolute huge-dropdown-container d-flex p-0 shadow-none"
              : "d-none"
          }`}
          style={{ left: 0, top: 70 }}
        >
          <Card.Body
            className="p-0"
            style={{ backgroundColor: "#FFFFFF", borderRadius: 5 }}
          >
            <div className="pt-2" style={{ padding: "0px 50px 30px" }}>
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "10vh" }}
                >
                  <Spinner />
                </div>
              ) : (
                <>
                  <div
                    className="body-text3 d-flex align-items-center inter-500 mb-2"
                    onClick={() => navigate(`professionals/builders`)}
                  >
                    <span className="me-2">View All Builders</span>
                    <FaChevronRight size={12} />
                  </div>
                  <div className="d-flex flex-wrap" style={{gap:'5px 10px'}}>
                    {builders && builders.length > 0 ? (
                      builders.map((builder, index) => (
                        <div
                          key={index}
                          className=" body-text3 nav-link p-0 p-1 cursor-pointer small-img"
                          style={{width:'120px', }}
                          onClick={() => {
                            setShowBuilders(false);
                            navigate(`professionals/builders/${builder.slug}`);
                          }}
                        >
                          <Image
                            src={builder?.user_data?.profile_picture || userImg}
                            alt={builder.company_name || "Builder"}
                           style={{width:'100%', height:'50px', objectFit:'contain'}}
                            className="mb-1"
                          />
                          {builder.company_name && (
                            <div
                              className="text-center body-text3 pt-1"
                              style={{ fontSize: "12px", color: "#333" }}
                            >
                              {builder.company_name} 
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="body-text3 pt-3 text-center">
                        No builders are available
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </Card.Body>
        </Card>
      )}
      {!small && showBuilders && (
        <div className="d-flex flex-column">
          {builders.map((builder) => {
            return (
              <div
                className="me-2 cursor-pointer"
                onClick={() => {
                  navigate(`professionals/builders/${builder.slug}`);
                  setToggled(!toggled);
                }}
                style={{ padding: "6px 16px" }}
              >
                <div className={`body-text1 inter-400 text-gray-900`}>
                  {builder.company_name}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default BuildersMegaMenu;
