import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ConsultaionCard from "./ConsultationCard";
import { FaRegHeart } from "react-icons/fa6";
import { useEffect, useState } from "react";
import ShareModal from "components/General/ShareModal";
import { FiSend } from "react-icons/fi";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import { useSelector } from "react-redux";
import LoginModal from "components/Auth/LoginModal";
import useFetchMedia from "utilities/customHooks/useFetchMedia";
import ProjectCardShimmer from "./ProjectCardShimmer";
import { default_property_image } from "assets";

const UserProfileProjectCard = ({
  id,
  slug,
  project_name,
  bhk,
  isLiked,
  setIsLiked,
  roomId,
  builder_name,
  architect,
  property_name,
  isConsultation,
  boxShadow,
  setProjects,
  projects,
  setSingleRowProjects,
  imgHeight = "300px",
  className,
  style,
  setProjectLength,
  context = "home", // Add context prop with default "home"
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const { navbarToggle } = useSelector((state) => state.auth);

  const [showShare, setShowShare] = useState(false);
  const [hover, setHover] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);

  const { image, loading, error } = useFetchMedia("project", "room", roomId);

  const checkProjectIfSaved = async () => {
    try {
      const response = await authActions.checkProjectIfSaved(user.id, id);
      if (response) {
        setIsLiked(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProject = async () => {
    try {
      const response = await authActions.saveProject(user.id, id);
      if (response.data) {
        setIsLiked(true);
        notify("Project saved!", "success");
      } else {
        notify("Failed to save project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProject = async () => {
    try {
      const response = await authActions.unsaveProject(user.id, id);
      if (response) {
        setProjects((prev) => {
          const updatedProjects = prev.filter(
            (project) => project.project !== id
          );
          setSingleRowProjects(updatedProjects.slice(0, 3));
          return updatedProjects;
        });
        setProjectLength((prev) => prev - 1);
        notify("Project unsaved!", "success");
      } else {
        notify("Failed to unsave project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      checkProjectIfSaved();
    }
  }, [user]);

  const handleNavigation = () => {
    const navigationState = context === "profile"
      ? { fromProfile: true, fromSavedSection: "inspirations" }
      : { fromSavedSection: "inspirations" };
    navigate(`/projects/${slug}`, { state: navigationState });
  };

  if (isConsultation) {
    return <ConsultaionCard />;
  }

  if (!id) {
    return <ProjectCardShimmer />;
  }

  return (
    <>
      <Card
        className={`cursor-pointer w-100 py-0 px-0 border rounded shadow-none overflow-hidden position-relative ${
          hover ? "border-gray-300" : "border-gray-50"
        } ${className}`}
        style={{
          boxShadow: boxShadow,
          ...style,
        }}
        onClick={handleNavigation} // Updated to use handleNavigation
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Card.Img
          className="img-fluid"
          variant="top"
          src={image||default_property_image}
          style={{
            maxHeight: imgHeight,
            minHeight: imgHeight,
            objectFit: "cover",
          }}
        />
        <div
          className="d-flex position-absolute"
          style={{
            zIndex: 999,
            right: 15,
            top: 10,
            filter: navbarToggle ? "brightness(60%)" : "none",
          }}
        >
          <Button
            className="d-flex justify-content-center align-items-center share-like-btn p-0"
            onClick={(e) => {
              e.stopPropagation();
              setShowShare(true);
            }}
          >
            <FiSend size={20} />
          </Button>

          <Button
            className={`d-flex justify-content-center align-items-center share-like-btn ms-2 p-0 ${
              isLiked ? "bg-primary text-white" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              if (!user) {
                setShowLoginModal(true);
              } else {
                isLiked ? handleUnsaveProject() : handleSaveProject();
              }
            }}
          >
            <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : ""} />
          </Button>
        </div>
        <Card.Body
          className="p-2 d-flex flex-column align-items-start"
          onClick={handleNavigation} // Updated to use handleNavigation
        >
          <Card.Title
            className={`inter600 body-text1 mb-0 ${
              hover ? "text-primary-500" : "text-gray-800"
            }`}
          >
            {`${project_name}${bhk ? `/${bhk}` : ""}/${property_name}`}
          </Card.Title>
          <div className="body-text2 inter-400 text-gray-800 d-flex flex-column align-items-start">
            <span className="ms-0">Architect: {architect ?? "-"}</span>
            <span className="ms-0">Builder: {builder_name ?? "-"}</span>
          </div>
        </Card.Body>
      </Card>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={id}
        image={image}
        title={`${project_name}/${bhk}/${property_name}`}
        urlPath={`/projects/${slug}`}
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </>
  );
};

export default UserProfileProjectCard;