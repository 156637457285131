import { Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import BrandCards from "./BrandCards";
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";

// Custom arrow components for the slider
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        zIndex: 1,
        left: "5px",
      }}
      onClick={onClick}
    />
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        zIndex: 1,
        right: "5px",
      }}
      onClick={onClick}
    />
  );
};

// MediaSlide component to render either image or video
const MediaSlide = ({ file, height }) => {
  // Guard clause for undefined or null file
  if (!file) {
    return (
      <div className="position-relative overflow-hidden bg-light d-flex justify-content-center align-items-center rounded-3" style={{ height }}>
        <p className="text-muted">Media not available</p>
      </div>
    );
  }

  // Safely determine if the file is a video based on various properties
  const isVideo = 
    (typeof file !== 'string' && file.type && file.type.startsWith('video/')) || 
    (typeof file === 'string' && file.match && file.match(/\.(mp4|webm|mov|avi|wmv)$/i)) ||
    (file.fileType === 'video') ||
    (file.media_type === 'video');

  // Handle string URLs (existing media)
  if (typeof file === 'string') {
    return (
      <div className="position-relative overflow-hidden">
        {file.match && file.match(/\.(mp4|webm|mov|avi|wmv)$/i) ? (
          <video
            className="img-fluid w-100 rounded-3"
            src={file}
            controls
            style={{ height, objectFit: "cover" }}
          />
        ) : (
          <img
            className="img-fluid w-100 rounded-3"
            src={file}
            style={{ height, objectFit: "cover" }}
            alt="Room preview"
          />
        )}
      </div>
    );
  }

  // Handle File objects (new media)
  try {
    // Check if it's already a blob URL or a File object
    const src = file instanceof Blob 
      ? URL.createObjectURL(file) 
      : (file.url || file.preview || (typeof file === 'object' && file.toString ? file.toString() : null));
    
    // If we can't determine a valid source, throw error to use fallback
    if (!src) {
      throw new Error("Unable to determine valid source for media");
    }

    return (
      <div className="position-relative overflow-hidden">
        {isVideo ? (
          <video
            className="img-fluid w-100 rounded-3"
            src={src}
            controls
            style={{ height, objectFit: "cover" }}
          />
        ) : (
          <img
            className="img-fluid w-100 rounded-3"
            src={src}
            style={{ height, objectFit: "cover" }}
            alt="Room preview"
            onError={(e) => {
              console.error("Image load error");
              e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Crect width='100' height='100' fill='%23f0f0f0'/%3E%3Ctext x='50' y='50' font-family='Arial' font-size='12' text-anchor='middle' dominant-baseline='middle' fill='%23999999'%3EImage not available%3C/text%3E%3C/svg%3E";
            }}
          />
        )}
      </div>
    );
  } catch (error) {
    console.error("Error displaying media:", error);
    return (
      <div className="position-relative overflow-hidden bg-light d-flex justify-content-center align-items-center rounded-3" style={{ height }}>
        <p className="text-muted">Error displaying media</p>
      </div>
    );
  }
};

const EditedProjectDetails = ({
  projectDetails,
  status,
  setCurrentStep,
  user,
  currentStep,
  roomsToDisplay,
  temps,
  displaySelectedBrands,
  mediaForPreview, // Add the new prop
}) => {
  const location = useLocation();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  
  // Get slider height based on screen size
  const getSliderHeight = () => {
    if (xlarge) return 500;
    if (large) return 500;
    if (medium) return 400;
    if (small) return 300;
    return 200;
  };
  
  const settings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: mediaForPreview && mediaForPreview.length > 0 ? <NextArrow /> : null,
    prevArrow: mediaForPreview && mediaForPreview.length > 0 ? <PrevArrow /> : null,
  };

  // Define projectSegments to map the value to a label
  const projectSegments = [
    {
      id: 1,
      label: "Affordable (₹2,500 to ₹4,000)",
      value: "AFFORDABLE_RESIDENTIAL",
    },
    {
      id: 3,
      label: "Mid-Range (₹4,000 to ₹7,000)",
      value: "MID_RANGE_RESIDENTIAL",
    },
    {
      id: 5,
      label: "Luxury (₹7,000 to ₹12,000)",
      value: "LUXURY_RESIDENTIAL",
    },
    {
      id: 7,
      label: "Ultra-Luxury (₹12,000+)",
      value: "ULTRA_LUXURY_RESIDENTIAL",
    },
  ];

  // Find the label for the selected property_segment
  const selectedSegmentLabel =
    projectSegments.find((segment) => segment.value === projectDetails?.property_segment)?.label || "-";

  return (
    <>
      <div className="d-flex align-items-center">
        <h4 className="inter-600">
          {currentStep}.{" "}
          {location.pathname.includes("builder")
            ? "Sample Flat Preview"
            : "Project Preview"}
        </h4>
      </div>

      {/* Updated slider to use mediaForPreview for comprehensive view */}
      {mediaForPreview && mediaForPreview.length > 0 ? (
        <>
          {/* <div className="mb-2">
            <span className="me-3">Total media: {mediaForPreview.length}</span>
            {mediaForPreview.some(item => item.isNew) && (
              <span className="badge bg-primary me-2">New Items: {mediaForPreview.filter(m => m.isNew).length}</span>
            )}
            {mediaForPreview.some(item => item.isExisting) && (
              <span className="badge bg-secondary">Existing Items: {mediaForPreview.filter(m => m.isExisting).length}</span>
            )}
          </div> */}
          <Slider className="w-100" {...settings}>
            {mediaForPreview.map((mediaItem, index) => {
              // Skip rendering if there's no file property
              if (!mediaItem || !mediaItem.file) {
                console.log("Skipping invalid media item at index", index);
                return null;
              }
              
              console.log(`Rendering media ${index}: ${mediaItem.isNew ? 'new' : 'existing'} for ${mediaItem.roomType}`);
              
              return (
                <div key={index} className="position-relative overflow-hidden">
                  {/* Room type indicator */}
                  <div
                    className="bg-gray-50 rounded p-2 position-absolute"
                    style={{ top: 16, right: 16, zIndex: 1 }}
                  >
                    {mediaItem.roomType || "Room"}
                  </div>
                  
                  {/* Products indicator */}
                  {mediaItem.products?.length > 0 && (
                    <div
                      className="bg-gray-50 rounded p-2 position-absolute"
                      style={{ top: 16, left: 16, zIndex: 1 }}
                    >
                      {mediaItem.products.length} Products Added
                    </div>
                  )}
                  
                  {/* "New" indicator for newly added media */}
                  {/* {mediaItem.isNew && (
                    <div
                      className="bg-primary text-white rounded p-1 position-absolute"
                      style={{ bottom: 16, left: 16, zIndex: 1 }}
                    >
                      New
                    </div>
                  )} */}
                  
                  <MediaSlide file={mediaItem.file} height={getSliderHeight()} />
                </div>
              );
            }).filter(Boolean)} {/* Filter out null items */}
          </Slider>
        </>
      ) : (
        <div className="text-center p-5 border rounded">
          <p>No media available for preview</p>
          {mediaForPreview ? (
            <small className="text-muted">Media array is empty</small>
          ) : (
            <small className="text-muted">Media data is undefined</small>
          )}
        </div>
      )}
      
      <div className="mb-4">
        {!status && (
          <div className="mb-4 body-text2">
            <div className="d-flex justify-content-between">
              <h4 className="fw-bold">
                {location.pathname.includes("builder")
                  ? "Sample Flat Details"
                  : "Project Details"}
              </h4>
            </div>
            <div className="my-1">
              {location.pathname.includes("builder")
                ? "Sample Flat Name"
                : "Project Name"}
              : {projectDetails.project_name}
            </div>
            <div className="my-1">Layout: {projectDetails.bhk}</div>
            <div className="my-1">
              Designed Year: {projectDetails.project_year}
            </div>
            <div className="my-1">
              Flat Area:{" "}
              {projectDetails.flat_area || projectDetails?.project_area} Sq.Ft.
            </div>
            <div className="my-1">
              {location.pathname.includes("builder")
                ? "Sample Flat Segment"
                : "Project Segment"}
              : {selectedSegmentLabel}
            </div>
            <div className="my-1">Address: {projectDetails?.address}</div>
            <div className="my-1">City: {projectDetails?.city}</div>
            <div className="my-1">State: {projectDetails?.state}</div>
            <div className="my-1">Country: {projectDetails?.country}</div>
            <div className="my-1">Pincode: {projectDetails?.pincode}</div>
            {projectDetails?.project_property?.location && (
              <div className="my-1">
                Location: {projectDetails?.project_property?.location}
              </div>
            )}
            {projectDetails?.project_property?.builder_data && (
              <div className="my-1">
                Builder: {projectDetails?.builder_data?.name || "-"}
              </div>
            )}
            <div className="my-1">Architect: {user.full_name}</div>
            <div className="my-1">
              {location.pathname.includes("builder")
                ? "Sample Flat Description"
                : "Project Description"}
              : {projectDetails?.project_description || "-"}
            </div>
            <hr />
            <p className="h6 cursor-pointer">Product Brands Used</p>
            <Row>
              {displaySelectedBrands.map((brand) => (
                <Col className="mb-1" key={brand.id} xs={12} md={4}>
                  <BrandCards brand={brand} isPreview={true} />
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default EditedProjectDetails;