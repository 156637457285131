const ProfessionalShimmerCard = () => {
  return (
    <div
      className="card-primary-hover d-flex flex-column flex-lg-row p-2 p-md-3 text-start mb-4 border w-100 mt-2"
      style={{
        boxShadow: "none",
        minHeight: "200px",
        backgroundColor: "#f6f7f8",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Shimmer effect */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%)",
          animation: "shimmer 1.5s infinite",
        }}
      ></div>

      {/* Profile Picture Shimmer */}
      <div
        className="d-flex justify-content-center align-items-center mx-auto mx-lg-0"
        style={{ width: "200px" }}
      >
        <div
          className="bg-gray-25 border rounded-circle"
          style={{
            width: "140px",
            height: "140px",
            backgroundColor: "#ddd",
          }}
        ></div>
      </div>

      {/* Content Section */}
      <div className="w-100 p-0 ps-lg-2 mt-4 text-center text-lg-start">
        <div className="d-flex flex-column flex-lg-row justify-content-between w-100 ps-3">
          {/* Details Section Shimmer */}
          <div className="flex-grow-1">
            <div className="d-flex align-items-center gap-2 flex-wrap justify-content-center justify-content-lg-start">
              <div
                className="fw-semibold fs-5 lh-sm"
                style={{
                  width: "150px",
                  height: "20px",
                  backgroundColor: "#ddd",
                }}
              ></div>
              <div
                className="d-flex align-items-center bg-danger text-white px-2 py-1 gap-1 rounded-3"
                style={{
                  width: "50px",
                  height: "26px",
                  backgroundColor: "#ddd",
                }}
              ></div>
            </div>

            <div
              className="mt-1"
              style={{
                width: "100px",
                height: "16px",
                backgroundColor: "#ddd",
              }}
            ></div>
            <div
              className="mt-1"
              style={{
                width: "120px",
                height: "16px",
                backgroundColor: "#ddd",
              }}
            ></div>

            {/* Project and Expertise Details Shimmer */}
            <div className="d-flex flex-wrap gap-2 mt-2 justify-content-center justify-content-lg-start">
              <div
                className="px-2 py-1 border rounded"
                style={{
                  width: "120px",
                  height: "20px",
                  backgroundColor: "#ddd",
                }}
              ></div>
              <div
                className="px-2 py-1 border rounded"
                style={{
                  width: "150px",
                  height: "20px",
                  backgroundColor: "#ddd",
                }}
              ></div>
              <div
                className="px-2 py-1 border rounded"
                style={{
                  width: "130px",
                  height: "20px",
                  backgroundColor: "#ddd",
                }}
              ></div>
            </div>
          </div>

          {/* Buttons Section Shimmer */}
          <div className="d-flex gap-2 mt-3 mt-lg-0 justify-content-center justify-content-lg-start">
            <div
              className="social-btn py-2"
              style={{
                width: "40px",
                height: "40px",
                backgroundColor: "#ddd",
                borderRadius: "50%",
              }}
            ></div>
            <div
              className="social-btn py-2"
              style={{
                width: "40px",
                height: "40px",
                backgroundColor: "#ddd",
                borderRadius: "50%",
              }}
            ></div>
          </div>
        </div>
      </div>

      <style>{`
          @keyframes shimmer {
            0% { transform: translateX(-100%); }
            100% { transform: translateX(100%); }
          }
        `}</style>
    </div>
  );
};

export default ProfessionalShimmerCard;
