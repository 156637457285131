import { useEffect, useRef, useState } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Image,
    InputGroup,
    Row,
    Spinner,
} from "react-bootstrap";
import { LuMail } from "react-icons/lu";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import { userUpdate } from "reduxFiles/reducer/authSlices";
import { useDispatch } from "react-redux";
import { FiEdit3, FiRefreshCcw } from "react-icons/fi";
import { FaSave } from "react-icons/fa";
import { default_pfp } from "assets";

const AccountEditFormCustomer = ({ user }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({});
    const [hover, setHover] = useState(false);
    const [pincodeError, setPincodeError] = useState("");
    const [isPincodeLoading, setIsPincodeLoading] = useState(false);
    const [previewPic, setPreviewPic] = useState("");
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const form = useRef(null);

    const [formValues, setFormValues] = useState({
        full_name: user?.full_name || "",
        mobile: user?.mobile || "",
        pincode: user?.pincode || "",
        address: user?.address || "",
        city: user?.city || "",
        state: user?.state || "",
        country: user?.country || "",
    });

    const requiredFields = {
        full_name: "Name",
        mobile: "Phone Number",
        address: "Address",
        pincode: "Pincode",
        city: "City",
        state: "State",
        country: "Country",
    };

    useEffect(() => {
        if (user) {
            setFormValues({
                full_name: user.full_name || "",
                mobile: user.mobile || "",
                pincode: user.pincode || "",
                address: user.address || "",
                city: user.city || "",
                state: user.state || "",
                country: user.country || "",
            });
            setPreviewPic(user.profile_picture);
        }
    }, [user]);

    const handleProfilePicUpdate = async (e) => {
        const file = e.target.files[0];
        if (!file) return notify("No file selected", "error");

        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        const maxSize = 2 * 1024 * 1024;

        if (!allowedTypes.includes(file.type)) {
            return notify("Only JPG, JPEG, and PNG files are allowed", "error");
        }

        if (file.size > maxSize) {
            return notify("File size must be less than 2MB", "error");
        }

        const currentProfilePic = previewPic;

        const previewUrl = URL.createObjectURL(file);
        setPreviewPic(previewUrl);

        const formData = new FormData();
        formData.append("profile_picture", file);

        try {
            const response = await authActions.updateProfilePic(
                user?.id,
                formData
            );
            if (response.data) {
                notify("Profile Picture Updated!", "success");
                dispatch(userUpdate(response.data));
            }
        } catch (error) {
            console.log(error);
            setPreviewPic(currentProfilePic);
            notify("Failed to update profile picture", "error");
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({ ...prev, [name]: value }));
        if (value.trim() && fieldErrors[name]) {
            setFieldErrors((prev) => ({ ...prev, [name]: false }));
        }
    };

    const getPincode = async (pincode) => {
        setIsPincodeLoading(true);
        setPincodeError("");

        try {
            const response = await authActions.fetchPincode(pincode);
            if (response && response.District && response.State && response.Country) {
                setFormValues((prev) => ({
                    ...prev,
                    city: response.District,
                    state: response.State,
                    country: response.Country,
                }));
                setFieldErrors((prev) => ({
                    ...prev,
                    city: false,
                    state: false,
                    country: false,
                }));
            } else {
                setPincodeError("Invalid pincode. Please enter a valid pincode.");
                setFormValues((prev) => ({
                    ...prev,
                    city: "",
                    state: "",
                    country: "",
                }));
            }
        } catch (error) {
            console.error("Error fetching pincode details:", error);
            setPincodeError(
                "Failed to fetch location details. Please check the pincode."
            );
            setFormValues((prev) => ({
                ...prev,
                city: "",
                state: "",
                country: "",
            }));
        } finally {
            setIsPincodeLoading(false);
        }
    };

    const handlePincodeChange = (e) => {
        const newPincode = e.target.value;
        if (/^\d{0,6}$/.test(newPincode)) {
            setFormValues((prev) => ({ ...prev, pincode: newPincode }));
            setFieldErrors((prev) => ({ ...prev, pincode: !newPincode }));
            if (newPincode.length === 6) {
                getPincode(newPincode);
            } else {
                setFormValues((prev) => ({
                    ...prev,
                    city: "",
                    state: "",
                    country: "",
                }));
                setPincodeError("");
            }
        }
    };


    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (isPincodeLoading) {
            notify("Fetching location details. Please wait...", "info");
            setLoading(false);
            return;
        }

        const newFieldErrors = {};
        let hasErrors = false;
        const errorMessages = [];

        for (const [fieldName, displayName] of Object.entries(requiredFields)) {
            const value = formValues[fieldName];
            if (!value || value.toString().trim() === "") {
                newFieldErrors[fieldName] = true;
                hasErrors = true;
                errorMessages.push(`${displayName} is required`);
            } else {
                newFieldErrors[fieldName] = false;
            }
        }

        setFieldErrors(newFieldErrors);

        if (hasErrors) {
            notify(
                `Please fill in all required fields:\n${errorMessages.join("\n")}`,
                "error"
            );
            setLoading(false);
            return;
        }

        const formData = new FormData(form.current);
        formData.append("id", user?.id);

        Object.entries(formValues).forEach(([key, value]) => {
            formData.set(key, value);
        });

        let data = {};
        formData.forEach((value, key) => {
            // Trim the value and replace empty strings or whitespace-only strings with null
            const trimmedValue = typeof value === "string" ? value.trim() : value;
            data[key] = trimmedValue === "" ? null : trimmedValue;
        });

        try {
            const response = await authActions.updateProfile(data.id, data);
            if (response.data) {
                notify("Profile Updated Successfully!", "success");
                dispatch(userUpdate(response.data));
                setIsEdit(false);
            } else {
                notify("Error while updating profile!", "error");
            }
        } catch (error) {
            console.error(error);
            notify("Failed to update profile. Please try again.", "error");
        }
        setLoading(false);
    };

    console.log(user, "user")
    return (
        <Form
            ref={form}
            onSubmit={handleUpdate}
            className="border border-gray-100 mb-4"
            style={{ borderRadius: 6 }}
        >
            <div className="d-flex justify-content-between p-3">
                <div className={`h4 inter-600 mt-2`}>Contact Information</div>
                <div
                    className="mt-2 me-2 cursor-pointer"
                    onClick={() => setIsEdit(!isEdit)}
                >
                    <FiEdit3 size={20} />
                </div>
            </div>
            <Card
                className="d-flex justify-content-center position-relative overflow-hidden cursor-pointer shadow-none p-0 mx-3 mb-3"
                style={{
                    width: 180,
                    height: 150,
                    border: "1px dashed #BDC1C6",
                    borderRadius: 6,
                }}
            >
                <Image
                    className="p-0"
                    style={{ borderRadius: 6, objectFit: "cover" }}
                    maxWidth={180}
                    maxHeight={150}
                    src={previewPic || default_pfp}
                    alt="avatar"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                />
                {hover && (
                    <div
                        className="position-absolute w-100 h-100 top-0 start-0 body-text2 text-white d-flex flex-column align-items-center justify-content-center"
                        style={{
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            borderRadius: 6,
                        }}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        onClick={() => document.getElementById("profileUpload").click()}
                    >
                        <FiRefreshCcw size={20} color="white" />
                        <span className="mt-1">Reupload Profile Image</span>
                    </div>
                )}
                <input
                    type="file"
                    id="profileUpload"
                    style={{ display: "none" }}
                    onChange={handleProfilePicUpdate}
                />
            </Card>
            <Row className="px-3 pb-3">
                <Col sm={12} lg={6}>
                    <Form.Group className="mb-3 user-select-none">
                        <Form.Label className="mb-1 body-text2 inter-500">
                            Name<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                            required
                            type="text"
                            disabled={!isEdit}
                            className={`body-text2 py-2 border ${fieldErrors.full_name ? "border-danger" : "border-gray-300"
                                } ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                            name="full_name"
                            value={formValues.full_name || user?.full_name}
                            onChange={handleInputChange}
                            style={{ borderRadius: 3 }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 user-select-none">
                        <Form.Label className="mb-1 body-text2 inter-500">
                            Email<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <InputGroup
                            className="border border-gray-300 bg-disabled"
                            style={{ borderRadius: 3 }}
                        >
                            <InputGroup.Text
                                className="bg-disabled border-0"
                                style={{ borderRadius: 3 }}
                            >
                                <LuMail size={20} />
                            </InputGroup.Text>
                            <div
                                className={`ms-2 py-2 body-text2 border-0 bg-tertiary text-gray-600`}
                            >
                                {user?.email}
                            </div>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3 user-select-none">
                        <Form.Label className="mb-1 body-text2 inter-500">
                            Phone Number<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <InputGroup
                            className={`border ${fieldErrors.mobile ? "border-danger" : "border-gray-300"
                                }`}
                            style={{ borderRadius: 3 }}
                        >
                            <InputGroup.Text
                                className={`border-0 body-text2 inter-500 ${isEdit ? "bg-white" : "bg-disabled"
                                    }`}
                                style={{ borderRadius: 3 }}
                            >
                                +91
                            </InputGroup.Text>
                            <Form.Control
                                disabled={!isEdit}
                                className={`body-text2 py-2 border-0 ${isEdit ? "text-gray-900" : "text-gray-600"
                                    }`}
                                placeholder="Phone number"
                                name="mobile"
                                value={formValues.mobile || user?.mobile}
                                required
                                type="tel"
                                pattern="[0-9]{10}"
                                maxLength={10}
                                onChange={handleInputChange}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, "");
                                }}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="user-select-none mb-3">
                        <Form.Label className="mb-1 body-text2 inter-500">
                            Address<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                            required
                            type="text"
                            disabled={!isEdit}
                            className={`body-text2 py-2 border ${fieldErrors.address ? "border-danger" : "border-gray-300"
                                } ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                            name="address"
                            value={formValues.address || user?.address}
                            onChange={handleInputChange}
                            style={{ borderRadius: 3 }}
                        />
                    </Form.Group>
                    <Row>
                        <Col sm={6}>
                            <Form.Group className="user-select-none mb-3">
                                <Form.Label className="mb-1 body-text2 inter-500">
                                    Pincode<sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        required
                                        type="text"
                                        disabled={!isEdit}
                                        className={`body-text2 py-2 border ${fieldErrors.pincode ? "border-danger" : "border-gray-300"
                                            } ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                                        name="pincode"
                                        value={formValues.pincode}
                                        onChange={handlePincodeChange}
                                        maxLength={6}
                                        pattern="\d{6}"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g, "");
                                        }}
                                        style={{ borderRadius: 3 }}
                                    />
                                    {isPincodeLoading && (
                                        <InputGroup.Text>
                                            <Spinner animation="border" size="sm" />
                                        </InputGroup.Text>
                                    )}
                                </InputGroup>
                                {pincodeError && (
                                    <Form.Text className="text-danger">{pincodeError}</Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="user-select-none mb-3">
                                <Form.Label className="mb-1 body-text2 inter-500">
                                    City<sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    readOnly
                                    className={`body-text2 py-2 border ${fieldErrors.city ? "border-danger" : "border-gray-300"
                                        } ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                                    name="city"
                                    value={formValues.city || user?.city}
                                    style={{ borderRadius: 3, backgroundColor: "#f8f9fa" }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6}>
                            <Form.Group className="user-select-none mb-3">
                                <Form.Label className="mb-1 body-text2 inter-500">
                                    State<sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    readOnly
                                    className={`body-text2 py-2 border ${fieldErrors.state ? "border-danger" : "border-gray-300"
                                        } ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                                    name="state"
                                    value={formValues.state || user?.state}
                                    style={{ borderRadius: 3, backgroundColor: "#f8f9fa" }}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="user-select-none mb-3">
                                <Form.Label className="mb-1 body-text2 inter-500">
                                    Country<sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    readOnly
                                    className={`body-text2 py-2 border ${fieldErrors.country ? "border-danger" : "border-gray-300"
                                        } ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                                    name="country"
                                    value={formValues.country || user?.country}
                                    style={{ borderRadius: 3, backgroundColor: "#f8f9fa" }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {isEdit && (
                <div className="d-flex p-3">
                    <Button
                        className="bg-transparent inter-500 border-gray-200 text-gray-900 body-text2"
                        style={{ borderRadius: 3 }}
                        onClick={() => setIsEdit(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        className="inter-500 body-text2 text-white ms-2 d-flex justify-content-center align-items-center"
                        style={{ borderRadius: 3 }}
                        disabled={loading}
                    >
                        {loading ? (
                            <>
                                <Spinner animation="border" size="sm" className="me-2" />{" "}
                                Saving...
                            </>
                        ) : (
                            <>
                                <FaSave size={18} className="me-2" />
                                Save Profile
                            </>
                        )}
                    </Button>
                </div>
            )}
        </Form>
    )
}

export default AccountEditFormCustomer;