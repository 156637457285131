import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import Review from "components/Review/Review";
import * as authActions from "reduxFiles/actions/authActions";
import ProjectCard from "components/Cards/ProjectCard";
import { useSelector } from "react-redux";
import { notify } from "utilities/notifications/Notify";
import TeamMember from "components/TeamMember/TeamMember";
import Gallery from "components/Gallery/Gallery";
import TeamMemberList from "components/TeamMember/TeamMemberList";
import RatingStarsView from "components/General/RatingStarsView";
import userImg from "../../../assets/navbar/user.webp";
import { RiEditLine } from "react-icons/ri";

const DesignerProfile = () => {
  const navigate = useNavigate();
  const { detailedSlug } = useParams();
  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  const user = useSelector((state) => state.auth.user);
  const form = useRef();

  const [professionalDetails, setProfessionalDetails] = useState(null);
  const [projects, setProjects] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [showShare, setShowShare] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [teamMembers, setTeamMembers] = useState([]);
  const [media, setMedia] = useState([]);
  // Reference for form inputs
  const formRef = useRef();

  const [rating, setRating] = useState(0); // For whole number ratings
  const [hover, setHover] = useState(null);

  const handleStarClick = (value) => {
    setRating(value);
  };

  const fetchArchitectsBySlug = async () => {
    setLoading(false);
    setError("");
    try {
      //fetchBrandByUser
      const response = await authActions.fetchArchitectByUser(user.id);
      if (response.data) {
        if (response.data.results[0]?.id) {
          // fetchProjects(response.data.results[0].id);
          setProfessionalDetails(response.data.results[0]);
        }
      } else {
        setError(
          "Error while loading professionals's details. Try again later!"
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(true);
  };

  console.log(professionalDetails, "ProfessionalDetails");

  const fetchProjects = async (id) => {
    setLoading(true);
    try {
      //loadProjectsFilters
      const response = await authActions.fetchProjectsByArchitect(id);
      if (response.data) {
        setProjects(response.data?.results);
      } else {
        setError("Error while loading project details");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchReviews = async (id) => {
    try {
      const resReviews = await authActions.fetchReviewsByArchitectUser(id);
      if (resReviews?.data?.results) {
        setReviews(resReviews.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkProfessionalIfSaved = async () => {
    try {
      const response = await authActions.checkProfessionalIfSaved(
        user.id,
        professionalDetails.id
      );
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProfessional = async () => {
    try {
      const response = await authActions.saveProfessional(
        user.id,
        professionalDetails.id
      );
      if (response.data) {
        setIsLiked(true);
        notify("Professional saved successfully", "success");
      } else {
        notify("Failed to save professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProfessional = async () => {
    try {
      const response = await authActions.unsaveProfessional(
        user.id,
        professionalDetails.id
      );
      if (response) {
        setIsLiked(false);
        notify("Professional unsaved successfully", "success");
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTeamMembers = async () => {
    try {
      const response = await authActions.fetchTeamMembers({ user_id: user.id });
      if (response) {
        console.log(response.data);
        // notify("Professional unsaved successfully", "success");
        setTeamMembers(response.data);
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGalleries = async () => {
    try {
      const response = await authActions.fetchGallery({ user_id: user.id });
      if (response) {
        // notify("Professional unsaved successfully", "success");
        setMedia(response.data);
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatCurrency = (amount, currency = "INR") => {
    if (isNaN(amount) || amount === null) return "N/A";

    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 2 * 1024 * 1024; // 2MB

    if (!allowedTypes.includes(file.type)) {
      setErrorMessage("Only JPG, JPEG, and PNG files are allowed.");
      return;
    }

    if (file.size > maxSize) {
      setErrorMessage("Image size must be less than 2MB.");
      return;
    }

    setErrorMessage(""); // Clear error if valid
    // Handle valid file (e.g., update state, preview image, upload)
  };

  const handleAddTeamMember = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!formRef.current) return;

    const formData = new FormData(formRef.current);

    // Ensure the image meets the size requirement before submitting
    const imageFile = formRef.current.image.files[0];
    if (imageFile && imageFile.size > MAX_FILE_SIZE) {
      notify("File size exceeds 2MB. Please upload a smaller image.", "error");
      setIsSubmitting(false);
      return;
    }

    // Append user ID
    if (user.id) {
      formData.append("user_id", user.id);
    }

    try {
      const response = await authActions.addTeamMember(formData);

      if (response.status === 201) {
        notify("Team member added successfully", "success");
        setShowAddTeamModal(false);
        fetchTeamMembers(); // Refresh the team members list
      } else {
        notify("Failed to add team member", "error");
      }
    } catch (error) {
      console.error("Error while adding team member:", error);
      notify("Error while adding team member", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteMember = async (id) => {
    setIsSubmitting(true);

    // Get form data from the form reference

    try {
      const response = await authActions.deleteTeamMember(id); // API call to add a team member
      if (response.status === 204) {
        notify("Team member deleted successfully", "success");
        // setShowAddTeamModal(false);
        fetchTeamMembers(); // Refresh the team members list
      } else {
        notify("Failed to delete team member", "error");
      }
    } catch (error) {
      console.log(error);
      notify("Error while deleting team member", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (user.id) {
      fetchArchitectsBySlug();
      fetchTeamMembers();
      fetchGalleries();
    }
  }, [user]);

  useEffect(() => {
    if (professionalDetails?.id) {
      checkProfessionalIfSaved();
      fetchProjects(professionalDetails?.id);
    }
  }, [professionalDetails]);

  useEffect(() => {
    if (professionalDetails) {
      fetchReviews(professionalDetails?.user_data.id);
    }
  }, [professionalDetails]);

  return (
    <Container fluid className="mt-5 pt-4 p-0">
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Spinner />
        </div>
      ) : error ? (
        <div className="h3 inter-600 text-center">{error}</div>
      ) : (
        <div>
          <div className="bg-gray-25 geist">
            <Container>
              <Row className="py-3 border-bottom m-0">
                <Col lg={9} xl={10}>
                  <div className="d-flex align-items-center mb-2">
                    <div className="h2 inter-600 text-capitalize">
                      {professionalDetails?.user_data.full_name}
                    </div>
                    {user && user.user_type === "customer" && (
                      <div className="d-flex ms-auto">
                        <Button
                          className="d-flex justify-content-center align-items-center social-btn py-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowShare(true);
                          }}
                        >
                          <FiSend size={18} />
                        </Button>

                        <Button
                          className={`d-flex justify-content-center align-items-center social-btn py-2 ms-2 ${isLiked ? "bg-primary text-white" : ""
                            }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            isLiked
                              ? handleUnsaveProfessional()
                              : handleSaveProfessional();
                          }}
                        >
                          <FaRegHeart size={18} />
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="body-text inter-600 text-capitalize">
                    {professionalDetails?.company_name ??
                      detailedSlug?.split("-").join(" ")}
                  </div>
                  <div className="d-flex align-items-center body-text inter-600">
                    Ratings: {professionalDetails?.rating}
                    <FaStar color="#D95656" />
                  </div>
                  {(professionalDetails?.user_data?.pincode != null) && (
                    <div>
                      <span className="body-text inter-600">Location:</span>{" "}
                      {[
                        professionalDetails?.user_data?.address,
                        professionalDetails?.user_data?.city,
                        professionalDetails?.user_data?.state,
                        professionalDetails?.user_data?.country,
                      ]
                        .filter(Boolean) // Remove empty or null values
                        .join(", ") || "N/A"}{" "}
                      - {professionalDetails?.user_data?.pincode}
                    </div>
                  )}
                  <div>
                    <span className="body-text inter-600">
                      Delivered Projects:
                    </span>{" "}
                    {professionalDetails?.delivered_projects}
                  </div>
                  <div>
                    <span className="body-text inter-600">Passing Year:</span>{" "}
                    {professionalDetails?.passing_year}
                  </div>
                  <div>
                    <span className="body-text inter-600">College:</span>{" "}
                    {professionalDetails?.college}
                  </div>
                  <div>
                    <span className="body-text inter-600">Team Size:</span>{" "}
                    {professionalDetails?.team_size}
                  </div>
                  <div>
                    <span className="body-text inter-600">
                      {professionalDetails?.interest_of_service
                        ? professionalDetails.interest_of_service
                          .charAt(0)
                          .toUpperCase() +
                        professionalDetails.interest_of_service.slice(1)
                        : ""}{" "}
                      Job Cost:
                    </span>{" "}
                    {formatCurrency(professionalDetails?.job_cost)}
                  </div>
                  <div>
                    <span className="body-text inter-600">
                      Working Experience:
                    </span>{" "}
                    {professionalDetails?.working_experience}
                  </div>
                  {/* <div>
                    <span className="body-text inter-600">
                      Number of cities:
                    </span>{" "}
                    {professionalDetails?.no_of_cities}
                  </div> */}
                  <div>
                    <span className="body-text inter-600">Designed for:</span>{" "}
                    {professionalDetails?.project_names.length > 50
                      ? professionalDetails?.project_names.slice(0, 130) + "..."
                      : professionalDetails?.project_names}
                  </div>
                  {user && user.user_type === "customer" && (
                    <Button
                      className="text-white mt-3 mb-4"
                      onClick={() => {
                        if (professionalDetails?.id) {
                          navigate(`/consultation/architect/${detailedSlug}`, {
                            state: { architect: professionalDetails },
                          });
                        } else {
                          notify(
                            "Professional undefined, try again later",
                            "error"
                          );
                        }
                      }}
                    >
                      Book Free Consultation
                    </Button>
                  )}
                </Col>
                <Col lg={3} xl={2} className="position-relative">
                  <div className="position-relative">
                    {" "}
                    {/* Added wrapper for icon positioning */}
                    <div className="d-flex justify-content-start justify-content-lg-center">
                      <div
                        className="d-flex justify-content-center align-items-center bg-white ms-lg-auto rounded-circle overflow-hidden"
                        style={{ width: 150, height: 150 }}
                      >
                        <img
                          className="border rounded-circle"
                          width={150}
                          height={150}
                          style={{
                            border: "1px solid #D0D5DD",
                            objectFit: "cover",
                          }}
                          src={
                            professionalDetails?.user_data?.profile_picture ||
                            userImg
                          }
                          alt="Architect's Profile"
                        />
                      </div>
                    </div>
                    {/* Moved edit icon outside the overflow:hidden container */}
                    <div
                      className="position-absolute"
                      style={{ top: 0, right: -30 }}
                    >
                      <RiEditLine
                        onClick={() =>
                          navigate(`/${user.user_type}/account/settings/#edit`)
                        }
                        size={24}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Container className="py-3">
            <div className="mb-3">
              <div className="geist body-title mb-3">
                <span className="inter-700">
                  {projects?.length} real project designs
                </span>{" "}
                created
              </div>
              {projects?.length > 0 ? (
                <Row>
                  {projects?.map((item, index) => (
                    <Col
                      lg={4}
                      key={index}
                      className="d-flex justify-content-around my-3"
                    >
                      <ProjectCard
                        item={item}
                        {...item}
                        id={item.id}
                        project_name={item?.project_name}
                        // image={item?.rooms[0]?.media[0]?.file}
                        roomId={item?.rooms[0]?.id}
                        type={`${item?.bhk}, ${item?.project_area}`}
                        architect={item?.company_name}
                        location={item?.property_data?.location}
                        is_sample_flat={item?.is_sample_flat}
                        builders={
                          item?.property_data?.builder_data?.company_name
                        }
                        removeFavourite={true}
                      />
                    </Col>
                  ))}
                </Row>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                  style={{ height: 160 }}
                >
                  No projects available
                </div>
              )}
            </div>

            <div className="mb-4">
              <div className="h4 inter-600">Reviews and Ratings</div>
              {reviews?.length > 0 ? (
                reviews.map((review, index) => (
                  <Review key={index} {...review} />
                ))
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                  style={{ height: 160 }}
                >
                  No reviews available
                </div>
              )}
            </div>
            <div className="mb-4">
              <div className="h4 inter-600">Meet The Team!</div>
              <div className="py-3">
                {teamMembers?.length > 0 ? (
                  <div className="container">
                    <div className="row">
                      {teamMembers.map((teamMember, index) => (
                        <div
                          key={index}
                          className="col-5 col-sm-6 col-md-2 mb-3"
                        >
                          <TeamMember
                            {...teamMember}
                            handleDelete={handleDeleteMember}
                          />
                        </div>
                      ))}
                      <div
                        className="col-5 col-sm-6 col-md-2 mb-3"
                        onClick={() => setShowAddTeamModal(true)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="d-flex flex-column justify-content-start">
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              width: "100px",
                              height: "100px",
                              border: "1px solid #E8EAED",
                              borderRadius: "50%",
                              backgroundColor: "#eee",
                            }}
                          >
                            <span style={{ fontSize: 40, color: "#ccc" }}>
                              +
                            </span>
                          </div>
                          <div
                            className="text-muted body-text-2 "
                            style={{ marginTop: "10px" }}
                          >
                            Add Team Member
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="col-5 col-sm-6 col-md-2 mb-3"
                    onClick={() => setShowAddTeamModal(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="d-flex flex-column justify-content-start">
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          width: "100px",
                          height: "100px",
                          border: "1px solid #E8EAED",
                          borderRadius: "50%",
                          backgroundColor: "#eee",
                        }}
                      >
                        <span style={{ fontSize: 40, color: "#ccc" }}>
                          +
                        </span>
                      </div>
                      <div
                        className="text-muted body-text-2 "
                        style={{ marginTop: "10px" }}
                      >
                        Add Team Member
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="mb-4">
            <div className="h4 inter-600">Get To Know Us!</div>
              <Gallery
                items={media}
                fetchGalleries={fetchGalleries}
                enableEdit={user?.user_type === "architect"}
              />
            </div>
          </Container>
        </div>
      )}

      <Modal show={showAddTeamModal} onHide={() => setShowAddTeamModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={(e) => handleAddTeamMember(e)}>
            <Form.Group className="mb-3">
              <Form.Label>
                Name<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control type="text" name="name" placeholder="Enter name" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Role<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control type="text" name="role" placeholder="Enter role" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Profile Picture</Form.Label>
              <Form.Control
                type="file"
                name="image"
                accept="image/jpeg, image/jpg, image/png"
                onChange={handleImageChange}
              />
              {errorMessage && <p className="text-danger">{errorMessage}</p>}
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowAddTeamModal(false)}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default DesignerProfile;
