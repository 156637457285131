import ProductEnquiryForm from "components/Forms/ProductEnquiryForm";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useFetchMedia from "utilities/customHooks/useFetchMedia";

const ProductCard = ({
  id,
  brand_slug,
  product_data,
  product_category,
  product_category_data,
  productId,
  title,
  brand_name,
  description,
  productTypeImage,
  isEnquiry = false,
  isLast,
  productDataId,
  className,
  style,
}) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const { image, loading, error } = useFetchMedia(
    "product",
    "product",
    productId || productDataId
  );

  return (
    <>
      <div
        className={`d-flex ${isLast ? "mb-5" : "mb-2"} ${className}`}
        style={style}
      >
        {!loading ? (
          <div>
            <div
              className="bg-gray-25 rounded overflow-hidden"
              style={{ width: 120, height: 120 }}
            >
              <img
                className="img-fluid w-100"
                src={image ?? productTypeImage}
                alt={title}
                style={{ objectFit: "cover", width: 120, height: 120 }}
              />
            </div>
          </div>
        ) : (
          <div>
            <p>Loading..</p>
          </div>
        )}
        <div className="d-flex flex-column w-100 ps-3">
          <div className="mb-2">
            <div className="body-text1 inter-600">{title}</div>
            <div className="body-text2 inter-600">{brand_name}</div>
            <div className="body-text2 ">{description}</div>
          </div>
          <div>
            {!isEnquiry && (
              <div className="text-end">
                <Button
                  size="sm"
                  className="bg-gray"
                  onClick={() =>
                    navigate(
                      `/brands/${
                        product_data ? product_data.brand_slug : brand_slug
                      }?product_category=${product_category}&category_name=${
                        product_category_data?.title
                      }`
                    )
                  }
                >
                  Know more
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal show={show} centered onHide={() => setShow(false)}>
        <Modal.Header className="border-0" closeButton />
        <Modal.Body>
          <ProductEnquiryForm productId={productId} setShow={setShow} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductCard;
