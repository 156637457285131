// import { useEffect, useRef, useState } from "react";
// import {
//   Button,
//   Card,
//   Col,
//   Form,
//   Image,
//   InputGroup,
//   Row,
// } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { FaSave } from "react-icons/fa";
// import { LuMail } from "react-icons/lu";
// import { FiEdit3, FiRefreshCcw } from "react-icons/fi";

// import * as authActions from "reduxFiles/actions/authActions";
// import { userUpdate } from "reduxFiles/reducer/authSlices";
// import { notify } from "utilities/notifications/Notify";
// import { useLocation } from "react-router-dom";
// import { default_pfp } from "assets";

// function BuilderAccountEditForm() {
//   const location = useLocation();
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.auth.user);

//   const form = useRef(null);
//   const [isEdit, setIsEdit] = useState(false);
//   const [hover, setHover] = useState(false);

//   const [builderInfo, setBuilderInfo] = useState(null);
//   // Initialize expertiseType using an empty string (will update when data is fetched)
//   const [expertiseType, setExpertiseType] = useState("");
//   const [builderLocation, setBuilderLocation] = useState({
//     address_line_1: "",
//     address_line_2: "",
//     city: "",
//     state: "",
//     country: "",
//     pincode: "",
//   });

//   const fetchBuilderInfoData = async () => {
//     try {
//       const response = await authActions.fetchBuildersDataByUser(user.id);
//       console.log(response, "response");
//       if (response.data) {
//         const info = response.data?.results[0];
//         setBuilderInfo(info);
//         // Update expertiseType state from the fetched data
//         setExpertiseType(info.expertise_type || "");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   // Update builderLocation once builderInfo is fetched
//   useEffect(() => {
//     if (builderInfo) {
//       setBuilderLocation({
//         address_line_1: builderInfo.address_line_1 || "",
//         city: builderInfo.city || "",
//         state: builderInfo.state || "",
//         country: builderInfo.country || "",
//         pincode: builderInfo.pincode || "",
//       });
//     }
//   }, [builderInfo]);

//   const handleProfilePicUpdate = async (e) => {
//     const file = e.target.files[0];
//     const formData = new FormData();
//     formData.append("profile_picture", file);
//     try {
//       const response = await authActions.updateProfilePic(user.id, formData);
//       if (response.data) {
//         notify("Profile Picture Updated!", "success");
//         dispatch(userUpdate(response.data));
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleUpdate = async (e) => {
//     e.preventDefault();
//     const formData = new FormData(form.current);
//     formData.append("id", user.id);
//     let data = {};
//     formData.forEach((value, key) => {
//       if (value !== "") {
//         data[key] = value;
//       }
//     });
//     // Include expertiseType from state (overriding if necessary)
//     data.expertise_type = expertiseType;
//     try {
//       const response = await authActions.updateBuilder(builderInfo.id, data);
//       if (response.data) {
//         notify("Profile Updated Successfully!", "success");
//         // dispatch(userUpdate(response.data));
//         setIsEdit(false);
//       } else {
//         notify("Error while updating profile!", "error");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const getBuilderPincode = async (pincode) => {
//     try {
//       if (pincode.length < 6) return;
//       const response = await authActions.fetchPincode(pincode);
//       setBuilderLocation((prev) => ({
//         ...prev,
//         city: response?.District || "",
//         state: response?.State || "",
//         country: response?.Country || ""
//       }));
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleBuilderPincodeChange = (e) => {
//     const newPincode = e.target.value;
//     setBuilderLocation((prev) => ({ ...prev, pincode: newPincode }));
//     if (newPincode.length === 6) {
//       getBuilderPincode(newPincode);
//     }
//   };

//   // Phone number validation
//   const validatePhoneNumber = (phone) => {
//     // First check if phone is empty
//     if (!phone || phone.trim() === "") {
//       notify("Phone number is required", "error");
//       return false;
//     }

//     // Then validate format
//     const phoneRegex = /^\d+$/;
//     if (!phoneRegex.test(phone)) {
//       notify("Phone number must contain only digits", "error");
//       return false;
//     }

//     // Validate length
//     if (phone.length !== 10) {
//       notify("Phone number must be exactly 10 digits", "error");
//       return false;
//     }

//     return true;
//   };

//   useEffect(() => {
//     fetchBuilderInfoData();
//   }, [user]);

//   useEffect(() => {
//     if (location.hash !== "") {
//       if (location.hash === "#edit") {
//         setIsEdit(true);
//       }
//     }
//   }, [location]);

//   return (
//     <Form
//       ref={form}
//       onSubmit={handleUpdate}
//       className="border border-gray-100 mt-5 mb-4"
//       style={{ borderRadius: 6 }}
//     >
//       <div className="d-flex justify-content-between p-3">
//         <div className={`h4 inter-600 mt-2`}>Profile Information</div>
//         <div
//           className="mt-2 me-2 cursor-pointer"
//           onClick={() => setIsEdit(!isEdit)}
//         >
//           <FiEdit3 size={20} />
//         </div>
//       </div>
//       <Row className="px-3 pb-3">
//         <Col lg={5}>
//           <Card
//             className="d-flex justify-content-center position-relative overflow-hidden cursor-pointer shadow-none p-0 mb-3"
//             style={{
//               width: 180,
//               height: 150,
//               border: "1px dashed #BDC1C6",
//               borderRadius: 6,
//             }}
//           >
//             <Image
//               className="p-0"
//               style={{ borderRadius: 6, objectFit: "cover" }}
//               maxWidth={180}
//               maxHeight={150}
//               src={user?.profile_picture || default_pfp}
//               alt="avatar"
//               onMouseEnter={() => setHover(true)}
//               onMouseLeave={() => setHover(false)}
//             />
//             {hover && (
//               <div
//                 className="position-absolute w-100 h-100 top-0 start-0 body-text2 text-white d-flex flex-column align-items-center justify-content-center"
//                 style={{
//                   backgroundColor: "rgba(0, 0, 0, 0.5)",
//                   borderRadius: 6,
//                 }}
//                 onMouseEnter={() => setHover(true)}
//                 onMouseLeave={() => setHover(false)}
//                 onClick={() => document.getElementById("profileUpload").click()}
//               >
//                 <FiRefreshCcw size={20} color="white" />
//                 <span className="mt-1">Reupload Profile Image</span>
//               </div>
//             )}
//             <input
//               type="file"
//               id="profileUpload"
//               style={{ display: "none" }}
//               onChange={handleProfilePicUpdate}
//             />
//           </Card>
//           <Form.Group className="mb-3 user-select-none">
//             <Form.Label className="mb-1 body-text2 inter-500">
//               Company Name<sup style={{ color: "red" }}>*</sup>
//             </Form.Label>
//             <Form.Control
//               required
//               type="text"
//               disabled={!isEdit}
//               className={`body-text2 py-2 border border-gray-300 ${isEdit ? "text-gray-900" : "text-gray-600"
//                 }`}
//               name="full_name"
//               defaultValue={builderInfo?.company_name}
//               style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
//             />
//           </Form.Group>
//           <Form.Group className="mb-3 user-select-none">
//             <Form.Label className="mb-1 body-text2 inter-500">
//               Company Email<sup style={{ color: "red" }}>*</sup>
//             </Form.Label>
//             <InputGroup
//               className="border border-gray-300 bg-disabled"
//               style={{ borderRadius: 3 }}
//             >
//               <InputGroup.Text
//                 className="bg-disabled border-0"
//                 style={{ borderRadius: 3 }}
//               >
//                 <LuMail size={20} />
//               </InputGroup.Text>
//               <div
//                 className={`ms-2 py-2 body-text2 border-0 bg-tertiary text-gray-600`}
//               >
//                 {user?.email}
//               </div>
//             </InputGroup>
//           </Form.Group>
//           <Form.Group className="mb-3 user-select-none">
//             <Form.Label className="mb-1 body-text2 inter-500">
//               Phone Number<sup style={{ color: "red" }}>*</sup>
//             </Form.Label>
//             <InputGroup
//               className="border border-gray-300"
//               style={{ borderRadius: 3 }}
//             >
//               <InputGroup.Text
//                 className={`border-0 body-text2 inter-500 ${isEdit ? "bg-white" : "bg-disabled"
//                   }`}
//                 style={{ borderRadius: 3 }}
//               >
//                 +91
//               </InputGroup.Text>
//               <Form.Control
//                 disabled={!isEdit}
//                 className={`body-text2 py-2 border-0 ${isEdit ? "text-gray-900" : "text-gray-600"
//                   }`}
//                 placeholder="Phone number"
//                 name="mobile"
//                 defaultValue={user?.mobile}
//                 required
//               />
//             </InputGroup>
//           </Form.Group>
//           {/* Expertise Type Field as a controlled component */}
//           <Form.Group className="mb-3">
//             <Form.Label className="mb-1 body-text2 inter-500">
//               Expertise Type<sup style={{ color: "red" }}>*</sup>
//             </Form.Label>
//             <Form.Select
//               required
//               disabled={!isEdit}
//               className="py-2 body-text2"
//               name="expertise_type"
//               value={expertiseType}
//               onChange={(e) => setExpertiseType(e.target.value)}
//               style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
//             >
//               <option value="">-Select-</option>
//               <option value="Residential">Residential</option>
//               <option value="Commercial">Commercial</option>
//             </Form.Select>
//           </Form.Group>
//           <Form.Group className="mb-3 d-flex">
//             <div className="w-100">
//               <Form.Label className="mb-1 body-text2 inter-500">
//                 Years of Experience<sup style={{ color: "red" }}>*</sup>
//               </Form.Label>
//               <Form.Control
//                 required
//                 type="text"
//                 disabled={!isEdit}
//                 className={`body-text2 py-2 border border-gray-300 ${isEdit ? "text-gray-900" : "text-gray-600"
//                   }`}
//                 name="years_of_experience"
//                 defaultValue={builderInfo?.years_of_experience}
//                 style={{ borderRadius: 3 }}
//               />
//             </div>
//             <div className="ms-2 w-100">
//               <Form.Label className="mb-1 body-text2 inter-500">
//                 No. of Projects<sup style={{ color: "red" }}>*</sup>
//               </Form.Label>
//               <Form.Control
//                 required
//                 type="text"
//                 disabled={!isEdit}
//                 className={`body-text2 py-2 border border-gray-300 ${isEdit ? "text-gray-900" : "text-gray-600"
//                   }`}
//                 name="project_count"
//                 defaultValue={builderInfo?.project_count}
//                 style={{ borderRadius: 3 }}
//               />
//             </div>
//           </Form.Group>
//           <Form.Group className="user-select-none">
//             <Form.Label className="mb-1 body-text2 inter-500">
//               Office / Business Address
//             </Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="PLOT NUMBER, BUILDING NAME"
//               disabled={!isEdit}
//               className={`body-text2 py-2 border border-gray-300 mb-2 ${isEdit ? "text-gray-900" : "text-gray-600"
//                 }`}
//               name="address_line_1"
//               value={builderLocation.address_line_1}
//               onChange={(e) =>
//                 setBuilderLocation((prev) => ({
//                   ...prev,
//                   address_line_1: e.target.value,
//                 }))
//               }
//               style={{ borderRadius: 3 }}
//             />
//             <Form.Control
//               type="text"
//               placeholder="Pincode"
//               disabled={!isEdit}
//               className={`body-text2 py-2 border border-gray-300 ${isEdit ? "text-gray-900" : "text-gray-600"
//                 }`}
//               name="pincode"
//               value={builderLocation.pincode}
//               onChange={handleBuilderPincodeChange}
//               style={{ borderRadius: 3 }}
//             />
//             <div className="mt-2 d-flex">
//               <Form.Control
//                 type="text"
//                 placeholder="CITY"
//                 disabled
//                 className={`body-text2 py-2 border border-gray-300 ${isEdit ? "text-gray-900" : "text-gray-600"
//                   }`}
//                 name="city"
//                 value={builderLocation.city}
//                 onChange={(e) =>
//                   setBuilderLocation((prev) => ({
//                     ...prev,
//                     city: e.target.value,
//                   }))
//                 }
//                 style={{ borderRadius: 3 }}
//               />
//               <Form.Control
//                 type="text"
//                 placeholder="STATE"
//                 disabled
//                 className={`mx-2 body-text2 py-2 border border-gray-300 ${isEdit ? "text-gray-900" : "text-gray-600"
//                   }`}
//                 name="state"
//                 value={builderLocation.state}
//                 onChange={(e) =>
//                   setBuilderLocation((prev) => ({
//                     ...prev,
//                     state: e.target.value,
//                   }))
//                 }
//                 style={{ borderRadius: 3 }}
//               />
//               <Form.Control
//                 type="text"
//                 placeholder="COUNTRY"
//                 disabled
//                 className={`mx-2 body-text2 py-2 border border-gray-300 ${isEdit ? "text-gray-900" : "text-gray-600"
//                   }`}
//                 name="country"
//                 value={builderLocation.country}
//                 onChange={(e) =>
//                   setBuilderLocation((prev) => ({
//                     ...prev,
//                     country: e.target.value,
//                   }))
//                 }
//                 style={{ borderRadius: 3 }}
//               />
//             </div>
//           </Form.Group>
//         </Col>
//       </Row>
//       {isEdit && (
//         <div className="d-flex p-3">
//           <Button
//             className="bg-transparent inter-500 border-gray-200 text-gray-900 body-text2"
//             style={{ borderRadius: 3 }}
//             onClick={() => setIsEdit(false)}
//           >
//             Cancel
//           </Button>
//           <Button
//             type="submit"
//             variant="primary"
//             className="inter-500 body-text2 text-white ms-2 d-flex justify-content-center align-items-center"
//             style={{ borderRadius: 3 }}
//           >
//             <FaSave size={18} className="me-2" />
//             Save Profile
//           </Button>
//         </div>
//       )}
//     </Form>
//   );
// }

// export default BuilderAccountEditForm;
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  InputGroup,
  Row,
  Spinner,
  Modal,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FaSave } from "react-icons/fa";
import { LuMail } from "react-icons/lu";
import { FiEdit3, FiRefreshCcw, FiTrash2 } from "react-icons/fi";
import * as authActions from "reduxFiles/actions/authActions";
import { userUpdate } from "reduxFiles/reducer/authSlices";
import { notify } from "utilities/notifications/Notify";
import { useLocation } from "react-router-dom";
import { default_pfp } from "assets";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function BuilderAccountEditForm({ user }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const form = useRef(null);
  const bannerInputRef = useRef(null);
  const imgRef = useRef(null);
  const [isEdit, setIsEdit] = useState(false);
  const [hover, setHover] = useState(false);
  const [bannerHover, setBannerHover] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [profileImage, setProfileImage] = useState(user?.user_data?.profile_picture || default_pfp);
  const [bannerPreview, setBannerPreview] = useState("");
  const [showCropModal, setShowCropModal] = useState(false);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  const [builderInfo, setBuilderInfo] = useState(null);
  const [expertiseType, setExpertiseType] = useState("");
  const [builderLocation, setBuilderLocation] = useState({
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [projectCount, setProjectCount] = useState("");
  const [expertiseTypes, setExpertiseTypes] = useState([]);


  // Validation functions
  const validatePincode = (pincode) => {
    const pincodeRegex = /^\d{6}$/;
    if (pincode && !pincodeRegex.test(pincode)) {
      notify("Pincode must be exactly 6 digits", "error");
      return false;
    }
    return true;
  };

  const validatePhoneNumber = (phone) => {
    if (!phone || phone.trim() === "") {
      notify("Phone number is required", "error");
      return false;
    }

    const phoneRegex = /^\d+$/;
    if (!phoneRegex.test(phone)) {
      notify("Phone number must contain only digits", "error");
      return false;
    }

    if (phone.length !== 10) {
      notify("Phone number must be exactly 10 digits", "error");
      return false;
    }

    return true;
  };

  const validateNumericField = (value, fieldName) => {
    if (value === undefined || value === null || String(value).trim() === "") {
      notify(`${fieldName} is required`, "error");
      return false;
    }

    const numRegex = /^\d+$/;
    if (!numRegex.test(String(value))) {
      notify(`${fieldName} must contain only digits`, "error");
      return false;
    }

    if (parseInt(value) <= 0) {
      notify(`${fieldName} must be greater than 0`, "error");
      return false;
    }

    return true;
  };

  // Key press handlers for numeric input fields
  const handlePincodeKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      notify("Pincode must contain only digits", "error");
    }
  };

  const handlePhoneKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      notify("Phone number must contain only digits", "error");
    }
  };

  const handleNumericKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      notify("This field must contain only digits", "error");
    }
  };

  const fetchBuilderInfoData = async () => {
    if (!user?.user_data?.id) return;
    try {
      const response = await authActions.fetchBuildersDataByUser(user?.user_data?.id);
      if (response.data) {
        const info = response.data?.results[0];
        setBuilderInfo(info);
        setExpertiseType(info.expertise_type || "");
        setPhoneNumber(user?.user_data?.mobile || "");
        setYearsOfExperience(info.years_of_experience || "");
        setProjectCount(info.project_count || "");
        setBannerPreview(info.builder_banner || ""); // Assuming builder_banner field exists
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (builderInfo) {
      setBuilderLocation({
        address: builderInfo.user_data?.address || "",
        city: builderInfo?.user_data?.city || "",
        state: builderInfo?.user_data?.state || "",
        country: builderInfo?.user_data?.country || "",
        pincode: builderInfo?.user_data?.pincode || "",
      });
    }
  }, [builderInfo]);

  const handleProfilePicUpdate = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (!allowedTypes.includes(file.type)) {
      notify("Only JPEG, JPG, and PNG image formats are allowed!", "error");
      e.target.value = "";
      return;
    }

    const maxSize = 2 * 1024 * 1024;
    if (file.size > maxSize) {
      notify("Image size should not be greater than 2MB!", "error");
      e.target.value = "";
      return;
    }

    setIsUploading(true); // Start loading
    const formData = new FormData();
    formData.append("profile_picture", file);
    try {
      const response = await authActions.updateProfilePic(user?.user_data?.id, formData);
      if (response.data) {
        notify("Profile Picture Updated!", "success");
        dispatch(userUpdate(response.data));
        // Update the local state with the new image URL
        setProfileImage(response.data.profile_picture || default_pfp);
      }
    } catch (error) {
      console.log(error);
      notify("Error uploading profile picture", "error");
    } finally {
      setIsUploading(false); // Stop loading
      e.target.value = ""; // Reset the input
    }
  };

  const fetchExpertiseTypes = async () => {
    try {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      if (!baseUrl) {
        throw new Error("REACT_APP_API_BASE_URL is not defined in .env");
      }
      const url = `${baseUrl}/user/rest/expertise-type/?all=true&user_type=builder`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch expertise types");
      }

      const data = await response.json();
      const expertiseData = Array.isArray(data) ? data : data.results || [];
      setExpertiseTypes(expertiseData);
    } catch (error) {
      console.error("Error fetching expertise types:", error);
      notify("Error fetching expertise types", "error");
    }
  };

  const getBuilderPincode = async (pincode) => {
    try {
      if (!validatePincode(pincode)) {
        return;
      }

      const response = await authActions.fetchPincode(pincode);

      if (response) {
        setBuilderLocation((prev) => ({
          ...prev,
          city: response?.District || "",
          state: response?.State || "",
          country: response?.Country || "",
        }));
      } else {
        setBuilderLocation((prev) => ({
          ...prev,
          city: "",
          state: "",
          country: "",
        }));
        notify("Invalid pincode. Please enter a valid pincode.", "error");
      }
    } catch (error) {
      console.error(error);
      notify("Error fetching pincode details", "error");
    }
  };

  const handleBuilderPincodeChange = (e) => {
    const newPincode = e.target.value;

    if (newPincode && !/^\d*$/.test(newPincode)) {
      notify("Pincode must contain only digits", "error");
      return;
    }

    setBuilderLocation((prev) => ({ ...prev, pincode: newPincode }));

    if (newPincode.length < 6) {
      setBuilderLocation((prev) => ({
        ...prev,
        city: "",
        state: "",
        country: "",
      }));
    }

    if (newPincode.length === 6) {
      getBuilderPincode(newPincode);
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;

    if (value && !/^\d*$/.test(value)) {
      notify("Phone number must contain only digits", "error");
      return;
    }

    if (value.length <= 10) {
      setPhoneNumber(value);
    }
  };

  const handleYearsOfExperienceChange = (e) => {
    const value = e.target.value;

    if (value && !/^\d*$/.test(value)) {
      notify("Years of Experience must contain only digits", "error");
      return;
    }

    setYearsOfExperience(value);
  };

  const handleProjectCountChange = (e) => {
    const value = e.target.value;

    if (value && !/^\d*$/.test(value)) {
      notify("Number of Projects must contain only digits", "error");
      return;
    }

    setProjectCount(value);
  };

  const handleBannerClick = () => {
    if (bannerPreview) {
      setImageSrc(bannerPreview);
      setShowCropModal(true);
    } else {
      document.getElementById("bannerUpload").click();
    }
  };

  const handleBannerUpdate = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 4 * 1024 * 1024;

    if (!allowedTypes.includes(file.type)) {
      notify("Only JPG, JPEG, and PNG files are allowed", "error");
      return;
    }

    if (file.size > maxSize) {
      notify("File size must be less than 4MB", "error");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImageSrc(reader.result?.toString() || null);
      setShowCropModal(true);
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const crop = centerCrop(
      makeAspectCrop(
        { unit: "px", width: 1843, height: 300 },
        1843 / 300,
        width,
        height
      ),
      width,
      height
    );
    setCrop(crop);
  };

  const getCroppedImg = async () => {
    if (!completedCrop || !imgRef.current) return;

    const canvas = document.createElement("canvas");
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = 1843;
    canvas.height = 300;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      imgRef.current,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      1843,
      300
    );

    const currentBanner = bannerPreview;
    const previewUrl = canvas.toDataURL("image/jpeg");
    setBannerPreview(previewUrl);

    try {
      const blob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/jpeg")
      );
      const formData = new FormData();
      formData.append("banner_image", blob, "banner.jpg");

      const response = await authActions.updateBannerPic(
        user?.user_data?.id,
        formData
      );
      if (response.data) {
        notify("Banner Updated!", "success");
        dispatch(userUpdate(response.data));
      }
    } catch (error) {
      console.log(error);
      setBannerPreview(currentBanner);
      notify("Failed to update banner", "error");
    }

    setShowCropModal(false);
    setImageSrc(null);
    setCrop(null);
    setCompletedCrop(null);
    if (bannerInputRef.current) bannerInputRef.current.value = null;
  };

  const handleDeleteBanner = async () => {
    try {
      const response = await authActions.deleteBannerPic(user?.user_data?.id);
      if (response.status === 200) {
        notify("Banner deleted successfully!", "success");
        setBannerPreview("");
        setImageSrc(null);
        setCrop(null);
        setCompletedCrop(null);
        setShowCropModal(false);
        dispatch(userUpdate({ ...user.user_data, builder_banner: null }));
      } else {
        notify("Failed to delete banner", "error");
      }
    } catch (error) {
      console.error("Error deleting banner:", error);
      notify("Failed to delete banner", "error");
    }
  };

  const handleModalClose = () => {
    setShowCropModal(false);
    setImageSrc(null);
    setCrop(null);
    setCompletedCrop(null);
    if (bannerInputRef.current) {
      bannerInputRef.current.value = null; // Reset the file input to allow re-selection of the same file
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formData = new FormData(form.current);
    formData.append("id", user.id);
    formData.append("mobile", phoneNumber);
    formData.append("years_of_experience", yearsOfExperience);
    formData.append("project_count", projectCount);

    let data = {};
    formData.forEach((value, key) => {
      if (value !== "") {
        data[key] = value;
      }
    });

    data.expertise_type = expertiseType;
    data.address = builderLocation.address;
    data.city = builderLocation.city;
    data.state = builderLocation.state;
    data.country = builderLocation.country;
    data.pincode = builderLocation.pincode;

    try {
      const response = await authActions.updateBuilderInfo(builderInfo.id, data);
      if (response.data) {
        notify("Profile Updated Successfully!", "success");
        setIsEdit(false);
      } else {
        notify("Error while updating profile!", "error");
      }
    } catch (error) {
      console.log(error);
      notify("Error updating profile", "error");
    }
  };

  const validateForm = () => {
    if (!validatePhoneNumber(phoneNumber)) {
      return false;
    }

    if (!validateNumericField(yearsOfExperience, "Years of Experience")) {
      return false;
    }

    if (!validateNumericField(projectCount, "Number of Projects")) {
      return false;
    }

    if (builderLocation.pincode) {
      if (!validatePincode(builderLocation.pincode)) {
        return false;
      }

      if (!builderLocation.city || !builderLocation.state || !builderLocation.country) {
        notify("Please enter a valid pincode to populate city, state, and country", "error");
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    fetchBuilderInfoData();
    fetchExpertiseTypes();
  }, [user]);

  useEffect(() => {
    if (location.hash === "#edit") {
      setIsEdit(true);
    }
  }, [location]);

  // Update profile image when user prop changes
  useEffect(() => {
    setProfileImage(user?.user_data?.profile_picture || default_pfp);
  }, [user]);

  return (
    <Form
      ref={form}
      onSubmit={handleUpdate}
      className="border border-gray-100 mt-5 mb-4"
      style={{ borderRadius: 6 }}
    >
      {/* Banner Section */}
      <div className="px-3 mt-4">
        <Form.Label className="mb-1 body-text2 inter-500 mb-2">
          Banner Edit{" "}
          <span className="text-muted">(Perfect dimensions: 1843 x 300px)</span>
        </Form.Label>
        <Card
          className="d-flex justify-content-center position-relative overflow-hidden cursor-pointer shadow-none p-0 mb-3"
          style={{
            width: "100%",
            height: 250,
            border: "1px dashed #BDC1C6",
            borderRadius: 6,
          }}
        >
          <Image
            className="p-0"
            style={{
              borderRadius: 6,
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
            src={bannerPreview || default_pfp}
            alt="banner"
            onMouseEnter={() => setBannerHover(true)}
            onMouseLeave={() => setBannerHover(false)}
            onClick={handleBannerClick}
          />
          {bannerHover && (
            <div
              className="position-absolute w-100 h-100 top-0 start-0 body-text2 text-white d-flex flex-column align-items-center justify-content-center"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: 6,
              }}
              onMouseEnter={() => setBannerHover(true)}
              onMouseLeave={() => setBannerHover(false)}
              onClick={handleBannerClick}
            >
              <FiRefreshCcw size={20} color="white" />
              <span className="mt-1">Edit Banner</span>
            </div>
          )}
          <input
            type="file"
            id="bannerUpload"
            ref={bannerInputRef}
            style={{ display: "none" }}
            onChange={handleBannerUpdate}
            accept="image/jpeg,image/jpg,image/png"
          />
        </Card>
      </div>

      <div className="d-flex justify-content-between p-3">
        <div className={`h4 inter-600 mt-2`}>Profile Information</div>
        <div
          className="mt-2 me-2 cursor-pointer"
          onClick={() => setIsEdit(!isEdit)}
        >
          <FiEdit3 size={20} />
        </div>
      </div>
      <Row className="px-3 pb-3">
        <Col lg={5}>
          <Card
            className="d-flex justify-content-center position-relative overflow-hidden cursor-pointer shadow-none p-0 mb-3"
            style={{
              width: 180,
              height: 150,
              border: "1px dashed #BDC1C6",
              borderRadius: 6,
            }}
          >
            {isUploading ? (
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <>
                <Image
                  className="p-0"
                  style={{ borderRadius: 6, objectFit: "contain" }}
                  width={180}
                  height={150}
                  src={profileImage}
                  alt="avatar"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                />
                {hover && (
                  <div
                    className="position-absolute w-100 h-100 top-0 start-0 body-text2 text-white d-flex flex-column align-items-center justify-content-center"
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: 6,
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={() => document.getElementById("profileUpload").click()}
                  >
                    <FiRefreshCcw size={20} color="white" />
                    <span className="mt-1">Reupload Profile Image</span>
                  </div>
                )}
              </>
            )}
            <input
              type="file"
              id="profileUpload"
              style={{ display: "none" }}
              onChange={handleProfilePicUpdate}
            />
          </Card>
          <Form.Group className="mb-3 user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Company Name<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              required
              type="text"
              disabled={!isEdit}
              className={`body-text2 py-2 border border-gray-300 ${isEdit ? "text-gray-900" : "text-gray-600"}`}
              name="company_name"
              defaultValue={builderInfo?.company_name}
              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
            />
          </Form.Group>
          <Form.Group className="mb-3 user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Company Email<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup
              className="border border-gray-300 bg-disabled"
              style={{ borderRadius: 3 }}
            >
              <InputGroup.Text
                className="bg-disabled border-0"
                style={{ borderRadius: 3 }}
              >
                <LuMail size={20} />
              </InputGroup.Text>
              <div
                className={`ms-2 py-2 body-text2 border-0 bg-tertiary text-gray-600`}
              >
                {user?.user_data?.email}
              </div>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3 user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Phone Number<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup
              className="border border-gray-300"
              style={{ borderRadius: 3 }}
            >
              <InputGroup.Text
                className={`border-0 body-text2 inter-500 ${isEdit ? "bg-white" : "bg-disabled"}`}
                style={{ borderRadius: 3 }}
              >
                +91
              </InputGroup.Text>
              <Form.Control
                disabled={!isEdit}
                className={`body-text2 py-2 border-0 ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                placeholder="Phone number"
                name="mobile"
                value={phoneNumber}
                onChange={handlePhoneChange}
                onKeyPress={handlePhoneKeyPress}
                maxLength={10}
                required
                type="tel"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Expertise Type<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Select
              required
              disabled={!isEdit}
              className="py-2 body-text2"
              name="expertise_type"
              value={expertiseType}
              onChange={(e) => setExpertiseType(e.target.value)}
              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
            >
              <option value="">-Select-</option>
              {expertiseTypes.map((type) => (
                <option key={type.id} value={type.expertise_type}>
                  {type.expertise_type}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3 d-flex">
            <div className="w-100">
              <Form.Label className="mb-1 body-text2 inter-500">
                Years of Experience <sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control
                required
                type="text"
                disabled={!isEdit}
                className={`body-text2 py-2 border border-gray-300 ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                name="years_of_experience"
                value={yearsOfExperience}
                onChange={handleYearsOfExperienceChange}
                onKeyPress={handleNumericKeyPress}
                style={{ borderRadius: 3 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>
            <div className="ms-2 w-100">
              <Form.Label className="mb-1 body-text2 inter-500">
                No. of Projects<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control
                required
                type="number"
                disabled={!isEdit}
                className={`body-text2 py-2 border border-gray-300 ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                name="project_count"
                value={projectCount}
                onChange={handleProjectCountChange}
                onKeyPress={handleNumericKeyPress}
                style={{ borderRadius: 3 }}
              />
            </div>
          </Form.Group>
          <Form.Group className="user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Office / Business Address
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="PLOT NUMBER, BUILDING NAME"
              disabled={!isEdit}
              className={`body-text2 py-2 border border-gray-300 mb-2 ${isEdit ? "text-gray-900" : "text-gray-600"}`}
              name="address"
              value={builderLocation.address}
              onChange={(e) =>
                setBuilderLocation((prev) => ({
                  ...prev,
                  address: e.target.value,
                }))
              }
              style={{ borderRadius: 3 }}
            />
            <Form.Control
              type="text"
              placeholder="PINCODE"
              disabled={!isEdit}
              className={`body-text2 py-2 border border-gray-300 mb-2 ${isEdit ? "text-gray-900" : "text-gray-600"}`}
              name="pincode"
              value={builderLocation.pincode}
              onChange={handleBuilderPincodeChange}
              onKeyPress={handlePincodeKeyPress}
              maxLength={6}
              style={{ borderRadius: 3 }}
            />
            <div className="mt-2 d-flex">
              <Form.Control
                type="text"
                placeholder="CITY"
                disabled
                className={`body-text2 py-2 border border-gray-300 ${isEdit ? "" : "text-gray-600"}`}
                name="city"
                value={builderLocation.city}
                style={{ borderRadius: 3 }}
              />
              <Form.Control
                type="text"
                placeholder="STATE"
                disabled
                className={`mx-2 body-text2 py-2 border border-gray-300 ${isEdit ? "" : "text-gray-600"}`}
                name="state"
                value={builderLocation.state}
                style={{ borderRadius: 3 }}
              />
              <Form.Control
                type="text"
                placeholder="COUNTRY"
                disabled
                className={`body-text2 py-2 border border-gray-300 ${isEdit ? "" : "text-gray-600"}`}
                name="country"
                value={builderLocation.country}
                style={{ borderRadius: 3 }}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
      {isEdit && (
        <div className="d-flex p-3">
          <Button
            className="bg-transparent inter-500 border-gray-200 text-gray-900 body-text2"
            style={{ borderRadius: 3 }}
            onClick={() => setIsEdit(false)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="inter-500 body-text2 text-white ms-2 d-flex justify-content-center align-items-center"
            style={{ borderRadius: 3 }}
          >
            <FaSave size={18} className="me-2" />
            Save Profile
          </Button>
        </div>
      )}

      {/* Crop Modal for Banner */}
      <Modal
        show={showCropModal}
        onHide={handleModalClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Banner Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {imageSrc ? (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={1843 / 300}
              minWidth={1843}
              maxWidth={1843}
              minHeight={300}
            >
              <img
                ref={imgRef}
                src={imageSrc}
                onLoad={onImageLoad}
                alt="Crop preview"
                style={{ maxWidth: "100%" }}
              />
            </ReactCrop>
          ) : (
            <div className="text-center">
              <p>No banner image selected. Upload a new one below.</p>
            </div>
          )}
          <Form.Group className="mt-3">
            <Form.Label>Upload New Banner</Form.Label>
            <Form.Control
              type="file"
              ref={bannerInputRef}
              onChange={handleBannerUpdate}
              accept="image/jpeg,image/jpg,image/png"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
          {imageSrc && (
            <Button variant="danger" onClick={handleDeleteBanner} className="me-2">
              <FiTrash2 className="me-1" /> Delete
            </Button>
          )}
          {imageSrc && (
            <Button
              variant="primary"
              onClick={getCroppedImg}
              disabled={!completedCrop}
            >
              Crop & Save
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

export default BuilderAccountEditForm;