import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import InteriorRequirement from "./InteriorRequirement";
import ConsultationRequirement from "./ConsultationRequirement";
import BookConsultationForm from "./BookConsultationForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "utilities/notifications/Notify";
import * as authActions from "reduxFiles/actions/authActions";
import { login } from "reduxFiles/reducer/authSlices";
import ProjectReferenceCard from "components/Order/ProjectReferenceCard";
import SpaceReferenceCard from "components/Order/SpaceReferenceCard";
import ArchitectReferenceCard from "components/Order/ArchitectReferenceCard";
import ReactSelect from "react-select";
import { FaCheck } from "react-icons/fa6";
import { BookingConfirmation } from "components";
import { validateEmail } from "utilities/validateEmail";
import {
  resetConsultation,
  setArea,
  setFloorPlan,
  setPropertyId,
  setRequirement,
  setSelectedRoomType,
} from "reduxFiles/reducer/consultationSlice";
import { deleteProjectDetails } from "reduxFiles/reducer/projectSlice";

const Consultation = () => {
  const location = useLocation();
  const state = location.state || {};
  const dispatch = useDispatch();
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  // const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const form = useRef(null);

  const { refType, refId } = useParams();
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [custom, setCustom] = useState(false);
  // const [projectDetails, setProjectDetails] = useState(null);
  const [slug, setSlug] = useState(false);
  const [properties, setProperties] = useState([]);
  const [projectType, setProjectType] = useState("apartment");
  const [processEventKey, setProcessEventKey] = useState("selected_service");
  const [serviceType, setServiceType] = useState("");
  const [selectedProperty, setSelectedProperty] = useState(null);
  const { requirement, floorPlan, selectedRoomType, area, propertyId } =
    useSelector((state) => state.consultaion);
  const { projectDetails } = useSelector((state) => state.project);

  // const [selectedRoomType, setSelectedRoomType] = useState([]);
  // const [floorPlan, setFloorPlan] = useState("");
  const [show, setShow] = useState(false);
  // const [area, setArea] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [otpInput, setOtpInput] = useState(false);
  const [propertyName, setPropertyName] = useState("");
  const [property_obj, setProperty_obj] = useState(null);
  const { inspirationType } = state;

  const navigate = useNavigate();

  // console.log(properties);
  // console.log(propertyId);

  const propertyTypes = [
    { id: 1, title: "Apartments", slug: "apartment" },
    { id: 2, title: "Offices", slug: "office" },
    { id: 3, title: "Villas", slug: "villa" },
    { id: 4, title: "Showrooms", slug: "showroom" },
    // { id: 5, title: "Independent Land", slug: "independent_land" },
  ];

  const fetchProperties = async () => {
    try {
      const response = await authActions.fetchAllProperties();
      if (response.data) {
        setProperties(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProperties();

    return () => {
      dispatch(deleteProjectDetails({}));
      dispatch(resetConsultation());
    };
  }, []);

  const handleSubmitRequest = async (userId) => {
    setIsLoading(true);

    const formData = new FormData(form.current);
    const selectedRoomString = selectedRoomType
      .map((room) => {
        const [key, value] = Object.entries(room)[0];
        return `${key}: ${value}`;
      })
      .join(", ");

    let type = inspirationType || refType;

    if (!type) {
      // Fall back to determining type based on what data is available
      if (projectDetails?.id) {
        type = "project";
      } else if (state?.room?.id) {
        type = "room";
      } else if (state?.architect?.id) {
        type = "architect";
      }
    }

    const data = {
      user: userId,
      service_type: serviceType,
      property: property_obj,
      property_name: propertyName || '',
      requirement: requirement,
      selected_rooms: selectedRoomString,
      project_type: projectType,
      area: selectedProperty?.area || area || 0,
      floorplan: floorPlan,
      type: type,
      architect: state?.architect?.id,
      project: projectDetails?.id,
      room: state?.room?.id,
      requirement_msg: formData.get("requirements_msg"),
    };

    console.log({ data });

    try {
      const response = await authActions.requestConsultationCallback(data);
      if (response.data) {
        clearStates();
        navigate("/");
        setIsLoading(false);
        // setShow(true);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData(form.current);
    if (formData.get("confirm_password") !== formData.get("password")) {
      notify("Passwords did't match!");
      setIsLoading(false);
      return;
    }
    // formData.append("user_type", serviceType);
    formData.append("user_type", "customer");
    let data = {};
    formData.forEach((value, key) => (data[key] = value));
    console.log(data);
    try {
      const response = await authActions.signup(data);
      if (response.data) {
        setOtpInput(true);
      } else if (response?.msg) {
        notify(response.msg);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData(form.current);
    if (validateEmail(formData.get("username"))) {
      try {
        const response = await authActions.login(formData);
        if (response.data) {
          dispatch(login(response.data));
        }
        if (response.data?.user.id) {
          handleSubmitRequest(response.data.user.id);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      notify("Please enter a valid email id!", "error");
    }
    setIsLoading(false);
  };

  // useEffect(() => {
  //   const selected = properties.find((property) => property.id === propertyId);

  //   setSelectedProperty(selected);
  // }, [projectDetails, propertyId, properties]);

  useEffect(() => {
    if (propertyId) {
      const selected = properties.find((property) => property.id === propertyId);
      if (selected) {
        setSelectedProperty(selected);
      }
    }
    // Only run this effect when propertyId or properties change
  }, [propertyId, properties]);

  useEffect(() => {
    if (selectedProperty && selectedProperty.layout_type_data && selectedProperty.layout_type_data.length > 0) {
      console.log("Property has layouts:", selectedProperty.layout_type_data);

      // If we have a selected floor plan, find the matching layout
      if (floorPlan) {
        const matchingLayout = selectedProperty.layout_type_data.find(
          layout => layout.layout === floorPlan
        );

        if (matchingLayout && matchingLayout.area) {
          dispatch(setArea(matchingLayout.area));
          console.log("Updated area from matching layout:", matchingLayout.area);
          return;
        }
      }

      // If the property has a default area property, use that
      if (selectedProperty.area) {
        dispatch(setArea(selectedProperty.area));
        console.log("Using property's default area:", selectedProperty.area);
        return;
      }

      // If no match was found for the floor plan, don't reset to 0
      // Only update if there's no area set yet
      if (!area || area === 0) {
        const firstLayoutArea = selectedProperty.layout_type_data[0].area;
        if (firstLayoutArea) {
          dispatch(setArea(firstLayoutArea));
          console.log("Using first layout's area as default:", firstLayoutArea);
        }
      }
    }
  }, [selectedProperty, floorPlan, dispatch, area]);

  // Also add an effect to update area when floor plan changes
  useEffect(() => {
    if (floorPlan && selectedProperty && selectedProperty.layout_type_data) {
      const matchingLayout = selectedProperty.layout_type_data.find(
        layout => layout.layout === floorPlan
      );

      if (matchingLayout && matchingLayout.area) {
        dispatch(setArea(matchingLayout.area));
        console.log("Floor plan changed, updated area to:", matchingLayout.area);
      }
    }
  }, [floorPlan, selectedProperty, dispatch]);

  const handleContinue = () => {
    if (propertyId) {
      isAuthenticated
        ? handleSubmitRequest(user.id)
        : setProcessEventKey("book");
    } else if (projectType) {
      if (projectType === "apartment" || projectType == "villa") {
        if (requirement && floorPlan) {
          if (requirement == "selected_rooms") {
            if (selectedRoomType?.length > 0) {
              isAuthenticated
                ? handleSubmitRequest(user.id)
                : setProcessEventKey("book");
            } else {
              notify("Please select room types for selected rooms", "error");
            }
          } else {
            isAuthenticated
              ? handleSubmitRequest(user.id)
              : setProcessEventKey("book");
          }
        } else {
          notify("Please select both requirement and floor plan", "error");
        }
      } else {
        if (area && area !== 0 && area !== "0") {
          isAuthenticated
            ? handleSubmitRequest(user.id)
            : setProcessEventKey("book");
        } else {
          notify("Please select area", "error");
        }
      }
    } else {
      notify("Please select type of project", "error");
    }
  };

  const handleSubmit = () => {
    // First, ensure that at least one of the two conditions is met:
    // Either a property is selected (propertyId exists)
    // OR a custom service is being configured (i.e. a floor plan is selected)
    // if (!propertyId && !floorPlan) {
    //   notify(
    //     "Please either select a property or choose a floor plan for your service",
    //     "error"
    //   );
    //   return;
    // }

    if (custom && (!propertyName || propertyName.trim() === '')) {
      notify("Please enter a property name", "error");
      return;
    }

    // Check if either property is selected or custom is checked
    if (!selectedProperty && !custom) {
      notify("Please either select a property or check 'Property not in list'", "error");
      return;
    }

    if (!requirement) {
      notify("Please select your service requirement (Turnkey or Selected Spaces)", "error");
      return;
    }

    // If a property is selected, no further service details are needed.
    if (propertyId) {
      setProcessEventKey("book");
      return;
    }

    // If custom property (not from dropdown), require a floor plan
    if (custom && !floorPlan) {
      notify("Please select a layout plan", "error");
      return;
    }

    // If property is selected from dropdown, also require floor plan selection
    if (!floorPlan) {
      notify("Please select a layout plan for your property", "error");
      return;
    }

    // At this point, propertyId is not provided so we expect custom service details.
    if (!projectType) {
      notify("Please select type of project", "error");
      return;
    }

    // For projects like 'apartment' or 'villa', check that a requirement is chosen
    // and that a floor plan is selected.
    if (projectType === "apartment" || projectType === "villa") {
      // if (!requirement || !floorPlan) {
      //   notify("Please select both requirement and floor plan", "error");
      //   return;
      // }
      // If the requirement is "selected_rooms", ensure that at least one room type is selected.
      if (
        requirement === "selected_rooms" &&
        (!selectedRoomType || selectedRoomType.length === 0)
      ) {
        notify("Please select room types for selected rooms", "error");
        return;
      }
    } else {
      // For other project types, validate that an area is provided.
      if (!area || area === 0 || area === "0") {
        notify("Please select area", "error");
        return;
      }
    }

    // If all validations pass, move to the next step.
    setProcessEventKey("book");
  };

  const clearStates = () => {
    setProjectType("");
    setProcessEventKey("selected_service");
    setServiceType("");
    dispatch(setRequirement(""));
    // dispatch(setSelectedRoomType([]));
    dispatch(setFloorPlan(""));
    // setReferenceData(null);
    // setShow(false);
    dispatch(setArea(0));
    setIsLoading(false);
    setShowLoginForm(false);
    setOtpInput(false);
  };

  useEffect(() => {
    if ((refId, refType)) {
      console.log("refId, refType: ", refId, refType);
      // fetchData();
    }
  }, [refId, refType]);

  useEffect(() => {
    if (location.hash !== "") {
      setProcessEventKey("project_details");
      if (location.hash === "#turnkey") {
        setServiceType("turnkey");
      } else if (location.hash === "#customised") {
        setServiceType("customised");
      }
    }
  }, [location]);

  useEffect(() => {
    if (propertyId && !property_obj) {
      setProperty_obj(propertyId);
    }
  }, [propertyId]);

  return (
    <>
      <Container
        fluid
        className="mt-5 pt-4 px-0 d-flex flex-column align-items-center"
        style={{ minHeight: "70vh" }}
      >
        <div className="w-100 bg-brand-25 d-flex justify-content-center align-items-center">
          <h1 className="h2 text-primary-500 inter-600 pb-3 pt-4">
            {`${isAuthenticated ? `Hey ${user.full_name},` : ""
              } Get started with InterioKeys!`}
          </h1>
        </div>
        <div
          className="d-flex flex-column flex-lg-row my-md-4 mt-lg-5 pt-lg-5"
          style={large ? { maxWidth: 1000, width: "100%" } : { width: "100%" }}
        >
          <div
            className="d-flex justify-content-center py-4 py-lg-0"
            style={{ minWidth: 250 }}
          >
            <div className="d-flex flex-lg-column mt-0 mt-md-4 mt-lg-3 ml-2 mb-1 px-3 px-lg-0">
              <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
                <div
                  className={`d-flex justify-content-center align-items-center p-1 text-center body-text1 rounded-btn 
                        ${processEventKey === "selected_service"
                      ? "bg-black text-white"
                      : "bg-success text-white"
                    }`}
                >
                  {processEventKey === "selected_service" ? (
                    "1"
                  ) : (
                    <FaCheck size={18} />
                  )}
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${processEventKey !== "selected_service" ? "text-success" : ""
                    }`}
                >
                  Select Service
                </div>
              </div>
              <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
                <div
                  className={`d-flex justify-content-center align-items-center p-1 text-center rounded-btn ${processEventKey === "project_details"
                    ? "bg-black text-white"
                    : processEventKey === "book"
                      ? "bg-success text-white"
                      : "text-gray-200 border border-gray-200"
                    }`}
                >
                  {processEventKey === "selected_service" ||
                    processEventKey === "project_details" ? (
                    "2"
                  ) : (
                    <FaCheck size={18} />
                  )}
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${processEventKey !== "selected_service" &&
                    processEventKey !== "project_details"
                    ? "text-success"
                    : processEventKey === "project_details"
                      ? ""
                      : "text-gray-200"
                    }`}
                >
                  Project Details
                </div>
              </div>
              <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
                <div
                  className={`d-flex justify-content-center align-items-center p-1 text-center rounded-btn ${processEventKey === "book"
                    ? "bg-black text-white"
                    : "text-gray-200 border border-gray-200"
                    }`}
                >
                  3
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${processEventKey === "book"
                    ? "text-gray-900"
                    : "text-gray-200"
                    }`}
                >
                  Contact Details
                </div>
              </div>
            </div>
          </div>
          <div className="mx-3 mx-lg-0 p-3 border" style={{ borderRadius: 6 }}>
            {processEventKey === "selected_service" && (
              <div className="my-0" style={large ? { minWidth: 600 } : {}}>
                <h4 className="inter-600 mb-3 py-2">Select Service</h4>
                {state?.room && (
                  <div className="d-flex flex-column w-100">
                    <div className="text-gray-900 body-text2 inter-500 mb-1">
                      Selected Inspiration(Space)
                    </div>
                    <SpaceReferenceCard
                      room={state?.room}
                      className="bg-white"
                    />
                  </div>
                )}
                {Object.keys(projectDetails).length > 0 && (
                  <div className="d-flex flex-column w-100">
                    <div className="text-gray-900 body-text2 inter-500 mb-1">
                      Selected Inspiration(Project)
                    </div>
                    <ProjectReferenceCard
                      project={projectDetails}
                      className="bg-white"
                    />
                  </div>
                )}
                {state?.architect && (
                  <div className="d-flex flex-column w-100">
                    <div className="text-gray-900 body-text2 inter-500 mb-1">
                      Selected Architect
                    </div>
                    <ArchitectReferenceCard
                      architect={state.architect}
                      className="bg-white"
                    />
                  </div>
                )}
                <ConsultationRequirement
                  serviceType={serviceType}
                  setServiceType={setServiceType}
                />
              </div>
            )}
            {processEventKey === "project_details" && (
              <div className="my-0" style={large ? { minWidth: 650 } : {}}>
                <h4 className="inter-600 mb-3 py-2">Project Details</h4>
                <div className="body-text2 inter-500 text-start mt-2 mb-">
                  Property
                </div>
                <ReactSelect
                  name="project_property"
                  isMulti={false}
                  options={properties}
                  getOptionLabel={(e) => e.property_name}
                  getOptionValue={(e) => e.id}
                  isDisabled={custom}
                  onChange={(e) => {
                    if (e) {
                      // console.log("Selected property ID:", e.id);
                      dispatch(setPropertyId(e.id));
                      setProperty_obj(e.id);
                      setCustom(false);
                      setSelectedProperty(e);


                      if (e.area) {
                        dispatch(setArea(e.area));
                      }
                    } else {
                      dispatch(setPropertyId(""));
                      setProperty_obj(null);
                      setSelectedProperty(null);
                      // setCustom(true);
                    }
                  }}
                  value={selectedProperty}
                  isClearable
                  placeholder="Select Property"
                  className="body-text2 mb-2"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "1px solid #BDC1C6",
                      borderRadius: 3,
                    }),
                  }}
                />

                {/* Custom Property Checkbox */}
                <div className="d-flex align-items-center mb-3">
                  <Form.Check
                    type="checkbox"
                    id="custom-property-check"
                    checked={custom}
                    onChange={(e) => {
                      setCustom(e.target.checked);
                      if (e.target.checked) {
                        // Clear property selection when custom is checked
                        dispatch(setPropertyId(""));
                        setProperty_obj(null);
                        setSelectedProperty(null);
                      } else {
                        // Clear property name when custom is unchecked
                        setPropertyName("");
                      }
                    }}
                    className="me-2"
                  />
                  <Form.Label
                    htmlFor="custom-property-check"
                    className="body-text2 inter-400 mb-0 cursor-pointer"
                  >
                    Property not in list
                  </Form.Label>
                </div>

                {custom && (
                  <>
                    {/* Add Property Name Input Field */}
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Property Name
                      </Form.Label>
                      <Form.Control
                        required
                        className="py-2 body-text2 border-gray-300 text-gray-700"
                        type="text"
                        name="property_name"
                        placeholder="Enter Property Name"
                        value={propertyName}
                        onChange={(e) => setPropertyName(e.target.value)}
                      />
                    </Form.Group>

                  </>
                )}

                {custom ? (
                  <InteriorRequirement floorPlan={floorPlan} selectedProperty={null} />
                ) : selectedProperty ? (
                  <InteriorRequirement floorPlan={floorPlan} selectedProperty={selectedProperty} />
                ) : null}

                <div className="mt-4 d-flex">
                  {processEventKey === "project_details" && (
                    <Button
                      className="bg-transparent inter-500 border-gray-200 text-gray-900 body-text2"
                      style={{ borderRadius: 3 }}
                      onClick={() => {
                        // setCustom(true)
                        // setProjectType("")
                        setProcessEventKey("selected_service");
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {isAuthenticated ? (
                    <Button
                      variant="primary"
                      className="inter-500 body-text2 text-white ms-2"
                      style={{ width: 100 }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className="inter-500 body-text2 text-white ms-2"
                      style={{ borderRadius: 3 }}
                      onClick={handleContinue}
                    >
                      Continue
                    </Button>
                  )}
                </div>
              </div>
            )}
            {processEventKey === "book" && (
              <div className="my-0" style={large ? { minWidth: 650 } : {}}>
                <h4 className="inter-600 mb-3 py-2">Book Free Call</h4>
                <BookConsultationForm
                  form={form}
                  showLoginForm={showLoginForm}
                  setShowLoginForm={setShowLoginForm}
                  otpInput={otpInput}
                  handleSubmitRequest={handleSubmitRequest}
                  handleSignUp={handleSignUp}
                  handleLogin={handleLogin}
                />
                <div>
                  <Button
                    className="bg-transparent inter-500 border-gray-200 text-gray-900 body-text2"
                    style={{ borderRadius: 3 }}
                    onClick={() => setProcessEventKey("project_details")}
                  >
                    Back
                  </Button>
                  <Button
                    className="ms-2 bg-transparent inter-500 border-gray-200 text-gray-900 body-text2"
                    style={{ borderRadius: 3 }}
                    onClick={() => {
                      // setCustom(true)
                      // setProjectType("")
                      setProcessEventKey("selected_service");
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <BookingConfirmation show={show} setShow={setShow} />
      </Container >
    </>
  );
};

export default Consultation;
