import ShareModal from "components/General/ShareModal";
import { useEffect, useState } from "react";
import { Button, Card, Carousel } from "react-bootstrap";
import { FaRegHeart } from "react-icons/fa6";
import { FaDownload } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import { useSelector } from "react-redux";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import { product_placeholder_img } from "assets";
import ProjectCardShimmer from "./ProjectCardShimmer";
import useFetchMedia from "utilities/customHooks/useFetchMedia";
import { useLocation, useNavigate } from "react-router-dom";

const ProductListCard = ({
  id,
  title,
  media,
  product_category_data,
  product_type,
  product_sub_type,
  brand_name,
  cost,
  description,
  setSingleRowProducts,
  products,
  productId,
  imgHeight = "300px",
  onClick,
  className,
  style,
  collection,
  setProducts,
  setProductLength,
  showActions = true,
  context = "default", // Add context prop
}) => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const navigate = useNavigate();

  const [hover, setHover] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const { image, loading, error } = useFetchMedia("product", "product", productId, true);

  const checkProductIfSaved = async () => {
    try {
      const response = await authActions.checkProductIfSaved(user?.id, id);
      if (response?.is_saved) setIsLiked(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProduct = async () => {
    try {
      const response = await authActions.saveProduct(user?.id, id);
      if (response.data) {
        setIsLiked(true);
        notify("Product saved!", "success");
      } else {
        notify("Failed to save product, try again later", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProduct = async () => {
    try {
      const response = await authActions.unsaveProduct(user?.id, id);
      if (response) {
        if (location?.pathname !== "/architect/product-library" && location?.pathname !== "/brand/product-library") {
          setProducts((prev) => {
            const updatedProducts = prev.filter((product) => product.product !== id);
            setSingleRowProducts(updatedProducts.slice(0, 3));
            return updatedProducts;
          });
          setProductLength((prev) => prev - 1);
        } else {
          setIsLiked(false);
        }
        notify("Product unsaved!", "success");
      } else {
        notify("Failed to unsave product, try again later", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadImages = (id) => {
    authActions.downloadMediaZip(id);
  };

  const validImageExtensions = [".png", ".jpg", ".jpeg", ".webp"];
  const filteredImages = (image || []).filter((img) => {
    if (!img?.file) return false;
    const fileExtension = img.file.toLowerCase().split(".").pop();
    return validImageExtensions.includes(`.${fileExtension}`);
  });

  useEffect(() => {
    if (user?.id && id) checkProductIfSaved();
  }, [user?.id, id]);

  // Only navigate if explicitly defined via onClick prop
  const handleCardClick = (e) => {
    if (onClick) {
      onClick(e); 
    }
  };

  return (
    <>
      <Card
        className={`w-100 py-0 px-0 shadow-none overflow-hidden position-relative ${
          hover ? "border-gray-300" : "border-gray-25"
        } ${className}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleCardClick} // Use controlled click handler
        style={{ borderRadius: 6, transition: "border-color 0.3s ease", ...style }}
      >
        {filteredImages.length > 1 ? (
          <Carousel interval={null}>
            {filteredImages.map((img, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100 bg-gray-50"
                  src={img?.file || product_placeholder_img}
                  alt={`Product Image ${index + 1}`}
                  style={{ maxHeight: imgHeight, minHeight: imgHeight, objectFit: "cover", borderRadius: "6px" }}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <Card.Img
            className="img-fluid bg-gray-50"
            variant="top"
            src={filteredImages.length === 1 ? filteredImages[0].file : product_placeholder_img}
            style={{ maxHeight: imgHeight, minHeight: imgHeight, objectFit: "cover", borderRadius: "6px" }}
          />
        )}
        {showActions && (
          <div className="d-flex position-absolute" style={{ zIndex: 999, right: 15, top: 10 }}>
            <Button
              className={`d-flex justify-content-center align-items-center share-like-btn ms-2 p-0 ${isLiked ? "bg-primary text-white" : ""}`}
              onClick={(e) => {
                e.stopPropagation(); // Prevent card click from triggering
                isLiked ? handleUnsaveProduct() : handleSaveProduct();
              }}
            >
              <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : ""} />
            </Button>
          </div>
        )}
        <Card.Body className="d-flex justify-content-between align-items-center">
          <div className="p-1 d-flex flex-column align-items-start">
            <Card.Title
              className={`inter-700 body-text1 mb-0 ${hover ? "text-primary-500" : "text-gray-900"}`}
            >
              {title}
            </Card.Title>
            <div className="body-text2 inter-500 text-gray-800 d-flex flex-column align-items-start">
              <span className="ms-0">{brand_name}</span>
            </div>
            <div className="body-text2 inter-500 text-gray-800 d-flex flex-column align-items-start">
              <span className="ms-0">{collection}</span>
            </div>
            {product_category_data !== "null" && (
              <div className="body-text2 inter-500 text-gray-800 d-flex flex-column align-items-start">
                <span className="ms-0">{product_category_data?.title}</span>
              </div>
            )}
            {product_type && (
              <div className="body-text3 inter-400 text-gray-600 d-flex flex-column align-items-start">
                <span className="ms-0">{product_type} : {product_sub_type}</span>
              </div>
            )}
          </div>
          <Button
            className="share-like-btn ms-2 p-0"
            onClick={(e) => {
              e.stopPropagation(); // Prevent card click from triggering
              downloadImages(id);
            }}
          >
            <FaDownload size={16} />
          </Button>
        </Card.Body>
      </Card>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={id}
        images={image}
        title={brand_name}
        urlPath={`/products/`}
      />
    </>
  );
};

export default ProductListCard;