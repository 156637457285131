import { brand_placeholder } from "assets";
import { Card } from "react-bootstrap";

const BrandCard = ({ brand, removeBrandDisplaySelection, isPreview = false }) => {
  // console.log(brand)
  return (
    <Card
      className="border border-danger rounded shadow-sm d-flex align-items-center justify-content-between flex-row"
      style={{
        width: "180px",
        padding: "6px 12px",
        border: "1px solid #dc3545",
        display: "flex",
        alignItems: "center",
      }}
    >

      <img
        src={brand?.brand_data?.image ? brand?.brand_data?.image : brand_placeholder}
        alt={brand?.name || "Brand"}
        className="img-fluid"
        style={{
          maxHeight: "24px",
          objectFit: "contain",
          flexShrink: 0,
        }}
      />


      {!isPreview && <button
        className="btn btn-link p-0"
        style={{
          color: "#6c757d",
          fontSize: "18px",
          textDecoration: "none",
          marginLeft: "auto",
        }}
        onClick={() => removeBrandDisplaySelection(brand?.brand_data?.id)}
      >
        ×
      </button>}
    </Card>
  );
};

export default BrandCard;
