import { Card, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import useFetchMedia from "utilities/customHooks/useFetchMedia";

const BrandOnInspirationsCard = ({ roomId }) => {

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const maxLarge = useMediaQuery({ query: "(max-width: 991px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });
  const { image, loading, error } = useFetchMedia("project", "room", roomId);
  return (
    <Card
      className="border-0 overflow-hidden shadow-none p-0"
      style={{
        height: xxlarge
          ? 290
          : xlarge
          ? 250
          : large
          ? 200
          : medium
          ? 400
          : small
          ? 300
          : 200,
        borderRadius: 6,
      }}
    >
      <Image
        src={image}
        alt="Project Image"
        className="w-100 h-100"
        style={{ objectFit: "cover" }}
      />
    </Card>
  );
};

export default BrandOnInspirationsCard;
