import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import { FaEdit, FaTrash, FaSpinner } from 'react-icons/fa';
import axios from 'axios';
import * as authActions from "reduxFiles/actions/authActions";
import EditProductModal from './EditProductModel';

const ProductListView = ({ products: initialProducts, onEditProduct, onDeleteProduct }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [products, setProducts] = useState(initialProducts);
  const [showEditModal, setShowEditModal] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  
  const apiUrl = "/product/products";
  
  useEffect(() => {
    setProducts(initialProducts);
  }, [initialProducts]);


  const handleDeleteClick = (product) => {
    setProductToDelete(product);
    setShowDeleteModal(true);
  };

  const handleEditClick = (product) => {
    setProductToEdit(product);
    setShowEditModal(true);
  };

  const fetchProducts = async () => {
    try {
      const response = await authActions.fetchProductsPagewise("/product/products/?page=1");
      if (response?.data) {
        setProducts(response.data?.results);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const confirmDelete = async () => {
    if (!productToDelete) return;

    setIsDeleting(true);
    try {
      const response = await axios.delete(`${apiUrl}/${productToDelete.id}/`);
      const data = response.data;
      
      if (onDeleteProduct) {
        onDeleteProduct(productToDelete.id);
      }
      
      // await fetchProducts();
      
      setProducts(prevProducts => 
        prevProducts.filter(product => product.id !== productToDelete.id)
      );
      
      setShowDeleteModal(false);
      return data;
    } catch (err) {
      console.error("Error deleting product:", err);
      
      let errorMessage = "Failed to delete product. Please try again.";
      
      if (err?.response?.data) {
        if (err.response.data.responseMsg) {
          errorMessage = err.response.data.responseMsg;
        }
      }
      
      alert(errorMessage);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSaveProduct = async (productId, formData) => {
    try {
      const response = await axios.patch(
        `${apiUrl}/${productId}/`, 
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      
      const updatedProduct = response.data;
      
      if (onEditProduct) {
        onEditProduct(updatedProduct);
      }
    
      
      return updatedProduct;
    } catch (error) {
      console.error("Error updating product:", error);
      
      let errorMessage = "Failed to update product. Please try again.";
      
      if (error.response) {
        if (error.response.data && error.response.data.responseMsg) {
          errorMessage = error.response.data.responseMsg;
        } else if (typeof error.response.data === 'string') {
          errorMessage = error.response.data;
        } else if (error.response.status === 400) {
          errorMessage = "Invalid data submitted. Please check your form.";
        } else if (error.response.status === 403) {
          errorMessage = "You don't have permission to edit this product.";
        } else if (error.response.status === 404) {
          errorMessage = "Product not found. It may have been deleted.";
        }
      } else if (error.request) {
        errorMessage = "No response from server. Please check your connection.";
      }
      
      throw new Error(errorMessage);
    }
  };

  return (
    <>
      <div className="w-100 my-3">
        <Table striped bordered hover responsive className="w-100">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Category</th>
              <th>Product Type</th>
              <th>Product Subtype</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products?.map((product) => (
              <tr key={product.id} className="cursor-pointer">
                <td>{product.title}</td>
                <td>{product.product_category_data?.title || 'N/A'}</td>
                <td>{product.product_type_data?.title || 'N/A'}</td>
                <td>{product.product_sub_type_data?.title || 'N/A'}</td>
                <td>
                  <div className="d-flex gap-2">
                    <Button 
                      variant="primary" 
                      size="sm" 
                      onClick={() => handleEditClick(product)}
                    >
                      <FaEdit />
                    </Button>
                    <Button 
                      variant="danger" 
                      size="sm" 
                      onClick={() => handleDeleteClick(product)}
                    >
                      <FaTrash />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the product "{productToDelete?.title}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button 
            variant="danger" 
            onClick={confirmDelete} 
            disabled={isDeleting}
          >
            {isDeleting ? (
              <>
                <FaSpinner className="fa-spin me-2" />
                Deleting...
              </>
            ) : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>

      {productToEdit && (
        <EditProductModal
          show={showEditModal}
          setProducts={setProducts}
          onHide={() => setShowEditModal(false)}
          product={productToEdit}
          onSave={handleSaveProduct}
        />
      )}
    </>
  );
};

export default ProductListView;