import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, InputGroup } from "react-bootstrap";
import ReactSelect from "react-select";
import { notify } from "utilities/notifications/Notify";
import { useLocation } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

const AddProjectForm = ({
  projectInfoRef,
  projectDetails,
  properties,
  setSelectedLayout,
  selectedProperty,
  setSelectedProperty,
  setProjectDetails,
  setPropertyName,
  builderPropertyName,
  setShowPropertyModal,
  propertyName,
  selectedLayout,
  invalidFields,
  isGeneralInspiration,
  setIsGeneralInspiration,
}) => {
  const location = useLocation();
  const [availableLayouts, setAvailableLayouts] = useState([]);
  const [pincodeDetails, setPincodeDetails] = useState({});
  const isSampleFlat = location?.pathname?.includes("builder/addproject");
  const queryParams = new URLSearchParams(location.search);
  const isInspirationMode = queryParams.get("q") === "inspirations";

  // Check if the user navigated from ListedPropertyCard
  const fromListedPropertyCard = location?.state?.fromListedPropertyCard || false;

  const parseDateString = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return new Date(`${year}-${month}-${day}`);
  };

  const [startDate, setStartDate] = useState(
    projectDetails?.project_year
      ? parseDateString(projectDetails.project_year)
      : new Date()
  );

  const defaultLayouts = [
    { id: 1, layout: "2BHK" },
    { id: 2, layout: "2.5BHK" },
    { id: 3, layout: "3BHK" },
    { id: 4, layout: "3.5BHK" },
    { id: 5, layout: "4BHK" },
    { id: 7, layout: "5BHK" },
    { id: 9, layout: "6BHK" },
    { id: 11, layout: "7BHK" },
    { id: 13, layout: "8BHK" },
  ];

  // Add pincode validation and autofetch functions
  const validatePincode = (pincode) => {
    const pincodeRegex = /^\d{6}$/;
    if (pincode && !pincodeRegex.test(pincode)) {
      notify("Pincode must be exactly 6 digits", "error");
      return false;
    }
    return true;
  };

  const getPincode = async (pincode) => {
    pincode = pincode.replace(/\D/g, "").slice(0, 6);

    if (pincode.length < 6) {
      setPincodeDetails({});
      setProjectDetails((prevDetails) => ({
        ...prevDetails,
        country: "",
        city: "",
        state: "",
      }));
      return;
    }

    try {
      const response = await authActions.fetchPincode(pincode);
      if (!response || !response.Pincode) {
        notify(
          "Invalid pincode. Please enter a valid 6-digit pincode.",
          "error"
        );
        setPincodeDetails({});
        setProjectDetails((prevDetails) => ({
          ...prevDetails,
          country: "",
          city: "",
          state: "",
        }));
        return;
      }

      setPincodeDetails(response);
      setProjectDetails((prevDetails) => ({
        ...prevDetails,
        pincode: response?.Pincode || "",
        country: response?.Country || "",
        city: response?.District || "",
        state: response?.State || "",
      }));
    } catch (error) {
      console.log(error);
      notify(
        "Error fetching location data. Please try a different pincode.",
        "error"
      );
      setPincodeDetails({});
      setProjectDetails((prevDetails) => ({
        ...prevDetails,
        country: "",
        city: "",
        state: "",
      }));
    }
  };

  const handlePincodeBlur = (e) => {
    const pincode = e.target.value;
    if (pincode && !validatePincode(pincode)) {
      e.target.value = "";
      setProjectDetails((prev) => ({ ...prev, pincode: "" }));
    }
  };

  const handlePincodeKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      notify("Pincode must contain only digits", "error");
    }
  };

  const projectSegments = [
    {
      id: 1,
      label: "Affordable (₹2,500 to ₹4,000)",
      value: "AFFORDABLE_RESIDENTIAL",
    },
    {
      id: 3,
      label: "Mid-Range (₹4,000 to ₹7,000)",
      value: "MID_RANGE_RESIDENTIAL",
    },
    {
      id: 5,
      label: "Luxury (₹7,000 to ₹12,000)",
      value: "LUXURY_RESIDENTIAL",
    },
    {
      id: 7,
      label: "Ultra-Luxury (₹12,000+)",
      value: "ULTRA_LUXURY_RESIDENTIAL",
    },
  ];

  const projectStatuses = [
    { id: 1, label: "Design Phase Completed", value: "DESIGN_PHASE_COMPLETED" },
    { id: 2, label: "Execution In Progress ", value: "EXECUTION_IN_PROGRESS" },
    {
      id: 3,
      label: "Execution Phase Completed ",
      value: "EXECUTION_PHASE_COMPLETED",
    },
  ];

  const projectThemes = [
    { value: "MODERN", label: "Modern" },
    { value: "CONTEMPORARY", label: "Contemporary" },
    { value: "ART_MODERNE", label: "Art Moderne" },
    { value: "MID_CENTURY", label: "Mid-Century" },
    { value: "MINIMALIST", label: "Minimalist" },
    { value: "SCANDINAVIAN", label: "Scandinavian" },
    { value: "SHABBY_CHIC", label: "Shabby Chic" },
    { value: "ECLECTIC", label: "Eclectic" },
    { value: "INDUSTRIAL", label: "Industrial" },
    { value: "FARMHOUSE", label: "Farmhouse" },
    { value: "ART_DECO", label: "Art Deco" },
    { value: "BOHO", label: "Boho – Bohemian" },
    { value: "COASTAL", label: "Coastal" },
    { value: "FRENCH_COUNTRY", label: "French Country" },
    { value: "HOLLYWOOD_GLAM", label: "Hollywood Glam" },
    { value: "JAPANDI", label: "Japandi" },
    { value: "MEDITERRANEAN", label: "Mediterranean" },
    { value: "ASIAN_DECOR", label: "Asian Décor" },
    { value: "RUSTIC", label: "Rustic" },
    { value: "TRADITIONAL", label: "Traditional" },
    { value: "TRANSITIONAL", label: "Transitional" },
    { value: "MAXIMALIST", label: "Maximalist" },
    { value: "REVIVAL_1970S", label: "1970s Revival" },
    { value: "ENGLISH_COUNTRY", label: "English Country" },
    { value: "COTTAGECORE", label: "Cottagecore" },
    { value: "GRANDMILLENNIAL", label: "Grandmillennial" },
    { value: "MOROCCAN", label: "Moroccan" },
  ];

  useEffect(() => {
    if (selectedProperty) {
      if (selectedProperty.layout_type_data) {
        setAvailableLayouts(selectedProperty.layout_type_data);
      } else {
        setAvailableLayouts(defaultLayouts);
      }
    } else {
      setAvailableLayouts(defaultLayouts);
    }
  }, [selectedProperty]);

  return (
    <Form ref={projectInfoRef}>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1 body-text2 inter-500">
          {isSampleFlat
            ? "Sample Flat Name"
            : "Project Name"}
          <sup style={{ color: "red" }}>*</sup>
        </Form.Label>
        <Form.Control
          required
          className="py-2 body-text2"
          type="text"
          name="project_name"
          value={projectDetails?.project_name || ""}
          onChange={(e) => {
            setProjectDetails({
              ...projectDetails,
              project_name: e.target.value,
            });
          }}
          style={{
            border: invalidFields?.project_name
              ? "1px solid red"
              : "1px solid #BDC1C6",
            borderRadius: 3,
          }}
        />
        {invalidFields?.project_name && (
          <Form.Text className="text-danger">
            {isSampleFlat
              ? "Sample Flat Name is required"
              : "Project Name is required"}
          </Form.Text>
        )}
      </Form.Group>

      {!isInspirationMode && !isSampleFlat && (
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            id="isGeneralInspiration"
            label="Is this a general inspiration?"
            checked={isGeneralInspiration}
            onChange={(e) => {
              setIsGeneralInspiration(e.target.checked);
              if (e.target.checked) {
                setSelectedProperty(null);
                setPropertyName("");
              }
            }}
          />
        </Form.Group>
      )}

      <Form.Group className="mb-3">
        <Form.Label className="mb-1 body-text2 inter-500">
          Property Name{!isGeneralInspiration && <sup style={{ color: "red" }}>*</sup>}
        </Form.Label>
        {projectDetails && properties?.length > 0 ? (
          <ReactSelect
            name="project_property"
            isMulti={false}
            options={properties}
            required={!isGeneralInspiration}
            isDisabled={isGeneralInspiration || builderPropertyName}
            getOptionLabel={(e) => e.property_name}
            getOptionValue={(e) => e.id}
            value={selectedProperty}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setProjectDetails({
                  ...projectDetails,
                  property_name: selectedOption.property_name || propertyName,
                });
                setPropertyName(selectedOption.property_name || propertyName);
                setSelectedProperty(selectedOption || propertyName);
              } else {
                setSelectedProperty("");
                setProjectDetails({
                  ...projectDetails,
                  property_name: "",
                });
              }
            }}
            filterOption={(option, inputValue) => {
              if (option.value === "add") {
                return true;
              }
              const propertyName = option.label || "";
              return propertyName
                .toLowerCase()
                .includes(inputValue.toLowerCase());
            }}
            isClearable
            placeholder={
              isGeneralInspiration
                ? "Not required for general inspiration"
                : "Select Property"
            }
            className="body-text2"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: invalidFields?.property_name
                  ? "1px solid red"
                  : "1px solid #BDC1C6",
                borderRadius: 3,
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                borderBottom: "1px solid #DADCE0",
                backgroundColor:
                  state.data.id === "add"
                    ? "#FDF3F3"
                    : state.isFocused
                      ? "#f0f0f0"
                      : baseStyles.backgroundColor,
                color: state.data.id === "add" ? "#C53D3D" : baseStyles.color,
                fontWeight:
                  state.data.id === "add" ? 500 : baseStyles.fontWeight,
              }),
            }}
          />
        ) : (
          <div>Please wait while we are loading the properties...</div>
        )}
        <Form.Control
          type="hidden"
          name="property_name"
          value={projectDetails?.property_name || ""}
        />
        {invalidFields?.property_name && !isGeneralInspiration && (
          <Form.Text className="text-danger">
            Property Name is required
          </Form.Text>
        )}
      </Form.Group>

      <div className="d-flex w-100 mb-3">
        {!isGeneralInspiration ? (
          <Form.Group className="w-50">
            <Form.Label className="mb-1 body-text2 inter-500">
              Layout<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Select
              required
              className="py-2 body-text2"
              type="text"
              placeholder="Select Layout"
              name="bhk"
              value={projectDetails?.bhk || selectedLayout || ""}
              onChange={(e) => {
                const selectedLayoutValue = e.target.value;
                const selectedLayoutObj = availableLayouts?.find(
                  (item) => item.layout === selectedLayoutValue
                );
                setSelectedLayout(selectedLayoutValue);
                setProjectDetails({
                  ...projectDetails,
                  bhk: selectedLayoutValue,
                  project_area: selectedLayoutObj?.area || "",
                });
              }}
              style={{
                border: invalidFields?.bhk
                  ? "1px solid red"
                  : "1px solid #BDC1C6",
                borderRadius: 3,
              }}
            >
              <option value="">Select</option>
              {propertyName &&
                availableLayouts?.map((type, index) => (
                  <option key={index} value={type.layout}>
                    {type.layout}
                  </option>
                ))}
            </Form.Select>
            {invalidFields?.bhk && (
              <Form.Text className="text-danger">Layout is required</Form.Text>
            )}
          </Form.Group>
        ) : (
          <Form.Group className="w-50">
            <Form.Label className="mb-1 body-text2 inter-500">
              Layout<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Select
              required
              className="py-2 body-text2"
              type="text"
              placeholder="Select Layout"
              name="bhk"
              value={projectDetails?.bhk || selectedLayout || ""}
              onChange={(e) => {
                setProjectDetails({
                  ...projectDetails,
                  bhk: e.target.value,
                });
              }}
              style={{
                border: invalidFields?.bhk
                  ? "1px solid red"
                  : "1px solid #BDC1C6",
                borderRadius: 3,
              }}
            >
              <option value="">Select</option>
              {isGeneralInspiration &&
                defaultLayouts?.map((type, index) => (
                  <option key={index} value={type.layout}>
                    {type.layout}
                  </option>
                ))}
            </Form.Select>
            {invalidFields?.bhk && (
              <Form.Text className="text-danger">Layout is required</Form.Text>
            )}
          </Form.Group>
        )}

        {propertyName !== "Non Listed Property" ? (
          <Form.Group className="w-50 ms-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Flat Area<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            {isGeneralInspiration || propertyName === "Non Listed Property" ? (
              <Form.Control
                required
                className="py-2 body-text2"
                type="text"
                placeholder="Enter Flat Area"
                name="project_area"
                value={projectDetails?.project_area || ""}
                onChange={(e) => {
                  setProjectDetails({
                    ...projectDetails,
                    project_area: e.target.value, // Store as string
                  });
                }}
                style={{
                  border: invalidFields?.project_area
                    ? "1px solid red"
                    : "1px solid #BDC1C6",
                  borderRadius: 3,
                }}
              />
            ) : (
              <Form.Select
                required
                className="py-2 body-text2"
                type="text"
                placeholder="Select Flat Area"
                name="project_area"
                value={projectDetails?.project_area || ""}
                onChange={(e) =>
                  setProjectDetails({
                    ...projectDetails,
                    project_area: e.target.value,
                  })
                }
                disabled
                style={{
                  border: invalidFields?.project_area
                    ? "1px solid red"
                    : "1px solid #BDC1C6",
                  borderRadius: 3,
                  backgroundColor: "#f0f0f0",
                }}
              >
                <option value="">Select</option>
                {availableLayouts[0]?.area &&
                  projectDetails.bhk &&
                  availableLayouts?.map((type, index) => (
                    <option key={index} value={type.area}>
                      {type?.area ? `${type.area} sq.ft.` : ""}
                    </option>
                  ))}
              </Form.Select>
            )}
            {invalidFields?.project_area && (
              <Form.Text className="text-danger">
                Flat Area is required
              </Form.Text>
            )}
          </Form.Group>
        ) : (
          <Form.Group className="w-50 ms-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Flat Area<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              required
              className="py-2 body-text2"
              type="text"
              placeholder="Enter Flat Area"
              name="project_area"
              value={projectDetails?.project_area || ""}
              onChange={(e) => {
                setProjectDetails({
                  ...projectDetails,
                  project_area: e.target.value,
                });
                setSelectedLayout(e.target.value);
              }}
              style={{
                border: invalidFields?.project_area
                  ? "1px solid red"
                  : "1px solid #BDC1C6",
                borderRadius: 3,
              }}
            />
            {invalidFields?.project_area && (
              <Form.Text className="text-danger">
                Flat Area is required
              </Form.Text>
            )}
          </Form.Group>
        )}
      </div>

      <div className="d-flex w-100 mb-3">
        <Form.Group className="w-50">
          <Form.Label className="mb-1 body-text2 inter-500">
            {isSampleFlat
              ? "Sample Flat Segment"
              : "Project Segment"}
          </Form.Label>
          <Form.Select
            className="py-2 body-text2"
            type="text"
            placeholder="Select Layout"
            name="property_segment"
            value={projectDetails?.property_segment || ""}
            onChange={(e) =>
              setProjectDetails({
                ...projectDetails,
                property_segment: e.target.value,
              })
            }
            style={{
              border: "1px solid #BDC1C6",
              borderRadius: 3,
            }}
          >
            <option value="">Select</option>
            {projectSegments?.map((type, index) => (
              <option key={index} value={type.value}>
                {type.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="w-50 ms-3">
          <Form.Label className="mb-1 body-text2 inter-500">
            BOQ Cost
          </Form.Label>
          <InputGroup
            style={{
              border: "1px solid #BDC1C6",
              borderRadius: 3,
            }}
          >
            <InputGroup.Text>₹</InputGroup.Text>
            <Form.Control
              required
              className="py-2 body-text2 border-0"
              type="number"
              name="project_cost"
              placeholder="Enter Amount"
              value={projectDetails?.project_cost || ""}
              min={0}
              onChange={(e) => {
                const value = e.target.value;
                if (value < 0) {
                  notify(
                    "BOQ Cost cannot be negative. Please enter a positive value.",
                    "error"
                  );
                } else {
                  setProjectDetails({
                    ...projectDetails,
                    project_cost: value,
                  });
                }
              }}
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
                width: "40%",
              }}
            />
            <Form.Select
              className="body-text2 border-0"
              style={{ borderLeft: "1px solid #BDC1C6" }}
              name="cost_unit"
              value={projectDetails?.cost_unit || ""}
              onChange={(e) =>
                setProjectDetails({
                  ...projectDetails,
                  cost_unit: e.target.value,
                })
              }
            >
              <option value="Lakhs">Lakhs</option>
              <option value="Crores">Crores</option>
            </Form.Select>
          </InputGroup>
        </Form.Group>
      </div>

      <Form.Group className="w-100 mb-3">
        <Form.Label className="mb-1 body-text2 inter-500">
          Designed Date
        </Form.Label>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          className="form-control py-2 body-text2 w-100"
          dateFormat="dd-MM-yyyy"
          name="project_year"
          wrapperClassName="w-100"
          style={{
            border: "1px solid #BDC1C6",
            borderRadius: 3,
          }}
        />
      </Form.Group>
      <div className="w-100 mb-3">
        <Form.Group>
          <Form.Label className="mb-1 body-text2 inter-500">
            Address<sup style={{ color: "red" }}>*</sup>
          </Form.Label>
          <Form.Control
            required={!isGeneralInspiration}
            readOnly={!isGeneralInspiration}
            className="py-2 body-text2"
            type="text"
            name="address"
            placeholder="Enter address ..."
            value={
              isGeneralInspiration
                ? projectDetails?.address || ""
                : selectedProperty?.address || ""
            }
            onChange={(e) => {
              if (isGeneralInspiration) {
                setProjectDetails({
                  ...projectDetails,
                  address: e.target.value,
                });
              }
            }}
            style={{
              border: "1px solid #BDC1C6",
              borderRadius: 3,
              backgroundColor: isGeneralInspiration ? "" : "#F0F0F0",
              color: "#6C757D",
            }}
          />
        </Form.Group>
      </div>
      <Form.Group className="mb-3 d-flex">
        <div className="w-100">
          <Form.Label className="mb-1 body-text2 inter-500">
            Pincode<sup style={{ color: "red" }}>*</sup>
          </Form.Label>
          <Form.Control
            required={!isGeneralInspiration}
            readOnly={!isGeneralInspiration}
            onChange={(e) => {
              if (isGeneralInspiration) {
                // Update the local state first
                setProjectDetails({
                  ...projectDetails,
                  pincode: e.target.value,
                });
                // Only call the API when we have 6 digits
                if (e.target.value.length === 6) {
                  getPincode(e.target.value);
                }
              }
            }}
            className="py-2 body-text2"
            type="text"
            placeholder="Enter pincode"
            name="pincode"
            value={
              isGeneralInspiration
                ? projectDetails?.pincode || ""
                : selectedProperty?.pincode || ""
            }
            style={{
              border: "1px solid #BDC1C6",
              borderRadius: 3,
              backgroundColor: isGeneralInspiration ? "" : "#F0F0F0",
              color: isGeneralInspiration ? "" : "#6C757D",
            }}
          />
        </div>

        <div className="ms-2 w-100">
          <Form.Label className="mb-1 body-text2 inter-500">
            Country<sup style={{ color: "red" }}>*</sup>
          </Form.Label>
          <Form.Control
            required={!isGeneralInspiration}
            readOnly
            className="py-2 body-text2"
            type="text"
            placeholder="Enter country"
            name="country"
            value={
              isGeneralInspiration
                ? projectDetails?.country || ""
                : selectedProperty?.country || ""
            }
            style={{
              border: "1px solid #BDC1C6",
              borderRadius: 3,
              backgroundColor: "#F0F0F0",
              color: "#6C757D",
            }}
          />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 d-flex">
        <div className="w-100">
          <Form.Label className="mb-1 body-text2 inter-500">
            City<sup style={{ color: "red" }}>*</sup>
          </Form.Label>
          <Form.Control
            required={!isGeneralInspiration}
            readOnly
            className="py-2 body-text2"
            type="text"
            placeholder="Enter city"
            name="city"
            value={
              isGeneralInspiration
                ? projectDetails?.city || ""
                : selectedProperty?.city || ""
            }
            style={{
              border: "1px solid #BDC1C6",
              borderRadius: 3,
              backgroundColor: "#F0F0F0",
              color: "#6C757D",
            }}
          />
        </div>

        <div className="ms-2 w-100">
          <Form.Label className="mb-1 body-text2 inter-500">
            State<sup style={{ color: "red" }}>*</sup>
          </Form.Label>
          <Form.Control
            required={!isGeneralInspiration}
            readOnly
            className="py-2 body-text2"
            type="text"
            placeholder="Enter state"
            name="state"
            value={
              isGeneralInspiration
                ? projectDetails?.state || ""
                : selectedProperty?.state || ""
            }
            style={{
              border: "1px solid #BDC1C6",
              borderRadius: 3,
              backgroundColor: "#F0F0F0",
              color: "#6C757D",
            }}
          />
        </div>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="mb-1 body-text2 inter-500">
          {isSampleFlat
            ? "Sample Flat Theme"
            : "Project Theme"}
          <sup style={{ color: "red" }}>*</sup>
        </Form.Label>
        <Form.Select
          required
          className="py-2 body-text2"
          name="project_theme"
          value={projectDetails?.project_theme || ""}
          onChange={(e) =>
            setProjectDetails({
              ...projectDetails,
              project_theme: e.target.value,
            })
          }
          style={{
            border: invalidFields?.project_theme
              ? "1px solid red"
              : "1px solid #BDC1C6",
            borderRadius: 3,
          }}
        >
          <option value="" disabled>
            Select a theme
          </option>
          {projectThemes?.map((theme, index) => (
            <option key={index} value={theme.value}>
              {theme.label}
            </option>
          ))}
        </Form.Select>
        {invalidFields?.project_theme && (
          <Form.Text className="text-danger">
            {isSampleFlat
              ? "Sample Flat Theme is required"
              : "Project Theme is required"}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="mb-1 body-text2 inter-500">
          {isSampleFlat
            ? "Sample Flat Description"
            : "Project Description"}
        </Form.Label>
        <Form.Control
          required
          className="py-2 body-text2"
          as="textarea"
          rows={6}
          name="project_description"
          placeholder="Describe the project..."
          value={projectDetails?.project_description || ""}
          onChange={(e) => {
            setProjectDetails((prev) => ({
              ...prev,
              project_description: e.target.value,
            }));
          }}
          style={{
            border: "1px solid #BDC1C6",
            borderRadius: 3,
            resize: "none",
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default AddProjectForm;