import { brand_placeholder } from "assets";
import LoginModal from "components/Auth/LoginModal";
import ShareModal from "components/General/ShareModal";
import { useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { FaRegHeart } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import BrandCardShimmer from "./BrandCardShimmer";
import axios from "axios";

const BrandCard = ({
  id,
  slug,
  companyName,
  image,
  brandCategories,
  warranty,
  rating,
  averagePricing,
  setSingleRowBrands,
  brands,
  minPricing,
  maxPricing,
  setBrands,
  idToUnSave,
  is_saved,
  created_at,
  setBrandsLength,
  context, // Add context prop
}) => {
  const { user, navbarToggle } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const [showShare, setShowShare] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const checkBrandIfSaved = async () => {
    try {
      const response = await authActions.checkIsSavedBrand(user.id, id);
      setIsLiked(response.status);
    } catch (error) {
      console.log(error);
    }
  };
  const BACKEND_BASE_URL = process.env.REACT_APP_BASE_URL;


  const handleSaveBrand = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      const response = await authActions.saveBrand(user.id, id);
      if (response.status) {
        setIsLiked(true);
        notify("Brand saved!", "success");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUnsaveBrand = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      const response = await authActions.unsaveBrand(user.id, idToUnSave);
      if (response) {
        setBrands((prev) => {
          const updatedBrands = prev.filter((brand) => brand.brand !== id);
          setSingleRowBrands(updatedBrands.slice(0, 3));
          return updatedBrands;
        });
        setBrandsLength((prev) => prev - 1);
        notify("Brand unsaved!", "success");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    checkBrandIfSaved();
  }, []);

  if (!id) return <BrandCardShimmer />;

  const handleNavigation = () => {
    const navigationState = context === "profile"
      ? { state: { fromProfile: true, fromSavedSection: "brands" } }
      : { state: { fromSavedSection: "brands" } };
    navigate(`/brands/${slug}`, navigationState);
  };

  return (
    <>
      <Card
        key={id}
        className="card-primary-hover d-flex p-4 flex-row text-start my-4 border w-100 position-relative"
        style={{ boxShadow: "none" }}
        onClick={handleNavigation}
      >
        <Card.Img
          className="bg-white w-50 border p-md-5"
          variant="top"
          src={`${BACKEND_BASE_URL}${image}`  ? `${BACKEND_BASE_URL}${image}`  : brand_placeholder}
          height={large ? 200 : 150}
          alt="Brand Img"
          style={{ objectFit: "contain", maxWidth: 300 }}
        />
        <div className="d-flex position-absolute" style={{ զIndex: 999, right: 15, top: 10, filter: navbarToggle ? "brightness(60%)" : "none" }}>
          <Button
            className="d-flex justify-content-center align-items-center share-like-btn p-0"
            onClick={(e) => {
              e.stopPropagation();
              setShowShare(true);
            }}
          >
            <FiSend size={20} />
          </Button>
          <Button
            className={`d-flex justify-content-center align-items-center share-like-btn ms-2 p-0 ${isLiked ? "bg-primary text-white" : ""}`}
            disabled={isProcessing}
            onClick={(e) => {
              e.stopPropagation();
              if (!user) setShowLoginModal(true);
              else isLiked ? handleUnsaveBrand() : handleSaveBrand();
            }}
          >
            {isProcessing ? <Spinner size="sm" animation="border" /> : <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : ""} />}
          </Button>
        </div>
        <Card.Body className="w-100">
          <Card.Title className="h4 inter-700 text-gray-900">{companyName}</Card.Title>
          <div className="body-text2 inter-600 text-gray-900">
            {brandCategories?.map((category, index) => (
              <span key={index}>
                {category?.name}
                {index === brandCategories?.length - 1 ? "" : ", "}
              </span>
            ))}
          </div>
          <div className="body-text2 inter-600 text-gray-900">
            <span>Warranty: </span>{warranty ?? "-"}
          </div>
          <div className="body-text2 inter-600 d-flex align-items-center">
            Ratings: {rating}
          </div>
          <div className="body-text2 inter-600 text-gray-900">
            {minPricing && maxPricing && (
              <>
                <span>Average Pricing: </span> ₹{Math.round(minPricing)} - ₹{Math.round(maxPricing)}
              </>
            )}
          </div>
        </Card.Body>
      </Card>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={id}
        image={image}
        title={`${companyName}`}
        urlPath={`/brands/${slug}`}
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </>
  );
};

export default BrandCard;