import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {
  setFloorPlan,
  setRequirement,
  setRoomQuantities,
  setRooms,
  setSelectedRoomType,
} from "reduxFiles/reducer/consultationSlice";

const RoomList = ({ room, index }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(room.quantity);
  const { requirement, selectedRoomType, area, propertyId, rooms } =
    useSelector((state) => state.consultaion);

  const handleUpdateRoomCount = () => {
    const updatedRooms = rooms.map((room, i) =>
      i === index ? { ...room, quantity } : room
    );
    dispatch(setRooms(updatedRooms));

    let selectedRooms = updatedRooms.filter((item) => item.quantity > 0);
    dispatch(
      setSelectedRoomType(
        selectedRooms?.map((item) => ({ [item.label]: item.quantity }))
      )
    );
  };

  useEffect(() => {
    handleUpdateRoomCount();
  }, [quantity]);

  return (
    <div
      key={index}
      className={`body-text2 inter-400 d-flex justify-content-between user-select-none ${index === 0 ? "" : "mt-2"
        }`}
    >
      <div>{room.label}</div>
      <div className="d-flex justify-content-center align-items-center">
        <div
          className={`me-2 d-flex justify-content-center align-items-center ${quantity === 0 ? "" : "cursor-pointer"
            }`}
          style={{
            width: "20px",
            height: "20px",
            borderRadius: 10,
            background: quantity === 0 ? "#FFFFFF" : "#F8F9FA",
          }}
          onClick={() => {
            if (quantity > 0) {
              setQuantity(quantity - 1);
            }
          }}
        >
          <FaMinus size={12} color={quantity === 0 ? "#BDC1C6" : "#1A1C1F"} />
        </div>
        <div>{quantity}</div>
        <div
          className="ms-2 d-flex justify-content-center align-items-center cursor-pointer"
          style={{
            width: "20px",
            height: "20px",
            borderRadius: 10,
            background: "#F8F9FA",
          }}
          onClick={() => setQuantity(quantity + 1)}
        >
          <FaPlus size={12} />
        </div>
      </div>
    </div>
  );
};

const InteriorRequirement = ({ floorPlan, selectedProperty }) => {
  const dispatch = useDispatch();
  // const [selectedOption, setSelectedOption] = useState("");
  const { requirement, selectedRoomType, area, propertyId, rooms } =
    useSelector((state) => state.consultaion);

  /*  const [rooms, setRooms] = useState([
    { id: 1, label: "Bedroom", value: "bedroom", quantity: 0 },
    { id: 2, label: "Bathroom", value: "bathroom", quantity: 0 },
    { id: 3, label: "Kitchen", value: "kitchen", quantity: 0 },
    { id: 4, label: "Dining Room", value: "diningroom", quantity: 0 },
    { id: 5, label: "Living Room", value: "livingroom", quantity: 0 },
  ]); */
  const standardFloorPlans = [
    { id: 1, title: "2 BHK", slug: "2BHK" },
    { id: 2, title: "2.5 BHK", slug: "2.5BHK" },
    { id: 3, title: "3 BHK", slug: "3BHK" },
    { id: 4, title: "3.5 BHK", slug: "3.5BHK" },
    { id: 5, title: "4 BHK", slug: "4BHK" },
    { id: 6, title: "5 BHK", slug: "5BHK" },
    { id: 7, title: "6 BHK", slug: "6BHK" },
    { id: 8, title: "7 BHK", slug: "7BHK" },
    { id: 9, title: "8 BHK", slug: "8BHK" },

  ];

  const handleOptionChange = (event) => {
    event.stopPropagation();
  
    const selectedOption = event.target.value;
    dispatch(setRequirement(selectedOption))
  };

  const [propertyFloorPlans, setPropertyFloorPlans] = useState(standardFloorPlans);


  // Prepare property layouts when a property is selected
  useEffect(() => {
    if (selectedProperty && selectedProperty.layout_type_data && selectedProperty.layout_type_data.length > 0) {
      // Format the property layouts for display
      const layouts = selectedProperty.layout_type_data.map((layout) => ({
        id: layout.id,
        title: `${layout.layout} (${layout.area} sqft)`,
        slug: layout.layout,
      }));
      setPropertyFloorPlans(layouts);
    } else {
      setPropertyFloorPlans([]);
    }
  }, [selectedProperty]);

  return (
    <div>
      <div>
        <div className="body-text2 inter-500 text-start mb-1">
          {selectedProperty ? "Property Layout Plan" : "Layout Plan"}
        </div>

        <Row className="row-cols-3 px-2">
          {/* Show property layouts if property is selected, otherwise show standard layouts */}
          {selectedProperty && propertyFloorPlans.length > 0 ? (
            propertyFloorPlans.map((plan) => (
              <Col key={plan.id} className="mb-2 px-1">
                <Button
                  className={`body-text2 inter-500 w-100 btn-bg-custom ${floorPlan === plan.slug
                      ? "border-gray-600 text-gray-700 bg-gray-50"
                      : "border-gray-300 text-gray-700 bg-white"
                    }`}
                  style={{ borderRadius: 3, minHeight: 36 }}
                  onClick={() => dispatch(setFloorPlan(plan.slug))}
                >
                  {plan.title}
                </Button>
              </Col>
            ))
          ) : (
            standardFloorPlans.map((plan) => (
              <Col key={plan.id} className="mb-2 px-1">
                <Button
                  className={`body-text2 inter-500 w-100 btn-bg-custom ${floorPlan === plan.slug
                      ? "border-gray-600 text-gray-700 bg-gray-50"
                      : "border-gray-300 text-gray-700 bg-white"
                    }`}
                  style={{ borderRadius: 3, minHeight: 36 }}
                  onClick={() => dispatch(setFloorPlan(plan.slug))}
                >
                  {plan.title}
                </Button>
              </Col>
            ))
          )}
        </Row>
      </div>
      
      <div className="mb-3">
        <div className="body-text1 inter-500 text-start mt-3 mb-1">
          Your Requirement
        </div>
        <div className="body-text2 inter-500 text-start mb-1">Spaces</div>
        <div className="d-flex justify-content-between body-text2">
          <div
            className={`text-gray-900 bg-white px-2 py-1 me-2 inter-500 w-100 border ${requirement === "complete_home"
              ? "border-gray-400"
              : "border-gray-200 bg-white"
              }`}
            style={{ borderRadius: 3 }}
          >
            <label
              onClick={handleOptionChange}
              className="d-flex align-items-center cursor-pointer"
            >
              <Form.Check
                type="radio"
                name="requirement"
                value="complete_home"
                className="me-2"
                checked={requirement === "complete_home"}
                readOnly
              />
              <span
                className={`ms-1 ${requirement === "complete_home"
                  ? "text-gray-700 border-gray-600"
                  : "text-gray-700 border-gray-300"
                  }`}
              >
                Complete Home
              </span>
            </label>
          </div>
          <div
            className={`text-gray-900 bg-white px-2 py-1 inter-500 w-100 border ${requirement === "selected_rooms"
              ? "border-gray-400"
              : "border-gray-200"
              }`}
            style={{ borderRadius: 3 }}
          >
            <label
              onClick={handleOptionChange}
              className="d-flex align-items-center cursor-pointer"
            >
              <Form.Check
                type="radio"
                name="requirement"
                value="selected_rooms"
                className="me-2"
                checked={requirement === "selected_rooms"}
                readOnly
              />
              <span
                className={`ms-1 ${requirement === "selected_rooms"
                  ? "text-gray-700 border-gray-600"
                  : "text-gray-700 border-gray-300"
                  }`}
              >
                Selected Spaces Only
              </span>
            </label>
          </div>
        </div>
        {requirement === "selected_rooms" && (
          <div
            className="bg-white mt-2"
            style={{
              border: "1px solid #DADCE0",
              borderRadius: 6,
              padding: 12,
            }}
          >
            {rooms.map((room, index) => (
              <RoomList key={room.id} room={room} index={index} />
            ))}
          </div>
        )}
      </div>

      

      {/* Show property-specific additional options if a property is selected */}
      {selectedProperty && selectedProperty.hasAdditionalOptions && (
        <div className="mt-3">
          <div className="body-text2 inter-500 text-start mb-1">
            Property-Specific Options
          </div>
          <div className="p-3 border rounded">
            {/* This would display property-specific options */}
            <p className="mb-0 body-text2">
              Additional options for {selectedProperty.property_name}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default InteriorRequirement;
