import React, { useRef, useState } from "react";
import TeamMember from "./TeamMember";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

const TeamMemberList = ({
  teamMembers,
  handleDeleteMember,
  handleAddTeamMember,
  handleEditTeamMember,
  showAddTeamModal,
  setShowAddTeamModal,
  isSubmitting,
  enableEdit = false,
}) => {
  const addFormRef = useRef();
  const editFormRef = useRef();
  const [showEditTeamModal, setShowEditTeamModal] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);

  const handleEditClick = (teamMember) => {
    console.log("Opening edit modal for:", teamMember.id);
    setSelectedTeamMember(teamMember);
    setShowEditTeamModal(true);
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    console.log("Saving edit for:", selectedTeamMember?.id);

    const formData = new FormData();
    const nameInput = editFormRef.current.elements.name.value;
    const roleInput = editFormRef.current.elements.role.value;
    const imageInput = editFormRef.current.elements.image;

    formData.append("name", nameInput);
    formData.append("role", roleInput);
    if (imageInput.files && imageInput.files.length > 0) {
      formData.append("image", imageInput.files[0]);
      console.log("New image selected:", imageInput.files[0].name);
    } else {
      console.log("No image change");
    }

    console.log("FormData:", [...formData.entries()]);
    handleEditTeamMember(selectedTeamMember.id, formData);
    setShowEditTeamModal(false);
    setSelectedTeamMember(null);
  };

  return (
    <div className="py-3 mt-2">
      {teamMembers?.length > 0 ? (
        <div className="d-flex flex-wrap">
          {teamMembers.map((teamMember, index) => (
            <TeamMember
              key={teamMember.id} // Use unique ID instead of index
              {...teamMember}
              handleDelete={handleDeleteMember}
              handleEdit={enableEdit ? () => handleEditClick(teamMember) : null}
            />
          ))}
          {enableEdit && (
            <div
              className="inter-400 text-center d-flex flex-column justify-content-start align-items-center mt-2"
              style={{ height: "160px", cursor: "pointer" }}
              onClick={() => setShowAddTeamModal(true)}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid #E8EAED",
                  borderRadius: "50px",
                  backgroundColor: "#eee",
                }}
              >
                <span style={{ fontSize: 40, color: "#ccc" }}>+</span>
              </div>
              <div className="text-muted body-text-2" style={{ marginTop: "10px" }}>
                Add Team Member
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
          style={{ height: 160 }}
        >
          {enableEdit ? (
            <div
              className="inter-400 text-center d-flex flex-column justify-content-start align-items-center mt-3"
              style={{ height: "160px", cursor: "pointer" }}
              onClick={() => setShowAddTeamModal(true)}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid #E8EAED",
                  borderRadius: "50px",
                  backgroundColor: "#eee",
                }}
              >
                <span style={{ fontSize: 40, color: "#ccc" }}>+</span>
              </div>
              <div className="text-muted body-text-2" style={{ marginTop: "10px" }}>
                Add Team Member
              </div>
            </div>
          ) : (
            "No Team Members available"
          )}
        </div>
      )}

      {/* Add Team Member Modal */}
      <Modal show={showAddTeamModal} onHide={() => setShowAddTeamModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={addFormRef}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter name"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Control
                type="text"
                name="role"
                placeholder="Enter role"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Profile Picture</Form.Label>
              <Form.Control type="file" name="image" accept="image/*" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddTeamModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              const formData = new FormData(addFormRef.current);
              handleAddTeamMember(formData);
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <Spinner size="sm" animation="border" /> : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Team Member Modal */}
      <Modal show={showEditTeamModal} onHide={() => setShowEditTeamModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={editFormRef}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                defaultValue={selectedTeamMember?.name}
                placeholder="Enter name"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Control
                type="text"
                name="role"
                defaultValue={selectedTeamMember?.role}
                placeholder="Enter role"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Profile Picture</Form.Label>
              <Form.Control type="file" name="image" accept="image/*" />
              {selectedTeamMember?.image && (
                <div className="mt-2">
                  <img
                    src={selectedTeamMember.image}
                    alt="Current Profile"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                </div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditTeamModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEditSubmit} disabled={isSubmitting}>
            {isSubmitting ? <Spinner size="sm" animation="border" /> : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TeamMemberList;