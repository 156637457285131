import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Privacy = () => {
    return (
        <Container
            fluid
            className="mt-5 pt-4 text-start"
            style={{ minHeight: "90vh" }}
        >
            <div style={{ marginTop: '16px', textAlign: 'justify' }}>
                <span className="h3 inter-600 text-black p-4 mb-6" >Interiokeys Privacy Policy</span>
                <div className="p-4 inter-400 body-title">
                    <p className="mb-4">InterioKeys is the leading platform for home renovation and design, providing an all-in-one software solution for industry professionals and tools for homeowners to update their homes from start to finish. Using InterioKeys, people can find ideas and inspiration, hire professionals, and browse products. The platform provides home industry professionals with a business management and marketing SaaS solution that helps them win projects, collaborate with clients and teams, and run their business efficiently and profitably.</p>
                    
                    <p>This Privacy Policy describes what personal information we collect through the Platform, how we use this information, how this information may be shared, and what choices and rights you have with respect to this information. Access and use of our Platform is subject to this Privacy Policy.</p>
                    
                    <p className="mb-0">Information We Collect: <br />
                        We may collect the following types of information:</p>
                    <ul>
                        <li>Account and Profile Information: We collect details such as your name, email address, interests, gender, age, and profile information when you create an account.</li>
                        <li>Professional Information: If you register as a professional, we collect additional business details, including company name, phone number, and areas served.</li>
                        <li>Purchases: If you make payments within our Platform, we collect transaction details and billing information.</li>
                        <li>Usage Data: We collect information on how you interact with our Platform, including log data, IP addresses, device information, and browsing behavior.</li>
                        <li>Cookies and Tracking Technologies: We use cookies and similar technologies to enhance user experience and analyze usage trends.</li>
                    </ul>

                    <p className="mb-0">How We Use Your Information <br />
                        We use the collected information for:</p>
                    <ul>
                        <li>Providing and improving our Platform services.</li>
                        <li>Enabling communication between users.</li>
                        <li>Processing transactions and payments.</li>
                        <li>Personalizing user experience and showing relevant content.</li>
                        <li>Marketing and promotional purposes.</li>
                        <li>Ensuring security and preventing fraudulent activities.</li>
                        <li>Complying with legal obligations.</li>
                    </ul>

                    <p className="mb-0">How We Share Your Information <br />
                        We do not sell your personal data. However, we may share information with:</p>
                    <ul>
                        <li>Service Providers: Third-party vendors that help us operate the platform (e.g., payment processors, hosting providers, analytics services).</li>
                        <li>Business Partners: If you engage with brands or designers through InterioKeys, relevant information may be shared to facilitate collaboration.</li>
                        <li>Legal Compliance: If required by law or to protect the rights and safety of InterioKeys and its users.</li>
                        <li>Mergers & Acquisitions: In case of a business transfer, your information may be shared with a successor entity.</li>
                    </ul>

                    <p className="mb-0">Data Security <br />
                        We implement industry-standard security measures to protect your information. However, no method of transmission over the Internet is 100% secure.</p>

                    <p>Your Rights and Choices <br />
                        You have the right to:</p>
                    <ul>
                        <li>Access, update, or delete your personal information.</li>
                        <li>Opt out of marketing communications.</li>
                        <li>Manage cookie preferences through browser settings.</li>
                        <li>Request data portability and restrict processing in certain cases.</li>
                    </ul>
                    <p>To exercise these rights, contact us at hello@interiokeys.in</p>
                    
                    <p>Third-Party Links <br />
                        InterioKeys may contain links to third-party websites. We are not responsible for their privacy practices.</p>

                    <p>Retention of Data <br />
                        We retain your information as long as necessary to fulfill the purposes outlined in this policy unless a longer retention period is required by law.</p>

                    <p>Updates to This Privacy Policy <br />
                        We may update this Privacy Policy from time to time. Changes will be posted on this page with the updated effective date.</p>

                    <p className="mb-0">Trademarks and Intellectual Property</p>
                    <ul>
                        <li>"InterioKeys" and associated logos, designs, and trademarks are the exclusive property of InterioKeys.</li>
                        <li>You may not copy, imitate, or use any branding, visual elements, or proprietary content without authorization.</li>
                        <li>Any unauthorized use of trademarks may result in legal action.</li>
                    </ul>

                    <p>Contact Us <br />
                        For any questions about this Privacy Policy, please contact us at:<br />
                        hello@interiokeys.in</p>
                </div>

            </div>
        </Container >
    );
};

export default Privacy;
