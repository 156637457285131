import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Dropdown,
  InputGroup,
  Alert,
} from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import * as authActions from "reduxFiles/actions/authActions";
import axios from "axios";
import { useSelector } from "react-redux";

const DownloadCSVModal = ({ show, handleClose, setIsDownloading }) => {
   const user = useSelector((state) => state.auth.user);
  const [productCategories, setProductCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productSubTypes, setProductSubTypes] = useState([]);
  
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProductType, setSelectedProductType] = useState("");
  const [selectedProductSubType, setSelectedProductSubType] = useState("");
  
  const [searchCategoryTerm, setSearchCategoryTerm] = useState("");
  const [searchTypeTerm, setSearchTypeTerm] = useState("");
  const [searchSubtypeTerm, setSearchSubtypeTerm] = useState("");
  
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState("");

  // Filter product categories based on the search query
  const filteredCategories = productCategories.filter((prodCat) =>
    prodCat.title.toLowerCase().includes(searchCategoryTerm.toLowerCase())
  );

  const filteredProductTypes = productTypes.filter((prodType) =>
    prodType.title.toLowerCase().includes(searchTypeTerm.toLowerCase())
  );

  const filteredProductSubtypes = productSubTypes.filter((prodSubtype) =>
    prodSubtype.title.toLowerCase().includes(searchSubtypeTerm.toLowerCase())
  );

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchProductTypes(selectedCategory);
      setSelectedProductType("");
      setSelectedProductSubType("");
      setProductSubTypes([]);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedProductType) {
      fetchProductSubTypes(selectedProductType);
      setSelectedProductSubType("");
    }
  }, [selectedProductType]);

  const fetchCategories = async () => {
    try {
      const response = await authActions.fetchProductCategories();
      if (response?.data) {
        setProductCategories(response.data);
      }
    } catch (e) {
      console.log(e);
      setError("Failed to load product categories");
    }
  };

  const fetchProductTypes = async (categoryId) => {
    try {
      const response = await authActions.fetchProductTypes(categoryId);
      if (response?.data) {
        setProductTypes(response.data);
      }
    } catch (e) {
      console.log(e);
      setError("Failed to load product types");
    }
  };

  const fetchProductSubTypes = async (typeId) => {
    try {
      const response = await authActions.fetchProductSubTypes(typeId);
      if (response?.data) {
        setProductSubTypes(response.data);
      }
    } catch (e) {
      console.log(e);
      setError("Failed to load product subtypes");
    }
  };

  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const handleProductTypeSelect = (productTypeId) => {
    setSelectedProductType(productTypeId);
    setSearchTypeTerm("");
  };

  const handleProductSubtypeSelect = (productSubtypeId) => {
    setSelectedProductSubType(productSubtypeId);
    setSearchSubtypeTerm("");
  };

  const handleDownloadExcel = async () => {
    setDownloading(true);
    setIsDownloading(true);
    setError("");
    
    try {
      // Build the query parameters based on the selected filters
      let url = '/product/products/export_excel/?';
      
      if (selectedCategory) {
        url += `category=${selectedCategory}&`;
      }
      
      if (selectedProductType) {
        url += `product_type=${selectedProductType}&`;
      }
      
      if (selectedProductSubType) {
        url += `product_sub_type=${selectedProductSubType}&`;
      }
      
      if (user) {
        url += `user=${user?.id}&`;
      }
      
      // Remove trailing '&' or '?' if present
      url = url.endsWith('&') ? url.slice(0, -1) : url;
      url = url.endsWith('?') ? url.slice(0, -1) : url;
      
      // Make the API call to get the Excel file
      const response = await axios.get(url, {
        responseType: 'blob' // Important for handling file downloads
      });
      
      // Create a blob from the response data with the Excel MIME type
      const blob = new Blob([response.data], { 
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
      });
      
      // Create a temporary URL for the blob
      const downloadUrl = window.URL.createObjectURL(blob);
      
      // Create an anchor element and set its attributes
      const a = document.createElement('a');
      a.href = downloadUrl;
      
      // Set the file name
      let fileName = 'products';
      if (selectedCategory) {
        const category = productCategories.find(cat => cat.id === selectedCategory);
        if (category) fileName += `_${category.title.replace(/\s+/g, '_')}`;
      }
      if (selectedProductType) {
        const productType = productTypes.find(type => type.id === selectedProductType);
        if (productType) fileName += `_${productType.title.replace(/\s+/g, '_')}`;
      }
      if (selectedProductSubType) {
        const productSubType = productSubTypes.find(subType => subType.id === selectedProductSubType);
        if (productSubType) fileName += `_${productSubType.title.replace(/\s+/g, '_')}`;
      }
      fileName += '.xlsx'; // Changed extension to .xlsx
      a.download = fileName;
      
      // Trigger the download
      document.body.appendChild(a);
      a.click();
      
      // Clean up
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
      
      // Close the modal
      handleClose();
    } catch (error) {
      console.error('Error downloading Excel:', error);
      setError('Failed to download Excel file. Please try again.');
    } finally {
      setDownloading(false);
      setIsDownloading(false);
    }
  };

  // Reset error when modal is opened/closed
  useEffect(() => {
    if (show) {
      setError("");
    }
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Download Products Spread Sheet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert variant="danger" className="mb-3">
            {error}
          </Alert>
        )}
        
        <p>Filter the products you want to download:</p>
        
        <Form.Group className="mb-3">
          <Form.Label>Product Category</Form.Label>
          <Dropdown>
            <Dropdown.Toggle
              className="bg-transparent text-gray-700 cursor-pointer w-100"
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
              }}
            >
              {selectedCategory
                ? productCategories.find(
                    (cat) => cat.id === selectedCategory
                  )?.title
                : "Select Product Category"}
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                maxHeight: "250px",
                maxWidth: "100%",
                overflowY: "auto",
              }}
            >
              <InputGroup className="mb-3 px-2">
                <Form.Control
                  placeholder="Search categories..."
                  value={searchCategoryTerm}
                  onChange={(e) => setSearchCategoryTerm(e.target.value)}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
              </InputGroup>

              {filteredCategories.map((prodCat) => (
                <Dropdown.Item
                  as="button"
                  key={prodCat.id}
                  onClick={() => handleCategorySelect(prodCat.id)}
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                  }}
                >
                  {prodCat.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Product Type</Form.Label>
          <Dropdown>
            <Dropdown.Toggle
              className="bg-transparent text-gray-700 cursor-pointer w-100"
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
              }}
              disabled={!selectedCategory}
            >
              {selectedProductType
                ? productTypes.find(
                    (type) => type.id === selectedProductType
                  )?.title
                : "Select Product Type"}
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                maxHeight: "250px",
                maxWidth: "100%",
                overflowY: "auto",
              }}
            >
              <InputGroup className="mb-3 px-2">
                <Form.Control
                  placeholder="Search product types..."
                  value={searchTypeTerm}
                  onChange={(e) => setSearchTypeTerm(e.target.value)}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
              </InputGroup>

              {filteredProductTypes.map((prodType) => (
                <Dropdown.Item
                  as="button"
                  key={prodType.id}
                  onClick={() => handleProductTypeSelect(prodType.id)}
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                  }}
                >
                  {prodType.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Product Subtype</Form.Label>
          <Dropdown>
            <Dropdown.Toggle
              className="bg-transparent text-gray-700 cursor-pointer w-100"
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
              }}
              disabled={!selectedProductType}
            >
              {selectedProductSubType
                ? productSubTypes.find(
                    (subtype) => subtype.id === selectedProductSubType
                  )?.title
                : "Select Product Subtype"}
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                maxHeight: "250px",
                maxWidth: "100%",
                overflowY: "auto",
              }}
            >
              <InputGroup className="mb-3 px-2">
                <Form.Control
                  placeholder="Search product subtypes..."
                  value={searchSubtypeTerm}
                  onChange={(e) => setSearchSubtypeTerm(e.target.value)}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
              </InputGroup>

              {filteredProductSubtypes.map((prodSubtype) => (
                <Dropdown.Item
                  as="button"
                  key={prodSubtype.id}
                  onClick={() => handleProductSubtypeSelect(prodSubtype.id)}
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                  }}
                >
                  {prodSubtype.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
        
        
        <p className="text-muted small mt-3">
          Note: The Spread Sheet will include all standard product fields plus any dynamic fields 
          specific to the selected product subtype.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={handleDownloadExcel}
          disabled={downloading || !selectedCategory || !selectedProductType || !selectedProductSubType}
        >
          {downloading ? 'Downloading...' : 'Download Excel'} <FaDownload className="ms-1" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DownloadCSVModal;