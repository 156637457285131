import { useEffect, useState } from "react";
import { Card, Image, Spinner } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { truncateText } from "utilities/truncateText";
import userImg from "../../assets/navbar/user.webp";
import useFetchMedia from "utilities/customHooks/useFetchMedia";

const InspirationMegaMenu = ({
  showInspirations,
  setShowInspirations,
  toggled,
  setToggled,
}) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const navigate = useNavigate();
  const projectsRooms = [
    { id: 1, title: "All Projects", slug: "projects" },
    { id: 2, title: "All Spaces", slug: "spaces" },
    { id: 3, title: "General Inspirations", slug: "general-inspirations" },
  ];
  const [activeTab, setActiveTab] = useState(projectsRooms[0]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      switch (activeTab.slug) {
        case "projects":
          console.log(activeTab.slug);
          const response = await authActions.fetchAllProperties();
          if (response.data) {
            setData(response.data);
          }
          break;
        case "spaces":
          console.log(activeTab.slug);
          const response1 = await authActions.fetchRoomTypes();
          if (response1.data) {
            setData(response1.data);
          }
          break;
        case "general-inspirations":
          // Fetch general inspiration projects
          const response2 = await authActions.fetchProjects(
            `/project/projects/?status=APPROVED&is_general_inspiration=true`
          );
          if (response2) {
            // Format the data to match the expected structure in the render logic
            const formattedData = response2.results.map(project => {
              // Check if project has rooms and use the first room's ID
              const roomId = project.rooms && project.rooms.length > 0 
                ? project.rooms[0].id 
                : null;
                
              return {
                id: project.id,
                slug: project.slug,
                property_name: project.project_name,
                // Set a placeholder image initially
                property_img: project.thumbnail_image || project.images?.[0] || userImg,
                is_general_inspiration: true,
                roomId: roomId // Using the first room's ID instead of project ID
              };
            });
            setData(formattedData);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (activeTab) {
      fetchData();
    }
  }, [activeTab]);

  // Create separate components for each type to properly use hooks
  const GeneralInspirationItem = ({ item, setShowInspirations }) => {
    const navigate = useNavigate();
    // Always call the hook without conditions, but only if roomId exists
    const { image: customImage, loading: imageLoading } = useFetchMedia(
      "project", 
      "room", 
      item.roomId
    );
    
    // Use the custom image if available, otherwise fall back to the default
    const displayImage = customImage || item?.property_img || item?.image || userImg;
    
    return (
      <div
        className="d-flex flex-column align-items-center body-text3 nav-link cursor-pointer me-2"
        onClick={() => {
          setShowInspirations(false);
          navigate(`/projects/${item.slug}`);
        }}
      >
        {imageLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ width: "90px", height: "80px" }}>
            <Spinner size="sm" />
          </div>
        ) : (
          <Image
            src={displayImage}
            alt={item?.property_name || item?.title || "Project Image"}
            className="small-img"
            width="90px"
            height="80px"
            style={{ objectFit: "contain" }}
          />
        )}
        <div className="p-1">
          {truncateText(
            item?.property_name || item?.title,
            13
          )}
        </div>
      </div>
    );
  };
  
  // Regular item for projects and spaces - NO HOOKS USED HERE
  const RegularItem = ({ item, activeTab, setShowInspirations }) => {
    const navigate = useNavigate();
    
    return (
      <div
        className="d-flex flex-column align-items-center body-text3 nav-link cursor-pointer me-2"
        onClick={() => {
          setShowInspirations(false);
          if (activeTab.slug === "projects") {
            navigate(`/projects?property=${item.slug}`);
          } else if (activeTab.slug === "spaces") {
            navigate(`/spaces?room-type=${item.slug}`);
          }
        }}
      >
        <Image
          src={item?.property_img || item?.image || userImg}
          alt={item?.property_name || item?.title || "Project Image"}
          className="small-img"
          width="90px"
          height="80px"
          style={{ objectFit: "contain" }}
        />
        <div className="p-1">
          {truncateText(
            item?.property_name || item?.title,
            13
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {small && (
        <Card
          className={`${
            showInspirations
              ? "position-absolute huge-dropdown-container d-flex p-0 shadow-none"
              : "d-none"
          }`}
          style={{ left: 0, top: 70 }}
        >
          <Card.Body
            className="p-0"
            style={{ backgroundColor: "#FFFFFF", borderRadius: 5 }}
          >
            <div className="pt-2" style={{ padding: "0px 50px 30px" }}>
              <div className="body-text2 d-flex mb-4">
                {projectsRooms.map((item) => (
                  <div
                    key={item.slug}
                    className="me-2 cursor-pointer"
                    onClick={() => setActiveTab(item)}
                  >
                    <div
                      className={`my-1 mx-2 inter-500 ${
                        item.slug === activeTab.slug
                          ? "text-primary"
                          : "text-gray-900"
                      }`}
                    >
                      {item.title}
                    </div>
                    <div
                      className={`mt-1 w-100 ${
                        item.slug === activeTab.slug ? "bg-primary" : ""
                      }`}
                      style={{ height: 2 }}
                    />
                  </div>
                ))}
              </div>
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "10vh" }}
                >
                  <Spinner />
                </div>
              ) : (
                <>
                  <div
                    className="body-text3 d-flex align-items-center inter-500 mb-2 cursor-pointer"
                    onClick={() => {
                      setShowInspirations(false);
                      if (activeTab.slug === "projects") {
                        navigate(`/projects`);
                      } else if (activeTab.slug === "spaces") {
                        navigate(`/spaces`);
                      } else if (activeTab.slug === "general-inspirations") {
                        navigate(`/projects?is_general_inspiration=true`);
                      }
                    }}
                  >
                    <span className="me-2">
                      View All{" "}
                      {activeTab.slug === "projects" 
                        ? "Projects" 
                        : activeTab.slug === "spaces" 
                          ? "Spaces" 
                          : "General Inspirations"}
                    </span>
                    <FaChevronRight size={12} />
                  </div>
                  <div className="d-flex flex-wrap">
                    {data?.length > 0 ? (
                      data?.map((item, index) => {
                        // Render different components based on the tab
                        if (activeTab.slug === "general-inspirations") {
                          return (
                            <GeneralInspirationItem 
                              key={index} 
                              item={item} 
                              setShowInspirations={setShowInspirations} 
                            />
                          );
                        } else {
                          return (
                            <RegularItem 
                              key={index} 
                              item={item} 
                              activeTab={activeTab}
                              setShowInspirations={setShowInspirations} 
                            />
                          );
                        }
                      })
                    ) : (
                      <div className="text-center w-100 py-3 text-muted">
                        No {activeTab.slug === "projects" 
                          ? "projects" 
                          : activeTab.slug === "spaces" 
                            ? "spaces" 
                            : "general inspirations"} available
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </Card.Body>
        </Card>
      )}
      {!small && showInspirations && (
        <div className="d-flex flex-column mb-2">
          {projectsRooms.map((item) => (
            <div
              key={item.slug}
              className="me-2 cursor-pointer"
              onClick={() => {
                if (item.slug === "projects") {
                  navigate(`/projects`);
                } else if (item.slug === "spaces") {
                  navigate(`/spaces`);
                } else if (item.slug === "general-inspirations") {
                  navigate(`/projects?is_general_inspiration=true`);
                }
                setToggled(!toggled);
              }}
              style={{ padding: "9px 16px" }}
            >
              <div className="body-text1 inter-500 text-gray-900">
                {item.title}
              </div>
              <div
                className="body-text2 inter-400 text-gray-600"
                style={{
                  width: "250px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.slug === "spaces"
                  ? "E.g. Bedroom, Bathroom, Living Room"
                  : item.slug === "general-inspirations"
                  ? "E.g. Modern Living, Minimalist Kitchen, Classic Bedroom"
                  : "E.g. Jewel of India, Mangalam Radiance, Melodia Apartments"}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default InspirationMegaMenu;