import { Card, Button, Placeholder } from "react-bootstrap";
import { FiSend } from "react-icons/fi";
import { FaRegHeart } from "react-icons/fa";

const ProjectCardShimmer = () => {
  return (
    <Card className="cursor-pointer w-100 py-0 px-0 border rounded shadow-none overflow-hidden position-relative">
      {/* Image Placeholder */}
      <div
        className="img-fluid bg-light placeholder-glow"
        style={{
          width: "100%",
          height: "220px",
          background:
            "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
          backgroundSize: "200% 100%",
          animation: "loading 1.5s infinite",
        }}
      ></div>

      {/* Action Buttons */}
      <div
        className="d-flex position-absolute"
        style={{
          zIndex: 999,
          right: 15,
          top: 10,
        }}
      >
        <Button className="d-flex justify-content-center align-items-center share-like-btn p-0 placeholder">
          <FiSend size={20} />
        </Button>

        <Button className="d-flex justify-content-center align-items-center share-like-btn ms-2 p-0 placeholder">
          <FaRegHeart size={20} />
        </Button>
      </div>

      {/* Card Body */}
      <Card.Body className="p-2 d-flex flex-column align-items-start">
        <Placeholder as={Card.Title} animation="glow">
          <Placeholder xs={8} />
        </Placeholder>
        <Placeholder
          as="div"
          animation="glow"
          className="d-flex flex-column w-100"
        >
          <Placeholder xs={6} className="mb-2" />
          <Placeholder xs={4} />
        </Placeholder>
      </Card.Body>
    </Card>
  );
};

export default ProjectCardShimmer;
