import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { LuMail } from "react-icons/lu";
import { brandsignup } from "assets";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from "components/Forms/MultiSelect";
import * as authActions from "reduxFiles/actions/authActions";

const BrandSignup = ({
  form,
  step,
  setStep,
  handleSignUp,
  verifyOtpLogin,
  loading,
  emailError,
  setEmailError,
  otp,
  setOtp,
  setUsername,
  resendTimer,
  setResendTimer,
  isResendDisabled,
  setIsResendDisabled,
  setShowOtpFields,
  showOtpFields,
}) => {
  // const [showOtpFields, setShowOtpFields] = useState(false);
  const navigate = useNavigate();
  // const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [validate, setValidate] = useState(false);

  const [formData, setFormData] = useState({
    full_name: "",
    mobile: "",
    email: "",
    brand_category: [],
  });


  useEffect(() => {
    let timer;
    if (showOtpFields && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setIsResendDisabled(false);
    }
    fetchBrandCategories();
    return () => clearInterval(timer);
  }, [showOtpFields, resendTimer]);

  const fetchBrandCategories = async () => {
    try {
      const response = await authActions.fetchBrandCategoriesNoPagination();
      // console.log(response);
      if (response?.data) {
        setCategories(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    const otpArray = otp.split("");

    if (value.match(/^\d$/)) {
      otpArray[index] = value;
      setOtp(otpArray.join(""));

      // Move to the next input if there is one
      if (index < 3) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
    else if (value === "") {
      otpArray[index] = "";
      setOtp(otpArray.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    // alert(e.key);
    const otpArray = otp.split("");
    console.log(otpArray);
    if (e.key === "Backspace" && (otpArray[index] === "" || otpArray.length < (index + 1)) && index > 0) {
      // Move focus to previous field and clear its value
      let x = document.getElementById(`otp-${index - 1}`);
      console.log(x);
      x.focus();
      setOtp((prev) => {
        const otpArray = prev.split("");
        otpArray[index - 1] = "";
        return otpArray.join("");
      });
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log(value);
  //   setFormData((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(value);

    const finalValue = name === "email" ? value.toLowerCase() : value;

    setFormData((prev) => ({
      ...prev,
      [name]: finalValue,
    }));
  };

  // New handler for MultiSelect
  const handleCategoryChange = (selectedOptions) => {
    setFormData((prev) => ({
      ...prev,
      brand_category: selectedOptions || [], // Ensure it's an array, even if null/undefined
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!showOtpFields) {
      if (formData.brand_category.length === 0) {
        setValidate(true);
        return;
      }

      const submissionData = {
        ...formData,
        email: formData.email.toLowerCase()
      };

      handleSignUp(submissionData);
      // handleSignUp(formData);
    } else {
      verifyOtpLogin(otp);
    }
  };

  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center mt-5"
      style={{ minHeight: "70vh" }}
    >
      <Row style={{ maxWidth: 1000 }}>
        <Col
          lg={6}
          className="mb-3 mb-lg-0 d-flex flex-column justify-content-between align-items-center"
        >
          <Card
            style={{
              boxShadow: "none",
              borderRadius: 0,
              border: "none",
              borderLeft: "1px solid #BDC1C6",
            }}
          >
            <h4 className="text-gray-900 inter-600">Brand Registration</h4>
            <div className="text-gray-500">
              Showcase your product range through 10k+ inspirations made by top
              designers!
            </div>
          </Card>
          <Image src={brandsignup} className="mt-4" alt="Brand signup" width="80%" />

        </Col>
        <Col lg={6}>
          <Form
            ref={form}
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 body-text2 inter-600">
                Company Name<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control
                required
                className="py-2 body-text2"
                type="text"
                name="full_name"
                minLength={3}
                placeholder="Enter your Company Name"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                onChange={handleChange}
                disabled={showOtpFields}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 body-text2 inter-600">
                Phone Number<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text
                  className="body-text2 inter-600"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #BDC1C6",
                    borderRadius: "3px 0px 0px 3px",
                  }}
                >
                  +91
                </InputGroup.Text>
                <Form.Control
                  className="border-start-0 p-2 body-text2"
                  type="text"
                  name="mobile"
                  required
                  placeholder="9090909090"
                  maxLength={10}
                  pattern="[0-9]{10}" // Ensures exactly 6 digits
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, ""); // Removes non-numeric characters
                  }}
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: "0px 3px 3px 0px",
                  }}
                  disabled={showOtpFields}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 body-text2 inter-600">
                Company Email<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #BDC1C6",
                    borderRadius: "3px 0px 0px 3px",
                  }}
                >
                  <LuMail size={20} color="#1A1C1F" />
                </InputGroup.Text>
                <Form.Control
                  className="border-start-0 p-2 body-text2"
                  type="email"
                  name="email"
                  required
                  placeholder="example@gmail.com"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: "0px 3px 3px 0px",
                  }}
                  onFocus={() => setEmailError("")}
                  // onChange={(e) => { setUsername(e.target.value); handleChange(e); }}
                  onChange={(e) => {
                    // Ensure email is lowercase when setting username
                    const lowerCaseEmail = e.target.name === 'email' ? e.target.value.toLowerCase() : e.target.value;
                    setUsername(lowerCaseEmail);
                    handleChange(e);
                  }}
                // disabled={showOtpFields}
                />
              </InputGroup>
              <div className="d-flex flex-column">
                {emailError && (
                  <span className="body-text3 text-danger inter-400">
                    {emailError}
                  </span>
                )}
                <span className="body-text3 text-gray-700 inter-400">
                  Note: Your Email ID cannot be changed later. Choose one
                  accordingly.
                </span>
              </div>
            </Form.Group>
            <Form.Group>
              <MultiSelect
                name="brand_category"
                value={formData.brand_category}
                onChange={handleCategoryChange} // Use the new handler
                categories={categories}
                validate={validate}
                required={true}
              />
            </Form.Group>

            {showOtpFields && (
              <>
                <Form.Group className="my-3">
                  <Form.Label className="mb-1 body-text2 inter-600">
                    OTP<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Row>
                    {[0, 1, 2, 3].map((index) => (
                      <Col key={index} xs="3">
                        <Form.Control
                          required
                          id={`otp-${index}`}
                          maxLength="1"
                          pattern="[0-9]{1}" // Ensures exactly 6 digits
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, ""); // Removes non-numeric characters
                          }}
                          value={otp[index] || ""}
                          onChange={(e) => handleOtpChange(e, index)}
                          style={{
                            textAlign: "center",
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                      </Col>
                    ))}
                  </Row>
                  <Form.Text className="text-success">
                    OTP has been sent to the email address
                  </Form.Text>
                </Form.Group>
                <div className="text-center mb-3">
                  {isResendDisabled ? (
                    <span className="text-muted">
                      Resend OTP in {resendTimer} seconds
                    </span>
                  ) : (
                    <span
                      className="text-primary cursor-pointer"
                      onClick={(e) => handleSignUp(e)}
                      style={{ textDecoration: "underline" }}
                    >
                      Resend OTP
                    </span>
                  )}
                </div>
              </>
            )}

            <Form.Group className="mb-1">
              <Form.Check
                type="checkbox"
                label={
                  <div className="body-text2 inter-400">
                    Agree to{" "}
                    <span
                      className="text-primary-500 cursor-pointer"
                      onClick={() => window.open("/terms", "_blank")}
                    >
                      Terms and conditions
                    </span>{" "}
                    &{" "}
                    <span
                      className="text-primary-500 cursor-pointer"
                      onClick={() => window.open("/privacy", "_blank")}
                    >
                      Privacy Policy
                    </span>
                  </div>
                }
                required
              />
            </Form.Group>
            <div className="d-flex mt-5 w-100">
              <Button
                className="px-3 py-2 me-3 text-gray-900 bg-white border-gray-200"
                style={{ borderRadius: 3 }}
                onClick={() => setStep(step - 1)}
                disabled={loading || showOtpFields}
              >
                Back
              </Button>
              <Button
                className="w-100 p-2"
                type="submit"
                style={{ borderRadius: 3 }}
                disabled={loading}
              >
                {loading && (
                  <Spinner animation="border" size="sm" className="me-2" />
                )}
                {showOtpFields ? "Verify OTP" : "Register"}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default BrandSignup;
