import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdOutlineArrowForward,
  MdArrowForward,
  MdArrowForwardIos,
} from "react-icons/md";
import Slider from "react-slick";

import Banners from "./Banners";
import ServiceCard from "./ServiceCard";
import ProjectCard from "components/Cards/ProjectCard";
import TestimonialCard from "components/Cards/TestimonialCard";
import VerticalSlider from "./VerticalSlider";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

import { ArchitectsForDisplay, HowItWorks } from "components";
import { Breakpoint } from "react-socks";
import {
  archilabs,
  ayushi_j,
  brand_mobile_card_img,
  brand_mobile_model,
  brand_partner_1,
  brand_partner_2,
  brand_partner_3,
  brand_partner_4,
  brand_why_choose_us,
  builder_mobile_card_img,
  builder_mobile_model,
  career_choice,
  client_leads,
  dharmesh_shah,
  for_professionals_1,
  for_professionals_2,
  joi_property,
  joi_property2,
  somani_house1,
  managalam_tarang,
  meera_hoya,
  model_mobile_bg,
  partner_img_1,
  partner_img_2,
  partner_img_3,
  partner_img_4,
  partner_img_5,
  partner_img_bg,
  partner_img_brand,
  partner_profile1,
  piyush_rai,
  priya_joshi,
  priyanka_rai,
  product_dev,
  recreating_seating,
  referral_code,
  relationship_logo,
  show_your_work1,
  show_your_work2,
  show_your_work3,
  shree_residence,
  somani_house,
  suncity_avenue,
  testimonial_pic,
  wait_is_over,
  walk_through,
  web_visibility,
} from "assets";
import CustomAccordion from "components/CustomAccordion/CustomAccordion";
import LoginModal from "components/Auth/LoginModal";
import CustomSliderForBuilder from "components/Partner/partner-builder-mobile-slider";
import StepsCarousel from "components/Partner/builder-steps-slider";
import CustomSliderForBrand from "components/Partner/brand_mobile_slider";
import StepsCarouselBrand from "components/Partner/brand-steps-slider";

const ProfessionalBrand = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const navigate = useNavigate();

  const [brands, setBrands] = useState(null);
  const [recommendedProjects, setRecommendedProjects] = useState([]);
  const [recommendedArchitects, setRecommendedArchitects] = useState([]);
  const [clientTestimonials, setClientTestimonials] = useState(null);
  const [architectTestimonials, setArchitectTestimonials] = useState(null);
  const [builderTestimonials, setBuilderTestimonials] = useState(null);
  const [brandTestimonials, setBrandTestimonials] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeItemId, setActiveItemId] = useState("step_1");
  const [hovered, setHovered] = useState(null);
  // const [showLoginModal, setShowLoginModal] = useState(false);
  // const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [activeKey, setActiveKey] = useState("0");
  const toggleAccordion = (id) => {
    setActiveKey(activeKey === id ? null : id);
  };

  const portfolioSteps = [
    {
      id: 1,
      title: "Create your professional account",
      description:
        "Showcase all your work, details and awards with a single click. Let clients find you!",
    },
    {
      id: 2,
      title: "Badges to promote your presence",
      description:
        "To let your efforts be known, we assign you badges when you reach each milestone on the Interiokeys platform.",
    },
    {
      id: 3,
      title: "Badges to promote your presence",
      description:
        "To let your efforts be known, we assign you badges when you reach each milestone on the Interiokeys platform.",
    },
  ];
  const stats = [
    {
      value: "403%",
      text: `More inquiries are generated through home images and videos compared to a bare shell.`,
    },
    {
      value: "94%",
      text: `Buyers check online presence before making property buying decisions in 2024.`,
    },
    {
      value: "68.2 Cr",
      text: `Searches happened in “Home Decor”, “interior” on Pinterest in 2024.`,
    },


    {
      value: "74%",
      text: `Of recent buyers get home interior inspirations through the internet.`,
    },
  ];

  const faqData = [
    {
      id: "0",
      question: "What is InterioKeys, and how does it benefit brands?",
      answer:
        "InterioKeys is a comprehensive platform connecting brands with builders, architects, designers, and customers. It helps brands showcase their products in real-world designs and projects, increasing visibility and driving sales through a targeted audience.",
    },
    {
      id: "1",
      question: "How can brands showcase their products on InterioKeys?",
      answer:
        "Brands can upload their product catalogs and collaborate with architects and designers to feature their products in executable designs. These designs directly influence builders and buyers, ensuring practical and aesthetic integration.",
    },
    {
      id: "2",
      question: "What is an executable design, and how do brands fit into it?",
      answer:
        "Executable designs are ready-to-implement interior solutions that include layouts, BOQs, and materials sourced from products listed on InterioKeys. This ensures that your brand’s products are part of practical and fully executable projects.",
    },
    {
      id: "3",
      question: "Can brands collaborate with designers through InterioKeys?",
      answer:
        "Yes, brands can work with architects and interior designers to feature their products in designs, ensuring greater visibility and relevance. This collaboration helps brands align their offerings with current market trends and customer preferences.",
    },
    {
      id: "4",
      question: "How does InterioKeys ensure visibility for brands?",
      answer:
        "InterioKeys promotes brands through: Featured product listings, collaboration in blogs, podcasts, and digital magazines, showcase opportunities in sample flats, project shoots, and testimonials, and targeted marketing campaigns to builders, designers, and customers.",
    },
    {
      id: "5",
      question: "How can brands track their performance on InterioKeys?",
      answer:
        "InterioKeys provides analytics and insights, including views, clicks, customer engagement, and sales conversions. This data helps brands optimize their listings and marketing strategies.",
    },
    {
      id: "6",
      question: "How does InterioKeys help brands reach a nationwide audience?",
      answer:
        "InterioKeys connects brands with projects across India, ensuring visibility in multiple cities. This allows brands to expand their reach and influence builders, designers, and customers nationwide.",
    },
    {
      id: "7",
      question: "Can brands recommend designers or architects to work with?",
      answer:
        "Yes, brands can recommend specific designers or architects they’d like to collaborate with, fostering partnerships that benefit both parties.",
    },
    {
      id: "8",
      question:
        "What kind of marketing support does InterioKeys offer to brands?",
      answer:
        "InterioKeys provides comprehensive marketing support through PR campaigns, social media promotions, podcasts, blogs, magazine features, and testimonials and reviews from satisfied customers and professionals.",
    },
    {
      id: "9",
      question:
        "How does InterioKeys help brands reduce costs and improve efficiency?",
      answer:
        "By connecting brands directly with builders and designers, InterioKeys reduces the need for middlemen. Additionally, its data-driven approach ensures brands can target the right audience, maximizing ROI.",
    },
    {
      id: "10",
      question: "How does InterioKeys ensure fair competition among brands?",
      answer:
        "InterioKeys offers equal visibility opportunities for all listed brands. Products are recommended based on relevance to projects and customer preferences, ensuring a level playing field.",
    },
    {
      id: "11",
      question: "Can brands offer exclusive deals or discounts on InterioKeys?",
      answer:
        "Yes, brands can create exclusive offers or discounts for builders, designers, or customers, encouraging higher engagement and sales.",
    },
    {
      id: "12",
      question: "Can brands measure the ROI of their presence on InterioKeys?",
      answer:
        "Yes, with detailed analytics on engagement, conversions, and sales, brands can measure the ROI of their listings and marketing efforts on the platform.",
    },
    {
      id: "13",
      question:
        "What kind of support does InterioKeys offer for onboarding brands?",
      answer:
        "InterioKeys provides dedicated support for uploading product catalogs, setting up profiles, and creating marketing strategies to maximize brand visibility.",
    },
    {
      id: "14",
      question: "How does InterioKeys protect brand data and information?",
      answer:
        "InterioKeys employs industry-standard encryption and secure data storage to protect brand information, ensuring privacy and data security.",
    },
    {
      id: "15",
      question: "How does InterioKeys ensure fair competition among brands?",
      answer:
        "InterioKeys offers equal visibility opportunities for all listed brands. Products are recommended based on relevance to projects and customer preferences, ensuring a level playing field.",
    },
    {
      id: "16",
      question: "Can brands offer exclusive deals or discounts on InterioKeys?",
      answer:
        "Yes, brands can create exclusive offers or discounts for builders, designers, or customers, encouraging higher engagement and sales.",
    },
  ];

  const accordionItems = [
    {
      id: "step_1",
      title: "Showcase Your Product Collections",
      content:
        "Sign up to showcase your product collection with detailed specifications, high-quality images, and pricing to attract designers and customers.",
      step: "Step 1",
    },
    {
      id: "step_2",
      title: "Craft Exceptional Design Inspiration",
      content:
        "Designers will now highlight your product in their inspirations and also you can create inspirations also to showcase on real projects.",
      step: "Step 2",
    },
    {
      id: "step_3",
      title: "Generate direct and indirect sales",
      content:
        "Generate sales directly through projects executed by interiokeys as well as indirect sales through architects highlighting your products.",
      step: "Step 3",
    },
  ];

  const stepImages = [
    {
      id: "step_2",
      images: [
        {
          url: `${joi_property2}`,
          // title: "Jewel of India/Suncity Builders",
          // layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft) ",
          // location: "Location: Vaishali Nagar, Jaipur, India",
        },
      ],
    },
    {
      id: "step_1",
      images: [
        {
          url: `${walk_through}`,
          title: "Let us walk you through the ArchiLabs design process.",
          description: `Showing you how Archilabs transforms your home through ideation, design and execution.`,
          linkText: "Read More",
        },
        {
          url: `${archilabs}`,
          title: "ArchiLabs on designing the new heritage museum",
          description: `Showing you how Archilabs transforms your home through ideation, design and execution.`,
          linkText: "Read More",
        },
        {
          url: `${recreating_seating}`,
          title: "Recreating Seating Experience",
          description: `Showing you how Archilabs transforms your home through products reimagined as a statement piece`,
          linkText: "Enquire",
        },
        {
          url: `${wait_is_over}`,
          title: "Wait, is that a light?",
          description: `Going beyond conventional designs to create beautiful and functional lighting systems for your home`,
          linkText: "Enquire",
        },
      ],
    },
    {
      id: "step_3",
      images: [
        {
          url: `${somani_house1}`,
        },
      ],
    },
  ];

  const profilePics = [
    { img: `${partner_profile1}`, name: "Piyush Rai", role: "Co-Founder" },
    { img: `${priya_joshi}`, name: "Priya Joshi", role: "Co-Founder" },
    { img: `${dharmesh_shah}`, name: "Dharmesh Shah", role: "Founder" },
    { img: `${piyush_rai}`, name: "Piyush Rai", role: "Co-Founder" },
    { img: `${meera_hoya}`, name: "Meera hoya", role: "Founder" },
    { img: `${ayushi_j}`, name: "Ayushi J", role: "Founder" },
    { img: `${priyanka_rai}`, name: "Priyanka Rai", role: "Founder" },
  ];

  const cardData = [
    {
      id: 1,
      icon: `${relationship_logo}`,
      title: "Increase Product Visibility",
      description:
        "Feature your products in real-life project inspirations that resonate with homeowners and professionals alike.",
    },
    {
      id: 2,
      icon: `${web_visibility}`,
      title: "Build Authentic Connections",
      description:
        "Engage with designers who can organically integrate your products into their projects.",
    },
    {
      id: 3,
      icon: `${product_dev}`,
      title: "Measure Impact",
      description:
        "Get real-time insights on product views, inquiries, and usage in inspirations to refine your offerings.",
    },
    {
      id: 4,
      icon: `${client_leads}`,
      title: "Reduce Marketing Efforts",
      description:
        "InterioKeys brings the audience to you, cutting down the time and expense of traditional outreach strategies.",
    },
    {
      id: 5,
      icon: `${referral_code}`,
      title: "Targeted Engagement",
      description:
        "Connect with architects, designers, and homeowners who are actively searching for products in your category.",
    },
    {
      id: 6,
      icon: `${career_choice}`,
      title: "Showcase Collections",
      description:
        "Display your products in a creative and relevant context by being tagged in design inspirations by top designers.",
    },
  ];

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const fetchBrands = async () => {
    try {
      const response = await authActions.fetchRecommendedBrands();
      if (response.data) {
        setBrands(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecommendedProjects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setRecommendedProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchRecommendedArchitects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedArchitects();
      if (response.data) {
        setRecommendedArchitects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchTestimonialsWithType = async () => {
    try {
      const response = await authActions.fetchTestimonialsWithType();
      if (response.data) {
        setClientTestimonials(response.data?.client_testimonials);
        setArchitectTestimonials(response.data?.architect_testimonials);
        setBuilderTestimonials(response.data?.builder_testimonials);
        setBrandTestimonials(response.data?.brand_testimonials);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBrands();
    fetchRecommendedProjects();
    fetchRecommendedArchitects();
    fetchTestimonialsWithType();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-next-arrow"}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={35} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-prev-arrow"}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={35} />
      </div>
    );
  }

  const sliderSettings = {
    customPaging: function (i) {
      return <div className="custom-dot mt-3" />;
    },
    dotsClass: "slick-dots",
    dots: small ? false : true,
    arrows: false,
    infinite: true,
    autoplay: large ? false : true,
    speed: 500,
    cssEase: "linear",
    slidesToShow: large ? 3 : small ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleActiveItemId = (id) => {
    setActiveItemId(id);
  };

  const renderCardsForActiveStep = () => {
    const step = stepImages.find((item) => item.id === activeItemId); // Find step by activeItemId

    return step.images.map((image, index) => (
      <div className="w-100"
        key={index}
        style={{
          // height: "478px",
          paddingTop: "25.74px",
          paddingBottom: "25.74px",
          gap: "21.3px",
          borderRadius: "6.21px",
          textAlign: "left",
          margin: "16px",
        }}
      >
        <img
          src={image.url}
          alt={image.title}
          style={{
            width: "100%", // Ensures the image takes the full width of the div
            height: "100%", // Ensures the image fills the div
            objectFit: "cover",
            borderRadius: "6.21px",
          }}
        />
      </div>
    ));
  };

  const renderCardsForStep2 = () => {
    const step = stepImages.find((item) => item.id === activeItemId); // Find step by activeItemId

    return step.images.map((image, index) => (
      <div
        key={index} className=" py-3"
        style={{ maxWidth: "25%", textAlign: "left",}}
      >
        <div className="mx-3 border rounded">
        <img
          src={image.url}
          alt={image.title}
          style={{
            width: "100%",
            height: "320px",
            objectFit: "cover",
            borderRadius: "6px",
          }}
        />
        <div className="px-2 py-4">
          <div
            className="body-title inter-600 text-gray-800 wrap"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
          >
            {image.title}
          </div>
          <div
            className="body-text2 inter-400 text-gray-800"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            {image.description}
          </div>
          <p
            className="body-text2 inter-500 mb-0 pt-2"
            style={{ color: "#c53d3d", cursor: "pointer" }}
          >
            {image.linkText}
          </p>
        </div>
        </div>
      </div>
    ));
  };

  const renderCardsForStep3 = () => {
    const step = stepImages.find((item) => item.id === activeItemId); // Find step by activeItemId

    return step.images.map((image, index) => (
      <div
        key={index}
        style={{
          width: "100%",
          height: "478px",
          paddingTop: "25.74px",
          paddingBottom: "25.74px",
          gap: "21.3px",
          borderRadius: "6.21px",
          textAlign: "left",
          margin: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={image.url}
          alt={image.title}
          style={{
            width: "100%", // Makes the image take full width of the div
            height: "100%", // Makes the image take full height of the div
            objectFit: "cover",
            borderRadius: "6.21px",
          }}
        />
      </div>
    ));
  };

  return (
    <Container fluid className="px-0">
      {small ? (
        <Container
          fluid
          className="d-flex flex-column align-items-center text-center px-0"
          style={{ minHeight: "100vh" }}
        >
          <Container fluid style={{ background: "#F8F9FA" }}>
            <Container className={`pt-5 ${screenHeight <= 800 ? "mt-5" : ""}`}>
              <div
                className="text-center fw-semibold mt-5 mb-0"
                style={{
                  fontFamily: "Inter",
                  fontSize: "30px",
                  lineHeight: "38px",
                  letterSpacing: "-3%",
                  color: "#C53D3D",
                }}
              >
                First of a Kind Platform
              </div>

              <div className="h1 px-lg-5 inter-600 pt-lg-2 pb-lg-5 mt-0 mb-0">
                Showcase your brand with stunning design inspirations,
                reaching lakhs of active homeowners searching for best interior
                products.
              </div>
              {/* <div className="text-gray-600 body-title inter-400 my-3">
            Gain an edge over competitors by collaborating with Interiokeys, where we give you support from start to finish.
          </div> */}
            </Container>
          </Container>
          {/* <div className="d-flex justify-content-center bg-brand-500-half w-100 mx-0">
          <div className="mx-2">
            <Image src={for_professionals_1} alt="For Professioanls image 1" className="w-100" />
          </div>
          <div className="mx-2">
            <Image src={for_professionals_2} alt="For Professioanls image 1" className="w-100" />
          </div>
        </div> */}
          <Container fluid className="py-5 px-0">
            <Container>
              <div className="h2 inter-600">Partner with Us!</div>
            </Container>
            <Container className="pt-5 px-0" style={{ width: "1350px" }}>
              <Row>
                {/* Left Column (3 units) */}
                <Col xs={4} className="d-flex flex-column align-items-center">
                  <Row className="mb-2 justify-content-center w-100">
                    <Col>
                      <div className="text-start partner-card-border architectimg">
                        <img
                          src={brand_partner_1}
                          alt="Shree Residence/5BHK/Jewel of India"
                          className="img-fluid rounded-top"
                          
                        />
                        <p className="ps-2 py-2 body-text1 inter-600 text-gray-800 m-0">
                          Bedspreads/D-decor furnishings
                        </p>
                      </div>
                    </Col>
                  </Row> 
                  <Row className="justify-content-center w-100">
                    <Col>
                      <div className="text-start partner-card-border architectimg">
                        <img
                          src={brand_partner_3}
                          alt="Bathtubs/hansgrohe sanitaryware"
                          className="img-fluid rounded-top"
                         
                        />
                        <p className="ps-3 py-2 body-text1 inter-600 text-gray-800 m-0">
                          Bathtubs/hansgrohe sanitaryware
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>

                {/* Middle Column (6 units) */}
                <Col
                  xs={4}
                  className="d-flex flex-column justify-content-end align-items-center"
                >
                  <div
                    className="architectmainimg"
                    style={{
                      backgroundImage: `url(${partner_img_bg})`,
                      backgroundSize: "contain",
                      backgroundPosition: "bottom",
                      backgroundRepeat: "no-repeat",
                    }} 
                    >
                    <img
                      src={partner_img_brand}
                      alt="Partner Image"
                      className="img-fluid"
                     
                    />
                  </div>
                </Col>

                {/* Right Column (3 units) */}
                <Col xs={4} className="d-flex flex-column align-items-center">
                  <Row className="mb-2 w-100">
                    <Col>
                      <div className="text-start border rounded architectimg">
                        <img
                          src={brand_partner_2}
                          alt="Outdoor Collection/Kajaria Tiles"
                          className="img-fluid rounded-top"
                         
                        />
                        <p className="ps-2 py-2 body-text1 inter-600 text-gray-800 m-0">
                          Outdoor Collection/Kajaria Tiles
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="justify-content-center w-100">
                    <Col>
                      <div className="text-start partner-card-border architectimg">
                        <img
                          src={brand_partner_4}
                          alt="Luxury Lights/Hybec Lighting"
                          className="img-fluid rounded-top"
                         
                        />
                        <p className="ps-2 py-2 body-text1 inter-600 text-gray-800 m-0">
                          Luxury Lights/Hybec Lighting
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Container>
          <Button
            className="px-3 text-start btn-primary mt-3 mb-5 d-flex justify-content-between align-items-center"
            onClick={() => {
              navigate("/signup#professional|brand");
            }}
          >
            Become an InteriorKeys Brand Partner <MdArrowForwardIos />{" "}
          </Button>
          <Container fluid className="border-t border-gray-300 py-5 px-0" style={{}}>
            <div className="serchessection px-5">
              {stats.map((stat, index) => (
                <div className="serchesbox" key={index}>
                  <span
                    style={{
                      color: "#C53D3D",
                      fontFamily: "Inter",
                      fontWeight: 600,
                      lineHeight: "1",
                      letterSpacing: "0%",
                    }}
                  >
                    {stat.value}
                  </span>
                  <p
                    style={{
                      color: "#27201B",
                      fontFamily: "Inter",
                      fontWeight: 300,
                      lineHeight: "1.4",
                      letterSpacing: "0%",
                      marginTop: "12px",
                    }}
                  >
                    {stat.text}
                  </p>
                </div>
              ))}
            </div>
          </Container>

          <Container fluid className="p-5 bg-gray-25">
            <Row>
              {/* Left Section */}
              <Col xl={4} className=" text-start">
                <div className="mb-4 h2 inter-600">How it Works</div>
                <CustomAccordion
                  accordionItems={accordionItems}
                  onToggle={handleActiveItemId}
                />
                <Button
                  className="px-3 text-start btn-primary mt-3 d-flex justify-content-between align-items-center"
                  onClick={() => {
                    navigate("/signup#professional|brand");
                  }}
                >
                  Become an Interiorkeys Brand Partner <MdArrowForwardIos />{" "}
                </Button>
              </Col>

              <Col
                xl={8}
                className="d-flex align-items-center justify-content-around mt-3 mt-xl-0 "
              >
                <div className="rounded w-100"  style={{ backgroundColor: "#FFFFFF" }} >
                {activeItemId === "step_1" && ( 
                  <div className="d-flex align-items-center justify-content-between" >
                    {renderCardsForStep2()}
                  </div>
                )}

                {activeItemId === "step_2" && (
                  <div className="d-flex align-items-center justify-content-between w-100">
                    {renderCardsForActiveStep()}
                  </div>
                )}

                {activeItemId === "step_3" && (
                  <div className="d-flex align-items-center justify-content-between">
                    {renderCardsForStep3()}
                  </div>
                )}
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid className="p-5 my-5">
            <div className="h2 inter-600 text-gray-900 text-start mb-5">
              InterioKeys Helps You!
            </div>
            <Row>
              {cardData.map((card) => (
                <Col key={card.id} xs={12} md={6} lg={4} className="mb-4">
                  <div
                    className="p-3 text-start"
                    style={{ border: "1px solid #E8EAED", borderRadius: "6px" }}
                  >
                    <img
                      src={card.icon}
                      alt={card.title}
                      style={{ width: "48px" }}
                    />
                    <h5
                      className="body-title inter-600 mt-3"
                      style={{
                        color: hovered === card.id ? "#C53D3D" : "#1A1C1F",
                      }}
                      onMouseEnter={() => setHovered(card.id)}
                      onMouseLeave={() => setHovered(null)}
                    >
                      {card.title}
                    </h5>
                    <p className="body-text1 inter-400 text-gray-600">
                      {card.description}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
          <Container fluid className="p-5 my-5">
            <div className="h2 inter-600 text-gray-900 text-start mb-5">
              Why Should You Choose Us?
            </div>
            <Row
              className=""
              style={{ border: "1px solid #D1D1D1", borderRadius: "6px" }}
            >
              {/* Image Section */}
              <Col xs={12} md={3} className="mb-4 mb-md-0 px-0">
                <img
                  src={brand_why_choose_us}
                  alt="Mandeep and Nisha"
                  className="img-fluid"
                  style={{
                    width: "100%",
                    maxHeight: "280px",
                    objectFit: "cover",
                  }}
                />
              </Col>
              {/* Text Section */}
              <Col
                xs={12}
                md={9}
                className="text-start d-flex flex-column justify-content-between"
              >
                <div className="mt-4 ms-2">
                  <div className="h3 inter-600 text-gray-900">
                    Where brands meet brilliance and inspire designs that shine.
                  </div>
                  <div className="body-title inter-400 text-gray-600">
                    "As a lighting solutions brand, InterioKeys has provided us
                    with an incredible opportunity to showcase our collections
                    to the right audience. Architects and designers tagging our
                    products in their inspirations have driven more inquiries
                    and collaborations than we imagined. It's the perfect blend
                    of marketing and networking for brands like ours."
                  </div>
                </div>
                <div className="mb-4 ms-2">
                  <div
                    className="inter-600 text-gray-900"
                    style={{ fontSize: "20px" }}
                  >
                    Anita Verma
                  </div>
                  <div
                    className="inter-400 text-gray-600"
                    style={{ fontSize: "16px" }}
                  >
                    InterioKeys Brand Partner
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="h2 inter-600 text-gray-900 text-start mb-2">
            Frequently Asked Questions
          </div>

          {/* {/* <Container fluid className="p-5 my-5"> */}
          <div
            className="container mt-4 mb-5 qandasection"
            style={{
              width: "90%",
              maxWidth: "1200px",
              minHeight: "auto",
              padding: "3% 5%",
              borderRadius: "12px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#EBEBEB",
            }}
          >
            <Accordion activeKey={activeKey}>
              {faqData.map((faq) => (
                <Card
                  key={faq.id}
                  className="mb-2"
                  style={{
                    backgroundColor:
                      activeKey === faq.id ? "#EBEBEB" : "transparent",
                  }}
                >
                  <span className="qnumber"> Q{parseInt(faq.id) + 1}.</span>

                  <Card.Header
                    className="pt-0"
                    onClick={() => toggleAccordion(faq.id)}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      textAlign: "left",
                      backgroundColor:
                        activeKey === faq.id ? "#EBEBEB" : "transparent",
                      cursor: "pointer",
                      padding: "10px 15px",
                    }}
                  >
                    <div style={{ fontWeight: "bold", flex: 1 }}>
                      {faq.question}
                    </div>
                    <Button
                      variant="light"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleAccordion(faq.id);
                      }}
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                    >
                      {activeKey === faq.id ? "−" : "+"}
                    </Button>
                  </Card.Header>
                  <Accordion.Collapse eventKey={faq.id}>
                    <Card.Body
                      className="text-muted pt-0"
                      style={{
                        backgroundColor:
                          activeKey === faq.id ? "#EBEBEB" : "transparent",
                      }}
                    >
                      {faq.answer.split("\n").map((line, index) =>
                        line.startsWith("- ") ? (
                          <ul key={index} style={{ marginLeft: "20px" }}>
                            <li>{line.substring(2)}</li>
                          </ul>
                        ) : (
                          <p key={index} style={{ marginBottom: "8px" }}>
                            {line}
                          </p>
                        )
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </div>
        </Container>
      ) : (
        <Container
          fluid
          className="d-flex flex-column align-items-center text-center"
          style={{ minHeight: "100vh", padding: "45px 0px 0px", width: "100%" }}
        >
          <div
            style={{ marginTop: "65px" }}
            className="d-flex flex-column align-items-center mb-3"
          >
<div
  className="text-center fw-semibold mt-5 mb-4"
  style={{
    fontFamily: "Inter",
    fontSize: "30px", // Default size for larger screens
    lineHeight: "38px",
    letterSpacing: "-3%",
    color: "#C53D3D",
  }}
>
  First of a Kind Platform
</div>

<div
  className="h2 text-gray-900 inter-600 text-center px-3 px-md-0 mb-3"
  style={{
    fontSize: "24px", // Default size for larger screens
    lineHeight: "32px",
  }}
>
  Showcase your brand with stunning design inspirations, reaching lakhs of active homeowners searching for best interior products.
</div>
            {/* <Button
              className="brand-primary-500 text-white body-text-1 mt-3"
              style={{
                borderRadius: "3px",
                width: "40%",
                height: "32px",
                padding: "6px 12px",
              }}
              onClick={() => {
                navigate("/signup#professional|brand");
              }}
            >
              Partner with Us
            </Button> */}
          </div>

          <div
            className="w-full h-full min-h-screen"
            style={{
              backgroundImage: `url(${model_mobile_bg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
                   {/* Text content */}
                   <div className="text-center mt-4">
                <p className="body-title inter-600 text-gray-900 mb-2">
                  Partner with Us!
                </p>
              </div>
            {/* Main content container */}
            <div className="flex flex-col items-center">
              {/* Model image */}
              <div className="w-full">
                <img
                  src={brand_mobile_model}
                  alt="Partner representative"
                  className="w-full h-auto object-contain"
                />
              </div>

       
            </div>
          </div>
          <Container className="mb-4">
            <CustomSliderForBrand />
            <Card
              style={{
                borderRadius: "6px",
                border: "1px solid #E8EAED",
                padding: "0px",
              }}
            >
              <Card.Img
                variant="top"
                src={brand_mobile_card_img}
                className="rounded-top"
                style={{
                  height: "200px",
                  objectFit: "cover",
                }}
              />
              <Card.Body className="p-2">
                <Card.Title className="d-flex align-items-center mb-0">
                  <span
                    className="inter-500 text-gray-800"
                    style={{ fontSize: "14px" }}
                  >
                    Faux Wood XR21/Centuryply
                  </span>
                </Card.Title>
              </Card.Body>
            </Card>
          </Container>
          <Button
            className="px-3 mb-4 text-start btn-primary mt-3 d-flex justify-content-between align-items-center"
            onClick={() => {
              navigate("/signup#professional|brand");
            }}
          >
            Become an Interiorkeys Brand Partner <MdArrowForwardIos />{" "}
          </Button>

          <Container className="mb-4">
            <p className="body-title inter-500 text-gray-800 text-start">
              How it Work
            </p>
            <StepsCarouselBrand />
            <Button
              className="brand-primary-500 text-white body-text-1 mt-3 w-100"
              style={{
                borderRadius: "3px",
                height: "32px",
                padding: "6px 12px",
              }}
              onClick={() => {
                navigate("/signup#professional|brand");
              }}
            >
              Become an Interiorkeys Brand Partner <MdArrowForwardIos />{" "}
            </Button>
          </Container>
          <Container className="mb-4">
            <p className="body-title inter-500 text-gray-800 text-start">
              InterioKeys Helps You!
            </p>
            {cardData.map((item) => (
              <div
                className="body-text1 inter-500 text-gray-800 "
                style={{
                  padding: "6px",
                  borderRadius: "3px",
                  border: "1px solid #e8eaed",
                  marginBottom: "6px",
                }}
              >
                {item.title}
              </div>
            ))}
          </Container>
          <Container className="mb-4">
            <p className="body-title inter-500 text-gray-800 text-start">
              Why Should You Choose Us?
            </p>
            <Card className="shadow-sm" style={{ borderRadius: "3px" }}>
              <Card.Body className="p-0 text-start">
                {/* Heading */}
                <p className="body-title text-gray-900 inter-600">
                  Showcase your collections
                </p>

                {/* Quote */}
                <blockquote className="body-text1 inter-400 text-gray-600">
                  "InterioKeys helped us showcase our lighting collections to
                  the right audience, driving inquiries and collaborations
                  through designer inspirations. It's the perfect mix of
                  marketing and networking."
                </blockquote>

                <div
                  className="w-100 mb-2"
                  style={{ borderTop: "1px solid #E8EAED" }}
                ></div>
                {/* Footer */}
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    {/* Company Info */}
                    <div>
                      <p className="body-text1 inter-500 text-gray-900 mb-0">
                        Anita Verma
                      </p>
                      <p className="body-text1 inter-400 text-gray-600 mb-0">
                        InterioKeys Brand Partner
                      </p>
                    </div>

                    {/* Logo Section */}
                    <div className="d-flex align-items-center">
                      {/* Vertical Separator */}
                      <div
                        className="vr me-3"
                        style={{ height: "30px", borderColor: "#E8EAED" }}
                      ></div>
                      {/* Logo */}
                      <img
                        src={brand_why_choose_us}
                        alt="Kedia logo"
                        width="80px"
                        height="30px"
                        className="object-fit-cover"
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Container>
          <div className="h2 inter-600 text-gray-900 text-start mt-5 mb-2">
            Frequently Asked Questions
          </div>

          {/* {/* <Container fluid className="p-5 my-5"> */}
          <div
            className="container mt-4 mb-5 qandasection"
            style={{
              width: "90%",
              maxWidth: "1200px",
              minHeight: "auto",
              padding: "3% 5%",
              borderRadius: "12px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#EBEBEB",
            }}
          >
            <Accordion activeKey={activeKey}>
              {faqData.map((faq) => (
                <Card
                  key={faq.id}
                  className="mb-2"
                  style={{
                    backgroundColor:
                      activeKey === faq.id ? "#EBEBEB" : "transparent",
                  }}
                >
                  <span className="qnumber"> Q{parseInt(faq.id) + 1}.</span>

                  <Card.Header
                    className="pt-0"
                    onClick={() => toggleAccordion(faq.id)}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor:
                        activeKey === faq.id ? "#EBEBEB" : "transparent",
                      cursor: "pointer",
                      padding: "10px 15px",
                    }}
                  >
                    <div style={{ fontWeight: "bold", flex: 1 }}>
                      {faq.question}
                    </div>
                    <Button
                      variant="light"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleAccordion(faq.id);
                      }}
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                    >
                      {activeKey === faq.id ? "−" : "+"}
                    </Button>
                  </Card.Header>
                  <Accordion.Collapse eventKey={faq.id}>
                    <Card.Body
                      className="text-muted pt-0"
                      style={{
                        backgroundColor:
                          activeKey === faq.id ? "#EBEBEB" : "transparent",
                      }}
                    >
                      {faq.answer.split("\n").map((line, index) =>
                        line.startsWith("- ") ? (
                          <ul key={index} style={{ marginLeft: "20px" }}>
                            <li>{line.substring(2)}</li>
                          </ul>
                        ) : (
                          <p key={index} style={{ marginBottom: "8px" }}>
                            {line}
                          </p>
                        )
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </div>
        </Container>
      )}
      <Container
        fluid
        className="px-0 bg-brand-500 text-center d-flex justify-content-center align-items-center "
        style={{ minHeight: small ? "400px" : "300px" }}
      >
        <div>
          <div className="h1 inter-600 text-white">
            Become An InterioKeys Brand Partner
          </div>
          <div className="body-title inter-400 text-white mb-3 mb-lg-5 px-3 px-lg-0">
            Get ahead of the curve and join InterioKeys as a partner. Let us
            help you become the top professional of your industry!
          </div>
          <button
            className="text-center mt-3 text-gray-700 inter-500"
            style={{
              width: small ? "auto" : "90%",
              backgraoundColor: "#f1f3f4",
              borderRadius: "3px",
              border: "none",
              padding: "8px 14px",
            }}
            onClick={() => {
              navigate("/signup#professional|brand");
            }}
          >
            {small ? (
              <>
                Become an Interiorkeys Brand Partner <MdArrowForwardIos />{" "}
              </>
            ) : (
              <span className="text-primary-500">Partner with InterioKeys</span>
            )}
          </button>
        </div>
      </Container>
      {/* <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      /> */}
    </Container>
  );
};

export default ProfessionalBrand;
