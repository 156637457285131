import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import {
  FaFile,
  FaFileImage,
  FaFilePdf,
  FaFileZipper,
  FaTrash,
  FaUpload,
  FaXmark,
} from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PreviewPropertyCard from "components/Cards/PreviewPropertyCard";
import { useSelector } from "react-redux";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import { useMediaQuery } from "react-responsive";
import DropFileInputProperty from "components/DragInput/DropFileInputProperty";
import UploadingFiles from "components/DragInput/UploadingFiles";
import { formatBytes } from "utilities/formatBytes";
import { getExtension } from "utilities/getExtension";
import { FiTrash2 } from "react-icons/fi";
import axios from "axios";

function AddProperty() {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const user = useSelector((state) => state.auth.user);

  const [currentStep, setCurrentStep] = useState(1);
  const propertyInfoRef = useRef();
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [projectTypes, setProjectTypes] = useState([]);
  const [layouts, setLayouts] = useState([{ id: 1, layout: "", area: "", totalFlats: "" }]);
  const [pincodeDetails, setPincodeDetails] = useState({});
  const [possessionMonth, setPossessionMonth] = useState("");
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [parentId, setParentId] = useState(null);

  const [selectedPropertyImage, setSelectedPropertyImage] = useState(null);
  const [completeProjectFiles, setCompleteProjectFiles] = useState([]);
  const [brochureFiles, setBrochureFiles] = useState([]);
  const [workingDrawingFiles, setWorkingDrawingFiles] = useState([]);
  const [structureDrawingFiles, setStructureDrawingFiles] = useState([]);
  const [mepDrawingFiles, setMepDrawingFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [layoutErrors, setLayoutErrors] = useState({});

  const brochureInputRef = useRef(null);
  const workingDrawingInputRef = useRef(null);
  const structureDrawingInputRef = useRef(null);
  const mepDrawingInputRef = useRef(null);


  const constructionStatuses = [
    { id: 1, label: "Pre-Construction Phase", value: "PRE_CONSTRUCTION" },
    { id: 2, label: "Under Construction", value: "UNDER_CONSTRUCTION" },
    { id: 3, label: "Ready for Occupancy", value: "READY_FOR_OCCUPANCY" },
  ];

  const propertySegments = [
    { id: 1, label: "Affordable (₹2,500 to ₹4,000)", value: "AFFORDABLE_RESIDENTIAL" },
    { id: 3, label: "Mid-Range (₹4,000 to ₹7,000)", value: "MID_RANGE_RESIDENTIAL" },
    { id: 5, label: "Luxury (₹7,000 to ₹12,000)", value: "LUXURY_RESIDENTIAL" },
    { id: 7, label: "Ultra-Luxury (₹12,000+)", value: "ULTRA_LUXURY_RESIDENTIAL" },
  ];

  const defaultLayouts = [
    { id: 1, layout: "2BHK" },
    { id: 2, layout: "2.5BHK" },
    { id: 3, layout: "3BHK" },
    { id: 4, layout: "3.5BHK" },
    { id: 5, layout: "4BHK" },
    { id: 7, layout: "5BHK" },
    { id: 9, layout: "6BHK" },
    { id: 11, layout: "7BHK" },
    { id: 13, layout: "8BHK" },
  ];

  const [availableLayouts, setAvailableLayouts] = useState(defaultLayouts);

  const handlePropertyImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!allowedTypes.includes(file.type)) {
      setErrorMessage("Only JPG, JPEG, and PNG files are allowed.");
      setSelectedPropertyImage(null);
      return;
    }

    if (file.size > maxSize) {
      setErrorMessage("Image size must be less than 5MB.");
      setSelectedPropertyImage(null);
      return;
    }

    setErrorMessage("");
    setSelectedPropertyImage(file);
    setFieldErrors((prev) => ({ ...prev, property_img: "" })); // Clear error when valid image is selected
  };

  const addLayout = () => {
    setLayouts([...layouts, { id: Date.now(), layout: "", area: "", totalFlats: "" }]);
  };

  const deleteLayout = (index) => {
    const updatedLayouts = [...layouts.slice(0, index), ...layouts.slice(index + 1)];
    setLayouts(updatedLayouts);
    validateTotalFlats(updatedLayouts); // Re-validate after deletion
  };

  // Centralized function to validate total flats
  const validateTotalFlats = (updatedLayouts) => {
    const totalLayoutFlats = updatedLayouts.reduce((sum, layout) => {
      return sum + (parseInt(layout.totalFlats) || 0);
    }, 0);
    const propertyFlats = parseInt(propertyDetails?.flats) || 0;

    if (totalLayoutFlats > propertyFlats) {
      setLayoutErrors((prev) => ({
        ...prev,
        totalFlats: `Total flats across layouts (${totalLayoutFlats}) exceeds property total (${propertyFlats})`,
      }));
      return false;
    } else {
      setLayoutErrors((prev) => ({ ...prev, totalFlats: "" }));
      return true;
    }
  };

  // Add a ref to store the timeout for debouncing
  const debounceTimeoutRef = useRef(null);

  const handleInputChange = (layoutId, field, value) => {
    const updatedLayouts = layouts.map((layout) =>
      layout.id === layoutId ? { ...layout, [field]: value } : layout
    );
    setLayouts(updatedLayouts);

    if (field === "totalFlats") {
      // Clear any existing timeout
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      // Set a new timeout to validate total flats
      debounceTimeoutRef.current = setTimeout(() => {
        validateTotalFlats(updatedLayouts);
      }, 500); // 500ms debounce delay
    }
  };

  const fileRemove = (file) => {
    const updatedList = completeProjectFiles.filter((f) => f !== file);
    setCompleteProjectFiles(updatedList);
  };

  const joinLayoutString = () => {
    let formattedProperties = [];
    layouts.forEach((layout) =>
      formattedProperties.push(`${layout.layout} (${layout.area})`)
    );
    console.log(formattedProperties);
    return formattedProperties.join(", ");
  };

  const validatePincode = (pincode) => {
    const pincodeRegex = /^\d{6}$/;
    if (pincode && !pincodeRegex.test(pincode)) {
      notify("Pincode must be exactly 6 digits", "error");
      return false;
    }
    return true;
  };

  const validatePhoneNumber = (phone) => {
    if (phone.length !== 10) {
      notify("Phone number must be exactly 10 digits", "error");
      return false;
    }
    return true;
  };

  const getPincode = async (pincode) => {
    pincode = pincode.replace(/\D/g, "").slice(0, 6);

    if (pincode.length < 6) {
      setPincodeDetails({});
      setPropertyDetails((prevDetails) => ({
        ...prevDetails,
        country: "",
        city: "",
        state: "",
      }));
      return;
    }

    try {
      const response = await authActions.fetchPincode(pincode);
      if (!response || !response.Pincode) {
        notify("Invalid pincode. Please enter a valid 6-digit pincode.", "error");
        setPincodeDetails({});
        setPropertyDetails((prevDetails) => ({
          ...prevDetails,
          country: "",
          city: "",
          state: "",
        }));
        return;
      }

      setPincodeDetails(response);
      setPropertyDetails((prevDetails) => ({
        ...prevDetails,
        pincode: response?.Pincode || "",
        country: response?.Country || "",
        city: response?.District || "",
        state: response?.State || "",
      }));
    } catch (error) {
      console.log(error);
      notify("Error fetching location data. Please try a different pincode.", "error");
      setPincodeDetails({});
      setPropertyDetails((prevDetails) => ({
        ...prevDetails,
        country: "",
        city: "",
        state: "",
      }));
    }
  };

  const validateRequiredFiles = () => {
    let isValid = true;
    let errorMessage = "";

    if (brochureFiles.length === 0) {
      isValid = false;
      errorMessage = "Brochure files are required";
    } else if (workingDrawingFiles.length === 0) {
      isValid = false;
      errorMessage = "Working Drawing files are required";
    } else if (structureDrawingFiles.length === 0) {
      isValid = false;
      errorMessage = "Structure Drawing files are required";
    } else if (mepDrawingFiles.length === 0) {
      isValid = false;
      errorMessage = "MEP Drawing files are required";
    }

    if (!isValid) {
      notify(errorMessage, "error");
    }

    return isValid;
  };

  const handlePropertySubmit = async () => {
    if (!validatePossessionYear(propertyDetails?.possession_year)) {
      notify("Year must be exactly 4 digits!", "error");
      return;
    }

    setLoading(true);
    const propertyData = {
      property: propertyDetails,
      layouts: layouts,
    };
    const formData = new FormData();
    formData.append("user", user.id);
    formData.append("data", JSON.stringify(propertyData));
    formData.append("property_img", selectedPropertyImage);

    try {
      const response = await authActions.createProperty(formData);
      if (response.data) {
        setIsFileUploading(true);
        setParentId(response.data.id);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handlePossessionYearChange = (e) => {
    const year = e.target.value;
    if (!/^\d{0,4}$/.test(year)) return;

    setPropertyDetails((prev) => ({
      ...prev,
      possession_year: year,
    }));

    if (year.length === 4) {
      setFieldErrors((prev) => ({ ...prev, possession_year: "" }));
    }
  };

  const validatePossessionYear = (year) => {
    if (year.length !== 4) {
      notify("Year must be exactly 4 digits!", "error");
      return false;
    }
    return true;
  };

  const handlePhoneNumberChange = (e) => {
    let phone = e.target.value;
    if (!/^\d*$/.test(phone)) return;
    if (phone.length > 10) return;

    setPropertyDetails((prev) => ({
      ...prev,
      phone_number: phone,
    }));

    if (phone.length === 10) {
      setFieldErrors((prev) => ({ ...prev, phone_number: "" }));
    }
  };

  const handlePincodeBlur = (e) => {
    const pincode = e.target.value;
    if (pincode && !validatePincode(pincode)) {
      e.target.value = "";
      setPropertyDetails((prev) => ({ ...prev, pincode: "" }));
    }
  };

  const handlePincodeKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      notify("Pincode must contain only digits", "error");
    }
  };

  const handlePhoneKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      notify("Phone number must contain only digits", "error");
    }
  };

  const fieldLabels = {
    property_name: "Property Name",
    flats: "Number of Flats",
    construction_status: "Construction Status",
    possession_month: "Possession Month",
    possession_year: "Possession Year",
    address: "Address",
    pincode: "Pincode",
    country: "Country",
    city: "City",
    state: "State",
    property_segment: "Property Segment",
    property_img: "Property Image",
    name: "Contact Name",
    phone_number: "Phone Number",
  };

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // New handler for dynamic field updates and error clearing
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setPossessionMonth(value);
    setPropertyDetails((prev) => ({ ...prev, [name]: value }));

    if (value.trim()) {
      setFieldErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handlePropertyDetails = () => {
    setFieldErrors({});

    const formData = new FormData(propertyInfoRef.current);
    let isError = false;
    let errors = {};
    let data = { ...propertyDetails } || {};

    formData.forEach((value, key) => {
      if (!value && key !== "property_img") {
        isError = true;
        errors[key] = `${fieldLabels[key] || key} is required!`;
        notify(`${fieldLabels[key] || key} is required!`);
        return;
      }

      if (key === "flats" && parseInt(value) <= 0) {
        isError = true;
        errors[key] = "Number of Flats must be greater than 0";
        notify(`Number of Flats must be greater than 0`);
        return;
      }

      if (key === "possession_year" && !validatePossessionYear(value)) {
        isError = true;
        errors[key] = "Year must be exactly 4 digits";
        return;
      }

      data[key] = value;
    });

    if (!selectedPropertyImage) {
      isError = true;
      errors["property_img"] = errorMessage || "Property Image is required!";
      if (errorMessage) {
        notify(errorMessage);
      } else {
        notify("Property Image is required!");
      }
    }

    if (data.pincode) {
      if (!validatePincode(data.pincode)) {
        isError = true;
        errors["pincode"] = "Pincode must be exactly 6 digits";
      } else if (!data.city || !data.state || !data.country) {
        isError = true;
        errors["pincode"] = "Invalid pincode or location data couldn't be fetched. Please enter a valid pincode.";
        notify("Invalid pincode or location data couldn't be fetched. Please enter a valid pincode.", "error");
      }
    }

    if (data.phone_number && !validatePhoneNumber(data.phone_number)) {
      isError = true;
      errors["phone_number"] = "Phone number must be exactly 10 digits";
    }

    setFieldErrors(errors);

    if (isError) {
      return;
    }
    console.log(data);
    setPropertyDetails(data);
    setCurrentStep(2);
  };

  const calculateTotalBytes = (files) => {
    let totalBytes = 0;
    for (let i = 0; i < files.length; i++) {
      totalBytes += files[i].size || 0;
    }
    return totalBytes;
  };

  // Function to format bytes to readable format
  const formatFileSize = (bytes) => {
    if (bytes < 1024) return bytes + ' bytes';
    else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(2) + ' KB';
    else return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  };

  // Set the exact limit in bytes
  const MAX_FILE_SIZE_BYTES = 25 * 1024 * 1024; // 25MB in bytes

  const handleBrochureChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (!newFiles.length) return;

    // Calculate using bytes for precision
    const currentBytes = calculateTotalBytes(brochureFiles);
    const newFilesBytes = calculateTotalBytes(newFiles);
    const totalBytes = currentBytes + newFilesBytes;

    // If adding would exceed limit, notify and don't add files
    if (totalBytes > MAX_FILE_SIZE_BYTES) {
      notify(`Cannot add files: total size would be ${formatFileSize(totalBytes)}, which exceeds the 25MB limit`, "error");
      return; // Don't add the files
    }

    // If we get here, it's safe to add the files
    setBrochureFiles([...brochureFiles, ...newFiles]);
  };

  const handleWorkingDrawingChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (!newFiles.length) return;

    const allowedExtensions = ['.dxf', '.dwg', '.zip'];
    const invalidFiles = newFiles.filter(file => {
      const ext = '.' + file.name.split('.').pop().toLowerCase();
      return !allowedExtensions.includes(ext);
    });

    if (invalidFiles.length > 0) {
      notify("Only DXF, DWG, and ZIP files are accepted. Please remove invalid files.", "error");
      return; // Don't add any files if invalid types are detected
    }

    const currentBytes = calculateTotalBytes(workingDrawingFiles);
    const newFilesBytes = calculateTotalBytes(newFiles);
    const totalBytes = currentBytes + newFilesBytes;

    if (totalBytes > MAX_FILE_SIZE_BYTES) {
      notify(`Cannot add files: total size would be ${formatFileSize(totalBytes)}, which exceeds the 25MB limit`, "error");
      return; // Don't add the files
    }

    setWorkingDrawingFiles([...workingDrawingFiles, ...newFiles]);
  };

  const handleStructureDrawingChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (!newFiles.length) return;

    const allowedExtensions = ['.dxf', '.dwg', '.zip'];
    const invalidFiles = newFiles.filter(file => {
      const ext = '.' + file.name.split('.').pop().toLowerCase();
      return !allowedExtensions.includes(ext);
    });

    if (invalidFiles.length > 0) {
      notify("Only DXF, DWG, and ZIP files are accepted. Please remove invalid files.", "error");
      return; // Don't add any files if invalid types are detected
    }

    const currentBytes = calculateTotalBytes(structureDrawingFiles);
    const newFilesBytes = calculateTotalBytes(newFiles);
    const totalBytes = currentBytes + newFilesBytes;

    if (totalBytes > MAX_FILE_SIZE_BYTES) {
      notify(`Cannot add files: total size would be ${formatFileSize(totalBytes)}, which exceeds the 25MB limit`, "error");
      return; // Don't add the files
    }

    setStructureDrawingFiles([...structureDrawingFiles, ...newFiles]);
  };

  const handleMepDrawingChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (!newFiles.length) return;

    const allowedExtensions = ['.dxf', '.dwg', '.zip'];
    const invalidFiles = newFiles.filter(file => {
      const ext = '.' + file.name.split('.').pop().toLowerCase();
      return !allowedExtensions.includes(ext);
    });

    if (invalidFiles.length > 0) {
      notify("Only DXF, DWG, and ZIP files are accepted. Please remove invalid files.", "error");
      return; // Don't add any files if invalid types are detected
    }

    const currentBytes = calculateTotalBytes(mepDrawingFiles);
    const newFilesBytes = calculateTotalBytes(newFiles);
    const totalBytes = currentBytes + newFilesBytes;

    if (totalBytes > MAX_FILE_SIZE_BYTES) {
      notify(`Cannot add files: total size would be ${formatFileSize(totalBytes)}, which exceeds the 25MB limit`, "error");
      return; // Don't add the files
    }

    setMepDrawingFiles([...mepDrawingFiles, ...newFiles]);
  };

  // File removal handlers
  const removeBrochureFile = (index) => {
    setBrochureFiles(prev => prev.filter((_, i) => i !== index));

    if (brochureInputRef.current) {
      brochureInputRef.current.value = "";
    }
  };

  const removeWorkingDrawingFile = (index) => {
    setWorkingDrawingFiles(prev => prev.filter((_, i) => i !== index));

    if (workingDrawingInputRef.current) {
      workingDrawingInputRef.current.value = "";
    }
  };

  const removeStructureDrawingFile = (index) => {
    setStructureDrawingFiles(prev => prev.filter((_, i) => i !== index));

    if (structureDrawingInputRef.current) {
      structureDrawingInputRef.current.value = "";
    }
  };

  const removeMepDrawingFile = (index) => {
    setMepDrawingFiles(prev => prev.filter((_, i) => i !== index));

    if (mepDrawingInputRef.current) {
      mepDrawingInputRef.current.value = "";
    }
  };

  useEffect(() => {
    // Create modified versions of brochure files
    const modifiedBrochureFiles = brochureFiles.map(file =>
      new File([file], `brochure_${file.name}`, { type: file.type })
    );

    // Create modified versions of drawing files with specific prefixes
    const modifiedDrawingFiles = [
      ...workingDrawingFiles.map(file =>
        new File([file], `working_drawings_${file.name}`, { type: file.type })
      ),
      ...structureDrawingFiles.map(file =>
        new File([file], `structure_drawings_${file.name}`, { type: file.type })
      ),
      ...mepDrawingFiles.map(file =>
        new File([file], `mep_drawings_${file.name}`, { type: file.type })
      )
    ];

    // Update the state with combined array
    setCompleteProjectFiles([...modifiedBrochureFiles, ...modifiedDrawingFiles]);
  }, [brochureFiles, workingDrawingFiles, structureDrawingFiles, mepDrawingFiles]);

  const renderFileCard = (file, index, onRemove) => {
    const extension = file.name.split('.').pop().toLowerCase();

    return (
      <Card
        key={index}
        className="d-flex flex-row justify-content-between align-items-center border-gray-100 shadow-none mb-1"
        style={{ borderRadius: 3 }}
      >
        <div className="d-flex align-items-center">
          {extension === "pdf" ? (
            <FaFilePdf className="text-primary-500 me-2" size={24} />
          ) : extension === "zip" ? (
            <FaFileZipper className="text-primary-500 me-2" size={24} />
          ) : extension === "dwg" ? (
            <FaFile className="text-info me-2" size={24} />
          ) : (
            <FaFile className="text-primary-500 me-2" size={24} />
          )}
          <div className="d-flex flex-column">
            <span className="body-text2 text-gray-900">{file.name}</span>
            <span className="text-gray-600" style={{ fontSize: 10 }}>
              {formatBytes(file?.size)}
            </span>
          </div>
        </div>
        <div className="cursor-pointer" onClick={() => onRemove(index)}>
          <FiTrash2 size={20} color="#3C4043" />
        </div>
      </Card>
    );
  };

  // console.log({ completeProjectFiles });

  return (
    <Container
      fluid
      className="mt-5 pt-4 px-0 d-flex flex-column justify-content-center align-items-center"
      style={{ minHeight: "60vh" }}
    >
      <div className="w-100 bg-info d-flex justify-content-center align-items-center">
        <h1 className="h2 text-primary inter-600 py-3">Add a new Property</h1>
      </div>
      <div className="d-flex flex-column flex-lg-row my-md-4" style={large ? { maxWidth: 1000 } : {}}>
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-lg-column mt-2 mt-md-4 mt-lg-3 ml-2 mb-1 px-3 px-lg-0">
            <div className="d-flex align-items-center mb-4 me-2">
              <div
                className={`p-1 text-center rounded-btn ${currentStep === 1
                  ? "bg-black text-white"
                  : currentStep === 2 || currentStep === 3
                    ? "bg-success text-white"
                    : "bg-gray-25"
                  }`}
              >
                {currentStep === 1 ? "1" : <FaCheck size={12} />}
              </div>
              <div
                className={`ms-2 inter-500 body-text1 ${currentStep !== 1 ? "text-success" : ""}`}
              >
                Property Details
              </div>
            </div>
            <div className="d-flex align-items-center mb-4 me-2">
              <div
                className={`p-1 text-center rounded-btn ${currentStep === 2
                  ? "bg-black text-white"
                  : currentStep === 3
                    ? "bg-success text-white"
                    : "text-gray-300 bg-gray-25 border border-gray-100"
                  }`}
              >
                {currentStep === 1 || currentStep === 2 ? "2" : <FaCheck size={12} />}
              </div>
              <div
                className={`ms-2 inter-500 body-text1 ${currentStep !== 1 && currentStep !== 2
                  ? "text-success"
                  : currentStep === 2
                    ? ""
                    : "text-gray-300"
                  }`}
              >
                Property Files
              </div>
            </div>
            <div className="d-flex align-items-center mb-4 me-2">
              <div
                className={`p-1 text-center rounded-btn ${currentStep === 3
                  ? "bg-black text-white"
                  : "text-gray-300 bg-gray-25 border border-gray-100"
                  }`}
              >
                3
              </div>
              <div
                className={`ms-2 inter-500 body-text1 ${currentStep === 3 ? "text-gray-900" : "text-gray-300"
                  }`}
              >
                Property Preview
              </div>
            </div>
          </div>
        </div>
        <div className="p-3">
          <div className="p-3 border" style={{ borderRadius: 6 }}>
            <UploadingFiles
              show={isFileUploading}
              files={completeProjectFiles}
              setShow={setIsFileUploading}
              parentId={parentId}
              app_label={"property"}
              model_name={"property"}
            />
            {currentStep === 1 && (
              <div style={large ? { minWidth: 600 } : {}}>
                <h4 className="fw-bold">{currentStep}. Property Details</h4>
                <div className="mt-4">
                  <Form ref={propertyInfoRef}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Property Name<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        required
                        className="py-2 body-text2"
                        type="text"
                        name="property_name"
                        placeholder="Enter property name"
                        value={propertyDetails?.property_name || ""}
                        onChange={handleFieldChange}
                        style={{
                          border: fieldErrors.property_name ? "1px solid red" : "1px solid #BDC1C6",
                          borderRadius: 3,
                        }}
                      />
                      {fieldErrors.property_name && (
                        <OverlayTrigger
                          placement="bottom"
                          show={true}
                          overlay={<Tooltip id="tooltip-property_name">{fieldErrors.property_name}</Tooltip>}
                        >
                          <div className="text-danger mt-1" style={{ fontSize: "0.75rem", cursor: "help" }}>
                            <i className="fas fa-exclamation-circle me-1"></i>
                            {fieldErrors.property_name}
                          </div>
                        </OverlayTrigger>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Number of Flats<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        required
                        className="py-2 body-text2"
                        type="number"
                        placeholder="No. of flats"
                        name="flats"
                        value={propertyDetails?.flats || ""}
                        onChange={handleFieldChange}
                        style={{
                          border: fieldErrors.flats ? "1px solid red" : "1px solid #BDC1C6",
                          borderRadius: 3,
                        }}
                      />
                      {fieldErrors.flats && (
                        <OverlayTrigger
                          placement="bottom"
                          show={true}
                          overlay={<Tooltip id="tooltip-flats">{fieldErrors.flats}</Tooltip>}
                        >
                          <div className="text-danger mt-1" style={{ fontSize: "0.75rem", cursor: "help" }}>
                            <i className="fas fa-exclamation-circle me-1"></i>
                            {fieldErrors.flats}
                          </div>
                        </OverlayTrigger>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex">
                      <div className="w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Construction Status<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Select
                          required
                          className="py-2 body-text2"
                          name="construction_status"
                          value={propertyDetails?.construction_status || ""}
                          onChange={handleFieldChange}
                          style={{
                            border: fieldErrors.construction_status ? "1px solid red" : "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        >
                          <option value="">Select</option>
                          {constructionStatuses.map((conStatus) => (
                            <option key={conStatus.id} value={conStatus.value}>
                              {conStatus.label}
                            </option>
                          ))}
                        </Form.Select>
                        {fieldErrors.construction_status && (
                          <OverlayTrigger
                            placement="bottom"
                            show={true}
                            overlay={<Tooltip id="tooltip-construction_status">{fieldErrors.construction_status}</Tooltip>}
                          >
                            <div className="text-danger mt-1" style={{ fontSize: "0.75rem", cursor: "help" }}>
                              <i className="fas fa-exclamation-circle me-1"></i>
                              {fieldErrors.construction_status}
                            </div>
                          </OverlayTrigger>
                        )}
                      </div>
                      <div className="ms-2 w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Possession Date<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <div className="d-flex gap-2">
                          <Form.Select
                            required
                            className="py-2 body-text2"
                            name="possession_month"
                            value={propertyDetails?.possession_month || ""}
                            onChange={handleFieldChange}
                            style={{
                              border: fieldErrors.possession_month ? "1px solid red" : "1px solid #BDC1C6",
                              borderRadius: 3,
                              width: "50%",
                            }}
                          >
                            <option value="">Month</option>
                            {months.map((month, index) => (
                              <option key={index + 1} value={index + 1}>
                                {month}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control
                            required
                            className="py-2 body-text2"
                            type="number"
                            placeholder="Year"
                            name="possession_year"
                            value={propertyDetails?.possession_year || ""}
                            onChange={handlePossessionYearChange}
                            style={{
                              border: fieldErrors.possession_year ? "1px solid red" : "1px solid #BDC1C6",
                              borderRadius: 3,
                              width: "50%",
                            }}
                          />
                        </div>
                        {(fieldErrors.possession_month || fieldErrors.possession_year) && (
                          <OverlayTrigger
                            placement="bottom"
                            show={true}
                            overlay={<Tooltip id="tooltip-possession">{fieldErrors.possession_month || fieldErrors.possession_year}</Tooltip>}
                          >
                            <div className="text-danger mt-1" style={{ fontSize: "0.75rem", cursor: "help" }}>
                              <i className="fas fa-exclamation-circle me-1"></i>
                              {fieldErrors.possession_month || fieldErrors.possession_year}
                            </div>
                          </OverlayTrigger>
                        )}
                      </div>
                    </Form.Group>
                    <div className="w-100 mb-3">
                      <Form.Group>
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Address<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          className="py-2 body-text2"
                          type="text"
                          name="address"
                          placeholder="Enter address ..."
                          required
                          value={propertyDetails?.address || ""}
                          onChange={handleFieldChange}
                          style={{
                            border: fieldErrors.address ? "1px solid red" : "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                        {fieldErrors.address && (
                          <OverlayTrigger
                            placement="bottom"
                            show={true}
                            overlay={<Tooltip id="tooltip-address">{fieldErrors.address}</Tooltip>}
                          >
                            <div className="text-danger mt-1" style={{ fontSize: "0.75rem", cursor: "help" }}>
                              <i className="fas fa-exclamation-circle me-1"></i>
                              {fieldErrors.address}
                            </div>
                          </OverlayTrigger>
                        )}
                      </Form.Group>
                    </div>
                    <Form.Group className="mb-3 d-flex">
                      <div className="w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Pincode<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          type="text"
                          onChange={(e) => {
                            getPincode(e.target.value);
                            handleFieldChange(e);
                          }}
                          onKeyPress={handlePincodeKeyPress}
                          onBlur={handlePincodeBlur}
                          maxLength={6}
                          placeholder="Enter pincode"
                          name="pincode"
                          value={propertyDetails?.pincode || ""}
                          style={{
                            border: fieldErrors.pincode ? "1px solid red" : "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                        {fieldErrors.pincode && (
                          <OverlayTrigger
                            placement="bottom"
                            show={true}
                            overlay={<Tooltip id="tooltip-pincode">{fieldErrors.pincode}</Tooltip>}
                          >
                            <div className="text-danger mt-1" style={{ fontSize: "0.75rem", cursor: "help" }}>
                              <i className="fas fa-exclamation-circle me-1"></i>
                              {fieldErrors.pincode}
                            </div>
                          </OverlayTrigger>
                        )}
                      </div>
                      <div className="ms-2 w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Country<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          disabled={true}
                          className="py-2 body-text2"
                          type="text"
                          placeholder="Enter country"
                          name="country"
                          value={propertyDetails?.country || ""}
                          style={{
                            border: fieldErrors.country ? "1px solid red" : "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                        {fieldErrors.country && (
                          <OverlayTrigger
                            placement="bottom"
                            show={true}
                            overlay={<Tooltip id="tooltip-country">{fieldErrors.country}</Tooltip>}
                          >
                            <div className="text-danger mt-1" style={{ fontSize: "0.75rem", cursor: "help" }}>
                              <i className="fas fa-exclamation-circle me-1"></i>
                              {fieldErrors.country}
                            </div>
                          </OverlayTrigger>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex">
                      <div className="w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          City<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          disabled={true}
                          type="text"
                          placeholder="Enter city"
                          name="city"
                          value={propertyDetails?.city || ""}
                          style={{
                            border: fieldErrors.city ? "1px solid red" : "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                        {fieldErrors.city && (
                          <OverlayTrigger
                            placement="bottom"
                            show={true}
                            overlay={<Tooltip id="tooltip-city">{fieldErrors.city}</Tooltip>}
                          >
                            <div className="text-danger mt-1" style={{ fontSize: "0.75rem", cursor: "help" }}>
                              <i className="fas fa-exclamation-circle me-1"></i>
                              {fieldErrors.city}
                            </div>
                          </OverlayTrigger>
                        )}
                      </div>
                      <div className="ms-2 w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          State<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          disabled={true}
                          type="text"
                          placeholder="Enter state"
                          name="state"
                          value={propertyDetails?.state || ""}
                          style={{
                            border: fieldErrors.state ? "1px solid red" : "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                        {fieldErrors.state && (
                          <OverlayTrigger
                            placement="bottom"
                            show={true}
                            overlay={<Tooltip id="tooltip-state">{fieldErrors.state}</Tooltip>}
                          >
                            <div className="text-danger mt-1" style={{ fontSize: "0.75rem", cursor: "help" }}>
                              <i className="fas fa-exclamation-circle me-1"></i>
                              {fieldErrors.state}
                            </div>
                          </OverlayTrigger>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Property Segment<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Select
                        required
                        className="py-2 body-text2"
                        name="property_segment"
                        value={propertyDetails?.property_segment || ""}
                        onChange={handleFieldChange}
                        style={{
                          border: fieldErrors.property_segment ? "1px solid red" : "1px solid #BDC1C6",
                          borderRadius: 3,
                        }}
                      >
                        <option value="">Select</option>
                        {propertySegments?.map((segment, index) => (
                          <option key={index} value={segment.value}>
                            {segment.label}
                          </option>
                        ))}
                      </Form.Select>
                      {fieldErrors.property_segment && (
                        <OverlayTrigger
                          placement="bottom"
                          show={true}
                          overlay={<Tooltip id="tooltip-property_segment">{fieldErrors.property_segment}</Tooltip>}
                        >
                          <div className="text-danger mt-1" style={{ fontSize: "0.75rem", cursor: "help" }}>
                            <i className="fas fa-exclamation-circle me-1"></i>
                            {fieldErrors.property_segment}
                          </div>
                        </OverlayTrigger>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Property Image (Max 5MB)<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      {!selectedPropertyImage ? (
                        <>
                          <Form.Control
                            required
                            type="file"
                            className="py-2 body-text2"
                            name="property_img"
                            onChange={handlePropertyImageChange}
                            style={{
                              border: fieldErrors.property_img ? "1px solid red" : "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                            accept="image/*"
                          />
                          {errorMessage && (
                            <div className="text-danger mt-1">{errorMessage}</div>
                          )}
                        </>
                      ) : (
                        <div className="drop-file-preview__item d-flex justify-content-between align-items-start w-100">
                          <div className="rounded overflow-hidden border me-2">
                            <img
                              src={URL.createObjectURL(selectedPropertyImage)}
                              alt=""
                              className="img-fluid"
                              style={{ objectFit: "cover", height: 76 }}
                            />
                          </div>
                          <div className="w-100">
                            {selectedPropertyImage?.name} -{" "}
                            {(selectedPropertyImage?.size / 1024).toFixed(2)} KB
                          </div>
                          <div
                            onClick={() => setSelectedPropertyImage(null)}
                            className="drop-file-preview__item__del cursor-pointer bg-gray text-center rounded-circle ms-2"
                          >
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ width: 40, height: 40 }}
                            >
                              <div className="w-100 mb-1">
                                <FaXmark size={18} className="text-dark" />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Form.Group>
                    <Form.Label className="mb-1 body-text2 inter-500">Point of Contact</Form.Label>
                    <Form.Group className="mb-3 d-flex">
                      <div className="w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Name<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          type="text"
                          placeholder="Enter Name"
                          name="name"
                          value={propertyDetails?.name || ""}
                          onChange={handleFieldChange}
                          style={{
                            border: fieldErrors.name ? "1px solid red" : "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                        {fieldErrors.name && (
                          <OverlayTrigger
                            placement="bottom"
                            show={true}
                            overlay={<Tooltip id="tooltip-name">{fieldErrors.name}</Tooltip>}
                          >
                            <div className="text-danger mt-1" style={{ fontSize: "0.75rem", cursor: "help" }}>
                              <i className="fas fa-exclamation-circle me-1"></i>
                              {fieldErrors.name}
                            </div>
                          </OverlayTrigger>
                        )}
                      </div>
                      <div className="ms-2 w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Phone No<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          type="tel"
                          placeholder="Enter Phone Number"
                          name="phone_number"
                          maxLength={10}
                          value={propertyDetails?.phone_number || ""}
                          onChange={handlePhoneNumberChange}
                          onKeyPress={handlePhoneKeyPress}
                          style={{
                            border: fieldErrors.phone_number ? "1px solid red" : "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                        {fieldErrors.phone_number && (
                          <OverlayTrigger
                            placement="bottom"
                            show={true}
                            overlay={<Tooltip id="tooltip-phone_number">{fieldErrors.phone_number}</Tooltip>}
                          >
                            <div className="text-danger mt-1" style={{ fontSize: "0.75rem", cursor: "help" }}>
                              <i className="fas fa-exclamation-circle me-1"></i>
                              {fieldErrors.phone_number}
                            </div>
                          </OverlayTrigger>
                        )}
                      </div>
                    </Form.Group>
                  </Form>
                  <div className="d-flex">
                    <Button
                      className="d-flex align-items-center text-white body-text2 me-2"
                      style={{ borderRadius: 3 }}
                      onClick={handlePropertyDetails}
                    >
                      Continue
                    </Button>
                    <Button
                      className="bg-transparent border-gray-200 text-gray-900 body-text2"
                      style={{ borderRadius: 3 }}
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div style={large ? { minWidth: 600 } : {}}>
                <h4 className="fw-bold">{currentStep}. Add Property Files</h4>
                <div className="my-4">
                  {layouts.map((layout, index) => (
                    <div key={index} className="mb-3">
                      <div className="d-flex flex-wrap align-items-center w-100 mt-2 me-2">
                        <Col xs={12} md={3} className="">
                          <Form.Group className="mb-3 mb-md-0">
                            <Form.Label className="mb-1 body-text2 inter-500">
                              Layout Type<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <Form.Select
                              required
                              className="py-2 body-text2"
                              name="layout"
                              value={layout.layout}
                              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                              onChange={(e) =>
                                handleInputChange(layout.id, e.target.name, e.target.value)
                              }
                            >
                              <option value="" disabled>
                                Select Layout
                              </option>
                              {availableLayouts.map((layoutOption) => (
                                <option key={layoutOption.id} value={layoutOption.layout}>
                                  {layoutOption.layout}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={4} className="px-1">
                          <Form.Group className="mb-3 mb-md-0">
                            <Form.Label className="mb-1 body-text2 inter-500">
                              Layout Area<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <InputGroup style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}>
                              <Form.Control
                                required
                                className="py-2 body-text2 border-0"
                                type="number"
                                name="area"
                                min={0}
                                placeholder="200"
                                value={layout.area}
                                onChange={(e) => {
                                  if (e.target.value < 0) {
                                    notify("Layout Area must be greater than 0.", 'error');
                                    return;
                                  } else {
                                    handleInputChange(layout.id, e.target.name, e.target.value);
                                  }
                                }}
                                style={{
                                  border: "1px solid #BDC1C6",
                                  borderRadius: "3px 0 0 3px",
                                  width: "60%",
                                }}
                              />
                              <Form.Select
                                className="body-text2 border-0"
                                name="unit"
                                value={layout.unit || "Sq.ft."}
                                onChange={(e) =>
                                  handleInputChange(layout.id, e.target.name, e.target.value)
                                }
                                style={{
                                  border: "1px solid #BDC1C6",
                                  borderRadius: "0 3px 3px 0",
                                  width: small ? "31%" : "",
                                }}
                              >
                                <option value="Sq.ft.">Sq.ft.</option>
                                <option value="Sq.m.">Sq.m.</option>
                              </Form.Select>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                          <Form.Group className="mb-3 mb-md-0">
                            <Form.Label className="mb-1 body-text2 inter-500">
                              Total Flats<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <Form.Control
                              required
                              className="py-2 body-text2"
                              type="number"
                              name="totalFlats"
                              placeholder="Enter total flats"
                              value={layout.totalFlats}
                              style={{
                                border: layoutErrors.totalFlats ? "1px solid red" : "1px solid #BDC1C6",
                                borderRadius: 3,
                              }}
                              onChange={(e) => {
                                if (e.target.value < 0) {
                                  notify("Total Flats must be greater than 0.", 'error');
                                  return;
                                } else {
                                  handleInputChange(layout.id, e.target.name, e.target.value);
                                }
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          xs={12}
                          md={1}
                          style={{ marginTop: small ? "2rem" : "" }}
                          className="d-flex"
                        >
                          {layouts.length > 1 && (
                            <Button
                              className="d-flex align-items-center rounded-btn bg-gray"
                              onClick={() => deleteLayout(index)}
                            >
                              <FaTrash size={18} />
                            </Button>
                          )}
                        </Col>
                      </div>
                      {layoutErrors.totalFlats && (
                        <OverlayTrigger
                          placement="bottom"
                          show={true}
                          overlay={
                            <Tooltip id={`tooltip-totalFlats-${index}`}>
                              {layoutErrors.totalFlats}
                            </Tooltip>
                          }
                        >
                          <div
                            className="text-danger mt-1"
                            style={{ fontSize: "0.75rem", cursor: "help" }}
                          >
                            <i className="fas fa-exclamation-circle me-1"></i>
                            {layoutErrors.totalFlats}
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                  ))}
                  <div
                    className="mt-2 ms-2 body-text3 inter-500 cursor-pointer"
                  >
                    <span onClick={() => addLayout()} style={{ borderBottom: "1px solid #000000" }}>
                      Add another layout
                    </span>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="mb-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Brochure (Max 25MB)<sup style={{ color: "red" }}>*</sup>
                    </Form.Label>
                    <div
                      className="d-flex align-items-center border rounded cursor-pointer overflow-hidden"
                      onClick={() => document.getElementById('brochureInput').click()}
                      style={{ height: "38px" }}
                    >
                      <div className="bg-brand-500 body-text1 inter-500 text-white px-2 py-2 h-100 d-flex align-items-center">
                        Choose files
                      </div>
                      <div className="px-3 text-muted body-text2 inter-500">
                        {brochureFiles.length > 0 ? `${brochureFiles.length} file(s) selected` : 'No file chosen'}
                      </div>
                      <Form.Control
                        id="brochureInput"
                        type="file"
                        ref={brochureInputRef}
                        accept=".pdf,.zip"
                        onChange={handleBrochureChange}
                        className="d-none"
                        multiple
                      />
                    </div>
                    <small className="text-muted body-text2 inter-500">Only PDF and ZIP files are accepted</small>

                    {brochureFiles.length > 0 && (
                      <div className="drop-file-preview mt-2">
                        {brochureFiles.map((file, index) => (
                          renderFileCard(file, index, removeBrochureFile)
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Working Drawing (Max 25MB)<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <div
                    className="d-flex align-items-center border rounded cursor-pointer overflow-hidden"
                    onClick={() => document.getElementById('workingDrawingInput').click()}
                    style={{ height: "38px" }}
                  >
                    <div className="bg-brand-500 body-text1 inter-500 text-white px-2 py-2 h-100 d-flex align-items-center">
                      Choose files
                    </div>
                    <div className="px-3 text-muted body-text2 inter-500">
                      {workingDrawingFiles.length > 0 ? `${workingDrawingFiles.length} file(s) selected` : 'No file chosen'}
                    </div>
                    <Form.Control
                      id="workingDrawingInput"
                      type="file"
                      accept=".dxf,.dwg,.zip"
                      ref={workingDrawingInputRef}
                      onChange={handleWorkingDrawingChange}
                      className="d-none"
                      multiple
                    />
                  </div>
                  <small className="text-muted body-text2 inter-500">DXF, DWG, and ZIP files are accepted</small>

                  {workingDrawingFiles.length > 0 && (
                    <div className="drop-file-preview mt-2">
                      {workingDrawingFiles.map((file, index) => (
                        renderFileCard(file, index, removeWorkingDrawingFile)
                      ))}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Structure Drawing (Max 25MB)<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <div
                    className="d-flex align-items-center border rounded cursor-pointer overflow-hidden"
                    onClick={() => document.getElementById('structureDrawingInput').click()}
                    style={{ height: "38px" }}
                  >
                    <div className="bg-brand-500 body-text1 inter-500 text-white px-2 py-2 h-100 d-flex align-items-center">
                      Choose files
                    </div>
                    <div className="px-3 text-muted body-text2 inter-500">
                      {structureDrawingFiles.length > 0 ? `${structureDrawingFiles.length} file(s) selected` : 'No file chosen'}
                    </div>
                    <Form.Control
                      id="structureDrawingInput"
                      type="file"
                      ref={structureDrawingInputRef}
                      accept=".dxf,.dwg,.zip"
                      onChange={handleStructureDrawingChange}
                      className="d-none"
                      multiple
                    />
                  </div>
                  <small className="text-muted body-text2 inter-500">DXF, DWG, and ZIP files are accepted</small>

                  {structureDrawingFiles.length > 0 && (
                    <div className="drop-file-preview mt-2">
                      {structureDrawingFiles.map((file, index) => (
                        renderFileCard(file, index, removeStructureDrawingFile)
                      ))}
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    MEP Drawing (Max 25MB)<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <div
                    className="d-flex align-items-center border rounded cursor-pointer overflow-hidden"
                    onClick={() => document.getElementById('mepDrawingInput').click()}
                    style={{ height: "38px" }}
                  >
                    <div className="bg-brand-500 body-text1 inter-500 text-white px-2 py-2 h-100 d-flex align-items-center">
                      Choose files
                    </div>
                    <div className="px-3 text-muted body-text2 inter-500">
                      {mepDrawingFiles.length > 0 ? `${mepDrawingFiles.length} file(s) selected` : 'No file chosen'}
                    </div>
                    <Form.Control
                      id="mepDrawingInput"
                      type="file"
                      ref={mepDrawingInputRef}
                      accept=".dxf,.dwg,.zip"
                      onChange={handleMepDrawingChange}
                      className="d-none"
                      multiple
                    />
                  </div>
                  <small className="text-muted body-text2 inter-500">DXF, DWG, and ZIP files are accepted</small>

                  {mepDrawingFiles.length > 0 && (
                    <div className="drop-file-preview mt-2">
                      {mepDrawingFiles.map((file, index) => (
                        renderFileCard(file, index, removeMepDrawingFile)
                      ))}
                    </div>
                  )}
                </div>
                <div className="mt-4 d-flex">
                  <Button
                    className="d-flex align-items-center text-white body-text2 me-2"
                    style={{ borderRadius: 3 }}
                    onClick={() => {
                      const layoutsIncomplete = layouts.some(
                        (layout) => !layout.layout || !layout.area || !layout.totalFlats
                      );

                      if (layoutsIncomplete) {
                        notify("All layout details are required", "error");
                        return;
                      }

                      // Validate total flats using the centralized function
                      const isTotalFlatsValid = validateTotalFlats(layouts);
                      if (!isTotalFlatsValid) {
                        return; // Prevent navigation if validation fails
                      }

                      // Add file validation check
                      if (!validateRequiredFiles()) {
                        return;
                      }

                      setCurrentStep(3);
                    }}
                  >
                    Continue
                  </Button>
                  <Button
                    className="bg-transparent border-gray-200 text-gray-900 body-text2"
                    style={{ borderRadius: 3 }}
                    onClick={() => setCurrentStep(1)}
                  >
                    Back
                  </Button>
                </div>
              </div>
            )}
            {currentStep === 3 && (
              <div style={large ? { minWidth: 600 } : {}}>
                <h4 className="fw-bold">{currentStep}. Property Preview</h4>
                <PreviewPropertyCard
                  property_name={propertyDetails?.property_name}
                  builder={user?.full_name}
                  flats={propertyDetails?.flats}
                  layout_types={layouts}
                  city={propertyDetails?.city}
                  name={propertyDetails?.name}
                  phone_number={propertyDetails?.phone_number}
                  pincode={propertyDetails?.pincode}
                  possession_year={propertyDetails?.possession_year}
                  possession_month={propertyDetails?.possession_month}
                  state={propertyDetails?.state}
                  address={propertyDetails?.address}
                  country={propertyDetails?.country}
                  property_year={propertyDetails?.property_year}
                  construction_status={propertyDetails?.construction_status}
                  project_type={propertyDetails?.project_type}
                  property_segment={propertyDetails?.property_segment}
                  property_img={
                    selectedPropertyImage ? URL.createObjectURL(selectedPropertyImage) : ""
                  }
                  setCurrentStep={setCurrentStep}
                />
                <div className="mt-4 d-flex">
                  <Button
                    className="d-flex align-items-center text-white body-text2 me-2"
                    style={{ borderRadius: 3 }}
                    onClick={handlePropertySubmit}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <Spinner animation="border" size="sm" className="me-2" /> Submitting...
                      </>
                    ) : (
                      <>Submit Property</>
                    )}
                  </Button>
                  <Button
                    className="bg-transparent border-gray-200 text-gray-900 body-text2"
                    style={{ borderRadius: 3 }}
                    disabled={loading}
                    onClick={() => setCurrentStep(2)}
                  >
                    Back
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default AddProperty;