
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { notify } from "utilities/notifications/Notify";
const { Modal, ProgressBar, Button } = require("react-bootstrap");

const CHUNK_SIZE = 1024 * 1024;

const UploadingFiles = ({
  files = [],
  show = false,
  setShow,
  parentId = null,
  app_label = null,
  model_name = null,
  isEdit = false,
}) => {
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploading, setUploading] = useState(false);
  const [confirmingCancel, setConfirmingCancel] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [errorState, setErrorState] = useState(null);
  const navigate = useNavigate();

  const cancelTokensRef = useRef([]);
  const isMounted = useRef(true);
  const isCanceling = useRef(false);
  const uploadStateRef = useRef({
    canceled: false,
    inProgress: false,
    completed: false,
    fileIdsUploaded: []
  });

  // Clean up on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
      // Attempt to cancel if component unmounts during upload
      if (uploadStateRef.current.inProgress && !uploadStateRef.current.completed) {
        cancelUploadInBackground();
      }
    };
  }, []);

  // Start upload when all necessary props are available
  useEffect(() => {
    if (
      files.length > 0 &&
      parentId != null &&
      app_label != null &&
      model_name != null &&
      show &&
      !uploadStateRef.current.inProgress &&
      !uploadStateRef.current.completed
    ) {
      handleUpload();
    }
  }, [parentId, app_label, model_name, files, show]);

  // Function to cancel upload in background (for unmounting scenarios)
  const cancelUploadInBackground = async () => {
    try {
      if (parentId) {
        // Check status first to avoid unnecessary API calls
        const statusResponse = await axios.get(`/property/properties/${parentId}/`, {
          timeout: 5000 // Short timeout for quick response
        });

        if (statusResponse.data.upload_status === 'DRAFT') {
          await axios.post(`/property/properties/${parentId}/cancel_upload/`, {
            timeout: 5000
          });
          console.log("Upload canceled in background");
        }
      }
    } catch (error) {
      console.error("Background cancellation error:", error);
    }
  };

  const cancelAllUploads = () => {
    // Update our state tracker first
    uploadStateRef.current.canceled = true;

    // Cancel all active upload requests
    cancelTokensRef.current.forEach(cancelToken => {
      if (cancelToken && cancelToken.cancel) {
        cancelToken.cancel("Operation canceled by the user.");
      }
    });
    cancelTokensRef.current = [];
  };

  const handleClose = () => {
    if (uploading && !uploadComplete) {
      // If files are still uploading, show confirmation dialog
      setConfirmingCancel(true);
    } else {
      // If uploads are complete or not started, just close the modal
      setShow(false);
      // if (uploadComplete) {
        navigate("/builder/properties", { state: { reload: true } });
      // }
    }
  };

  const confirmCancel = async () => {
    if (isCanceling.current) return; // Prevent double-cancellation
    isCanceling.current = true;

    try {
      // First, mark as canceled and stop ongoing uploads
      cancelAllUploads();
      setConfirmingCancel(false);

      // Use an AbortController for the cancel API request
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 8000); // 8-second timeout

      if (parentId) {
        try {
          // Execute both requests in parallel
          const [propertyResponse] = await Promise.all([
            axios.get(`/property/properties/${parentId}/`, {
              signal: controller.signal
            })
          ]);

          clearTimeout(timeoutId);
          const propertyStatus = propertyResponse.data.upload_status;

          if (propertyStatus === 'DRAFT') {
            // Use a new controller for the cancel request
            const cancelController = new AbortController();
            const cancelTimeoutId = setTimeout(() => cancelController.abort(), 8000);

            try {
              await axios.post(`/property/properties/${parentId}/cancel_upload/`, {}, {
                signal: cancelController.signal
              });
              clearTimeout(cancelTimeoutId);
              notify("Upload canceled and property removed", "info");
            } catch (cancelError) {
              console.error('Cancel upload request failed:', cancelError);
              notify("Failed to cancel upload - property may still exist", "warning");
            }
          } else {
            notify("Cannot cancel - property is already in " + propertyStatus + " status", "info");
          }
        } catch (error) {
          console.error('Error in cancel process:', error);
          notify("Failed to cancel upload - please check properties page", "warning");
        }
      }

      setShow(false);
      navigate("/builder/properties");
      setUploading(false);
      uploadStateRef.current.inProgress = false;
    } catch (error) {
      console.error('Error in cancel process:', error);
      setShow(false);
      navigate("/builder/properties");
    } finally {
      isCanceling.current = false;
    }
  };

  const handleUpload = async () => {
    if (uploadStateRef.current.inProgress) return; // Prevent double-upload

    setUploading(true);
    uploadStateRef.current.inProgress = true;
    uploadStateRef.current.fileIdsUploaded = [];

    // Reset progress for UI
    const initialProgress = {};
    files.forEach(file => {
      initialProgress[file.name] = 0;
    });
    setUploadProgress(initialProgress);

    try {
      // Start uploading files while property is still in DRAFT status
      for (let file of files) {
        // Check if canceled or component unmounted
        if (!isMounted.current || uploadStateRef.current.canceled) {
          console.log("Upload stopped - component unmounted or canceled");
          return;
        }

        try {
          const fileId = await uploadFileInChunks(file);
          if (fileId) {
            uploadStateRef.current.fileIdsUploaded.push(fileId);
            console.log(`File ${file.name} uploaded successfully with ID: ${fileId}`);
          }
        } catch (fileError) {
          if (axios.isCancel(fileError)) {
            console.log("File upload canceled:", file.name);
          } else {
            console.error(`Error uploading file ${file.name}:`, fileError);
            throw fileError; // Re-throw to be caught by outer catch
          }
        }
      }

      // Only mark property as complete if not canceled and all files uploaded
      if (
        parentId &&
        isMounted.current &&
        !uploadStateRef.current.canceled &&
        uploadStateRef.current.fileIdsUploaded.length === files.length
      ) {
        try {
          // Double-check the property status before completion
          const statusCheck = await axios.get(`/property/properties/${parentId}/`);

          if (statusCheck.data.upload_status === 'DRAFT') {
            await axios.post(`/property/properties/${parentId}/complete_upload/`);
            uploadStateRef.current.completed = true;
            setUploadComplete(true);

            if (isMounted.current) {
              setUploading(false);
              navigate("/builder/properties", { state: { reload: true } });
              if (isEdit) {
                notify("Property Updated!", "success");
              } else {
                notify("Property Created!", "success");
              }
            }
          } else {
            console.log("Property already in non-DRAFT status:", statusCheck.data.upload_status);
          }
        } catch (error) {
          console.error('Failed to mark property as complete:', error);
          setErrorState("Upload completed but property status could not be updated");
          notify("Upload completed but property status could not be updated", "warning");
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Overall upload process canceled");
      } else {
        console.error("Upload error:", error);
        setErrorState("Upload failed: " + (error.message || "Unknown error"));
        notify("Upload failed. Please try again.", "error");
      }
    } finally {
      if (isMounted.current) {
        uploadStateRef.current.inProgress = false;
        setUploading(false);
      }
    }
  };

  const uploadFileInChunks = async (file) => {
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let fileId = null;
    let uploadedChunks = 0;

    try {
      for (let i = 0; i < totalChunks; i++) {
        if (!isMounted.current || uploadStateRef.current.canceled) {
          throw new axios.Cancel("Upload canceled");
        }

        const start = i * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);

        if (i === 0) {
          const hash = await computeHash(file);
          fileId = await uploadFirstChunk(chunk, file.name, totalChunks, hash);
          if (totalChunks === 1) {
            await uploadNextChunk(chunk, fileId, i);
          }
        } else {
          await uploadNextChunk(chunk, fileId, i);
        }

        uploadedChunks++;

        if (isMounted.current) {
          setUploadProgress((prev) => ({
            ...prev,
            [file.name]: Math.round((uploadedChunks / totalChunks) * 100),
          }));
        }
      }

      return fileId;
    } catch (error) {
      if (axios.isCancel(error)) {
        throw error; // Re-throw cancel errors
      } else {
        console.error(`Error uploading ${file.name} in chunks:`, error);
        // Update UI to show error state for this file
        if (isMounted.current) {
          setUploadProgress((prev) => ({
            ...prev,
            [file.name]: -1, // Use -1 to indicate error
          }));
        }
        throw error;
      }
    }
  };

  const generateUniqueFileName = (fileName) => {
    // Trim the file name to remove leading/trailing spaces
    fileName = fileName.trim();

    // Find the last dot to separate the extension
    const lastDotIndex = fileName.lastIndexOf(".");
    let baseName, extension;

    if (lastDotIndex !== -1) {
      baseName = fileName.substring(0, lastDotIndex);
      extension = fileName.substring(lastDotIndex);
    } else {
      baseName = fileName;
      extension = "";
    }

    // Remove spaces, underscores, and dashes from the base name
    baseName = baseName.replace(/[\s_-]+/g, "");
    // Remove any accidental spaces from the extension and make it lowercase
    extension = extension.replace(/\s+/g, "").toLowerCase();

    // Generate an 8-character random string
    const randomString = Math.random().toString(36).substring(2, 10);

    return `${baseName}_${Date.now()}_${randomString}${extension}`;
  };

  const uploadFirstChunk = async (chunk, fileName, totalChunks, hash) => {
    const source = axios.CancelToken.source();
    cancelTokensRef.current.push(source);

    const uniqueFileName = generateUniqueFileName(fileName);
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("file_name", fileName);
    formData.append("total_chunks", totalChunks);
    formData.append("hash", hash);
    formData.append("parentId", parentId);
    formData.append("app_label", app_label);
    formData.append("model_name", model_name);

    try {
      const response = await axios.post(
        "/uploads/upload/first_chunk/",
        formData,
        {
          cancelToken: source.token,
          timeout: 30000 // 30 second timeout
        }
      );

      // Remove from active tokens after successful request
      cancelTokensRef.current = cancelTokensRef.current.filter(token => token !== source);

      return response.data.file_id;
    } catch (error) {
      // Remove from active tokens on error
      cancelTokensRef.current = cancelTokensRef.current.filter(token => token !== source);
      throw error;
    }
  };

  const uploadNextChunk = async (chunk, fileId, chunkIndex) => {
    const source = axios.CancelToken.source();
    cancelTokensRef.current.push(source);

    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("file_id", fileId);
    formData.append("chunk_index", chunkIndex);

    try {
      await axios.post(
        "/uploads/upload/next_chunk/",
        formData,
        {
          cancelToken: source.token,
          timeout: 30000 // 30 second timeout
        }
      );

      // Remove from active tokens after successful request
      cancelTokensRef.current = cancelTokensRef.current.filter(token => token !== source);
    } catch (error) {
      // Remove from active tokens on error
      cancelTokensRef.current = cancelTokensRef.current.filter(token => token !== source);
      throw error;
    }
  };

  const computeHash = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const wordArray = CryptoJS.lib.WordArray.create(reader.result);
        const hash = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex);
        resolve(hash);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  // Get progress color based on status
  const getProgressVariant = (progress) => {
    if (progress === -1) return "danger"; // Error
    if (progress === 100) return "success"; // Complete
    return "primary"; // In progress
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        backdrop="static"
        onHide={handleClose}
        aria-labelledby="uploading-files-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>
              {errorState ? "Upload Error" :
                uploadComplete ? "Upload Complete" :
                  "Uploading Files..."}
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorState && (
            <div className="alert alert-danger mb-3">
              {errorState}
            </div>
          )}

          <table style={{ width: "100%", marginTop: "10px" }}>
            <thead>
              <tr>
                <th className="text-primary">File Path</th>
                <th className="text-primary">File Size (bytes)</th>
                <th className="text-primary">Progress</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, index) => (
                <tr key={index}>
                  <td>{file.name}</td>
                  <td>{file.size}</td>
                  <td>
                    <ProgressBar
                      striped={uploadProgress[file.name] !== 100}
                      variant={getProgressVariant(uploadProgress[file.name])}
                      now={uploadProgress[file.name] > 0 ? uploadProgress[file.name] : 0}
                      label={uploadProgress[file.name] === -1 ? "Error" :
                        uploadProgress[file.name] === 100 ? "Complete" :
                          `${uploadProgress[file.name] || 0}%`}
                    />
                    {uploadProgress[file.name] === -1 && (
                      <small className="text-danger">Failed to upload. Please try again.</small>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          {uploadComplete ? (
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          ) : uploading ? (
            <Button variant="secondary" onClick={handleClose}>
              Cancel Upload
            </Button>
          ) : (
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={confirmingCancel}
        onHide={() => setConfirmingCancel(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Upload?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Files are currently uploading. Canceling will remove the property and all uploaded files. This operation cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmingCancel(false)}>
            Continue Uploading
          </Button>
          <Button
            variant="danger"
            onClick={confirmCancel}
            disabled={isCanceling.current}
          >
            {isCanceling.current ? "Canceling..." : "Cancel Upload"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadingFiles;