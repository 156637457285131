import { joi_property, managalam_tarang, suncity_avenue,walk_through2,walk_through1 } from "assets";
import React from "react";
import { Carousel, Card } from "react-bootstrap";

const StepsCarouselForArch = () => {
  const accordionItems = [
    {
      id: "step_1",
      image: `${joi_property}`,
      title: "Create your professional account",
      content:
       "Showcase all your work, details and awards with a single click. Let clients find you!",
      step: "Step 1",
    },
    {
      id: "step_2",

      image: `${walk_through2}`,
      title: "Badges to promote your presence",
      content:
       "To let your efforts be known, we assign you badges when you reach each milestone on the InterioKeys platform.",
      step: "Step 2",
    },

    {
      id: "step_3",
      image: `${walk_through1}`,
      // property_title: "Mangalam Tarang/Mangalam Group",
      title: "Badges to promote your presence",
      content:
      "To let your efforts be known, we assign you badges when you reach each milestone on the InterioKeys platform.",

      step: "Step 3",
    },
  ];

  return (
    <Carousel
      controls={false}
      indicators={true}
      interval={null}
      touch={true}
      className="builder-carousel w-100"
    >
      {accordionItems.map((item) => (
        <Carousel.Item key={item.id}>
          <div className="mb-4 w-100">
            <Card
              className="shadow-sm w-100 text-start"
              style={{ padding: "12px", borderRadius: "6px" }}
            >
              <div className="px-0">
                <div className="mb-2">
                  <p
                    className="text-gray-500 inter-400"
                    style={{ fontSize: "12px", lineHeight: "15px" }}
                  >
                    {item.step}
                  </p>
                </div>

                <p
                  className="text-primary-500 inter-400 mb-0"
                  style={{ fontSize: "14px" }}
                >
                  {item.title}
                </p>

                <p
                  className="text-gray-900 inter-400"
                  style={{ fontSize: "12px" }}
                >
                  {item.content}
                </p>

                <Card.Img
                  variant="top"
                  src={item.image}
                  className="mb-0"
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "6px",
                  }}
                />

                <div style={{ padding: "6px" }}>
                  <p className="body-title inter-600 text-gray-800 mb-0">
                    {item.property_title}
                  </p>
                  <div className="body-text1 inter-400 text-gray-800">
                    <p className="mb-0">{item.layout}</p>
                    <p className="mb-0">{item.location}</p>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default StepsCarouselForArch;
