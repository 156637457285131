import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import { FaTrash, FaEdit } from "react-icons/fa";
import axios from "axios";
import { useSelector } from "react-redux";
import { notify } from "utilities/notifications/Notify";

const Gallery = ({
  items,
  fetchGalleries,
  enableEdit = false,
  videoRefCallback, // New prop to capture video elements
  onVideoPlay,      // New prop to handle video play events
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [editDescription, setEditDescription] = useState("");
  const [editFile, setEditFile] = useState(null);

  const authUser = useSelector((state) => state.auth.user);
  const [showAll, setShowAll] = useState(false);
  const visibleItems = showAll ? items : items?.slice(0, 5);

  const handleAddMediaClick = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFiles([]); // Reset selected files when closing the modal
    setErrorMessage(""); // Clear any error messages
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const imageTypes = ["image/jpeg", "image/jpg", "image/png"];
    const videoTypes = ["video/mp4", "video/quicktime"];
    const maxImageSize = 2 * 1024 * 1024; // 2MB
    const maxVideoSize = 50 * 1024 * 1024; // 50MB

    for (let file of files) {
      if (![...imageTypes, ...videoTypes].includes(file.type)) {
        setErrorMessage("Only JPG, JPEG, PNG, MP4, and MOV files are allowed.");
        return;
      }

      if (imageTypes.includes(file.type) && file.size > maxImageSize) {
        setErrorMessage("Image size must be less than 2MB.");
        return;
      }

      if (videoTypes.includes(file.type) && file.size > maxVideoSize) {
        setErrorMessage("Video size must be less than 50MB.");
        return;
      }
    }

    setErrorMessage("");
    setSelectedFiles(files.map((file) => ({ file, description: "" })));
  };

  const handleDescriptionChange = (index, value) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles[index].description = value;
    setSelectedFiles(updatedFiles);
  };

  const handleSubmit = async () => {
    if (!selectedFiles.length) {
      notify("Please select files to upload.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("user_id", authUser.id);
    selectedFiles.forEach(({ file, description }) => {
      formData.append("files", file);
      formData.append("descriptions", description || "");
    });

    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "/userprofile/rest/gallery/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        notify("Files uploaded successfully!", "success");
        setShowModal(false);
        setSelectedFiles([]); // Reset after successful upload
        fetchGalleries();
      } else {
        notify("Failed to upload files.", "error");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      if (error.response && error.response.data) {
        const errorMsg = Object.values(error.response.data).flat().join(" ");
        notify(`Error: ${errorMsg}`, "error");
      } else {
        notify("An error occurred while uploading files", "error");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (!itemToDelete) return;

    try {
      const response = await axios.delete(
        `/userprofile/rest/gallery/${itemToDelete.id}/`,

      );
      if (response.status === 204) {
        notify("Media deleted successfully!", "success");
        fetchGalleries();
      } else {
        notify("Failed to delete media.", "error");
      }
    } catch (error) {
      console.error("Error deleting media:", error);
      notify("An error occurred while deleting media", "error");
    } finally {
      setShowDeleteModal(false);
      setItemToDelete(null);
    }
  };

  const handleEditClick = (item) => {
    setItemToEdit(item);
    setEditDescription(item.description || "");
    setEditFile(null);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setItemToEdit(null);
    setEditDescription("");
    setEditFile(null);
    setErrorMessage("");
  };

  const handleEditFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const imageTypes = ["image/jpeg", "image/jpg", "image/png"];
    const videoTypes = ["video/mp4", "video/quicktime"];
    const maxImageSize = 2 * 1024 * 1024; // 2MB
    const maxVideoSize = 50 * 1024 * 1024; // 50MB

    if (![...imageTypes, ...videoTypes].includes(file.type)) {
      setErrorMessage("Only JPG, JPEG, PNG, MP4, and MOV files are allowed.");
      return;
    }

    if (imageTypes.includes(file.type) && file.size > maxImageSize) {
      setErrorMessage("Image size must be less than 2MB.");
      return;
    }

    if (videoTypes.includes(file.type) && file.size > maxVideoSize) {
      setErrorMessage("Video size must be less than 50MB.");
      return;
    }

    setErrorMessage("");
    setEditFile(file);
  };

  const handleEditSubmit = async () => {
    if (!itemToEdit) return;

    const formData = new FormData();
    formData.append("description", editDescription);
    // Always include the media_type
    formData.append("media_type", itemToEdit.media_type);

    if (editFile) {
      formData.append("file", editFile);
      // Update media_type based on the new file type
      formData.append(
        "media_type",
        editFile.type.startsWith("image/") ? "image" : "video"
      );
    } else {
      // If no new file is uploaded, include the existing file URL to satisfy the backend
      formData.append("file", itemToEdit.file); // This assumes the backend can handle the existing file URL
    }

    // Log FormData contents for debugging
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    setIsSubmitting(true);
    try {
      const response = await axios.put(
        `/userprofile/rest/gallery/${itemToEdit.id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        notify("Gallery item updated successfully!", "success");
        setShowEditModal(false);
        fetchGalleries();
      } else {
        notify("Failed to update gallery item.", "error");
      }
    } catch (error) {
      console.error("Error updating gallery item:", error);
      if (error.response && error.response.data) {
        const errorMsg = Object.values(error.response.data).flat().join(" ");
        notify(`Error: ${errorMsg}`, "error");
      } else {
        notify("An error occurred while updating the gallery item", "error");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Container fluid className="p-0">
        {items?.length > 0 && (
          <>
            <Row
              className="mb-3 g-2 media-gallery-grid"
              style={{
                display: "grid",
                gridTemplateColumns: `repeat(auto-fit, minmax(250px, 1fr))`,
                gap: "10px",
                width: "100%",
              }}
            >
              {visibleItems.map((item, index) => (
                <Col
                  key={index}
                  className="p-0"
                  style={{ width: "100%", minHeight: "300px" }}
                >
                  <Card
                    className="overflow-hidden position-relative border-0"
                    style={{
                      height: "100%",
                      boxShadow: "none",
                      border: "1px solid #ddd",
                    }}
                  >
                    {authUser?.id === item.user && enableEdit && (
                      <div
                        className="position-absolute"
                        style={{ top: "5px", right: "5px", zIndex: 10 }}
                      >
                        <Button
                          variant="primary"
                          size="sm"
                          className="me-2"
                          onClick={() => handleEditClick(item)}
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteClick(item)}
                        >
                          <FaTrash />
                        </Button>
                      </div>
                    )}

                    {item.media_type === "image" ? (
                      <Card.Img
                        variant="top"
                        src={item.file}
                        style={{
                          objectFit: "cover",
                          height: "200px",
                          width: "100%",
                        }}
                      />
                    ) : (
                      <video
                        ref={(element) => {
                          // Only register if it's a valid video element
                          if (element && typeof element.play === 'function' && typeof element.pause === 'function') {
                            videoRefCallback && videoRefCallback(item.id, element);
                          }
                        }}
                        controls
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                        }}
                        onPlay={() => onVideoPlay && onVideoPlay(item.id)}
                      >
                        <source src={item.file} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}


                    {item.description && (
                      <Card.Body
                        style={{
                          padding: "10px",
                          maxHeight: "80px",
                          overflowY: "auto",
                        }}
                      >
                        <Card.Text>
                          {item.description.length > 50 ? (
                            <>
                              {item.description.slice(0, 50)}...
                              <Button
                                variant="link"
                                onClick={() => setShowDescriptionModal(item)}
                                style={{ padding: 0, fontSize: "0.875rem" }}
                              >
                                Read More
                              </Button>
                            </>
                          ) : (
                            item.description
                          )}
                        </Card.Text>
                      </Card.Body>
                    )}
                  </Card>
                </Col>
              ))}
            </Row>

            {items.length > 5 && !showAll && (
              <div className="text-center mt-3">
                <Button onClick={() => setShowAll(true)} variant="primary">
                  Show All
                </Button>
              </div>
            )}
          </>
        )}

        {enableEdit && (
          <Row>
            <Col xs={12} md={4}>
              <Card
                className="d-flex align-items-center justify-content-center mb-3"
                style={{
                  height: "250px",
                  cursor: "pointer",
                  border: "2px dashed #aaa",
                  boxShadow: "none",
                }}
                onClick={handleAddMediaClick}
              >
                <Card.Body className="text-center d-flex align-items-center">
                  <span>+ Add Media</span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        {/* Add Media Modal */}
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Media</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload Images or Videos</Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  accept="image/jpeg, image/jpg, image/png, video/mp4, video/quicktime"
                  onChange={handleFileChange}
                />
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
              </Form.Group>
              {selectedFiles.map((fileObj, index) => (
                <Form.Group key={index} className="mb-3">
                  <Form.Label>Description for {fileObj.file.name}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter description"
                    value={fileObj.description}
                    onChange={(e) => handleDescriptionChange(index, e.target.value)}
                  />
                </Form.Group>
              ))}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  Uploading... <Spinner size="sm" animation="border" />
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Edit Media Modal */}
        <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Media</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {/* Media Section: Current Media and File Upload Side by Side */}
              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>Current Media</Form.Label>
                    {itemToEdit && (
                      <div>
                        {itemToEdit.media_type === "image" ? (
                          <Card.Img
                            variant="top"
                            src={itemToEdit.file}
                            style={{
                              objectFit: "cover",
                              height: "200px",
                              width: "100%",
                            }}
                          />
                        ) : (
                          <video
                            ref={(element) => {
                              // Store the element reference but don't register it yet
                              // We'll only register after metadata is loaded
                            }}
                            onLoadedMetadata={(e) => {
                              // Now that the video is fully loaded, we can register it
                              const element = e.target;
                              if (element && typeof element.play === 'function' && typeof element.pause === 'function') {
                                console.log("Video metadata loaded, registering gallery video:", itemToEdit.id);
                                videoRefCallback && videoRefCallback(itemToEdit.id, element);
                              }
                            }}
                            controls
                            style={{
                              width: "100%",
                              height: "200px",
                              objectFit: "cover",
                            }}
                            onPlay={() => onVideoPlay && onVideoPlay(itemToEdit.id)}
                          >
                            <source src={itemToEdit.file} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="editFile">
                    <Form.Label>Upload New Image or Video (Optional)</Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/jpeg, image/jpg, image/png, video/mp4, video/quicktime"
                      onChange={handleEditFileChange}
                      disabled={isSubmitting}
                    />
                    {errorMessage && <p className="text-danger">{errorMessage}</p>}
                  </Form.Group>
                  {/* Preview of the new file (if uploaded) */}
                  {editFile && (
                    <div className="mt-2">
                      <p>New Media Preview:</p>
                      {editFile.type.startsWith("image/") ? (
                        <img
                          src={URL.createObjectURL(editFile)}
                          alt="New Media Preview"
                          style={{
                            width: "100%",
                            maxHeight: "200px",
                            objectFit: "contain",
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                          }}
                        />
                      ) : (
                        <video
                          controls
                          style={{
                            width: "100%",
                            maxHeight: "200px",
                            objectFit: "contain",
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                          }}
                        >
                          <source src={URL.createObjectURL(editFile)} type={editFile.type} />
                          Your browser does not support the video tag.
                        </video>
                      )}
                      <Button
                        variant="link"
                        className="text-danger p-0 mt-1"
                        onClick={() => setEditFile(null)}
                      >
                        Remove New File
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>

              {/* Description Section */}
              <Form.Group controlId="editDescription" className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter description"
                  value={editDescription}
                  onChange={(e) => setEditDescription(e.target.value)}
                  disabled={isSubmitting}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleEditSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  Saving... <Spinner size="sm" animation="border" />
                </>
              ) : (
                "Save Changes"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this media?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteConfirm}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Description Modal */}
        <Modal
          show={!!showDescriptionModal}
          onHide={() => setShowDescriptionModal(null)}
          centered
          style={{ maxWidth: "90vw" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              maxHeight: "60vh",
              overflowY: "auto",
              wordWrap: "break-word",
            }}
          >
            {showDescriptionModal?.media_type === "image" ? (
              <img
                src={showDescriptionModal?.file}
                alt="Gallery Item"
                style={{
                  width: "100%",
                  maxHeight: "300px",
                  objectFit: "contain",
                  marginBottom: "15px",
                }}
              />
            ) : (
              <video
                controls
                style={{
                  width: "100%",
                  maxHeight: "300px",
                  objectFit: "contain",
                  marginBottom: "15px",
                }}
                onLoadedMetadata={(e) => {
                  if (showDescriptionModal) {
                    const element = e.target;
                    if (element && typeof element.play === 'function' && typeof element.pause === 'function') {
                      console.log("Modal video metadata loaded, registering:", `modal-${showDescriptionModal.id}`);
                      videoRefCallback && videoRefCallback(`modal-${showDescriptionModal.id}`, element);
                    }
                  }
                }}
                onPlay={() => {
                  if (showDescriptionModal) {
                    onVideoPlay && onVideoPlay(`modal-${showDescriptionModal.id}`);
                  }
                }}
              >
                <source src={showDescriptionModal?.file} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            <div>{showDescriptionModal?.description}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDescriptionModal(null)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default Gallery;