import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Spinner,
  Alert,
  ListGroup,
  ProgressBar,
  Tab,
  Tabs,
  Badge
} from "react-bootstrap";
import { FaUpload, FaFileExcel, FaDownload, FaExclamationTriangle, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import axios from "axios";

const UploadExcelModal = ({ show, handleClose, onUploadComplete, refreshData }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [results, setResults] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [activeTab, setActiveTab] = useState("summary");
  
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!file) {
      setError("Please select an Excel file to upload");
      return;
    }
    
    // Verify file type
    if (!file.name.endsWith('.xlsx')) {
      setError("Only Excel (.xlsx) files are supported");
      return;
    }
    
    setUploading(true);
    setError("");
    setSuccess(false);
    setResults(null);
    setUploadProgress(0);
    
    try {
      // Create form data for the file upload
      const formData = new FormData();
      formData.append('file', file);
      
      // Make API call to upload the file with progress tracking
      const response = await axios.post('/product/products/import_excel/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      
      // Handle successful response
      setSuccess(true);
      setResults(response.data);
      
      // If there are errors, set the active tab to the errors tab
      if (response.data.errors && response.data.errors.length > 0) {
        setActiveTab("errors");
      }
      
      // Callback to parent component
      if (onUploadComplete) {
        onUploadComplete(response.data);
      }
      
      // Refresh data in the parent component if needed
      if (refreshData) {
        refreshData();
      }
    } catch (error) {
      console.error('Error uploading Excel file:', error);
      
      // Handle different error structures
      if (error.response) {
        if (error.response.data.status === 'error' && error.response.data.validation_errors) {
          // Handle validation errors from the new API structure
          setResults({
            errors: error.response.data.validation_errors,
            isValidationError: true
          });
          setActiveTab("errors");
        } else if (error.response.data.error) {
          // Handle general error message
          setError(error.response.data.error);
        } else {
          setError("Server error occurred. Please try again.");
        }
      } else {
        setError("Failed to connect to the server. Please check your internet connection.");
      }
    } finally {
      setUploading(false);
    }
  };

  const resetForm = () => {
    setFile(null);
    setError("");
    setSuccess(false);
    setResults(null);
    setUploadProgress(0);
    setActiveTab("summary");
  };

  const handleModalClose = () => {
    resetForm();
    handleClose();
  };

  const renderResultSummary = () => {
    if (!results) return null;
    
    return (
      <div className="mb-3">
        <h5>Results Summary:</h5>
        <div className="d-flex flex-wrap gap-3">
          <div className="p-3 bg-light rounded border">
            <div className="d-flex align-items-center">
              <FaCheckCircle className="text-success me-2" size={20} />
              <div>
                <h6 className="mb-0">Updated</h6>
                <div className="h4 mb-0">{results.updated || 0}</div>
              </div>
            </div>
          </div>
          <div className="p-3 bg-light rounded border">
            <div className="d-flex align-items-center">
              <FaCheckCircle className="text-primary me-2" size={20} />
              <div>
                <h6 className="mb-0">Created</h6>
                <div className="h4 mb-0">{results.created || 0}</div>
              </div>
            </div>
          </div>
          {results.images_added > 0 && (
            <div className="p-3 bg-light rounded border">
              <div className="d-flex align-items-center">
                <FaCheckCircle className="text-info me-2" size={20} />
                <div>
                  <h6 className="mb-0">Images Added</h6>
                  <div className="h4 mb-0">{results.images_added}</div>
                </div>
              </div>
            </div>
          )}
          {results.images_removed > 0 && (
            <div className="p-3 bg-light rounded border">
              <div className="d-flex align-items-center">
                <FaTimesCircle className="text-warning me-2" size={20} />
                <div>
                  <h6 className="mb-0">Images Removed</h6>
                  <div className="h4 mb-0">{results.images_removed}</div>
                </div>
              </div>
            </div>
          )}
        </div>

        {results.errors && results.errors.length > 0 && (
          <Alert variant="warning" className="mt-3">
            <div className="d-flex align-items-center">
              <FaExclamationTriangle className="me-2" />
              <span>
                {results.errors.length} error{results.errors.length !== 1 ? 's' : ''} occurred during processing.
                <Button
                  variant="link"
                  className="p-0 ms-2"
                  onClick={() => setActiveTab('errors')}
                >
                  View errors
                </Button>
              </span>
            </div>
          </Alert>
        )}
      </div>
    );
  };

  const renderErrorList = () => {
    if (!results || !results.errors || results.errors.length === 0) {
      return (
        <Alert variant="success">
          <FaCheckCircle className="me-2" />
          No errors were encountered during processing.
        </Alert>
      );
    }

    return (
      <div>
        <Alert variant={results.isValidationError ? "danger" : "warning"} className="mb-3">
          <div className="d-flex align-items-center mb-2">
            <FaExclamationTriangle className="me-2" />
            <h6 className="mb-0">
              {results.isValidationError 
                ? "Validation errors prevented processing your file" 
                : "Some errors occurred while processing your file"}
            </h6>
          </div>
          <small>
            {results.isValidationError 
              ? "Please fix these issues and try again. No changes were made to your products." 
              : "The following errors occurred during processing. Some products may have been updated."}
          </small>
        </Alert>

        <ListGroup variant="flush" className="border rounded mb-3" style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {results.errors.map((error, index) => (
            <ListGroup.Item key={index} className="text-danger">
              <div className="d-flex">
                <FaExclamationTriangle className="me-2 mt-1 flex-shrink-0" />
                <div>{error}</div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    );
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Products from Excel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert variant="danger" className="mb-3">
            <div className="d-flex">
              <FaExclamationTriangle className="me-2 mt-1 flex-shrink-0" />
              <div>{error}</div>
            </div>
          </Alert>
        )}
        
        {success || (results && results.errors && results.errors.length > 0) ? (
          <div>
            {success && (
              <Alert variant="success" className="mb-3">
                <FaCheckCircle className="me-2" />
                Excel file uploaded and processed successfully!
              </Alert>
            )}
            
            <Tabs
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              className="mb-3"
            >
              <Tab 
                eventKey="summary" 
                title={
                  <div className="d-flex align-items-center">
                    <span>Summary</span>
                  </div>
                }
              >
                {renderResultSummary()}
              </Tab>
              <Tab 
                eventKey="errors" 
                title={
                  <div className="d-flex align-items-center">
                    <span>Errors</span>
                    {results && results.errors && results.errors.length > 0 && (
                      <Badge bg="danger" className="ms-2">
                        {results.errors.length}
                      </Badge>
                    )}
                  </div>
                }
              >
                {renderErrorList()}
              </Tab>
            </Tabs>
            
            <div className="d-flex justify-content-end">
              <Button variant="secondary" onClick={handleModalClose} className="me-2">
                Close
              </Button>
              <Button variant="primary" onClick={resetForm}>
                Upload Another File
              </Button>
            </div>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <p>
              Upload an Excel file to update existing products or create new ones. 
              The file should include essential columns like Brand ID, Title, Product Category ID, etc.
            </p>
            
            <Form.Group className="mb-3">
              <Form.Label>Select Excel File</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type="file"
                  accept=".xlsx"
                  onChange={handleFileChange}
                  disabled={uploading}
                />
              </div>
              <Form.Text className="text-muted">
                Only .xlsx files are supported. Maximum file size: 10MB.
              </Form.Text>
            </Form.Group>
            
            {file && (
              <Alert variant="info" className="mb-3 d-flex align-items-center">
                <FaFileExcel className="me-2" />
                <div>
                  <strong>Selected file:</strong> {file.name} ({(file.size / 1024).toFixed(2)} KB)
                </div>
              </Alert>
            )}
            
            {uploading && (
              <div className="mb-3">
                <p className="mb-1">Uploading... {uploadProgress}%</p>
                <ProgressBar 
                  animated 
                  now={uploadProgress} 
                  label={`${uploadProgress}%`} 
                  variant="primary"
                />
              </div>
            )}
            
            <Alert variant="info">
              <h6>Important Notes:</h6>
              <ul className="mb-0">
                <li>Products are identified by Brand ID and Title. If a product exists, it will be updated.</li>
                <li>To create new products, include all required fields (Brand ID, Title, Product Category, Type, Subtype).</li>
                <li>Each product must have at least one image URL in the "Image URLs" column.</li>
                <li>Dynamic fields will be completely replaced with values from your Excel file.</li>
                <li>Brochures must be PDF or ZIP files only.</li>
              </ul>
            </Alert>
            
            <div className="d-flex justify-content-between align-items-center mt-4">
              <Button
                type="submit"
                variant="primary"
                disabled={!file || uploading}
              >
                {uploading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Uploading...
                  </>
                ) : (
                  <>
                    <FaUpload className="me-1" /> Upload & Process
                  </>
                )}
              </Button>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UploadExcelModal;