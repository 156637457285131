import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { default_property_image } from "assets";
import { notify } from "utilities/notifications/Notify";
import axios from "axios";
import { useSelector } from "react-redux";
import { FaFile, FaFileImage, FaFilePdf, FaRegHeart } from "react-icons/fa6";
import { FiDownload } from "react-icons/fi";
import { BiDislike } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";
import useFetchMedia from "utilities/customHooks/useFetchMedia";

function ListedPropertyCard({
  id,
  index,
  slug,
  property_name,
  property_img,
  flats,
  layout_types,
  project_type,
  location,
  builder,
  total_rooms,
  is_liked,
  is_viewed,
  pincode,
  city,
  state,
  country,
  property_segment,
  construction_status,
  property_year,
  setProperties,
  properties,
  setSingleRowProperties,
  setPropertyLength,
  possession_month,
  possession_year,
  phone_number,
  locationPath,
  name,
  created_at,
  not_interested,
  notInterestedAction,
  context = "default",
}) {
  const small = useMediaQuery({ query: "(max-width: 576px)" });
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [isLiked, setIsLiked] = useState(is_liked); // Use prop as initial state
  const [isViewed, setIsViewed] = useState(is_viewed);
  const [downloadModal, setDownloadModal] = useState(false);
  const [downloadingBrochures, setDownloadingBrochures] = useState(false);
  const [downloadingCadFiles, setDownloadingCadFiles] = useState(false);
  const [heartLoading, setHeartLoading] = useState(false); // Local loading state for heart icon
  const [showSpinner, setShowSpinner] = useState(false); // Control spinner visibility

  const { image: media_files } = useFetchMedia("property", "property", id, true);

  const handleDownloadAllMedia = async () => {
    if (!media_files || media_files.length === 0) {
      notify("No files available to download", "warning");
      return;
    }
    setDownloadingBrochures(true);
    try {
      const mediaIds = media_files.map((file) => file.id);
      const response = await axios.post(
        "/property/properties/download_media_zip/",
        { ids: mediaIds.join(",") },
        { responseType: "blob", headers: { "Content-Type": "application/json" } }
      );
      const contentType = response.headers["content-type"];
      if (contentType && contentType.includes("application/zip")) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "media_files.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        notify("Files downloaded successfully", "success");
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const errorJson = JSON.parse(reader.result);
          notify(`Download failed: ${errorJson.error || "Unknown error"}`, "error");
        };
        reader.readAsText(response.data);
      }
    } catch (error) {
      notify(`Failed to download: ${error.message || "Unknown error"}`, "error");
    } finally {
      setDownloadingBrochures(false);
    }
  };

  const handleDownloadBrochures = async () => {
    if (!id) {
      notify("No property ID provided", "warning");
      return;
    }
    setDownloadingBrochures(true);
    try {
      const response = await axios.post(
        "/property/properties/download_brochures_zip/",
        { ids: id },
        { responseType: "blob", headers: { "Content-Type": "application/json" } }
      );
      const contentType = response.headers["content-type"];
      if (contentType && contentType.includes("application/zip")) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "property_brochures.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        notify("Brochures downloaded successfully", "success");
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const errorJson = JSON.parse(reader.result);
          notify(`Download failed: ${errorJson.error || "Unknown error"}`, "error");
        };
        reader.readAsText(response.data);
      }
    } catch (error) {
      notify(`Failed to download brochures: ${error.message || "Unknown error"}`, "error");
    } finally {
      setDownloadingBrochures(false);
    }
  };

  const handleDownloadCadFiles = async () => {
    if (!id) {
      notify("No property ID provided", "warning");
      return;
    }
    setDownloadingCadFiles(true);
    try {
      const response = await axios.post(
        "/property/properties/download_cad_files_zip/",
        { ids: id },
        { responseType: "blob", headers: { "Content-Type": "application/json" } }
      );
      const contentType = response.headers["content-type"];
      if (contentType && contentType.includes("application/zip")) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "property_cad_files.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        notify("CAD files downloaded successfully", "success");
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const errorJson = JSON.parse(reader.result);
          notify(`Download failed: ${errorJson.error || "Unknown error"}`, "error");
        };
        reader.readAsText(response.data);
      }
    } catch (error) {
      notify(`Failed to download CAD files: ${error.message || "Unknown error"}`, "error");
    } finally {
      setDownloadingCadFiles(false);
    }
  };

  const handleViewProperty = async () => {
    try {
      const response = await authActions.viewProperty(user.id, id);
      if (response.data) {
        setIsViewed(true);
        setDownloadModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProperty = async () => {
    setHeartLoading(true);
    try {
      const response = await authActions.saveProperty(user.id, id);
      if (response.data) {
        setIsLiked(true);
        notify("Project saved successfully", "success");
      } else {
        notify("Failed to save project, try again later", "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setHeartLoading(false);
    }
  };

  const handleUnsaveProperty = async () => {
    setHeartLoading(true);
    try {
      const response = await authActions.unsaveProperty(user.id, id);
      if (response) {
        if (locationPath?.pathname !== "/architect/projects" && locationPath?.pathname !== "/brand/projects") {
          setProperties((prev) => {
            const updatedProperties = prev.filter((property) => property.saved_property !== id);
            setSingleRowProperties(updatedProperties.slice(0, 3));
            return updatedProperties;
          });
          setPropertyLength((prev) => prev - 1);
        } else {
          setIsLiked(false);
        }
        notify("Project unsaved successfully", "success");
      } else {
        notify("Failed to unsave project, try again later", "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setHeartLoading(false);
    }
  };

  const checkSavedProperty = async () => {
    setHeartLoading(true);
    // Delay showing the spinner for 300ms to avoid flickering for fast responses
    const timer = setTimeout(() => setShowSpinner(true), 300);
    try {
      const response = await authActions.isCheckSavedProperty(user?.id, id);
      setIsLiked(response?.data?.status);
    } catch (error) {
      console.log(error);
    } finally {
      clearTimeout(timer);
      setHeartLoading(false);
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    if (user?.id) {
      checkSavedProperty();
    }
  }, [user?.id]); // Run only when user ID changes (on login/logout)

  const handleNavigation = () => {
    const userTypePrefix = user?.user_type === "brand" ? "brand" : "architect";
    const navigationState = context === "profile"
      ? { state: { fromProfile: true, fromSavedSection: "properties" } }
      : { state: { fromSavedSection: "properties" } };
    navigate(`/${userTypePrefix}/property/${id}`, navigationState);
  };

  // Prevent navigation when interacting with buttons or heart icon
  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <Card
        key={index}
        className="p-0 d-flex flex-column text-start my-4 shadow-none card-hover cursor-pointer"
        style={{ border: "1px solid #E8EAED", minHeight: "227px", borderRadius: 6 }}
        onClick={handleNavigation}
      >
        <Row>
          <Col xs={12} md={4} lg={5} xl={4} xxl={3} className="mb-2 mb-md-0" style={{ flexGrow: 1 }}>
            <Card.Img
              className="bg-gray-25 border-0"
              variant="top"
              src={property_img ?? default_property_image}
              style={{ height: '230px', borderRadius: "6px 0px 0px 6px" }}
            />
          </Col>
          <Col xs={12} md={8} lg={7} xl={8} xxl={9}>
            <Card.Body className="w-100 h-100 d-flex flex-column justify-content-between">
              <div>
                <div className="d-flex flex-row justify-content-between">
                  <Card.Title className="h4 inter-700 hover-text-primary">
                    {property_name} {builder && `/ ${builder}`}
                  </Card.Title>
                  <div className="d-flex justify-content-center align-items-center">
                    <div
                      className={`d-flex justify-content-center align-items-center cursor-pointer me-2 p-0 ${isLiked ? "bg-primary text-white" : "bg-gray-50"}`}
                      style={{ width: 35, height: 35, borderRadius: 17.5 }}
                      onClick={handleStopPropagation}
                      onClickCapture={(e) => {
                        isLiked ? handleUnsaveProperty() : handleSaveProperty();
                      }}
                    >
                      {heartLoading && showSpinner ? <Spinner animation="border" size="sm" /> : <FaRegHeart size={18} />}
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="body-text2 pb-2" style={{ position: "relative" }}>
                    <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                      <span>Number of Flats: {flats}</span>
                    </Card.Text>
                    {layout_types && (
                      <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                        <span>Layout Types: {layout_types ? layout_types : "-"}</span>
                      </Card.Text>
                    )}
                    {project_type && (
                      <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                        <span>Project Type: {project_type}</span>
                      </Card.Text>
                    )}
                    {!pincode ? (
                      <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                        <span>Location: {location}</span>
                      </Card.Text>
                    ) : (
                      <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                        <span>Location: </span>{city}, {state} - {country} {pincode}
                      </Card.Text>
                    )}
                    <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                      <span>Property Segment: {property_segment.replace(/_/g, " ")}</span>
                    </Card.Text>
                    <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                      <span>Construction Status: {construction_status.replace(/_/g, " ")}</span>
                    </Card.Text>
                    <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                      <span>Possession Year: {possession_month ? `${possession_month + ", " + possession_year}` : `${property_year}`}</span>
                    </Card.Text>
                    {total_rooms > 0 && (
                      <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                        <span>Total Inspirations Created: {total_rooms}</span>
                      </Card.Text>
                    )}
                  </div>
                  <div className="d-flex justify-content-end architectbtn m-2" onClick={handleStopPropagation}>
                    <Button
                      className="bg-transparent border-brand-500 text-primary-500 body-text3 me-1"
                      disabled={downloadingBrochures}
                      style={{ borderRadius: 3 }}
                      onClickCapture={handleDownloadBrochures}
                    >
                      {downloadingBrochures ? (
                        <span className="d-flex justify-content-center align-items-center">
                          <Spinner className="me-2" size="sm" /> {!small ? "Downloading.." : ""}
                          <FiDownload size={16} className="ms-2" />
                        </span>
                      ) : (
                        <span className="d-flex justify-content-center align-items-center">
                          {!small ? "Download Brochures" : ""}
                          <FiDownload size={18} className={`${!small ? "ms-2" : ""}`} />
                        </span>
                      )}
                    </Button>
                    <Button
                      className="bg-transparent border-brand-500 text-primary-500 body-text3 me-1"
                      disabled={downloadingCadFiles}
                      style={{ borderRadius: 3 }}
                      onClickCapture={handleDownloadCadFiles}
                    >
                      {downloadingCadFiles ? (
                        <span className="d-flex justify-content-center align-items-center">
                          <Spinner className="me-2" size="sm" /> {!small ? "Downloading.." : ""}
                          <FiDownload size={16} className="ms-2" />
                        </span>
                      ) : (
                        <span className="d-flex justify-content-center align-items-center">
                          {!small ? "Download CAD Files" : ""}
                          <FiDownload size={18} className={`${!small ? "ms-2" : ""}`} />
                        </span>
                      )}
                    </Button>
                    <Button
                      className="d-flex align-items-center bg-brand-500 text-white body-text3"
                      style={{ borderRadius: 3, border: "none" }}
                      onClickCapture={() => navigate(`/${user?.user_type}/addproject/${id}?q=inspirations`)}
                    >
                      Submit Inspiration
                    </Button>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default ListedPropertyCard;