import { createSlice } from "@reduxjs/toolkit";

const spaceSlice = createSlice({
    name: "space",
    initialState: {
        roomType: ""
    },
    reducers: {
        updateSpace: (state, action) => {
            state.roomType = action.payload;
        }
    }
})

export const { updateSpace } = spaceSlice.actions;

export default spaceSlice.reducer