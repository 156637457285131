import { HiXMark } from "react-icons/hi2";
import { useMediaQuery } from "react-responsive";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FiSend } from "react-icons/fi";
import { FaRegHeart } from "react-icons/fa6";
import { MdArrowOutward } from "react-icons/md";
import { useState, useEffect } from "react"; 
import * as authActions from "reduxFiles/actions/authActions";
import { useLocation, useNavigate } from "react-router-dom";
import { notify } from "utilities/notifications/Notify";
import ShareModal from "components/General/ShareModal";
import useFetchMedia from "utilities/customHooks/useFetchMedia";
import LoginModal from "components/Auth/LoginModal";

const RenderItem = ({ room, setShow, inspirationslider = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showShare, setShowShare] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const user = useSelector((state) => state.auth.user);

  const { image, loading, error } = useFetchMedia("project", "room", room?.id);

  console.log(image);

  const handleSaveRoom = async (id) => {
    try {
      const response = await authActions.saveRoom(user.id, id);
      if (response.data) {
        setIsLiked(true);
        notify("Space saved successfully", "success");
      } else {
        notify("Failed to save space, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveRoom = async (id) => {
    try {
      const response = await authActions.unsaveRoom(user.id, id);
      if (response) {
        setIsLiked(false);
        notify("Space unsaved successfully", "success");
      } else {
        notify("Failed to unsave space, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkRoomIfSaved = async (id) => {
    try {
      const response = await authActions.checkRoomIfSaved(user.id, id);
      if (response) {
        setIsLiked(true);
      } else {
        setIsLiked(false); // Ensure isLiked is false if not saved
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleHeartClickInspiration = (e) => {
    e.stopPropagation();
    if (!user) {
      // If user is not logged in, show the login modal
      setShowLoginModal(true);
      return;
    }

    // If user is logged in, proceed with save/unsave logic
    if (isLiked) {
      handleUnsaveRoom(room.id);
    } else {
      handleSaveRoom(room.id);
    }
  };

  // Function to handle heart icon click for the user type condition case
  const handleHeartClickUser = (e) => {
    e.stopPropagation();
    if (isLiked) {
      handleUnsaveRoom(room.id);
    } else {
      handleSaveRoom(room.id);
    }
  };

  // Function to handle successful login
  const handleLoginSuccess = () => {
    if (isLiked) {
      handleUnsaveRoom(room.id);
    } else {
      handleSaveRoom(room.id);
    }
  };

  // Check if the room is already liked when the component mounts
  useEffect(() => {
    if (user && room?.id) {
      checkRoomIfSaved(room.id);
    }
  }, [user, room?.id]);

  console.log(room, "room");
  return (
    <div className="bg-gray">
      <div
        className="position-relative overflow-hidden"
        style={{ height: large ? "100vh" : "50vh" }}
      >
        <div
          className="position-absolute top-0 start-0 overflow-hidden"
          style={{ width: "100%", height: "100%" }}
        >
          <img
            src={image}
            className="w-100 img-fluid"
            style={{
              height: "100%",
              objectFit: "cover",
              borderTopLeftRadius: 12,
              borderBottomLeftRadius: 12,
            }}
          />
        </div>
        <div
          className="position-absolute w-100 overflow-class"
          style={{ top: 0, zIndex: 5 }}
        >
          <div
            className="d-flex flex-column flex-lg-row justify-content-lg-between p-2"
            style={{ background: "rgba(0, 0, 0, 0.4)" }}
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex inter-700 text-white w-100 mb-2">
                {room.room_type_data?.title} / {`${room.project_data?.bhk} bhk`}{" "}
                / {room.project_data?.property_name}
              </div>
              {!large && (
                <div
                  className="d-flex justify-content-center align-items-center bg-gray-50 rounded-circle w-100 h-100 me-3"
                  style={{ width: 30, height: 30 }}
                >
                  <HiXMark
                    size={18}
                    style={{ cursor: "pointer" }}
                    onClick={() => setShow(false)}
                  />
                </div>
              )}
            </div>
            <div className="d-flex justify-content-lg-end align-items-start">
              <Button
                className="d-flex justify-content-center align-items-center social-btn me-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowShare(true);
                }}
              >
                <FiSend size={18} />
              </Button>
              {(user &&
                user?.user_type !== "builder" &&
                user?.user_type !== "architect" &&
                user?.user_type !== "brand") ||
              inspirationslider ? (
                <Button
                  className={`d-flex justify-content-center align-items-center social-btn me-2 ${
                    isLiked ? "bg-primary text-white" : ""
                  }`}
                  onClick={
                    inspirationslider
                      ? handleHeartClickInspiration
                      : handleHeartClickUser
                  }
                >
                  <FaRegHeart size={18} />
                </Button>
              ) : null}
              {!inspirationslider && (
                <Button
                  variant="info"
                  className="text-primary"
                  onClick={() => {
                    if (
                      location.pathname ===
                      `/projects/${room.project_data.slug}`
                    ) {
                      setShow(false);
                    }
                    navigate(`/projects/${room.project_data.slug}`);
                  }}
                  style={{ width: 140 }}
                >
                  Full Project <MdArrowOutward size={18} />
                </Button>
              )}
            </div>
          </div>
        </div>
        <div
          className="position-absolute"
          style={{ bottom: 16, left: 6, zIndex: 1 }}
        >
          <div className="d-flex align-items-end">
            <Button className="bg-gray-25 text-dark border-0 px-3 p-2 rounded inter-700 body-text2 mx-2">
              {room.room_type_data?.title}
            </Button>
            <Button className="bg-gray-25 text-dark border-0 px-3 p-2 rounded inter-700 body-text2">
              {room.project_data?.property_name}
            </Button>
          </div>
        </div>
        <ShareModal
          show={showShare}
          onHide={() => setShowShare(false)}
          id={room?.id?.id}
          image={image}
          title={`${room?.room_type_data?.title} / ${room?.project_data?.project_name}`}
          // urlPath={} // #defaultUrl
        />
        <LoginModal
          showLoginModal={showLoginModal}
          setShowLoginModal={setShowLoginModal}
          isHomeOwner={isHomeOwner}
          setIsHomeOwner={setIsHomeOwner}
          onLoginSuccess={handleLoginSuccess}
        />
      </div>
    </div>
  );
};

export default RenderItem;