import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { ad_1 } from "assets";
import ListedPropertyCard from "components/Cards/ListedPropertyCard";
import * as authActions from "reduxFiles/actions/authActions";
import { FaBuildingColumns, FaPlus } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useScrollBottom, InfiniteScroll } from "components";
import MultipleAds from "components/Advertisements/MultipleAds";
import BrandCardShimmer from "components/Cards/BrandCardShimmer";
import ListedPropertyShimmer from "./ListedPropertySimmer";

function ListedProperties({
  companyName,
  properties,
  setProperties,
  projectType,
  constructionStatus,
  propertySegment,
  sortBy,
  setPropertyLength,
  location,
  q,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const locationPath = useLocation()


  const [noNextItem, setNoNextItem] = useState(true);
  const bottom = useScrollBottom();

  const loadPaginatedActiveProperties = async () => {
    setLoading(true);
    let url = `/property/custom_property_search/?status=APPROVED`;
    const response = await authActions.loadCustomPaginatedProperties(
      url,
      projectType,
      constructionStatus,
      propertySegment,
      sortBy,
      location,
      q
    );

    // console.log(response);
    if (response) {
      setProperties(response);
    }
    setLoading(false);
  };

  const loadMoreActiveProperties = async () => {
    const propertyList = properties.results;
    if (properties.next) {
      setNoNextItem(false);
      const newProperties = await authActions.loadCustomPaginatedProperties(
        properties.next
      );

      console.log(newProperties);
      if (newProperties) {
        const newResults = propertyList.concat(newProperties.results);
        newProperties.results = newResults;
        setProperties(newProperties);
      }
    } else {
      setNoNextItem(true);
    }
  };

  const notInterestedAction = async (propId) => {
    try {
      const response = await authActions.notInterestedProperty(user.id, propId);
      if (response.data) {
        loadPaginatedActiveProperties();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user.id) loadPaginatedActiveProperties();
  }, [
    user,
    projectType,
    constructionStatus,
    propertySegment,
    sortBy,
    location,
    q,
  ]);

  console.log(properties, "properties");

  useEffect(() => {
    if (bottom) {
      loadMoreActiveProperties();
    }
  }, [bottom]);

  return (
    <Row>
      <Col lg={9}>
        {loading ? (
          <div className="container">
            {[...Array(4)].map((_, index) => (
              <div key={index}>
                <ListedPropertyShimmer />
              </div>
            ))}
          </div>
        ) : (
          <>
            {properties.count > 0 ? (
              <>
                {properties?.results?.map((property, index) => (
                  <ListedPropertyCard
                    key={index}
                    index={index}
                    {...property}
                    setProperties={setProperties}
                    setPropertyLength={setPropertyLength}
                    builder={property?.builder_data?.company_name}
                    project_type={property?.project_type_data?.title}
                    notInterestedAction={notInterestedAction}
                    locationPath={locationPath}
                    slug = {property.slug}
                  />
                ))}
                <InfiniteScroll bottom={bottom} noNextItem={noNextItem} />
              </>
            ) : (
              <div
                className="py-4 d-flex flex-column justify-content-center align-items-center"
                style={{ minHeight: "70vh" }}
              >
                {/* <div className="h2 inter-600">Welcome, {companyName}!</div> */}
                <div
                  className="d-flex justify-content-center align-items-center my-3"
                  style={{
                    backgroundColor: "#E8EAED",
                    width: 100,
                    height: 86,
                    borderRadius: 6,
                  }}
                >
                  <FaBuildingColumns size={30} />
                </div>
                <div className="h3 inter-600">
                  There are no properties listed yet.
                </div>
                <div className="body-title text-gray-600">
                  Add your first custom project to start building an inspiration
                  library.
                </div>
                <div className="mt-3">
                  <Button
                    className="d-flex align-items-center text-white"
                    style={{ borderRadius: 3 }}
                    onClick={() => navigate("/architect/addproject")}
                  >
                    <FaPlus className="me-2" /> Add Project
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </Col>
      <Col lg={3}>
        {/* <div className="my-4 position-relative">
          <div className="w-100">
            <img className="img-fluid w-100" src={ad_1} alt="Ads" />
          </div>
          <div className="position-absolute" style={{ top: 10, right: 10 }}>
            <Button variant="info" className="text-gray-900 fw-bold">
              AD
            </Button>
          </div>
        </div> */}
        <MultipleAds
          endPoint={user?.user_type === "brand" ? "brand_ads" : "architect_ads"}
        />
      </Col>
    </Row>
  );
}

export default ListedProperties;
