import ProjectCard from "components/Cards/ProjectCard";
import ProjectCardShimmer from "components/Cards/ProjectCardShimmer";
import React from "react";
import { MdSentimentDissatisfied } from "react-icons/md";

const InspirationsList = ({ inspirations, listType, loading }) => {
  if (loading) {
    return (
      <div className="row">
        {[...Array(3)].map((_, index) => (
          <div key={index} className="col-md-4 mb-4">
            <ProjectCardShimmer />
          </div>
        ))}
      </div>
    );
  }

  if (inspirations.length === 0) {
    const title =
      listType === "sample"
        ? "No Sample Flats Found."
        : "No Inspirations Found.";
    const description =
      listType === "sample"
        ? "Add your first sample flat!"
        : "Add your first project!";

    return (
      <div
        className="py-4 d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "40vh" }}
      >
        <div
          className="d-flex justify-content-center align-items-center my-3"
          style={{
            backgroundColor: "#E8EAED",
            width: 100,
            height: 100,
            borderRadius: 6,
          }}
        >
          <MdSentimentDissatisfied size={30} />
        </div>
        <div className="h3 inter-600">{title}</div>
        <div className="body-title text-gray-600">{description}</div>
      </div>
    ); 
  }
  return (
    <div className="row">
      {inspirations?.map((inspiration) => (
        <div key={inspiration.id} className="col-md-4 mb-4">
          <ProjectCard
            item={inspiration}
            id={inspiration.id}
            slug={inspiration.slug}
            project_name={inspiration.project_name}
            bhk={inspiration.bhk}
            builder_name={inspiration.builder_name}
            architect={inspiration.architect}
            property_name={inspiration.property_name}
            brand={inspiration.brand}
            brand_name={inspiration.company_name}
            is_sample_flat={inspiration?.is_sample_flat}
            roomId={
              inspiration.rooms && inspiration.rooms.length > 0
                ? inspiration.rooms[0].id
                : null
            }
          />
        </div>
      ))}
    </div>
  );
};

export default InspirationsList;
