import React from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";
import axios from "axios";

import { Navbar, Footer, ScrollToTop } from "components";

import Home from "pages/Home/Home";
import PartnerSignup from "pages/SignUp/PartnerSignup";
import Signup from "pages/SignUp/Signup";

import Consultation from "pages/Consultaion/Consultation";

import ProjectList from "pages/Projects/ProjectList";
import SpacesList from "pages/Projects/SpacesList";
import ProjectDetails from "pages/Projects/ProjectDetails";
import BrandsList from "pages/Professionals/BrandsList";
import BrandDetails from "pages/Professionals/BrandDetails";
import ProfessionalDetails from "pages/Professionals/ProfessionalDetails";
import WhyChooseUs from "pages/WhyChooseUs/WhyChooseUs";

// Customer Logged in Pages
import LoginHomepage from "pages/Home/LoginHomepage";
import UserProfile from "pages/User/UserProfile";
import ProjectTracker from "pages/User/ProjectTracker";
import Saved from "pages/User/Saved";

// Builder Logged in Pages
import BuilderProperties from "pages/Builder/BuilderProperties";
import AddProperty from "pages/Builder/AddProperty";
import EditProperty from "pages/Builder/EditProperty";
import InspirationsCreated from "pages/Builder/InspirationsCreated";
import BuilderPersonalProfile from "pages/Builder/Account/BuilderPersonalProfile";
import BuilderPublicProfile from "pages/Builder/Account/PublicProfile";

// Architect Logged in Pages
import DesignerLanding from "pages/Designer/DesignerLanding";
import AddProject from "pages/ProfessionalSpecific/AddProject";
import ProductLibrary from "pages/ProfessionalSpecific/ProductLibrary";
import ArchitectPublicProfile from "pages/ProfessionalSpecific/ArchitectPublicProfile";
import ProfessionalSettings from "pages/ProfessionalSpecific/ProfessionalSettings";

import BuilderDetails from "pages/Professionals/BuilderDetails";
import ArchitectsList from "pages/Professionals/ArchitectsList";
import BuildersList from "pages/Professionals/BuildersList";
import ProfessionalsList from "pages/Professionals/ProfessionalsList";
import AddProduct from "pages/ProfessionalSpecific/AddProducts";
import ProfessionalArchitect from "pages/Home/ProfessionalArchitect";

import PageNotFound from "pages/Errors/PageNotFound";
import DesignerProfile from "pages/Designer/Account/DesignerProfile";
import ProfessionalBrand from "pages/Home/ProfessionalBrand";
import ProfessionalBuilder from "pages/Home/ProfessionalBuilder";
import ProfessionalOthers from "pages/Home/ProfessionalOthers";
import AboutUs from "pages/AboutUs/AboutUs";
import ContactUs from "pages/ContactUs/ContactUs";
import TermsAndPrivacy from "pages/TermsAndPrivacy/Terms";
import BlogsPage from "pages/BlogsPage/BlogsPage";
import CareerPage from "pages/CareerPage/CareerPage";
import TradeMarkPage from "pages/TrademarkPage/TradeMarkPage";
import ProjectDrawing from "pages/ProjectDrawing/ProjectDrawing";
import EditProjects from "pages/ProfessionalSpecific/EditProjects";
import Terms from "pages/TermsAndPrivacy/Terms";
import Privacy from "pages/TermsAndPrivacy/Privacy";
import PropertyDetailsPage from "pages/Professionals/PropertyDetailsPage";
import Breadcrumb from "components/Breadcrumb";
import FavouritePage from "pages/Professionals/FavouritePage";

const Routers = () => {
  const token = useSelector((state) => state.auth.token);
  // const user = useSelector((state) => state.authReducer.user);
  // const isAuthenticated = useSelector(
  //   (state) => state.authReducer.isAuthenticated
  // );
  // const isAdmin = useSelector((state) => state?.authReducer?.user?.is_admin);
  // const isSuperuser = useSelector((state) => state?.authReducer?.user?.is_superuser);
  // const deviceWidth = useCurrentWidth();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);

  // console.log("isAuthenticated, user:", isAuthenticated, user);

  // axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "https://apis.interiokeys.com";
  // axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "http://192.168.29.58:8000/";
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;



  if (token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }

  return (
    <Router>
      <ScrollToTop />
      <Container fluid className="px-0 inter user-select-none">
        <Navbar />
        {/* <Breadcrumb />   */} 
        <Routes>
          {isAuthenticated && user?.user_type === "customer" ? (
            <Route path="" element={<LoginHomepage />} />
          ) : user?.user_type === "builder" ? (
            <Route>
              <Route path="" element={<Navigate to="/builder/properties" />} />
              <Route path="builder" element={<Outlet />}>
                <Route path="inspirations" element={<InspirationsCreated />}>
                  <Route
                    path=":propertySlug"
                    element={<InspirationsCreated />}
                  />
                </Route>
                <Route path="addproject" element={<AddProject />} />
                <Route path="editproject" element={<EditProjects />}>
                  <Route path=":projectId" element={<EditProjects />} />
                </Route>
                <Route path="properties" element={<BuilderProperties />}>
                  <Route path=":propertyId" element={<EditProperty />} />
                </Route>
                <Route path="addproperty" element={<AddProperty />} />
                <Route path="account" element={<Outlet />}>
                  <Route path="settings" element={<BuilderPersonalProfile />} />
                  {/* Comment this - start */}
                  {/* <Route path="settings" element={<BuilderPublicProfile />} /> */}
                  {/* Comment this - end */}
                </Route>
                <Route
                  path="property/:propertyId"
                  element={<PropertyDetailsPage />}
                />
              </Route>
            </Route>
          ) : user?.user_type === "architect" ? (
            <Route>
              <Route path="" element={<Navigate to="/architect/projects" />} />
              <Route path="architect" element={<Outlet />}>
                <Route path="projects" element={<DesignerLanding />} />
                <Route path="addproject" element={<AddProject />}>
                  <Route path=":propertyId" element={<AddProject />} />
                </Route>
                <Route path="editproject" element={<EditProjects />}>
                  <Route path=":projectId" element={<EditProjects />} />
                </Route>
                <Route path="product-library" element={<ProductLibrary />} />
                <Route path="account" element={<Outlet />}>
                  {/* Comment this - start */}
                  {/* <Route path="settings" element={<ArchitectPublicProfile />} /> */}
                  <Route path="settings" element={<ProfessionalSettings />} />
                  {/* Comment this - end */}
                  <Route path="profile" element={<DesignerProfile />} />
                  <Route path="favourite" element={<FavouritePage />} />
                </Route>
                <Route
                  path="property/:propertyId"
                  element={<PropertyDetailsPage />}
                />
              </Route>
            </Route>
          ) : user?.user_type === "brand" ? (
            <Route>
              <Route path="" element={<Navigate to="/brand/projects" />} />
              <Route path="brand" element={<Outlet />}>
                <Route path="projects" element={<DesignerLanding />} />
                <Route path="addproject" element={<AddProject />}>
                  <Route path=":propertyId" element={<AddProject />}>
                    <Route path=":status" element={<AddProject />} />
                  </Route>
                </Route>
                <Route path="editproject" element={<EditProjects />}>
                  <Route path=":projectId" element={<EditProjects />} />
                </Route>
                <Route path="addproduct" element={<AddProduct />} />
                <Route path="product-library" element={<ProductLibrary />} />
                <Route path="account" element={<Outlet />}>
                  {/* Comment this - start */}
                  <Route path="settings" element={<ProfessionalSettings />} />
                  <Route path="favourite" element={<FavouritePage />} />
                  {/* Comment this - end */}
                  <Route path="profile" element={<BrandDetails />} />
                </Route>
                <Route
                  path="property/:propertyId"
                  element={<PropertyDetailsPage />}
                />
              </Route>
            </Route>
          ) : (
            <Route path="" element={<Home />} />
            // <Route path="" element={<ProjectList />}>
            //   <Route path=":slug" element={<ProjectDetails />} />
            // </Route>
          )}
          {isAuthenticated && (
            <Route path="user" element={<Outlet />}>
              <Route path="profile" element={<UserProfile />} />
              <Route path="saved" element={<Saved />} />
              <Route path="projecttracker" element={<ProjectTracker />} />
              <Route path="favourite" element={<FavouritePage />} />
            </Route>
          )}
          <Route path="partner-builder" element={<ProfessionalBuilder />} />
          <Route path="partner-brand" element={<ProfessionalBrand />} />
          <Route path="partner-architect" element={<ProfessionalArchitect />} />
          <Route path="partner-others" element={<ProfessionalOthers />} />
          <Route path="partner-signup" element={<PartnerSignup />} />
          <Route path="signup" element={<Signup />} />
          <Route path="consultation" element={<Consultation />}>
            <Route path=":refType/:refId" element={<Consultation />} />
          </Route>
          <Route path="properties" element={<ProjectList />}>
            <Route path=":propertyId" element={<ProjectDetails />} />
          </Route>
          <Route path="projects" element={<ProjectList />}>
            <Route path=":slug" element={<ProjectDetails />} />
          </Route>
          <Route path="spaces" element={<SpacesList />} />
          <Route path="brands" element={<BrandsList />}>
            <Route path=":slug" element={<BrandDetails />} />
          </Route>
          <Route path="professionals" element={<Outlet />}>
            {/* <Route path="builders" element={<BuildersList />} /> */}
            <Route path="builders" element={<BuildersList />}>
              <Route path=":detailedSlug" element={<BuilderDetails />} />
            </Route>
            <Route path="architects" element={<ArchitectsList />}>
              <Route path=":detailedSlug" element={<ProfessionalDetails />} />
            </Route>
            <Route path=":slug" element={<ProfessionalsList />}>
              <Route path=":detailedSlug" element={<ProfessionalDetails />} />
            </Route>
            {/* <Route path=":slug" element={<ProfessionalsList />} /> */}
            <Route path=":slug" element={<BrandDetails />} />
          </Route>
          <Route path="/why-choose-us" element={<WhyChooseUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/careers" element={<CareerPage />} />
          <Route path="/trademark" element={<TradeMarkPage />} />
          <Route path="/project-drawings" element={<ProjectDrawing />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
};

export default Routers;
