import { useEffect, useState } from "react";
import * as authActions from "reduxFiles/actions/authActions";
import ProjectCard from "components/Cards/ProjectCard";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ShareModal from "components/General/ShareModal";
import { useSelector } from "react-redux";
import PropertyCard from "components/Cards/PropertyCard";
import { IoChevronBack } from "react-icons/io5";
import { useScrollBottom, InfiniteScroll } from "components";
import userImg from "../../assets/navbar/user.webp";

const InspirationsCreated = () => {
  const { propertySlug } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [propertyData, setPropertyData] = useState(null);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showShareProject, setShowShareProject] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);
  const [noNextItem, setNoNextItem] = useState(true);

  const bottom = useScrollBottom();

  const fetchProperty = async () => {
    try {
      const response = await authActions.fetchPropertyBySlug(propertySlug);
      if (response.data) {
        setPropertyData(response.data?.results[0]);
      }
    } catch (error) {
      console.log("Error fetching property:", error);
    }
  };

  const fetchProjectsByProperty = async () => {
    if (!propertyData?.id) return;

    setLoading(true);
    try {
      const response = await authActions.fetchProjectByProperty(propertyData.id);
      console.log("response?.data",response?.data)
      if (response?.data) {
        setProjects(response.data.results || []);
        setNextUrl(response.data.next || null);
        setNoNextItem(!response.data.next);
      }
    } catch (error) {
      console.log("Error fetching projects:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreProjects = async () => {
    if (!nextUrl || loading) return;

    setLoading(true);
    try {
      const response = await authActions.loadProjects(nextUrl);
      if (response) {
        setProjects((prevProjects) => [...prevProjects, ...response.results]);
        setNextUrl(response.next || null);
        setNoNextItem(!response.next);
      }
    } catch (error) {
      console.log("Error loading more projects:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (propertySlug) {
      fetchProperty();
    }
  }, [propertySlug]);

  useEffect(() => {
    if (propertyData?.id) {
      fetchProjectsByProperty();
    }
  }, [propertyData]);

  useEffect(() => {
    if (bottom && !noNextItem) {
      loadMoreProjects();
    }
  }, [bottom]);

  return (
    <Container fluid>
      <Container className="mt-5 pt-5">
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <IoChevronBack size={18} />
          <span className="ms-2 body-text2 inter-500">Back to My Properties</span>
        </div>
        {propertyData ? (
          <PropertyCard
            {...propertyData}
            key={propertyData?.id}
            builder={propertyData?.builder_data?.company_name}
            project_type={propertyData?.project_type_data?.title}
            allFunction={false}
          />
        ) : (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "200px" }}>
            <Spinner />
          </div>
        )}
        <div className="body-text1 inter-600 text-gray-800 mt-5">
          {propertyData?.property_name} Inspirations ({projects?.length || 0})
        </div>
        <div className="mb-3">
          {loading && projects.length === 0 ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ minHeight: "50vh" }}
            >
              <Spinner />
            </div>
          ) : projects?.length > 0 ? (
            <div className="d-flex flex-column align-items-center" style={{ minHeight: "80vh" }}>
              <Row className="row-cols-3 w-100">
                {projects?.map((item, index) => (
                  <Col
                    key={index}
                    className={`d-flex flex-column py-2 mb-2 justify-content-start ${
                      index % 3 === 0 ? "ps-0" : index % 3 === 2 ? "pe-0" : ""
                    }`}
                  >
                    <ProjectCard
                      {...item}
                      id={item.id}
                      project_name={item?.project_name}
                    //   image={item?.rooms?.[0]?.media?.[0]?.file ||userImg } 
                      is_sample_flat={item?.is_sample_flat}
                      roomId={item?.rooms[0]?.id}
                      architect={item?.company_name}
                      location={item?.property_data?.location}
                      builders={item?.property_data?.builder_data?.company_name}
                      className="mx-0 flex-grow"
                    />
                    <ShareModal
                      show={showShareProject}
                      onHide={() => setShowShareProject(false)}
                      title={`${item?.project_name}/${item?.bhk}/${item?.property_name}`}
                      urlPath={
                        item?.slug !== "undefined"
                          ? `projects/${item?.slug}`
                          : null
                      }
                    />
                  </Col>
                ))}
              </Row>
              <InfiniteScroll bottom={bottom} noNextItem={noNextItem} />
              {loading && projects.length > 0 && (
                <div className="d-flex justify-content-center align-items-center w-100 mt-3">
                  <Spinner />
                </div>
              )}
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center bg-gray-25 rounded w-100"
              style={{ height: "60vh" }}
            >
              No projects available
            </div>
          )}
        </div>
      </Container>
    </Container>
  );
};

export default InspirationsCreated;