import { useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import { BuilderAccountEditForm } from "components";
import BuilderThoughtForm from "components/Forms/BuilderThoughtForm";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  fetchBuildersThoughs,
  fetchTeamMembers,
  fetchGallery,
} from "reduxFiles/actions/authActions";
import Gallery from "components/Gallery/Gallery";
import TeamMemberList from "components/TeamMember/TeamMemberList";
import { notify } from "utilities/notifications/Notify";
import * as authActions from "reduxFiles/actions/authActions";
import { FaEye } from "react-icons/fa6";
import BuilderProfilePreview from "pages/ProfessionalSpecific/BuilderProfilePreview";

const BuilderPersonalProfile = () => {
  const user = useSelector((state) => state.auth.user);
  const [thoughts, setThoughts] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);
  const [media, setMedia] = useState([]);
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showProfilePreview, setShowProfilePreview] = useState(false);
  const { pathname } = useLocation();
  const formRef = useRef(null); // Initialize formRef

  // Fetch builder's thoughts
  useEffect(() => {
    const fetchData = async () => {
      const response =
        pathname.includes("builder") && (await fetchBuildersThoughs(user.id));
      setThoughts(response.data.results[0]);
    };

    fetchData();
  }, [user.id, pathname]);

  // Fetch team members
  useEffect(() => {
    const fetchTeamData = async () => {
      const response = await fetchTeamMembers({ user_id: user.id });
      if (response) {
        setTeamMembers(response.data);
      }
    };

    fetchTeamData();
  }, [user.id]);

  // Function to fetch gallery media
  const fetchGalleryData = async () => {
    const response = await fetchGallery({ user_id: user.id });
    if (response) {
      setMedia(response.data); // Update the media state
    }
  };

  // Fetch gallery media on component mount
  useEffect(() => {
    fetchGalleryData();
  }, [user.id]);

  // Handle adding a team member
  const handleAddTeamMember = async (formData) => {
    setIsSubmitting(true);
    try {
      // Append user_id to the form data
      formData.append("user_id", user.id);

      const response = await authActions.addTeamMember(formData);
      if (response.status === 201) {
        notify("Team member added successfully", "success");
        setShowAddTeamModal(false);

        // Refresh the team members list by passing user_id
        const teamResponse = await fetchTeamMembers({ user_id: user.id });
        if (teamResponse) {
          setTeamMembers(teamResponse.data); // Update the team members state
        }
      } else {
        notify("Failed to add team member", "error");
      }
    } catch (error) {
      console.error("Error while adding team member:", error);
      notify("Error while adding team member", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle deleting a team member
  const handleDeleteMember = async (id) => {
    setIsSubmitting(true);
    try {
      const response = await authActions.deleteTeamMember(id);
      if (response.status === 204) {
        notify("Team member deleted successfully", "success");

        // Refresh the team members list by passing user_id
        const teamResponse = await fetchTeamMembers({ user_id: user.id });
        if (teamResponse) {
          setTeamMembers(teamResponse.data); // Update the team members state
        }
      } else {
        notify("Failed to delete team member", "error");
      }
    } catch (error) {
      console.error("Error while deleting team member:", error);
      notify("Error while deleting team member", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditTeamMember = async (id, formData) => {
    setIsSubmitting(true);
    try {
      const response = await authActions.updateTeamMember(id, formData);
      if (response.status === 200) {
        notify("Team member updated successfully", "success");
        const teamResponse = await fetchTeamMembers({ user_id: user.id });
        if (teamResponse) setTeamMembers(teamResponse.data);
      } else {
        notify("Failed to update team member", "error");
      }
    } catch (error) {
      console.error("Error while updating team member:", error);
      notify("Error while updating team member", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleViewPublicProfile = () => {
    setShowProfilePreview(true);
  };
  
  return (
    <>
      {showProfilePreview ? (
        <BuilderProfilePreview 
          isPreview={true}
          onBack={() => setShowProfilePreview(false)}
          previewUser={user}
        />
      ) : (
        <Container fluid className="mt-5 pt-4 px-0" style={{ minHeight: "100vh" }}>
          <Container>
            {/* Header with View Public Profile Button */}
            <div className="d-flex justify-content-between align-items-center py-3 my-4">
              <h3 className="inter-600 text-gray-900">Settings</h3>
              <Button 
                variant="outline-primary" 
                className="d-flex align-items-center gap-2"
                onClick={handleViewPublicProfile}
              >
                <FaEye /> View Public Profile
              </Button>
            </div>

            {/* Builder Account Edit Form */}
            <BuilderAccountEditForm user={thoughts} />

            {/* Builder Thought Form */}
            <BuilderThoughtForm thoughts={thoughts} />

            {/* Team Members Section */}
            <div className="mb-4">
              <div className="h4 inter-600">Our Team</div>
              <TeamMemberList
                teamMembers={teamMembers}
                handleDeleteMember={handleDeleteMember}
                handleAddTeamMember={handleAddTeamMember}
                handleEditTeamMember={handleEditTeamMember}
                showAddTeamModal={showAddTeamModal}
                setShowAddTeamModal={setShowAddTeamModal}
                isSubmitting={isSubmitting}
                enableEdit={user?.user_type === "builder"} // Enable editing for builders
              />
            </div>

            {/* Gallery Section */}
            <div className="mb-4">
              <div className="h4 inter-600">Gallery</div>
              <Gallery
                items={media}
                fetchGalleries={fetchGalleryData} // Pass the fetchGalleryData function
                enableEdit={user?.user_type === "builder"}
              />
            </div>
          </Container>

          {/* Add Team Member Modal */}
          <Modal show={showAddTeamModal} onHide={() => setShowAddTeamModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Team Member</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form ref={formRef}>
                {" "}
                {/* Assign formRef to the Form */}
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    type="text"
                    name="role"
                    placeholder="Enter role"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Profile Picture</Form.Label>
                  <Form.Control type="file" name="image" accept="image/*" />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowAddTeamModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  const formData = new FormData(formRef.current); // Extract form data
                  handleAddTeamMember(formData); // Pass form data to the handler
                }}
                disabled={isSubmitting}
              >
                {isSubmitting ? <Spinner size="sm" animation="border" /> : "Submit"}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      )}
    </>
  );
};

export default BuilderPersonalProfile;