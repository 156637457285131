import { useRef } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import { truncateText } from "utilities/truncateText";
import BrandAboutUsCards from "./BrandAboutUsCards";

// items should contain image and title
const Banners = ({ items, speed }) => {
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  let sliderRef = useRef(null);
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: large ? 6 : 2,
    slidesToScroll: 1,
  };

  return (
    <Container>
      <Row className="mb-4">
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {items.map((item, index) => (
            <div key={index} className="pe-3">
              <BrandAboutUsCards item={item} />
            </div>
          ))}
        </Slider>
      </Row>
    </Container>
  );
};

export default Banners;
