import { ad_1, ad_2, all_products } from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import BrandCard from "components/Cards/BrandCard";
import Pagination from "components/Pagination";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { CgSortAz } from "react-icons/cg";
import { IoClose } from "react-icons/io5";
import { FaFilter, FaTags } from "react-icons/fa6";
import MultipleAds from "components/Advertisements/MultipleAds";
import BrandCardShimmer from "components/Cards/BrandCardShimmer";
import { FiChevronDown } from "react-icons/fi";

const BrandsList = () => {
  const { slug } = useParams();
  const [brandCount, setBrandCount] = useState(0)
  const [brands, setBrands] = useState([]);
  const [allBrands, setAllBrands] = useState([])
  const [categoryName, setCategoryName] = useState("")
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [loadingBrands, setLoadingBrands] = useState(true); // Loading state
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [brandCategories, setBrandCategories] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [next, setNext] = useState("");
  const categoryChangedRef = useRef(false);
  const latestCategoryRef = useRef("");
  const scrollRef = useRef();
  const latestRequestParamsRef = useRef("");
  const navigate = useNavigate();
  const location = useLocation();

  let searchParams = new URLSearchParams(document.location.search);

  const [brand, setBrand] = useState(searchParams.get("brand") || "");
  const brandId = searchParams.get("brandId");

  const [brandCategory, setbrandCategory] = useState(
    searchParams.get("brand_category") || ""
  );

  const fetchBrandByCategoryName = async (category) => {
    latestCategoryRef.current = category;
    try {
      setLoadingBrands(true)
      const response = await authActions.fetchBrandByCategoryName(category);
      // Only update state if this response corresponds to the latest category
      if (latestCategoryRef.current === category) {
        setAllBrands(response.data);
      }
    } catch (error) {
      console.error(error);
    }finally{
      setLoadingBrands(false)
    }
  };

  const fetchBrands = async (page) => {
    setLoading(true);
    try {
      const cleanParam = (param) => param && param !== "null" ? param : "";
      const queryString = new URLSearchParams({
        brand_category: cleanParam(brandCategory),
        ordering: cleanParam(sortBy),
        brand: cleanParam(brand),
        page,
      }).toString();

      const response = await authActions.fetchBrandsPagewise(queryString);
      if (response.data) {
        setBrandCount(response?.data?.count)
        setBrands((prev) => [...prev, ...response.data.results]);
        const totalFetched = page * 12;
        setHasMore(totalFetched < response.data.count);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchBrandCategories = async () => {
    try {
      const response = await authActions.fetchBrandCategoriesNoPagination();
      if (response?.data) {
        setBrandCategories(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let searchParams = new URLSearchParams(document.location.search);

    let category = searchParams.get("brand_category");
    let sortBy = searchParams.get("sort_by");
    let brandParam = searchParams.get("brand");

    if (category) {
      setbrandCategory(category);
    }

    if (sortBy) setSortBy(sortBy);
    if (brandParam) setBrand(brandParam);
  }, []);



  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const categoryFromUrl = searchParams.get("brand_category") || "";
    const newBrand = searchParams.get("brand") || "";
    const triggeredBy = searchParams.get("triggeredBy") || "";
    if (categoryFromUrl !== brandCategory) {
      setbrandCategory(categoryFromUrl);
      setCurrentPage(1);
      setBrands([]);
    }

    if (categoryFromUrl && triggeredBy === "footer") {
      setCategoryName(categoryFromUrl);
      fetchBrandByCategoryName(categoryFromUrl ? categoryFromUrl : "");
    }

    if (newBrand !== brand) {
      setBrand(newBrand);
      setCurrentPage(1);
      setBrands([]);
    }
  }, [location.search]);




  const onChangeFilters = (value, type) => {
    if (type === "category") {
      setbrandCategory(value);
      setBrand("");
      const selectedCategory = brandCategories.find((b) => b.id === value);
      const selectedCategoryName = selectedCategory ? selectedCategory.name : "";
      setCategoryName(selectedCategoryName);

      categoryChangedRef.current = true;
  
      fetchBrandByCategoryName(selectedCategoryName);
    } else if (type === "sort_by") {
      setSortBy(value);
    } else if (type === "brand") {
      setBrand(value);
    }
  
    const queryString = new URLSearchParams({
      brand_category: type === "category" ? value : brandCategory,
      ordering: type === "sort_by" ? value : sortBy,
      brand: type === "brand" ? value : type === "category" ? "" : brand,
    }).toString();
  
    navigate(`/brands?${queryString}`);
  
    setCurrentPage(1); 
    setBrands([]);
  };
  

  /* const loadPaginatedBrands = async (url, sortBy, brandCategory, brand) => {
    setLoading(true);
    try {
      const response = await authActions.fetchBrandsByFilter(
        url,
        sortBy,
        brandCategory,
        brand
      );
      setBrandCount(response?.count)
      if (response?.results) {
        setBrands((prev) => [...prev, ...response.results]);
        const totalFetched = currentPage * 12;
        setHasMore(totalFetched < response?.count);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }; */


  const loadPaginatedBrands = async (url, sortBy, brandCategory, brand) => {
    setLoading(true);
    
    // Create a unique identifier for this request based on its parameters
    const requestParams = JSON.stringify({ url, sortBy, brandCategory, brand });
    latestRequestParamsRef.current = requestParams;
    
    try {
      const response = await authActions.fetchBrandsByFilter(
        url,
        sortBy,
        brandCategory,
        brand
      );
      
      // Only update state if this response corresponds to the latest request
      if (latestRequestParamsRef.current === requestParams) {
        setBrandCount(response?.count);
        if (response?.results) {
          setBrands((prev) => [...prev, ...response.results]);
          const totalFetched = currentPage * 12;
          setHasMore(totalFetched < response?.count);
        }
      } else {
        console.log("Ignoring outdated response for", { sortBy, brandCategory, brand });
      }
    } catch (error) {
      console.log(error);
    }
    
    // Only update loading state if this is still the latest request
    if (latestRequestParamsRef.current === requestParams) {
      setLoading(false);
    }
  };

  const loadPaginatedBrandsFromBrandId = async (brandId) => {
    setLoading(true);
    try {
      const response = await authActions.fetchBrandsByFilter(
        "/user/rest/brand-info/",
        null,
        brandId,
        null
      );

      if (response?.results) {
        setBrands(response?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!brandId && next !== null) {
      loadPaginatedBrands(
        "/user/rest/brand-info/",
        sortBy,
        brandCategory,
        brand
      );
      setCurrentPage(1);
    }
  }, [sortBy, brandCategory, brand]);

  useEffect(() => {
    if (brandId) {
      loadPaginatedBrandsFromBrandId(brandId);
      setCurrentPage(1);
    }
    // alert();
  }, [brandId]);

  useEffect(() => {
    fetchBrandCategories();
  }, []);

  useEffect(() => {
    if (!brandId && currentPage > 1) {
      fetchBrands(currentPage);
    }
    // Only fetch if the change did not come directly from onChangeFilters
    /* if (!categoryChangedRef.current) {
      fetchBrandByCategoryName(categoryName);
    } */
    // Reset the flag after the effect runs
    categoryChangedRef.current = false;
  }, [currentPage, brandCategory, sortBy, brand]);

  const filteredBrands = useMemo(() => {
    if (!searchTerm) return allBrands;
    return allBrands.filter((b) =>
      b.company_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, allBrands]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasMore && !loading) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (scrollRef.current) {
      observer.observe(scrollRef.current);
    }

    return () => {
      if (scrollRef.current) {
        observer.unobserve(scrollRef.current);
      }
    };
  }, [hasMore, loading]);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  if (slug) {
    return <Outlet />;
  }

  return (
    <Container className="mt-5 pt-5">
      <section className="d-flex flex-column alignt-items-center">
        <div className="body-title my-4">
          <span className="inter-600">
            Brands Available {brandCount ? brandCount : "0"}
          </span>
        </div>
        <>
          {/* Mobile Filter Icon */}
          <div className="d-block d-md-none">
            <FaFilter
              size={20}
              className="cursor-pointer my-2"
              onClick={handleToggleFilters}
            />
          </div>

          {/* Filters (Visible on desktop and toggled on mobile) */}
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${showFilters ? "" : "d-none d-md-flex" // Show filters when clicked on mobile or always on desktop
              }`}
          >
            <div className="row g-2 mb-3">
              {/* Brand Category */}
              <div className="col-12 col-md-auto">
                <label htmlFor="brandCategorySelect" className="mb-1 text-gray-700">
                  Brand Category
                </label>
                {brandCategory && brandCategory !== "null" ? (
                  <div
                    className="d-flex justify-content-center align-items-center filter-container flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {brandCategories.find((b) => b.id === brandCategory)?.name}
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        onChangeFilters(null, "category");
                        setCategoryName("");
                      }}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <div className="form-group">
                    <Form.Select
                      id="brandCategorySelect"
                      className="bg-transparent text-gray-700 me-2 cursor-pointer"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      onChange={(e) => {
                        const selectedId = e.target.value;
                        const selectedCategory = brandCategories.find(
                          (b) => b.id === selectedId
                        );
                        // Update the categoryName state with the selected category's name
                        setCategoryName(selectedCategory ? selectedCategory.name : "");
                        // Pass the selected category id to onChangeFilters
                        onChangeFilters(selectedId, "category");
                      }}
                      value={brandCategory && brandCategory !== "null" ? brandCategory : ""}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {brandCategories.map((b, index) => (
                        <option key={index} value={b.id}>
                          {b.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                )}
              </div>

              {/* Brand */}
              <div className="col-12 col-md-auto position-relative" ref={dropdownRef}>
                <label htmlFor="brandSearchInput" className="mb-1 text-gray-700">
                  Brand
                </label>
                {brand && brand !== "null" ? (
                  <div
                    className="d-flex filter-container justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {allBrands?.find((f) => f.slug === brand)?.company_name || brand}
                    </span>
                    {brand && (
                      <IoClose
                        size={24}
                        className="cursor-pointer ms-2"
                        onClick={() => onChangeFilters(null, "brand")}
                      />
                    )}
                  </div>
                ) : (
                  <div className="form-group">

                    <div className="position-relative">
                      <input
                        id="brandSearchInput"
                        type="text"
                        className="form-control bg-transparent text-gray-700 me-2 cursor-pointer pe-4"
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                        placeholder="Select"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onFocus={() => setShowDropdown(true)}
                      />

                      {/* Dropdown Icon */}
                      <FiChevronDown
                        className="position-absolute"
                        style={{
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          color: "#6c757d",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowDropdown(!showDropdown)}
                      />

                      {showDropdown && (
                        <div
                          className="position-absolute w-100 bg-white shadow-sm mt-1"
                          style={{
                            maxHeight: "200px",
                            overflowY: "auto",
                            borderRadius: 3,
                            border: "1px solid #BDC1C6",
                            zIndex: 30,
                          }}
                        >
                          {loading ? (
                            <div className="p-2 text-muted text-center">
                              Loading...
                            </div>
                          ) : filteredBrands.length > 0 ? (
                            filteredBrands.map((b, index) => (
                              <div
                                key={index}
                                className="p-2 cursor-pointer text-gray-700"
                                onClick={() => {
                                  onChangeFilters(b.slug, "brand");
                                  setSearchTerm("");
                                  setShowDropdown(false);
                                }}
                              >
                                {b.company_name}
                              </div>
                            ))
                          ) : (
                            <div className="p-2 text-muted">No results found</div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Sort By */}
            <div className="col-12 col-md-auto">
              <div className="form-group">
                <label htmlFor="sortBySelect" className="mb-1 text-gray-700">
                  Sort By
                </label>
                <div className="custom-select-wrapper d-flex align-items-center">
                  <Form.Select
                    id="sortBySelect"
                    className="bg-transparent text-gray-700 cursor-pointer custom-select"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    onChange={(e) => onChangeFilters(e.target.value, "sort_by")}
                    value={sortBy || ""}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="-created_at">Featured (New to Old)</option>
                    <option value="created_at">Featured (Old to New)</option>
                  </Form.Select>
                  {sortBy ? (
                    <IoClose
                      size={24}
                      className="cursor-pointer ms-2 custom-close"
                      onClick={() => onChangeFilters(null, "sort_by")}
                    />
                  ) : (
                    <CgSortAz size={24} className="custom-dropdown-icon" />
                  )}
                </div>
              </div>
            </div>
          </div>

        </>

        {/* <Row>
          <Col lg={8} xl={9}>
            {loading ? (
              <div className="container">
                {[...Array(4)].map((_, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <BrandCardShimmer />
                  </div>
                ))}
              </div>
            ) : brands?.length > 0 ? (
              <div className="d-flex flex-column align-items-center w-100">
                {brands?.map((brand, index) => {
                  return (
                    <BrandCard
                      id={brand.user}
                      slug={brand.slug}
                      companyName={brand.company_name}
                      image={brand?.image}
                      // image={brand.user_data?.profile_picture}
                      brandCategories={brand?.brand_category_data}
                      warranty={brand?.warranty}
                      rating={brand?.rating}
                      averagePricing={brand?.average_pricing}
                      minPricing={brand?.min_pricing}
                      maxPricing={brand?.max_pricing}
                      created_at={brand?.created_at}
                    />
                  );
                })}
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center align-items-center bg-gray-25 rounded"
                style={{ height: 160 }}
              >
                <FaTags size={40} className="mb-2 text-muted" />
                <h5>No Brands Available</h5>
              </div>
            )}
          </Col>
          <Col lg={4} xl={3} className=" mt-4">
            <MultipleAds endPoint={"customer_ads"} />
          </Col>
        </Row> */}
        <Row>
          <Col lg={8} xl={9}>
            {loading && currentPage === 1 ? (
              <div className="container">
                {[...Array(4)].map((_, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <BrandCardShimmer />
                  </div>
                ))}
              </div>
            ) : brands?.length > 0 ? (
              <div className="d-flex flex-column align-items-center w-100">
                {brands.map((brand, index) => (
                  <BrandCard
                    key={index}
                    id={brand.user}
                    slug={brand.slug}
                    companyName={brand.company_name}
                    image={brand?.image}
                    brandCategories={brand?.brand_category_data}
                    warranty={brand?.warranty}
                    rating={brand?.rating}
                    averagePricing={brand?.average_pricing}
                    minPricing={brand?.min_pricing}
                    maxPricing={brand?.max_pricing}
                    created_at={brand?.created_at}
                  />
                ))}
                {/* Sentinel element for Intersection Observer */}
                <div ref={scrollRef} style={{ height: "20px" }} />
                {loading && (
                  <div className="container">
                    {[...Array(2)].map((_, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <BrandCardShimmer />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center align-items-center bg-gray-25 rounded"
                style={{ height: 160 }}
              >
                <FaTags size={40} className="mb-2 text-muted" />
                <h5>No Brands Available</h5>
              </div>
            )}
          </Col>
          <Col lg={4} xl={3} className="mt-4">
            <MultipleAds endPoint={"customer_ads"} />
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default BrandsList;
