import ShareModal from "components/General/ShareModal";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import userImg from "../../assets/navbar/user.webp";

const AllProfessionalCard = ({
  id,
  name,
  professional_id,
  slug,
  company_name,
  user_data,
  type,
  location,
  rating,
  project_count,
  expertise,
  project_names,
  setSingleRowProfessionals,
  professionals,
  image,
  isLiked,
  setIsLiked,
  imgHeight = "300px",
  onClick,
  setProfessionals,
  setProfessionalLength,
  context, // Add context prop
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [hover, setHover] = useState(false);
  const [showShare, setShowShare] = useState(false);

  // Format project names to limit display to 2 items with ellipsis if more
  const formatProjectNames = (names) => {
    if (!names || names === "No projects") return "No projects";

    // Handle both string and array formats
    const projectsArray = Array.isArray(names)
      ? names
      : names.split(',').map(item => item.trim());

    if (projectsArray.length <= 2) {
      return projectsArray.join(', ');
    } else {
      return `${projectsArray.slice(0, 3).join(', ')}...`;
    }
  };

  const checkProfessionalIfSaved = async () => {
    try {
      const response = await authActions.checkProfessionalIfSaved(user.id, professional_id);
      if (response) setIsLiked(response.status);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProfessional = async () => {
    try {
      const response = await authActions.saveProfessional(user.id, professional_id);
      if (response.data) {
        setIsLiked(true);
        notify("Professional saved successfully", "success");
      } else {
        notify("Failed to save professional, try again later", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProfessional = async () => {
    try {
      const response = await authActions.unsaveProfessional(user.id, professional_id);
      if (response) {
        setProfessionals((prev) => {
          const updatedProf = prev.filter((prof) => prof.id !== id);
          setSingleRowProfessionals(updatedProf.slice(0, 3));
          return updatedProf;
        });
        setProfessionalLength((prev) => prev - 1);
        notify("Professional unsaved successfully", "success");
      } else {
        notify("Failed to unsave professional, try again later", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user && professional_id) checkProfessionalIfSaved();
  }, [user, professional_id, isLiked]);

  const handleNavigation = () => {
    const navigationState = context === "profile"
      ? { state: { fromProfile: true, fromSavedSection: "professionals" } }
      : { state: { fromSavedSection: "professionals" } };
    navigate(`/professionals/${type}/${slug}`, navigationState);
  };

  return (
    <>
      <Card
        className={`cursor-pointer w-100 p-2 p-md-3 border rounded shadow-none overflow-hidden position-relative d-flex flex-column flex-lg-row p-2 p-md-3 text-start mb-4 border w-100 mt-2 ${hover ? "border-gray-300" : "border-gray-50"
          }`}
        onClick={onClick || handleNavigation}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="d-flex justify-content-center align-items-center mx-auto mx-lg-0" style={{ width: "200px" }}>
          <Card.Img
            className="img-fluid"
            variant="top"
            src={image || userImg}
            style={{ width: "140px", height: "140px", objectFit: "cover", maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
        <Card.Body className="p-2 d-flex flex-column flex-lg-row justify-content-between align-items-lg-start align-items-center" onClick={handleNavigation}>
          <div className="ps-3">
            <Card.Title
              className={`inter-700 mb-0 ${hover ? "text-primary-500" : "text-gray-900"}`}
              style={{ fontSize: "16px" }}
            >
              {company_name}
            </Card.Title>
            <div className="inter-600 d-flex align-items-center">
              Ratings: {rating ? <div className="d-flex align-items-center">{rating} <FaStar color="#D95656" className="ms-1" /></div> : "Unavailable"}
            </div>
            <div className="body-text2 inter-400 d-flex flex-column align-items-start">
              <span className="ms-0">Architects and Interior Designers</span>
              <span className="ms-0">Inspirations Created: {formatProjectNames(project_names)}</span>
            </div>
          </div>
          <div className="d-flex gap-2 mt-3 mt-lg-0 justify-content-center justify-content-lg-start" style={{ zIndex: 999, right: 15, top: 10 }}>
            <Button
              className="d-flex justify-content-center align-items-center share-like-btn p-2"
              style={{ backgroundColor: "#F1F3F4", border: "none" }}
              onClick={(e) => {
                e.stopPropagation();
                setShowShare(true);
              }}
            >
              <FiSend size={20} color="#3C4043" />
            </Button>
            {user && user?.user_type !== "builder" && user?.user_type !== "architect" && user?.user_type !== "brand" && (
              <Button
                className={`d-flex justify-content-center align-items-center share-like-btn ms-2 p-0 ${isLiked ? "bg-primary text-white" : ""}`}
                onClick={(e) => {
                  e.stopPropagation();
                  isLiked ? handleUnsaveProfessional() : handleSaveProfessional();
                }}
              >
                <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : "#3C4043"} />
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={id}
        image={image}
        title={company_name}
        urlPath={`/professionals/${slug}`}
      />
    </>
  );
};

export default AllProfessionalCard;