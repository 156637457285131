import React, { useState } from "react";
import { Card, Image, Button, Modal } from "react-bootstrap";
import { FaTrash, FaEdit } from "react-icons/fa";
import { useSelector } from "react-redux";
import userImg from "../../assets/navbar/user.webp";

const TeamMember = ({
  id,
  name,
  role,
  image,
  user,
  handleDelete,
  handleEdit, // Now directly triggers TeamMemberList's edit flow
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const authUser = useSelector((state) => state.auth.user);

  const handleDeleteClick = () => setShowConfirmModal(true);
  const handleCancelDelete = () => setShowConfirmModal(false);
  const handleConfirmDelete = async () => {
    await handleDelete(id);
    setShowConfirmModal(false);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    handleEdit(); // Call TeamMemberList's handler directly
  };

  return (
    <div className="inter-400 pe-4 position-relative">
      {authUser?.id === user && (
        <div
          className="position-absolute d-flex justify-content-center align-items-center gap-2"
          style={{
            top: "5px",
            right: "5px",
            zIndex: 2,
            backgroundColor: "#f8f9fa",
            borderRadius: "50%",
            padding: "5px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <FaEdit
            onClick={handleEditClick}
            className="text-primary cursor-pointer"
            style={{ fontSize: "16px" }}
            title="Edit Team Member"
          />
          <FaTrash
            onClick={handleDeleteClick}
            className="text-secondary cursor-pointer"
            style={{ fontSize: "16px" }}
            title="Delete Team Member"
          />
        </div>
      )}

      <Image
        src={image || userImg}
        roundedCircle
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid #E8EAED",
          objectFit: "cover",
        }}
        alt={`${name}'s profile`}
      />
      <Card.Body>
        <Card.Title className="body-title" style={{ marginTop: "10px", marginLeft: "11px" }}>
          {name}
        </Card.Title>
        <Card.Text className="text-muted body-text-2" style={{ marginLeft: "11px" }}>
          {role}
        </Card.Text>
      </Card.Body>

      <Modal show={showConfirmModal} onHide={handleCancelDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this team member?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TeamMember;