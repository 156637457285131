import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  InputGroup,
  Row,
  Spinner,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { FaSave } from "react-icons/fa";
import { LuMail } from "react-icons/lu";
import { FiEdit3, FiRefreshCcw, FiTrash2 } from "react-icons/fi";
import * as authActions from "reduxFiles/actions/authActions";
import { userUpdate } from "reduxFiles/reducer/authSlices";
import { notify } from "utilities/notifications/Notify";
import { useLocation } from "react-router-dom";
import userImg from "../../assets/navbar/user.webp";

import { default_pfp } from "assets";
import MultiSelect from "./MultiSelect";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function AccountEditForm({ user }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const form = useRef(null);
  const brochureInputRef = useRef(null);
  const bannerInputRef = useRef(null);
  const imgRef = useRef(null);
  const [isEdit, setIsEdit] = useState(false);
  const [hover, setHover] = useState(false);
  const [bannerHover, setBannerHover] = useState(false);
  const [productCategories, setProductCategories] = useState(null);
  const [professionalDetails, setProfessionalDetails] = useState([]);
  const [intrestOfServices, setIntrestOfServices] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedWarrentyDuration, setSelectedWarrentyDuration] = useState("");
  const [loading, setLoading] = useState(false);
  const [brandCategories, setBrandCategories] = useState([]);
  const [validate, setValidate] = useState(false);
  const [selectedBrandCategories, setSelectedBrandCategories] = useState([]);
  const [showJobCost, setShowJobCost] = useState(false);
  const [pincodeError, setPincodeError] = useState("");
  const [isPincodeLoading, setIsPincodeLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [previewPic, setPreviewPic] = useState("");
  const [brochure, setBrochure] = useState(null);
  const [brochureLoading, setBrochureLoading] = useState(false);
  const [bannerPreview, setBannerPreview] = useState("");
  const [showCropModal, setShowCropModal] = useState(false);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [expertiseTypes, setExpertiseTypes] = useState([]);

  const [formValues, setFormValues] = useState({
    full_name: "",
    mobile: "",
    pincode: "",
    address: "",
    city: "",
    state: "",
    country: "",
  });

  const [isInitialized, setIsInitialized] = useState(false);

  const requiredFields = {
    full_name: "Name",
    mobile: "Phone Number",
    address: "Address",
    pincode: "Pincode",
    city: "City",
    state: "State",
    country: "Country",
  };

  useEffect(() => {
    console.log("User object:", user);
    console.log("User type:", user?.user_data?.user_type);
    console.log("User ID:", user?.user_data?.id);
  }, [user]);

  useEffect(() => {
    if (user?.user_data && !isInitialized) {
      setFormValues({
        full_name: user.user_data.full_name || "",
        mobile: user.user_data.mobile || "",
        pincode: user.user_data.pincode || "",
        address: user.user_data.address || "",
        city: user.user_data.city || "",
        state: user.user_data.state || "",
        country: user.user_data.country || "",
      });
      setPreviewPic(user.user_data.profile_picture || "");
      setIsInitialized(true);
    }
  }, [user, isInitialized]);

  const getPincode = async (pincode) => {
    setIsPincodeLoading(true);
    setPincodeError("");

    try {
      const response = await authActions.fetchPincode(pincode);
      if (response && response.District && response.State && response.Country) {
        setFormValues((prev) => ({
          ...prev,
          city: response.District,
          state: response.State,
          country: response.Country,
        }));
        setFieldErrors((prev) => ({
          ...prev,
          city: false,
          state: false,
          country: false,
        }));
      } else {
        setPincodeError("Invalid pincode. Please enter a valid pincode.");
        setFormValues((prev) => ({
          ...prev,
          city: "",
          state: "",
          country: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching pincode details:", error);
      setPincodeError(
        "Failed to fetch location details. Please check the pincode."
      );
      setFormValues((prev) => ({
        ...prev,
        city: "",
        state: "",
        country: "",
      }));
    } finally {
      setIsPincodeLoading(false);
    }
  };

  const handlePincodeChange = (e) => {
    const newPincode = e.target.value;
    if (/^\d{0,6}$/.test(newPincode)) {
      setFormValues((prev) => ({ ...prev, pincode: newPincode }));
      setFieldErrors((prev) => ({ ...prev, pincode: !newPincode }));
      if (newPincode.length === 6) {
        getPincode(newPincode);
      } else {
        setFormValues((prev) => ({
          ...prev,
          city: "",
          state: "",
          country: "",
        }));
        setPincodeError("");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    if (value.trim() && fieldErrors[name]) {
      setFieldErrors((prev) => ({ ...prev, [name]: false }));
    }
  };

  const fetchArchitectsById = async () => {
    try {
      const response = await authActions.fetchArchitectByUser(
        user?.user_data?.id
      );
      if (response.data) {
        if (response.data.results[0]?.id) {
          const architectData = response.data.results[0];
          console.log(response?.data?.results[0]);
          setProfessionalDetails(response.data.results[0]);
          setBannerPreview(response?.data?.results[0]?.architect_banner || "");
          if (architectData.interest_of_service) {
            setIntrestOfServices(architectData.interest_of_service);
            setShowJobCost(true);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBrandByUser = async () => {
    try {
      const response = await authActions.fetchBrandByUser(user?.user_data?.id);
      if (response.data) {
        if (response.data?.results[0]) {
          setProfessionalDetails(response.data.results[0]);
          setSelectedCategory(response?.data?.results[0]?.product_category[0]);
          setSelectedWarrentyDuration(
            response?.data?.results[0]?.warranty_duration
          );
          setBannerPreview(response?.data?.results[0]?.brand_banner || "");
          setSelectedBrandCategories(
            response?.data?.results[0]?.brand_category_data.map((item) => {
              return { value: item.id, label: item.name };
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await authActions.fetchProductCategories();
      if (response?.data) {
        setProductCategories(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchBrandCategories = async () => {
    try {
      const response = await authActions.fetchBrandCategoriesNoPagination();
      if (response?.data) {
        setBrandCategories(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchExpertiseTypes = async () => {
    try {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      if (!baseUrl) {
        throw new Error("REACT_APP_API_BASE_URL is not defined in .env");
      }
      const userType = user?.user_data?.user_type === "architect" ? "architect" : "brand";
      const url = `${baseUrl}/user/rest/expertise-type/?all=true&user_type=${userType}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch expertise types");
      }

      const data = await response.json();
      const expertiseData = Array.isArray(data) ? data : data.results || [];
      setExpertiseTypes(expertiseData);
    } catch (error) {
      console.error("Error fetching expertise types:", error);
      notify("Error fetching expertise types", "error");
    }
  };

  const handleProfilePicUpdate = async (e) => {
    const file = e.target.files[0];
    if (!file) return notify("No file selected", "error");

    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 2 * 1024 * 1024;

    if (!allowedTypes.includes(file.type)) {
      return notify("Only JPG, JPEG, and PNG files are allowed", "error");
    }

    if (file.size > maxSize) {
      return notify("File size must be less than 2MB", "error");
    }

    const currentProfilePic = previewPic;

    const previewUrl = URL.createObjectURL(file);
    setPreviewPic(previewUrl);

    const formData = new FormData();
    formData.append("profile_picture", file);

    try {
      const response = await authActions.updateProfilePic(
        user?.user_data?.id,
        formData
      );
      if (response.data) {
        notify("Profile Picture Updated!", "success");
        dispatch(userUpdate(response.data));
      }
    } catch (error) {
      console.log(error);
      setPreviewPic(currentProfilePic);
      notify("Failed to update profile picture", "error");
    }
  };

  const handleBannerClick = () => {
    if (bannerPreview) {
      setImageSrc(bannerPreview);
      setShowCropModal(true);
    } else {
      document.getElementById("bannerUpload").click();
    }
  };

  const handleBannerUpdate = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 4 * 1024 * 1024;

    if (!allowedTypes.includes(file.type)) {
      return notify("Only JPG, JPEG, and PNG files are allowed", "error");
    }

    if (file.size > maxSize) {
      return notify("File size must be less than 4MB", "error");
    }

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImageSrc(reader.result?.toString() || null);
      setShowCropModal(true);
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: "px",
          width: 1843,
          height: 300,
        },
        1843 / 300,
        width,
        height
      ),
      width,
      height
    );
    setCrop(crop);
  };

  const getCroppedImg = async () => {
    if (!completedCrop || !imgRef.current) return;

    const canvas = document.createElement("canvas");
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = 1843;
    canvas.height = 300;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      imgRef.current,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      1843,
      300
    );

    const currentBanner = bannerPreview;
    const previewUrl = canvas.toDataURL("image/jpeg");
    setBannerPreview(previewUrl);

    try {
      const blob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/jpeg")
      );
      const formData = new FormData();
      formData.append("banner_image", blob, "banner.jpg");

      const response = await authActions.updateBannerPic(
        user?.user_data?.id,
        formData
      );
      if (response.data) {
        notify("Banner Updated!", "success");
        dispatch(userUpdate(response.data));
      }
    } catch (error) {
      console.log(error);
      setBannerPreview(currentBanner);
      notify("Failed to update banner", "error");
    }

    setShowCropModal(false);
    setImageSrc(null);
    setCrop(null);
    setCompletedCrop(null);
    if (bannerInputRef.current) bannerInputRef.current.value = null;
  };

  const handleDeleteBanner = async () => {
    try {
      const response = await authActions.deleteBannerPic(user?.user_data?.id);
      if (response.status === 200) {
        notify("Banner deleted successfully!", "success");
        setBannerPreview("");
        setImageSrc(null);
        setCrop(null);
        setCompletedCrop(null);
        setShowCropModal(false);
        dispatch(userUpdate({ ...user.user_data, banner: null }));
      } else {
        notify("Failed to delete banner", "error");
      }
    } catch (error) {
      console.error("Error deleting banner:", error);
      notify("Failed to delete banner", "error");
    }
  };

  const handleModalClose = () => {
    setShowCropModal(false);
    setImageSrc(null);
    setCrop(null);
    setCompletedCrop(null);
    if (bannerInputRef.current) bannerInputRef.current.value = null;
  };

  const uploadBrochure = async (userId, file) => {
    if (!file) {
      console.log("No file provided to uploadBrochure.");
      return;
    }

    const formData = new FormData();
    formData.append("brochure", file);

    try {
      console.log("Uploading brochure for user ID:", userId);
      console.log("File being uploaded:", file);
      const response = await dispatch(
        authActions.uploadBrandBrochure(userId, formData)
      );
      console.log("Upload brochure response:", response);

      if (
        response.status === 200 &&
        response.data?.message === "Brochure uploaded successfully"
      ) {
        notify("Brochure uploaded successfully!", "success");
        setProfessionalDetails((prev) => ({
          ...prev,
          brochure: file.name,
        }));
        setBrochure(null);
        if (brochureInputRef.current) {
          brochureInputRef.current.value = null;
        }
      } else {
        console.log("Upload failed with response:", response);
        notify(
          response.data?.message || "Failed to upload brochure. Please try again.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error uploading brochure:", error);
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Failed to upload brochure. Please try again.";
      notify(errorMessage, "error");
    }
  };

  const handleBrochureChange = async (e) => {
    const file = e.target.files[0];
    console.log("Selected brochure file:", file);
    if (!file) {
      console.log("No file selected.");
      setBrochure(null);
      return notify("No file selected", "error");
    }

    const allowedTypes = ["pdf", "zip"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    console.log("File extension:", fileExtension);
    if (!allowedTypes.includes(fileExtension)) {
      console.log("Invalid file type. Allowed types:", allowedTypes);
      setBrochure(null);
      return notify("Only PDF and ZIP files are allowed for brochure", "error");
    }

    const maxSize = 100 * 1024 * 1024;
    console.log("File size:", file.size, "Max size:", maxSize);
    if (file.size > maxSize) {
      console.log("File size exceeds limit.");
      setBrochure(null);
      return notify("Brochure file size must be less than 100MB", "error");
    }

    setBrochure(file);
    console.log("Brochure state updated:", file);
    // Removed the success notification here to avoid duplicate notifications
    // notify("Brochure file selected successfully", "success");

    // Upload the brochure immediately if the user is a brand
    console.log("Checking user type for brochure upload...");
    console.log("User type:", user?.user_data?.user_type);
    console.log("User ID:", user?.user_data?.id);
    if (user?.user_data?.user_type === "brand") {
      if (!user?.user_data?.id) {
        console.log("User ID is undefined. Cannot upload brochure.");
        notify("User ID not found. Cannot upload brochure.", "error");
        return;
      }
      setBrochureLoading(true);
      try {
        console.log("Calling uploadBrochure with file:", file);
        await uploadBrochure(user?.user_data?.id, file);
      } catch (error) {
        console.error("Brochure upload failed in handleBrochureChange:", error);
        // No need to notify here since uploadBrochure already handles notifications
      } finally {
        setBrochureLoading(false);
      }
    } else {
      console.log("Brochure upload skipped. User is not a brand user.");
      notify("Only brand users can upload a brochure", "error");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    setValidate(true);

    if (isPincodeLoading) {
      notify("Fetching location details. Please wait...", "info");
      setLoading(false);
      return;
    }

    const newFieldErrors = {};
    let hasErrors = false;
    const errorMessages = [];

    for (const [fieldName, displayName] of Object.entries(requiredFields)) {
      const value = formValues[fieldName];
      if (!value || value.toString().trim() === "") {
        newFieldErrors[fieldName] = true;
        hasErrors = true;
        errorMessages.push(`${displayName} is required`);
      } else {
        newFieldErrors[fieldName] = false;
      }
    }

    if (
      user?.user_data?.user_type === "brand" &&
      selectedBrandCategories.length === 0
    ) {
      hasErrors = true;
      errorMessages.push("Brand Categories are required");
    }

    setFieldErrors(newFieldErrors);

    if (hasErrors) {
      notify(`Please fill in all required fields:\n${errorMessages.join("\n")}`, "error");
      setLoading(false);
      return;
    }

    const formData = new FormData(form.current);
    formData.append("id", user?.user_data?.id);

    formData.append(
      "brand_category",
      selectedBrandCategories.map((item) => item.value)
    );

    if (selectedCategory) {
      formData.set("product_category", selectedCategory);
    }

    // Update formData with controlled values
    Object.entries(formValues).forEach(([key, value]) => {
      formData.set(key, value);
    });

    let data = {};
    formData.forEach((value, key) => {
      // Trim the value and replace empty strings or whitespace-only strings with null
      const trimmedValue = typeof value === "string" ? value.trim() : value;
      data[key] = trimmedValue === "" ? null : trimmedValue;
    });

    try {
      const response = await authActions.updateProfile(data.id, data);
      if (response.data) {
        notify("Profile Updated Successfully!", "success");
        dispatch(userUpdate(response.data));
        setIsEdit(false);
        setFormValues({
          full_name: response.data.full_name || "",
          mobile: response.data.mobile || "",
          pincode: response.data.pincode || "",
          address: response.data.address || "",
          city: response.data.city || "",
          state: response.data.state || "",
          country: response.data.country || "",
        });
      } else {
        notify("Error while updating profile!", "error");
      }
    } catch (error) {
      console.error(error);
      notify("Failed to update profile. Please try again.", "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (location.hash === "#edit") {
      setIsEdit(true);
    }
  }, [location]);

  useEffect(() => {
    fetchCategories();
    fetchBrandCategories();
    fetchExpertiseTypes();
    if (user?.user_data?.user_type === "architect") {
      fetchArchitectsById();
    }

    if (user?.user_data?.user_type === "brand") {
      fetchBrandByUser();
    }
  }, [user]);

  const handleChange = (selectedOptions) => {
    setSelectedBrandCategories(selectedOptions);
  };

  return (
    <>
      <Form
        ref={form}
        onSubmit={handleUpdate}
        className="border border-gray-100 mb-4"
        style={{ borderRadius: 6 }}
      >
        <div className="px-3 mt-4">
          <Form.Label className="mb-1 body-text2 inter-500 mb-2">
            Banner Edit{" "}
            <span className="text-muted">(Perfect dimensions: 1843 x 300px)</span>
          </Form.Label>
          <Card
            className="d-flex justify-content-center position-relative overflow-hidden cursor-pointer shadow-none p-0 mb-3"
            style={{
              width: "100%",
              height: 250,
              border: "1px dashed #BDC1C6",
              borderRadius: 6,
            }}
          >
            <Image
              className="p-0"
              style={{
                borderRadius: 6,
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
              src={bannerPreview || default_pfp}
              alt="banner"
              onMouseEnter={() => setBannerHover(true)}
              onMouseLeave={() => setBannerHover(false)}
              onClick={handleBannerClick}
            />
            {bannerHover && (
              <div
                className="position-absolute w-100 h-100 top-0 start-0 body-text2 text-white d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: 6,
                }}
                onMouseEnter={() => setBannerHover(true)}
                onMouseLeave={() => setBannerHover(false)}
                onClick={handleBannerClick}
              >
                <FiRefreshCcw size={20} color="white" />
                <span className="mt-1">Edit Banner</span>
              </div>
            )}
            <input
              type="file"
              id="bannerUpload"
              ref={bannerInputRef}
              style={{ display: "none" }}
              onChange={handleBannerUpdate}
              accept="image/jpeg,image/jpg,image/png"
            />
          </Card>
        </div>

        <div className="px-3">
          <Form.Label className="mb-1 body-text2 inter-500">
            Profile Pic Edit
          </Form.Label>
          <Card
            className="d-flex justify-content-center position-relative overflow-hidden cursor-pointer shadow-none p-0 mx-3 mb-3"
            style={{
              width: 180,
              height: 150,
              border: "1px dashed #BDC1C6",
              borderRadius: 6,
            }}
          >
            <Image
              className="p-0"
              style={{ borderRadius: 6, objectFit: "contain" }}
              maxWidth={180}
              maxHeight={150}
              src={previewPic || userImg}
              alt="avatar"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            />
            {hover && (
              <div
                className="position-absolute w-100 h-100 top-0 start-0 body-text2 text-white d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: 6,
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => document.getElementById("profileUpload").click()}
              >
                <FiRefreshCcw size={20} color="white" />
                <span className="mt-1">Reupload Profile Image</span>
              </div>
            )}
            <input
              type="file"
              id="profileUpload"
              style={{ display: "none" }}
              onChange={handleProfilePicUpdate}
            />
          </Card>
        </div>

        <div className="d-flex justify-content-between p-3">
          <div className={`h4 inter-600 mt-2`}>Contact Information</div>
          <div
            className="mt-2 me-2 cursor-pointer"
            onClick={() => setIsEdit(!isEdit)}
          >
            <FiEdit3 size={20} />
          </div>
        </div>
        <Row className="px-3 pb-3">
          <Col sm={12} lg={6}>
            <Form.Group className="mb-3 user-select-none">
              <Form.Label className="mb-1 body-text2 inter-500">
                Name<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control
                required
                type="text"
                disabled={!isEdit}
                className={`body-text2 py-2 border ${
                  fieldErrors.full_name ? "border-danger" : "border-gray-300"
                } ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                name="full_name"
                value={formValues.full_name}
                onChange={handleInputChange}
                style={{ borderRadius: 3 }}
              />
            </Form.Group>
            <Form.Group className="mb-3 user-select-none">
              <Form.Label className="mb-1 body-text2 inter-500">
                Company Email<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <InputGroup
                className="border border-gray-300 bg-disabled"
                style={{ borderRadius: 3 }}
              >
                <InputGroup.Text
                  className="bg-disabled border-0"
                  style={{ borderRadius: 3 }}
                >
                  <LuMail size={20} />
                </InputGroup.Text>
                <div
                  className={`ms-2 py-2 body-text2 border-0 bg-tertiary text-gray-600`}
                >
                  {user?.user_data?.email}
                </div>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3 user-select-none">
              <Form.Label className="mb-1 body-text2 inter-500">
                Phone Number<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <InputGroup
                className={`border ${
                  fieldErrors.mobile ? "border-danger" : "border-gray-300"
                }`}
                style={{ borderRadius: 3 }}
              >
                <InputGroup.Text
                  className={`border-0 body-text2 inter-500 ${
                    isEdit ? "bg-white" : "bg-disabled"
                  }`}
                  style={{ borderRadius: 3 }}
                >
                  +91
                </InputGroup.Text>
                <Form.Control
                  disabled={!isEdit}
                  className={`body-text2 py-2 border-0 ${
                    isEdit ? "text-gray-900" : "text-gray-600"
                  }`}
                  placeholder="Phone number"
                  name="mobile"
                  value={formValues.mobile}
                  required
                  type="tel"
                  pattern="[0-9]{10}"
                  maxLength={10}
                  onChange={handleInputChange}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="user-select-none mb-3">
              <Form.Label className="mb-1 body-text2 inter-500">
                Address<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control
                required
                type="text"
                disabled={!isEdit}
                className={`body-text2 py-2 border ${
                  fieldErrors.address ? "border-danger" : "border-gray-300"
                } ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                name="address"
                value={formValues.address}
                onChange={handleInputChange}
                style={{ borderRadius: 3 }}
              />
            </Form.Group>
            <Row>
              <Col sm={6}>
                <Form.Group className="user-select-none mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Pincode<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      required
                      type="text"
                      disabled={!isEdit}
                      className={`body-text2 py-2 border ${
                        fieldErrors.pincode ? "border-danger" : "border-gray-300"
                      } ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                      name="pincode"
                      value={formValues.pincode}
                      onChange={handlePincodeChange}
                      maxLength={6}
                      pattern="\d{6}"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                      }}
                      style={{ borderRadius: 3 }}
                    />
                    {isPincodeLoading && (
                      <InputGroup.Text>
                        <Spinner animation="border" size="sm" />
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                  {pincodeError && (
                    <Form.Text className="text-danger">{pincodeError}</Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="user-select-none mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    City<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    readOnly
                    className={`body-text2 py-2 border ${
                      fieldErrors.city ? "border-danger" : "border-gray-300"
                    } ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                    name="city"
                    value={formValues.city}
                    style={{ borderRadius: 3, backgroundColor: "#f8f9fa" }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group className="user-select-none mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    State<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    readOnly
                    className={`body-text2 py-2 border ${
                      fieldErrors.state ? "border-danger" : "border-gray-300"
                    } ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                    name="state"
                    value={formValues.state}
                    style={{ borderRadius: 3, backgroundColor: "#f8f9fa" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="user-select-none mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Country<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    readOnly
                    className={`body-text2 py-2 border ${
                      fieldErrors.country ? "border-danger" : "border-gray-300"
                    } ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                    name="country"
                    value={formValues.country}
                    style={{ borderRadius: 3, backgroundColor: "#f8f9fa" }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          {user?.user_data?.user_type === "architect" && (
            <Col sm={12} lg={6}>
              <Row>
                <Col sm={6}>
                  <Form.Group className="user-select-none mb-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Interest of services
                    </Form.Label>
                    <Form.Select
                      disabled={!isEdit}
                      className={`body-text2 py-2 border border-gray-300 ${
                        isEdit ? "text-gray-900" : "text-gray-600"
                      }`}
                      value={professionalDetails?.interest_of_service || ""}
                      onChange={(e) => {
                        setProfessionalDetails({
                          ...professionalDetails,
                          interest_of_service: e.target.value,
                        });
                        setShowJobCost(true);
                        setIntrestOfServices(e.target.value);
                      }}
                      name="interest_of_service"
                      style={{ borderRadius: 3 }}
                    >
                      <option value="">Select Interest of Service</option>
                      <option value="turnkey">Turnkey</option>
                      <option value="design">Design</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                {showJobCost && intrestOfServices && (
                  <Col sm={6}>
                    <Form.Group className="user-select-none mb-3">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        {intrestOfServices
                          ? intrestOfServices.charAt(0).toUpperCase() +
                            intrestOfServices.slice(1)
                          : ""}{" "}
                        job cost
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Text>₹</InputGroup.Text>
                        <Form.Control
                          type="number"
                          disabled={!isEdit}
                          value={professionalDetails?.job_cost || ""}
                          onChange={(e) =>
                            setProfessionalDetails({
                              ...professionalDetails,
                              job_cost: e.target.value,
                            })
                          }
                          className={`body-text2 py-2 border border-gray-300 ${
                            isEdit ? "text-gray-900" : "text-gray-600"
                          }`}
                          name="job_cost"
                          style={{ borderRadius: 3 }}
                        />
                        <InputGroup.Text className="body-text2 text-gray-600 bg-white border border-gray-300">
                          per sq. ft.
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                )}

                <Col sm={6}>
                  <Form.Group className="user-select-none mb-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      College
                    </Form.Label>
                    <Form.Control
                      type="text"
                      disabled={!isEdit}
                      className={`body-text2 py-2 border border-gray-300 ${
                        isEdit ? "text-gray-900" : "text-gray-600"
                      }`}
                      value={professionalDetails?.college || ""}
                      onChange={(e) =>
                        setProfessionalDetails({
                          ...professionalDetails,
                          college: e.target.value,
                        })
                      }
                      name="college"
                      style={{ borderRadius: 3 }}
                    />
                  </Form.Group>
                </Col>

                <Col sm={6}>
                  <Form.Group className="user-select-none mb-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Passing Year
                    </Form.Label>
                    <Form.Control
                      type="number"
                      disabled={!isEdit}
                      className={`body-text2 py-2 border border-gray-300 ${
                        isEdit ? "text-gray-900" : "text-gray-600"
                      }`}
                      value={professionalDetails?.passing_year || ""}
                      onChange={(e) =>
                        setProfessionalDetails({
                          ...professionalDetails,
                          passing_year: e.target.value,
                        })
                      }
                      name="passing_year"
                      style={{ borderRadius: 3 }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group className="user-select-none mb-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Working Experience
                    </Form.Label>
                    <Form.Control
                      disabled={!isEdit}
                      type="number"
                      className="body-text2 py-2"
                      placeholder="Enter Working Experience"
                      name="working_experience"
                      value={professionalDetails?.working_experience || ""}
                      onChange={(e) =>
                        setProfessionalDetails({
                          ...professionalDetails,
                          working_experience: e.target.value,
                        })
                      }
                      style={{
                        border: "1px solid #BDC1C6",
                        borderRadius: 3,
                      }}
                    />
                    <Form.Text className="text-muted">
                      Please enter the number of years (e.g., 5, 10, 20).
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="user-select-none mb-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Team Size
                    </Form.Label>
                    <Form.Control
                      type="number"
                      disabled={!isEdit}
                      value={professionalDetails?.team_size || ""}
                      onChange={(e) =>
                        setProfessionalDetails({
                          ...professionalDetails,
                          team_size: e.target.value,
                        })
                      }
                      className={`body-text2 py-2 border border-gray-300 ${
                        isEdit ? "text-gray-900" : "text-gray-600"
                      }`}
                      name="team_size"
                      style={{ borderRadius: 3 }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="user-select-none mb-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Delivered Projects
                    </Form.Label>
                    <Form.Control
                      type="number"
                      disabled={!isEdit}
                      value={professionalDetails?.delivered_projects || ""}
                      onChange={(e) =>
                        setProfessionalDetails({
                          ...professionalDetails,
                          delivered_projects: e.target.value,
                        })
                      }
                      className={`body-text2 py-2 border border-gray-300 ${
                        isEdit ? "text-gray-900" : "text-gray-600"
                      }`}
                      name="delivered_projects"
                      style={{ borderRadius: 3 }}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="user-select-none mb-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Expertise Type
                    </Form.Label>
                    <Form.Select
                      disabled={!isEdit}
                      className={`body-text2 py-2 border border-gray-300 ${
                        isEdit ? "text-gray-900" : "text-gray-600"
                      }`}
                      name="expertise_type"
                      value={professionalDetails?.expertise_type || ""}
                      onChange={(e) =>
                        setProfessionalDetails({
                          ...professionalDetails,
                          expertise_type: e.target.value,
                        })
                      }
                      style={{ borderRadius: 3 }}
                    >
                      <option value="">Select Expertise Type</option>
                      {expertiseTypes.map((type) => (
                        <option key={type.id} value={type.expertise_type}>
                          {type.expertise_type}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          )}
          {user?.user_data?.user_type === "brand" && (
            <Col sm={12} lg={6}>
              <Row>
                <Col sm={6}>
                  <Form.Group className="user-select-none mb-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Warranty
                    </Form.Label>
                    <Form.Control
                      type="text"
                      disabled={!isEdit}
                      className={`body-text2 py-2 border border-gray-300 ${
                        isEdit ? "text-gray-900" : "text-gray-600"
                      }`}
                      name="warranty"
                      value={professionalDetails?.warranty || ""}
                      onChange={(e) =>
                        setProfessionalDetails({
                          ...professionalDetails,
                          warranty: e.target.value,
                        })
                      }
                      style={{ borderRadius: 3 }}
                    />
                  </Form.Group>
                </Col>

                <Col sm={6}>
                  <Form.Group className="user-select-none mb-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Warranty Duration
                    </Form.Label>
                    <Form.Select
                      className="py-2 body-text2"
                      type="text"
                      disabled={!isEdit}
                      placeholder="Select Duration Unit"
                      name="warranty_duration"
                      value={selectedWarrentyDuration}
                      onChange={(e) =>
                        setSelectedWarrentyDuration(e.target.value)
                      }
                      style={{
                        border: "1px solid #BDC1C6",
                        borderRadius: 3,
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Days">Days</option>
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <MultiSelect
                    name="brand_category"
                    value={selectedBrandCategories}
                    onChange={handleChange}
                    categories={brandCategories}
                    validate={validate}
                    disabled={!isEdit}
                    required={true}
                  />
                </Col>
                <Col lg={6}>
                  <Form.Group className="user-select-none mb-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Established Year
                    </Form.Label>
                    <Form.Control
                      disabled={!isEdit}
                      type="number"
                      className="body-text2 py-2"
                      value={professionalDetails?.established_year || ""}
                      onChange={(e) =>
                        setProfessionalDetails({
                          ...professionalDetails,
                          established_year: e.target.value,
                        })
                      }
                      placeholder="Enter Year"
                      name="established_year"
                      max={new Date().getFullYear()}
                      style={{
                        border: "1px solid #BDC1C6",
                        borderRadius: 3,
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Form.Group className="user-select-none mb-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Brochure (Optional)
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="file"
                        ref={brochureInputRef}
                        disabled={!isEdit || brochureLoading}
                        className={`body-text2 py-2 border border-gray-300 ${
                          isEdit ? "text-gray-900" : "text-gray-600"
                        }`}
                        name="brochure"
                        accept=".pdf,.zip"
                        onChange={handleBrochureChange}
                        style={{ borderRadius: 3 }}
                      />
                      {brochureLoading && (
                        <InputGroup.Text>
                          <Spinner animation="border" size="sm" />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                    {professionalDetails?.brochure &&
                      professionalDetails.brochure.length > 0 &&
                      !brochure && (
                        <Form.Text className="text-muted">
                          Current brochure:{" "}
                          <a
                            href={
                              Array.isArray(professionalDetails.brochure)
                                ? professionalDetails.brochure[0]
                                : professionalDetails.brochure
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {(() => {
                              const brochureUrl = Array.isArray(
                                professionalDetails.brochure
                              )
                                ? professionalDetails.brochure[0]
                                : professionalDetails.brochure;
                              return brochureUrl
                                ? brochureUrl.split("/").pop()
                                : "Brochure";
                            })()}
                          </a>
                        </Form.Text>
                      )}
                    {brochure && !brochureLoading && (
                      <Form.Text className="text-success">
                        Selected file: {brochure.name}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group className="user-select-none mb-3">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Expertise Type
                    </Form.Label>
                    <Form.Select
                      disabled={!isEdit}
                      className={`body-text2 py-2 border border-gray-300 ${
                        isEdit ? "text-gray-900" : "text-gray-600"
                      }`}
                      name="expertise_type"
                      value={professionalDetails?.expertise_type || ""}
                      onChange={(e) =>
                        setProfessionalDetails({
                          ...professionalDetails,
                          expertise_type: e.target.value,
                        })
                      }
                      style={{ borderRadius: 3 }}
                    >
                      <option value="">Select Expertise Type</option>
                      {expertiseTypes.map((type) => (
                        <option key={type.id} value={type.expertise_type}>
                          {type.expertise_type}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        {isEdit && (
          <div className="d-flex p-3">
            <Button
              className="bg-transparent inter-500 border-gray-200 text-gray-900 body-text2"
              style={{ borderRadius: 3 }}
              onClick={() => {
                setIsEdit(false);
                setBrochure(null);
                if (brochureInputRef.current) {
                  brochureInputRef.current.value = null;
                }
                setFormValues({
                  full_name: user?.user_data?.full_name || "",
                  mobile: user?.user_data?.mobile || "",
                  pincode: user?.user_data?.pincode || "",
                  address: user?.user_data?.address || "",
                  city: user?.user_data?.city || "",
                  state: user?.user_data?.state || "",
                  country: user?.user_data?.country || "",
                });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="inter-500 body-text2 text-white ms-2 d-flex justify-content-center align-items-center"
              style={{ borderRadius: 3 }}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />{" "}
                  Saving...
                </>
              ) : (
                <>
                  <FaSave size={18} className="me-2" />
                  Save Profile
                </>
              )}
            </Button>
          </div>
        )}
      </Form>

      <Modal
        show={showCropModal}
        onHide={handleModalClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Banner Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {imageSrc ? (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={1843 / 300}
              minWidth={1843}
              maxWidth={1843}
              minHeight={300}
            >
              <img
                ref={imgRef}
                src={imageSrc}
                onLoad={onImageLoad}
                alt="Crop preview"
                style={{ maxWidth: "100%" }}
              />
            </ReactCrop>
          ) : (
            <div className="text-center">
              <p>No banner image selected. Upload a new one below.</p>
            </div>
          )}
          <Form.Group className="mt-3">
            <Form.Label>Upload New Banner</Form.Label>
            <Form.Control
              type="file"
              ref={bannerInputRef}
              onChange={handleBannerUpdate}
              accept="image/jpeg,image/jpg,image/png"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
          {imageSrc && (
            <Button
              variant="danger"
              onClick={handleDeleteBanner}
              className="me-2"
            >
              <FiTrash2 className="me-1" /> Delete
            </Button>
          )}
          {imageSrc && (
            <Button
              variant="primary"
              onClick={getCroppedImg}
              disabled={!completedCrop}
            >
              Crop & Save
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AccountEditForm;