import React from "react";
import { FiTrash2 } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setEmail,
  setFloorPlan,
  setFullName,
  setMobile,
  setRequirement,
  setRequirementsMsg,
  setSelectedRoomType,
} from "reduxFiles/reducer/consultationSlice";
import {
  deleteProjectDetails,
  updateServiceType,
} from "reduxFiles/reducer/projectSlice";
import useFetchMedia from "utilities/customHooks/useFetchMedia";

const ProjectReferenceCard = ({ project, className }) => {
  const dispatch = useDispatch();
  const location = useLocation()

  const { image, loading, error } = useFetchMedia(
    "project",
    "room",
    project?.rooms[0]?.id
  );

  const handleDelete = () => {
    dispatch(deleteProjectDetails({}));
    dispatch(setFullName(""));
    dispatch(setEmail(""));
    dispatch(setMobile(""));
    dispatch(setRequirementsMsg(""));
    dispatch(setRequirement(""));
    dispatch(setFloorPlan(""));
    dispatch(setSelectedRoomType([]));
    dispatch(updateServiceType(""));
  };
  return (
    <div
      className={`d-flex mb-3 ${className}`}
      style={{ borderRadius: 3, border: "1px solid #BDC1C6" }}
    >
      <div className="">
        {project?.property_data?.property_img && (
          <img
            className="overflow-hidden"
            src={project.property_data.property_img}
            style={{
              height: 72,
              width: 120,
              objectFit: "cover",
              borderRadius: "3px 0 0 3px",
            }}
            alt={project?.project_name || "Project image"}
            onError={(e) => {
              console.error("Image failed to load:", e);
              e.target.style.display = "none";
            }}
          />)}
      </div>
      <div className="d-flex justify-content-between align-items-center w-100 mx-3">
        <div className="d-flex flex-column justify-content-center text-gray-900">
          <div className="body-text1 inter-500 mb-0">
            {project?.project_name}/{project?.bhk}/
            {project?.property_name}
          </div>
          <div className="body-text2 inter-400 d-flex flex-column align-items-start">
            Architect: {project?.company_name}
          </div>
          <div className="body-text2 inter-400 d-flex flex-column align-items-start">
            {project?.property_data?.builder_data && (
              <span className="ms-0">
                Builders: {project?.builder_name}
              </span>
            )}
          </div>
        </div>
        {!location?.pathname.includes("projecttracker") && <FiTrash2 onClick={handleDelete} size={20} color="#3C4043" />}
      </div>
    </div>
  );
};

export default ProjectReferenceCard;
