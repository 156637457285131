import { useRef, useState, useEffect } from 'react';

/**
 * Custom hook to manage video playing state across multiple video components
 * Ensures only one video plays at a time within a component tree
 * 
 * @returns {Object} - Hook functions and state
 */
const useVideoPlayer = () => {
  // Store the ID of the currently active/playing video
  const [activeVideoId, setActiveVideoId] = useState(null);
  
  // Use a ref to store all registered video elements
  const videoRefs = useRef({});

  // Handle play event for a specific video
  const handleVideoPlay = (videoId) => {
    console.log("Video play triggered for:", videoId);

    // If there's already an active video and it's different from the current one
    if (activeVideoId && activeVideoId !== videoId) {
      const previousVideo = videoRefs.current[activeVideoId];

      // Pause previous video if it exists and is playing
      if (previousVideo && typeof previousVideo.pause === 'function') {
        console.log("Pausing previous video:", activeVideoId);
        try {
          previousVideo.pause();

          // Dispatch a custom event to notify the previous video's component
          window.dispatchEvent(new CustomEvent('pause-other-videos', {
            detail: { currentVideoId: videoId }
          }));
        } catch (error) {
          console.error("Error pausing video:", error);
        }
      } else {
        console.warn("Cannot pause previous video - invalid reference:", activeVideoId);
      }
    }

    // Set the new active video
    setActiveVideoId(videoId);
  };

  // Register a video element with its ID
  const registerVideoRef = (id, element) => {
    if (element && typeof element.play === 'function' && typeof element.pause === 'function') {
      console.log("Registering video element:", id);
      videoRefs.current[id] = element;
    } else {
      console.warn("Attempted to register invalid video element:", id);
    }
  };

  // Clean up function to pause all videos when component unmounts
  useEffect(() => {
    return () => {
      // Pause all videos when leaving the page
      Object.entries(videoRefs.current).forEach(([id, videoElement]) => {
        if (videoElement && typeof videoElement.pause === 'function') {
          try {
            videoElement.pause();
          } catch (error) {
            console.error(`Error pausing video ${id}:`, error);
          }
        }
      });
    };
  }, []);

  return {
    activeVideoId,
    registerVideoRef,
    handleVideoPlay,
    videoRefs: videoRefs.current
  };
};

export default useVideoPlayer;