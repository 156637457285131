import { all_products } from "assets";
import ProductListCard from "components/Cards/ProductListCard";
import Pagination from "components/Pagination";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { CgSortAz } from "react-icons/cg";
import { IoClose, } from "react-icons/io5";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowForward,
} from "react-icons/io";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import ProductCategorySlider from "./ProductCategorySlider";
import { FaDownload, FaFilter, FaList, FaUpload } from "react-icons/fa6";
import FilterSearchBar from "components/FilterSearchBar/FilterSearchBar";
import { FaTh, FaTimes } from "react-icons/fa";
import ProjectCardShimmer from "components/Cards/ProjectCardShimmer";
import { FaBoxOpen } from "react-icons/fa";
import { BiGrid } from "react-icons/bi";
import ProductListView from "./ProductListView";
import ProductListShimmer from "./ProductListShimmer";
import DownloadCSVModal from "./DownloadCSVModel";
import UploadExcelModal from "./UploadExcelModel";

const ProductLibrary = () => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });

  const navigate = useNavigate();

  const [productCategories, setProductCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [next, setNext] = useState("");
  const [productSubTypes, setProductSubTypes] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [brands, setBrands] = useState([]);
  const [productCategory, setProductCategory] = useState("");
  const [productType, setProductType] = useState("");
  const [productSubType, setProductSubType] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [brand, setBrand] = useState("");
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [hasFetchedProducts, setHasFetchedProducts] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [isGridView, setIsGridView] = useState(true);

  const [selectedCategory, setSelectedCategory] = useState(productCategory);
  const [selectedProductType, setSelectedProductType] = useState(productType);
  const [selectedProductSubType, setSelectedProductSubType] =
    useState(productSubType);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTypeTerm, setSearchTypeTerm] = useState("");
  const [searchSubtypeTerm, setSearchSubtypeTerm] = useState("");

  const [searchBrandTerm, setSearchBrandTerm] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState(brand);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTypeSearch = (e) => setSearchTypeTerm(e.target.value);
  const handleSubtypeSearch = (e) => setSearchSubtypeTerm(e.target.value);
  const handleBrandSearch = (e) => setSearchBrandTerm(e.target.value);

  // Filter product categories based on the search query
  const filteredCategories = productCategories.filter((prodCat) =>
    prodCat.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredProductTypes = productTypes.filter((prodType) =>
    prodType.title.toLowerCase().includes(searchTypeTerm.toLowerCase())
  );

  const filteredProductSubtypes = productSubTypes.filter((prodSubtype) =>
    prodSubtype.title.toLowerCase().includes(searchSubtypeTerm.toLowerCase())
  );

  // Filter brands based on the search term
  const filteredBrands = brands.filter((brand) =>
    brand.company_name.toLowerCase().includes(searchBrandTerm.toLowerCase())
  );

  const handleSelect = (categoryId) => {
    setSelectedCategory(categoryId);
    setProductCategory(categoryId);
    setProductSubTypes([])
    onChangeFilters(categoryId, "category");
  };

  const handleProductTypeSelect = (productTypeId) => {
    setSelectedProductType(productTypeId);
    onChangeFilters(productTypeId, "product_type");
    setSearchTypeTerm("");
    setSelectedProductSubType("")
    setSelectedBrandId("")
  };

  const handleProductSubtypeSelect = (productSubtypeId) => {
    setSelectedProductSubType(productSubtypeId);
    onChangeFilters(productSubtypeId, "product_sub_type");
    setSearchSubtypeTerm("");
  };

  const handleBrandSelect = (brandId) => {
    setSelectedBrandId(brandId);
    onChangeFilters(brandId, "brands");
    setSearchBrandTerm(""); // Clear search term after selection
  };

  const searchParams = new URLSearchParams(document.location.search);

  const category = searchParams.get("category");
  const type = searchParams.get("product_type");
  const subType = searchParams.get("product_sub_type");
  const sort = searchParams.get("sort_by");
  const brandParam = searchParams.get("brands");
  const q = searchParams.get("q");

  /* const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsPagewise(
        "/product/products/?page=1"
      );
      if (response?.data) {
        setProducts(response.data?.results);
        setNext(response.data?.next); // Set the next URL
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }; */

  const fetchProducts = async () => {
    setLoading(true);
    try {
      // For architect users, get all products
      // For brand users, only get their products
      const url = user?.user_type === 'brand'
        ? `/product/products/?page=1&brand__user=${user.id}`
        : "/product/products/?page=1";

      const response = await authActions.fetchProductsPagewise(url);
      if (response?.data) {
        setProducts(response.data?.results);
        setNext(response.data?.next); // Set the next URL
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProductsByBrand = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsByBrandUser(user?.id);
      if (response.data) {
        setProducts(response.data?.results);
        setNext(response.data?.next); // Set the next URL
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchCategories = async () => {
    try {
      const response = await authActions.fetchProductCategories();
      if (response?.data) {
        setProductCategories(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchProductTypes = async (categoryId) => {
    try {
      const response = await authActions.fetchProductTypes(categoryId);
      if (response?.data) {
        setProductTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchProductSubTypes = async (typeId) => {
    try {
      const response = await authActions.fetchProductSubTypes(typeId);
      if (response?.data) {
        setProductSubTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchBrandsPagewise = async () => {
    try {
      const response = await authActions.fetchBrandsPagewise();
      if (response?.data) {
        setBrands(response.data.results);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadMoreProducts = () => {
    if (loading) return;
    setCurrentPage((prevPage) => {
      const nextPage = prevPage + 1;

      if (param1 === "architect") {
        if (nextPage > 2 || next === null) return prevPage; // Return current page if no more to load
        fetchProducts(nextPage);
      } else if (param1 === "brand") {
        fetchProductsByBrand(nextPage);
      }
      return nextPage;
    });
  };

  useEffect(() => {
    fetchBrandsPagewise();
  }, []);

  useEffect(() => {
    fetchCategories();

    if (category) {
      setProductCategory(category);
      fetchProductTypes(category);
    }

    if (type) {
      setProductType(type);
      fetchProductSubTypes(type);
    }
    if (subType) setProductSubType(subType);

    if (q) setSearchText(q);

    if (sort) setSortBy(sort);
    if (brandParam) setBrand(brandParam);
  }, [productType]);

  const onChangeFilters = (value = null, type = null) => {
    // If all filters are being reset:
    if (value === null && type === null) {
      setProductCategory(null);
      setProductType(null);
      setProductSubType(null);
      setBrand(null);
      setSortBy(null);

      if (param1 === "architect") {
        navigate(`/architect/product-library`);
      } else {
        navigate(`/brand/product-library`);
      }
      return;
    }

    // If the product category is changed, reset product type and sub-type.
    if (type === "category") {
      setProductCategory(value);
      setProductType("");
      setProductSubType("");
      setSelectedProductType("");
      setBrand("");
      setSelectedBrandId("");
      setSelectedProductSubType("");
    } else if (type === "product_type") {
      setProductType(value);
      setProductSubType("");
      setBrand("")
      setSelectedBrandId("")
    } else if (type === "product_sub_type") {
      setProductSubType(value);
      setBrand("")
      setSelectedBrandId("")
    } else if (type === "brands") {
      setBrand(value);
    } else if (type === "sort_by") {
      setSortBy(value);
    } else if (type === "q") {
      setSearchText(value);
    }

    // Build filter parameters. If category changes, ensure product_type and product_sub_type are removed.
    const filterParams = {
      category: type === "category" ? value : productCategory,
      product_type:
        type === "category" ? "" : type === "product_type" ? value : productType,
      product_sub_type:
        type === "category" || type === "product_type" ? "" : type === "product_sub_type" ? value : productSubType,
      brands: (type === "category" || type === "product_sub_type") ? "" : type === "brands" ? value : brand,
      sort_by: type === "sort_by" ? value : sortBy,
      q: type === "q" ? value : searchText,
    };

    const queryString = Object.entries(filterParams)
      .filter(([key, val]) => val)
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");

    if (param1 === "architect") {
      navigate(`/architect/product-library?${queryString}`);
    } else {
      navigate(`/brand/product-library?${queryString}`);
    }
  };

  /* const loadPaginatedProducts = async (next) => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsPagewise(next);
      if (response) {
        setProducts((prevProducts) => [
          ...prevProducts,
          ...(response?.data?.results || []),
        ]);
        setNext(response?.data?.next);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }; */

  const loadPaginatedProducts = async (next, userId = null) => {
    setIsLoadingMore(true);
    try {
      // Append userId parameter if provided
      let url = next;
      if (userId && !url.includes('brand__user')) {
        url += (url.includes('?') ? '&' : '?') + `brand__user=${userId}`;
      }

      const response = await authActions.fetchProductsPagewise(url);
      if (response) {
        setProducts((prevProducts) => [
          ...prevProducts,
          ...(response?.data?.results || []),
        ]);
        setNext(response?.data?.next);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoadingMore(false);
  };

  /* const filteredProducts = async (
    productCategory,
    productType,
    productSubType,
    brand,
    searchText,
    sortBy
  ) => {
    setLoading(true);
    try {
      const response = await authActions.buildFirstPageUrl(
        productCategory,
        productType,
        productSubType,
        brand,
        searchText,
        sortBy
      );

      if (response?.data) {
        setNext(response?.data?.next);
        setProducts(response?.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setHasFetchedProducts(true);
  }; */

  const filteredProducts = async (
    productCategory,
    productType,
    productSubType,
    brand,
    searchText,
    sortBy
  ) => {
    setLoading(true);
    try {
      // Include userId for brand users to filter only their products
      const userId = user?.user_type === 'brand' ? user.id : null;

      const response = await authActions.buildFirstPageUrl(
        productCategory,
        productType,
        productSubType,
        brand,
        searchText,
        sortBy,
        userId // Pass userId as a new parameter
      );

      if (response?.data) {
        setNext(response?.data?.next);
        setProducts(response?.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setHasFetchedProducts(true);
  };

  /* const fetchFilteredBrands = async () => {
    const response = await authActions.fetchBrandsByProductCategories(
      productCategory,
      productType,
      productSubType
    );
    if (response?.data) {
      setBrands(response.data.results);
    }
  }; */

  const fetchFilteredBrands = async () => {
    try {
      // For brand users, only get their brand info
      if (user?.user_type === 'brand') {
        const brandResponse = await authActions.fetchBrandsByUser(user.id);
        if (brandResponse?.data) {
          setBrands(brandResponse.data.results);
        }
      } else {
        // For architects, get all brands filtered by selected categories
        const response = await authActions.fetchBrandsByProductCategories(
          productCategory,
          productType,
          productSubType
        );
        if (response?.data) {
          setBrands(response.data.results);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFilteredBrands();
  }, [productCategory, productType, productSubType]);

  useEffect(() => {
    filteredProducts(
      productCategory,
      productType,
      productSubType,
      brand,
      searchText,
      sortBy
    );
  }, [productCategory, brand, productType, productSubType, sortBy, searchText]);

  useEffect(() => {
    if (param1 === "architect") {
      fetchProducts();
    } else if (param1 === "brand") {
      fetchProductsByBrand();
    }
  }, [param1, user]);

  // Fetch Product Types when category changes
  useEffect(() => {
    if (productCategory) {
      fetchProductTypes(productCategory);
    }
  }, [productCategory]);

  // Fetch Product SubTypes when product type changes
  useEffect(() => {
    if (productType) {
      fetchProductSubTypes(productType);
    }
  }, [productType]);

  useEffect(() => {
    fetchCategories();
  }, []);

  let refreshData;

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleOpenDownloadModal = () => {
    setShowDownloadModal(true);
  };

  const handleCloseDownloadModal = () => {
    setShowDownloadModal(false);
  };


  /* const handleLoadMore = () => {
    if (next) {
      loadPaginatedProducts(next);
    }
  }; */

  const handleUploadComplete = () => {
    // Refresh data after successful upload
    if (refreshData) {
      refreshData();
    }
  };

  const handleLoadMore = () => {
    if (next && !isLoadingMore) {
      // Pass the user ID if it's a brand user, to maintain filtering
      const userId = user?.user_type === 'brand' ? user.id : null;
      loadPaginatedProducts(next, userId);
    }
  };

  return (
    <Container fluid>
      <Container className="mt-5 pt-5">
        <ProductCategorySlider
          all_products={all_products}
          productCategories={productCategories}
          setProductCategory={setProductCategory}
          onChangeFilters={onChangeFilters}
          fetchProducts={fetchProducts}
          productCategory={productCategory}
        />

        <div className="d-flex justify-content-between align-items-center my-4">
          <span className="fw-bold">
            Products Available ({products?.length})
          </span>
          <div className="d-flex">
            <button
              type="button"
              className={`btn btn-link p-2 ${isGridView ? "text-primary" : "text-secondary"}`}
              onClick={() => setIsGridView(true)}
              style={{ textDecoration: "none" }}
            >
              <FaTh size={20} />
            </button>
            <button
              type="button"
              className={`btn btn-link p-2 ${!isGridView ? "text-primary" : "text-secondary"}`}
              onClick={() => setIsGridView(false)}
              style={{ textDecoration: "none" }}
            >
              <FaList size={20} />
            </button>
            <div className="d-flex gap-2">
              <Button
                type="button"
                className="p-2"
                onClick={handleOpenDownloadModal}
                disabled={isDownloading}
                style={{ textDecoration: "none" }}
              >
                <FaDownload size={20} /> Download
              </Button>


              <Button
                type="button"
                className={`p-2 `}
                style={{ textDecoration: "none" }}
                onClick={() => setShowUploadModal(true)}
              >
                <FaUpload size={20} /> Export Products
              </Button>
            </div>
          </div>
        </div>

        <div className="d-block d-md-none d-flex justify-content-between align-items-center">
          <FaFilter
            size={20}
            className="cursor-pointer my-2"
            onClick={handleToggleFilters}
          />

          {!small && (
            <div className="ms-3">
              <span
                className="cursor-pointer text-gray-700"
                onClick={() => {
                  onChangeFilters();
                  setSearchText("");
                  setSelectedCategory(null);
                  setSelectedProductType(null);
                  setSelectedProductSubType(null);
                  setSelectedBrandId(null);
                  setProductSubTypes([])
                }}
              >
                Clear Filters
              </span>
            </div>
          )}
        </div>

        {!small && (
          <div className="mb-3">
            <FilterSearchBar
              onChangeFilters={onChangeFilters}
              setSearchText={setSearchText}
              searchText={searchText}
            />
          </div>
        )}

        <div
          className={`d-flex flex-column flex-md-row justify-content-between ${showFilters ? "" : "d-none d-md-flex"
            }`}
        >
          <div className="row g-2">
            <div className="col-12 col-md-auto">
              <Dropdown>
                <Dropdown.Toggle
                  className="bg-transparent text-gray-700 cursor-pointer w-100"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                  }}
                >
                  {productCategory
                    ? productCategories.find(
                      (cat) => cat.id === productCategory
                    )?.title
                    : productCategories.find(
                      (cat) => cat.id === productCategory
                    )?.title || "Product Category"}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    maxHeight: "250px",
                    maxWidth: "100%",
                    overflowY: "auto",
                  }}
                >
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Search categories..."
                      value={searchTerm}
                      onChange={handleSearch}
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </InputGroup>

                  {filteredCategories.map((prodCat) => (
                    <Dropdown.Item
                      as="button"
                      key={prodCat.id}
                      onClick={() => handleSelect(prodCat.id, prodCat.title)}
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {prodCat.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="col-12 col-md-auto">
              <Dropdown>
                <Dropdown.Toggle
                  className="bg-transparent text-gray-700 cursor-pointer w-100"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                  }}
                >
                  {selectedProductType
                    ? productTypes.find(
                      (type) => type.id === selectedProductType
                    )?.title
                    : "Product Type"}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    maxHeight: "250px",
                    maxWidth: "100%",
                    overflowY: "auto",
                  }}
                >
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Search product types..."
                      value={searchTypeTerm}
                      onChange={handleTypeSearch}
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </InputGroup>

                  {filteredProductTypes.map((prodType) => (
                    <Dropdown.Item
                      as="button"
                      key={prodType.id}
                      onClick={() => handleProductTypeSelect(prodType.id)}
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {prodType.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="col-12 col-md-auto">
              <Dropdown>
                <Dropdown.Toggle
                  className="bg-transparent text-gray-700 cursor-pointer w-100"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                  }}
                >
                  {selectedProductSubType
                    ? productSubTypes.find(
                      (subtype) => subtype.id === selectedProductSubType
                    )?.title
                    : "Product Subtype"}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    maxHeight: "250px",
                    maxWidth: "100%",
                    overflowY: "auto",
                  }}
                >
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Search product subtypes..."
                      value={searchSubtypeTerm}
                      onChange={handleSubtypeSearch}
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </InputGroup>

                  {filteredProductSubtypes.map((prodSubtype) => (
                    <Dropdown.Item
                      as="button"
                      key={prodSubtype.id}
                      onClick={() => handleProductSubtypeSelect(prodSubtype.id)}
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {prodSubtype.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {/* <div className="col-12 col-md-auto">
              <Dropdown>
                <Dropdown.Toggle
                  className="bg-transparent text-gray-700 cursor-pointer w-100"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                  }}
                >
                  {selectedBrandId
                    ? brands.find((brand) => brand.id === selectedBrandId)
                      ?.company_name
                    : "Select Brand"}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    maxHeight: "250px",
                    maxWidth: "100%",
                    overflowY: "auto",
                  }}
                >
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Search brands..."
                      value={searchBrandTerm}
                      onChange={handleBrandSearch}
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </InputGroup>

                  {filteredBrands.map((brand) => (
                    <Dropdown.Item
                      as="button"
                      key={brand.id}
                      onClick={() => handleBrandSelect(brand.id)}
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {brand.company_name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div> */}

            {small && (
              <div className="col-12 col-md-auto">
                <FilterSearchBar
                  onChangeFilters={onChangeFilters}
                  setSearchText={setSearchText}
                  searchText={searchText}
                />
              </div>
            )}
          </div>

          <div className={small ? "d-flex align-items-center" : ""}>
            <div className="col-12 col-md-auto">
              <Dropdown>
                <Dropdown.Toggle
                  className="bg-transparent text-gray-900 cursor-pointer"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                    width: "100%",
                  }}
                >
                  {sortBy === "-created_at"
                    ? "Featured (New to Old)"
                    : sortBy === "created_at"
                      ? "Featured (Old to New)"
                      : "Sort By"}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    maxHeight: "250px",
                    maxWidth: "100%",
                    overflowY: "auto",
                  }}
                >
                  <Dropdown.Item
                    as="button"
                    onClick={() => onChangeFilters("-created_at", "sort_by")}
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    Featured (New to Old)
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() => onChangeFilters("created_at", "sort_by")}
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    Featured (Old to New)
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {small && (
              <div
                className="col-12 col-md-auto"
                style={{ marginInlineStart: small && "1rem" }}
              >
                <Button
                  variant="danger"
                  className="cursor-pointer text-white w-100"
                  onClick={() => {
                    onChangeFilters();
                    setSearchText("");
                    setSelectedCategory(null);
                    setSelectedProductType(null);
                    setSelectedProductSubType(null);
                    setSelectedBrandId(null);
                    setProductSubTypes([])
                    setProductTypes([])
                  }}
                >
                  Clear Filters
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="mb-3">
          {loading ? (
            <div className="d-flex flex-column align-items-center w-100">
              {isGridView ? (
                <Row className="w-100">
                  {[...Array(6)].map((_, index) => (
                    <Col key={index} lg={4} md={6} sm={12} className="py-2 mb-2">
                      <ProjectCardShimmer />
                    </Col>
                  ))}
                </Row>
              ) : (
                <ProductListShimmer />
              )}
            </div>
          ) : hasFetchedProducts && products?.length > 0 ? (
            <div
              className="d-flex flex-column align-items-center"
              style={{ minHeight: "80vh" }}
            >
              {isGridView ? (<Row className="w-100">
                {products.map((product, index) => (
                  <Col
                    key={index}
                    lg={4}
                    md={6}
                    sm={12}
                    className={`d-flex flex-column py-2 mb-2 justify-content-start ${large
                      ? index % 3 === 0
                        ? "ps-0"
                        : index % 3 === 2
                          ? "pe-0"
                          : ""
                      : medium
                        ? index % 2 === 0
                          ? "ps-0"
                          : "pe-0"
                        : "p-0"
                      }`}
                  >
                    <ProductListCard
                      {...product}
                      productId={product?.id}
                      product_category={product?.product_category_data?.title}
                      product_type={product?.product_type_data?.title}
                      product_sub_type={product?.product_sub_type_data?.title}
                      className={`cursor-pointer`}
                    />
                  </Col>
                ))}
              </Row>) : (
                <ProductListView products={products} />
              )}
              {isLoadingMore && isGridView && (
                <Row className="w-100">
                  {[...Array(3)].map((_, index) => (
                    <Col key={`loadmore-shimmer-${index}`} lg={4} md={6} sm={12} className="py-2 mb-2">
                      <ProjectCardShimmer />
                    </Col>
                  ))}
                </Row>
              )}
              {isLoadingMore && !isGridView && <ProductListShimmer />}
              {next && (
                <Button
                  className="d-flex justify-content-center align-items-center text-center border-0 mt-1 p-2"
                  style={{
                    borderRadius: "50%",
                    background: "#F1F3F4",
                    marginBottom: small ? "2rem" : "",
                  }}
                  onClick={handleLoadMore}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    <IoIosArrowDown size={20} color="#3C4043" />
                  )}
                </Button>
              )}
            </div>
          ) : hasFetchedProducts ? (
            <div
              className="d-flex justify-content-center align-items-center my-2 w-100"
              style={{ height: "300px", backgroundColor: "lightgray" }}
            >
              <div className="text-center">
                <FaBoxOpen size={50} className="mb-2 text-muted" />
                <h4>No Products Found</h4>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center w-100">
              <Row className="w-100">
                {[...Array(6)].map((_, index) => (
                  <Col key={index} lg={4} md={6} sm={12} className="py-2 mb-2">
                    <ProjectCardShimmer />
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </div>

        <DownloadCSVModal
          show={showDownloadModal}
          handleClose={handleCloseDownloadModal}
          setIsDownloading={setIsDownloading}
        />

        <UploadExcelModal
          show={showUploadModal}
          handleClose={() => setShowUploadModal(false)}
          onUploadComplete={handleUploadComplete}
        />
      </Container>
    </Container>
  );
};

export default ProductLibrary;