import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const ListedPropertyShimmer = () => {
  const shimmerStyle = {
    background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
    backgroundSize: "200% 100%",
    animation: "shimmer 1.5s infinite linear",
  };

  return (
    <Card
      className="p-0 d-flex flex-column text-start my-4 shadow-none"
      style={{
        border: "1px solid #E8EAED",
        borderRadius: 6,
        overflow: "hidden",
      }}
    >
      <Row>
        <Col
          xs={12}
          md={4}
          lg={5}
          xl={4}
          xxl={3}
          className="mb-2 mb-md-0"
          style={{
            height: 232,
            borderRadius: "6px 0px 0px 6px",
            ...shimmerStyle,
          }}
        />
        <Col xs={12} md={8} lg={7} xl={8} xxl={9}>
          <Card.Body className="w-100 h-100 d-flex flex-row justify-content-between align-items-start">
            <div className="d-flex flex-column w-100">
              <div
                style={{
                  width: "60%",
                  height: 20,
                  marginBottom: 8,
                  ...shimmerStyle,
                }}
              ></div>
              <div
                style={{
                  width: "30%",
                  height: 20,
                  marginBottom: 8,
                  ...shimmerStyle,
                }}
              ></div>
              <div
                style={{
                  width: "30%",
                  height: 20,
                  marginBottom: 8,
                  ...shimmerStyle,
                }}
              ></div>
              <div
                style={{
                  width: "30%",
                  height: 20,
                  marginBottom: 8,
                  ...shimmerStyle,
                }}
              ></div>
            </div>
            <div
              className="d-flex align-items-center justify-content-center ms-3"
              style={{
                width: 35,
                height: 30,
                borderRadius: "50%",
                ...shimmerStyle,
              }}
            ></div>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default ListedPropertyShimmer;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = `
  @keyframes shimmer {
    0% { background-position: -200% 0; }
    100% { background-position: 200% 0; }
  }
`;
document.head.appendChild(styleSheet);
