import LoginModal from "components/Auth/LoginModal";
import ShareModal from "components/General/ShareModal";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import userImg from "../../assets/navbar/user.webp";

const ProfessionalCard = ({
  architect_id,
  architect_user,
  name,
  slug,
  company_name,
  interest_of_service,
  job_cost,
  user_data,
  type,
  city,
  state,
  rating,
  project_count,
  expertise_type,
  project_names,
  project_img,
  profile_img,
  onClick,
  delivered_projects,
  // pincode,

}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const [reviews, setReviews] = useState([]);

  const [showShare, setShowShare] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);

  const checkProfessionalIfSaved = async () => {
    try {
      const response = await authActions.checkProfessionalIfSaved(
        user?.id,
        architect_id
      );
      if (response) {
        setIsLiked(response.status);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProfessional = async () => {
    try {
      const response = await authActions.saveProfessional(
        user?.id,
        architect_id
      );
      if (response.data) {
        setIsLiked(true);
        notify("Professional saved successfully", "success");
      } else {
        notify("Failed to save professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProfessional = async () => {
    try {
      const response = await authActions.unsaveProfessional(
        user?.id,
        architect_id
      );
      if (response) {
        setIsLiked(false);
        notify("Professional unsaved successfully", "success");
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchReviews = async (id) => {
    const resReviews = await authActions.fetchReviewsByArchitectUser(id);
    console.log(resReviews?.data?.results);
    if (resReviews?.data?.results) {
      setReviews(resReviews.data.results);
    }
  };

  useEffect(() => {
    if (architect_id) {
      console.log("architect_id", architect_id);
      fetchReviews(architect_id);
    }
  }, [architect_user]);

  useEffect(() => {
    console.log("project_count", project_count);
    if (user?.id && architect_id) {
      checkProfessionalIfSaved();
    }
  }, [user?.id, architect_id]);


  return (
    <>
      <Card
        className="card-primary-hover d-flex flex-column flex-lg-row p-2 p-md-3 text-start mb-4 border w-100 mt-2"
        style={{ boxShadow: "none", minHeight: "200px" }}
        onClick={onClick}
      >
        {/* Profile Picture - Center on Mobile */}
        <div
          className="d-flex justify-content-center align-items-center mx-auto mx-lg-0"
          style={{ width: "200px" }}
        >
          <img
            src={profile_img || user_data?.profile_picture || userImg}
            alt="Profile"
            className="bg-gray-25 border rounded-circle img-fluid"
            style={{
              width: "140px",
              height: "140px",
              objectFit: "cover",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
        </div>

        {/* Content Section */}
        <Card.Body className="w-100 p-0 ps-lg-2 mt-4 text-center text-lg-start">
          <div className="d-flex flex-column flex-lg-row justify-content-between w-100 ps-3">
            {/* Details Section */}
            <div className="flex-grow-1">
              <div className="d-flex align-items-center gap-2 flex-wrap justify-content-center justify-content-lg-start">
                <div className="text-uppercase fw-semibold fs-5 lh-sm text-dark">
                  {name ?? user_data?.full_name}
                </div>
                <div
                  className="d-flex align-items-center bg-danger text-white px-2 py-1 gap-1 rounded-3"
                  style={{ height: "26px", fontSize: "0.875rem" }}
                >
                  <FaStar
                    className="d-block"
                    style={{ width: "16px", height: "16px" }}
                  />
                  {reviews?.length > 0
                    ? (
                      reviews.reduce(
                        (acc, review) => acc + review.rating,
                        0
                      ) / reviews.length
                    ).toFixed(1)
                    : 0}
                </div>
              </div>
              <div>
                {company_name && (
                  <div className="mt-1 h4 fw-medium text-primary-500" >
                    {company_name}
                  </div>
                )}
              </div>

              <div
                className="body-text inter-500 fs-6 mt-1"
                style={{ color: "#666666" }}
              >
                {type}
              </div>
              {(city || user_data.city) && (
                <div className="mt-1">
                  {city || user_data.city}
                  {state || user_data.state
                    ? `, ${state || user_data.state}`
                    : ""}
                </div>
              )}

              {/* Project and Expertise Details */}
              <div className="d-flex flex-wrap gap-2 mt-2 justify-content-center justify-content-lg-start">
                {(project_count > 0
                  ? project_count
                  : delivered_projects > 0
                    ? delivered_projects
                    : project_count) !== null && (
                    <div
                      className="d-flex align-items-center px-2 py-1 border rounded"
                      style={{ backgroundColor: "#F1F3F4", color: "#2E3134" }}
                    >
                      {project_count > 0
                        ? project_count
                        : delivered_projects > 0
                          ? delivered_projects
                          : project_count}{" "}
                      Projects Completed
                    </div>
                  )}

                {/* {project_names && (
                  <div
                    className="d-flex align-items-center px-2 py-1 border rounded"
                    style={{ backgroundColor: "#F1F3F4", color: "#2E3134" }}
                  >
                    Projects: {project_names}
                  </div>
                )} */}
                {interest_of_service ===
                  "turnkey" && (
                    <div
                      className="d-flex align-items-center p-2 border"
                      style={{
                        backgroundColor: "#F1F3F4",
                        color: "#2E3134",
                      }}
                    >
                      Turnkey: ₹ {job_cost ?? 0} per
                      sq. ft.
                    </div>
                  )}

                {interest_of_service ===
                  "design" && (
                    <div
                      className="d-flex align-items-center p-2 border"
                      style={{
                        backgroundColor: "#F1F3F4",
                        color: "#2E3134",
                      }}
                    >
                      Only Design: ₹ {job_cost ?? 0}{" "}
                      per sq. ft.
                    </div>
                  )}


                {expertise_type && (
                  <div
                    className="d-flex align-items-center px-2 py-1 border rounded"
                    style={{ backgroundColor: "#F1F3F4", color: "#2E3134" }}
                  >
                    Expertise: {expertise_type}
                  </div>
                )}
              </div>
            </div>

            {/* Buttons Section */}
            <div className="d-flex gap-2 mt-3 mt-lg-0 justify-content-center justify-content-lg-start">
              <Button
                className="d-flex justify-content-center align-items-center social-btn py-2"
                style={{ backgroundColor: "#F1F3F4", border: "none" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowShare(true);
                }}
              >
                <FiSend color="#000000" size={18} />
              </Button>
              {user?.user_type !== "builder" &&
                user?.user_type !== "architect" &&
                user?.user_type !== "brand" && (
                  <Button
                    className={`d-flex justify-content-center align-items-center social-btn py-2 ${isLiked ? "bg-primary text-white" : ""
                      }`}
                    style={{ backgroundColor: "#F1F3F4", border: "none" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!user) {
                        setShowLoginModal(true);
                      } else {
                        isLiked
                          ? handleUnsaveProfessional()
                          : handleSaveProfessional();
                      }
                    }}
                  >
                    <FaRegHeart color="#000000" size={18} />
                  </Button>
                )}
            </div>
          </div>
        </Card.Body>
      </Card>

      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={architect_id}
        image={profile_img}
        title={company_name}
        urlPath={`/professionals/architects/${slug}`}
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </>
  );
};

export default ProfessionalCard;
