import { combineReducers } from "@reduxjs/toolkit";
import utilitiesReducer from "../reducer/utilitySlice";
import authReducer from "../reducer/authSlices";
import projectReducer from "../reducer/projectSlice";
import locationReducer from "../reducer/locationSlice"
import spaceReducer from "../reducer/spaceSlice"
import consultaionReducer from "../reducer/consultationSlice"

const rootReducer = combineReducers({
  utilities: utilitiesReducer,
  space: spaceReducer,
  auth: authReducer,
  project: projectReducer,
  location: locationReducer,
  consultaion: consultaionReducer
});

export default rootReducer;
