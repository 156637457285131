import { createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";

const projectSlice = createSlice({
    name: "project",
    initialState: {
        projectDetails: {},
        serviceTypeGlobal: "",
        deleteImageIds: []
    },
    reducers: {
        updateProjectDetails: (state, action) => {
            state.projectDetails = action.payload
        },
        deleteProjectDetails: (state, action) => {
            state.projectDetails = action.payload
        },
        updateServiceType: (state, action) => {
            state.serviceTypeGlobal = action.payload
        },
        addDeletedImageId: (state, action) => {
            state.deleteImageIds.push(action.payload); 
        },
        clearDeletedImageIds: (state) => {
            state.deleteImageIds = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(REHYDRATE, (state, action) => {
            return {
                projectDetails: {},
                serviceTypeGlobal: "",
                deleteImageIds: []
            };
        });
    }
})

export const { updateProjectDetails, deleteProjectDetails, updateServiceType, addDeletedImageId, clearDeletedImageIds } = projectSlice.actions

export default projectSlice.reducer