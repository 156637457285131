import { joi_property2, walk_through, somani_house1, brand_partner_4} from 'assets';
import React from 'react';
import { Carousel, Card } from 'react-bootstrap';

const StepsCarouselBrand = () => {
    const accordionItems = [

        {
            id: "step_1",
            title: "Showcase Your Product Collections",
            image:`${walk_through}`,
            // product_title:'Bedspreads/D-decor furnishings',
            content:
            "Sign up to showcase your product collection with detailed specifications, high-quality images, and pricing to attract designers and customers.",
            step: "Step 1",
          },
          {
            id: "step_2",
            title: "Craft Exceptional Design Inspiration",
            image:`${joi_property2}`,
            // product_title:'Outdoor Collection/Kajaria Tiles',
            content:
            "Designers will now highlight your product in their inspirations and also you can create inspirations also to showcase on real projects.",
            step: "Step 2",
          },
          {
            id: "step_3",
            title: "Generate direct and indirect sales",
            image:`${somani_house1}`,
            // product_title:'Bathtubs/hansgrohe sanitaryware',
            content:
            "Generate sales directly through projects executed by interioKeys as well as indirect sales through architects highlighting your products.",
            step: "Step 3",
          },

    ];

    return (
        <Carousel
            controls={false}
            indicators={true}
            interval={null}
            touch={true}
            className="builder-carousel w-100"
        >
            {accordionItems.map((item) => (
                <Carousel.Item key={item.id}>
                    <div className="mb-4 w-100">
                        <Card className="shadow-sm w-100 text-start" style={{ padding: '12px', borderRadius: '6px'}}>
                            <div className="px-0">
                                <div className="mb-2">
                                    <p className="text-gray-500 inter-400" style={{ fontSize: '12px', lineHeight: '15px' }}>{item.step}</p>
                                </div>

                                <p className="text-primary-500 inter-400 mb-0" style={{ fontSize: '14px' }}>{item.title}</p>

                                <p className="text-gray-900 inter-400" style={{ fontSize: '12px' }}>
                                    {item.content}
                                </p>

                                <Card.Img
                                    variant="top"
                                    src={item.image}
                                    className="mb-0"
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        objectFit: 'cover',
                                        borderRadius: '6px'
                                    }}
                                />

                                <div style={{ padding: '6px 0 0 0' }}>
                                    <p className="body-title inter-600 text-gray-800 mb-0">{item.product_title}</p>
                                    {/* <div className="body-text1 inter-400 text-gray-800">
                                        <p className="mb-0">{item.layout}</p>
                                        <p className="mb-0">{item.location}</p>
                                    </div> */}
                                </div>
                            </div>
                        </Card>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default StepsCarouselBrand;