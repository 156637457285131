import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaFile, FaFileImage, FaFilePdf, FaFileZipper } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import { useMediaQuery } from "react-responsive";
import DropFileInputProperty from "components/DragInput/DropFileInputProperty";
import UploadingFiles from "components/DragInput/UploadingFiles";
import { FiRefreshCcw, FiTrash2 } from "react-icons/fi";
import useFetchMedia from "utilities/customHooks/useFetchMedia";

function EditProperty() {
  const { propertyId } = useParams();
  const navigate = useNavigate();

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const user = useSelector((state) => state.auth.user);
  const propertyInfoRef = useRef();
  const [propertyData, setPropertyData] = useState(null);
  const [previousPincode, setPreviousPincode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [hover, setHover] = useState(false);
  const [projectTypes, setProjectTypes] = useState([]);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [layouts, setLayouts] = useState([]);
  const [pincodeLength, setPincodeLength] = useState(0);

  const [selectedProjectType, setSelectedProjectType] = useState("");
  const [selectedConstructionStatus, setSelectedConstructionStatus] = useState("");
  const [selectedPropertySegment, setSelectedPropertySegment] = useState("");

  const [isFileUploading, setIsFileUploading] = useState(false);
  const [parentId, setParentId] = useState(null);

  const [selectedPropertyImage, setSelectedPropertyImage] = useState(null);
  const [completeProjectFiles, setCompleteProjectFiles] = useState([]);
  // const [mediaList, setMediaList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emptyFields, setEmptyFields] = useState({});
  const [brochureFiles, setBrochureFiles] = useState([]);
  const [workingDrawingFiles, setWorkingDrawingFiles] = useState([]);
  const [structureDrawingFiles, setStructureDrawingFiles] = useState([]);
  const [mepDrawingFiles, setMepDrawingFiles] = useState([]);
  const [layoutErrors, setLayoutErrors] = useState({}); // New state for layout-specific errors

  const brochureInputRef = useRef(null);
  const workingDrawingInputRef = useRef(null);
  const structureDrawingInputRef = useRef(null);
  const mepDrawingInputRef = useRef(null);

  const constructionStatuses = [
    { id: 1, label: "Pre-Construction Phase", value: "PRE_CONSTRUCTION" },
    { id: 2, label: "Under Construction", value: "UNDER_CONSTRUCTION" },
    { id: 3, label: "Ready for Occupancy", value: "READY_FOR_OCCUPANCY" },
  ];

  const propertySegments = [
    { id: 1, label: "Affordable (₹2,500 to ₹4,000)", value: "AFFORDABLE_RESIDENTIAL" },
    { id: 3, label: "Mid-Range (₹4,000 to ₹7,000)", value: "MID_RANGE_RESIDENTIAL" },
    { id: 5, label: "Luxury (₹7,000 to ₹12,000)", value: "LUXURY_RESIDENTIAL" },
    { id: 7, label: "Ultra-Luxury (₹12,000+)", value: "ULTRA_LUXURY_RESIDENTIAL" },
  ];

  const defaultLayouts = [
    { id: 1, layout: "2BHK" },
    { id: 2, layout: "2.5BHK" },
    { id: 3, layout: "3BHK" },
    { id: 4, layout: "3.5BHK" },
    { id: 5, layout: "4BHK" },
    { id: 7, layout: "5BHK" },
    { id: 9, layout: "6BHK" },
    { id: 11, layout: "7BHK" },
    { id: 13, layout: "8BHK" },
  ];

  const fetchPropertyData = async () => {
    const response = await authActions.fetchPropertyById(propertyId);
    try {
      if (response.data) {
        setPropertyData(response.data);
        setPreviousPincode(response?.data?.pincode);
        setPincodeLength(response?.data?.pincode?.length);
        setSelectedProjectType(response.data.project_type);
        setSelectedConstructionStatus(response.data.construction_status);
        setSelectedPropertySegment(response.data.project_segment);
        setLayouts(response.data?.layout_type_data);
        setBrochureFiles(response?.data?.brochure_files || []);
        setWorkingDrawingFiles(response?.data?.working_drawing_files || []);
        setStructureDrawingFiles(response?.data?.structure_drawing_files || []);
        setMepDrawingFiles(response?.data?.mep_drawing_files || []);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { image, error } = useFetchMedia("property", "property", propertyId);

  // const fetchMedia = async () => {
  //   try {
  //     const response = await authActions.getMediaFiles("property", "property", propertyId);
  //     console.log(response, "response");
  //     if (response.length > 0) {
  //       setMediaList(response);
  //       setMediaFiles(response);
  //     } else {
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (propertyId) {
      // fetchMedia();
      fetchPropertyData();
    }
  }, [propertyId]);

  const fetchProjectTypes = async () => {
    const response = await authActions.fetchProjectTypes();
    try {
      if (response.data) {
        setProjectTypes(response.data?.results);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePropertyImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!allowedTypes.includes(file.type)) {
      setErrorMessage("Only JPG, JPEG, and PNG files are allowed.");
      setSelectedPropertyImage(null);
      return;
    }

    if (file.size > maxSize) {
      setErrorMessage("Image size must be less than 5MB.");
      setSelectedPropertyImage(null);
      return;
    }

    setErrorMessage("");
    setSelectedPropertyImage(file);
    setEmptyFields((prev) => ({ ...prev, property_img: false })); // Clear error when valid image is selected
  };

  const addLayout = () => {
    setLayouts([...layouts, { id: Date.now(), layout: "", area: "", totalFlats: "" }]);
  };

  const deleteLayout = (index) => {
    const updatedLayouts = [...layouts.slice(0, index), ...layouts.slice(index + 1)];
    setLayouts(updatedLayouts);
    validateTotalFlats(updatedLayouts); // Re-validate after deletion
  };

  // Centralized function to validate total flats
  const validateTotalFlats = (updatedLayouts) => {
    const totalLayoutFlats = updatedLayouts.reduce((sum, layout) => {
      return sum + (parseInt(layout.totalFlats) || 0);
    }, 0);
    const propertyFlats = parseInt(propertyData?.flats) || 0;

    if (totalLayoutFlats > propertyFlats) {
      setLayoutErrors((prev) => ({
        ...prev,
        totalFlats: `Total flats across layouts (${totalLayoutFlats}) exceeds property total (${propertyFlats})`,
      }));
      return false;
    } else {
      setLayoutErrors((prev) => ({ ...prev, totalFlats: "" }));
      return true;
    }
  };

  // Add a ref to store the timeout for debouncing
  const debounceTimeoutRef = useRef(null);

  const handleInputChange = (layoutId, field, value) => {
    const updatedLayouts = layouts.map((layout) =>
      layout.id === layoutId ? { ...layout, [field]: value } : layout
    );

    setLayouts(updatedLayouts);

    if (field === "totalFlats") {
      // Clear any existing timeout
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      // Set a new timeout to validate total flats
      debounceTimeoutRef.current = setTimeout(() => {
        validateTotalFlats(updatedLayouts);
      }, 500); // 500ms debounce delay
    }
  };

  const validatePossessionYear = (year) => {
    const currentYear = new Date().getFullYear();
    if (year && year.toString().length === 4 && parseInt(year) < currentYear) {
      notify(`Possession Year must be ${currentYear} or later!`, "error");
      return false;
    }
    return true;
  };

  const validatePincode = (pincode) => {
    const pincodeRegex = /^\d{6}$/;
    if (pincode && !pincodeRegex.test(pincode)) {
      notify("Pincode must be exactly 6 digits", "error");
      return false;
    }
    return true;
  };

  const validatePhoneNumber = (phone) => {
    if (phone.length < 10) return true;

    if (!phone || phone.trim() === "") {
      notify("Phone number is required", "error");
      return false;
    }

    if (!/^\d{10}$/.test(phone)) {
      notify("Phone number must be exactly 10 digits", "error");
      return false;
    }

    return true;
  };

  const handlePincodeKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      notify("Pincode must contain only digits", "error");
    }
  };

  const handlePhoneKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      notify("Phone number must contain only digits", "error");
    }
  };

  const handleChange = (name, value) => {
    // Clear empty field error when a value is entered
    if (value.trim() !== "") {
      setEmptyFields((prev) => ({ ...prev, [name]: false }));
    }

    if (name === "pincode") {
      if (value && !/^\d*$/.test(value)) {
        notify("Pincode must contain only digits", "error");
        return;
      }

      setPincodeLength(value.length);

      if (value.length < 6) {
        setPropertyData((prevData) => ({
          ...prevData,
          city: "",
          state: "",
          country: "",
        }));
      }

      if (value.length === 6 && value !== previousPincode) {
        if (validatePincode(value)) {
          getPincode(value);
        }
      }

      setPreviousPincode(value);
    }

    if (name === "phone_number") {
      if (!/^\d*$/.test(value)) {
        notify("Phone number must contain only digits", "error");
        return;
      }

      if (value.length > 10) return;

      if (value.length === 10) {
        validatePhoneNumber(value);
      }
    }

    if (name === "flats") {
      const newTotalFlats = parseInt(value) || 0;
      const totalLayoutFlats = layouts.reduce((sum, layout) => {
        return sum + (parseInt(layout.totalFlats) || 0);
      }, 0);

      if (newTotalFlats < 0) {
        notify(`Number of flats (${newTotalFlats}) should be greater than 0`, "error");
      }

      if (newTotalFlats < totalLayoutFlats) {
        notify(
          `New total flats (${newTotalFlats}) is less than the sum of flats in layouts (${totalLayoutFlats})`,
          "error"
        );
      }

      // Re-validate total flats when the property flats change
      const updatedLayouts = layouts; // Layouts remain unchanged
      validateTotalFlats(updatedLayouts);
    }

    setPropertyData((prevData) => {
      const newData = { ...prevData };
      newData[name] = value;
      console.log(`Updated propertyData:`, newData);
      return newData;
    });
  };

  const getPincode = async (pincode) => {
    try {
      if (pincode.length < 6 || pincode === previousPincode) return;

      const response = await authActions.fetchPincode(pincode);
      if (response && response.Pincode) {
        setPropertyData((prevDetails) => ({
          ...prevDetails,
          pincode: response.Pincode,
          country: response.Country || "",
          city: response.District || "",
          state: response.State || "",
        }));
      } else {
        setPropertyData((prevDetails) => ({
          ...prevDetails,
          country: "",
          city: "",
          state: "",
        }));
        notify("Invalid pincode or unable to fetch details", "error");
      }

      setPreviousPincode(pincode);
    } catch (error) {
      console.error(error);
      setPropertyData((prevDetails) => ({
        ...prevDetails,
        country: "",
        city: "",
        state: "",
      }));
      notify("Failed to fetch pincode details", "error");
    }
  };

  const generateTempId = () => `temp_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

  // const handleFiles = (files) => {
  //   const filesArray = Array.from(files);
  //   const newFiles = filesArray.filter((file) => {
  //     const isDuplicate = mediaList.some(
  //       (existingFile) => existingFile.temp_file_name === file.name
  //     );
  //     return !isDuplicate;
  //   });

  //   if (newFiles.length === 0) {
  //     notify("These files have already been added", "info");
  //     return;
  //   }

  //   setCompleteProjectFiles((prevFiles) => [...prevFiles, ...newFiles]);
  //   setMediaList((prevList) => [
  //     ...prevList,
  //     ...newFiles.map((file) => ({
  //       id: generateTempId(),
  //       temp_file_name: file.name,
  //       size: file.size,
  //       isNewFile: true,
  //     })),
  //   ]);
  // };

  // const handleDelete = (id) => {
  //   const fileToDelete = mediaList.find((file) => file.id === id);
  //   if (!fileToDelete) return;

  //   if (fileToDelete.isNewFile) {
  //     setCompleteProjectFiles((prevFiles) =>
  //       prevFiles.filter((file) => file.name !== fileToDelete.temp_file_name)
  //     );
  //   } else {
  //     setMediaFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  //   }

  //   setMediaList((prevList) => prevList.filter((file) => file.id !== id));
  // };

  const isEmpty = (value) => {
    if (value === undefined || value === null) return true;
    const stringValue = String(value);
    if (stringValue.trim() === "") return true;
    if (typeof value === "number" && isNaN(value)) return true;
    return false;
  };

  const validateForm = (data) => {
    const requiredFields = [
      { name: "property_name", label: "Property Name" },
      { name: "flats", label: "Number of Flats" },
      { name: "construction_status", label: "Construction Status" },
      { name: "possession_year", label: "Possession Year" },
      { name: "possession_month", label: "Possession Month" },
      { name: "address", label: "Address" },
      { name: "pincode", label: "Pincode" },
      { name: "city", label: "City" },
      { name: "state", label: "State" },
      { name: "country", label: "Country" },
      { name: "property_segment", label: "Property Segment" },
      { name: "name", label: "Name" },
      { name: "phone_number", label: "Phone Number" },
    ];

    let isValid = true;
    const newEmptyFields = {};

    for (const field of requiredFields) {
      if (isEmpty(data[field.name])) {
        newEmptyFields[field.name] = true;
        notify(`${field.label} is required`, "error");
        isValid = false;
      }
    }

    setEmptyFields(newEmptyFields);

    if (!validatePincode(data?.pincode)) {
      return false;
    }

    if (!data.city || !data.state || !data.country) {
      notify("Please enter a valid pincode to populate city, state, and country", "error");
      return false;
    }

    if (!validatePhoneNumber(data?.phone_number)) {
      return false;
    }

    if (!/^\d{4}$/.test(data?.possession_year)) {
      notify("Possession Year must be a 4-digit number", "error");
      return false;
    }

    // if (mediaFiles.length === 0 && completeProjectFiles.length === 0) {
    //   notify("Property Files are required. Please upload at least one file.", "error");
    //   return false;
    // }

    for (let i = 0; i < layouts.length; i++) {
      const layout = layouts[i];
      if (!layout.layout) {
        notify(`Layout Type is required for Layout ${i + 1}`, "error");
        return false;
      }
      if (!layout.area) {
        notify(`Layout Area is required for Layout ${i + 1}`, "error");
        return false;
      }
      if (!layout.totalFlats) {
        notify(`Total Flats is required for Layout ${i + 1}`, "error");
        return false;
      }
    }

    const totalLayoutFlats = layouts.reduce((sum, layout) => {
      return sum + (parseInt(layout.totalFlats) || 0);
    }, 0);

    const propertyFlats = parseInt(data?.flats) || 0;

    if (totalLayoutFlats > propertyFlats) {
      setLayoutErrors((prev) => ({
        ...prev,
        totalFlats: `Total flats across layouts (${totalLayoutFlats}) exceeds property total (${propertyFlats})`,
      }));
      return false;
    } else {
      setLayoutErrors((prev) => ({ ...prev, totalFlats: "" }));
    }

    return isValid;
  };

  const handlePossessionYearChange = (e) => {
    const year = e.target.value;
    if (/^\d{0,4}$/.test(year)) {
      handleChange("possession_year", year);
    }
  };

  const validateRequiredFiles = () => {
    let isValid = true;
    let errorMessage = "";

    if (brochureFiles.length === 0) {
      isValid = false;
      errorMessage = "Brochure files are required";
    } else if (workingDrawingFiles.length === 0) {
      isValid = false;
      errorMessage = "Working Drawing files are required";
    } else if (structureDrawingFiles.length === 0) {
      isValid = false;
      errorMessage = "Structure Drawing files are required";
    } else if (mepDrawingFiles.length === 0) {
      isValid = false;
      errorMessage = "MEP Drawing files are required";
    }

    if (!isValid) {
      notify(errorMessage, "error");
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formElement = propertyInfoRef.current;
    const formValues = {};

    if (propertyData?.phone_number?.length !== 10) {
      notify("Phone number must be exactly 10 digits", "error");
      return;
    }

    // Add file validation check
    if (!validateRequiredFiles()) {
      return;
    }

    const formInputs = formElement.querySelectorAll("input, select, textarea");
    formInputs.forEach((input) => {
      if (input.name) {
        formValues[input.name] = input.value;
      }
    });

    const updatedPropertyData = { ...propertyData, ...formValues };

    if (!validateForm(updatedPropertyData)) {
      return;
    }

    setLoading(true);
    const dataToUpdate = {
      property_name: propertyData?.property_name,
      flats: propertyData?.flats,
      construction_status: propertyData?.construction_status,
      property_year: propertyData?.property_year,
      address: propertyData?.address,
      city: propertyData?.city,
      country: propertyData?.country,
      pincode: propertyData?.pincode,
      state: propertyData?.state,
      project_type: propertyData?.project_type,
      property_segment: propertyData?.property_segment,
      phone_number: propertyData?.phone_number,
      name: propertyData?.name,
      possession_year: propertyData?.possession_year,
      possession_month: propertyData?.possession_month,
    };

    const existingFiles = [
      ...brochureFiles.filter(file => file.id).map(file => ({
        id: file.id,
        name: file.name || file.temp_file_name,
        type: 'brochure'
      })),
      ...workingDrawingFiles.filter(file => file.id).map(file => ({
        id: file.id,
        name: file.name || file.temp_file_name,
        type: 'working_drawing'
      })),
      ...structureDrawingFiles.filter(file => file.id).map(file => ({
        id: file.id,
        name: file.name || file.temp_file_name,
        type: 'structure_drawing'
      })),
      ...mepDrawingFiles.filter(file => file.id).map(file => ({
        id: file.id,
        name: file.name || file.temp_file_name,
        type: 'mep_drawing'
      }))
    ];

    const propertyDetails = {
      property: dataToUpdate,
      layouts: layouts,
      mediaFiles: existingFiles
    };

    const formData = new FormData();
    formData.append(`property`, JSON.stringify(propertyDetails.property));
    formData.append(`layouts`, JSON.stringify(propertyDetails.layouts));
    formData.append('mediaFiles', JSON.stringify(propertyDetails.mediaFiles));

    if (selectedPropertyImage) {
      formData.append("property_img", selectedPropertyImage);
    }

    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      const response = await authActions.updateProperty(propertyId, formData);
      if (response.data) {
        if (completeProjectFiles.length > 0) {
          setIsFileUploading(true);
          setParentId(response.data.id);
        } else {
          navigate(`/builder/properties`, { state: { reload: true } });
          notify("Property Updated!", "success");
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const calculateTotalBytes = (files) => {
    let totalBytes = 0;
    for (let i = 0; i < files.length; i++) {
      totalBytes += files[i].size || 0;
    }
    return totalBytes;
  };

  // Function to format bytes to readable format
  const formatFileSize = (bytes) => {
    if (bytes < 1024) return bytes + ' bytes';
    else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(2) + ' KB';
    else return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  };

  // Set the exact limit in bytes
  const MAX_FILE_SIZE_BYTES = 25 * 1024 * 1024; // 25MB in bytes

  const handleBrochureChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (!newFiles.length) return;

    const allowedExtensions = ['.pdf', '.zip'];
    const invalidFiles = newFiles.filter(file => {
      const ext = '.' + file.name.split('.').pop().toLowerCase();
      return !allowedExtensions.includes(ext);
    });

    if (invalidFiles.length > 0) {
      notify("Only PDF and ZIP files are accepted. Please remove invalid files.", "error");
      return; // Don't add any files if invalid types are detected
    }

    // Calculate using bytes for precision
    const currentBytes = calculateTotalBytes(brochureFiles);
    const newFilesBytes = calculateTotalBytes(newFiles);
    const totalBytes = currentBytes + newFilesBytes;

    // If adding would exceed limit, notify and don't add files
    if (totalBytes > MAX_FILE_SIZE_BYTES) {
      notify(`Cannot add files: total size would be ${formatFileSize(totalBytes)}, which exceeds the 25MB limit`, "error");
      return; // Don't add the files
    }

    // If we get here, it's safe to add the files
    setBrochureFiles([...brochureFiles, ...newFiles]);
  };

  const handleWorkingDrawingChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (!newFiles.length) return;

    const allowedExtensions = ['.dxf', '.dwg', '.zip'];
    const invalidFiles = newFiles.filter(file => {
      const ext = '.' + file.name.split('.').pop().toLowerCase();
      return !allowedExtensions.includes(ext);
    });

    if (invalidFiles.length > 0) {
      notify("Only DXF, DWG, and ZIP files are accepted. Please remove invalid files.", "error");
      return; // Don't add any files if invalid types are detected
    }

    const currentBytes = calculateTotalBytes(workingDrawingFiles);
    const newFilesBytes = calculateTotalBytes(newFiles);
    const totalBytes = currentBytes + newFilesBytes;

    if (totalBytes > MAX_FILE_SIZE_BYTES) {
      notify(`Cannot add files: total size would be ${formatFileSize(totalBytes)}, which exceeds the 25MB limit`, "error");
      return; // Don't add the files
    }

    setWorkingDrawingFiles([...workingDrawingFiles, ...newFiles]);
  };

  const handleStructureDrawingChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (!newFiles.length) return;

    const allowedExtensions = ['.dxf', '.dwg', '.zip'];
    const invalidFiles = newFiles.filter(file => {
      const ext = '.' + file.name.split('.').pop().toLowerCase();
      return !allowedExtensions.includes(ext);
    });

    if (invalidFiles.length > 0) {
      notify("Only DXF, DWG, and ZIP files are accepted. Please remove invalid files.", "error");
      return; // Don't add any files if invalid types are detected
    }

    const currentBytes = calculateTotalBytes(structureDrawingFiles);
    const newFilesBytes = calculateTotalBytes(newFiles);
    const totalBytes = currentBytes + newFilesBytes;

    if (totalBytes > MAX_FILE_SIZE_BYTES) {
      notify(`Cannot add files: total size would be ${formatFileSize(totalBytes)}, which exceeds the 25MB limit`, "error");
      return; // Don't add the files
    }

    setStructureDrawingFiles([...structureDrawingFiles, ...newFiles]);
  };

  const handleMepDrawingChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (!newFiles.length) return;

    const allowedExtensions = ['.dxf', '.dwg', '.zip'];
    const invalidFiles = newFiles.filter(file => {
      const ext = '.' + file.name.split('.').pop().toLowerCase();
      return !allowedExtensions.includes(ext);
    });

    if (invalidFiles.length > 0) {
      notify("Only DXF, DWG, and ZIP files are accepted. Please remove invalid files.", "error");
      return; // Don't add any files if invalid types are detected
    }

    const currentBytes = calculateTotalBytes(mepDrawingFiles);
    const newFilesBytes = calculateTotalBytes(newFiles);
    const totalBytes = currentBytes + newFilesBytes;

    if (totalBytes > MAX_FILE_SIZE_BYTES) {
      notify(`Cannot add files: total size would be ${formatFileSize(totalBytes)}, which exceeds the 25MB limit`, "error");
      return; // Don't add the files
    }

    setMepDrawingFiles([...mepDrawingFiles, ...newFiles]);
  };

  // File removal handlers
  const removeBrochureFile = (index) => {
    setBrochureFiles(prev => prev.filter((_, i) => i !== index));
    // Reset the file input so the same file can be selected again
    if (brochureInputRef.current) {
      brochureInputRef.current.value = "";
    }
  };
  
  const removeWorkingDrawingFile = (index) => {
    setWorkingDrawingFiles(prev => prev.filter((_, i) => i !== index));
    if (workingDrawingInputRef.current) {
      workingDrawingInputRef.current.value = "";
    }
  };
  
  const removeStructureDrawingFile = (index) => {
    setStructureDrawingFiles(prev => prev.filter((_, i) => i !== index));
    if (structureDrawingInputRef.current) {
      structureDrawingInputRef.current.value = "";
    }
  };
  
  const removeMepDrawingFile = (index) => {
    setMepDrawingFiles(prev => prev.filter((_, i) => i !== index));
    if (mepDrawingInputRef.current) {
      mepDrawingInputRef.current.value = "";
    }
  };

  useEffect(() => {
    fetchProjectTypes();
  }, []);

  useEffect(() => {
    // Create modified versions of brochure files (only for new files, not existing ones from API)
    const modifiedBrochureFiles = brochureFiles
      .filter(file => !file.id) // Only include new files
      .map(file => new File([file], `brochure_${file.name}`, { type: file.type }));

    // Create modified versions of drawing files with specific prefixes
    const modifiedWorkingDrawingFiles = workingDrawingFiles
      .filter(file => !file.id)
      .map(file => new File([file], `working_drawings_${file.name}`, { type: file.type }));

    const modifiedStructureDrawingFiles = structureDrawingFiles
      .filter(file => !file.id)
      .map(file => new File([file], `structure_drawings_${file.name}`, { type: file.type }));

    const modifiedMepDrawingFiles = mepDrawingFiles
      .filter(file => !file.id)
      .map(file => new File([file], `mep_drawings_${file.name}`, { type: file.type }));

    // Update the state with combined array
    setCompleteProjectFiles([
      ...modifiedBrochureFiles,
      ...modifiedWorkingDrawingFiles,
      ...modifiedStructureDrawingFiles,
      ...modifiedMepDrawingFiles
    ]);
  }, [brochureFiles, workingDrawingFiles, structureDrawingFiles, mepDrawingFiles]);

  const renderFileCard = (file, index, onRemove) => {
    const extension = file.name ? file.name.split('.').pop().toLowerCase() :
      (file.temp_file_name ? file.temp_file_name.split('.').pop().toLowerCase() : '');

    return (
      <Card
        key={index}
        className="d-flex flex-row justify-content-between align-items-center border-gray-100 shadow-none mb-1"
        style={{ borderRadius: 3 }}
      >
        <div className="d-flex align-items-center">
          {extension === "pdf" ? (
            <FaFilePdf className="text-primary-500 me-2" size={24} />
          ) : extension === "zip" ? (
            <FaFileZipper className="text-primary-500 me-2" size={24} />
          ) : extension === "dwg" ? (
            <FaFile className="text-info me-2" size={24} />
          ) : (
            <FaFile className="text-primary-500 me-2" size={24} />
          )}
          <div className="d-flex flex-column">
            <span className="body-text2 text-gray-900">{file.name || file.temp_file_name}</span>
            <span className="text-gray-600" style={{ fontSize: 10 }}>
              {typeof file.size === "number" ? `${(file.size / 1024).toFixed(2)} KB` : file.size}
            </span>
          </div>
        </div>
        <div className="cursor-pointer" onClick={() => onRemove(index)}>
          <FiTrash2 size={20} color="#3C4043" />
        </div>
      </Card>
    );
  };

  return (
    <Container
      className={`mt-5 pt-4 d-flex flex-column justify-content-center ${large ? "px-0" : ""}`}
      style={{ minHeight: "100vh" }}
    >
      <div className="d-flex justify-content-between align-items-center mt-5 pt-4">
        <h3 className="text-gray-900 inter-600 m-0">Edit Property Details</h3>
        <div className="d-flex">
          <Button
            className="d-flex align-items-center text-white body-text2 me-2"
            style={{ borderRadius: 3 }}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner animation="border" size="sm" className="me-2" /> Saving...
              </>
            ) : (
              <>Save Changes</>
            )}
          </Button>
          <Button
            className="bg-transparent border-gray-200 text-gray-900 body-text2"
            style={{ borderRadius: 3 }}
            disabled={loading}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row my-4 w-100">
        <div className={`p-3 me-2 border border-gray-100 ${large ? "w-50" : "w-100"}`} style={{ borderRadius: 6 }}>
          <h4 className="fw-bold">Property Details</h4>
          <div className="mt-4">
            <Form ref={propertyInfoRef}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500 ">
                  Property Image (Max. 5MB)<sup style={{ color: "red" }}>*</sup>
                  {errorMessage && <small className="text-danger mt-1">{errorMessage}</small>}
                </Form.Label>
                <Card
                  className="d-flex justify-content-center position-relative overflow-hidden cursor-pointer shadow-none p-0 mb-3"
                  style={{ width: 180, height: 150, border: `1px dashed ${emptyFields.property_img ? "red" : "#BDC1C6"}`, borderRadius: 6 }}
                >
                  <Image
                    className="p-0"
                    style={{ borderRadius: 6, objectFit: "cover" }}
                    maxWidth={180}
                    height="100%"
                    src={selectedPropertyImage ? URL.createObjectURL(selectedPropertyImage) : propertyData?.property_img}
                    alt="avatar"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  />
                  {hover && (
                    <div
                      className="position-absolute w-100 h-100 top-0 start-0 body-text2 text-white d-flex flex-column align-items-center justify-content-center"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: 6 }}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      onClick={() => document.getElementById("propertyImageUpload").click()}
                    >
                      <FiRefreshCcw size={20} color="white" />
                      <span className="mt-1">Change Property Image</span>
                    </div>
                  )}
                  <input
                    type="file"
                    id="propertyImageUpload"
                    style={{ display: "none" }}
                    onChange={handlePropertyImageChange}
                  />
                </Card>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Property Name<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  required
                  className="py-2 body-text2"
                  type="text"
                  name="property_name"
                  placeholder="Enter property name"
                  onChange={(e) => handleChange("property_name", e.target.value)}
                  value={propertyData?.property_name || ""}
                  style={{ border: `1px solid ${emptyFields.property_name ? "red" : "#BDC1C6"}`, borderRadius: 3 }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Number of Flats<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  required
                  className="py-2 body-text2"
                  min={0}
                  type="number"
                  placeholder="No. of flats"
                  name="flats"
                  onChange={(e) => handleChange("flats", e.target.value)}
                  value={propertyData?.flats || ""}
                  style={{ border: `1px solid ${emptyFields.flats ? "red" : "#BDC1C6"}`, borderRadius: 3 }}
                />
              </Form.Group>
              <Form.Group className="mb-3 d-flex">
                <div className="w-100">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Construction Status<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Select
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Select Status"
                    name="construction_status"
                    onChange={(e) => handleChange("construction_status", e.target.value)}
                    value={propertyData?.construction_status || ""}
                    style={{ border: `1px solid ${emptyFields.construction_status ? "red" : "#BDC1C6"}`, borderRadius: 3 }}
                  >
                    <option value="">Select</option>
                    {constructionStatuses.map((conStatus) => (
                      <option key={conStatus.id} value={conStatus.value}>
                        {conStatus.label}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="ms-2 w-100">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Possession Month & Year<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <div className="d-flex gap-2">
                    <Form.Select
                      required
                      className="py-2 body-text2"
                      name="possession_month"
                      onChange={(e) => handleChange("possession_month", e.target.value)}
                      value={propertyData?.possession_month || ""}
                      style={{
                        border: `1px solid ${emptyFields.possession_month ? "red" : "#BDC1C6"}`,
                        borderRadius: 3,
                        width: "50%",
                      }}
                    >
                      <option value="">Month</option>
                      {[
                        { label: "January", value: 1 },
                        { label: "February", value: 2 },
                        { label: "March", value: 3 },
                        { label: "April", value: 4 },
                        { label: "May", value: 5 },
                        { label: "June", value: 6 },
                        { label: "July", value: 7 },
                        { label: "August", value: 8 },
                        { label: "September", value: 9 },
                        { label: "October", value: 10 },
                        { label: "November", value: 11 },
                        { label: "December", value: 12 },
                      ].map((month) => (
                        <option key={month.value} value={month.value}>
                          {month.label}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control
                      required
                      className="py-2 body-text2"
                      type="number"
                      placeholder="Year"
                      name="possession_year"
                      onChange={(e) => handlePossessionYearChange(e)}
                      value={propertyData?.possession_year || ""}
                      style={{
                        border: `1px solid ${emptyFields.possession_year ? "red" : "#BDC1C6"}`,
                        borderRadius: 3,
                        width: "50%",
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
              <div className="mb-3 w-100">
                <Form.Group>
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Address<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Enter address"
                    name="address"
                    onChange={(e) => handleChange("address", e.target.value)}
                    value={propertyData?.address || ""}
                    style={{ border: `1px solid ${emptyFields.address ? "red" : "#BDC1C6"}`, borderRadius: 3 }}
                  />
                </Form.Group>
              </div>
              <div className="d-flex justify-content-between gap-2">
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Pincode<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Enter pincode"
                    name="pincode"
                    onChange={(e) => {
                      handleChange("pincode", e.target.value);
                      if (e.target.value.length === 6) {
                        getPincode(e.target.value);
                      }
                    }}
                    onKeyPress={handlePincodeKeyPress}
                    maxLength={6}
                    value={propertyData?.pincode || ""}
                    style={{ border: `1px solid ${emptyFields.pincode ? "red" : "#BDC1C6"}`, borderRadius: 3 }}
                  />
                </Form.Group>
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Country<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Enter country"
                    name="country"
                    disabled
                    value={propertyData?.country || ""}
                    style={{ border: `1px solid ${emptyFields.country ? "red" : "#BDC1C6"}`, borderRadius: 3 }}
                  />
                </Form.Group>
              </div>
              <div className="d-flex justify-content-between gap-2">
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    State<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Enter state"
                    name="state"
                    disabled
                    value={propertyData?.state || ""}
                    style={{ border: `1px solid ${emptyFields.state ? "red" : "#BDC1C6"}`, borderRadius: 3 }}
                  />
                </Form.Group>
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    City<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Enter city"
                    name="city"
                    disabled
                    value={propertyData?.city || ""}
                    style={{ border: `1px solid ${emptyFields.city ? "red" : "#BDC1C6"}`, borderRadius: 3 }}
                  />
                </Form.Group>
              </div>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Property Segment<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Select
                  required
                  className="py-2 body-text2"
                  type="text"
                  name="property_segment"
                  onChange={(e) => handleChange("property_segment", e.target.value)}
                  value={propertyData?.property_segment || ""}
                  style={{ border: `1px solid ${emptyFields.property_segment ? "red" : "#BDC1C6"}`, borderRadius: 3 }}
                >
                  <option value="">Select</option>
                  {propertySegments?.map((segment, index) => (
                    <option key={index} value={segment.value}>
                      {segment.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className={`p-3 border border-gray-100 ${large ? "ms-2 w-50" : "mt-4 w-100"}`} style={{ borderRadius: 6 }}>
          <h4 className="fw-bold">Property Details</h4>
          <div className="my-4">
            {layouts.map((layout, index) => (
              <div key={index}>
                <Row className="w-100 mt-2 me-2">
                  <Col xs={4} md={4} className="ps-3">
                    <Form.Group className="mb-3 mb-md-0">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Layout Type<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Select
                        required
                        className="py-2 body-text2"
                        name="layout"
                        value={layout.layout}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                        onChange={(e) => handleInputChange(layout.id, e.target.name, e.target.value)}
                      >
                        <option value="" disabled>
                          Select Layout
                        </option>
                        {defaultLayouts.map((layoutOption) => (
                          <option key={layoutOption.id} value={layoutOption.layout}>
                            {layoutOption.layout}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={3} md={4} className="px-1">
                    <Form.Group className="mb-3 mb-sm-0">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Layout Area<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <InputGroup style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}>
                        <Form.Control
                          required
                          className="py-2 body-text2 border-0"
                          type="number"
                          name="area"
                          placeholder="200"
                          value={layout.area}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              notify("Total Flats must be greater than 0.", 'error');
                              return;
                            } else {
                              handleInputChange(layout.id, e.target.name, e.target.value);
                            }
                          }}
                          style={{ border: "1px solid #BDC1C6", borderRadius: "3px 0 0 3px", width: "40%" }}
                        />
                        <Form.Select
                          className="body-text2 border-0"
                          name="unit"
                          value={layout.unit || "Sq.ft."}
                          onChange={(e) => handleInputChange(layout.id, e.target.name, e.target.value)}
                          style={{ border: "1px solid #BDC1C6", borderRadius: "0 3px 3px 0" }}
                        >
                          <option value="Sq.ft.">Sq.ft.</option>
                          <option value="Sq.m.">Sq.m.</option>
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={3} md={3} className="px-1">
                    <Form.Group className="mb-3 mb-sm-0">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Total Flats<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        required
                        className="py-2 body-text2"
                        type="number"
                        name="totalFlats"
                        placeholder="Enter total flats"
                        value={layout.totalFlats || ""}
                        style={{
                          border: layoutErrors.totalFlats ? "1px solid red" : "1px solid #BDC1C6",
                          borderRadius: 3,
                        }}
                        onChange={(e) => {
                          if (e.target.value < 0) {
                            notify("Total Flats must be greater than 0.", 'error');
                            return;
                          } else {
                            handleInputChange(layout.id, e.target.name, e.target.value);
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2} md={1} className="d-flex justify-content-end" style={{ display: "flex", alignItems: "center" }}>
                    {layouts.length > 1 && (
                      <Button className="d-flex align-items-center rounded-btn bg-gray" onClick={() => deleteLayout(index)}>
                        <FiTrash2 size={18} />
                      </Button>
                    )}
                  </Col>
                </Row>
                {layoutErrors.totalFlats && (
                  <OverlayTrigger
                    placement="bottom"
                    show={true}
                    overlay={
                      <Tooltip id={`tooltip-totalFlats-${index}`}>
                        {layoutErrors.totalFlats}
                      </Tooltip>
                    }
                  >
                    <div
                      className="text-danger mt-1"
                      style={{ fontSize: "0.75rem", cursor: "help" }}
                    >
                      <i className="fas fa-exclamation-circle me-1"></i>
                      {layoutErrors.totalFlats}
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            ))}
            <div className="mt-2 ms-2 body-text3 inter-500 cursor-pointer" >
              <span onClick={() => addLayout()} style={{ borderBottom: "1px solid #000000" }}>Add another layout</span>
            </div>
          </div>
          <h6 className="mb-1 body-text2 inter-500">Point of Contact</h6>
          <div className="d-flex justify-content-between gap-2">
            <Form.Group className="mb-3 w-100">
              <Form.Label className="mb-1 body-text2 inter-500">
                Name<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control
                required
                className="py-2 body-text2"
                type="text"
                placeholder="Enter Name"
                name="name"
                onChange={(e) => handleChange("name", e.target.value)}
                value={propertyData?.name || ""}
                style={{ border: `1px solid ${emptyFields.name ? "red" : "#BDC1C6"}`, borderRadius: 3 }}
              />
            </Form.Group>
            <Form.Group className="mb-3 w-100">
              <Form.Label className="mb-1 body-text2 inter-500">
                Phone No<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control
                required
                className="py-2 body-text2"
                type="tel"
                placeholder="Enter Phone Number"
                name="phone_number"
                maxLength={10}
                onChange={(e) => handleChange("phone_number", e.target.value)}
                onKeyPress={handlePhoneKeyPress}
                value={propertyData?.phone_number || ""}
                style={{ border: `1px solid ${emptyFields.phone_number ? "red" : "#BDC1C6"}`, borderRadius: 3 }}
              />
            </Form.Group>
          </div>

          {/* Brochure Files Section */}
          <div className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Brochure (Max 25MB)<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <div
              className="d-flex align-items-center border rounded cursor-pointer overflow-hidden"
              onClick={() => document.getElementById('brochureInput').click()}
              style={{ height: "38px" }}
            >
              <div className="bg-brand-500 body-text1 inter-500 text-white px-2 py-2 h-100 d-flex align-items-center">
                Choose files
              </div>
              <div className="px-3 text-muted body-text2 inter-500">
                {brochureFiles.length > 0 ? `${brochureFiles.length} file(s) selected` : 'No file chosen'}
              </div>
              <Form.Control
                id="brochureInput"
                type="file"
                ref={brochureInputRef}
                accept=".pdf,.zip"
                onChange={handleBrochureChange}
                className="d-none"
                multiple
              />
            </div>
            <small className="text-muted body-text2 inter-500">Only PDF and ZIP files are accepted</small>

            {brochureFiles.length > 0 && (
              <div className="drop-file-preview mt-2">
                {brochureFiles.map((file, index) => (
                  renderFileCard(file, index, removeBrochureFile)
                ))}
              </div>
            )}
          </div>

          {/* Working Drawing Files Section */}
          <div className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Working Drawing (Max 25MB)<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <div
              className="d-flex align-items-center border rounded cursor-pointer overflow-hidden"
              onClick={() => document.getElementById('workingDrawingInput').click()}
              style={{ height: "38px" }}
            >
              <div className="bg-brand-500 body-text1 inter-500 text-white px-2 py-2 h-100 d-flex align-items-center">
                Choose files
              </div>
              <div className="px-3 text-muted body-text2 inter-500">
                {workingDrawingFiles.length > 0 ? `${workingDrawingFiles.length} file(s) selected` : 'No file chosen'}
              </div>
              <Form.Control
                id="workingDrawingInput"
                type="file"
                ref={workingDrawingInputRef}
                accept=".dxf,.dwg,.zip"
                onChange={handleWorkingDrawingChange}
                className="d-none"
                multiple
              />
            </div>
            <small className="text-muted body-text2 inter-500">DXF, DWG, and ZIP files are accepted</small>

            {workingDrawingFiles.length > 0 && (
              <div className="drop-file-preview mt-2">
                {workingDrawingFiles.map((file, index) => (
                  renderFileCard(file, index, removeWorkingDrawingFile)
                ))}
              </div>
            )}
          </div>

          {/* Structure Drawing Files Section */}
          <div className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Structure Drawing (Max 25MB)<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <div
              className="d-flex align-items-center border rounded cursor-pointer overflow-hidden"
              onClick={() => document.getElementById('structureDrawingInput').click()}
              style={{ height: "38px" }}
            >
              <div className="bg-brand-500 body-text1 inter-500 text-white px-2 py-2 h-100 d-flex align-items-center">
                Choose files
              </div>
              <div className="px-3 text-muted body-text2 inter-500">
                {structureDrawingFiles.length > 0 ? `${structureDrawingFiles.length} file(s) selected` : 'No file chosen'}
              </div>
              <Form.Control
                id="structureDrawingInput"
                type="file"
                ref={structureDrawingInputRef}
                accept=".dxf,.dwg,.zip"
                onChange={handleStructureDrawingChange}
                className="d-none"
                multiple
              />
            </div>
            <small className="text-muted body-text2 inter-500">DXF, DWG, and ZIP files are accepted</small>

            {structureDrawingFiles.length > 0 && (
              <div className="drop-file-preview mt-2">
                {structureDrawingFiles.map((file, index) => (
                  renderFileCard(file, index, removeStructureDrawingFile)
                ))}
              </div>
            )}
          </div>

          {/* MEP Drawing Files Section */}
          <div className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              MEP Drawing (Max 25MB)<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <div
              className="d-flex align-items-center border rounded cursor-pointer overflow-hidden"
              onClick={() => document.getElementById('mepDrawingInput').click()}
              style={{ height: "38px" }}
            >
              <div className="bg-brand-500 body-text1 inter-500 text-white px-2 py-2 h-100 d-flex align-items-center">
                Choose files
              </div>
              <div className="px-3 text-muted body-text2 inter-500">
                {mepDrawingFiles.length > 0 ? `${mepDrawingFiles.length} file(s) selected` : 'No file chosen'}
              </div>
              <Form.Control
                id="mepDrawingInput"
                type="file"
                ref={mepDrawingInputRef}
                accept=".dxf,.dwg,.zip"
                onChange={handleMepDrawingChange}
                className="d-none"
                multiple
              />
            </div>
            <small className="text-muted body-text2 inter-500">DXF, DWG, and ZIP files are accepted</small>

            {mepDrawingFiles.length > 0 && (
              <div className="drop-file-preview mt-2">
                {mepDrawingFiles.map((file, index) => (
                  renderFileCard(file, index, removeMepDrawingFile)
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <UploadingFiles
        isEdit={true}
        show={isFileUploading}
        files={completeProjectFiles}
        setShow={setIsFileUploading}
        parentId={parentId}
        app_label={"property"}
        model_name={"property"}
      />
    </Container>
  );
};

export default EditProperty;