import { Button, Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ConsultaionCard from "./ConsultationCard";
import { FaRegHeart } from "react-icons/fa6";
import { useEffect, useState, useRef } from "react";
import ShareModal from "components/General/ShareModal";
import { FiSend } from "react-icons/fi";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import { useSelector } from "react-redux";
import LoginModal from "components/Auth/LoginModal";
import propertyImg from "../../assets/products/post-placeholder.webp";
import ProjectCardShimmer from "./ProjectCardShimmer";
import { RiEditLine } from "react-icons/ri";

const ProjectCard = ({
  item,
  id,
  slug,
  project_name,
  bhk,
  builder_name,
  architect,
  property_name,
  isConsultation,
  is_sample_flat,
  boxShadow,
  imgHeight = "300px",
  className,
  style,
  roomId,
  removeFavourite = false,
  brand = null,
  brand_name = null,
  is_architect = false,
  zIndex,
  videoRefCallback, // This will come from the parent using the useVideoPlayer hook
  onVideoPlay,      // This will come from the parent using the useVideoPlayer hook
  isPreview = false,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const { navbarToggle } = useSelector((state) => state.auth);
  const location = useLocation();
  const videoRef = useRef(null);

  const [showShare, setShowShare] = useState(false);
  const [hover, setHover] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState("");
  const [mediaType, setMediaType] = useState("image");
  const [videoElementReady, setVideoElementReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  
  // Generate a unique ID for this video element
  const videoCardId = `project-video-${id || Math.random().toString(36).substr(2, 9)}`;

  // Modified approach for video reference registration using onLoadedMetadata
  const handleVideoRef = (element) => {
    videoRef.current = element;

    // Register this video element with the parent component using the callback
    if (element && videoRefCallback) {
      // We don't need to check for play/pause functions here because
      // we'll register the element when metadata is loaded
      videoRef.current = element;
    }
  };

  // Handle when video metadata is loaded - this ensures the video element is fully initialized
  const handleVideoLoaded = () => {
    if (videoRef.current &&
      typeof videoRef.current.play === 'function' &&
      typeof videoRef.current.pause === 'function') {
      console.log("Video metadata loaded, element ready in ProjectCard:", videoCardId);
      setVideoElementReady(true);
      
      // Now it's safe to register the video with the parent
      if (videoRefCallback) {
        videoRefCallback(videoCardId, videoRef.current);
      }
    }
  };

  // Listen for external pause events
  useEffect(() => {
    const handleExternalPause = (e) => {
      if (e.detail && e.detail.currentVideoId !== videoCardId && isPlaying && videoRef.current) {
        console.log("Pausing video due to external event:", videoCardId);
        videoRef.current.pause();
        setIsPlaying(false);
      }
    };

    window.addEventListener("pause-other-videos", handleExternalPause);

    return () => {
      window.removeEventListener("pause-other-videos", handleExternalPause);
    };
  }, [videoCardId, isPlaying]);

  const checkProjectIfSaved = async () => {
    try {
      const response = await authActions.checkProjectIfSaved(user?.id, id);
      if (response) {
        setIsLiked(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProject = async () => {
    try {
      const response = await authActions.saveProject(user.id, id);
      if (response.data) {
        setIsLiked(true);
        notify("Project saved!", "success");
      } else {
        notify("Failed to save project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProject = async () => {
    try {
      const response = await authActions.unsaveProject(user.id, id);
      if (response) {
        setIsLiked(false);
        notify("Project unsaved!", "success");
      } else {
        notify("Failed to unsave project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMedia = async () => {
    try {
      const response = await authActions.getMediaFiles(
        "project",
        "room",
        roomId
      );
      if (response.length > 0) {
        setImage(response[0]?.file);
        setMediaType(response[0]?.media_type || "image");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (roomId) {
      fetchMedia();
    }
  }, [roomId]);

  useEffect(() => {
    if (user?.id != null) {
      checkProjectIfSaved();
    }
  }, [user]);

  if (isConsultation) {
    return <ConsultaionCard />;
  }

  // Handler for video play events
  const handleVideoPlay = (e) => {
    e.stopPropagation(); // Prevent navigation
    console.log("Video play triggered in ProjectCard:", videoCardId);
    setIsPlaying(true);

    // Notify parent component that this video is playing
    if (onVideoPlay) {
      onVideoPlay(videoCardId);
    }
  };

  return (
    <>
      <Card
        className={`cursor-pointer w-100 py-0 px-0 border rounded shadow-none overflow-hidden position-relative ${hover ? "border-gray-300" : "border-gray-50"} ${className}`}
        style={{
          boxShadow: boxShadow,
          ...style,
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {mediaType === "video" ? (
          <div
            style={{
              maxHeight: imgHeight,
              minHeight: imgHeight,
              position: "relative",
              overflow: "hidden"
            }}
            onClick={() => navigate(`/projects/${slug}`)}
          >
            <video
              ref={handleVideoRef}
              className="w-100 h-100 object-fit-cover"
              src={image}
              controls={hover} // Only show controls on hover
              autoPlay={false}
              muted
              // loop attribute removed to prevent looping
              onLoadedMetadata={handleVideoLoaded}
              onPlay={(e) => {
                setIsPlaying(true); // Update state when video plays
                if (onVideoPlay) {
                  onVideoPlay(videoCardId);
                }
              }}
              onPause={() => setIsPlaying(false)} // Update state when video pauses
              onEnded={() => {
                setIsPlaying(false); // Set playing state to false when video ends
                if (videoRef.current) {
                  videoRef.current.currentTime = 0; // Reset to beginning (optional)
                }
              }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent navigation when clicking on video
                if (!hover) {
                  navigate(`/projects/${slug}`);
                }
              }}
            >
              Your browser does not support the video tag.
            </video>
            {/* Optional play button overlay when not hovering */}
            {!isPlaying && !hover && (
              <div
                className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (videoRef.current) {
                    // Play the video
                    videoRef.current.play()
                      .then(() => {
                        setIsPlaying(true);
                        // Notify parent that this video is playing
                        if (onVideoPlay) {
                          onVideoPlay(videoCardId);
                        }
                      })
                      .catch(error => {
                        console.error("Error playing video:", error);
                      });
                  }
                }}
              >
                <div className="bg-primary rounded-circle d-flex justify-content-center align-items-center"
                  style={{ width: "50px", height: "50px" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-white">
                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                  </svg>
                </div>
              </div>
            )}
          </div>
        ) : (
          <Card.Img
            className="img-fluid"
            variant="top"
            src={image || propertyImg}
            style={{
              maxHeight: imgHeight,
              minHeight: imgHeight,
              objectFit: "cover",
            }}
            onClick={() => navigate(`/projects/${slug}`)}
          />
        )}
        {is_sample_flat && (
          <div
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              zIndex: zIndex !== undefined ? zIndex : 1000,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "#fff",
              padding: "2px 6px",
              borderRadius: "4px",
              fontSize: "0.75rem",
              fontWeight: 600,
            }}
          >
            Sample Flat
          </div>
        )}
        <div
          className="d-flex position-absolute"
          style={{
            zIndex: zIndex !== undefined ? zIndex : 999,
            right: 15,
            top: 10,
            filter: navbarToggle ? "brightness(60%)" : "none",
          }}
        >
          {/* Show edit button only if not in preview mode and user is an architect */}
          {!isPreview && user?.user_type === "architect" && (
            <Button
              className="d-flex justify-content-center align-items-center share-like-btn p-0 me-2"
              onClick={(e) => {
                e.stopPropagation(); // Prevent card navigation
                navigate(`/architect/editProject/${id}`, {
                  state: { item },
                });
              }}
            >
              <RiEditLine size={20} className="cursor-pointer" />
            </Button>
          )}

          <Button
            className="d-flex justify-content-center align-items-center share-like-btn p-0"
            onClick={(e) => {
              e.stopPropagation();
              setShowShare(true);
            }}
          >
            <FiSend size={20} />
          </Button>

          {/* Show edit icon for builders on sample flats - also not in preview mode */}
          {!isPreview &&
            is_sample_flat &&
            location?.pathname.includes("builder") &&
            user?.user_type === "builder" && (
              <Button
                onClick={() => navigate(`/builder/editProject/${id}`)}
                className="d-flex justify-content-center align-items-center share-like-btn ms-2 p-0"
              >
                <RiEditLine size={24} className="cursor-pointer" />
              </Button>
            )}

          {/* Show heart icon only if it's not a sample flat OR if it is a sample flat, the user is NOT logged in as a builder */}
          {!removeFavourite &&
            (!is_sample_flat ||
              (is_sample_flat && user?.user_type !== "builder")) && (
              <Button
                className={`d-flex justify-content-center align-items-center share-like-btn ms-2 p-0 ${isLiked && user ? "bg-primary text-white" : ""}`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!user) {
                    setShowLoginModal(true); // Show login modal if not logged in
                  } else {
                    isLiked ? handleUnsaveProject() : handleSaveProject(); // Handle save/unsave if logged in
                  }
                }}
              >
                <FaRegHeart
                  size={20}
                  color={isLiked && user ? "#FFFFFF" : ""}
                />
              </Button>
            )}
        </div>
        <Card.Body
          onClick={() => navigate(`/projects/${slug}`)}
          className="p-2 d-flex flex-column align-items-start"
        >
          <Card.Title
            className={`inter600 body-text1 mb-0 ${hover ? "text-primary-500" : "text-gray-800"}`}
          >
            {`${project_name}${bhk ? `/${bhk}` : ""}/${property_name}`}
          </Card.Title>
          <div className="body-text2 inter-400 text-gray-800 d-flex flex-column align-items-start">
            {architect && (
              <span className="ms-0">Architect: {architect ?? "-"}</span>
            )}
            {brand && <span className="ms-0">Brand: {brand_name ?? "-"}</span>}
            {!is_architect && (
              <span className="ms-0">Builder: {builder_name ?? "-"}</span>
            )}
            {!architect && !brand && (
              <span style={{ paddingBottom: "20px" }}></span>
            )}
          </div>
        </Card.Body>
      </Card>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={id}
        image={image}
        title={`${project_name}/${bhk}/${property_name}`}
        urlPath={`/projects/${slug}`}
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </>
  );
};

export default ProjectCard;