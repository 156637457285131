import { RiUpload2Fill } from "react-icons/ri";
import { useState, useEffect, useRef } from "react";
import { notify } from "utilities/notifications/Notify";

const DropFileInputProjectNew = ({
  className,
  onFileChange,
  allowMultiple = true,
}) => {
  const wrapperRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = [];
    let hasError = false;

    files.forEach((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedImageExtensions = ["png", "jpg", "jpeg", "webp"];
      const allowedVideoExtensions = ["mp4", "webm", "mov"];
      const allowedModelExtensions = ["dwg", "skp"];
      const allowedExtensions = [
        ...allowedImageExtensions,
        ...allowedVideoExtensions,
        ...allowedModelExtensions
      ];

      // 10MB limit for videos, 1MB for images
      const maxSize = file.type.startsWith("video/") ? 10 * 1024 * 1024 : 1024 * 1024;

      if (file.size > maxSize) {
        hasError = true;
        notify(
          `File too large: ${file.name}. ${file.type.startsWith("video/") ?
            "Videos must be under 10MB" :
            "Images must be under 1MB"}`,
          "error"
        );
      } else if (!allowedExtensions.includes(fileExtension)) {
        hasError = true;
        notify(
          `Invalid file type: ${fileExtension}. Allowed types: png, jpg, jpeg, mp4, webm, mov, dwg, skp`,
          "error"
        );
      } else {
        validFiles.push(file);
      }
    });

    if (!hasError && validFiles.length > 0) {
      setErrorMessage("");
      onFileChange(validFiles);
    }
  };

  return (
    <>
      {((!allowMultiple && fileList.length === 0) || allowMultiple) && (
        <div
          className={`${className} position-relative`}
          ref={wrapperRef}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          style={{
            borderRadius: 3,
            width: "160px",
            height: "160px",
            position: "relative",
          }}
        >
          {/* File input covers the entire box */}
          <input
            type="file"
            accept=".dwg,.skp,.png,.jpg,.jpeg,.mp4,.webm,.mov"
            multiple={allowMultiple}
            onChange={onFileDrop}
            className="cursor-pointer"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              opacity: 0,
              zIndex: 2,
              cursor: "pointer",
            }}
          />

          <div
            className="d-flex flex-column justify-content-center align-items-center border border-dashed"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <RiUpload2Fill size={24} className="text-dark mb-3" />
            <div className="text-center">
              Drop files here or{" "}
              <span className="text-primary">click to upload</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DropFileInputProjectNew;
