import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  Carousel,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import DropFileInputProduct from "components/DragInput/DropFileInputProduct";
import ReactSelect from "react-select";
import LoadingOverlay from "components/General/LoadingOverlay";
import { notify } from "utilities/notifications/Notify";
import * as authActions from "reduxFiles/actions/authActions";
import { FaCircleMinus, FaXmark } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import ProductRequirement from "./ProductRequirement";
import { FiUpload, FiUploadCloud } from "react-icons/fi";
import DropFileInputProject from "components/DragInput/DropFileInputProject";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import DropExcelFileInput from "components/DragInput/DropExcelFileInput";
import { default_property_image, product_placeholder_img } from "assets";
import DynamicProductFields from "components/DynamicField/DynamicProductField";
import BrochureUploader from "components/DragInput/BrochureUploader";
import axios from "axios";
import MultiProductBrochureUploader from "components/DragInput/MultiProductBrochureUploader";
import ExcelTemplateGenerator from "./ExcelTemplateGenerator";

function AddProduct() {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const { status } = useParams();
  const user = useSelector((state) => state.auth.user);

  const productInfoRef = useRef();
  const [uploadType, setUploadType] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [brandInfo, setBrandInfo] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState({
    uploadType: uploadType,
    product_category: "",
    collection_name: "",
    brand: "",
    product_type: "",
    product_sub_type: "",
    size: "",
    thickness: "",
    weight: "",
    material: "",
    color: "",
    pricing: "",
    price_range: "",
    discount_upto: "",
    surfaceFinish: "",
    description: "",
    features: "",
    brochure: null,
    images: [],
  });

  const [multipleProductDetails, setMultipleProductDetails] = useState({
    uploadType: uploadType,
    product_category: "",
    collection_name: "",
    description: "",
    features: "",
    brochure: null,
    products: [],
  });

  const [invalidFields, setInvalidFields] = useState({});
  const [validationTriggered, setValidationTriggered] = useState(false); // New state to track validation trigger
  const docRef = useRef(null);

  const [productCategories, setProductCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productSubTypes, setProductSubTypes] = useState([]);
  const [extractedData, setExtractedData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [customFieldValues, setCustomFieldValues] = useState({});
  const [productFieldsData, setProductFieldsData] = useState([]);
  const dynamicFieldsRef = useRef(null);
  const [pricingUnits, setPricingUnits] = useState(["Per-Sft", "Per-Unit", "Per-Kg"]);

  const [showBrochureUploader, setShowBrochureUploader] = useState(false);
  const [brochureToUpload, setBrochureToUpload] = useState(null);
  const [createdProductId, setCreatedProductId] = useState(null);
  const [dynamicFieldLabels, setDynamicFieldLabels] = useState({});

  const [createdProductIds, setCreatedProductIds] = useState([]);
  const [multipleBrochureToUpload, setMultipleBrochureToUpload] = useState(null);
  const [showMultipleBrochureUploader, setShowMultipleBrochureUploader] = useState(false);
  const [pricingUnitsLoading, setPricingUnitsLoading] = useState(false);

  const fetchCategories = async () => {
    try {
      const response = await authActions.fetchProductCategories();
      console.log(response);
      if (response?.data) {
        setProductCategories(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleFileUpload = () => {
    docRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSize = 100 * 1024 * 1024; // 100MB in bytes
      if (file.type === "application/pdf" || file.type === "application/zip") {
        if (file.size <= maxSize) {

          setBrochureToUpload(file);

          setProductDetails((prevDetails) => ({
            ...prevDetails,
            brochure: file,
          }));
        } else {
          notify("The file size exceeds the 100MB limit. Please upload a smaller file.", "error");
          e.target.value = null; // Reset the file input
        }
      } else {
        notify("Please upload a valid PDF or ZIP file.", "error");
        e.target.value = null;
      }
    }
  };

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSize = 100 * 1024 * 1024; // 100MB in bytes
      if (file.type === "application/pdf" || file.type === "application/zip") {
        if (file.size <= maxSize) {
          setMultipleProductDetails((prevDetails) => ({
            ...prevDetails,
            brochure: file,
          }));
        } else {
          notify("The file size exceeds the 100MB limit. Please upload a smaller file.", "error");
          e.target.value = null; // Reset the file input
        }
      } else {
        notify("Please upload a valid PDF or ZIP file.", "error");
        e.target.value = null; // Reset the file input
      }
    }
  };

  const handleImageChange = (newImages) => {
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      images: newImages, // Assuming 'files' is an array of uploaded images
    }));
  };

  const handleExcelAndContinue = async () => {
    setLoading(true);
    if (!selectedFile) {
      notify("Please select a file before continuing!", "error", 1);
      setLoading(false);
      return;
    }

    const formData = new FormData();
    console.log({ selectedFile });
    formData.append("file", selectedFile?.[0]);
    console.log({ formData });

    try {
      const response = await authActions.loadAndRetrieveDataFromFile(formData);
      if (response?.data) {
        console.log("Response data from excel: ", response?.data);
        setExtractedData(response.data.products);
        setMultipleProductDetails((prevDetails) => ({
          ...prevDetails,
          products: response.data.products, // Populate products field
        }));
      }
      setCurrentStep((prev) => prev + 1);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log({ multipleProductDetails });
  }, [multipleProductDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Handle custom fields differently (fields that come from product_fields)
    if (name.startsWith('custom_field_')) {
      // Check if the field has validation requirements based on field type
      const fieldId = name.replace('custom_field_', '');
      const field = productFieldsData.find(f => f.id.toString() === fieldId);

      if (field) {
        // Validate numeric fields
        if (field.field_type === 'int' || field.field_type === 'float' || field.field_type === 'decimal') {
          if (value !== '' && value < 0) {
            notify(`${field.label} should not be negative`, "error");
            return;
          }
        }

        // Validate text fields don't contain only numbers if they should be text
        if (field.field_type === 'string' || field.field_type === 'text') {
          if (value !== '' && /^\d+$/.test(value)) {
            notify(`${field.label} should contain text, not just numbers`, "error");
            return;
          }
        }
      }

      // Update custom field values
      setCustomFieldValues(prev => ({
        ...prev,
        [name]: value
      }));

      // Store the field label for preview rendering
      if (field) {
        setDynamicFieldLabels(prev => ({
          ...prev,
          [name]: field.label
        }));
      }
    } else {
      // Handle core product fields

      // Validate pricing fields
      if ((name === "price_range" || name === "discount_upto") && value !== '' && value < 0) {
        const fieldDisplayNames = {
          "price_range": "Pricing Range",
          "discount_upto": "Discount Upto"
        };
        const displayName = fieldDisplayNames[name] || name;
        notify(`${displayName} should not be negative`, 'error');
        return;
      }

      // Update product details
      setProductDetails((prev) => ({
        ...prev,
        [name]: value,
      }));

      // Handle category/type changes
      if (name === "product_category") {
        triggerProductTypes(value);
        // Clear dependent fields when category changes
        setProductDetails(prev => ({
          ...prev,
          product_type: "",
          product_sub_type: ""
        }));
        setProductTypes([]);
        setProductSubTypes([]);
        setProductFieldsData([]);
        setCustomFieldValues({});
      }

      if (name === "product_type") {
        triggerProductSubTypes(value);
        // Clear dependent fields when type changes
        setProductDetails(prev => ({
          ...prev,
          product_sub_type: ""
        }));
        setProductSubTypes([]);
        setProductFieldsData([]);
        setCustomFieldValues({});
      }
    }
  };

  const triggerProductTypes = async (id) => {
    try {
      const response = await authActions.fetchProductTypes(id);
      console.log(response);
      if (response?.data) {
        setProductTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const triggerProductSubTypes = async (id) => {
    try {
      const response = await authActions.fetchProductSubTypes(id);
      console.log(response);
      if (response?.data) {
        setProductSubTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setMultipleProductDetails((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Add these calls to fetch product types and subtypes
    if (name === "product_category") {
      triggerProductTypes(value);
    }

    if (name === "product_type") {
      triggerProductSubTypes(value);
    }
  };

  const handlePricing = (value, type) => {
    if (type === "price" && value < 0) {
      notify(`Price should not be negative`, "error");
      return;
    }

    const currentPricing = productDetails.pricing ? productDetails.pricing.split(" ") : ["", "Per-Sft"];
    const price = type === "price" ? value : currentPricing[0] || "";

    // Use the first available pricing unit if current doesn't exist in the options
    const currentUnit = currentPricing[1] || "Per-Sft";
    const unit = type === "unit" ? value : currentUnit;

    setProductDetails({
      ...productDetails,
      pricing: `${price} ${unit}`
    });
  };



  const validateSingleFields = () => {
    const requiredFields = {
      product_category: "Product Category",
      collection_name: "Collection Name",
      brand: "Product Name",
      product_type: "Product Type",
      product_sub_type: "Product Sub-Type",
    };

    // Create a map of required custom fields from product fields data
    const requiredCustomFields = {};
    productFieldsData.forEach(field => {
      if (field.required) {
        requiredCustomFields[`custom_field_${field.id}`] = field.label;
      }
    });

    const newInvalidFields = {};

    // Validate core product fields
    Object.keys(requiredFields).forEach((field) => {
      const value = productDetails[field] || "";
      newInvalidFields[field] = !value || value.trim() === "";
    });

    // Validate required custom fields
    Object.keys(requiredCustomFields).forEach((field) => {
      const value = customFieldValues[field] || "";
      newInvalidFields[field] = !value || value.trim() === "";
    });

    if (validationTriggered) {
      setInvalidFields(newInvalidFields);
    }

    // Consolidate missing fields, including both core and custom fields
    const missingFields = [
      ...Object.keys(newInvalidFields)
        .filter(field => !field.startsWith('custom_field_') && newInvalidFields[field])
        .map(field => requiredFields[field]),
      ...Object.keys(newInvalidFields)
        .filter(field => field.startsWith('custom_field_') && newInvalidFields[field])
        .map(field => requiredCustomFields[field])
    ];

    return missingFields.length === 0 ? null : missingFields;
  };

  const validateMultipleFields = () => {
    const requiredFields = {
      product_category: "Product Category",
    };
    const newInvalidFields = {};

    Object.keys(requiredFields).forEach((field) => {
      const value = multipleProductDetails[field] || "";
      newInvalidFields[field] = !value || value.trim() === "";
    });

    if (validationTriggered) {
      setInvalidFields(newInvalidFields);
    }

    const missingFields = Object.keys(newInvalidFields)
      .filter((field) => newInvalidFields[field])
      .map((field) => requiredFields[field]);

    return missingFields.length === 0 ? null : missingFields;
  };

  const handleContinue = () => {
    console.log("Saved Product Details:", productDetails);
    if (currentStep === 2 && uploadType === "single") {
      setValidationTriggered(true); // Trigger validation
      const missingFields = validateSingleFields();
      if (missingFields) {
        const errorMessage = `Please fill in: ${missingFields.join(", ")}`;
        notify(errorMessage, "error");
        return;
      }
      setInvalidFields({}); // Clear invalid fields on success
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handleMultipleContinue = () => {
    if (currentStep === 2 && uploadType === "multiple") {
      setValidationTriggered(true); // Trigger validation
      const missingFields = validateMultipleFields();
      if (missingFields) {
        const errorMessage = `Please fill in: ${missingFields.join(", ")}`;
        notify(errorMessage, "error");
        return;
      }
      setInvalidFields({}); // Clear invalid fields on success
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handleSubmitProduct = async (e) => {
    e.preventDefault();
    setLoading(true);

    setValidationTriggered(true); // Trigger validation
    const missingFields = validateSingleFields();
    if (missingFields) {
      const errorMessage = `Please fill in: ${missingFields.join(", ")}`;
      notify(errorMessage, "error");
      setLoading(false);
      return;
    }

    const formData = new FormData();

    // Add base required fields
    formData.append("uploadType", uploadType);
    formData.append("product_category", productDetails.product_category);
    formData.append("title", productDetails.brand);
    formData.append("collection", productDetails.collection_name);
    formData.append("brand", brandInfo?.id);
    formData.append("product_type", productDetails.product_type);
    formData.append("product_sub_type", productDetails.product_sub_type);

    // Add optional fields if they exist
    if (productDetails.pricing) {
      formData.append("cost", productDetails.pricing);
    }

    if (productDetails.surfaceFinish) {
      formData.append("texture", productDetails.surfaceFinish);
    }

    if (productDetails.description) {
      formData.append("description", productDetails.description);
    }

    if (productDetails.features) {
      formData.append("features", productDetails.features);
    }

    if (productDetails.price_range) {
      formData.append('price_range', productDetails.price_range);
    }

    if (productDetails.discount_upto) {
      formData.append('discount_upto', productDetails.discount_upto);
    }

    // Process dynamic fields
    const productFields = {};
    Object.entries(customFieldValues).forEach(([key, value]) => {
      if (key.startsWith('custom_field_')) {
        const fieldId = key.replace('custom_field_', '');
        productFields[fieldId] = value;
      }
    });

    // Add the product_fields JSON string to formData
    if (Object.keys(productFields).length > 0) {
      formData.append("product_fields", JSON.stringify(productFields));
    }

    // Add images
    if (productDetails.images && productDetails.images.length > 0) {
      productDetails.images.forEach((image) => {
        formData.append("images", image);
      });
    }

    try {
      const response = await authActions.createProduct(formData);
      if (response.status === 201) {
        // Check if we have a brochure to upload
        if (productDetails.brochure) {
          // Set the created product ID for the brochure uploader
          setCreatedProductId(response.data.id);
          // Show the brochure uploader modal
          setShowBrochureUploader(true);
          setBrochureToUpload(productDetails.brochure);
        } else {
          // If no brochure to upload, navigate to product library immediately
          notify("Product created successfully!", "success");
          navigate("/brand/product-library");
        }
      }
    } catch (error) {
      console.error("Error creating product:", error);
      notify(
        error.response?.data?.msg || error.response?.data?.error || "An error occurred!",
        "error",
        1
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitProducts = async (e) => {
    e.preventDefault();
    setLoading(true);
    setValidationTriggered(true); // Trigger validation
    const missingFields = validateMultipleFields();
    if (missingFields) {
      const errorMessage = `Please fill in: ${missingFields.join(", ")}`;
      notify(errorMessage, "error");
      setLoading(false);
      return;
    }

    // Validate if there are any products in the array
    if (!extractedData || extractedData.length === 0) {
      notify("No products to submit. Please upload a valid Excel file with product data.", "error");
      setLoading(false);
      return;
    }

    console.log("Total extracted data rows:", extractedData.length);
    console.log("First few rows from Excel:", extractedData.slice(0, 2));

    // First validation phase: check each product for required fields and valid references
    const invalidProducts = [];
    const validProducts = [];

    // Process each product row from the Excel data
    for (let index = 0; index < extractedData.length; index++) {
      const product = extractedData[index];
      const errors = [];

      // Skip completely empty rows
      if (!product || Object.keys(product).filter(key => product[key] !== null && product[key] !== undefined && product[key] !== "").length === 0) {
        continue;
      }

      // Skip template examples - ONLY exact "Sample Product Name" matches
      const isExactTemplateSample =
        (product.title === "Sample Product Name") ||
        (product.name === "Sample Product Name");

      if (isExactTemplateSample) {
        console.log("Skipping exact template sample row:", product);
        continue;
      }

      // Get key product properties with fallbacks for different naming conventions
      const title = product.title || product.name || '';

      // Check if product has type and subtype IDs in the data
      const hasTypeId = !!product.product_type_id;
      const hasSubTypeId = !!(product.product_subtype_id || product.product_sub_type_id);

      // Only use names as fallback if IDs aren't available
      const productTypeName = hasTypeId ? '' : (product.product_type || '');
      const productSubTypeName = hasSubTypeId ? '' : (product.product_sub_type || product.product_subtype || '');

      // Validate required fields
      if (!title.trim()) {
        errors.push("Product name/title is required");
      }

      // Validate that we have either type ID or type name
      if (!hasTypeId && !productTypeName.trim()) {
        errors.push("Product Type ID is required");
      }

      // Validate that we have either subtype ID or subtype name
      if (!hasSubTypeId && !productSubTypeName.trim()) {
        errors.push("Product Sub-Type ID is required");
      }

      // Create resolved type and subtype ID variables
      let resolvedTypeId = null;
      let resolvedSubTypeId = null;

      // For product type - use ID directly from Excel if available
      if (product.product_type_id) {
        resolvedTypeId = product.product_type_id;
      }
      // Only look up by name if ID is missing
      else if (productTypeName && productTypes && productTypes.length > 0) {
        // Case-insensitive comparison for product type name
        const productType = productTypes.find(
          type => type.title.toLowerCase() === productTypeName.toLowerCase()
        );

        if (productType) {
          resolvedTypeId = productType.id;
        } else {
          errors.push(`Product Type '${productTypeName}' not found`);
        }
      }

      // For product subtype - use ID directly from Excel if available
      if (product.product_subtype_id || product.product_sub_type_id) {
        resolvedSubTypeId = product.product_subtype_id || product.product_sub_type_id;
      }
      // Only look up by name if ID is missing
      else if (productSubTypeName && productSubTypes && productSubTypes.length > 0) {
        // Case-insensitive comparison for product subtype name
        const productSubType = productSubTypes.find(
          subType => subType.title.toLowerCase() === productSubTypeName.toLowerCase()
        );

        if (productSubType) {
          resolvedSubTypeId = productSubType.id;
        } else {
          errors.push(`Product Sub-Type '${productSubTypeName}' not found`);
        }
      }

      // Validate numeric fields
      if (product.cost && isNaN(parseFloat(product.cost))) {
        errors.push("Cost must be a number");
      } else if (product.cost && parseFloat(product.cost) < 0) {
        errors.push("Cost should not be negative");
      }

      if (product.discount_upto && isNaN(parseFloat(product.discount_upto))) {
        errors.push("Discount must be a number");
      } else if (product.discount_upto && parseFloat(product.discount_upto) < 0) {
        errors.push("Discount should not be negative");
      }

      // If any validation errors, add to invalid products
      if (errors.length > 0) {
        invalidProducts.push({
          index,
          name: title || `Row ${index + 2}`,
          errors
        });
      } else {
        // Add to valid products if it passes all validations
        // Create a new object with the resolved IDs included
        validProducts.push({
          ...product,
          resolvedTypeId,      // Store the resolved type ID
          resolvedSubTypeId    // Store the resolved subtype ID
        });
      }
    }

    console.log("Validation complete:", {
      totalProducts: extractedData.length,
      validProducts: validProducts.length,
      invalidProducts: invalidProducts.length
    });

    // If we have validation errors, display them and stop
    if (invalidProducts.length > 0) {
      const errorMessage = `Validation failed for ${invalidProducts.length} product(s). Please check the Excel file and fix the errors.`;
      notify(errorMessage, "error");

      // Show detailed errors for the first few invalid products
      const detailedErrors = invalidProducts.slice(0, 3).map(err =>
        `${err.name}: ${err.errors.join(', ')}`
      ).join('\n');

      if (detailedErrors) {
        setTimeout(() => {
          notify(`Details:\n${detailedErrors}${invalidProducts.length > 3 ? '\n...' : ''}`, "error", 5);
        }, 1000);
      }

      setLoading(false);
      return;
    }

    // If no valid products after filtering
    if (validProducts.length === 0) {
      notify("No valid products to submit after validation. Please check your Excel file and ensure it has products with correct Name, Product Type, and Product Sub-Type.", "error");
      setLoading(false);
      return;
    }

    // Prepare data for submission
    const formData = new FormData();
    formData.append("uploadType", uploadType);
    formData.append("brand", brandInfo?.id);
    formData.append("product_category", multipleProductDetails?.product_category);

    // Optional fields
    if (multipleProductDetails.collection_name) {
      formData.append("collection_name", multipleProductDetails.collection_name);
    }
    if (multipleProductDetails.description) {
      formData.append("description", multipleProductDetails.description);
    }
    if (multipleProductDetails.features) {
      formData.append("features", multipleProductDetails.features);
    }

    // Add brochure file if present
    const brochureFile = multipleProductDetails.brochure;

    // Prepare products array
    const productsArray = validProducts.map(product => {
      // Format cost to include unit if not already formatted
      let formattedCost = product.cost;
      if (formattedCost && !String(formattedCost).includes(' ')) {
        formattedCost = `${formattedCost} Per-Sft`;
      }

      // Process image URLs if present
      let imageData = null;
      if (product.image_urls) {
        // If image_urls is a string with URLs
        imageData = product.image || null;
      }

      // Extract dynamic fields from the product data
      // These are fields that aren't part of the standard product fields
      const standardFields = [
        'title', 'name', 'product_type', 'product_type_id', 'product_sub_type',
        'product_sub_type_id', 'product_subtype', 'product_subtype_id',
        'cost', 'collection', 'description', 'features', 'discount_upto',
        'brochure_url', 'image', 'image_urls', 'resolvedTypeId', 'resolvedSubTypeId',
        'brand_id', 'product_category_id'
      ];

      // Create a product_fields object for dynamic fields
      const productFields = {};

      // Loop through all properties in the product
      Object.keys(product).forEach(key => {
        // If it's not a standard field, it's a dynamic field
        if (!standardFields.includes(key) && product[key] !== null && product[key] !== undefined) {
          productFields[key] = product[key];
        }
      });

      // Create the final product object
      const finalProduct = {
        name: product.title || product.name,
        product_type: product.resolvedTypeId || product.product_type_id,
        product_sub_type: product.resolvedSubTypeId || product.product_subtype_id || product.product_sub_type_id,
        cost: formattedCost || "",
        collection: multipleProductDetails.collection_name || product.collection || "",
        description: product.description || multipleProductDetails.description || "",
        features: product.features || multipleProductDetails.features || "",
        discount_upto: product.discount_upto || "",
        brochure_url: product.brochure_url || "",
        image: imageData,
        image_urls: product.image_urls || ""
      };

      // Only add product_fields if there are any dynamic fields
      if (Object.keys(productFields).length > 0) {
        finalProduct.product_fields = productFields;
      }

      return finalProduct;
    });

    console.log("Submitting products:", productsArray.length);
    console.log("First product sample:", productsArray[0]);

    // Add products data to form
    formData.append("products", JSON.stringify(productsArray));

    try {
      // Submit the form data to create products
      const response = await authActions.createProduct(formData);

      if (response.status === 201) {
        // Extract all product IDs from the response
        const productIds = response.data.map(product => product.id);
        setCreatedProductIds(productIds);

        // If there's a brochure to upload, show the uploader
        if (brochureFile && productIds.length > 0) {
          setMultipleBrochureToUpload(brochureFile);
          setShowMultipleBrochureUploader(true);
        } else {
          // No brochure, just navigate away
          notify(`Successfully created ${productIds.length} products!`, "success");
          navigate("/brand/product-library");
        }
      }
    } catch (error) {
      console.error("Error creating products:", error);

      // Handle different error scenarios
      if (error.response) {
        // The server responded with an error
        if (error.response.data?.invalid_products) {
          // Process validation errors for individual products
          const invalidProducts = error.response.data.invalid_products;
          const errorCount = invalidProducts.length;

          // Display a summary error message
          notify(`Validation failed for ${errorCount} product(s). Please check the Excel file and fix the errors.`, "error");

          // Log detailed errors to console for debugging
          console.error("Product validation errors:", invalidProducts);

          // Optionally show more detailed errors for the first few invalid products
          const detailedErrors = invalidProducts.slice(0, 3).map(p =>
            `${p.name}: ${p.errors.join(', ')}`
          ).join('\n');

          if (detailedErrors) {
            setTimeout(() => {
              notify(`Examples of errors:\n${detailedErrors}${invalidProducts.length > 3 ? '\n...' : ''}`, "error", 5);
            }, 1000);
          }
        } else {
          // General server error with message
          notify(error.response.data?.error || error.response.data?.msg || "An error occurred while creating products!", "error");
        }
      } else {
        // Network error or other unexpected error
        notify("Failed to submit products. Please try again later.", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMultipleBrochureUploadComplete = () => {
    notify("Products Created with Brochure!", "success");
    navigate("/brand/product-library");
  };

  const handleMultipleBrochureUploadCancel = async () => {
    try {
      // Delete all created products
      const response = await axios({
        method: 'post', // or 'post' depending on your backend
        url: '/product/products/delete_multiple_products/',
        data: { productIds: createdProductIds }
      });

      notify("Upload canceled. Products were not created.", "info");
    } catch (error) {
      console.error("Error deleting products after cancel:", error);
      notify("Upload was canceled, but there was an issue deleting the products.", "error");
    } finally {
      navigate("/brand/product-library");
    }
  };


  const handleBrochureUploadComplete = async (fileId) => {
    try {
      // Make an API call to associate the uploaded brochure with the product
      // Using the ViewSet URL pattern: /api/products/{productId}/associate-brochure/
      await axios.post(`/product/products/${createdProductId}/associate-brochure/`, {
        fileId: fileId
      });

      notify("Product Created with Brochure!", "success");
      // Navigate to product library after successful association
      navigate("/brand/product-library");
    } catch (error) {
      console.error("Error associating brochure with product:", error);
      notify(
        "Product was created but there was an issue attaching the brochure. Please try adding the brochure from the product details page.",
        "warning"
      );
      // Still navigate to product library even if association fails
      navigate("/brand/product-library");
    }
  };

  const handleBrochureUploadCancel = () => {
    // Product should already be deleted by the BrochureUploader when canceling,
    // so we just need to notify the user and navigate
    notify("Upload canceled. Product was not created.", "info");

    // Navigate to product library
    navigate("/brand/product-library");
  };

  // Real-time validation after trigger
  useEffect(() => {
    if (validationTriggered && currentStep === 2) {
      if (uploadType === "single") {
        validateSingleFields();
      } else if (uploadType === "multiple") {
        validateMultipleFields();
      }
    }
  }, [productDetails, multipleProductDetails, validationTriggered, currentStep, uploadType]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchBrandByUser = async () => {
      try {
        const response = await authActions.fetchBrandByUser(user.id);
        if (response.data?.results[0]) {
          setBrandInfo(response.data.results[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchBrandByUser();
  }, [user]);

  // Effect to load product fields when subtype changes
  useEffect(() => {
    // Reset pricing units immediately on sub-type change
    setPricingUnitsLoading(true);

    // Clear custom fields when subtype changes
    setCustomFieldValues({});
    setDynamicFieldLabels({});

    // If we don't have a sub-type selected, use defaults and exit
    if (!productDetails.product_sub_type) {
      setProductFieldsData([]);
      setPricingUnits(["Per-Sft", "Per-Unit", "Per-Kg"]);
      setPricingUnitsLoading(false);
      return;
    }

    // Fetch product fields data for the new sub-type
    const fetchProductFieldsData = async () => {
      try {
        const response = await authActions.fetchProductFields(productDetails.product_sub_type);
        if (response?.data) {
          const fields = response.data.results;
          setProductFieldsData(fields);

          // After setting product fields data, check for units
          checkForDynamicUnits(fields);
        } else {
          // If no response or empty results, fall back to defaults
          resetToDefaultUnits();
        }
      } catch (e) {
        console.log('Error fetching product fields:', e);
        resetToDefaultUnits();
      }
    };

    const resetToDefaultUnits = () => {
      const defaultUnits = ["Per-Sft", "Per-Unit", "Per-Kg"];
      setPricingUnits(defaultUnits);

      // Update current pricing if needed
      const currentPrice = productDetails.pricing ? productDetails.pricing.split(" ")[0] || "" : "";
      const currentUnit = productDetails.pricing ? productDetails.pricing.split(" ")[1] : "";

      if (!currentUnit || !defaultUnits.includes(currentUnit)) {
        setProductDetails(prev => ({
          ...prev,
          pricing: `${currentPrice} ${defaultUnits[0]}`
        }));
      }

      setPricingUnitsLoading(false);
    };

    const checkForDynamicUnits = (fields) => {
      try {
        // Extract units from fields
        const units = [];

        fields.forEach(field => {
          if (field.unit && field.unit.short_code) {
            units.push({
              short_code: field.unit.short_code,
              name: field.unit.name || field.unit.short_code
            });
          }
        });

        // Use unique units
        const uniqueUnits = units.filter((unit, index, self) =>
          index === self.findIndex(u => u.short_code === unit.short_code)
        );

        if (uniqueUnits.length > 0) {
          // Found units, create pricing units
          const newPricingUnits = uniqueUnits
            .map(unit => unit.short_code ? `Per-${unit.short_code}` : null)
            .filter(Boolean);

          if (newPricingUnits.length > 0) {
            console.log("Setting new dynamic pricing units:", newPricingUnits);
            setPricingUnits(newPricingUnits);

            // Update current pricing if needed
            const currentPrice = productDetails.pricing ? productDetails.pricing.split(" ")[0] || "" : "";
            const currentUnit = productDetails.pricing ? productDetails.pricing.split(" ")[1] : "";

            if (!currentUnit || !newPricingUnits.includes(currentUnit)) {
              setProductDetails(prev => ({
                ...prev,
                pricing: `${currentPrice} ${newPricingUnits[0]}`
              }));
            }

            setPricingUnitsLoading(false);
            return;
          }
        }

        // If we get here, no valid units were found
        resetToDefaultUnits();
      } catch (error) {
        console.error("Error checking for field units:", error);
        resetToDefaultUnits();
      }
    };

    // Start the process
    fetchProductFieldsData();
  }, [productDetails.product_sub_type]);

  // Function to render dynamic field values in the preview
  // Function to render dynamic field values in the preview
  const renderDynamicFieldsPreview = () => {
    if (!Object.keys(customFieldValues).length) return null;

    return (
      <>
        {Object.entries(customFieldValues).map(([fieldKey, fieldValue]) => {
          // Get the field ID from the key
          const fieldId = fieldKey.replace('custom_field_', '');

          // Find the field definition to get proper label and unit
          const fieldDef = productFieldsData.find(f => f.id.toString() === fieldId);

          // Use the stored label or fallback to a generic label
          const fieldLabel = fieldDef ? fieldDef.label :
            dynamicFieldLabels[fieldKey] ||
            fieldKey.replace('custom_field_', 'Field ');

          // Add unit if available
          let displayValue = fieldValue || "--";
          if (fieldDef && fieldDef.unit && fieldDef.unit.short_code) {
            displayValue = `${displayValue} ${fieldDef.unit.short_code}`;
          }

          return (
            <p key={fieldKey} className="body-text1 inter-400 text-gray-900">
              {fieldLabel}: {displayValue}
            </p>
          );
        })}
      </>
    );
  };

  return (
    <Container
      fluid
      className="mt-5 pt-4 px-0 d-flex justify-content-center align-items-center"
      style={{ minHeight: "60vh" }}
    >
      {processing && (
        <LoadingOverlay isLoading={processing} setLoading={setProcessing} />
      )}
      <div
        className="d-flex flex-column flex-lg-row my-md-4"
        style={large ? { maxWidth: 1000, width: "100%" } : { width: "100%" }}
      >
        <div
          className="d-flex justify-content-center px-0 px-lg-0 py-4 py-lg-0"
          style={{ minWidth: 250 }}
        >
          <div className="d-flex flex-lg-column mt-2 mt-md-4 mt-lg-3 ml-2 mb-1 px-3 px-lg-0">
            <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
              <div
                className={`p-1 text-center rounded-btn-brand ${currentStep === 1
                  ? "bg-black text-white"
                  : "bg-success text-white"
                  }`}
              >
                {currentStep === 1 ? "1" : <FaCheck size={16} />}
              </div>
              <div
                className={`ms-1 ms-lg-2 inter-500 body-text1 ${currentStep !== 1 ? "text-success" : ""
                  }`}
              >
                Upload Type
              </div>
            </div>
            <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
              <div
                className={`p-1 text-center rounded-btn-brand ${currentStep === 2
                  ? "bg-black text-white"
                  : currentStep > 2
                    ? "bg-success text-white"
                    : "text-gray-300 bg-gray-25 border border-gray-100"
                  }`}
              >
                {currentStep <= 2 ? "2" : <FaCheck size={12} />}
              </div>
              <div
                className={`ms-1 ms-lg-2 inter-500 body-text1 ${currentStep > 2
                  ? "text-success"
                  : currentStep === 2
                    ? ""
                    : "text-gray-300"
                  }`}
              >
                Product Details
              </div>
            </div>
            <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
              <div
                className={`p-1 text-center rounded-btn-brand ${currentStep === 3
                  ? "bg-black text-white"
                  : currentStep > 3
                    ? "bg-success text-white"
                    : "text-gray-300 bg-gray-25 border border-gray-100"
                  }`}
              >
                {currentStep <= 3 ? "3" : <FaCheck size={12} />}
              </div>
              <div
                className={`ms-1 ms-lg-2 inter-500 body-text1 ${currentStep > 3
                  ? "text-success"
                  : currentStep === 3
                    ? ""
                    : "text-gray-300"
                  }`}
              >
                {uploadType === "single" ? "Product Images" : "Product Library"}
              </div>
            </div>
            <div className="d-flex align-items-center mb-0 mb-lg-4 me-2">
              <div
                className={`p-1 text-center rounded-btn-brand ${currentStep === 4
                  ? "bg-black text-white"
                  : "text-gray-300 bg-gray-25 border border-gray-100"
                  }`}
              >
                4
              </div>
              <div
                className={`ms-1 ms-lg-2 inter-500 body-text1 ${currentStep === 4 ? "text-gray-900" : "text-gray-300"
                  }`}
              >
                Product Preview
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 border mx-2 mx-lg0" style={{ borderRadius: 6 }}>
          {currentStep === 1 && (
            <div style={large ? { minWidth: 600 } : {}}>
              <h4 className="inter-600">{currentStep}. Upload Type</h4>
              <ProductRequirement
                uploadType={uploadType}
                setUploadType={setUploadType}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              />
              <Button
                className="bg-transparent border-gray-300 text-gray-900 body-text2 mt-3"
                style={{ borderRadius: 3, padding: "8px 14px" }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
          )}
          {currentStep === 2 && uploadType === "single" && (
            <div style={large ? { width: 600 } : {}}>
              <h4 className="inter-600">{currentStep}. Product Details</h4>
              <Form ref={productInfoRef}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Category<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Select
                    required
                    className="py-2 body-text2"
                    name="product_category"
                    value={productDetails?.product_category}
                    onChange={handleInputChange}
                    style={{
                      border: invalidFields.product_category
                        ? "1px solid red"
                        : "1px solid #BDC1C6",
                      borderRadius: 3,
                    }}
                  >
                    <option value="">Select</option>
                    {productCategories?.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </Form.Select>
                  {invalidFields.product_category && (
                    <Form.Text className="text-danger">
                      Product Category is required
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Collection Name<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    name="collection_name"
                    value={productDetails?.collection_name}
                    onChange={handleInputChange}
                    placeholder="Luxury Laminates"
                    style={{
                      border: invalidFields.collection_name
                        ? "1px solid red"
                        : "1px solid #BDC1C6",
                      borderRadius: 3,
                    }}
                  />
                  {invalidFields.collection_name && (
                    <Form.Text className="text-danger">
                      Collection Name is required
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Name<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    name="brand"
                    value={productDetails?.brand}
                    onChange={handleInputChange}
                    placeholder="Sb 1806"
                    style={{
                      border: invalidFields.brand
                        ? "1px solid red"
                        : "1px solid #BDC1C6",
                      borderRadius: 3,
                    }}
                  />
                  {invalidFields.brand && (
                    <Form.Text className="text-danger">
                      Product Name is required
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Type<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Select
                    required
                    className="py-2 body-text2"
                    name="product_type"
                    value={productDetails?.product_type}
                    onChange={handleInputChange}
                    style={{
                      border: invalidFields.product_type
                        ? "1px solid red"
                        : "1px solid #BDC1C6",
                      borderRadius: 3,
                    }}
                  >
                    <option value="">Select</option>
                    {productTypes?.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </Form.Select>
                  {invalidFields.product_type && (
                    <Form.Text className="text-danger">
                      Product Type is required
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Sub-Type<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Select
                    required
                    className="py-2 body-text2"
                    name="product_sub_type"
                    value={productDetails?.product_sub_type}
                    onChange={handleInputChange}
                    style={{
                      border: invalidFields.product_sub_type
                        ? "1px solid red"
                        : "1px solid #BDC1C6",
                      borderRadius: 3,
                    }}
                  >
                    <option value="">Select</option>
                    {productSubTypes?.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </Form.Select>
                  {invalidFields.product_sub_type && (
                    <Form.Text className="text-danger">
                      Product Sub-Type is required
                    </Form.Text>
                  )}
                </Form.Group>
                {productDetails.product_sub_type && (
                  <DynamicProductFields
                    productSubType={productDetails.product_sub_type}
                    onChange={handleInputChange}
                    values={customFieldValues}
                    invalidFields={invalidFields}
                    setInvalidFields={setInvalidFields}
                    ref={dynamicFieldsRef}
                  />
                )}
                <div className="w-100 mb-3">
                  <Form.Group>
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Pricing
                    </Form.Label>
                    <InputGroup className="d-flex">
                      <div
                        className="d-flex flex-grow-1"
                        style={{ flexBasis: "85%" }}
                      >
                        <InputGroup style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}>

                          <InputGroup.Text>₹</InputGroup.Text>

                          <Form.Control
                            className="body-text2 inter-500"
                            style={{ flexGrow: 1, border: 'none' }}
                            type="number"
                            min={0}
                            placeholder="300"
                            value={productDetails.pricing.split(" ")[0]} // Extract price
                            onChange={(e) =>
                              handlePricing(e.target.value, "price")
                            }
                          />
                        </InputGroup>
                      </div>
                      {pricingUnitsLoading ? (
                        // Show loading state when units are loading
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            border: "1px solid #BDC1C6",
                            flexBasis: "15%",
                            backgroundColor: "#f8f9fa"
                          }}
                        >
                          <Spinner animation="border" size="sm" className="me-1" />
                          {/* <span className="small">Loading...</span> */}
                        </div>
                      ) : (
                        // Show regular select when units are loaded
                        <Form.Select
                          className="body-text3 inter-500 bg-gray-50 text-gray-700"
                          style={{ border: "1px solid #BDC1C6", flexBasis: "15%" }}
                          value={productDetails.pricing.split(" ")[1] || "Per Sft"}
                          onChange={(e) => handlePricing(e.target.value, "unit")}
                        >
                          {pricingUnits.map((unit, index) => (
                            <option key={index} value={unit}>
                              {unit.replace('-', ' ')}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="w-100 mb-3">
                  {/* <Form.Group className="w-50">
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Pricing Range
                    </Form.Label>
                    <Form.Control
                      className="py-2 body-text2"
                      type="number"
                      min={0}
                      name="price_range"
                      value={productDetails?.price_range}
                      onChange={handleInputChange}
                      placeholder="Enter pricing range"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label className="mb-1 body-text2 inter-500">
                      Discount Upto (%)
                    </Form.Label>
                    <Form.Control
                      className="py-2 body-text2"
                      type="number"
                      min={0}
                      name="discount_upto"
                      value={productDetails?.discount_upto}
                      onChange={handleInputChange}
                      placeholder="Enter max discount"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </Form.Group>
                </div>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label className="body-text2 inter-500">
                    Brochure/Product Document (Max. 100MB)
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="No document uploaded"
                      name="brochure"
                      value={productDetails?.brochure ? productDetails.brochure.name : ""}
                      readOnly
                      className="body-text2 inter-500"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                    <Button
                      className="d-flex align-items-center body-text3 inter-500 bg-gray-50 text-gray-700"
                      onClick={handleFileUpload}
                    >
                      Upload Document
                      <FiUpload className="ms-1" />
                    </Button>
                    <input
                      type="file"
                      ref={docRef}
                      accept=".pdf,.zip"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </InputGroup>
                  <small className="text-muted body-text2 inter-500">Only PDF and ZIP files are accepted</small>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Description
                  </Form.Label>
                  <Form.Control
                    className="py-2 body-text2"
                    as="textarea"
                    rows={3}
                    name="description"
                    value={productDetails.description}
                    onChange={handleInputChange}
                    placeholder="Enter your product description here."
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3, resize: "none" }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Features
                  </Form.Label>
                  <Form.Control
                    className="py-2 body-text2"
                    as="textarea"
                    rows={3}
                    name="features"
                    value={productDetails.features}
                    onChange={handleInputChange}
                    placeholder="Enter your product features here."
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3, resize: "none" }}
                  />
                </Form.Group>
              </Form>
              <div className="d-flex">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={handleContinue}
                >
                  Continue
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCurrentStep(1)}
                >
                  Back
                </Button>
              </div>
            </div>
          )}
          {currentStep === 2 && uploadType === "multiple" && (
            <div style={large ? { width: 600 } : {}}>
              <h4 className="inter-600">{currentStep}. Product Details</h4>
              <Form ref={productInfoRef}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Category<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Select
                    required
                    className="py-2 body-text2"
                    name="product_category"
                    value={multipleProductDetails?.product_category}
                    onChange={handleInputChange2}
                    style={{
                      border: invalidFields.product_category
                        ? "1px solid red"
                        : "1px solid #BDC1C6",
                      borderRadius: 3,
                    }}
                  >
                    <option value="">Select</option>
                    {productCategories?.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </Form.Select>
                  {invalidFields.product_category && (
                    <Form.Text className="text-danger">
                      Product Category is required
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Type
                  </Form.Label>
                  <Form.Select
                    className="py-2 body-text2"
                    name="product_type"
                    value={multipleProductDetails?.product_type}
                    onChange={handleInputChange2}
                    style={{
                      border: invalidFields.product_type
                        ? "1px solid red"
                        : "1px solid #BDC1C6",
                      borderRadius: 3,
                    }}
                  >
                    <option value="">Select</option>
                    {productTypes?.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Sub-Type
                  </Form.Label>
                  <Form.Select
                    className="py-2 body-text2"
                    name="product_sub_type"
                    value={multipleProductDetails?.product_sub_type}
                    onChange={handleInputChange2}
                    style={{
                      border: invalidFields.product_sub_type
                        ? "1px solid red"
                        : "1px solid #BDC1C6",
                      borderRadius: 3,
                    }}
                  >
                    <option value="">Select</option>
                    {productSubTypes?.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {/* <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Collection Name<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    name="collection_name"
                    value={multipleProductDetails?.collection_name}
                    onChange={handleInputChange2}
                    placeholder="Luxury Laminates"
                    style={{
                      border: invalidFields.collection_name
                        ? "1px solid red"
                        : "1px solid #BDC1C6",
                      borderRadius: 3,
                    }}
                  />
                  {invalidFields.collection_name && (
                    <Form.Text className="text-danger">
                      Collection Name is required
                    </Form.Text>
                  )}
                </Form.Group> */}
                {/* <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label className="body-text2 inter-500">
                    Brochure/Product Document (Max. 100MB)
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="No document uploaded"
                      name="brochure"
                      value={
                        multipleProductDetails?.brochure ? multipleProductDetails.brochure.name : ""
                      }
                      readOnly
                      className="body-text2 inter-500"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                    <Button
                      className="d-flex align-items-center body-text3 inter-500 bg-gray-50 text-gray-700 border-none"
                      onClick={handleFileUpload}
                    >
                      Upload Document
                      <FiUpload className="ms-1" />
                    </Button>
                    <input
                      type="file"
                      ref={docRef}
                      accept=".pdf,.zip"
                      style={{ display: "none" }}
                      onChange={handleFileChange2}
                    />
                  </InputGroup>
                  <small className="text-muted body-text2 inter-500">Only PDF and ZIP files are accepted</small>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Description
                  </Form.Label>
                  <Form.Control
                    className="py-2 body-text2"
                    as="textarea"
                    rows={3}
                    name="description"
                    placeholder="Describe the project..."
                    value={multipleProductDetails?.description}
                    onChange={handleInputChange2}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3, resize: "none" }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Product Features
                  </Form.Label>
                  <Form.Control
                    className="py-2 body-text2"
                    as="textarea"
                    rows={3}
                    name="features"
                    placeholder="Add project features"
                    value={multipleProductDetails?.features}
                    onChange={handleInputChange2}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3, resize: "none" }}
                  />
                </Form.Group> */}
              </Form>
              <div className="d-flex">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={handleMultipleContinue}
                >
                  Continue
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCurrentStep(1)}
                >
                  Back
                </Button>
              </div>
            </div>
          )}
          {currentStep === 3 && uploadType === "single" && (
            <div style={large ? { width: 600 } : {}}>
              <h4 className="inter-600">{currentStep}. Product Images</h4>
              <div className="inter-400 body-text3 text-gray-600 mb-1">
                Tip: Please upload the images (.jpg/.jpeg/.png) relevant to your product only.
              </div>
              <DropFileInputProduct
                onFileChange={handleImageChange}
                fileList={productDetails?.images || []}
              />
              <div className="d-flex mt-3">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={handleContinue}
                >
                  Continue
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCurrentStep(2)}
                >
                  Back
                </Button>
              </div>
            </div>
          )}
          {currentStep === 3 && uploadType === "multiple" && (
            <div style={large ? { width: 600 } : {}}>
              <h4 className="inter-600">
                {currentStep}. Product Library<sup style={{ color: "red" }}>*</sup>
              </h4>
              <div className="inter-400 body-text3 text-gray-600 mb-1">
                Tip: Please upload our template excel (.xlsx) file to add all your products. (Max. 8MB)
              </div>

              {/* Add the custom template generator here */}
              <div className="mb-3">
                <ExcelTemplateGenerator
                  brandInfo={brandInfo}
                  productCategory={multipleProductDetails.product_category}
                  productType={multipleProductDetails.product_type}
                  productSubType={multipleProductDetails.product_sub_type}
                  productCategories={productCategories}
                  productTypes={productTypes}
                  productSubTypes={productSubTypes}
                />
                <div className="small text-muted">
                  Download a template pre-filled with your selected values
                </div>
              </div>

              <DropExcelFileInput
                file={selectedFile}
                onFileChange={(file) => setSelectedFile(file)}
              />
              {/* <div className="mt-1 body-text3">
                <p className="text-gray-600">
                  Don't have the CSV format?{" "}
                  <a
                    href="/format.xlsx"
                    download="format.xlsx"
                    className="text-primary cursor-pointer"
                    style={{ textDecoration: "none" }}
                  >
                    Download Format Here
                  </a>
                </p>
              </div> */}
              <div className="d-flex mt-3">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={handleExcelAndContinue}
                  disabled={loading}
                >
                  {loading && <Spinner animation="border" size="sm" className="me-2" />}
                  Continue
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCurrentStep(2)}
                >
                  Back
                </Button>
              </div>
            </div>
          )}
          {currentStep === 4 && uploadType === "single" && (
            <div style={large ? { width: 600 } : {}}>
              <h4 className="inter-600">{currentStep}. Product Preview</h4>
              <div className="mb-3">
                {productDetails.images && productDetails.images.length > 0 ? (
                  <Carousel controls={true} indicators={true} interval={null}>
                    {productDetails.images.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Product Image ${index + 1}`}
                          style={{
                            width: "100%",
                            height: "300px",
                            objectFit: "cover",
                            borderRadius: "6px",
                          }}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                ) : (
                  <img
                    src={product_placeholder_img}
                    alt="No Image Available"
                    style={{
                      width: "100%",
                      maxHeight: "300px",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                )}
              </div>
              <div className="position-relative my-3 d-flex align-items-cen">
                <div className="h4 inter-600 text-gray-900">Product Details</div>
                <button
                  className="position-absolute btn btn-light"
                  onClick={() => setCurrentStep(2)}
                  style={{ top: "10px", right: "10px", borderRadius: "50" }}
                >
                  <RiEditLine />
                </button>
              </div>
              <div className="mb-3">
                <p className="body-text1 inter-400 text-gray-900">
                  Product Category:{" "}
                  {productCategories.find(
                    (category) => category.id === productDetails.product_category
                  )?.title || ""}
                </p>
                <p className="body-text1 inter-400 text-gray-900">
                  Collection Name: {productDetails.collection_name}
                </p>
                <p className="body-text1 inter-400 text-gray-900">
                  Product Name: {productDetails.brand}
                </p>
                <p className="body-text1 inter-400 text-gray-900">
                  Product Type:{" "}
                  {productTypes.find((type) => type.id === productDetails.product_type)?.title || ""}
                </p>
                <p className="body-text1 inter-400 text-gray-900">
                  Product Sub-Type:{" "}
                  {productSubTypes.find(
                    (subtype) => subtype.id === productDetails.product_sub_type
                  )?.title || ""}
                </p>
                <hr />
                {/* Render Dynamic Fields in Preview */}
                {renderDynamicFieldsPreview()}
                <p className="body-text1 inter-400 text-gray-900">
                  Pricing: {productDetails.pricing}
                </p>
                <hr />
              </div>
              <h5 className="body-text1 inter-400 text-gray-900">Product Description</h5>
              <p className="body-text1 inter-400 text-gray-900">{productDetails.description}</p>
              <div className="body-text1 inter-400 text-gray-900">Product Features</div>
              <p className="body-text1 inter-400 text-gray-900">{productDetails.features}</p>
              <h5 className="body-text1 inter-400 text-gray-900">Product Brochure</h5>
              <div
                className="d-flex flex-column justify-content-center align-items-start"
                style={{ padding: "0.5rem", borderRadius: 5 }}
              >
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <div className="d-flex flex-column">
                    <span className="body-text2 inter-400 text-gray-900">
                      {productDetails.brochure?.name || "No document uploaded"}
                    </span>
                    {productDetails.brochure && (
                      <span className="inter-400 text-gray-600" style={{ fontSize: "10px" }}>
                        {(productDetails.brochure.size / 1024).toFixed(2)} KB
                      </span>
                    )}
                  </div>
                  {productDetails.brochure && (
                    <RiDeleteBin6Line className="align-self-center" style={{ cursor: "pointer" }} />
                  )}
                </div>
              </div>
              <div className="d-flex mt-3">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={handleSubmitProduct}
                  disabled={loading}
                >
                  {loading && <Spinner animation="border" size="sm" className="me-2" />}
                  Submit Product
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  disabled={loading}
                  onClick={() => setCurrentStep(3)}
                >
                  Back
                </Button>
              </div>
            </div>
          )}
          {currentStep === 4 && uploadType === "multiple" && (
            <div style={large ? { maxWidth: 600 } : {}}>
              <div className="h4 inter-600">{currentStep}. Product Preview</div>
              <div className="position-relative my-3 d-flex align-items-center">
                <button
                  className="position-absolute btn btn-light"
                  onClick={() => setCurrentStep(3)}
                  style={{ top: "10px", right: "10px", borderRadius: "50" }}
                >
                  <RiEditLine />
                </button>
              </div>
              <p className="body-text1 inter-400 text-gray-900">
                Product Category:{" "}
                {productCategories.find(
                  (category) => category.id === multipleProductDetails.product_category
                )?.title || ""}
              </p>

              {/* Show uploaded file info */}
              <div className="mt-3 mb-3">
                <h5 className="body-text1 inter-400 text-gray-900">
                  Uploaded Excel File
                </h5>
                <p className="body-text2 inter-400 text-gray-600">
                  {selectedFile && selectedFile[0] ? selectedFile[0].name : "No file selected"}
                  {selectedFile && selectedFile[0] && (
                    <span className="ms-2">
                      ({(selectedFile[0].size / 1024).toFixed(2)} KB)
                    </span>
                  )}
                </p>
              </div>

              {/* Display products count summary */}
              <div className="mb-3">
                <h5 className="body-text1 inter-400 text-gray-900">
                  Products Summary
                </h5>
                <p className="body-text1 inter-400 text-gray-900">
                  Total Products: <strong>{extractedData.length}</strong>
                </p>
              </div>

              {/* Dynamic table that shows all fields from Excel */}
              {extractedData && extractedData.length > 0 ? (
                <div className="table-responsive">
                  <Table bordered hover>
                    <thead>
                      <tr>
                        {/* Image column if available */}
                        {extractedData.some(product => product.image !== undefined) &&
                          <th style={{ minWidth: '60px' }}>Image</th>
                        }

                        {/* Get all unique keys from all products */}
                        {Array.from(new Set(
                          extractedData.flatMap(product =>
                            Object.keys(product).filter(key => key !== 'image')
                          )
                        )).map((key, index) => (
                          <th key={index} style={{ minWidth: '120px', whiteSpace: 'nowrap' }}>
                            {/* Format header names for better readability */}
                            {key
                              .replace(/_/g, ' ')
                              .split(' ')
                              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                              .join(' ')}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {extractedData.map((product, rowIndex) => (
                        <tr key={rowIndex}>
                          {/* Show image if any product has an image */}
                          {extractedData.some(p => p.image !== undefined) && (
                            <td>
                              {product.image ? (
                                <img
                                  src={`data:image/png;base64,${product.image}`}
                                  alt={product.name || product.title || `Product ${rowIndex + 1}`}
                                  style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: 5 }}
                                  onError={(e) => {
                                    e.target.src = product_placeholder_img;
                                  }}
                                />
                              ) : (
                                <img
                                  src={product_placeholder_img}
                                  alt="No image"
                                  style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: 5 }}
                                />
                              )}
                            </td>
                          )}

                          {/* Display all other fields dynamically, ensuring all columns are represented */}
                          {Array.from(new Set(
                            extractedData.flatMap(product =>
                              Object.keys(product).filter(key => key !== 'image')
                            )
                          )).map((key, cellIndex) => (
                            <td key={cellIndex} className="align-middle">
                              {/* Format the value based on its type or key name */}
                              {product[key] !== undefined ? (
                                typeof product[key] === 'string' && product[key].length > 50 ? (
                                  <div className="text-truncate" style={{ maxWidth: '150px' }} title={product[key]}>
                                    {product[key]}
                                  </div>
                                ) : key.toLowerCase().includes('url') && product[key] && String(product[key]).startsWith('http') ? (
                                  <a href={product[key]} target="_blank" rel="noopener noreferrer">View</a>
                                ) : key.toLowerCase().includes('cost') || key.toLowerCase().includes('price') ? (
                                  isNaN(product[key]) ? product[key] : `₹${product[key]}`
                                ) : key.toLowerCase().includes('discount') ? (
                                  isNaN(product[key]) ? product[key] : `${product[key]}%`
                                ) : Array.isArray(product[key]) ? (
                                  product[key].join(', ')
                                ) : typeof product[key] === 'object' && product[key] !== null ? (
                                  JSON.stringify(product[key])
                                ) : (
                                  String(product[key])
                                )
                              ) : (
                                ''
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="alert alert-warning">
                  No products found in the uploaded Excel file.
                </div>
              )}

              <div className="d-flex mt-3">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={handleSubmitProducts}
                  disabled={loading || extractedData.length === 0}
                >
                  {loading && <Spinner animation="border" size="sm" className="me-2" />}
                  Submit Products ({extractedData.length})
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCurrentStep(3)}
                >
                  Back
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      {showBrochureUploader && brochureToUpload && createdProductId && (
        <BrochureUploader
          file={brochureToUpload}
          show={showBrochureUploader}
          setShow={setShowBrochureUploader}
          productId={createdProductId}
          onUploadComplete={handleBrochureUploadComplete}
          onUploadCancel={handleBrochureUploadCancel}
        />
      )}

      {showMultipleBrochureUploader && multipleBrochureToUpload && createdProductIds.length > 0 && (
        <MultiProductBrochureUploader
          file={multipleBrochureToUpload}
          show={showMultipleBrochureUploader}
          setShow={setShowMultipleBrochureUploader}
          productIds={createdProductIds}
          onUploadComplete={handleMultipleBrochureUploadComplete}
          onUploadCancel={handleMultipleBrochureUploadCancel}
        />
      )}
    </Container>
  );
}

export default AddProduct;