import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Breakpoint } from "react-socks";
import { truncateText } from "utilities/truncateText";
import Slider from "react-slick";
import { project_image_1, project_image_2, project_image_3 } from "assets";
import { useNavigate } from "react-router-dom";
import { notify } from "utilities/notifications/Notify";
import BrandOnInspirationsCard from "./BrandOnInspirationCard";

const BrandOnInspirations = ({ recommendedProjects }) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const maxLarge = useMediaQuery({ query: "(max-width: 991px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });
  const navigate = useNavigate();

  let sliderRef = useRef(null);
  var settings = {
    customPaging: function (i) {
      return <div className="custom-dot mt-3" />;
    },
    dotsClass: "slick-dots",
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [brandImages, setBrandImages] = useState({
    mainImage: { id: 1, title: "Jewel of India", image: project_image_1 },
    secondColumnImages: [
      { id: 2, title: "Jewel of India", image: project_image_2 },
      { id: 3, title: "Jewel of India", image: project_image_3 },
    ],
  });

  const handleImageClick = (index, id) => {
    setBrandPoints(
      id === 1 ? points1 : id === 2 ? points2 : id === 3 ? points3 : null
    );
    const newMainImage = brandImages.secondColumnImages[index];
    const newSecondColumnImages = [...brandImages.secondColumnImages];
    newSecondColumnImages[index] = brandImages.mainImage;
    setBrandImages({
      mainImage: newMainImage,
      secondColumnImages: newSecondColumnImages,
    });
  };

  const [hoveredPoint, setHoveredPoint] = useState(null);
  const points1 = [
    { x: "32%", y: "17%", text: "Philips Lighting", slug: "" },
    { x: "80%", y: "30%", text: "Asian Paints", slug: "asian-paints" },
    { x: "46%", y: "66%", text: "Stanley Furniture", slug: "" },
    {
      x: "5%",
      y: "75%",
      text: "Royale Touche Laminates",
      slug: "",
    },
  ];
  const points2 = [
    { x: "32%", y: "10%", text: "DUOTONE Tiles", slug: "" },
    { x: "38%", y: "41%", text: "HYBEC Lighting", slug: "" },
    { x: "65%", y: "48%", text: "DUOTONE Tiles", slug: "" },
    { x: "40%", y: "74%", text: "Hans-grohe", slug: "" },
    { x: "17%", y: "82%", text: "Hans-grohe", slug: "" },
  ];
  const points3 = [
    { x: "32%", y: "17%", text: "Changi lighting", slug: "" },
    { x: "13%", y: "30%", text: "D-Decor", slug: "" },
    { x: "56%", y: "23%", text: "Asian Paints", slug: "asian-paints" },
    { x: "5%", y: "69%", text: "Merino Laminates", slug: "" },
    { x: "80%", y: "70%", text: "D-Decor", slug: "" },
  ];
  const [brandPoints, setBrandPoints] = useState(points1);

  const handleMouseEnter = (index) => {
    setHoveredPoint(index);
  };

  const handleMouseLeave = () => {
    setHoveredPoint(null);
  };

  const handleClick = (point) => {
    if (point !== "") {
      navigate(`/brands/${point}`);
    } else {
      notify("Brand Not Available");
    }
  };

  return (
    <>
      <Breakpoint large up>
        <Row className="w-100">
          <Col md={12} lg={8} className="overflow-hidden">
            <Card
              className="position-relative border-0 cursor-pointer overflow-hidden shadow-none p-0"
              style={{ borderRadius: 6 }}
            >
              <Image
                src={brandImages.mainImage["image"]}
                alt={brandImages.mainImage["title"]}
                className="w-100"
                style={{ height: large ? 530 : 260 }}
              />
              <div
                className="position-absolute py-1 px-2 inter-400 body-text1"
                style={{
                  bottom: 10,
                  right: 10,
                  background: "#E8EAEDCC",
                  borderRadius: 3,
                }}
              >
                {truncateText(brandImages.mainImage["title"], 18)}
              </div>
              {brandPoints?.map((point, index) => (
                <div
                  key={index}
                  className="hovered-point"
                  style={{ top: point.y, left: point.x }}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleClick(point?.slug)}
                >
                  <div
                    className={`body-text1 text-gray-900 bg-white inter-400 tooltip ${
                      hoveredPoint === index ? "visible" : ""
                    }`}
                  >
                    {truncateText(point.text, 17)}
                  </div>
                </div>
              ))}
            </Card>
          </Col>
          <Col md={12} lg={4} className="ps-2">
            <Row className="mb-3">
              {brandImages.secondColumnImages.map((imgSrc, index) => {
                return (
                  <Col
                    lg={12}
                    md={6}
                    className="cursor-pointer rounded overflow-hidden"
                    style={{
                      position: "relative",
                      display: "inline-block",
                      opacity: 0.6,
                    }}
                    onClick={() => handleImageClick(index, imgSrc.id)}
                  >
                    <div className="rounded overflow-hidden mb-2">
                      <img
                        src={imgSrc.image}
                        alt={imgSrc.title}
                        className="w-100"
                        style={{ height: 260 }}
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Breakpoint>
      {maxLarge && (
        <Container fluid className="px-0">
          <Row className="mb-4">
            <Slider
              ref={(slider) => {
                sliderRef = slider;
              }}
              {...settings}
            >
              {recommendedProjects?.map((item, index) => (
                <Col key={index} className="px-1">
                  <BrandOnInspirationsCard roomId={item?.rooms[0]?.id} />
                </Col>
              ))}
            </Slider>
          </Row>
        </Container>
      )}
    </>
  );
};

export default BrandOnInspirations;
