import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { BiPhone } from "react-icons/bi";
import { BsMailbox } from "react-icons/bs";
import { MdMail, MdMailOutline, MdOutlinePhone, MdPhone } from "react-icons/md";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
  return (
    <Container
      fluid
      className="my-5 p-0 py-4 d-flex justify-content-center align-items-center"
      style={{ minHeight: "80vh" }}
    >
      <Row className="justify-content-center w-100">
        <Col xs={12} md={6} lg={4}>
          <div className="text-center p-4">
            <p className="h1 inter-600 text-gray-900 mb-3">Contact us</p>
            <p className="h3 inter-500 text-gray-900">Connect instantly with a single click.</p>
            <p className="body-title inter-400 text-gray-600 mb-4">
              Call us anytime between 10am-7pm
            </p>
            <div className="d-flex flex-column" style={{ backgroundColor: "#f8f9fa", borderRadius: '6px', border: '1px solid #e8eaed' }}>
              <a
                href="mailto:support@interiokeys.com"
                className="d-flex align-items-center justify-content-between text-decoration-none"
                style={{ margin: '12px' }}
              >
                <span className="text-gray-800">support@interiokeys.com</span>
                <MdMailOutline size={20} style={{ color: "#c53d3d" }} />
              </a>
              <hr className="m-0" />
              <a
                href="tel:+91xxxxxx2846"
                className="d-flex align-items-center justify-content-between text-decoration-none"
                style={{ margin: '12px' }}
              >
                <span className="text-gray-800">+91 xxxxxx2846</span>
                <MdOutlinePhone size={20} style={{ color: "#c53d3d" }} />
              </a>
            </div>

          </div>

        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
