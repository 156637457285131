import useFetchMedia from "utilities/customHooks/useFetchMedia";
import { Card, Col, Container, Image, Row } from "react-bootstrap";

const BrandAboutUsCards = ({ item }) => {
  /* const { image, loading, error } = useFetchMedia(
    "user",
    "brandinfo",
    item?.id
  );

  console.log(image, "image") */
  return (
    <Card
      className="d-flex justify-content-center align-items-center my-2 shadow-none"
      style={{
        minHeight: 86,
        border: "1px solid #E8EAED",
        borderRadius: 6,
      }}
    >
      <img src={item.image} alt={item?.title} className="img-fluid" />
    </Card>
  );
};

export default BrandAboutUsCards;
