import { useEffect, useRef, useState, useCallback } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Modal,
} from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import Review from "components/Review/Review";
import * as authActions from "reduxFiles/actions/authActions";
import ProjectCard from "components/Cards/ProjectCard";
import { useSelector } from "react-redux";
import { notify } from "utilities/notifications/Notify";
import LoginModal from "components/Auth/LoginModal";
import ShareModal from "components/General/ShareModal";
import { jewel_of_india, testimonial_pic } from "assets";
import { useMediaQuery } from "react-responsive";
import TeamMemberList from "components/TeamMember/TeamMemberList";
import Gallery from "components/Gallery/Gallery";
import RatingStarsView from "components/General/RatingStarsView";
import userImg from "../../assets/navbar/user.webp";
import TeamMember from "components/TeamMember/TeamMember";
import { IoIosArrowDown } from "react-icons/io";
import ArchitectImage from "../../assets/architech.png";

const ProfessionalDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const slug = useParams();
  const { detailedSlug } = useParams();
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const queryParams = new URLSearchParams(location.search);

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const form = useRef();

  const [rating, setRating] = useState(5);

  const [professionalDetails, setProfessionalDetails] = useState(null);
  const [projects, setProjects] = useState([]);
  const [inspirationType, setInspirationType] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [roomCategories, setRoomCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [showShare, setShowShare] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [media, setMedia] = useState([]);
  const [sortBy, setSortBy] = useState(queryParams.get("sort_by") || "");
  const [status, setStatus] = useState(queryParams.get("status") || "");
  const [next, setNext] = useState("");
  const [activeTab, setActiveTab] = useState("about-us");
  const [showAll, setShowAll] = useState(false);
  const aboutUsRef = useRef(null);
  const projectsRef = useRef(null);
  const visibleReviews = showAll ? reviews : reviews.slice(0, 5);
  const reviewsRef = useRef(null);
  const propertiesHeadingRef = useRef(null);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  console.log("projects   999  ", projects);
  const teamRef = useRef(null);
  const galleryRef = useRef(null);
  const [pendingReview, setPendingReview] = useState({
    text: "",
    rating: 5,
    shouldReopen: false
  });

  const [isSubmittingReview, setIsSubmittingReview] = useState(false);

  const fetchArchitectById = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await authActions.fetchArchitectsBySlug(detailedSlug);
      //   if (response.data) {
      //     if (response.data.results[0].id) {
      //       fetchProjects(response.data.results[0].id);
      //     }
      //     setProfessionalDetails(response.data.results[0]);
      //   } else {
      //     setError(
      //       "Error while loading professionals's details. Try again later!"
      //     );
      //   }
      // } catch (error) {
      //   console.log(error);

      // console.log(detailedSlug, "detailedSlug")
      if (response.data && response.data.results) {
        const desiredArchitect = response.data.results[0];
        if (desiredArchitect) {
          /* if (sortBy === "" || status === "") {
            fetchProjects(desiredArchitect?.id);
          } */
          fetchReviews(desiredArchitect?.user);
          setProfessionalDetails(desiredArchitect);
        } else {
          setError("No professional found. Please try again!");
        }
      } else {
        setError(
          "Error while loading professionals' details. Try again later!"
        );
      }
    } catch (error) {
      console.log("Error fetching architects by slug:", error);
      setError("Failed to load data. Please try again later.");
    }
    setLoading(false);
  };

  const hasAboutUs =
    professionalDetails?.architect_thoughts !== null &&
    professionalDetails?.architect_image !== null;

  const hasTeam = teamMembers?.length > 0;
  const hasGallery = media?.length > 0;
  const hasProjects = projects?.length > 0;

  const availableTabs = [
    hasAboutUs && "about-us",
    hasTeam && "team",
    hasGallery && "gallery",
    hasProjects && "created-designs",
  ].filter(Boolean); // Remove falsy values

  const submitReview = async (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      // Save the current review state
      const reviewText = form.current.elements.review.value;
      setPendingReview({
        text: reviewText,
        rating: rating,
        shouldReopen: true
      });

      // Close review modal and open login modal
      setShow(false);
      setTimeout(() => setShowLoginModal(true), 200);
      return;
    }

    setIsSubmittingReview(true);

    const formData = new FormData(form.current);
    formData.append("for_user", professionalDetails?.user_data?.id);
    formData.append("by_user", user?.id);
    formData.append("rating", rating);
    if (formData.get("review")) {

      const response = await authActions.addReview(formData);
      if (response.data) {
        notify("Review submitted successfully!", "success");

        if (professionalDetails?.user_data?.id) { // Updated to use user_data.id
          fetchReviews(professionalDetails?.user_data?.id);
        }
        setIsSubmittingReview(false);
        form.current.reset();
        setShow(!show);
        setPendingReview({
          text: "",
          rating: 5,
          shouldReopen: false
        });
        setRating(5);
      }
    } else {
      notify("Review cannot be empty!", "error");
      setIsSubmittingReview(false);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);

    let sectionRef;
    switch (tab) {
      case "about-us":
        sectionRef = aboutUsRef;
        break;
      case "team":
        sectionRef = teamRef;
        break;
      case "gallery":
        sectionRef = galleryRef;
        break;
      case "created-designs":
        sectionRef = projectsRef;
        break;
      default:
        sectionRef = null;
    }

    if (sectionRef?.current) {
      window.scrollTo({
        top: sectionRef.current.offsetTop - 100, // Adjust for sticky header
        behavior: "smooth",
      });
    }
  };

  const handleScroll = useCallback(() => {
    const sections = [
      { id: "about-us", ref: aboutUsRef },
      { id: "team", ref: teamRef },
      { id: "gallery", ref: galleryRef },
      { id: "projects", ref: projectsRef },
    ];

    let currentTab = "about-us";
    for (const section of sections) {
      if (section.ref.current) {
        const rect = section.ref.current.getBoundingClientRect();
        if (rect.top <= 150 && rect.bottom >= 150) {
          currentTab = section.id;
          break;
        }
      }
    }
    setActiveTab(currentTab);
  }, []);

  const fetchProjects = async (id) => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjectsByArchitect(id);
      if (response.data) {
        setProjects(response.data?.results);
        setNext(response?.data?.next);
      } else {
        setError("Error while loading project details");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const loadProjects = async (next) => {
    try {
      const response = await authActions.loadProjects(next);

      // setCount(response?.count);
      setNext(response?.next);
      if (Array.isArray(response?.results)) {
        setProjects((prevProjects) => [...prevProjects, ...response?.results]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadmore = async () => {
    if (next) {
      loadProjects(next);
    }
  };

  const handleSortBy = (ordering) => {
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set("sort_by", ordering);
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);

    setSortBy(ordering);
  };

  const handleStatusFilter = (status) => {
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set("status", status);
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);

    setStatus(status);
  };

  const fetchProjectsWithFilters = async (id, ordering = "", status = "") => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjectsByArchitect(
        id,
        ordering,
        status
      );
      if (response.data) {
        setProjects(response.data?.results);
        setNext(response?.data?.next);
      } else {
        setError("Error while loading project details");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (professionalDetails?.id) {
      fetchProjects(professionalDetails?.id);
    }
  }, [professionalDetails?.id]);

  const handleClearFilters = () => {
    // Create a copy of the current URL search params
    const updatedSearchParams = new URLSearchParams(location.search);

    // Remove specific filters from the search params
    updatedSearchParams.delete("status");
    updatedSearchParams.delete("sort_by");
    setSortBy("");
    setStatus("");
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
  };
  const fetchReviews = async (id) => {
    setLoading(true);
    try {
      const resReviews = await authActions.fetchReviewsByArchitectUser(id);
      console.log(resReviews?.data?.results);
      if (resReviews?.data?.results) {
        setReviews(resReviews.data.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const checkProfessionalIfSaved = async () => {
    try {
      const response = await authActions.checkProfessionalIfSaved(
        user.id,
        professionalDetails?.user_data?.id
      );
      setIsLiked(response?.status);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRoomCategories = async () => {
    try {
      const response = await authActions.fetchRoomCategoriesData();
      if (response.data) {
        setRoomCategories(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProfessional = async () => {
    try {
      const response = await authActions.saveProfessional(
        user.id,
        professionalDetails.user_data.id
      );
      if (response.data) {
        setIsLiked(true);
        notify("Professional saved successfully", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProfessional = async () => {
    try {
      const response = await authActions.unsaveProfessional(
        user.id,
        professionalDetails.user_data.id
      );

      if (response) {
        setIsLiked(false);
        notify("Professional unsaved successfully", "success");
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTeamMembers = async (id) => {
    try {
      const response = await authActions.fetchTeamMembers({ user_id: id });
      if (response) {
        console.log(response.data);
        // notify("Professional unsaved successfully", "success");
        setTeamMembers(response.data);
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGalleries = async (id) => {
    try {
      const response = await authActions.fetchGallery({ user_id: id });
      if (response) {
        // notify("Professional unsaved successfully", "success");
        setMedia(response.data);
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Detect active tab on scroll
  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        { tab: "about-us", ref: aboutUsRef },
        { tab: "team", ref: teamRef },
        { tab: "gallery", ref: galleryRef },
        { tab: "created-designs", ref: projectsRef },
      ];

      for (let section of sections) {
        if (section.ref?.current) {
          const rect = section.ref.current.getBoundingClientRect();
          if (rect.top >= 0 && rect.top <= 300) {
            setActiveTab(section.tab);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (detailedSlug) {
      fetchArchitectById();
    }
  }, [detailedSlug]);

  useEffect(() => {
    console.log("professionalDetails 4444444", professionalDetails);
    if (professionalDetails) {
      fetchReviews(professionalDetails?.user_data?.id); // Updated to use user_data.id
    }
  }, [professionalDetails]);

  useEffect(() => {
    if (professionalDetails) {
      fetchTeamMembers(professionalDetails?.user);
      fetchGalleries(professionalDetails?.user);
    }
  }, [professionalDetails]);

  useEffect(() => {
    if (professionalDetails) {
      checkProfessionalIfSaved();
    }
  }, [professionalDetails]);


  useEffect(() => {
    // Check if user just logged in and there's a pending review
    if (isAuthenticated && pendingReview.shouldReopen) {
      // Reopen the review modal
      setShow(true);

      // We need to wait for the modal to open and the form to be in the DOM
      setTimeout(() => {
        if (form.current) {
          // Set the form values from the saved state
          form.current.elements.review.value = pendingReview.text;
          setRating(pendingReview.rating);
        }

        // Reset the "shouldReopen" flag but keep the text and rating
        setPendingReview(prev => ({
          ...prev,
          shouldReopen: false
        }));
      }, 300);
    }
  }, [isAuthenticated, pendingReview.shouldReopen]);

  return (
    <Container fluid className="mt-5 pt-4 p-0">
      <div>
        <img
          src={professionalDetails?.architect_banner || ArchitectImage}
          alt="Architect Image"
          className="img-fluid d-block w-100"
          style={{
            objectFit: "contain",
          }}
        />
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Spinner />
        </div>
      ) : error ? (
        <>
          <Container
            fluid
            className="my-5 p-0 py-4 d-flex justify-content-center align-items-center"
            style={{ minHeight: "80vh" }}
          >
            <div
              className="h2 text-primary-500 inter-600 p-5"
              style={{ backgroundColor: "#F8F9FA" }}
            >
              {error}
            </div>
          </Container>

          {/* <div className="h3 inter-600 text-center"></div> */}
        </>
      ) : (
        <div>
          <div className="geist titleimgtext">
            <Container className="pb-4">
              <div className="d-flex justify-content-center bg-gray-25 geist w-100 m-0 py-3 px-xl-3 px-0 titletextbar mt-4">
                <Col lg={2} xl={2} className="p-0">
                  <div
                    className="d-flex justify-content-start align-items-start"
                    style={{ height: "100%" }}
                  >
                    <div className="d-flex justify-content-start align-items-start bg-white rounded overflow-hidden titleimg">
                      <img
                        className="border img-fluid w-100 h-100"
                        style={{
                          border: "1px solid #D0D5DD",
                          borderRadius: "12px",
                          objectFit: "contain", // Change "cover" to "contain"
                        }}
                        src={
                          professionalDetails?.user_data?.profile_picture ||
                          userImg
                        }
                        alt="Architect's Profile"
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={10} xl={10}>
                  <div className="titletextbtnbox w-100">
                    <div className="titletext">
                      <div className="d-flex align-items-center gap-3">
                        <div className="text-uppercase fw-semibold fs-4 lh-sm text-dark">
                          {professionalDetails?.company_name ??
                            detailedSlug?.split("-").join(" ")}
                        </div>
                        <div
                          className="d-flex align-items-center bg-danger text-white gap-1 rounded-1 px-2 py-1"
                          style={{ height: "30px" }}
                        >
                          <FaStar style={{ width: "16px", height: "16px" }} />
                          <span className="fs-6">
                            {reviews?.length > 0
                              ? (
                                reviews.reduce(
                                  (acc, review) => acc + review.rating,
                                  0
                                ) / reviews.length
                              ).toFixed(1)
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="body-text inter-500 fs-6 mt-2">
                        {professionalDetails?.user_data?.user_subtype_data?.title}
                      </div>

                      {!professionalDetails?.user_data.pincode ? (
                        <div>{professionalDetails?.location}</div>
                      ) : (
                        <div>
                          {[
                            // professionalDetails?.user_data?.address,
                            professionalDetails?.user_data?.city,
                            professionalDetails?.user_data?.state,
                            professionalDetails?.user_data?.country,
                          ]
                            .filter(Boolean) // Remove empty or null values
                            .join(", ") || "N/A"}{" "}
                          - {professionalDetails?.user_data.pincode}
                        </div>
                      )}
                      {location?.pathname === "/brand/account/profile" && (
                        <div>
                          <span>Warranty: {professionalDetails?.warranty}</span>
                        </div>
                      )}
                      {location?.pathname === "/brand/account/profile" && (
                        <div>
                          <span>
                            Warranty Duration:{" "}
                            {professionalDetails?.warranty_duration}
                          </span>
                        </div>
                      )}
                      {location?.pathname === "/brand/account/profile" && (
                        <div>
                          <span>
                            Established Year:{" "}
                            {professionalDetails?.established_year}
                          </span>
                        </div>
                      )}
                      {/* {location?.pathname === "/brand/account/profile" && (
                        <div>
                          <span>
                            General Price:{" "}
                            {professionalDetails?.min_pricing} to{" "}
                            {professionalDetails?.max_pricing}
                          </span>
                        </div>
                      )} */}
                      {location?.pathname === "/brand/account/profile" && (
                        <div>
                          <span>
                            Product Category:{" "}
                            {
                              professionalDetails?.product_category_data?.[0]
                                ?.title
                            }
                          </span>
                        </div>
                      )}

                      <div className="d-flex gap-3 mt-3 flex-wrap">
                        <div
                          className="d-flex align-items-center p-2 border"
                          style={{
                            backgroundColor: "#F1F3F4",
                            color: "#2E3134",
                          }}
                        >
                          {professionalDetails?.delivered_projects ?? 0} Project
                          Done
                        </div>
                        {professionalDetails?.expertise_type && (
                        <div
                          className="d-flex align-items-center p-2 border"
                          style={{
                            backgroundColor: "#F1F3F4",
                            color: "#2E3134",
                          }}
                        >
                          Expertise:  {professionalDetails?.expertise_type}
                        </div>
                        )}
                        {professionalDetails?.interest_of_service ===
                          "turnkey" && (
                            <div
                              className="d-flex align-items-center p-2 border"
                              style={{
                                backgroundColor: "#F1F3F4",
                                color: "#2E3134",
                              }}
                            >
                              Turnkey: ₹ {professionalDetails?.job_cost ?? 0} per
                              sq. ft.
                            </div>
                          )}

                        {professionalDetails?.interest_of_service ===
                          "design" && (
                            <div
                              className="d-flex align-items-center p-2 border"
                              style={{
                                backgroundColor: "#F1F3F4",
                                color: "#2E3134",
                              }}
                            >
                              Only Design: ₹ {professionalDetails?.job_cost ?? 0}{" "}
                              per sq. ft.
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="d-flex align-items-center my-2 my-lg-0 titletextbtn">
                      {(!user || user?.user_type === "customer") && (
                        <div className="d-flex">
                          <Button
                            className="d-flex justify-content-center align-items-center social-btn py-2"
                            style={{
                              backgroundColor: "#F1F3F4",
                              border: "none",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowShare(true);
                            }}
                          >
                            <FiSend color="#000000" size={18} />
                          </Button>
                          <button
                            className={`d-flex justify-content-center align-items-center social-btn-professional py-2 ms-2 ${isLiked ? "bg-primary text-white" : ""
                              }`}
                            style={{
                              backgroundColor: "#F1F3F4",
                              border: "none",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!user) {
                                setShowLoginModal(true);
                              } else {
                                isLiked
                                  ? handleUnsaveProfessional()
                                  : handleSaveProfessional();
                              }
                            }}
                          >
                            <FaRegHeart color="#000000" size={18} />
                          </button>

                          {(!user || user?.user_type === "customer") && (
                            <Button
                              className="text-white ms-2"
                              onClick={() => {
                                if (professionalDetails?.id) {
                                  navigate(
                                    `/consultation/architect/${detailedSlug}`,
                                    {
                                      state: { architect: professionalDetails },
                                    }
                                  );
                                } else {
                                  notify(
                                    "Professional undefined, try again later",
                                    "error"
                                  );
                                }
                              }}
                            >
                              {" "}
                              Book Consultaion{" "}
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div className="d-flex align-items-center body-text inter-600">
                    Ratings: {professionalDetails?.rating}
                    <FaStar color="#D95656" />
                  </div> */}
                </Col>
              </div>

              <Container fluid className="p-0 m-0 interotabssection">
                <Row className="gx-5">
                  {/* Left Side: About Us & Projects/Properties */}
                  <Col xs={12} xl={8} className="mt-3">
                    {/* Conditionally render navigation if any section has data */}
                    {availableTabs.length > 0 && (
                      <div
                        className="d-flex border-bottom"
                        style={{
                          borderBottom: "1px solid #DADCE0",
                          background: "#fff",
                          position: "sticky",
                          zIndex: "999",
                          top: "70px",
                        }}
                      >
                        {availableTabs.map((tab) => (
                          <button
                            key={tab}
                            className="border-0 bg-transparent px-sm-3 py-2 position-relative"
                            style={{
                              fontFamily: "Inter",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "18px",
                              textAlign: "center",
                              color: activeTab === tab ? "#C53D3D" : "#80868B",
                              borderBottom: "2px solid transparent",
                            }}
                            onClick={() => handleTabClick(tab)}
                          >
                            {tab === "about-us"
                              ? "About Us"
                              : tab === "team"
                                ? "Team"
                                : tab === "gallery"
                                  ? "Gallery"
                                  : "Created Designs"}
                            {activeTab === tab && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "3px",
                                  background: "#C53D3D",
                                }}
                              />
                            )}
                          </button>
                        ))}
                      </div>
                    )}

                    {/* About Us Section */}
                    {hasAboutUs && (
                      <div ref={aboutUsRef} className="mt-3">
                        <Row className="border rounded-2 m-0 overflow-hidden">
                          <Col xs={12} className="px-0">
                            {professionalDetails?.architect_image &&
                              /\.(mp4|webm|ogg)$/i.test(
                                professionalDetails.architect_image
                              ) ? (
                              <video
                                src={professionalDetails.architect_image}
                                className="img-fluid w-100"
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "6px 6px 0 0",
                                  height: "418px",
                                }}
                                controls
                                autoPlay
                                muted
                              />
                            ) : (
                              <img
                                src={
                                  professionalDetails?.architect_image ||
                                  "/default-image.jpg"
                                }
                                alt="Architect"
                                className="img-fluid w-100"
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "6px 6px 0 0",
                                  height: "418px",
                                }}
                              />
                            )}
                          </Col>
                          <Col
                            xs={12}
                            className="py-3 text-start d-flex flex-column justify-content-center"
                          >
                            <div className="ms-3">
                              <div className="body-text1 inter-400 text-gray-600">
                                {professionalDetails?.architect_thoughts ||
                                  "“Collaborating with Interiofy has been an inspiring journey...”"}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}

                    {/* Team Section */}
                    {hasTeam && (
                      <div ref={teamRef} className="mb-4 pt-4">
                        <div className="h4 inter-600">Meet the Team!</div>
                        <div className="py-3">
                          {teamMembers?.length > 0 ? (
                            <div className="row">
                              {teamMembers.map((teamMember, index) => (
                                <div
                                  key={index}
                                  className="col-5 col-sm-6 col-md-2 mb-3"
                                >
                                  <TeamMember {...teamMember} />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div
                              className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                              style={{ height: 160 }}
                            >
                              No Team Members available
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {/* Gallery Section */}
                    {hasGallery && (
                      <div ref={galleryRef} className="mb-4">
                        <div className="h4 inter-600 pb-2 mt-3">Get to Know Us!</div>
                        <Gallery
                          items={media}
                          fetchGalleries={fetchGalleries}
                        />
                      </div>
                    )}

                    {/* Projects Section */}
                    {hasProjects && (
                      <div ref={projectsRef} className="pt-5 mt-5 pb-3">
                        <div className="h3 inter-600 mb-4">
                          Inspirations Created ({projects?.length})
                        </div>
                        {projectsLoading ? (
                          <div
                            className="d-flex justify-content-center align-items-center w-100"
                            style={{ minHeight: "50vh" }}
                          >
                            <Spinner />
                          </div>
                        ) : (
                          <Row>
                            {projects?.map((project, index) => (
                              <Col xs={12} sm={6} key={index} className="p-2">
                                <ProjectCard {...project}
                                  roomId={project?.rooms[0]?.id}
                                  architect={professionalDetails?.company_name}
                                  is_architect={true}
                                  zIndex = { 99 }
                                />
                              </Col>
                            ))}
                          </Row>
                        )}
                      </div>
                    )}
                  </Col>

                  {/* Right Side: Reviews and Ratings */}
                  <Col xs={12} xl={4} className="pt-5">
                    <Container
                      className="pt-4 pb-3 border reviewpart"
                      style={{
                        borderColor: "#E8EAED",
                        borderWidth: "1px",
                        position: "sticky",
                        top: "110px",
                      }}
                    >
                      {/* Review Count & Title */}
                      <div
                        className="h4 inter-600 text-center mb-4"
                        style={{ color: "#1A1C1F" }}
                      >
                        <span className="text-danger">
                          {reviews?.length ?? 0}
                        </span>{" "}
                        Reviews and Ratings
                      </div>

                      {/* Average Rating */}
                      <div className="h4 inter-600 text-center text-danger d-flex justify-content-center align-items-center gap-2">
                        <FaStar className="text-danger" />
                        {reviews?.length > 0
                          ? (
                            reviews.reduce(
                              (acc, review) => acc + review.rating,
                              0
                            ) / reviews.length
                          ).toFixed(1)
                          : 0}
                      </div>

                      <div
                        className="h6 inter-600 text-center pt-1 pb-1"
                        style={{ color: "#5F6368" }}
                      >
                        Average Rating
                      </div>

                      {/* Add Review Button */}
                      <div className="w-100 my-3 d-flex justify-content-center">
                        <Button className="text-white" onClick={handleShow}>
                          Add Your Review
                        </Button>
                      </div>

                      {/* Review Modal */}
                      <Modal show={show} onHide={handleClose} centered>
                        <Modal.Header closeButton>
                          <Modal.Title className="h3 inter-600 text-center w-100">
                            Add Review
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="d-flex justify-content-center mb-3">
                            <div style={{ transform: "scale(0.85)" }}>
                              <RatingStarsView
                                rating={rating}
                                setRating={setRating}
                              />
                            </div>
                          </div>

                          <Form ref={form} onSubmit={submitReview}>
                            <Form.Group>
                              <Form.Control
                                name="review"
                                as="textarea"
                                rows={5}
                                placeholder="Give your feedback for builder.."
                                style={{ resize: "none" }}
                                defaultValue={pendingReview.text}
                              />
                            </Form.Group>

                            {/* Submit Button Inside Modal */}
                            <div className="w-100 my-3 d-flex justify-content-center">
                              <Button
                                className="text-white"
                                disabled={isSubmittingReview}
                                type="submit"
                                style={{
                                  fontSize: "0.875rem",
                                  padding: "6px 12px",
                                }}
                              >
                                {isSubmittingReview ? (
                                  <>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      className="me-1"
                                    />
                                    {" "}Submitting...
                                  </>
                                ) : (
                                  "Submit Review"
                                )}
                              </Button>
                            </div>
                          </Form>
                        </Modal.Body>
                      </Modal>

                      {/* Display Reviews - Show Only 2 Initially */}
                      {(showAll ? reviews : reviews.slice(0, 2)).length > 0 ? (
                        (showAll ? reviews : reviews.slice(0, 2)).map(
                          (review, index) => <Review key={index} {...review} for_user={professionalDetails?.user_data?.id}
                          onReviewUpdate={() => fetchReviews(professionalDetails?.user_data?.id)}/>
                        )
                      ) : (
                        <div
                          className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                          style={{ height: 160 }}
                        >
                          No reviews available
                        </div>
                      )}

                      {/* "See All" Button */}
                      {reviews?.length > 2 && !showAll && (
                        <div className="text-center mt-3">
                          <button
                            className="btn"
                            onClick={() => setShowAll(true)}
                            style={{
                              color: "#C53D3D",
                              border: "none",
                              textDecoration: "underline",
                            }}
                          >
                            See All
                          </button>
                        </div>
                      )}
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Container>
          </div>
        </div>
      )}
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={professionalDetails?.id}
        image={professionalDetails?.image}
        title={professionalDetails?.company_name}
        urlPath={
          detailedSlug !== "undefined" ? `/professionals/architects/${detailedSlug}` : null
        }
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
        currentPathname={location.pathname}
      />
    </Container>
  );
};

export default ProfessionalDetails;
