import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { LuMail } from "react-icons/lu";
import { othersignup } from "assets";
import { useLocation, useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

const OtherSignup = ({
  requiredSubtypes,
  step,
  setStep,
  resendTimer,
  setResendTimer,
  isResendDisabled,
  setIsResendDisabled,
  setShowOtpFields,
  showOtpFields,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const form = useRef();
  const pathname = useLocation();
  console.log(pathname.hash);
  const [designerCategory, setDesignerCategory] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  const validateForm = () => {
    const errors = {};
    const formData = new FormData(form.current);

    // Validate Designer Category
    if (!formData.get("user_subtype")) {
      errors.user_subtype = "Please select a designer category.";
    }

    // Validate Name
    if (!formData.get("full_name")?.trim()) {
      errors.full_name = "Full Name is required.";
    }

    // Validate Company Name
    if (!formData.get("company_name")?.trim()) {
      errors.company_name = "Company Name is required.";
    }

    // Validate Phone Number
    const mobile = formData.get("mobile");
    if (!mobile || !/^\d{10}$/.test(mobile)) {
      errors.mobile = "Please enter a valid 10-digit phone number.";
    }

    // Validate Email
    const email = formData.get("email");
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Please enter a valid email address.";
    }

    // Return errors
    return errors;
  };

  const handleOtherRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate form fields
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      setLoading(false);
      return;
    }

    // Submit form data
    const formData = new FormData(form.current);
    const response = await authActions.saveOthersInfo(formData);

    if (response) {
      form.current.reset();
      setErrorMessages({});
      setModalShow(true);
    }

    setLoading(false);
  };

  // useEffect(() => {
  //   if (
  //     pathname?.hash === "#professional|others" &&
  //     requiredSubtypes.length <= 0
  //   ) {
  //     const updateData = [
  //       {
  //         id: "89b05c24-3128-4d60-873c-fbdfc879d2c3",
  //         title: "OEM",
  //       },
  //       {
  //         id: "eee678e8-72d0-44b9-8836-9b071979310f",
  //         title: "Turnkey contractors",
  //       },
  //       {
  //         title: "Project Management (PMC)",
  //         id: "0a771bc6-8609-4bb9-b76f-4ea7281c5068",
  //       },
  //     ];
  //     setDesignerCategory(updateData);
  //   }
  // }, [pathname.hash, requiredSubtypes]);

  return (
    <>
      <Container
        className="d-flex flex-column justify-content-center align-items-center mt-5"
        style={{ minHeight: "70vh" }}
      >
        <Row style={{ maxWidth: 1000 }}>
          <Col
            lg={6}
            className="mb-3 mb-lg-0 d-flex flex-column justify-content-between align-items-center"
          >
            <Card
              style={{
                boxShadow: "none",
                borderRadius: 0,
                border: "none",
                borderLeft: "1px solid #BDC1C6",
              }}
            >
              <h4 className="text-gray-900 inter-600">
                Others(Executors) Registration
              </h4>
              <div className="text-gray-500">
                Showcase your projects to potential clients while you design for
                the top properties in India.
              </div>
            </Card>
            <Image
              className="mt-4"
              src={othersignup}
              alt="Homeowner signup"
              width="80%"
            />
          </Col>
          <Col lg={6}>
            <Form ref={form} onSubmit={handleOtherRegister}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-600">
                  Designer Category<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                {requiredSubtypes.length > 0 ? (
                  <Form.Select
                    className="py-2 body-text2"
                    name="user_subtype"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    {requiredSubtypes.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <div
                    className="py-2 placeholder-glow"
                    style={{
                      border: "1px solid #BDC1C6",
                      borderRadius: 3,
                      height: "38px", // Match the height of the dropdown
                    }}
                  >
                    <span className="placeholder col-12 h-100"></span>
                  </div>
                )}
                {errorMessages.user_subtype && (
                  <div className="text-danger">
                    {errorMessages.user_subtype}
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-600">
                  Name<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  className="py-2 body-text2"
                  type="text"
                  name="full_name"
                  placeholder="Enter your Full Name"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
                {errorMessages.full_name && (
                  <div className="text-danger">{errorMessages.full_name}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-600">
                  Company Name<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  className="py-2 body-text2"
                  type="text"
                  name="company_name"
                  placeholder="Enter your Company Name"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
                {errorMessages.company_name && (
                  <div className="text-danger">
                    {errorMessages.company_name}
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-600">
                  Phone Number<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text
                    className="body-text2 inter-600"
                    style={{
                      backgroundColor: "white",
                      border: "1px solid #BDC1C6",
                      borderRadius: "3px 0px 0px 3px",
                    }}
                  >
                    +91
                  </InputGroup.Text>
                  <Form.Control
                    className="border-start-0 p-2 body-text2"
                    type="text"
                    name="mobile"
                    placeholder="9090909090"
                    style={{
                      border: "1px solid #BDC1C6",
                      borderRadius: "0px 3px 3px 0px",
                    }}
                    pattern="[0-9]{10}"
                    maxLength={10}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, ""); // Removes non-numeric characters
                    }}
                  />
                </InputGroup>
                {errorMessages.mobile && (
                  <div className="text-danger">{errorMessages.mobile}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-600">
                  Company Email<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text
                    style={{
                      backgroundColor: "white",
                      border: "1px solid #BDC1C6",
                      borderRadius: "3px 0px 0px 3px",
                    }}
                  >
                    <LuMail size={20} color="#1A1C1F" />
                  </InputGroup.Text>
                  <Form.Control
                    className="border-start-0 p-2 body-text2"
                    type="email"
                    name="email"
                    placeholder="example@gmail.com"
                    style={{
                      border: "1px solid #BDC1C6",
                      borderRadius: "0px 3px 3px 0px",
                    }}
                  />
                </InputGroup>
                {errorMessages.email && (
                  <div className="text-danger">{errorMessages.email}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Check
                  type="checkbox"
                  label={
                    <div className="body-text2 inter-400">
                      Agree to{" "}
                      <span
                        className="text-primary-500 cursor-pointer"
                        onClick={() => window.open("/terms", "_blank")}
                      >
                        Terms and conditions
                      </span>{" "}
                      &{" "}
                      <span
                        className="text-primary-500 cursor-pointer"
                        onClick={() => window.open("/privacy", "_blank")}
                      >
                        Privacy Policy
                      </span>
                    </div>
                  }
                  required
                />
              </Form.Group>

              <div className="d-flex mt-5 w-100">
                <Button
                  className="px-3 py-2 me-3 text-gray-900 bg-white border-gray-200"
                  style={{ borderRadius: 3 }}
                  onClick={() => setStep(step - 1)}
                >
                  Back
                </Button>
                <Button
                  className="w-100 p-2"
                  type="submit"
                  style={{ borderRadius: 3 }}
                  disabled={loading}
                >
                  {loading && (
                    <Spinner animation="border" size="sm" className="me-2" />
                  )}
                  Register
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <Modal
        className="d-flex justify-content-center align-items-center"
        show={modalShow}
      >
        <div className="py-3 px-4">
          <Modal.Body className="p-0">
            <h1 className="h2 text-primary text-center inter-600 pt-3">
              Thank You for Registering with InterioKeys!
            </h1>
            <div className="text-gray-900 text-center">
              We will get in touch with you soon to start the onboarding
              process.
            </div>
            <Button
              onClick={() => navigate(`/`)}
              variant="primary"
              className="mt-5 py-2 body-text2 border-0"
              style={{
                fontWeight: "600",
                color: "#fff",
                width: "100%",
                borderRadius: 3,
              }}
            >
              Go to Homepage
            </Button>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default OtherSignup;
