import React, { useEffect, useRef, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  Col,
  Container,
  Form,
  Dropdown,
  Image,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import RangeSlider from "components/Forms/RangeSlider";
import ReactSelect from "react-select";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FaCircleMinus, FaXmark } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";

import { notify } from "utilities/notifications/Notify";
import { formatNumber } from "utilities/numberFormatter";
import AddPropertyModal from "./AddPropertyModal";
import LoadingOverlay from "components/General/LoadingOverlay";
import DropFileInputProject from "components/DragInput/DropFileInputProject";
import AddProductsModal from "./AddProductsModal";
import * as authActions from "reduxFiles/actions/authActions";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import EditProjectsForm from "./EditProjectForm";
import EditProjectRoomSelector from "./EditProjectRoomSelector";
import AddProjectDetails from "./AddProjectDetails";
import AddBrandsModel from "./AddBrandsModel";
import AddBrandsModelForEditProject from "./AddBrandsModelForEditProject";
import { clearDeletedImageIds } from "reduxFiles/reducer/projectSlice";
import BrandCards from "./BrandCards";
import EditedProjectDetails from "./EditedProjectDetails";
import useFetchMedia from "utilities/customHooks/useFetchMedia";

function EditProjects() {
  const navigate = useNavigate();
  const location = useLocation();
  const deletedImageIds = useSelector((state) => state.project?.deleteImageIds);
  const dispatch = useDispatch();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const { projectId } = useParams();
  const { status } = useParams();
  const user = useSelector((state) => state.auth.user);

  const projectInfoRef = useRef();
  const [brandInfo, setBrandInfo] = useState(null);
  const [architectInfo, setArchitectInfo] = useState(null);
  const [projectDetails, setProjectDetails] = useState({});
  const defaultAddProperty = { id: "add", property_name: "Add New Property" };
  const [properties, setProperties] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const defaultLayouts = [
    { id: 1, layout: "2BHK" },
    { id: 2, layout: "2.5BHK" },
    { id: 3, layout: "3BHK" },
    { id: 4, layout: "3.5BHK" },
    { id: 5, layout: "4BHK" },
    { id: 7, layout: "5BHK" },
    { id: 9, layout: "6BHK" },
    { id: 11, layout: "7BHK" },
    { id: 13, layout: "8BHK" },
  ];
  const [availableLayouts, setAvailableLayouts] = useState(defaultLayouts);

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedRoomtypes, setSelectedRoomtypes] = useState([]);
  const [roomsToDisplay, setRoomsToDisplay] = useState(null);
  const [roomtypeIndex, setRoomTypeIndex] = useState(0);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [customProperty, setCustomProperty] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detailsForProject, setDetailsForProject] = useState({});
  const [displaySelectedBrands, setDisplaySelectedBrands] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);

  const [rooms, setRooms] = useState([]);
  const [selectorRooms, setSelectorRooms] = useState([]);

  const [editedRoom, setEditedRooms] = useState([]);
  const [activeRoomIndex, setActiveRoomIndex] = useState(0);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedRoomSlug, setSelectedRoomSlug] = useState("");
  const [roomId, setRoomId] = useState("");
  const [temps, setTemps] = useState([]);
  const [noRoomImage, setNoRoomImage] = useState(true);
  const [allBrands, setAllBrands] = useState([]);
  const [newFiles, setNewFiles] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isGeneralInspiration, setIsGeneralInspiration] = useState(false);
  const [previewButtonLoading, setPreviewButtonLoading] = useState(false);
  const [mediaForPreview, setMediaForPreview] = useState([]);

  const fetchAllRoomsMedia = async () => {
    const mediaMap = {};

    for (const room of selectedRoomtypes) {
      if (room.id) {
        try {
          const response = await authActions.getMediaFiles(
            "project",
            "room",
            room.id
          );

          // Store the fetched media
          mediaMap[room.id] = response || [];
        } catch (error) {
          console.error(`Error fetching media for room ${room.id}:`, error);
          mediaMap[room.id] = [];
        }
      }
    }

    return mediaMap;
  };

  useEffect(() => {
    const fetchDataById = async () => {
      const response = await authActions.fetchProject(projectId);
      // console.log(response?.data);
      setSelectedTheme(response?.data?.project_theme);
      setDetailsForProject(response?.data);
      setProjectDetails(response?.data);
      setSelectedBrands(
        response?.data?.brands_ids?.map((item) => item.brand) || []
      );
      setDisplaySelectedBrands(response?.data?.brands_ids);
      const mappedRooms = response?.data?.rooms?.map((item) => item) || [];
      setRooms(mappedRooms);
      setRoomId(mappedRooms?.[0]?.id);
      setSelectorRooms(mappedRooms);
      setEditedRooms(mappedRooms.map?.((r) => ({ id: r.id })));
      setSelectedRoomtypes(response?.data?.rooms);
      setIsGeneralInspiration(response?.data?.is_general_inspiration || false);

      if (response?.data?.rooms?.length) {
        const imageUrls = response?.data?.rooms
          ?.map((item) => item.room_type_data?.image)
          .filter(Boolean);
        setSliderImages(imageUrls);
      }
    };
    fetchDataById();
  }, [projectId]);

  const headingText = location.pathname.includes("builder")
    ? "Edit Sample Flat"
    : "Edit the Project";

  const toggleBrandSelection = (brandId) => {
    setSelectedBrands((prevSelected) =>
      prevSelected.includes(brandId)
        ? prevSelected.filter((id) => id !== brandId)
        : [...prevSelected, brandId]
    );

    setDisplaySelectedBrands((prevDisplay) => {
      const isAlreadySelected = prevDisplay.some(
        (brand) => brand.brand_data.id === brandId
      );

      if (isAlreadySelected) {
        // Remove the brand if it's already selected
        return prevDisplay.filter((brand) => brand.brand_data.id !== brandId);
      } else {
        // Add the brand if it's not selected
        const brandToAdd = allBrands.find((brand) => brand.id === brandId);
        if (brandToAdd) {
          return [...prevDisplay, { brand_data: brandToAdd }];
        }
      }

      return prevDisplay;
    });
  };

  const removeBrandDisplaySelection = (id) => {
    console.log(id);
    setDisplaySelectedBrands((prevDisplay) =>
      prevDisplay?.filter((brand) => brand?.brand_data?.id !== id)
    );

    setSelectedBrands((prevSelected) =>
      prevSelected.filter((selectedBrand) => selectedBrand !== id)
    );
  };

  const fetchProperties = async () => {
    try {
      const response = await authActions.fetchAllProperties();
      if (response?.data) {
        setProperties([defaultAddProperty, ...response.data]);
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPropertiesByBuilderNoPagination = async () => {
    try {
      const response = await authActions.fetchPropertiesByBuilderNoPagination(
        user?.id
      );
      if (response?.data) {
        setProperties([...response?.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRoomTypes = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchRoomTypes();
      if (response.data) {
        const formattedData = response?.data.map((item) => ({
          room_type_data: {
            title: item.title,
            image: item.image,
            slug: item.slug,
            id: item.id || "",
          },
        }));
        setRoomTypes(formattedData);
        const masterBedroom = response.data?.filter(
          (item) => item?.title?.toLowerCase() === "master bedroom"
        )[0];
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const addSelectedRoomtype = (types) => {
    setProcessing(true);
    const updatedTypes = [
      ...new Map(
        types.map((item) => [item?.room_type_data?.id, item])
      ).values(),
    ];

    setSelectedRoomtypes(updatedTypes);

    /* if (updatedTypes.length > selectedRoomtypes.length) {
      // Add the newly selected room types to the editedRooms list
      const newRoomtype = updatedTypes[updatedTypes.length - 1];
      setEditedRooms([...selectedRoomtypes, newRoomtype]);
    } else {
      // Handle the case when a room type is deselected
      const selectedIds = new Set(updatedTypes.map((item) => item.id));
      const filteredRoomTypes = selectedRoomtypes.filter((item) =>
        selectedIds.has(item.id)
      );
      setSelectedRoomtypes(filteredRoomTypes);
    } */

    setProcessing(false);
  };

  const onSelectRoomFiles = (files, index = roomtypeIndex) => {
    // setProcessing(true);
    // console.log(selectedRoomtypes[index], "selectedRoomtypes[index]");
    const temp = files.map((item) => {
      return { file: item, products: [] };
    });

    setSelectedRoomtypes([
      ...selectedRoomtypes.slice(0, index),
      {
        ...selectedRoomtypes[index],
        rooms: [...temp],
      },
      ...selectedRoomtypes.slice(index + 1),
    ]);
    // setProcessing(false);
  };

  const handleGoToPreview = async () => {
    // Set loading state for the button
    setPreviewButtonLoading(true);
  
    try {
      console.log("Starting validation process...");
      
      // Track rooms that don't have any media
      let roomsWithoutMedia = [];
      
      // Helper function to find files for a room using all possible key formats
      const findFilesForRoom = (room) => {
        if (!room?.room_type_data) return 0;
        
        const typeId = room.room_type_data.id;
        const roomId = room.id || '';
        let totalFiles = 0;
        
        // Check all possible key formats
        // 1. Standard format: typeId_roomId
        const standardKey = `${typeId}_${roomId}`;
        if (newFiles[standardKey] && newFiles[standardKey].length) {
          totalFiles += newFiles[standardKey].length;
          console.log(`Found ${newFiles[standardKey].length} files with key: ${standardKey}`);
        }
        
        // 2. Format for new rooms with empty room ID
        if (!room.id) {
          // Check "typeId_" format
          const altKey1 = `${typeId}_`;
          if (newFiles[altKey1] && newFiles[altKey1].length) {
            totalFiles += newFiles[altKey1].length;
            console.log(`Found ${newFiles[altKey1].length} files with key: ${altKey1}`);
          }
          
          // Check just "typeId" format
          if (newFiles[typeId] && newFiles[typeId].length) {
            totalFiles += newFiles[typeId].length;
            console.log(`Found ${newFiles[typeId].length} files with key: ${typeId}`);
          }
          
          // Check for any keys that start with typeId
          Object.keys(newFiles).forEach(key => {
            if (key.startsWith(typeId) && key !== standardKey && key !== altKey1 && key !== typeId) {
              totalFiles += newFiles[key].length;
              console.log(`Found ${newFiles[key].length} files with key: ${key}`);
            }
          });
        }
        
        return totalFiles;
      };
  
      // Process each room to validate it has media
      for (const room of selectedRoomtypes) {
        if (!room?.room_type_data) continue;
        
        const roomTypeId = room.room_type_data.id;
        const roomTitle = room.room_type_data.title || 'Room';
        const roomId = room.id || '';
        
        console.log(`Validating room: ${roomTitle} (Type ID: ${roomTypeId}, Room ID: ${roomId})`);
        
        // 1. Check for existing media in the DB (only for rooms that have an ID)
        let existingMediaCount = 0;
        if (room.id) {
          try {
            const mediaResponse = await authActions.getMediaFiles(
              "project",
              "room",
              room.id
            );
            
            // Filter out deleted media
            const validMedia = mediaResponse.filter(
              media => !deletedImageIds.includes(media.id)
            );
            
            existingMediaCount = validMedia.length;
            console.log(`Room ${roomTitle} (ID: ${roomId}) has ${existingMediaCount} existing media items`);
          } catch (error) {
            console.error(`Error fetching media for room ${roomId}:`, error);
          }
        }
        
        // 2. Check for newly uploaded files for this room
        const newMediaCount = findFilesForRoom(room);
        console.log(`Room ${roomTitle} has ${newMediaCount} newly uploaded media items`);
        
        // 3. Total media count for this room
        const totalMediaCount = existingMediaCount + newMediaCount;
        console.log(`Room ${roomTitle} total media count: ${totalMediaCount}`);
        
        // If room has no media, add to the list of rooms without media
        if (totalMediaCount === 0) {
          roomsWithoutMedia.push({
            title: roomTitle,
            id: roomId
          });
        }
      }
      
      // If any rooms don't have media, show an error
      if (roomsWithoutMedia.length > 0) {
        // Format room names for the error message
        const roomNames = roomsWithoutMedia.map(room => room.title).join(', ');
        notify(`Please upload at least one image for: ${roomNames}`, "error");
        console.log(`Validation failed: missing media for rooms: ${roomNames}`);
        setPreviewButtonLoading(false);
        return;
      }
  
      console.log("Validation successful! Preparing media for preview...");
  
      // All rooms have at least one media item
      // Now prepare the media for preview
      const allMediaForPreview = [];
  
      // Helper function to collect files for preview for a specific room
      const collectFilesForPreview = (room) => {
        if (!room?.room_type_data) return;
        
        const typeId = room.room_type_data.id;
        const roomId = room.id || '';
        const roomTitle = room.room_type_data.title || 'Room';
        
        // Process all possible key formats
        const keysToCheck = [
          `${typeId}_${roomId}`,  // Standard format
          `${typeId}_`,           // For new rooms
          typeId                  // Just the type ID
        ];
        
        // Add any other keys that start with typeId
        Object.keys(newFiles).forEach(key => {
          if (key.startsWith(typeId) && !keysToCheck.includes(key)) {
            keysToCheck.push(key);
          }
        });
        
        // Check each possible key
        keysToCheck.forEach(key => {
          if (newFiles[key] && newFiles[key].length > 0) {
            newFiles[key].forEach(file => {
              allMediaForPreview.push({
                file: file,
                isNew: true,
                roomType: roomTitle,
                products: []
              });
            });
          }
        });
      };
  
      // 1. Process existing media from all rooms
      for (const room of selectedRoomtypes) {
        if (!room?.room_type_data || !room.id) continue;
  
        const roomTitle = room.room_type_data.title || 'Room';
        const roomId = room.id;
        
        try {
          const mediaResponse = await authActions.getMediaFiles(
            "project",
            "room",
            roomId
          );
          
          // Filter out deleted media
          const validMedia = mediaResponse.filter(
            media => !deletedImageIds.includes(media.id)
          );
          
          // Add each valid media to our preview collection
          validMedia.forEach(media => {
            const mediaUrl = media.file_url || media.image_url || media.url || media.file;
            
            if (mediaUrl) {
              allMediaForPreview.push({
                file: mediaUrl,
                isExisting: true,
                roomType: roomTitle,
                mediaId: media.id,
                products: media.products || []
              });
            }
          });
        } catch (error) {
          console.error(`Error fetching media for preview for room ${roomId}:`, error);
        }
      }
  
      // 2. Process new files for all rooms
      for (const room of selectedRoomtypes) {
        collectFilesForPreview(room);
      }
  
      console.log(`Collected ${allMediaForPreview.length} media items for preview`);
  
      // Final validation - ensure we have some media to preview
      if (allMediaForPreview.length === 0) {
        notify("No media found for preview. Please upload at least one image.", "error");
        setPreviewButtonLoading(false);
        return;
      }
  
      // Store media for preview
      setMediaForPreview(allMediaForPreview);
  
      // All validations passed, proceed to next step
      setCurrentStep(3);
    } catch (error) {
      console.error("Error during validation:", error);
      notify("An error occurred during validation. Please try again.", "error");
    } finally {
      // Reset button loading state
      setPreviewButtonLoading(false);
    }
  };



  const handleProjectDetails = () => {
    // Get data from the form
    const formData = new FormData(projectInfoRef.current);
    let data = {};

    // Debug: Log all form field values
    formData.forEach((value, key) => {
      data[key] = value.trim();
    });

    // Add crucial state values that might not be in the form
    // console.log("Current state values:");
    // console.log("selectedProperty:", selectedProperty);
    // console.log("selectedTheme:", selectedTheme);
    // console.log("projectDetails:", projectDetails);

    // Simple validation logic focusing on the main required fields
    let errorFields = [];
    const isBuilder = location.pathname.includes("builder");

    const projectLabel = isBuilder ? "Sample Flats" : "Project";

    // Check for project name (direct form field)
    if (!data.project_name || data.project_name.trim() === "") {
      errorFields.push(`${projectLabel} Name`);
    }

    // Check for property selection (this comes from state, not from form)
    // Using projectDetails as a fallback since it's populated from backend
    const hasProperty =
      selectedProperty || (projectDetails && projectDetails.project_property);
    if (!hasProperty && !isGeneralInspiration) {
      errorFields.push("Property");
    }

    // Check for layout/BHK
    // Using either form data or projectDetails as fallback
    const hasBhk = data.bhk || (projectDetails && projectDetails.bhk);
    if (!hasBhk) {
      errorFields.push("Layout");
    }

    // Check for flat area
    // Using either form data or projectDetails as fallback
    const hasFlatArea =
      data.flat_area || (projectDetails && projectDetails.flat_area);
    if (!hasFlatArea) {
      errorFields.push("Flat Area");
    }

    // Check for theme (this comes from state, not directly from form)
    // Using projectDetails as a fallback
    const hasTheme =
      selectedTheme || (projectDetails && projectDetails.project_theme);
    if (!hasTheme) {
      errorFields.push(`${projectLabel} Theme`);
    }

    // Show error if any required fields are missing
    if (errorFields.length > 0) {
      console.error("Validation failed for:", errorFields);
      notify(`Please enter the required fields: ${errorFields.join(", ")}`);
      return;
    }

    // All validation passed, prepare data for next step
    // Use existing projectDetails as a base to avoid losing data
    const updatedDetails = {
      ...projectDetails,
      ...data,
      project_theme: selectedTheme || projectDetails?.project_theme,
      is_general_inspiration: isGeneralInspiration,
      id: projectId,
    };

    setProjectDetails(updatedDetails);

    setCurrentStep(2);
  };

  useEffect(() => {
    if (customProperty) {
      setProperties((prev) => [...prev, customProperty]);
    }
  }, [customProperty]);

  useEffect(() => {
    if (selectedProperty) {
      if (selectedProperty.layout_type_data) {
        setAvailableLayouts(selectedProperty.layout_type_data);
      } else {
        setAvailableLayouts(defaultLayouts);
      }
    } else {
      setAvailableLayouts(defaultLayouts);
    }
  }, [selectedProperty]);

  useEffect(() => {
    let temp = [];
    if (selectedRoomtypes?.length > 0) {
      selectedRoomtypes.forEach((rt) => {
        if (rt?.rooms?.length > 0) {
          rt?.rooms.forEach((r) => {
            temp.push(r);
          });
        }
      });
    }
    setRoomsToDisplay(temp);
  }, [selectedRoomtypes]);

  const handleRoomSelect = (index, item) => {
    setRoomTypeIndex(index);
    setSelectedRoom(item.title);
    setRoomId(item?.id);
    setSelectedRoomSlug(item?.room_type_data?.id);
  };

  const handleProjectSubmit = async (e) => {
    setSubmitLoading(true);
    e.preventDefault();

    const projectDetailsWithStatus = {
      ...projectDetails,
      is_general_inspiration: isGeneralInspiration,
      status: "APPROVED",
      property_segment: projectDetails.property_segment, // Explicitly include property_segment
    };

    const formData = new FormData();
    formData.append("user", user?.id);
    formData.append("project", JSON.stringify(projectDetailsWithStatus));
    formData.append("deleteImageIds", JSON.stringify(deletedImageIds));
    formData.append("room_types", JSON.stringify(selectedRoomtypes));
    formData.append("brands_ids", JSON.stringify(selectedBrands));
    Object.entries(newFiles).forEach(([roomType, files], fileIndex) => {
      files.forEach((file, index) => {
        formData.append(`${roomType}_${fileIndex}`, file);
      });
    });

    /* for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    } */

    try {
      const response = await authActions.editProject(projectId, formData);

      // console.log(response, "project");
      navigate("/");
      setSubmitLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(clearDeletedImageIds());
    }
    setSubmitLoading(false);
  };

  useEffect(() => {
    if (user?.user_type === "builder") {
      fetchPropertiesByBuilderNoPagination();
    } else {
      fetchProperties();
    }
    fetchRoomTypes();
  }, []);

  if (!detailsForProject) return;

  return (
    <>
      <AddBrandsModelForEditProject
        show={showProductsModal}
        setShow={setShowProductsModal}
        selectedRoomtypes={selectedRoomtypes}
        setSelectedBrands={setSelectedBrands}
        setDisplaySelectedBrands={setDisplaySelectedBrands}
        setAllBrands={setAllBrands}
        roomtypeIndex={roomtypeIndex}
        activeRoomIndex={activeRoomIndex}
        setActiveRoomIndex={setActiveRoomIndex}
        toggleBrandSelection={toggleBrandSelection}
        selectedBrands={selectedBrands}
      />
      <Container
        fluid
        className="mt-5 pt-4 px-0 d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "60vh" }}
      >
        <div className="w-100 bg-info d-flex justify-content-center align-items-center">
          <h1 className="h2 text-primary inter-600 py-3">{headingText}</h1>
        </div>
        {processing && (
          <LoadingOverlay isLoading={processing} setLoading={setProcessing} />
        )}
        <div
          className="d-flex flex-column flex-lg-row my-md-4"
          style={large ? { maxWidth: 1000, width: "100%" } : { width: "100%" }}
        >
          <div
            className="d-flex justify-content-center"
            style={{ minWidth: 250 }}
          >
            <div className="d-flex flex-lg-column mt-2 mt-md-4 mt-lg-3 ml-2 mb-1 px-3 px-lg-0">
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${currentStep === 1
                    ? "bg-black text-white"
                    : currentStep === 2 || currentStep === 3
                      ? "bg-success text-white"
                      : "bg-gray-25"
                    }`}
                >
                  {currentStep === 1 ? "1" : <FaCheck size={12} />}
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${currentStep !== 1 ? "text-success" : ""
                    }`}
                >
                  {location.pathname.includes("builder")
                    ? "Sample Flat Details"
                    : "Project Details"}
                </div>
              </div>
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${currentStep === 2
                    ? "bg-black text-white"
                    : currentStep === 3
                      ? "bg-success text-white"
                      : "text-gray-300 bg-gray-25 border border-gray-100"
                    }`}
                >
                  {currentStep === 1 || currentStep === 2 ? (
                    "2"
                  ) : (
                    <FaCheck size={12} />
                  )}
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${currentStep !== 1 && currentStep !== 2
                    ? "text-success"
                    : currentStep === 2
                      ? ""
                      : "text-gray-300"
                    }`}
                >
                  {location.pathname.includes("builder")
                    ? "Sample Flat Image"
                    : "Project Image"}
                </div>
              </div>
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${currentStep === 3
                    ? "bg-black text-white"
                    : "text-gray-300 bg-gray-25 border border-gray-100"
                    }`}
                >
                  3
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${currentStep === 3 ? "text-gray-900" : "text-gray-300"
                    }`}
                >
                  {location.pathname.includes("builder")
                    ? "Sample Flat Review"
                    : "Project Review"}
                </div>
              </div>
            </div>
          </div>
          <div className="p-3">
            <div className="p-3 border" style={{ borderRadius: 6 }}>
              {currentStep === 1 && (
                <div style={large ? { minWidth: 600 } : {}}>
                  <h4 className="inter-600">
                    {currentStep}.{" "}
                    {location.pathname.includes("builder")
                      ? "Sample Flat Details"
                      : "Project Details"}
                  </h4>
                  <div className="mt-4">
                    <EditProjectsForm
                      projectInfoRef={projectInfoRef}
                      projectDetails={projectDetails}
                      properties={properties}
                      setProjectDetails={setProjectDetails}
                      selectedProperty={selectedProperty}
                      setShowPropertyModal={setShowPropertyModal}
                      setSelectedProperty={setSelectedProperty}
                      setSelectedTheme={setSelectedTheme}
                      selectedTheme={selectedTheme}
                      isGeneralInspiration={isGeneralInspiration}
                      setIsGeneralInspiration={setIsGeneralInspiration}
                    />
                    <div className="d-flex">
                      <Button
                        className="d-flex align-items-center text-white body-text2 me-2"
                        style={{ borderRadius: 3 }}
                        onClick={handleProjectDetails}
                      >
                        Continue
                      </Button>
                      <Button
                        className="bg-transparent border-gray-200 text-gray-900 body-text2"
                        style={{ borderRadius: 3 }}
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {currentStep === 2 && (
                <div style={large ? { minWidth: 600 } : {}}>
                  <h4 className="inter-600">
                    {currentStep}.{" "}
                    {location.pathname.includes("builder")
                      ? "Add Sample Flat Images"
                      : "Add Project Images"}
                  </h4>
                  {!loading ? (
                    <EditProjectRoomSelector
                      rooms={rooms}
                      setRooms={setRooms}
                      selectorRooms={selectorRooms}
                      selectedRoomtypes={selectedRoomtypes}
                      setSelectedRoomtypes={setSelectedRoomtypes}
                      setProcessing={setProcessing}
                      setLoading={setLoading}
                      roomtypeIndex={roomtypeIndex}
                      setRoomTypeIndex={setRoomTypeIndex}
                      roomTypes={roomTypes}
                      addSelectedRoomtype={addSelectedRoomtype}
                      handleRoomSelect={handleRoomSelect}
                      onSelectRoomFiles={onSelectRoomFiles}
                      selectedRoomSlug={selectedRoomSlug}
                      setSelectedRoomSlug={setSelectedRoomSlug}
                      setNewFiles={setNewFiles}
                      newFiles={newFiles}
                      setTemps={setTemps}
                      roomId={roomId}
                      setRoomId={setRoomId}
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: 240 }}
                    >
                      <Spinner />
                    </div>
                  )}
                  <p
                    className="text-primary cursor-pointer"
                    onClick={() => {
                      setRoomTypeIndex(roomtypeIndex);
                      // setActiveRoomIndex(index);
                      setShowProductsModal(!showProductsModal);
                    }}
                  >
                    Tag Product Brands Used &gt;
                  </p>
                  <Row>
                    {displaySelectedBrands.map((brand) => (
                      <Col className="mb-1" key={brand.id} xs={12} md={4}>
                        <BrandCards
                          brand={brand}
                          removeBrandDisplaySelection={
                            removeBrandDisplaySelection
                          }
                        />
                      </Col>
                    ))}
                  </Row>

                  <div className="mt-4 d-flex">
                    <Button
                      className="d-flex align-items-center text-white body-text2 me-2"
                      style={{ borderRadius: 3 }}
                      onClick={handleGoToPreview}
                      disabled={previewButtonLoading}
                    >
                      {previewButtonLoading ? (
                        <>
                          <Spinner animation="border" size="sm" className="me-2" />
                          Validating...
                        </>
                      ) : (
                        "Go to Preview"
                      )}
                    </Button>
                  </div>
                </div>
              )}
              {currentStep === 3 && (
                <div style={large ? { width: 600 } : {}}>
                  <EditedProjectDetails
                    projectDetails={projectDetails}
                    status={status}
                    setCurrentStep={setCurrentStep}
                    user={user}
                    currentStep={currentStep}
                    roomsToDisplay={roomsToDisplay}
                    temps={temps}
                    displaySelectedBrands={displaySelectedBrands}
                    mediaForPreview={mediaForPreview}
                  />
                  <div className="d-flex">
                    <Button
                      className="d-flex align-items-center text-white body-text2 me-2"
                      style={{ borderRadius: 3 }}
                      onClick={handleProjectSubmit}
                      disabled={submitLoading}
                    >
                      {submitLoading ? (
                        <>
                          {" "}
                          <Spinner
                            animation="border"
                            size="sm"
                            className="me-2"
                          />{" "}
                          Saving...
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                    <Button
                      className="bg-transparent border-gray-200 text-gray-900 body-text2"
                      style={{ borderRadius: 3 }}
                      onClick={() => setCurrentStep(2)}
                      disabled={submitLoading} // Disable when saving
                    >
                      Back
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <AddPropertyModal
          show={showPropertyModal}
          setShow={setShowPropertyModal}
          setCustomProperty={setCustomProperty}
          setSelectedProperty={setSelectedProperty}
        />
      </Container>
    </>
  );
}

export default EditProjects;