import DropFileInputProjectNew from "components/DragInput/DropFileInputProjectNew";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import ReactSelect from "react-select";
import * as authActions from "reduxFiles/actions/authActions";
import MediaCard from "./MediaCard";

const AddProjectRoomSelector = ({
  selectedRoomtypes,
  setSelectedRoomtypes,
  setLoading,
  setProcessing,
  roomtypeIndex,
  setRoomTypeIndex,
  roomTypes,
  addSelectedRoomtype,
  setTemps,
}) => {
  const prevRoomtypesRef = useRef(selectedRoomtypes);
  const justAddedRef = useRef(false);

  const handleRoomTypeChange = (types) => {
    setProcessing(true);
    if (selectedRoomtypes.length < types.length) {
      // A new room type has been added
      if (types?.length > 0) {
        const item = types[types.length - 1];
        const newRoomtype = {
          id: item.id,
          title: item.title,
          image: item.image,
          rooms: [],
        };

        // Add the new room type and update state
        const updatedRoomtypes = [...selectedRoomtypes, newRoomtype];
        setSelectedRoomtypes(updatedRoomtypes);

        // Set focus to the newly added room type
        setRoomTypeIndex(updatedRoomtypes.length - 1);
        justAddedRef.current = true;
      }
    } else {
      // A room type has been removed
      const rtypesIds = new Set(types.map((item) => item.id));
      const temp = [];
      for (const item of selectedRoomtypes) {
        if (rtypesIds.has(item.id)) {
          temp.push(item);
        }
      }
      setSelectedRoomtypes(temp);

      // Adjust the index if the currently selected type was removed
      if (temp.length === 0) {
        setRoomTypeIndex(0);
      } else if (roomtypeIndex >= temp.length) {
        setRoomTypeIndex(temp.length - 1);
      }
    }
    setProcessing(false);
  };


  useEffect(() => {
    // Skip this effect if we just added a new room type
    if (justAddedRef.current) {
      justAddedRef.current = false;
      prevRoomtypesRef.current = selectedRoomtypes;
      return;
    }

    if (selectedRoomtypes.length === 0) {
      setRoomTypeIndex(0);
      prevRoomtypesRef.current = [];
      return;
    }

    const prevRoomtypes = prevRoomtypesRef.current;
    const currentRoomType = prevRoomtypes[roomtypeIndex];

    if (currentRoomType) {
      const newIndex = selectedRoomtypes.findIndex(
        (rt) => rt.id === currentRoomType.id
      );
      if (newIndex !== -1) {
        if (newIndex !== roomtypeIndex) {
          setRoomTypeIndex(newIndex);
        }
      } else {
        const newRoomTypeIndex = roomtypeIndex > 0 ? roomtypeIndex - 1 : 0;
        setRoomTypeIndex(newRoomTypeIndex);
      }
    } else {
      setRoomTypeIndex(0);
    }
    prevRoomtypesRef.current = selectedRoomtypes;
  }, [selectedRoomtypes, roomtypeIndex, setRoomTypeIndex]);

  const onRemoveRoomFile = (index) => {
    const removedFile = selectedRoomtypes[roomtypeIndex].rooms[index].file;
    const temp = [...selectedRoomtypes];

    const updatedRoomtype = {
      ...temp[roomtypeIndex],
      rooms: [
        ...temp[roomtypeIndex].rooms.slice(0, index),
        ...temp[roomtypeIndex].rooms.slice(index + 1),
      ],
    };

    const updatedRoomtypes = [
      ...temp.slice(0, roomtypeIndex),
      updatedRoomtype,
      ...temp.slice(roomtypeIndex + 1),
    ];

    setSelectedRoomtypes(updatedRoomtypes);

    setTemps((prevTemps) =>
      prevTemps.filter((tempFile) => tempFile !== removedFile)
    );
  };

  const onSelectRoomFiles = (files, index = roomtypeIndex) => {
    setProcessing(true);
    const currentFiles = new Set(
      selectedRoomtypes[index].rooms.map((item) => item.file)
    );
    const result = files.filter((file) => !currentFiles.has(file));
    setTemps((prev) => [...prev, ...result]);
    const temp = result?.map((item) => {
      // Add a type property to distinguish between image and video files
      const isVideo = item.type.startsWith('video/') || 
                     item.name.match(/\.(mp4|webm|mov|avi|wmv)$/i);
      return { 
        file: item, 
        products: [],
        fileType: isVideo ? 'video' : 'image' 
      };
    });
    setSelectedRoomtypes([
      ...selectedRoomtypes.slice(0, index),
      {
        ...selectedRoomtypes[index],
        rooms: [...selectedRoomtypes[index].rooms, ...temp],
      },
      ...selectedRoomtypes.slice(index + 1),
    ]);
    setProcessing(false);
  };


  return (
    <div style={{ minHeight: 300 }}>
      <div className="my-3">
        <ReactSelect
          name="type_of_space"
          defaultValue={[
            roomTypes.filter(
              (item) => item.title.toLowerCase() === "master bedroom"
            )[0],
          ]}
          options={roomTypes}
          isMulti
          getOptionLabel={(e) => e.title}
          getOptionValue={(e) => e.id}
          onChange={(e) => handleRoomTypeChange(e)}
          isClearable
          placeholder="Select type of space"
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderColor: "#BDC1C6",
            }),
          }}
        />
      </div>
      <div
        className="d-flex align-items-start mb-3 flex-wrap"
        style={{ width: "100%" }}
      >
        {selectedRoomtypes.length > 0 &&
          selectedRoomtypes?.map((item, index) => {
            return (
              <div key={index}>
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                    borderRadius: "5px",
                    border:
                      index === roomtypeIndex
                        ? "1px solid black"
                        : "1px solid #dee2e6",
                    backgroundColor:
                      index === roomtypeIndex ? "#f8f9fa" : "white",
                    padding: "8px 12px",
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: index === roomtypeIndex ? "black" : "#212529",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    marginRight: "8px",
                    marginBottom: "10px",
                  }}
                  onClick={() => setRoomTypeIndex(index)}
                >
                  {item.title} {/* ({item.rooms.length}) */}
                </Button>
              </div>
            );
          })}
      </div>
      <div
        className="position-relative d-flex justify-content-center align-items-center border border-dashed"
        style={{ width: "100%", borderRadius: 8 }}
      >
        <Row
          xs={2}
          sm={2}
          md={3}
          lg={3}
          xl={3}
          className="pt-2 pb-2 g-2 room-images-row"
        >
          {selectedRoomtypes[roomtypeIndex]?.rooms?.map((item, index) => (
            <Col key={index} className="p-2 mb-2">
            <MediaCard 
              file={item.file} 
              onRemove={onRemoveRoomFile} 
              index={index} 
            />
          </Col>
          ))}
          {selectedRoomtypes?.map((item, index) => (
            <>
              {index === roomtypeIndex && (
                <Col key={`drop-${index}`} className="p-2 mb-2">
                  <DropFileInputProjectNew
                    onFileChange={(files) => onSelectRoomFiles(files)}
                    allowMultiple
                    className={`${index === roomtypeIndex ? "d-block" : "d-none"
                      }`}
                  />
                </Col>
              )}
            </>
          ))}
        </Row>
      </div>

      {/* {selectedRoomtypes.length > 0 &&
                        selectedRoomtypes?.map((item, index) => {
                          return (
                            <div key={index} className="mb-3">
                              <DropFileInputProject
                                allowMultiple
                                allowOnlyImages
                                className={`bg-white border-dark ${
                                  index === roomtypeIndex ? "d-block" : "d-none"
                                }`}
                                files={() =>
                                  item?.rooms?.map((item) => item.file)
                                }
                                onFileChange={(files) => {
                                  onSelectRoomFiles(files);
                                }}
                              />
                            </div>
                          );
                        })} */}
    </div>
  );
};

export default AddProjectRoomSelector;
