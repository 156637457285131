import { ad_1, ad_2 } from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import ProfessionalCard from "components/Cards/ProfessionalCard";
import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import MultipleAds from "components/Advertisements/MultipleAds";
import BrandCardShimmer from "components/Cards/BrandCardShimmer";
import { useSelector } from "react-redux";
import { FaDraftingCompass } from "react-icons/fa";

const ArchitectsList = () => {
  const navigate = useNavigate();
  const { detailedSlug } = useParams();

  const [architects, setArchitects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [next, setNext] = useState("");
  const selectedLocation = useSelector(
    (state) => state.location.currentLocation
  );

  /* const fetchArchitects = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchArchitectsPagewise(page);
      console.log(response, "architect");
      if (response.data) {
        setArchitects((prev) => [...prev, ...response.data.results]);
        setTotalPages(Math.ceil(response.data.count / 12));
        setNext(response.data.next);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }; */

  const fetchArchitects = async (page, location) => {
    setLoading(true);
    try {
      const locationFilter = location === "All Location" ? "" : location;
      const response = await authActions.fetchArchitectsPagewise(
        page,
        locationFilter
      );
      if (response.data) {
        setArchitects((prev) =>
          page === 1
            ? response.data.results
            : [...prev, ...response.data.results]
        );
        setTotalPages(Math.ceil(response.data.count / 12));
        setNext(response.data.next);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (currentPage <= 2) {
      fetchArchitects(currentPage, selectedLocation);
    }
  }, [currentPage, selectedLocation]);

  // Infinite Scroll Handler
  const handleScroll = () => {
    const bottom =
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100; // Trigger 100px before the bottom
    if (bottom && !loading && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, currentPage, totalPages]);

  if (detailedSlug) {
    return <Outlet />;
  }

  return (
    <Container fluid className="mt-5 pt-5">
      <Container className="d-flex flex-column alignt-items-center">
        <Row>
          <Col md={8}>
            {loading && architects.length === 0 ? (
              <div className="container">
                {[...Array(4)].map((_, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <BrandCardShimmer />
                  </div>
                ))}
              </div>
            ) : architects.length > 0 ? (
              <div className="d-flex flex-column align-items-center w-100">
                {architects.map((architect, index) => (
                  <ProfessionalCard
                    key={index}
                    {...architect}
                    type={"Architect"}
                    architect_id={architect?.user_data?.id}
                    profile_img={architect.user_data?.profile_picture}
                    onClick={() =>
                      navigate(`/professionals/architects/${architect.slug}`)
                    }
                  />
                ))}
                {loading && (
                  <div className="container">
                    {[...Array(4)].map((_, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <BrandCardShimmer />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center align-items-center bg-gray-25 rounded"
                style={{ height: 160 }}
              >
                <FaDraftingCompass size={40} className="mb-2 text-muted" />
                <h5>No Architects Available</h5>
              </div>
            )}
          </Col>
          <Col md={4} lg={4} xl={3}>
            <MultipleAds endPoint={"customer_ads"} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ArchitectsList;
