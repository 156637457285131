import React, { useRef } from "react";
import { Button, Image } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { truncateText } from "utilities/truncateText";
import { useMediaQuery } from "react-responsive";
import ShimmerSlider from "pages/Home/ShimmerSlider";

const ProductCategorySlider = ({
  all_products,
  productCategory,
  productCategories,
  setProductCategory,
  onChangeFilters,
  fetchProducts,
}) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const sliderRef = useRef(null);

  const slideLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -sliderRef.current.offsetWidth * 0.2,
        behavior: "smooth",
      });
    }
  };

  const slideRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: sliderRef.current.offsetWidth * 0.2,
        behavior: "smooth",
      });
    }
  };

  const handleAllProducts = () => {
    // fetchProducts();
    onChangeFilters(null, "category");
  };

  return (
    <>
      {productCategories.length > 0 ? (
        <div
          className="d-flex align-items-center position-relative"
          style={{ gap: "16px", paddingBottom: "10px" }}
        >
          {/* Left Slide Button */}
          {small && (
            <div
              className="custom-prev-arrow-spaces position-absolute"
              style={{ display: "flex", left: -15, zIndex: 1 }}
              onClick={slideLeft}
            >
              <IoIosArrowBack size={15} color="white" />
            </div>
          )}

          {/* Slider Content */}
          <div
            ref={sliderRef}
            className="d-flex flex-nowrap overflow-auto gap-3 py-2 px-4"
          >
            {/* All Products Card */}
            <div
              className="d-flex flex-column align-items-center cursor-pointer"
              onClick={handleAllProducts}
            >
              <div
                style={{
                  position: "relative",
                  width: "132px",
                  height: "124px",
                }}
              >
                <Image
                  src={all_products}
                  alt="All Products"
                  className="small-imgs"
                  width="132px"
                  height="124px"
                />
              </div>
              <div className="p-1">All Products</div>
            </div>

            {/* Dynamic Product Category Cards */}
            {productCategories.map((productCat, index) => (
              <div
                key={index}
                className="d-flex flex-column align-items-center cursor-pointer"
                style={{
                  border:
                    productCategory === productCat.id
                      ? "2px solid #f1a1a1"
                      : "1px solid #e0e0e0", // Lighter border for selected item
                  borderRadius: "5px",
                  backgroundColor:
                    productCategory === productCat.id
                      ? "#fce4e4"
                      : "transparent", // Lighter background color
                  boxShadow:
                    productCategory === productCat.id
                      ? "0 2px 4px rgba(0, 0, 0, 0.1)"
                      : "",
                  transition: "all 0.3s ease", // Smooth transition for style changes
                }}
                onClick={() => {
                  setProductCategory(productCat.id);
                  onChangeFilters(productCat.id, "category");
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "132px",
                    height: "124px",
                  }}
                >
                  <Image
                    src={productCat?.image || all_products}
                    alt={productCat?.title}
                    className="small-img"
                    width="132px"
                    height="124px"
                  />
                </div>
                <div className="p-1">{truncateText(productCat?.title, 13)}</div>
              </div>
            ))}
          </div>

          {/* Right Slide Button */}
          {small && (
            <div
              className="custom-next-arrow-spaces position-absolute "
              style={{ display: "flex", right: -15, zIndex: 1 }}
              onClick={slideRight}
            >
              <IoIosArrowForward size={15} color="white" />
            </div>
          )}
        </div>
      ) : (
        <ShimmerSlider />
      )}
    </>
  );
};

export default ProductCategorySlider;
