import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { formatNumber } from "utilities/numberFormatter";
import ProductCard from "components/Cards/ProductCard";
import ShareModal from "components/General/ShareModal";
import InspirationSliderView from "./InspirationSliderView";
import * as authActions from "reduxFiles/actions/authActions";
import userImg from "../../assets/navbar/user.webp";
import { MdOutlineFileDownload } from "react-icons/md";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { FaRegHeart } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import { notify } from "utilities/notifications/Notify";
import { testimonial_pic } from "assets";
import LoginModal from "components/Auth/LoginModal";
import Slider from "react-slick";
import ProjectCard from "components/Cards/ProjectCard";
import MobileProjectCard from "components/Cards/MobileProjectCard";
import { GoArrowDownRight, GoArrowUpRight } from "react-icons/go";
import {
  deleteProjectDetails,
  updateProjectDetails,
  updateServiceType,
} from "reduxFiles/reducer/projectSlice";
import { setPropertyId } from "reduxFiles/reducer/consultationSlice";
import ProjectBrandCard from "components/Cards/ProjectBrandCard";
import ImageSlider from "components/CustomSlider/ImageSlider";

const ProjectDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [projectDetails, setProjectDetails] = useState(null);
  const [error, setError] = useState("");
  const [showShare, setShowShare] = useState(false);
  const [showSpaceView, setShowSpaceView] = useState(false);
  const [activeSpaceIndex, setActiveSpaceIndex] = useState(0);
  const [roomData, setRoomData] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [roomId, setRoomId] = useState("");
  const [image, setImage] = useState("");
  const [showAllRooms, setShowRooms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [similarProjects, setSimilarProjects] = useState([]);
  const [archDetails, setArchDetails] = useState();
  const [brandDetails, setBrandDetails] = useState();
  const [roomImages, setRoomImages] = useState([]);
  const [roomImageIndex, setRoomImageIndex] = useState(0);
  const [imagesByRoom, setImagesByRoom] = useState({}); // New state to store images grouped by room

  const fetchProject = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await authActions.fetchProjectBySlug(slug);
      if (response.data) {
        setProjectDetails(response?.data?.results[0]);

        setRoomId(response?.data?.results[0]?.rooms[0]?.id);
        dispatch(updateProjectDetails(response?.data?.results[0]));
        dispatch(setPropertyId(response?.data?.results[0]?.project_property));
      } else {
        setError("Error while fetching project details. Try again later!");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchMedia = async () => {
    try {
      const response = await authActions.getMediaFiles(
        "project",
        "room",
        roomId
      );
      if (response.length > 0) {
        setImage(response[0]?.file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMedias = async () => {
    try {
      const response = await authActions.getMediaFilesFromParents(
        "project",
        "room",
        projectDetails?.rooms.map((room) => room.id)
      );
      if (response.length > 0) {
        setRoomImages(response);
        // Group images by room
        const groupedImages = response.reduce((acc, image) => {
          const roomId = image.ParentId;
          if (!acc[roomId]) {
            acc[roomId] = [];
          }
          acc[roomId].push(image);
          return acc;
        }, {});
        setImagesByRoom(groupedImages);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSimilarProjects = async () => {
    if (projectDetails?.architect) {
      try {
        const response = await authActions.fetchProjectsByArchitect(
          projectDetails.architect
        );
        if (response?.data?.results) {
          const filteredProjects = response.data.results.filter(
            (project) => project.id !== projectDetails.id
          );
          setSimilarProjects(filteredProjects);
        } else {
          setError("Error while fetching project details. Try again later!");
        }
      } catch (error) {
        console.log(error);
      }
    } else if (projectDetails?.brand) {
      try {
        const response = await authActions.fetchProjectsByBrand(
          projectDetails.brand
        );
        if (response?.data?.results) {
          const filteredProjects = response.data.results.filter(
            (project) => project.id !== projectDetails.id
          );
          setSimilarProjects(filteredProjects);
        } else {
          setError("Error while fetching project details. Try again later!");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await authActions.fetchProjectsRandom();
        if (response?.data?.results) {
          const filteredProjects = response.data.results.filter(
            (project) => project.id !== projectDetails.id
          );
          setSimilarProjects(filteredProjects);
        } else {
          setError("Error while fetching project details. Try again later!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const checkProjectIfSaved = async () => {
    try {
      const response = await authActions.checkProjectIfSaved(
        user.id,
        projectDetails.id
      );
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProject = async () => {
    try {
      const response = await authActions.saveProject(
        user.id,
        projectDetails.id
      );
      if (response.data) {
        setIsLiked(true);
        notify("Project saved successfully", "success");
      } else {
        notify("Failed to save project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProject = async () => {
    try {
      const response = await authActions.unsaveProject(
        user.id,
        projectDetails.id
      );
      if (response) {
        setIsLiked(false);
        notify("Project unsaved successfully", "success");
      } else {
        notify("Failed to unsave project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getYear = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
  };

  const fetchArchitectDetails = async () => {
    try {
      const response = await authActions.fetchArchitect(
        projectDetails.architect
      );
      if (response) {
        setArchDetails(response.data);
        console.log("Architect details fetched successfully:", response);
      } else {
        console.error("No details found for the specified architect.");
      }
    } catch (error) {
      console.error("Failed to fetch architect details:", error);
    }
  };

  const fetchBrandDetails = async () => {
    try {
      const response = await authActions.fetchBrandInfo(projectDetails.brand);
      if (response) {
        setBrandDetails(response.data);
        console.log("Architect details fetched successfully:", response);
      } else {
        console.error("No details found for the specified architect.");
      }
    } catch (error) {
      console.error("Failed to fetch architect details:", error);
    }
  };

  const navigateToArchitectOrBrandPage = () => {
    if (archDetails?.slug) {
      navigate(`/professionals/architects/${archDetails.slug}`);
    }
    if (brandDetails?.slug) {
      navigate(`/brands/${brandDetails.slug}`);
    }
  };

  const roomsInRow = xlarge ? 6 : large ? 4 : medium ? 3 : 2;

  useEffect(() => {
    if (showAllRooms) {
      setRooms(projectDetails?.rooms);
    } else {
      setRooms(projectDetails?.rooms?.slice(0, roomsInRow));
    }
    if (projectDetails?.rooms?.length > 0) {
      fetchMedias();
    }
  }, [projectDetails, showAllRooms]);

  useEffect(() => {
    if (slug && slug !== "undefined") {
      fetchProject();
    } else {
      navigate(-1);
    }
  }, [slug]);

  useEffect(() => {
    if (roomId) {
      fetchMedia();
    }
  }, [roomId]);

  useEffect(() => {
    if (projectDetails?.architect != null) {
      fetchArchitectDetails();
    }
    if (projectDetails?.brand != null) {
      fetchBrandDetails();
    }
  }, [projectDetails]);

  useEffect(() => {
    if (projectDetails?.id) {
      checkProjectIfSaved();
    }
  }, [projectDetails]);

  useEffect(() => {
    fetchSimilarProjects();
  }, [projectDetails]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: similarProjects.length > 1,
    autoplay: true,
    speed: 1200,
    slidesToShow: small ? 3 : 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
  };
  const handleClickTurkey = () => {
    if (projectDetails?.id) {
      navigate(`/consultation`, {
        state: {
          servicetype: "turnkey",
          project: projectDetails,
          fromInspiration: true,
          inspirationSlug: slug, // Add the slug here
        },
      });
      dispatch(updateServiceType("turnkey"));
    } else {
      notify("Project undefined, try again later", "error");
    }
  };

  const handleClickCustomised = () => {
    if (projectDetails?.id) {
      navigate(`/consultation`, {
        state: {
          servicetype: "customised",
          project: projectDetails,
          fromInspiration: true,
          inspirationSlug: slug, // Add the slug here
        },
      });
      dispatch(updateServiceType("customised"));
    } else {
      notify("Project undefined, try again later", "error");
    }
  };
  return (
    <div className="mt-5 pt-5 p-0">
      {loading ? (
        <Container
          className="d-flex justify-content-center align-items-center w-100"
          style={{ minHeight: "50vh" }}
        >
          <Spinner />
        </Container>
      ) : error ? (
        <Container
          className="d-flex justify-content-center align-items-center h3 inter-600 text-center"
          style={{ minHeight: "50vh" }}
        >
          {error}
        </Container>
      ) : (
        <div>
          <Container fluid className="d-flex justify-content-center">
            <Container className="d-flex flex-column flex-lg-row align-items-start px-0 p-lg-3 m-0">
              <div
                className="d-flex justify-content-center align-items-center bg-white overflow-hidden"
                style={{
                  width: "100%",
                  maxWidth: small ? 350 : 10000,
                  height: 200,
                }}
              >
                <Image
                  className="bg-white"
                  width="100%"
                  height="200"
                  style={{
                    objectFit: "contain",
                    borderRadius: "12px",
                  }}
                  src={projectDetails?.property_data?.property_img || userImg}
                  alt="Builder's Profile"
                />
              </div>
              <div className="w-100 ms-0 ms-lg-3 text-gray-900">
                <div className="d-flex align-items-center mb-2 mt-2 mt-lg-0">
                  <div className="h3 inter-600">
                    {projectDetails?.project_name}/{projectDetails?.bhk}/
                    {projectDetails?.property_name}
                  </div>
                  <div className="d-flex ms-auto me-lg-5">
                    <Button
                      className="d-flex justify-content-center align-items-center share-like-btn-100 p-0"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowShare(true);
                      }}
                    >
                      <FiSend size={20} />
                    </Button>

                    {/* // user?.user_type !== "builder" &&
                    user?.user_type !== "architect" &&
                    user?.user_type !== "brand" && ( */}
                    <Button
                      className={`d-flex justify-content-center align-items-center share-like-btn-100 ms-2 p-0 ${
                        isLiked ? "bg-primary text-white" : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();

                        if (!user) {
                          setShowLoginModal(true);
                        } else {
                          isLiked ? handleUnsaveProject() : handleSaveProject();
                        }
                      }}
                    >
                      <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : ""} />
                    </Button>
                  </div>
                </div>
                <div className="body-text1 inter-400 mb-2">
                  <div className="mb-3">
                    {projectDetails?.company_name ||
                    projectDetails?.brand_name ? (
                      <span
                        className="bg-gray-50 py-2 px-3 inter-500 body-text-2 text-gray-700 cursor-pointer"
                        onClick={navigateToArchitectOrBrandPage}
                      >
                        {projectDetails.company_name ||
                          projectDetails.brand_name}
                        <GoArrowUpRight size={14} />
                      </span>
                    ) : (
                      <span className="bg-gray-50 py-2 px-3 inter-500 body-text-2 text-gray-700">
                        Sample Flat
                      </span>
                    )}
                  </div>

                  <div className="">Layout Type: {projectDetails?.bhk}</div>
                  <div className="">
                    {/* Area: {projectDetails?.project_area} Sft. */}
                    Area: {projectDetails?.flat_area} Sft.
                  </div>
                  <div className="">
                    Project Cost: {formatNumber(projectDetails?.project_cost)}{" "}
                    {projectDetails?.project_cost_unit}
                  </div>
                  <div className="">
                    Location:{" "}
                    {(() => {
                      const addressPart =
                        projectDetails?.property_data?.address?.trim() ||
                        projectDetails?.project_address?.trim() ||
                        "";
                      const cityPart =
                        projectDetails?.property_data?.city?.trim() ||
                        projectDetails?.project_city?.trim() ||
                        "";
                      const statePart =
                        projectDetails?.property_data?.state?.trim() ||
                        projectDetails?.project_state?.trim() ||
                        "";
                      const countryPart =
                        projectDetails?.property_data?.country?.trim() ||
                        projectDetails?.project_country?.trim() ||
                        "";
                      const locationPart = [
                        addressPart,
                        cityPart,
                        statePart,
                        countryPart,
                      ]
                        .filter(Boolean)
                        .join(", ") || "N/A";
                      const pincode =
                        projectDetails?.property_data?.pincode?.trim() ||
                        projectDetails?.project_pincode?.trim() ||
                        "";
                      const pincodePart = pincode ? ` - ${pincode}` : "";
                      return `${locationPart}${pincodePart}`;
                    })()}
                  </div>
                </div>
              </div>
            </Container>
          </Container>
          <Container className="">
            <div className="mt-4 mb-3">
              <span className="body-text inter-700">Image Description:</span>{" "}
              {projectDetails?.project_description}
            </div>
            <div>
              <Row className="mb-4">
                <Col lg={8}>
                  <div
                    className="position-relative mb-4 mb-lg-0"
                    style={{
                      borderRadius: 12,
                      overflow: "hidden",
                      backgroundColor: "#F1F3F4",
                    }}
                  >
                    {roomImages?.length > 0 ? (
                      <>
                        <img
                          className="img-fluid"
                          src={roomImages[roomImageIndex]?.file || userImg}
                          alt=""
                          style={{
                            width: "100%",
                            height: medium ? 450 : 300,
                            objectFit: "cover",
                            borderRadius: 12,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const currentImage = roomImages[roomImageIndex];
                            const matchedRoom = projectDetails?.rooms?.find(
                              (room) => room.id === currentImage?.ParentId
                            );
                            if (matchedRoom) {
                              const roomIndex = projectDetails?.rooms?.findIndex(
                                (room) => room.id === matchedRoom.id
                              );
                              setRoomData({
                                id: matchedRoom.id,
                                projectId: matchedRoom.project,
                              });
                              setActiveSpaceIndex(roomIndex !== -1 ? roomIndex : 0);
                              setShowSpaceView(true);
                            }
                          }}
                        />
                        {/* Button positioned at bottom-left, only when image is present */}
                        <div
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            left: "10px",
                          }}
                        >
                          <Button className="bg-gray-25 text-dark border-0 px-3 p-2 rounded inter-700 body-text2 mx-2">
                            {projectDetails?.rooms?.find(
                              (room) =>
                                room.id === roomImages[roomImageIndex]?.ParentId
                            )?.room_type_data?.title || "Unknown Room Type"}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: medium ? 450 : 300,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "1.2rem",
                          fontStyle: "italic",
                        }}
                      >
                        No Image Available
                      </div>
                    )}
                  </div>
                </Col>
                <Col lg={4}>
                  <div
                    className="d-flex flex-column overflow-hidden border"
                    style={{ borderRadius: 12, height: 450 }}
                  >
                    <div
                      className="inter-600 p-3 pb-2"
                      style={{ flex: "0 0 auto" }}
                    >
                      Brands Used in Image
                    </div>
                    <div className="overflow-scroll">
                      {projectDetails?.brands_ids?.length > 0 ? (
                        <div>
                          {projectDetails?.brands_ids?.map((brand, index) => (
                            <div key={index}>
                              <ProjectBrandCard brand={brand?.brand_data} />
                              {index !==
                                projectDetails.brands_ids.length - 1 && (
                                  <hr className="brand-divider" />
                                )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="px-3">
                          <div
                            className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                            style={{ height: 160 }}
                          >
                            No brands available
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <div>
                <InspirationSliderView
                  projectDetails={projectDetails}
                  ativeRoomIndex={activeSpaceIndex}
                  setActiveRoomIndex={setActiveSpaceIndex}
                  show={showSpaceView}
                  setShow={setShowSpaceView}
                  roomData={roomData}
                  specificRooms={projectDetails?.rooms}
                  roomImages={roomImages}
                  imagesByRoom={imagesByRoom} // Pass grouped images
                  initialImageIndex={roomImageIndex}
                />
                {roomImages?.length > 0 && (
                  <ImageSlider
                    items={roomImages.map((item) => item.file)}
                    currentItemIndex={roomImageIndex}
                    setCurrentIndex={setRoomImageIndex}
                  />
                )}
              </div>
            </div>
          </Container>
          {(user === null || user?.user_type === "customer") && (
            <Container className="geist my-4 my-md-5">
              <div className="h3 inter-600">
                Want these interiors for your home?
              </div>
              <div className="body-text1">
                At InterioKeys, you can choose from the 2 types of services
                provided. We recommend going Turnkey!
              </div>
              <div className="d-flex flex-column flex-lg-row my-4">
                <Col className="card p-3 border-primary">
                  <div className="h4 inter-700 text-start mt-3">
                    Turnkey (Complete) Interior Solution
                  </div>
                  <div className="body-text1 inter-400 text-gray-600 text-start mb-3">
                    Complete hassle-free execution of your home interiors from
                    ideation to handover, while maintaining transparency every
                    step of the way.
                  </div>
                  <ul className="body-text1 inter-500 text-gray-700">
                    <li>Interior Inspiration</li>
                    <li>First Free Consultation</li>
                    <li>Architect Consultations</li>
                    <li>Project Details and Estimates</li>
                    <li>Customized Drawings</li>
                    <li>Complete Site Execution</li>
                  </ul>
                  <Button
                    className="body-title inter-600 text-white mt-4 p-2"
                    onClick={handleClickTurkey}
                  >
                    Book Free Consultation
                  </Button>
                </Col>
                <Col
                  lg={1}
                  className="d-flex justify-content-center align-items-center py-4"
                >
                  <div className="fw-bold">OR</div>
                </Col>
                <Col className="card p-3">
                  <div className="h4 inter-700 text-start mt-3">
                    Project Drawings Only
                  </div>
                  <div className="body-text1 inter-400 text-gray-600 text-start mb-3">
                    Final construction drawings and architect support till
                    release of final drawings. Project execution not included.
                    Suited for people with contractors.
                  </div>
                  <ul className="body-text1 inter-500 text-gray-700">
                    <li>Interior Inspiration</li>
                    <li>First Free Consultation</li>
                    <li>Architect Consultations</li>
                    <li>Project Details and Estimates</li>
                    <li>Customized Drawings</li>
                    <li className="text-primary">
                      Site Execution (Not Included)
                    </li>
                  </ul>
                  <div className="d-flex flex-column flex-lg-row text-white mt-4 mt-auto">
                    <div className="mb-2 mb-lg-0 me-3 me-lg-0 w-100">
                      <Button
                        variant="info"
                        className="body-title w-95 inter-600 w-100 text-primary p-2"
                        onClick={handleClickCustomised}
                      >
                        Book Free Consultation
                      </Button>
                    </div>
                    {/* {large && <div className="p-2" />}
                  <div className={`mb-2 mb-lg-0 w-100`}>
                    <Button className="body-title w-95 inter-600 w-100 bg-gray p-2">
                      <MdOutlineFileDownload /> Sample Estimate and Plans
                    </Button>
                  </div> */}
                  </div>
                </Col>
              </div>
            </Container>
          )}
          <Row className="w-100">
            {similarProjects?.length > 0 && (
              <Container className="px-1 py-5">
                <div className="h3 inter-600 text-gray-900 text-start mb-1 mb-lg-3 ms-2">
                  Explore similar projects
                </div>
                <Slider {...settings}>
                  {similarProjects?.map((item, index) => {
                    return (
                      <Col
                        key={index}
                        lg={4}
                        md={12}
                        sm={12}
                        className={`d-flex flex-column py-2 px-2 justify-content-start ${
                          small ? "" : "p-0"
                        }`}
                      >
                        {small ? (
                          <ProjectCard
                            {...item}
                            id={item.id}
                            project_name={item?.project_name}
                            // image={item?.file}
                            roomId={item?.rooms[0]?.id}
                            type={`${item?.bhk}, ${item?.project_area}`}
                            architect={item?.company_name}
                            location={item?.property_data?.location}
                            builders={
                              item?.property_data?.builder_data?.company_name
                            }
                            is_sample_flat={item?.is_sample_flat}
                            className="mx-0 flex-grow"
                          // brand={item?.brand_name}
                          />
                        ) : (
                          <MobileProjectCard
                            {...item}
                            id={item.id}
                            project_name={item?.project_name}
                            // image={item?.rooms[0]?.media[0]?.file}
                            roomId={item?.rooms[0]?.id}
                            type={`${item?.bhk}, ${item?.project_area}`}
                            builder_name={
                              item?.property_data?.builder_data?.company_name
                            }
                            is_sample_flat={item?.is_sample_flat}
                            architect={item?.company_name}
                            className="w-100 mobile-card"
                          />
                        )}
                      </Col>
                    );
                  })}
                </Slider>
              </Container>
            )}
          </Row>
        </div>
      )}
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={projectDetails?.id}
        image={projectDetails?.user_data?.profile_picture}
        title={projectDetails?.project_name}
        urlPath={slug !== "undefined" ? `/projects/${slug}` : null}
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </div>
  );
};

export default ProjectDetails;
