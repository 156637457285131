import React, { useEffect, useRef, useState } from "react";
import { logout, toggledNavBar } from "reduxFiles/reducer/authSlices";
import {
  Button,
  Card,
  Container,
  Image,
  Nav,
  Navbar,
  NavDropdown,
  NavItem,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "components/Auth/LoginModal";
import * as authActions from "reduxFiles/actions/authActions";
import { interiokeys_full_logo, interiokeys_logo } from "assets";
import { notify } from "utilities/notifications/Notify";

import InspirationMegaMenu from "./InspirationMegaManu";
import BrandMegaMenu from "./BrandMegaMenu";
import ProfessionalMegaMenu from "./ProfessionalMegaMenu";
import BuilderMegaMenu from "./BuilderMegaMenu";

import LocationModal from "./LocationModal";
import SearchBar from "./SearchBar";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import { updateCurrentLocation } from "reduxFiles/reducer/locationSlice";
import {
  deleteProjectDetails,
  updateServiceType,
} from "reduxFiles/reducer/projectSlice";

const NavLink = ({ className, title, route }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  const [hover, setHover] = useState(null);

  return (
    <Nav.Link
      className={`py-2 ${param1 === route ? "border-primary" : ""
        } ${className}`}
      onClick={() => {
        navigate(`/${route}`);
      }}
      onMouseEnter={() => setHover(route)}
      onMouseLeave={() => setHover(null)}
      style={{
        color:
          hover || hover === ""
            ? hover === route
              ? "red"
              : "black"
            : param1 === route
              ? "red"
              : "black",
      }}
    >
      {title}
    </Nav.Link>
  );
};

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedLocation = useSelector(
    (state) => state.location.currentLocation
  );
  // const queryParams = new URLSearchParams(location.search);

  const params = location.pathname.split("/").filter((item) => item !== "");
  // const param1 = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 992px) and (max-width: 1300px)",
  });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [locationDropdown, setLocationDropdown] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("All Location");
  const [hoveredLocation, setHoveredLocation] = useState("");
  const [showLocationModal, setShowLocationModal] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [accHover, setAccHover] = useState(false);
  const [toggled, setToggled] = useState(false);
  const navbarRef = useRef(null);

  let inspirationHideTimeout;
  const [showInspirations, setShowInspirations] = useState(
    !small ? true : false
  );
  let brandHideTimeout;
  const [showBrands, setShowBrands] = useState(false);
  let professionalHideTimeout;
  const [showProfessionals, setShowProfessionals] = useState(false);
  const [partnerDropdown, setPartnerDropdown] = useState(false);
  const [hoveredOption, setHoveredOption] = useState("");
  const [showBuilders, setShowBuilders] = useState(false);
  let buildersHideTimeout;

  const options = [
    { label: "Builder", value: "builder" },
    { label: "Pros", value: "architect" },
    { label: "Brands", value: "brand" },
    { label: "Others", value: "others" },
  ];

  useEffect(() => {
    if (showProfessionals || showBrands || showInspirations || showBuilders) {
      dispatch(toggledNavBar(true));
    } else {
      dispatch(toggledNavBar(false));
    }
  }, [showProfessionals, showBrands, showInspirations, showBuilders]);

  const fetchAllLocations = async () => {
    try {
      const response = await authActions.fetchAllCities();
      if (response.data) {
        setAllLocations(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await authActions.logout({ user: user });
      if (response) {
        dispatch(logout());
        notify("Logged out successfully!", "success");
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    dispatch(deleteProjectDetails({}));
    dispatch(updateServiceType(""));
    navigate("/consultation", { state: { navBar: "" } });
    setToggled((prev) => !prev);
  };

  useEffect(() => {
    dispatch(updateCurrentLocation(currentLocation));
  }, [currentLocation]);

  useEffect(() => {
    fetchAllLocations();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setToggled(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      <Navbar
        expand="lg"
        fixed="top"
        className="bg-white border"
        expanded={toggled} // Control the expanded state
        ref={navbarRef}
      >
        <Container fluid className="px-2 px-lg-3 py-2">
          <Navbar.Brand href="/" className="me-0 d-flex align-items-center">
            {small && (
              <Image
                src={interiokeys_full_logo}
                alt="Interiokeys logo"
                style={{ maxWidth: !isLargeScreen ? 110 : 100 }}
              />
            )}
            {!small && (
              <>
                {/* <Image
                  src={interiokeys_logo}
                  alt="Interiokeys logo"
                  style={{ maxWidth: 32 }}
                /> */}
                <Image
                  src={interiokeys_full_logo}
                  alt="Interiokeys logo"
                  style={{ maxWidth: !isLargeScreen ? 110 : 100 }}
                />
                {!["builder", "architect", "brand"].includes(
                  user?.user_type
                ) && (
                    <Nav.Item
                      className="cursor-pointer text-white px-2 px-lg-2 body-text2"
                      onClick={() => {
                        setLocationDropdown(true);
                      }}
                      onMouseLeave={() => {
                        setLocationDropdown(false);
                      }}
                    >
                      <Nav.Link className="body-text1 inter-500 me-3">
                        {currentLocation}
                      </Nav.Link>
                      <Card
                        className={`${locationDropdown
                            ? "position-absolute location-dropdown-container d-flex p-0 shadow-none"
                            : "d-none"
                          }`}
                      >
                        <Card.Body
                          className="p-0"
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: 5,
                          }}
                        >
                          <div className="d-flex flex-column">
                            <div
                              className={`body-text2 text-gray-900 inter-600 p-2 w-100 ${hoveredLocation === "locate-me"
                                  ? "bg-gray-25"
                                  : ""
                                }`}
                              onMouseEnter={() => setHoveredLocation("locate-me")}
                              onMouseLeave={() => setHoveredLocation("")}
                              onClick={() => {
                                setShowLocationModal(true);
                                setLocationDropdown(false);
                              }}
                            >
                              Locate Me
                            </div>
                            <div
                              className={`body-text2 text-gray-900 p-2 w-100 border-top ${hoveredLocation === "All Location"
                                  ? "bg-gray-25"
                                  : ""
                                }`}
                              onMouseEnter={() =>
                                setHoveredLocation("All Location")
                              }
                              onMouseLeave={() => setHoveredLocation("")}
                              onClick={() => {
                                setCurrentLocation("All Location");
                                setLocationDropdown(false);
                              }}
                            >
                              All Location
                            </div>
                            {allLocations
                              ?.filter((item) => item.is_visible)
                              ?.map((item) => {
                                return (
                                  <>
                                    <div
                                      key={item.id}
                                      className={`body-text2 text-gray-900 p-2 w-100 border-top ${hoveredLocation === item.slug
                                          ? "bg-gray-25"
                                          : ""
                                        }`}
                                      onMouseEnter={() =>
                                        setHoveredLocation(item.slug)
                                      }
                                      onMouseLeave={() => setHoveredLocation("")}
                                      onClick={() => {
                                        setCurrentLocation(item?.title);
                                        setLocationDropdown(false);
                                      }}
                                    >
                                      {item?.title}
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </Card.Body>
                      </Card>
                    </Nav.Item>
                  )}
              </>
            )}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="focus-none p-1 border-0"
            onClick={() => setToggled((prev) => !prev)}
          >
            {/* <CgMenu size={medium ? 28 : small ? 24 : 22} color="#fff" /> */}
          </Navbar.Toggle>
          <Navbar.Collapse
            id="navbarScroll"
            className={
              large ? "d-flex justify-content-end" : "bg-extradark pb-4"
            }
          >
            {small &&
              !["builder", "architect", "brand"].includes(user?.user_type) && (
                <Nav.Item
                  className="cursor-pointer text-white px-2 px-lg-2 body-text2"
                  onClick={() => {
                    setLocationDropdown((prev) => !prev);
                  }}
                  onMouseLeave={() => {
                    setLocationDropdown(false);
                  }}
                >
                  <Nav.Link className="body-text2 inter-500 ms-3 me-3 d-flex align-items-center">
                    {currentLocation}{" "}
                    <div>
                      {locationDropdown ? (
                        <FaChevronUp className="ps-2" size={21} />
                      ) : (
                        <FaChevronDown className="ps-2" size={21} />
                      )}
                    </div>
                  </Nav.Link>
                  <Card
                    className={`${locationDropdown
                        ? "position-absolute location-dropdown-container d-flex p-0 shadow-none"
                        : "d-none"
                      }`}
                  >
                    <Card.Body
                      className="p-0"
                      style={{ backgroundColor: "#FFFFFF", borderRadius: 5 }}
                    >
                      <div className="d-flex flex-column">
                        <div
                          className={`body-text2 text-gray-900 inter-600 p-2 w-100 ${hoveredLocation === "locate-me" ? "bg-gray-25" : ""
                            }`}
                          onMouseEnter={() => setHoveredLocation("locate-me")}
                          onMouseLeave={() => setHoveredLocation("")}
                          onClick={() => {
                            setShowLocationModal(true);
                            setLocationDropdown((prev) => !prev);
                          }}
                        >
                          Locate Me
                        </div>
                        <div
                          className={`body-text2 text-gray-900 p-2 w-100 border-top ${hoveredLocation === "All Location"
                              ? "bg-gray-25"
                              : ""
                            }`}
                          onMouseEnter={() =>
                            setHoveredLocation("All Location")
                          }
                          onMouseLeave={() => setHoveredLocation("")}
                          onClick={() => {
                            setCurrentLocation("All Location");
                            setLocationDropdown(false);
                          }}
                        >
                          All Location
                        </div>
                        {allLocations
                          ?.filter((item) => item.is_visible)
                          ?.map((item) => {
                            return (
                              <div
                                key={item.id}
                                className={`body-text2 text-gray-900 p-2 w-100 border-top ${hoveredLocation === item.slug
                                    ? "bg-gray-25"
                                    : ""
                                  }`}
                                onMouseEnter={() =>
                                  setHoveredLocation(item.slug)
                                }
                                onMouseLeave={() => setHoveredLocation("")}
                                onClick={() => {
                                  setCurrentLocation(item?.title);
                                  setLocationDropdown((prev) => !prev);
                                }}
                              >
                                {item?.title}
                              </div>
                            );
                          })}
                      </div>
                    </Card.Body>
                  </Card>
                </Nav.Item>
              )}
            <Nav
              className={`body-text2 ${large
                  ? "d-flex justify-content-end align-items-center geist"
                  : "d-flex flex-column my-2 my-lg-0 geist"
                }`}
              navbarScroll
              style={{ fontWeight: 600, width: "100%" }}
            >
              {(!user || user.user_type === "customer") && (
                <div
                  className={
                    large
                      ? "d-flex justify-content-between align-items-center w-100"
                      : "d-flex flex-column"
                  }
                >
                  <Nav.Item
                    className="d-flex flex-grow"
                    style={{
                      padding: !small ? "6px 9px" : "",
                    }}
                  >
                    <SearchBar
                      searchText={searchText}
                      setSearchText={setSearchText}
                      setToggled={setToggled}
                    />
                  </Nav.Item>

                  <Nav.Item
                    className="cursor-pointer text-white px-2 px-lg-2"
                    onMouseEnter={() => {
                      if (small) {
                        clearTimeout(inspirationHideTimeout);
                        setShowInspirations(true);
                        setShowBrands(false);
                        setShowProfessionals(false);
                      }
                    }}
                    onMouseLeave={() => {
                      if (small) {
                        inspirationHideTimeout = setTimeout(() => {
                          setShowInspirations(false);
                        }, 500);
                      }
                    }}
                    onClick={() => {
                      if (!small) {
                        setShowInspirations((prev) => !prev); // Toggle the menu on click for mobile
                        setShowBrands(false);
                        setShowProfessionals(false);
                      }
                    }}
                  >
                    <Nav.Link
                      className={`${small ? "body-text2" : "body-text1"
                        } inter-500`}
                      style={{
                        padding: !small ? "9px 10px" : "",
                      }}
                    >
                      Inspirations
                    </Nav.Link>
                    <InspirationMegaMenu
                      showInspirations={showInspirations}
                      setShowInspirations={setShowInspirations}
                      toggled={toggled}
                      setToggled={setToggled}
                    />
                  </Nav.Item>
                  <Nav.Item
                    className="cursor-pointer text-white px-2 px-lg-2"
                    onMouseEnter={() => {
                      if (small) {
                        clearTimeout(buildersHideTimeout);
                        setShowBuilders(true);
                        setShowInspirations(false);
                        setShowBrands(false);
                        setShowProfessionals(false);
                      }
                    }}
                    onMouseLeave={() => {
                      if (small) {
                        buildersHideTimeout = setTimeout(() => {
                          setShowBuilders(false);
                        }, 500);
                      }
                    }}
                    onClick={() => {
                      if (!small) {
                        setShowInspirations(false);
                        setShowBrands(false);
                        setShowProfessionals(false);
                        setShowBuilders((prev) => !prev);
                      }
                    }}
                  >
                    <Nav.Link
                      className={`${small ? "body-text2" : "body-text1"
                        } inter-500`}
                      style={{
                        padding: !small ? "9px 10px" : "",
                      }}
                    >
                      Builders
                    </Nav.Link>
                    <BuilderMegaMenu
                      showBuilders={showBuilders}
                      setShowBuilders={setShowBuilders}
                      toggled={toggled}
                      setToggled={setToggled}
                    />
                  </Nav.Item>
                  <Nav.Item
                    className="cursor-pointer text-white px-2 px-lg-2"
                    onMouseEnter={() => {
                      if (small) {
                        clearTimeout(professionalHideTimeout);
                        setShowProfessionals(true);
                        setShowInspirations(false);
                        setShowBrands(false);
                      }
                    }}
                    onMouseLeave={() => {
                      if (small) {
                        professionalHideTimeout = setTimeout(() => {
                          setShowProfessionals(false);
                        }, 500);
                      }
                    }}
                    onClick={() => {
                      if (!small) {
                        setShowInspirations(false); // Toggle the menu on click for mobile
                        setShowBrands(false);
                        setShowProfessionals((prev) => !prev);
                      }
                    }}
                  >
                    <Nav.Link
                      className={`${small ? "body-text2" : "body-text1"
                        } inter-500`}
                      style={{
                        padding: !small ? "9px 10px" : "",
                      }}
                    >
                      Pros
                    </Nav.Link>
                    <ProfessionalMegaMenu
                      showProfessionals={showProfessionals}
                      setShowProfessionals={setShowProfessionals}
                      toggled={toggled}
                      setToggled={setToggled}
                    />
                  </Nav.Item>

                  <Nav.Item
                    className="cursor-pointer text-white px-2 px-lg-2"
                    onMouseEnter={() => {
                      if (small) {
                        clearTimeout(brandHideTimeout);
                        setShowBrands(true);
                        setShowInspirations(false);
                        setShowProfessionals(false);
                      }
                    }}
                    onMouseLeave={() => {
                      if (small) {
                        brandHideTimeout = setTimeout(() => {
                          setShowBrands(false);
                        }, 500);
                      }
                    }}
                    onClick={() => {
                      if (!small) {
                        setShowInspirations(false); // Toggle the menu on click for mobile
                        setShowBrands((prev) => !prev);
                        setShowProfessionals(false);
                      }
                    }}
                  >
                    <Nav.Link
                      className={`${small ? "body-text2" : "body-text1"
                        } inter-500`}
                      style={{
                        padding: !small ? "9px 10px" : "",
                      }}
                    >
                      Brands
                    </Nav.Link>
                    <BrandMegaMenu
                      showBrands={showBrands}
                      setShowBrands={setShowBrands}
                      toggled={toggled}
                      setToggled={setToggled}
                    />
                  </Nav.Item>

                  {!user && (
                    <Nav.Item className="cursor-pointer text-white px-2 px-lg-2">
                      <Nav.Link
                        href="/about-us"
                        className={`${small ? "body-text2" : "body-text1"
                          } inter-500`}
                        style={{
                          padding: !small ? "9px 10px" : "",
                        }}
                      >
                        About Us
                      </Nav.Link>
                    </Nav.Item>
                  )}

                  {/* <NavLink
                    title="Why Choose Us?"
                    route="why-choose-us"
                    className={`mx-lg-0 ms-xxl-2 me-xxl-2 ${small ? "body-text2" : "body-text1"} inter-500`}
                    style={{
                      padding: !small ? '9px 16px' : undefined
                    }}
                  /> */}

                  {!user && (
                    <Nav.Item
                      className="me-lg-2 position-relative"
                      style={{
                        padding: !small ? "9px 7px" : "",
                      }}
                    >
                      {/* Button with Dropdown Toggle */}
                      <Button
                        variant="primary"
                        className="body-text2 inter-500 border-0 transparent-button"
                        style={{
                          borderRadius: 3,
                          padding: small ? "8px 14px" : "",
                        }}
                        onMouseEnter={() => setPartnerDropdown(true)}
                        onMouseLeave={() => setPartnerDropdown(false)}
                      >
                        Become a Partner
                      </Button>

                      {/* Dropdown Menu */}
                      <Card
                        className={`${partnerDropdown
                            ? "position-absolute d-flex p-0 shadow-none"
                            : "d-none"
                          }`}
                        style={{
                          top: 30,
                          left: 20,
                          zIndex: 1050,
                          backgroundColor: "#FFFFFF",
                          borderRadius: 5,
                          width: "120px", // Adjust width as needed
                        }}
                        onMouseEnter={() => setPartnerDropdown(true)}
                        onMouseLeave={() => setPartnerDropdown(false)}
                      >
                        <Card.Body className="p-0">
                          <div className="d-flex flex-column">
                            {options.map((option, index) => (
                              <div
                                key={option.value}
                                className={`body-text2 text-gray-900 inter-400 p-2 w-100 cursor-pointer ${index == 0 ? "" : "border-top"
                                  } ${hoveredOption === option.value
                                    ? "bg-gray-25"
                                    : ""
                                  }`}
                                onMouseEnter={() => {
                                  setHoveredOption(option.value);
                                  setPartnerDropdown(true);
                                }}
                                onMouseLeave={() => {
                                  setHoveredOption("");
                                  setPartnerDropdown(false);
                                }}
                                onClick={() => {
                                  // navigate(`/signup#professional|${option.value}`)
                                  if (option.value === "others") {
                                    navigate(`/signup#professional|others`);
                                  } else {
                                    navigate(`/partner-${option.value}`);
                                  }
                                  setToggled(false);
                                  setPartnerDropdown(false);
                                }}
                              >
                                {option.label}
                              </div>
                            ))}
                          </div>
                        </Card.Body>
                      </Card>
                    </Nav.Item>
                  )}
                  <Nav.Item
                    className="mx-lg-0"
                    style={{
                      padding: !small ? "9px 16px" : "",
                    }}
                  >
                    <Button
                      variant="primary"
                      className="mt-lg-0 px-3 py-2 body-text2 text-white inter-500 border-0 w-100 w-lg-auto"
                      style={{
                        borderRadius: 3,
                      }}
                      onClick={handleClick}
                    >
                      Book Consultation
                    </Button>
                  </Nav.Item>
                </div>
              )}
              {user?.user_type === "builder" && (
                <div
                  className={
                    large
                      ? "d-flex justify-content-between align-items-center w-100"
                      : "d-flex flex-column"
                  }
                >
                  <Nav.Item className="d-flex flex-grow">
                    {/* <SearchBar searchText={searchText} setSearchText={setSearchText} /> */}
                  </Nav.Item>
                  <Nav.Item
                    className="text-gray-900"
                    style={{
                      padding: !small ? "9px 10px" : "",
                    }}
                  >
                    <Nav.Link href="/builder/properties">
                      My Properties
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item
                    style={{
                      padding: !small ? "9px 10px" : "",
                      margin: small ? "0rem 1rem" : ""
                    }}
                  >
                    <Button
                      variant="primary"
                      className="mt-lg-0 px-3 py-2 body-text2 text-white inter-500 border-0"
                      style={{
                        borderRadius: 3,
                      }}
                      onClick={() =>
                        navigate("/builder/addproject", {
                          state: { isSampleFlat: true },
                        })
                      }
                    >
                      Add New Sample Flat
                    </Button>
                  </Nav.Item> */}
                  <Nav.Item
                    style={{
                      padding: !small ? "9px 10px" : "",
                    }}
                  >
                    <Button
                      variant="primary"
                      className="mt-lg-0 px-3 py-2 body-text2 text-white inter-500 border-0"
                      style={{
                        borderRadius: 3,
                      }}
                      onClick={() => navigate("/builder/addproperty")}
                    >
                      Add New Property
                    </Button>
                  </Nav.Item>
                </div>
              )}
              {user?.user_type === "architect" && (
                <div
                  className={
                    large
                      ? "d-flex justify-content-between align-items-center w-100"
                      : "d-flex flex-column"
                  }
                >
                  <Nav.Item className="d-flex flex-grow">
                    {/* <SearchBar searchText={searchText} setSearchText={setSearchText} /> */}
                  </Nav.Item>
                  <Nav.Item className="text-gray-900 ps-2">
                    <Nav.Link href="/architect/projects">
                      Property Listing
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="text-gray-900 px-2">
                    <Nav.Link href="/architect/product-library">
                      Product Library
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item style={{ padding: !small ? "9px 10px" : "" }}>
                    <Button
                      variant="primary"
                      className="mt-lg-0 px-3 py-2 body-text2 text-white inter-500 border-0 bg-brown"
                      style={{
                        borderRadius: 3,
                      }}
                      onClick={() => navigate("/architect/addproject")}
                    >
                      Add Project
                    </Button>
                  </Nav.Item>
                </div>
              )}
              {user?.user_type === "brand" && (
                <div
                  className={
                    large
                      ? "d-flex justify-content-between align-items-center w-100"
                      : "d-flex flex-column"
                  }
                >
                  <Nav.Item className="d-flex flex-grow">
                    {/* <SearchBar searchText={searchText} setSearchText={setSearchText} /> */}
                  </Nav.Item>
                  <Nav.Item className="text-gray-900 ps-2">
                    <Nav.Link href="/brand/projects">Property Listing</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="text-gray-900 px-2">
                    <Nav.Link href="/brand/product-library">
                      Product Library
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="mt-3 mt-lg-0 ms-2 px-3 py-2 body-text2 text-white inter-500 border-0"
                      style={{
                        borderRadius: 3,
                      }}
                      onClick={() => navigate("/brand/addproduct")}
                    >
                      Add Product
                    </Button>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="mt-3 mt-lg-0 ms-2 px-3 py-2 body-text2 text-white inter-500 border-0"
                      style={{
                        borderRadius: 3,
                      }}
                      onClick={() => navigate("/brand/addproject")}
                    >
                      Add Project
                    </Button>
                  </Nav.Item>
                </div>
              )}
              <Nav.Item
                className="d-flex mx-0 mx-lg-0"
                style={{
                  padding: !small ? "0px 9px" : "",
                }}
              >
                {!isAuthenticated ? (
                  <NavDropdown
                    title="Login As"
                    className="d-flex flex-column justify-content-center text-center text-lg-start mx-2 ms-lg-2 me-xxl-0 mt-3 mt-lg-0 px-2 text-gray-900 bg-gray-50 w-100 w-lg-auto"
                    align={{ lg: "end" }}
                    style={{
                      // minWidth: !large && 160,
                      // minHeight: !medium && 32,
                      borderRadius: 3,
                    }}
                  >
                    <NavDropdown.Item
                      onClick={() => {
                        setIsHomeOwner(true);
                        setShowLoginModal(true);
                        setToggled(!toggled);
                      }}
                      className={`body-text2 py-2 text-center text-lg-center ${accHover === "homeowner"
                          ? "text-primary"
                          : "text-gray-900"
                        }`}
                      onMouseEnter={() => setAccHover("homeowner")}
                      onMouseLeave={() => setAccHover(null)}
                      style={{
                        borderRadius: 3,
                      }}
                    >
                      Homeowner
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        setIsHomeOwner(false);
                        setShowLoginModal(true);
                        setToggled(!toggled);
                      }}
                      className={`border-top body-text2 py-2 text-center  ${accHover === "professional"
                          ? "text-primary"
                          : "text-gray-900"
                        }`}
                      onMouseEnter={() => setAccHover("professional")}
                      onMouseLeave={() => setAccHover(null)}
                      style={{
                        borderRadius: 3,
                      }}
                    >
                      InterioKeys Partner
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <NavDropdown
                    title={user?.full_name}
                    className="d-flex flex-column justify-content-center ms-lg-2 me-xxl-0 mt-3 mt-lg-0 px-2 text-gray-900 bg-gray-50"
                    align={{ lg: "end" }}
                    style={{
                      minWidth: !large && 160,
                      minHeight: !medium && 32,
                      borderRadius: 3,
                    }}
                  >
                    {user.user_type === "customer" && <NavDropdown.Item
                      onClick={() =>
                        navigate(
                          `${user.user_type === "customer"
                          && "/user/profile"
                          }`
                        )
                      }
                      className={`body-text2 py-2 px-xxl-2 d-flex justify-content-start ${accHover === "profile"
                          ? "text-primary"
                          : "text-gray-900"
                        }`}
                      onMouseEnter={() => setAccHover("profile")}
                      onMouseLeave={() => setAccHover(null)}
                    >
                      Edit Profile
                    </NavDropdown.Item>}
                    {user.user_type === "customer" && (
                      <NavDropdown.Item
                        onClick={() => navigate("/user/projecttracker")}
                        className={`body-text2 py-2 px-xxl-2 d-flex justify-content-start ${accHover === "tracker"
                            ? "text-primary"
                            : "text-gray-900"
                          }`}
                        onMouseEnter={() => setAccHover("tracker")}
                        onMouseLeave={() => setAccHover(null)}
                      >
                        Project Tracker
                      </NavDropdown.Item>
                    )}

                    {user.user_type === "customer" && (
                      <NavDropdown.Item
                        onClick={() =>
                          navigate(`/user/favourite`)
                        }
                        className={`body-text2 py-2 px-xxl-2 d-flex justify-content-start ${accHover === "favourite"
                            ? "text-primary"
                            : "text-gray-900"
                          }`}
                        onMouseEnter={() => setAccHover("favourite")}
                        onMouseLeave={() => setAccHover(null)}
                      >
                        Favourite
                      </NavDropdown.Item>
                    )}

                    {(user.user_type === "architect" ||
                      user?.user_type === "brand") && (
                        <NavDropdown.Item
                          onClick={() =>
                            navigate(`/${user.user_type}/account/favourite`)
                          }
                          className={`body-text2 py-2 px-xxl-2 d-flex justify-content-start ${accHover === "favourite"
                              ? "text-primary"
                              : "text-gray-900"
                            }`}
                          onMouseEnter={() => setAccHover("favourite")}
                          onMouseLeave={() => setAccHover(null)}
                        >
                          Favourite
                        </NavDropdown.Item>
                      )}

                    {(user.user_type === "architect" ||
                      user?.user_type === "brand" || user?.user_type === "builder") && (
                        <NavDropdown.Item
                          onClick={() =>
                            navigate(`/${user.user_type}/account/settings`)
                          }
                          className={`body-text2 py-2 px-xxl-2 d-flex justify-content-start ${accHover === "settings"
                              ? "text-primary"
                              : "text-gray-900"
                            }`}
                          onMouseEnter={() => setAccHover("settings")}
                          onMouseLeave={() => setAccHover(null)}
                        >
                          Edit Profile
                        </NavDropdown.Item>
                      )}
                    {/* {(user.user_type === "architect" ||
                      user?.user_type === "brand") && (
                        <NavDropdown.Item
                          onClick={() =>
                            navigate(`/${user.user_type}/product-library`)
                          }
                          className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "properties"
                            ? "text-primary"
                            : "text-gray-900"
                            }`}
                          onMouseEnter={() => setAccHover("properties")}
                          onMouseLeave={() => setAccHover(null)}
                        >
                          Product Library
                        </NavDropdown.Item>
                      )} */}
                    {/* {user.user_type !== "customer" && (
                      <NavDropdown.Item
                        onClick={() =>
                          navigate(
                            user.user_type === "builder"
                              ? "/builder/account/settings"
                              : `/${user.user_type}/account/settings`
                          )
                        }
                        className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "settings"
                          ? "text-primary"
                          : "text-gray-900"
                          }`}
                        onMouseEnter={() => setAccHover("settings")}
                        onMouseLeave={() => setAccHover(null)}
                      >
                        Settings
                      </NavDropdown.Item>
                    )} */}
                    <NavDropdown.Item
                      onClick={handleLogout}
                      className={`border-top body-text2 py-2 px-xxl-2 d-flex justify-content-start ${accHover === "logout" ? "text-primary" : "text-gray-900"
                        }`}
                      onMouseEnter={() => setAccHover("logout")}
                      onMouseLeave={() => setAccHover(null)}
                    >
                      Sign Out
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {!small
        ? toggled && (
          <div className="huge-dropdown-overlay" style={{ top: 0 }} />
        )
        : (showInspirations ||
          showBrands ||
          showProfessionals ||
          showBuilders) && (
          <div className="huge-dropdown-overlay" style={{ top: 0 }} />
        )}
      <LocationModal
        showLocationModal={showLocationModal}
        setShowLocationModal={setShowLocationModal}
        allLocations={allLocations}
        setCurrentLocation={setCurrentLocation}
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </>
  );
};

export default NavBar;
