import RatingStarsView from "components/General/RatingStarsView";
import { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { FaCheck, FaStar } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { notify } from "utilities/notifications/Notify";
import * as authActions from "reduxFiles/actions/authActions";
import axios from "axios";

const Review = ({
  id,
  name,
  rating,
  review,
  created_at,
  type,
  content,
  image,
  by_user_data,
  for_user,
  onReviewUpdate
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editedReview, setEditedReview] = useState(review);
  const [editedRating, setEditedRating] = useState(rating || 5);

  const currentUser = useSelector((state) => state.auth.user);
  const isOwnReview = currentUser?.id === by_user_data?.id;

  const date = new Date(created_at);
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append("review", editedReview);
      formData.append("rating", editedRating);
      formData.append("for_user", for_user);
      formData.append("by_user", currentUser?.id);

      const response = await axios.put(`/marketing/review-ratings/${id}/`, formData, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data) {
        notify("Review updated successfully!", "success");
        setIsEditModalOpen(false);
        if (onReviewUpdate) onReviewUpdate();
      }
    } catch (error) {
      console.error("Error updating review:", error);
      notify("Failed to update review", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteReview = async () => {
    setIsDeleting(true);

    try {
      await axios.delete(`/marketing/review-ratings/${id}/`);

      notify("Review deleted successfully!", "success");
      setIsDeleteModalOpen(false);
      if (onReviewUpdate) onReviewUpdate();
    } catch (error) {
      console.error("Error deleting review:", error);
      notify("Failed to delete review", "error");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="my-3 p-4" style={{ backgroundColor: "#F8F9FA" }}>
      <div className="d-flex align-items-start mb-2">
        <div
          className="inter-700 me-2 bg-dark text-white d-flex justify-content-center align-items-center"
          style={{
            fontSize: "23px",
            borderRadius: "50%",
            width: '50px',
            height: '50px'
          }}
        >
          {by_user_data?.full_name?.charAt(0)}
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <div className="h5 inter-600 me-0 mb-0">{by_user_data?.full_name}</div>
            <div
              className="d-flex align-items-center text-danger h5 inter-600"
              style={{ gap: "4px" }}
            >
              <FaStar className="text-danger" style={{ fontSize: "0.9rem" }} />
              <span className="text-sm ms-0">{rating ?? 5}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="text-primary inter-600 mb-2">{type}</div>
      <div className="geist mb-2 inter-400 text-muted">{review}</div>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className="inter-400"
          style={{
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "-3%",
            color: "#2E3134",
          }}
        >
          {formattedDate}
        </div>
        {isOwnReview && (
          <div className="d-flex gap-3">
            <span
              className="text-primary cursor-pointer"
              onClick={() => setIsEditModalOpen(true)}
              style={{ cursor: 'pointer', fontSize: '14px' }}
            >
              Edit
            </span>
            <span
              className="text-danger cursor-pointer"
              onClick={() => setIsDeleteModalOpen(true)}
              style={{ cursor: 'pointer', fontSize: '14px' }}
            >
              {isDeleting ? (
                <>
                  <Spinner size="sm" animation="border" className="me-1" style={{ width: '10px', height: '10px' }} />
                  Deleting...
                </>
              ) : (
                "Delete"
              )}
            </span>
          </div>
        )}
      </div>
      {/* Edit Review Modal */}
      <Modal show={isEditModalOpen} onHide={() => setIsEditModalOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="h3 inter-600 text-center w-100">Edit Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center mb-3">
            <div style={{ transform: "scale(0.85)" }}>
              <RatingStarsView rating={editedRating} setRating={setEditedRating} />
            </div>
          </div>
          <Form onSubmit={handleEditSubmit}>
            <Form.Group>
              <Form.Control
                name="review"
                as="textarea"
                rows={5}
                placeholder="Edit your feedback for brand..."
                style={{ resize: "none" }}
                value={editedReview}
                onChange={(e) => setEditedReview(e.target.value)}
              />
            </Form.Group>
            <div className="w-100 my-3 d-flex justify-content-center">
              <button
                className="btn btn-primary text-white"
                type="submit"
                disabled={isSubmitting}
                style={{ fontSize: "0.875rem", padding: "6px 12px" }}
              >
                {isSubmitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-1"
                    />
                    {" "}Updating...
                  </>
                ) : (
                  "Update Review"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Delete Confirmation Modal */}
      <Modal show={isDeleteModalOpen} onHide={() => !isDeleting && setIsDeleteModalOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="h3 inter-600 text-center w-100">Delete Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">Are you sure you want to delete this review? This action cannot be undone.</p>
          <div className="d-flex justify-content-center gap-3 mt-4">
            <Button
              variant="outline-secondary"
              onClick={() => setIsDeleteModalOpen(false)}
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleDeleteReview}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-1"
                  />
                  {" "}Deleting...
                </>
              ) : (
                "Delete"
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Review;
