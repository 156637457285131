import * as authActions from "reduxFiles/actions/authActions";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  InputGroup,
} from "react-bootstrap";
import { notify } from "utilities/notifications/Notify";
import userImg from "../../assets/navbar/user.webp";

function BuilderEnquiryForm({
  brandId,
  show,
  onHide,
  builderId,
  projects,
  builderDetails,
})
 {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [projectSelect, setProjectSelect] = useState("");
  // const [builderDetails, setBuilderDetails] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(form.current);
    formData.append("builder", builderId);
    formData.append("project_select", projectSelect);

    let data = {};
    formData.forEach((value, key) => (data[key] = value));

    try {
      const response = await authActions.addBuilderEnquiry(data);
      if (response.data) {
        notify("Enquiry Sent!", "success");
        setProjectSelect("");
        onHide();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("Builder Details:", builderDetails);
  }, [builderDetails]);

  return (
    <Modal
      show={show}
      className=" sendenquirypopup"
      dialogClassName="shareModal"
      onHide={onHide}
      centered
    >
      {/* Add Modal Header with Close Button */}
      <Modal.Header closeButton>
        <Modal.Title>Send Enquiry</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-3">
        <div className="sendbodytitle">
          <div className="leftpart">
            <Card.Img
              src={builderDetails?.user_data.profile_picture  || userImg}
              alt="Company Logo"
              style={{ objectFit: "contain" }}
            />
          </div>

          <div className="rightpart">
            <h5>
              {" "}
              asdadada
              {builderDetails?.company_name}
            </h5>
            <p>Builders</p>
            {builderDetails?.city && (
              <p>
                {" "}
               
                {builderDetails.city}
                {builderDetails.state ? `, ${builderDetails.state}` : ""}
              </p>
            )}

            {/* Badge Section */}
            <div className="btngroup">
              <span>
                {builderDetails?.project_count ?? 0} Properties Created
              </span>
              {builderDetails?.expertise_type && (
                <span>{builderDetails.expertise_type}</span>
              )}
              <span>
                {builderDetails?.years_of_experience ?? 0} Years Experience
              </span>
            </div>
          </div>
        </div>

        <Form ref={form} onSubmit={handleSubmit}>
          <Form.Group controlId="formName" className="mb-3">
            <Form.Label>
              Name<sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Full Name Here"
              name="name"
              required
            />
          </Form.Group>

          <Form.Group controlId="formEmail" className="mb-3">
            <Form.Label>
              Email ID<sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email ID"
              name="email"
              required
            />
          </Form.Group>

          <Form.Group controlId="formPhone" className="mb-3">
            <Form.Label>
              Phone Number<sup className="text-danger">*</sup>
            </Form.Label>
            <InputGroup className="">
              <InputGroup.Text>+91</InputGroup.Text>
              <Form.Control
                type="tel"
                placeholder="Enter phone number"
                name="phone"
                pattern="[0-9]{10}"
                maxLength="10"
                required
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                }}
              />
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="formProjectSelect" className="mb-3">
            <Form.Label>
              Interested Property
            </Form.Label>
            <Form.Select
              value={projectSelect}
              onChange={(e) => setProjectSelect(e.target.value)}
            >
              <option value="">-Select-</option>
              {projects &&
                projects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.project_name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>

          <Form.Group controlId="formMessage" className="mb-3">
            <Form.Label>
              Message<sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Please specify any requirements or questions for the vendor."
              name="message"
              required
              style={{ resize: "none" }}
            />
          </Form.Group>

          {/* <Form.Group
            controlId="formCheckbox"
            className="mb-3 d-flex align-items-center"
          >
            <Form.Check type="checkbox" className="me-2" />
            <Form.Label className="mb-0">
              I want to receive brochures, information and updates on my Email
              ID
            </Form.Label>
          </Form.Group> */}

          <Button
            variant="danger"
            type="submit"
            className="w-100"
            disabled={loading}
          >
            {loading ? "Sending..." : "Send Enquiry"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default BuilderEnquiryForm;
