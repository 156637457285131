// App.jsx
import React, { useEffect, useState } from "react";
import "./App.css";
import "./App.scss";
import { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { Provider, useSelector, useDispatch } from "react-redux";
import store, { persistor } from "./reduxFiles/store";
import Routers from "./Routers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConf } from "reduxFiles/actions/authActions";
import { GoogleOAuthProvider } from "@react-oauth/google";
import useInactivityTimer from "./hooks/useInactivityTimer";
import { logout } from "reduxFiles/reducer/authSlices"; // Import logout action

const AppContent = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [accessGranted, setAccessGranted] = useState(false);

  // / 🔐 Password Prompt (runs once)
  useEffect(() => {
    if(process.env.REACT_APP_SITE_PASS=="True"){
      const password = prompt("Enter site password:");
      if (password === "Balram@123") {
        setAccessGranted(true);
      } else {
        alert("Incorrect password!");
        window.location.href = "https://google.com"; // Or redirect to your desired page
      }
    }
  }, []);

  // // Logout function that dispatches the Redux logout action
  const handleLogout = () => {
    dispatch(logout()); // Dispatch Redux logout action
    localStorage.removeItem("authToken");
    localStorage.removeItem("persist:root"); // Clears persisted Redux state
    sessionStorage.clear(); // Clear any session storage (if used)
    persistor.purge(); // Ensure Redux Persist data is cleared
    window.location.href = "/"; // Redirect to login or home page
    console.log("User logged out due to inactivity.");
  };
  
  useInactivityTimer(handleLogout, 30 * 60 * 1000, isAuthenticated);
  // // useInactivityTimer(handleLogout, 20 * 1000, isAuthenticated);

  if (!accessGranted) return null;

  return (
    <>
      <ToastContainer {...toastConf} />
      <Routers />
    </>
  );
};

const App = () => {
  setDefaultBreakpoints([
    { xsmall: 0 },
    { small: 576 },
    { medium: 768 },
    { large: 992 },
    { xlarge: 1200 },
    { xxlarge: 1400 },
  ]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <BreakpointProvider>
        <Provider store={store}>
          <AppContent />
        </Provider>
      </BreakpointProvider>
    </GoogleOAuthProvider>
  );
};

export default App;