import { Col, Container, Image, ListGroup, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdOutlineArrowForward,
  MdArrowForward,
  MdArrowForwardIos,
} from "react-icons/md";
import Slider from "react-slick";

import Banners from "./Banners";
import ServiceCard from "./ServiceCard";
import ProjectCard from "components/Cards/ProjectCard";
import TestimonialCard from "components/Cards/TestimonialCard";
import VerticalSlider from "./VerticalSlider";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { ArchitectsForDisplay, HowItWorks } from "components";
import { Breakpoint } from "react-socks";
import {
  archilabs,
  archilabs_mobile,
  ayushi_j,
  brand_mobile_model,
  builder_mobile_card_img,
  career_choice,
  client_leads,
  designer_mobile_model,
  dharmesh_shah,
  for_professionals_1,
  for_professionals_2,
  joi_property,
  managalam_tarang,
  meera_hoya,
  model_mobile_bg,
  partner_img_1,
  partner_img_2,
  partner_img_3,
  partner_img_4,
  partner_img_5,
  partner_img_bg,
  partner_profile1,
  piyush_rai,
  priya_joshi,
  priyanka_rai,
  product_dev,
  recreating_seating,
  referral_code,
  relationship_logo,
  show_your_work1,
  show_your_work2,
  show_your_work3,
  shree_residence,
  somani_house,
  suncity_avenue,
  shree_residence2,
  testimonial_pic,
  wait_is_over,
  walk_through,
  walk_through1,
  web_visibility,
  grand_property,
  list_property,
  list_property2,
  walk_through2,
} from "assets";
import CustomAccordion from "components/CustomAccordion/CustomAccordion";
import LoginModal from "components/Auth/LoginModal";
import CustomSliderForBuilder from "components/Partner/partner-builder-mobile-slider";
import StepsCarouselBrand from "components/Partner/brand-steps-slider";
import StepsCarousel from "components/Partner/builder-steps-slider";
import StepsCarouselForArch from "components/Partner/arch-steps-slider";

const ProfessionalArchitect = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const navigate = useNavigate();

  const [brands, setBrands] = useState(null);
  const [recommendedProjects, setRecommendedProjects] = useState([]);
  const [recommendedArchitects, setRecommendedArchitects] = useState([]);
  const [clientTestimonials, setClientTestimonials] = useState(null);
  const [architectTestimonials, setArchitectTestimonials] = useState(null);
  const [builderTestimonials, setBuilderTestimonials] = useState(null);
  const [brandTestimonials, setBrandTestimonials] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeItemId, setActiveItemId] = useState("step_1");
  const [hovered, setHovered] = useState(null);
  // const [showLoginModal, setShowLoginModal] = useState(false);
  // const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [activeKey, setActiveKey] = useState("0");

  const faqData = [
    {
      id: "0",
      question:
        "What is InterioKeys, and how does it benefit architects and designers?",
      answer:
        "InterioKeys is a comprehensive platform designed to connect architects, interior designers, and designer firms with high-profile builders, premium clients, and nationwide projects. It benefits architects by:\n\n- Acting as a marketing tool to promote your designs to high-intent clients, including Supernatural Customers (serious buyers).\n- Offering end-to-end project support, including execution, client communication, and marketing.\n- Providing opportunities to showcase your work, gain nationwide visibility, and collaborate with leading builders and brands.",
    },
    {
      id: "1",
      question:
        "How does InterioKeys differ from other platforms for architects and designers?",
      answer:
        "InterioKeys stands out by offering:\n\n- Direct connections to the supernatural customer (confirmed buyers of a particular space).\n- End-to-end support, including media exposure, project management tools, and execution assistance.\n- A focus on executable designs with listed products, ensuring practicality and seamless implementation.\n- Equal opportunities for architects to gain visibility and work on projects without favoritism.",
    },
    {
      id: "2",
      question: "What types of projects can I showcase on InterioKeys?",
      answer:
        "Architects can showcase a wide range of projects, including:\n\n- Residential Apartments, Villas.\n- Hospitality projects, High-rise buildings, etc.",
    },
    {
      id: "3",
      question:
        "How does InterioKeys help designers and architects expand their business beyond their local area?",
      answer:
        "InterioKeys has a nationwide reach, enabling architects to:\n\n- Access projects in multiple cities across India.\n- Gain exposure to new clients and markets through platform recommendations.\n- Showcase their portfolio to builders and clients in diverse regions, creating a boundary-less business opportunity.",
    },
    {
      id: "4",
      question:
        "How does InterioKeys market architects and designers to potential clients?",
      answer:
        "InterioKeys markets its Interioallies (architects, designers, and brands) through:\n\n- Blogs, podcasts, magazines, and testimonial showcases.\n- Project shoots and curated marketing campaigns.\n- Prominent placement of profiles and designs on its app and website.\n\nArchitects can focus on creating their best work while InterioKeys ensures maximum visibility.",
    },
    {
      id: "5",
      question:
        "What is an executable design, and why is it important on InterioKeys?",
      answer:
        "An executable design is a ready-to-implement interior solution that includes:\n\n- Pre-determined layouts, materials, and detailed BOQs (Bills of Quantities).\n- Products sourced exclusively from the InterioKeys product library.\n\nThis ensures practicality, seamless implementation, and a hassle-free customer experience, making it a cornerstone of the platform.",
    },
    {
      id: "6",
      question: "How does InterioKeys assist with project execution?",
      answer:
        "InterioKeys provides nationwide assistance for seamless project execution through:\n\n- A network of vetted turnkey contractors, PMCs (Project Management Consultants), and OEMs.\n- Project management tools for task tracking, milestone setting, and communication.\n- Resources for payment collection, product procurement, and project compliance.",
    },
    {
      id: "7",
      question: "Can designers collaborate with brands on InterioKeys?",
      answer:
        "Yes. Designers can collaborate with brands to:\n\n- Feature their products in executable designs.\n- Suggest new brands or products for collaboration.\n- Co-create innovative solutions tailored to specific projects and spaces.",
    },
    {
      id: "8",
      question:
        "How does InterioKeys handle intellectual property rights for designs shared on the platform?",
      answer:
        "Architects retain full ownership of their designs. Any usage by clients requires prior consent from the architect, ensuring legal compliance and ownership protection. Designs are also protected by watermarks of InterioKeys and secure uploads.",
    },
    {
      id: "9",
      question: "How does InterioKeys help architects set competitive pricing?",
      answer:
        "InterioKeys provides market insights and benchmarking tools to help architects:\n\n- Price their services competitively based on regional demand and project type.\n- Align pricing with client budgets while ensuring profitability.",
    },
    {
      id: "10",
      question:
        "How does InterioKeys ensure fair competition among architects?",
      answer:
        "InterioKeys ensures fairness by:\n\n- Offering equal visibility opportunities for all architects.\n- Matching projects to architects based on expertise, relevance, and client needs.\n- Avoiding favoritism and promoting healthy competition on the platform.",
    },
    {
      id: "11",
      question:
        "What kind of analytics does InterioKeys provide about my portfolio’s performance?",
      answer:
        "InterioKeys offers detailed analytics, including:\n\n- Profile views and project engagement rates.\n- Client interest and conversion metrics.\n- Insights into trends and areas for improvement to optimize portfolio performance.",
    },
    {
      id: "12",
      question:
        "Can InterioKeys help me manage legal aspects of working in different cities?",
      answer:
        "Yes. InterioKeys provides guidance on region-specific legal requirements and building codes.",
    },
    {
      id: "13",
      question: "How does InterioKeys protect my designs from being copied?",
      answer:
        "InterioKeys implements several protective measures, such as:\n\n- Watermarking designs.\n- Restricting access to unauthorized clients.",
    },
    // {
    //   id: "14",
    //   question:
    //     "Can architects and designers access short-term or freelance opportunities on InterioKeys?",
    //   answer:
    //     "Yes. InterioKeys offers both short-term and long-term projects, allowing architects to find freelance opportunities or full project contracts based on their preferences.",
    // },
    {
      id: "14",
      question:
        "Does InterioKeys support sustainable or green design practices?",
      answer:
        "Yes. InterioKeys actively promotes sustainable design by:\n\n- Providing a library of eco-friendly materials.\n- Offering certification resources for green practices.\n- Highlighting sustainable projects in marketing campaigns.",
    },
    {
      id: "15",
      question:
        "What kind of marketing support does InterioKeys provide to promote my services?",
      answer:
        "InterioKeys offers robust marketing support, including:\n\n- PR campaigns, social media promotions, and feature placements.\n- Blogs, podcasts, and magazines showcasing your work.\n- Project-specific marketing assets, such as photoshoots and testimonials.",
    },
    {
      id: "16",
      question:
        "How does InterioKeys facilitate collaborations with other professionals (e.g., engineers, landscapers)?",
      answer:
        "InterioKeys connects architects with a network of specialists, such as engineers and landscapers, enabling them to offer holistic solutions for comprehensive projects.",
    },
  ];

  const stats = [
    {
      value: "403%",
      text: `More inquiries are generated through home images and videos compared to a bare shell.`,
    },
    {
      value: "94%",
      text: `Buyers check online presence before making property buying decisions in 2024.`,
    },
    {
      value: "68.2 Cr",
      text: `Searches happened in “Home Decor”, “interior” on Pinterest in 2024.`,
    },


    {
      value: "74%",
      text: `Of recent buyers get home interior inspirations through the internet.`,
    },
  ];

  const portfolioSteps = [
    {
      id: 1,
      title: "Create your professional account",
      description:
        "Showcase all your work, details and awards with a single click. Let clients find you!",
    },
    {
      id: 2,
      title: "Badges to promote your presence",
      description:
        "To let your efforts be known, we assign you badges when you reach each milestone on the Interiokeys platform.",
    },
    {
      id: 3,
      title: "Badges to promote your presence",
      description:
        "To let your efforts be known, we assign you badges when you reach each milestone on the Interiokeys platform.",
    },
  ];

  const accordionItems = [
    {
      id: "step_1",
      title: "Design pre made Inspirations for trending properties",
      content:
        "Get access to the premium properties to create inspiration for and attract there buyers to work with you.",
      step: "Step 1",
    },
    {
      id: "step_2",
      title: "Showcase Projects",
      content:
        "Upload inspirations for the project with all necessary details along with tagging the brands. Upon approval, your designs will be showcased on public view.",
      step: "Step 2",
    },
    {
      id: "step_3",
      title: "Generate quality leads ",
      content:
        "You will start receiving enquiries from the apartment buyers from those properties you designed for as well as other potential buyers who liked your designs.",
      step: "Step 3",
    },
    // {
    //   id: "step_4",
    //   title: "Share your Work And Much More..",
    //   content:
    //     "Share all the work you do, your awards, your company, everything that makes you special!",
    //   step: "Step 4",
    // },
  ];

  const stepImages = [
    {
      id: "step_1",
      images: [
        {
          url: `${joi_property}`,
          title: "Jewel of India/Suncity Builders",
          layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft) ",
          location: "Location: Vaishali Nagar, Jaipur, India",
        },
        {
          url: `${managalam_tarang}`,
          title: "Mangalam Tarang/Mangalam Group",
          layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft)",
          location: "Location: Vaishali Nagar, Jaipur, India",
        },
        {
          url: `${suncity_avenue}`,
          title: "Mangalam Tarang/Mangalam Group",
          layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft)",
          location: "Location: Vaishali Nagar, Jaipur, India",
        },
      ],
    },
    {
      id: "step_3",
      images: [
        {
          url: `${walk_through2}`,
        },
      ],
    },
    {
      id: "step_2",
      images: [
        {
          url: `${walk_through1}`,
          // title: "Somani House/3BHK/Melodia Apartments",
          // architect: "Architects: DCJ Architects",
          // builders: "Builders: Melodia Group",
        },
        // {
        //   url: `${shree_residence}`,
        //   title: "Shree Residence/2BHK/Jewel of India",
        //   architect: "Architects: ArchiLabs",
        //   builders: "Builders: Suncity Builders",
        // },
        // {
        //   url: `${shree_residence2}`,
        //   title: "Boho House/3.5BHK/Mangalam Radiance",
        //   architect: "Architects: ArchiLabs",
        //   builders: "Builders: Suncity Builders",
        // },
      ],
    },
  ];

  const profilePics = [
    { img: `${partner_profile1}`, name: "Piyush Rai", role: "Co-Founder" },
    { img: `${priya_joshi}`, name: "Priya Joshi", role: "Co-Founder" },
    { img: `${dharmesh_shah}`, name: "Dharmesh Shah", role: "Founder" },
    { img: `${piyush_rai}`, name: "Piyush Rai", role: "Co-Founder" },
    { img: `${meera_hoya}`, name: "Meera hoya", role: "Founder" },
    { img: `${ayushi_j}`, name: "Ayushi J", role: "Founder" },
    { img: `${priyanka_rai}`, name: "Priyanka Rai", role: "Founder" },
  ];

  const cardData = [
    {
      id: 1,
      icon: `${relationship_logo}`,
      title: "Build Relationships",
      description:
        " Share valuable content, engage with your audience and show them you get their needs! No more cold calling or emails.",
    },
    {
      id: 2,
      icon: `${web_visibility}`,
      title: "Increased Visibility",
      description:
        "Once you get listed with Interiokeys, we will make sure your profile reaches to clients and builders from all over India!",
    },
    {
      id: 3,
      icon: `${product_dev}`,
      title: "Product Tagging for Direct Exposure",
      description:
        "Build your presence and gain exposure by tagging brands in your excellent designs. Fruitful collaborations await you!",
    },
    {
      id: 4,
      icon: `${client_leads}`,
      title: "Verified Client Leads",
      description:
        "Stop wasting time on clients that don’t convert. Interiokeys matches clients and designers for a great project experience.",
    },
    {
      id: 5,
      icon: `${referral_code}`,
      title: "Leverage Referrals",
      description:
        "Happy customers are your best advocates. Provide excellent services and the referral system will help you thrive.",
    },
    {
      id: 6,
      icon: `${career_choice}`,
      title: "Exclusive Opportunities",
      description:
        "Design for the top properties before anyone else! Create your best work to collaborate with builders on your design.",
    },
  ];

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const fetchBrands = async () => {
    try {
      const response = await authActions.fetchRecommendedBrands();
      if (response.data) {
        setBrands(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const toggleAccordion = (id) => {
    setActiveKey(activeKey === id ? null : id);
  };
  const fetchRecommendedProjects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setRecommendedProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchRecommendedArchitects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedArchitects();
      if (response.data) {
        setRecommendedArchitects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchTestimonialsWithType = async () => {
    try {
      const response = await authActions.fetchTestimonialsWithType();
      if (response.data) {
        setClientTestimonials(response.data?.client_testimonials);
        setArchitectTestimonials(response.data?.architect_testimonials);
        setBuilderTestimonials(response.data?.builder_testimonials);
        setBrandTestimonials(response.data?.brand_testimonials);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBrands();
    fetchRecommendedProjects();
    fetchRecommendedArchitects();
    fetchTestimonialsWithType();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-next-arrow"}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={35} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-prev-arrow"}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={35} />
      </div>
    );
  }

  const sliderSettings = {
    customPaging: function (i) {
      return <div className="custom-dot mt-3" />;
    },
    dotsClass: "slick-dots",
    dots: small ? false : true,
    arrows: false,
    infinite: true,
    autoplay: large ? false : true,
    speed: 500,
    cssEase: "linear",
    slidesToShow: large ? 3 : small ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleActiveItemId = (id) => {
    setActiveItemId(id);
  };

  const renderCardsForActiveStep = () => {
    const step = stepImages.find((item) => item.id === activeItemId); // Find step by activeItemId

    return step.images.map((image, index) => (
      <div
        className="step3tabboxitem col-4"
        key={index}
        style={{ textAlign: "left" }}
      >
        <img
          src={image.url}
          alt={image.title}
          style={{
            borderRadius: "6px",
          }}
        />
        <div style={{ padding: "6px", backgroundColor: "white" }}>
          <div className="body-text1 inter-600 text-gray-800 wrap">
            {image.title}
          </div>
          <div className="body-text2 inter-400 text-gray-800">
            {image.layout}
          </div>
          <div className="body-text2 inter-400 text-gray-800">
            {image.location}
          </div>
        </div>
      </div>
    ));
  };

  const renderCardsForStep2 = () => {
    const step = stepImages.find((item) => item.id === activeItemId); // Find step by activeItemId

    return step.images.map((image, index) => (
      <div
        key={index}
        className="d-flex flex-column align-items-start"
        style={{
          width: "100%",
          height: "477.71px",
          gap: "21.3px",
          borderRadius: "6.21px",
        }}
      >
        <img
          src={image.url}
          alt={image.title}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            borderRadius: "6.21px",
          }}
        />
      </div>
    ));
  };

  const renderCardsForStep3 = () => {
    const step = stepImages.find((item) => item.id === activeItemId); // Find step by activeItemId

    return step.images.map((image, index) => (
      <div
        key={index}
        style={{
          minWidth: "279px",
          textAlign: "left",
          margin: "16px",
        }}
      >
        <img
          src={image.url}
          alt={image.title}
          style={{
            width: "279px",
            height: "384px",
            objectFit: "cover",
            borderRadius: "6px",
          }}
        />
        <div
          style={{ padding: "6px", backgroundColor: "white", width: "279px" }}
        >
          <div
            className="body-text1 inter-600 text-gray-800 wrap"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1, // Truncate to one line
              WebkitBoxOrient: "vertical",
            }}
          >
            {image.title}
          </div>
          <div className="body-text2 inter-400 text-gray-800">
            {image.architect}
          </div>
          <div className="body-text2 inter-400 text-gray-800">
            {image.builders}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <Container fluid className="px-0 arctectpage">
      {small ? (
        <Container
          fluid
          className="d-flex flex-column align-items-center text-center px-0"
          style={{ minHeight: "100vh" }}
        >
          <Container fluid style={{ background: "#F8F9FA" }}>
            <div className="pageTitle maintitleBox text-center">
              <h1 className={`  ${screenHeight <= 800 ? "mt-5" : ""}`}>
                <b className="font-inter font-semibold text-[48px] leading-[67.2px]">
                  Design & Showcase your interior imaginations on cities elite
                  properties
                </b>
              </h1>
              <div className="inter-400 mt-3 font-inter font-normal text-[24px] leading-[31.2px]">
                InterioKeys will attract thousands of potential clients who have
                recently bought a home there and are actively seeking premium
                interior solutions.
              </div>
            </div>
          </Container>
          {/* <div className="d-flex justify-content-center bg-brand-500-half w-100 mx-0">
        <div className="mx-2">
          <Image src={for_professionals_1} alt="For Professioanls image 1" className="w-100" />
        </div>
        <div className="mx-2">
          <Image src={for_professionals_2} alt="For Professioanls image 1" className="w-100" />
        </div>
      </div> */}
          <Container fluid className="py-5 px-0 architectbanner">
            {/* <Container>
            <div className="h2 inter-600">Partner with Us!</div>
            <div className="body-title inter-400 text-gray-600">
              "Partner with InterioKeys to showcase your designs, connect with
              top brands, and inspire{" "}
              <span style={{ display: "block" }}>
                homeowners. Expand your reach and collaborate on unique
                projects."
              </span>
            </div>
          </Container> */}
            <Container
              className="pt-5 px-0"
              style={{ width: "1350px" }}
            >
              <Row>
                {/* Left Column (3 units) */}
                <Col xs={4} className="d-flex flex-column">
                  <Row className="mb-2 ">
                    <Col>
                      <div className="text-start partner-card-border architectimg">
                        <img
                          src={partner_img_1}
                          alt="Shree Residence/5BHK/Jewel of India"
                          className="img-fluid rounded-top"
                          style={{}}
                        />
                        <p className="ps-2 py-2 body-text1 inter-600 text-gray-800 m-0">
                          Shree Residence/5BHK/Jewel of India
                        </p>
                      </div>
                      F
                    </Col>
                  </Row>
                  <Row className="justify-content-center ">
                    <Col>
                      <div className="text-start partner-card-border architectimg">
                        <img
                          src={partner_img_2}
                          alt="Room 1"
                          className="img-fluid rounded-top"
                          style={{
                         
                          }}
                        />
                        <p className="ps-3 py-2 body-text1 inter-600 text-gray-800 m-0">
                          Joshi Home/5BHK/Jewel of India
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>

                {/* Middle Column (6 units) */}
                <Col
                  xs={4}
                  className="h-100 d-flex flex-column justify-content-end align-items-center"
                >
                  <div className="architectmainimg"
                    style={{
                      backgroundImage: `url(${partner_img_bg})`,
                      backgroundSize: "contain",
                      backgroundPosition: "bottom",
                      backgroundRepeat: "no-repeat",
                    
                    }}
                  >
                    <img
                      src={partner_img_3}
                      alt="Partner Image"
                      className="img-fluid"
                      style={{maxWidth:'100%'}}
                     
                    />
                  </div>
                </Col>

                {/* Right Column (3 units) */}
                <Col xs={4} className="d-flex flex-column">
                  <Row className="mb-2">
                    <Col>
                      <div className="text-start border rounded architectimg">
                        <img
                          src={partner_img_4}
                          alt="Room 3"
                          className="img-fluid rounded-top"                         
                        />
                        <p className="ps-2 py-2 body-text1 inter-600 text-gray-800 m-0">
                          Natural Home/3BHK/Melodia Apartments
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="text-start partner-card-border architectimg">
                        <img
                          src={partner_img_5}
                          alt="Room 4"
                          className="img-fluid rounded-top"                          
                        />
                        <p className="ps-2 py-2 body-text1 inter-600 text-gray-800 m-0"> 
                          Boho House/4BHK/Mangalam Radiacne
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <div className="col-12">
                  <Button
                    className="brand-primary-500 text-white body-text-1 mt-4"
                    style={{
                      height: "42px",
                      padding: "8px 14px",
                      gap: "9px",
                      borderRadius: "3px",
                    }}
                    onClick={() => {
                      navigate("/signup#professional|architect");
                    }}
                  >
                    Become an InterioKeys Professional Partner{" "}
                    <MdArrowForwardIos />
                  </Button>
                </div>
              </Row>
            </Container>
          </Container>

          <Container
            fluid
            className="border-t border-gray-300 py-5 px-0"
            style={{
              maxWidth: "100%", // Ensure it spans the full width
              height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "40px",
              padding: "40px 0", // Remove horizontal padding
            }}
          >
            <div className="serchessection px-5">
              {stats.map((stat, index) => (
                <div key={index} className="serchesbox">
                  <span
                    style={{
                      color: "#C53D3D",
                      fontFamily: "Inter",
                      fontWeight: 600,
                      lineHeight: "1",
                      letterSpacing: "0%",
                    }}
                  >
                    {stat.value}
                  </span>
                  <p
                    style={{
                      color: "#27201B",
                      fontFamily: "Inter",
                      fontWeight: 300,
                      lineHeight: "1.4",
                      letterSpacing: "0%",
                    }}
                  >
                    {stat.text}
                  </p>
                </div>
              ))}
            </div>
          </Container>

          <Container fluid className="p-5 bg-gray-25">
            <Row>
              {/* Left Section */}
              <Col xl={4} className="d-flex flex-column text-start">
                <div className="mb-4 h2 inter-600">How it Works</div>
                <CustomAccordion
                  accordionItems={accordionItems}
                  onToggle={handleActiveItemId}
                />
                <Button
                  className="px-3 text-start btn-primary mt-3 d-flex justify-content-between align-items-center"
                  onClick={() => {
                    navigate("/signup#professional|architect");
                  }}
                >
                  Become an InteriorKeys Professional Partner <MdArrowForwardIos />{" "}
                </Button>
              </Col>

              <Col
                xl={8}
                className="d-flex align-items-center justify-content-around mt-3 mt-xl-0 "
              >
                {activeItemId === "step_1" && (
                  <div
                    className="py-3 px-lg-4 px-2 step3tabbox row"
                    style={{ backgroundColor: "#FFFFFF" }}
                  >
                    {renderCardsForActiveStep()}
                  </div>
                )}

                {activeItemId === "step_3" && (
                  <div
                    className=" py-3 px-lg-4 px-2 d-flex align-items-center justify-content-between w-100"
                    style={{ backgroundColor: "#FFFFFF" }}
                  >
                    {renderCardsForStep2()}
                  </div>
                )}

                {/* {activeItemId === "step_3" && (
                <div className="d-flex align-items-center justify-content-between">
                  {renderCardsForStep3()}
                </div>
              )} */}

                {activeItemId === "step_2" && (
                  <div className="w-100 " >
                    <div
                      className="mb-4 p-3 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        paddingTop: "25.74px",
                        paddingBottom: "25.74px",
                        gap: "21.3px",
                        borderRadius: "6.21px",
                      }}
                    >
                      <Row className="align-items-center justify-content-center w-100 h-100">
                        <Col xs={12}  className="h-100">
                          <Image
                            src={walk_through1}
                            alt="Main Content Image"
                            fluid
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              borderRadius: "6.21px",
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>

          <Container fluid className="p-5 my-5">
            {/* <div className="h2 inter-600 text-gray-900 text-start mb-5">
            Interiokeys Helps You!
          </div> */}
            <Row>
              {cardData.map((card) => (
                <Col key={card.id} xs={12} md={6} lg={4} className="mb-4 " >
                  <div
                    className="p-3 text-start"
                    style={{ border: "1px solid #E8EAED", borderRadius: "6px" }}
                  >
                    <img
                      src={card.icon}
                      alt={card.title}
                      style={{ width: "48px" }}
                    />
                    <h5
                      className="body-title inter-600 mt-3"
                      style={{
                        color: hovered === card.id ? "#C53D3D" : "#1A1C1F",
                      }}
                      onMouseEnter={() => setHovered(card.id)}
                      onMouseLeave={() => setHovered(null)}
                    >
                      {card.title}
                    </h5>
                    <p className="body-text1 inter-400 text-gray-600">
                      {card.description}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>

          <Container fluid className="p-5 my-5">
            <div className="h2 inter-600 text-gray-900 text-center mb-5">
              Why Should You Choose Us?
            </div>

            <Row
              className=""
              style={{ border: "1px solid #D1D1D1", borderRadius: "6px" }}
            >
              {/* Image Section */}
              <Col xs={12} md={3} className="mb-4 mb-md-0 px-0">
                <img
                  src={testimonial_pic}
                  alt="Mandeep and Nisha"
                  className="img-fluid"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "280px",
                    objectFit: "cover",
                  }}
                />
              </Col>
              {/* Text Section */}
              <Col
                xs={12}
                md={9}
                className="text-start d-flex flex-column justify-content-between"
              >
                <div className="mt-4 ms-2">
                  <div className="h3 inter-600 text-gray-900">
                    "InterioKeys – Elevate Your Designs, Expand Your Reach."{" "}
                  </div>
                  <div className="body-title inter-400 text-gray-600">
                    "InterioKeys has been a game-changer for our design
                    practice. The platform connects us with premium brands and
                    property developers, giving our work the visibility it
                    deserves. Showcasing our inspirations has led to more client
                    inquiries and exciting collaborations. It’s an essential
                    tool for any designer looking to grow."{" "}
                  </div>
                </div>
                <div className=" mt-3 mt-lg-4 mb-4 ms-2">
                  <div
                    className="inter-600 text-gray-900"
                    style={{ fontSize: "20px" }}
                  >
                    Mandeep and Nisha
                  </div>
                  <div
                    className="inter-400 text-gray-600"
                    style={{ fontSize: "16px" }}
                  >
                    InterioKeys Turnkey Client
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <div className="h2 inter-600 text-gray-900 text-start mb-2">
            Frequently Asked Questions
          </div>

          {/* {/* <Container fluid className="p-5 my-5"> */}
          <div
            className="container mt-4 mb-5 qandasection"
            style={{
              width: "90%",
              maxWidth: "1200px",
              minHeight: "auto",
              padding: "3% 5%",
              borderRadius: "12px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#EBEBEB",
            }}
          >
            <Accordion activeKey={activeKey}>
              {faqData.map((faq) => (
                <Card
                  key={faq.id}
                  className="mb-2"
                  style={{
                    backgroundColor:
                      activeKey === faq.id ? "#EBEBEB" : "transparent",
                  }}
                >
                  <span className="qnumber"> Q{parseInt(faq.id) + 1}.</span>

                  <Card.Header
                    className="pt-0"
                    onClick={() => toggleAccordion(faq.id)}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      textAlign: "left",
                      backgroundColor:
                        activeKey === faq.id ? "#EBEBEB" : "transparent",
                      cursor: "pointer",
                      padding: "10px 15px",
                    }}
                  >
                    <div style={{ fontWeight: "bold", flex: 1 }}>
                      {faq.question}
                    </div>
                    <Button
                      variant="light"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleAccordion(faq.id);
                      }}
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                    >
                      {activeKey === faq.id ? "−" : "+"}
                    </Button>
                  </Card.Header>
                  <Accordion.Collapse eventKey={faq.id}>
                    <Card.Body
                      className="text-muted pt-0"
                      style={{
                        backgroundColor:
                          activeKey === faq.id ? "#EBEBEB" : "transparent",
                      }}
                    >
                      {faq.answer.split("\n").map((line, index) =>
                        line.startsWith("- ") ? (
                          <ul key={index} style={{ marginLeft: "20px" }}>
                            <li>{line.substring(2)}</li>
                          </ul>
                        ) : (
                          <p key={index} style={{ marginBottom: "8px" }}>
                            {line}
                          </p>
                        )
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </div>

          {/* </Container> */}
        </Container>
      ) : (
        <Container
          fluid
          className="d-flex flex-column align-items-center text-center"
          style={{ minHeight: "100vh", padding: "45px 0px 0px", width: "100%" }}
        >
          <div
            style={{ marginTop: "20px" }}
            className="d-flex flex-column align-items-center mb-3"
          >
            <span className="h2 text-gray-900 inter-600">
              <div className="pageTitle text-center">
                <h1 className={` ${screenHeight <= 800 ? "mt-2" : ""}`}>
                  <b className="font-inter font-semibold text-[48px] leading-[67.2px]">
                    Design & Showcase your interior imaginations on cities elite
                    properties
                  </b>
                </h1>
                <div className="text-gray-600 body-title inter-400 my-3 font-inter font-normal text-[24px] leading-[31.2px]">
                  InterioKeys will attract thousands of potential clients who
                  have recently bought a home there and are actively seeking
                  premium interior solutions.
                </div>
              </div>
            </span>

            {/* <Button
              className="brand-primary-500 text-white body-text-1 mt-3"
              style={{
                borderRadius: "3px",
                width: "40%",
                height: "32px",
                padding: "6px 12px",
              }}
              onClick={() => {
                navigate("/signup#professional|architect");
              }}
            >
Become an Interiokeys Builder Partner            
</Button> */}
          </div>

          <div
            className="w-full h-full min-h-screen"
            style={{
              backgroundImage: `url(${model_mobile_bg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* Main content container */}
            <div className="flex flex-col items-center">
              {/* Model image */}
              <div className="w-full">
                <img
                  src={designer_mobile_model}
                  alt="Partner representative"
                  className="w-full h-auto object-contain"
                />
              </div>

              {/* Text content */}
              {/* <div className="text-center mt-4">
                <p className="body-title inter-500 text-gray-900 mb-2">
                  Partner with Us!
                </p>
                <p className="body-text1 text-gray-600 inter-400">
                  Partner with Interiokeys to showcase designs,
                  <br /> connect with top brands, and get clients while <br />{" "}
                  expanding your reach with builders.
                </p>
              </div> */}
            </div>
          </div>
          <Container className="mb-4">
            <CustomSliderForBuilder />
            <Card
              style={{
                borderRadius: "6px",
                border: "1px solid #E8EAED",
                padding: "0px",
              }}
            >
              <Card.Img
                variant="top"
                src={builder_mobile_card_img}
                className="rounded-top"
                style={{
                  height: "200px",
                  objectFit: "cover",
                }}
              />
              <Card.Body className="p-2">
                <Card.Title className="d-flex align-items-center mb-0">
                  <span
                    className="inter-500 text-gray-800"
                    style={{ fontSize: "14px" }}
                  >
                    3BHK, Jewel of India
                  </span>
                </Card.Title>
              </Card.Body>
            </Card>
          </Container>
          <Button
            className="brand-primary-500 text-white body-text-1 mt-2 mb-4"
            style={{
              height: "35px",
              padding: "8px 14px",
              gap: "9px",
              borderRadius: "3px",
            }}
            onClick={() => {
              navigate("/signup#professional|architect");
            }}
          >
            Become an InterioKeys Professional Partner <MdArrowForwardIos />
          </Button>

          <Container
            fluid
            className="border-t border-gray-300 py-5 px-3 px-md-5 px-lg-20"
            style={{
              maxWidth: "1600px",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "40px",
              padding: "40px 20px",
            }}
          >
            <div
              className="d-flex flex-wrap justify-content-center"
              style={{ gap: "40px" }}
            >
              {stats.map((stat, index) => (
                <div
                  key={index}
                  className="d-flex flex-column align-items-center text-center"
                  style={{ minWidth: "150px", maxWidth: "250px" }}
                >
                  <span
                    style={{
                      color: "#C53D3D",
                      fontFamily: "Inter",
                      fontWeight: 600,
                      fontSize: "clamp(32px, 5vw, 52px)",
                      lineHeight: "1",
                      letterSpacing: "0%",
                    }}
                  >
                    {stat.value}
                  </span>
                  <p
                    style={{
                      color: "#27201B",
                      fontFamily: "Inter",
                      fontWeight: 400,
                      fontSize: "clamp(16px, 2.5vw, 21px)",
                      lineHeight: "1.4",
                      letterSpacing: "0%",
                      marginTop: "8px",
                    }}
                  >
                    {stat.text}
                  </p>
                </div>
              ))}
            </div>
          </Container>

          <Container className="mb-4">
            <p className="body-title inter-500 text-gray-800 text-start">
              How it Work
            </p>
            <StepsCarouselForArch />
            <Button
              className="brand-primary-500 text-white body-text-1 mt-3 w-100"
              style={{
                borderRadius: "3px",
                height: "32px",
                padding: "6px 12px",
              }}
              onClick={() => {
                navigate("/signup#professional|architect");
              }}
            >
              Partner with Us
            </Button>
          </Container>
          <Container className="mb-4">
            {/* <p className="body-title inter-500 text-gray-800 text-start">
              Interiokeys Helps You!
            </p> */}
            {cardData.map((item) => (
              <div
                className="body-text1 inter-500 text-gray-800 "
                style={{
                  padding: "6px",
                  borderRadius: "3px",
                  border: "1px solid #e8eaed",
                  marginBottom: "6px",
                }}
              >
                {item.title}
              </div>
            ))}
          </Container>
          <Container className="mb-4">
            <p className="body-title inter-500 text-gray-800 text-start">
              Why Should You Choose Us?
            </p>
            <Card className="shadow-sm" style={{ borderRadius: "3px" }}>
              <Card.Body className="p-0 text-start">
                {/* Heading */}
                <p className="body-title text-gray-900 inter-600">
                  Elevate your designs and expand your reach with InterioKeys
                </p>

                {/* Quote */}
                <blockquote className="body-text1 inter-400 text-gray-600">
                  "InterioKeys connects us with top brands and property
                  developers, boosting visibility and client inquiries. A
                  must-have for designers!"
                </blockquote>

                <div
                  className="w-100 mb-2"
                  style={{ borderTop: "1px solid #E8EAED" }}
                ></div>
                {/* Footer */}
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    {/* Company Info */}
                    <div>
                      <p className="body-text1 inter-500 text-gray-900 mb-0">
                        Archilabs
                      </p>
                      <p className="body-text1 inter-400 text-gray-600 mb-0">
                        InterioKeys Design Partner
                      </p>
                    </div>

                    {/* Logo Section */}
                    <div className="d-flex align-items-center">
                      {/* Vertical Separator */}
                      <div
                        className="vr me-3"
                        style={{ height: "30px", borderColor: "#E8EAED" }}
                      ></div>
                      {/* Logo */}
                      <img
                        src={archilabs_mobile}
                        alt="Kedia logo"
                        width="80px"
                        height="30px"
                        className="object-fit-cover"
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Container>

          <div className="h2 inter-600 text-gray-900 text-start mb-2">
            Frequently Asked Questions
          </div>

          {/* {/* <Container fluid className="p-5 my-5"> */}
          <div
            className="container mt-4 mb-5 qandasection"
            style={{
              width: "90%",
              maxWidth: "1200px",
              minHeight: "auto",
              padding: "3% 5%",
              borderRadius: "12px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#EBEBEB",
            }}
          >
            <Accordion activeKey={activeKey}>
              {faqData.map((faq) => (
                <Card
                  key={faq.id}
                  className="mb-2"
                  style={{
                    backgroundColor:
                      activeKey === faq.id ? "#EBEBEB" : "transparent",
                  }}
                >
                  <span className="qnumber"> Q{parseInt(faq.id) + 1}.</span>

                  <Card.Header
                    className="pt-0"
                    onClick={() => toggleAccordion(faq.id)}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor:
                        activeKey === faq.id ? "#EBEBEB" : "transparent",
                      cursor: "pointer",
                      padding: "10px 15px",
                    }}
                  >
                    <div style={{ fontWeight: "bold", flex: 1 }}>
                      {faq.question}
                    </div>
                    <Button
                      variant="light"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleAccordion(faq.id);
                      }}
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                    >
                      {activeKey === faq.id ? "−" : "+"}
                    </Button>
                  </Card.Header>
                  <Accordion.Collapse eventKey={faq.id}>
                    <Card.Body
                      className="text-muted pt-0"
                      style={{
                        backgroundColor:
                          activeKey === faq.id ? "#EBEBEB" : "transparent",
                      }}
                    >
                      {faq.answer.split("\n").map((line, index) =>
                        line.startsWith("- ") ? (
                          <ul key={index} style={{ marginLeft: "20px" }}>
                            <li>{line.substring(2)}</li>
                          </ul>
                        ) : (
                          <p key={index} style={{ marginBottom: "8px" }}>
                            {line}
                          </p>
                        )
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </div>
        </Container>
      )}
      <Container
        fluid
        className="px-0 bg-brand-500 text-center d-flex justify-content-center align-items-center "
        style={{ minHeight: small ? "400px" : "300px" }}
      >
        <div>
          <div className="h1 inter-600 text-white">
            Become An InterioKeys Professional Partner
          </div>
          <div
            className="body-title inter-400 text-white mt-2 mb-3 mb-lg-4 px-3 px-lg-0"
            style={{ maxWidth: "800px" }}
          >
            Get ahead of the curve and join InterioKeys as a partner. Let us
            help you become the top professional of your industry!
          </div>
          <button
            className="text-center mt-2 text-gray-700 inter-500"
            style={{
              width: small ? "auto" : "90%",
              backgraoundColor: "#f1f3f4",
              borderRadius: "3px",
              border: "none",
              padding: "8px 14px",
            }}
            onClick={() => {
              navigate("/signup#professional|architect");
            }}
          >
            {small ? (
              <>
                Become an InteriorKeys Professional Partner <MdArrowForwardIos />{" "}
              </>
            ) : (
              <span className="text-primary-500">Partner with InterioKeys</span>
            )}
          </button>
        </div>
      </Container>
      {/* <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      /> */}
    </Container>
  );
};

export default ProfessionalArchitect;
