import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { HiXMark } from "react-icons/hi2";
import { notify } from "utilities/notifications/Notify";
import InpirationsSlider from "components/CustomSlider/InpirationsSlider";
import ProjectBrandCard from "components/Cards/ProjectBrandCard";

const InspirationSliderView = ({
  ativeRoomIndex = 0,
  setActiveRoomIndex,
  show,
  setShow,
  roomData,
  specificRooms,
  projectDetails,
  roomImages, // List of all 30 images
  initialImageIndex, // Index of the clicked image
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const user = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(initialImageIndex || 0);

  useEffect(() => {
    setCurrentImageIndex(initialImageIndex || 0);
  }, [initialImageIndex]);

  // Create a flat list of pseudo room objects for each image
  const imageItems = roomImages.map((image) => {
    const room = specificRooms?.find((r) => r.id === image.ParentId);
    return {
      id: room?.id || "", // Room ID for fetching the image and other actions
      room_type_data: {
        title: room?.room_type_data?.title || "Unknown Room Type",
      },
      project_data: {
        bhk: projectDetails?.bhk || "",
        property_name: projectDetails?.property_name || "",
        slug: projectDetails?.slug || "",
      },
      image: image.file, // Pass the image URL directly to avoid fetching
    };
  });

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className="spaces-slider-custom-modal p-0 overflow-hidden"
      dialogClassName="spaces-slider-modal"
      fullscreen={true}
      style={{ borderRadius: 16, height: "100vh", margin: "8px" }}
    >
      <Modal.Body className="p-0 overflow-hidden" style={{ borderRadius: 16 }}>
        <Row className="flex-wrap p-0 overflowY-scroll">
          <Col lg={8} className="mb-0 pe-0">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center h-100">
                <Spinner />
              </div>
            ) : (
              <div className="spaces-slider-modal-content h-100 position-relative">
                <InpirationsSlider
                  items={imageItems} // Pass the flat list of pseudo room objects
                  setShow={setShow}
                  inspirationslider={true}
                  currentItemIndex={currentImageIndex}
                  setCurrentIndex={(index) => {
                    setCurrentImageIndex(index);
                    setActiveRoomIndex(index); // Repurpose to track image index
                  }}
                />
              </div>
            )}
          </Col>
          <Col lg={4} className="ps-0 py-3" style={{ maxHeight: "100vh" }}>
            <div className="px-4">
              {large && (
                <div className="text-end">
                  <HiXMark
                    size={24}
                    className="cursor-pointer"
                    onClick={() => setShow(false)}
                  />
                </div>
              )}

              <div
                className="d-flex flex-column overflow-hidden border"
                style={{ borderRadius: 12, maxHeight: "90vh" }}
              >
                <div className="inter-600 p-3 pb-2" style={{ flex: "0 0 auto" }}>
                  Brands Used in Image
                </div>
                <div className="overflow-scroll">
                  {projectDetails?.brands_ids?.length > 0 ? (
                    <div>
                      {projectDetails?.brands_ids?.map((brand, index) => (
                        <div key={index}>
                          <ProjectBrandCard brand={brand?.brand_data} />
                          {index !== projectDetails.brands_ids.length - 1 && (
                            <hr className="brand-divider" />
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="px-3">
                      <div
                        className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                        style={{ height: 160 }}
                      >
                        No brands available
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default InspirationSliderView;