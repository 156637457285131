import { useRef } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import HeroBannerCard from "./HeroBannerCard";

// items should contain image and title
const HeroBanner = ({ items, card_info }) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  let sliderRef = useRef(null);
  var settings = {
    customPaging: function (i) {
      return <div className="custom-dot mt-3" />;
    },
    dotsClass: "slick-dots",
    dots: small ? false : true,
    arrows: small ? false : true,
    infinite: large ? false : true,
    autoplay: large ? false : true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: large ? 3 : 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
  };

  return (
    <Container>
      <Row className="mb-4">
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {items.map((item, index) => (
            <Col key={index} className="px-2">
              <HeroBannerCard item={item} card_info={card_info} index={index} />
            </Col>
          ))}
        </Slider>
      </Row>
    </Container>
  );
};

export default HeroBanner;
