import React from "react";
import { Row, Col, Card } from "react-bootstrap";

const ShimmerPropertyCard = () => {
  const shimmerStyle = {
    background: "linear-gradient(90deg, #f6f7f8 25%, #eaeaea 50%, #f6f7f8 75%)",
    backgroundSize: "200% 100%",
    animation: "shimmer 1.5s infinite",
  };

  const shimmerText = {
    height: "16px",
    width: "80%",
    marginBottom: "8px",
    borderRadius: "4px",
    ...shimmerStyle,
  };

  return (
    <Row className="flex-wrap mx-0 border-bottom">
      <Col
        md={4}
        lg={3}
        className="mb-2 mb-md-0 px-0 position-relative"
        style={{ flexGrow: 1 }}
      >
        <div
          className="bg-gray-25 border-0"
          style={{ height: 200, borderRadius: 6, ...shimmerStyle }}
        />
      </Col>
      <Col md={8} lg={9} className="p-0">
        <Card.Body className="w-100 h-100 p-2 p-md-3 d-flex flex-column justify-content-between">
          <div>
            <div className="d-flex flex-row justify-content-between mb-2">
              <div
                style={{
                  height: "20px",
                  width: "50%",
                  borderRadius: "4px",
                  ...shimmerStyle,
                }}
              />
              <div
                style={{
                  height: "20px",
                  width: "20%",
                  borderRadius: "4px",
                  ...shimmerStyle,
                }}
              />
            </div>
            <div>
              <div style={shimmerText} />
              <div style={shimmerText} />
              <div style={{ ...shimmerText, width: "60%" }} />
            </div>
          </div>
          <div className="text-end">
            <div
              style={{
                height: "16px",
                width: "40%",
                borderRadius: "4px",
                ...shimmerStyle,
              }}
            />
          </div>
        </Card.Body>
      </Col>
    </Row>
  );
};

export default ShimmerPropertyCard;
