// src/Slider.js
import RenderItem from "pages/Projects/RenderItem";
import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Image } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const ImageSlider = ({
    items,
    currentItemIndex = 0,
    setCurrentIndex,
}) => {
    const scrollRef = useRef(null);
    const sleep = (milliseconds) => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };
    // const [currentIndex, setCurrentIndex] = useState(currentItemIndex);
    const [ismouseHovered, setIsmouseHovered] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState("");
    const [clickedImageIndex, setclickedImageIndex] = useState([]);
    const [isImageClicked, setIsImageClicked] = useState(false);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
        setIsmouseHovered(true);
    };
    const handleMouseLeave = () => {
        setHoveredIndex("");
        setIsmouseHovered(false);
    };
    const handleImageindex = (index) => {
        setclickedImageIndex(index);
        setIsImageClicked(true);
    };

    // useEffect(() => updateDragImages(items), [items]);

    // const [selected, setSelected] = useState(0);

    const scroll = async (scrollOffset) => {
        // console.log(scrollRef);
        let counter = 0;
        let absScrollOffset = scrollOffset < 0 ? -1 * scrollOffset : scrollOffset;
        // alert(scrollOffset);
        while (counter < absScrollOffset) {
            scrollOffset > 0
                ? (scrollRef.current.scrollLeft += 20)
                : (scrollRef.current.scrollLeft -= 20);
            counter += 20;
            await sleep(10);
        }
    };

    // return (
    //     <>
    //         {items.map((item, index) => (
    //             <div key={index} className="cslider-item">
    //                 <Image src={item.image} />
    //                 {/* <RenderItem room={item} setShow={setShow} /> */}
    //             </div>
    //         ))}
    //     </>
    // )

    return (
        <Container fluid className="p-0 d-flex user-select-none flex-row justify-content-start align-items-center container-fluid">
            {items.length > 6 && (
                <div
                    className="col-1 d-flex justify-content-center align-items-center text-white"
                    style={styles.iconStyle}
                    onClick={(e) => {
                        e.preventDefault();
                        scroll(-200);
                    }}
                >
                    <MdKeyboardArrowLeft />
                </div>
            )}
            <div
                className="col"
                style={{ overflowX: "scroll", maxWidth: "100%" }}
                ref={scrollRef}
            >
                <div
                    className="d-flex my-4 mx-3 py-3 user-select-none"
                    style={{
                        overflowX: "scroll",
                        overflowY: "hidden",
                        minWidth: "fit-content",
                    }}
                >
                    {items?.map(
                        (item, index) => {
                            // console.log({ item });
                            return (
                                <Card
                                    onClick={() => {
                                        // if (selectCallback) {
                                        //     selectCallback(index);
                                        // }
                                        setCurrentIndex(index);
                                    }}
                                    className="me-3 h-100 d-flex justify-content-center align-items-center p-0"
                                    style={
                                        currentItemIndex === index
                                            ? styles.imageContainerSelected
                                            : styles.imageContainer
                                    }
                                >
                                    <div
                                        className="d-flex justify-content-center align-items-center position-relative"
                                        dark={true}
                                        style={styles.sliderCard}
                                    >
                                        <Image
                                            loading="lazy"
                                            className="border-R20"
                                            src={item}
                                            alt={item?.title}
                                            style={styles.sliderCardImage}
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onClick={() => handleImageindex(index)}
                                            onBlur={() => handleMouseLeave()}
                                        />
                                    </div>
                                </Card>
                            );
                        }
                        // </div>
                    )}
                </div>
            </div>
            {items.length > 6 && (
                <div
                    className="col-1 d-flex justify-content-center align-items-center text-white"
                    style={styles.iconStyle}
                    onClick={(e) => {
                        e.preventDefault();
                        scroll(200);
                    }}
                >
                    <MdKeyboardArrowRight />
                </div>
            )}
        </Container>
    );
};


const styles = {
    sliderCard: {
        width: 140,
        height: 120,
    },
    sliderCardImage: {
        maxWidth: 140,
        maxHeight: 120,
    },
    imageContainer: {
        backgroundColor: "#ccc",
        border: "1px solid #CCC",
        width: 140,
        minHeight: 120,
        cursor: "pointer",
    },
    imageContainerSelected: {
        backgroundColor: "#fff",
        border: "3px solid #C53D3D",
        width: 140,
        overflowX: "scroll",
        overflowY: "hidden",
        minHeight: 120,
        cursor: "pointer",
    },
    iconStyle: {
        width: "40px",
        height: "40px",
        border: "1px solid #C53D3D",
        borderRadius: "20px",
        backgroundColor: "#C53D3D",
        cursor: "pointer",
    }
};

export default ImageSlider;
