import React, { useEffect, useRef, useState } from "react";
import "./drop-file-input.css";
import { FaUpload, FaXmark } from "react-icons/fa6";
import { RiUpload2Fill } from "react-icons/ri";
import { notify } from "utilities/notifications/Notify";

const DropExcelFileInput = ({
  file,
  onFileChange,
  className,
  style,
}) => {
  const wrapperRef = useRef(null);
  // const [file, setFile] = useState(null);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const selectedFile = e.target.files[0];

    // Validate file type
    // if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
    //   // setFile([selectedFile]);
    //   onFileChange([selectedFile]); // Pass the selected file to the parent
    // } else {
    //   notify("Only .xlsx files are allowed!", 'error');
    // }

    if (selectedFile) {
      // Validate file type
      if (!selectedFile.name.endsWith(".xlsx")) {
        notify("Only .xlsx files are allowed!", "error");
        return;
      }

      // Validate file size (8MB = 8 * 1024 * 1024 bytes)
      if (selectedFile.size > 8 * 1024 * 1024) {
        notify("File size should not exceed 8MB!", "error");
        return;
      }

      // Pass the selected file to the parent
      onFileChange([selectedFile]);
    }

  };

  const fileRemove = () => {
    // setFile(null);
    onFileChange(null); // Pass null to the parent to indicate no file selected
  };

  return (
    <>
      {/* Drop area */}
      {!file && (
        <div
          ref={wrapperRef}
          className={`drop-file-input position-relative d-flex justify-content-center align-items-center border border-dashed ${className}`}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          style={{
            width: "100%",
            height: 240,
            borderRadius: 8,
            ...style,
          }}
        >
          <div className="drop-file-input__label d-flex flex-column justify-content-center align-items-center body-text2">
            <RiUpload2Fill size={24} className="text-dark mb-3" />
            <p>
              Drag & Drop your .xlsx file here or{" "}
              <u className="text-primary">click to upload</u>
            </p>
          </div>
          <input
            type="file"
            value=""
            accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={onFileDrop}
            className="cursor-pointer position-absolute top-0 start-0 w-100 h-100"
            style={{ opacity: 0 }}
          />
        </div>
      )}

      {/* File preview */}
      {file && (
        <div className="drop-file-preview mt-3">
          <div
            className="drop-file-preview__item d-flex justify-content-between align-items-start w-100"
          >
            <div
              className="rounded overflow-hidden border me-2"
              style={{ height: 76, maxWidth: 100 }}
            >
              <img
                src={URL.createObjectURL(file[0])}
                alt=""
                className="img-fluid"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="w-100">{file[0].name}</div>
            <div
              onClick={fileRemove}
              className="drop-file-preview__item__del cursor-pointer bg-gray text-center rounded-circle ms-2"
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: 40, height: 40 }}
              >
                <div className="w-100 mb-1">
                  <FaXmark size={18} className="text-dark" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DropExcelFileInput;
