import { Col, Row } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { useMediaQuery } from "react-responsive";
import BrandCards from "./BrandCards";

import Slider from "react-slick";
import { useLocation } from "react-router-dom";

// Custom arrow components for the slider
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        zIndex: 1,
        left: "5px",
      }}
      onClick={onClick}
    >
      {/* <FaChevronLeft color="white" size={16} /> */}
    </div>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        zIndex: 1,
        right: "5px",
      }}
      onClick={onClick}
    >
      {/* <FaChevronRight color="white" size={16} /> */}
    </div>
  );
};

const AddProjectDetails = ({
  projectDetails,
  status,
  setCurrentStep,
  user,
  currentStep,
  newFiles,
  roomsToDisplay,
  temps,
  displaySelectedBrands,
}) => {
  const location = useLocation();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const settings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow:
      temps?.length > 0 || roomsToDisplay?.length > 0 ? <NextArrow /> : null,
    prevArrow:
      temps?.length > 0 || roomsToDisplay?.length > 0 ? <PrevArrow /> : null,
  };

  // Define projectSegments to map the value to a label
  const projectSegments = [
    {
      id: 1,
      label: "Affordable (₹2,500 to ₹4,000)",
      value: "AFFORDABLE_RESIDENTIAL",
    },
    {
      id: 3,
      label: "Mid-Range (₹4,000 to ₹7,000)",
      value: "MID_RANGE_RESIDENTIAL",
    },
    {
      id: 5,
      label: "Luxury (₹7,000 to ₹12,000)",
      value: "LUXURY_RESIDENTIAL",
    },
    {
      id: 7,
      label: "Ultra-Luxury (₹12,000+)",
      value: "ULTRA_LUXURY_RESIDENTIAL",
    },
  ];

  const getSliderHeight = () => {
    if (xlarge) return 500;
    if (large) return 500;
    if (medium) return 400;
    if (small) return 300;
    return 200;
  };


  // Find the label for the selected property_segment
  const selectedSegmentLabel =
    projectSegments.find((segment) => segment.value === projectDetails?.property_segment)?.label || "-";

  const MediaSlide = ({ file, height }) => {
    const isVideo = file.type?.startsWith('video/') ||
      file.name?.match(/\.(mp4|webm|mov|avi|wmv)$/i) ||
      (file.fileType === 'video');

    return (
      <div className="position-relative overflow-hidden">
        {isVideo ? (
          <video
            className="img-fluid w-100 rounded-3"
            src={URL.createObjectURL(file)}
            controls
            style={{ height, objectFit: "cover" }}
          />
        ) : (
          <img
            className="img-fluid w-100 rounded-3"
            src={URL.createObjectURL(file)}
            style={{ height, objectFit: "cover" }}
            alt="Room preview"
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <h4 className="inter-600">
          {currentStep}.{" "}
          {location.pathname.includes("builder/addproject")
            ? "Sample Flat Preview"
            : "Project Preview"}
        </h4>
        {temps?.length > 0 && (
          <small className="text-primary px-1">* Newly added Images</small>
        )}
      </div>
      {temps?.length > 0 || roomsToDisplay?.length > 0 ? (
        <Slider className="w-100" {...settings}>
          {temps?.map((file, index) => (
            <MediaSlide key={index} file={file} height={getSliderHeight()} />
          ))}

        </Slider>
      ) : (
        <Slider className="w-100" {...settings}>
          {roomsToDisplay?.map((room, index) => (
            <div key={index} className="position-relative overflow-hidden">
              <div
                className="bg-gray-50 rounded p-2 position-absolute"
                style={{ top: 16, left: 16, zIndex: 1 }}
              >
                {room?.products?.length} Products Added
              </div>
              <MediaSlide file={room.file} height={getSliderHeight()} />
            </div>
          ))}
        </Slider>
      )}
      <div className="mb-4">
        {!status && (
          <div className="mb-4 body-text2">
            <div className="d-flex justify-content-between">
              <h4 className="fw-bold">
                {location.pathname.includes("builder/addproject")
                  ? "Sample Flat Details"
                  : "Project Details"}
              </h4>
            </div>
            <div className="my-1">
              {location.pathname.includes("builder/addproject")
                ? "Sample Flat Name"
                : "Project Name"}
              : {projectDetails.project_name}
            </div>
            <div className="my-1">Layout: {projectDetails.bhk}</div>
            <div className="my-1">
              Designed Year: {projectDetails.project_year}
            </div>
            <div className="my-1">BOQ Cost: {projectDetails.project_cost} {projectDetails.cost_unit}</div>
            <div className="my-1">
              Flat Area:{" "}
              {projectDetails.flat_area || projectDetails?.project_area} Sq.Ft.
            </div>
            <div className="my-1">
              {location.pathname.includes("builder/addproject")
                ? "Sample Flat Segment"
                : "Project Segment"}
              : {selectedSegmentLabel}
            </div>
            <div className="my-1">Address: {projectDetails?.address}</div>
            <div className="my-1">City: {projectDetails?.city}</div>
            <div className="my-1">State: {projectDetails?.state}</div>
            <div className="my-1">Country: {projectDetails?.country}</div>
            <div className="my-1">Pincode: {projectDetails?.pincode}</div>
            {projectDetails?.project_property?.location && (
              <div className="my-1">
                Location: {projectDetails?.project_property?.location}
              </div>
            )}
            {projectDetails?.project_property?.builder_data && (
              <div className="my-1">
                Builder: {projectDetails?.builder_data?.name || "-"}
              </div>
            )}
            <div className="my-1">Architect: {user.full_name}</div>
            <div className="my-1">
              {location.pathname.includes("builder/addproject")
                ? "Sample Flat Description"
                : "Project Description"}
              : {projectDetails?.project_description || "-"}
            </div>
            {displaySelectedBrands.length > 0 && (
              <>
                <hr />
                <p className="h6 cursor-pointer">Product Brands Used</p>
                <Row>
                  {displaySelectedBrands.map((brand) => (
                    <Col className="mb-1" key={brand.id} xs={12} md={4}>
                      <BrandCards brand={brand} isPreview={true} />
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AddProjectDetails;