import React, { useEffect, useRef, useState } from "react";
import * as authActions from "reduxFiles/actions/authActions";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyOtpAndLogin } from "reduxFiles/reducer/authSlices";
import { notify } from "utilities/notifications/Notify";
import { professional_icon, homeowner_icon } from "assets";

import UserTypeSelection from "./UserTypeSelection";
import ProfessionalTypeSelection from "./ProfessionalTypeSelection";
import UserSignup from "./UserSignup";
import PartnerSignup from "./PartnerSignup";
import { validateEmail } from "utilities/validateEmail";

const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hash = location.hash.substring(1);
  const hashValues = hash.split('|');

  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [step, setStep] = useState(1);
  const [serviceType, setServiceType] = useState("architect");
  const [loading, setLoading] = useState(false);
  const [otpInput, setOtpInput] = useState(false);
  const [resendTimer, setResendTimer] = useState(120);

  const [username, setUsername] = useState("");
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [showOtpFields, setShowOtpFields] = useState(false);


  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const form = useRef();

  const joinAs = [
    { id: 1, title: "Homeowner", icon: homeowner_icon, description: "Client looking to get my home interiors done through InterioKeys" },
    { id: 2, title: "Professional", icon: professional_icon, description: "Designer, brand, builder, contractors, PMC, etc looking to work with InterioKeys" }
  ];

  const serviceCategories = [
    { id: 1, title: "Designers", type: "architect", description: "Looking to create design inspirations. Architects, interior designers and design+build firms, etc." },
    { id: 2, title: "Brands", type: "brand", description: "Looking to showcase products. Sanitaryware, lighting, flooring, etc." },
    { id: 3, title: "Builders", type: "builder", description: "Looking to list their properties. My Home, Suncity Builders, etc" },
    { id: 4, title: "Others (Execution)", type: "others", description: "PMC, Turnkey Contractors, OEM’s" },
  ];

  useEffect(() => {
    if (hashValues[0] === "homeowner") {
      setIsHomeOwner(true);
      setStep(3);
    } else if (hashValues[0] === "professional") {
      setIsHomeOwner(false);
      setStep(2);
    }
    if (hashValues[1]) {
      setServiceType(hashValues[1]);
      setStep(3);
    }
  }, [location]);

  const handleUserSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(form.current);
    const email = formData.get("email").toLowerCase();
    formData.set("email", email); // Replace with lowercase version
    formData.append("user_type", "customer");
    let data = {};
    formData.forEach((value, key) => (data[key] = value));

    if (validateEmail(email)) {
      try {
        const response = await authActions.signup(data);
        if (response?.status === 'SUCCESSFUL') {
          setOtpInput(true);
          setShowOtpFields(true);
          setIsResendDisabled(true);
          setResendTimer(response.time_left);
          notify("OTP sent successfully!", "success");
          // setLoading(false);
        } else if (response?.error === "Email already exists.") {
          notify("This email is already registered. Please log in.", "error");
        } else {
          notify(response?.msg || "Failed to send OTP. Try again.", "error");
        }
      } catch (error) {
        // setLoading(false);
        console.log(error);
      }
    } else {
      // setLoading(false);
      notify("Please enter a valid email id!", "error");
    }
    setLoading(false);
  };

  const verifyOtpLogin = async (e) => {
    // e.preventDefault();
    setLoading(true);
    const formData = new FormData(form.current);
    const data = {
      username: username.toLowerCase(),
      email_verified_otp: otp,
    };
    console.log(data);
    try {
      const response = await authActions.verfiyOtpLogin(data);
      if (response) {
        setOtp("");
        setOtpInput(true);
        setShowOtpFields(true);
        setIsResendDisabled(true);
        dispatch(verifyOtpAndLogin(response.data));
        notify("Logged in successfully!", "success");
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Container
      fluid
      className="my-5 p-0 py-4"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100 bg-brand-25 d-flex justify-content-center align-items-center">
        <div className="h2 text-primary-500 inter-600 pb-3 pt-4">
          {step === 1
            ? "Get started with InterioKeys!"
            : `Signup as InterioKeys ${isHomeOwner
              ? "Homeowner"
              : serviceType === "builder"
                ? "Builder"
                : serviceType === "brand"
                  ? "Brand"
                  : serviceType === "architect"
                    ? "Professional"
                    : "" // Default fallback
            }`
          }
        </div>
      </div>
      {step === 1 ?
        // <UserTypeSelection joinAs={joinAs} isHomeOwner={isHomeOwner} />
        <></>
        :
        step === 2 ?
          <ProfessionalTypeSelection
            serviceCategories={serviceCategories}
            step={step}
            setStep={setStep}
            serviceType={serviceType}
            setServiceType={setServiceType}
          />
          : step === 3 ?
            isHomeOwner ?
              <UserSignup
                form={form}
                step={step}
                setStep={setStep}
                otpInput={otpInput}
                handleUserSignUp={handleUserSignUp}
                verifyOtpLogin={verifyOtpLogin}
                loading={loading}
                otp={otp}
                setOtp={setOtp}
                setUsername={setUsername}
                resendTimer={resendTimer}
                setResendTimer={setResendTimer}
                isResendDisabled={isResendDisabled}
                setIsResendDisabled={setIsResendDisabled}
                showOtpFields={showOtpFields}
                setShowOtpFields={setShowOtpFields}
              />
              :
              <PartnerSignup
                form={form}
                step={step}
                setStep={setStep}
                serviceType={serviceType}
                verifyOtpLogin={verifyOtpLogin}
                otp={otp}
                setOtp={setOtp}
                setUsername={setUsername}
              />
            :
            <></>
      }
    </Container>
  );
};

export default Signup;
