import ProjectCard from "components/Cards/ProjectCard";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Nav,
  Row,
  Spinner,
} from "react-bootstrap";
import { FaRegHeart } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import { GoArrowUpRight } from "react-icons/go";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import InspirationsList from "./InspirationsList";
import MobileProjectCard from "components/Cards/MobileProjectCard";
import Slider from "react-slick/lib/slider";
import { useSelector } from "react-redux";

const PropertyDetailsPage = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [propertyData, setPropertyData] = useState(null);
  const [inspirations, setInspirations] = useState([]);
  const [nonSampleFlats, setNonSampleFlats] = useState([])
  const [loadingInspirations, setLoadingInspirations] = useState(true);
  const [sampleFlats, setSampleFlats] = useState([]);
  const [projects, setProjects] = useState([]);
  const { propertyId } = useParams();
  const small = useMediaQuery({ query: "(min-width: 576px)" });

  const settings = {
    dots: false,
    arrows: false,
    infinite: projects.length > 1,
    autoplay: true,
    speed: 1200,
    slidesToShow: small ? 3 : 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
  };

  const fetchPropertyData = async () => {
    const response = await authActions.fetchPropertyById(propertyId);
    try {
      if (response.data) {
        setPropertyData(response.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjectsByProperty = async (propertyId) => {
    try {
      const response = await authActions.fetchProjectByProperty(propertyId);
      if (response?.data?.results) {
        setInspirations(response?.data?.results);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingInspirations(false);
    }
  };

  useEffect(() => {
    if (inspirations && inspirations.length > 0) {
      const filteredSampleFlats = inspirations.filter(
        (project) => project.is_sample_flat === true
      );

      const filteredNonSampleFlats = inspirations.filter(
        (project) => project.is_sample_flat === false
      );


      setSampleFlats(filteredSampleFlats);
      setNonSampleFlats(filteredNonSampleFlats);
    } else {
      setSampleFlats([]);
      setNonSampleFlats([]);
    }
  }, [inspirations]);

  const fetchProjects = async (propertyId) => {
    try {
      const response = await authActions.fetchProjectsRandom();
      if (response?.data?.results) {
        setProjects(response?.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (propertyId) {
      fetchPropertyData();
      fetchProjects();
      fetchProjectsByProperty(propertyId);
    }
  }, [propertyId]);

  if (!propertyData) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "80vh" }}
      >
        <Spinner animation="border" />
      </Container>
    );
  }

  return (
    <div className="mt-5 pt-5 p-0">
      <Container fluid className="d-flex justify-content-center">
        <Container className="d-flex flex-column flex-lg-row align-items-start px-0 p-lg-3 m-0">
          <div
            className="d-flex justify-content-center align-items-center bg-white overflow-hidden"
            style={{
              width: "100%",
              maxWidth: small ? 350 : 10000,
              height: 200,
            }}
          >
            <Image
              className="bg-white"
              width="100%"
              height="200"
              style={{
                objectFit: "cover",
                borderRadius: "12px",
              }}
              src={propertyData?.property_img}
              alt="Property"
            />
          </div>

          {/* Right Content */}
          <div className="w-100 ms-0 ms-lg-3 text-gray-900">
            {/* Title & Icons */}
            <div className="d-flex align-items-center mb-2 mt-2 mt-lg-0">
              <div className="h3 inter-600">
                {propertyData?.title}/
                {propertyData?.layout_type_data?.[0]?.layout}/
                {propertyData?.property_name}
              </div>
              {/* {user?.user_type === "builder" && (
                <div className="d-flex ms-auto me-lg-5">
                  <Nav.Item style={{ padding: !small ? "9px 10px" : "" }}>
                    <Button
                      variant="primary"
                      className="mt-lg-0 px-3 py-2 body-text2 text-white inter-500 border-0 bg-brown"
                      style={{
                        borderRadius: 3,
                      }}
                      onClick={() =>
                        navigate("/builder/addproject", {
                          state: { isSampleFlat: true },
                        })
                      }
                    >
                      Add Sample Flats
                    </Button>
                  </Nav.Item>
                </div>
              )} */}
            </div>

            {/* Details */}
            <div className="body-text1 inter-400 mb-2">
              <div className="mb-3">
                <span className="bg-gray-50 py-2 px-3 inter-500 body-text-2 text-gray-700 cursor-pointer">
                  {propertyData?.builder_data?.company_name || ""}
                </span>
              </div>
              <div className="">
                Layout Type: {propertyData?.layout_type_data?.[0]?.layout}
              </div>
              <div className="">
                Area: {propertyData?.layout_type_data?.[0]?.area} Sft.
              </div>
              <div className="">
                Location:{" "}
                {`${[
                    propertyData?.address,
                    propertyData?.city,
                    propertyData?.state,
                    propertyData?.country,
                  ]
                    .filter(Boolean)
                    .join(", ") || "N/A"
                  } - ${propertyData?.pincode}`}
              </div>
            </div>
          </div>
        </Container>
      </Container>

      <Container className="mt-5">
        <div className="h3 inter-600 text-gray-900 text-start mb-1 mb-lg-3 ms-2">
          Sample Flats
        </div>
        <InspirationsList
          inspirations={sampleFlats}
          listType="sample"
          loading={loadingInspirations}
        />
      </Container>

      <Container className="mt-5">
        <div className="h3 inter-600 text-gray-900 text-start mb-1 mb-lg-3 ms-2">
          Related Inspirations
        </div>
        <InspirationsList
          inspirations={nonSampleFlats}
          listType="inspiration"
          loading={loadingInspirations}
        />
      </Container>
      <Container>
        {projects.length > 0 && (
          <Container className="px-1 py-5">
            <div className="h3 inter-600 text-gray-900 text-start mb-1 mb-lg-3 ms-2">
              Explore Related projects
            </div>
            <Slider {...settings}>
              {projects?.map((item, index) => {
                return (
                  <Col
                    key={index}
                    lg={4}
                    md={12}
                    sm={12}
                    className={`d-flex flex-column py-2 px-2 mb-2 justify-content-start ${small ? "" : "p-0"
                      }`}
                  >
                    {small ? (
                      <ProjectCard
                        {...item}
                        id={item.id}
                        project_name={item?.project_name}
                        // image={item?.file}
                        roomId={item?.rooms[0]?.id}
                        type={`${item?.bhk}, ${item?.project_area}`}
                        architect={item?.company_name}
                        is_sample_flat={item?.is_sample_flat}
                        location={item?.property_data?.location}
                        builders={
                          item?.property_data?.builder_data?.company_name
                        }
                        className="mx-0 flex-grow"
                      // brand={item?.brand_name}
                      />
                    ) : (
                      <MobileProjectCard
                        {...item}
                        id={item.id}
                        project_name={item?.project_name}
                        // image={item?.rooms[0]?.media[0]?.file}
                        roomId={item?.rooms[0]?.id}
                        type={`${item?.bhk}, ${item?.project_area}`}
                        builder_name={
                          item?.property_data?.builder_data?.company_name
                        }
                        is_sample_flat={item?.is_sample_flat}
                        architect={item?.company_name}
                        className="w-100 mobile-card"
                      />
                    )}
                  </Col>
                );
              })}
            </Slider>
          </Container>
        )}
      </Container>
    </div>
  );
};

export default PropertyDetailsPage;
