import { homeownersignup } from "assets";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, InputGroup, Row, Spinner } from "react-bootstrap";
import { LuMail } from "react-icons/lu";

const UserSignup = ({ form, handleUserSignUp, verifyOtpLogin, loading, otp, setOtp, setUsername, resendTimer, setResendTimer, isResendDisabled, setIsResendDisabled, setShowOtpFields, showOtpFields }) => {

    useEffect(() => {
        let timer;
        if (showOtpFields && resendTimer > 0) {
            timer = setInterval(() => {
                setResendTimer((prev) => prev - 1);
            }, 1000);
        } else if (resendTimer === 0) {
            setIsResendDisabled(false);
        }
        return () => clearInterval(timer);
    }, [showOtpFields, resendTimer]);

    const handleOtpChange = (e, index) => {
        const value = e.target.value;
        const otpArray = otp.split("");

        if (value.match(/^\d$/)) {
            otpArray[index] = value;
            setOtp(otpArray.join(""));

            // Move to the next input if there is one
            if (index < 3) {
                document.getElementById(`otp-${index + 1}`).focus();
            }
        }
        else if (value === "") {
            otpArray[index] = "";
            setOtp(otpArray.join(""));
        }
    };

    const handleKeyDown = (e, index) => {
        // alert(e.key);
        const otpArray = otp.split("");
        console.log(otpArray);
        if (e.key === "Backspace" && (otpArray[index] === "" || otpArray.length < (index + 1)) && index > 0) {
            // Move focus to previous field and clear its value
            let x = document.getElementById(`otp-${index - 1}`);
            console.log(x);
            x.focus();
            setOtp((prev) => {
                const otpArray = prev.split("");
                otpArray[index - 1] = "";
                return otpArray.join("");
            });
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!showOtpFields) {
            handleUserSignUp(e);
        } else {
            verifyOtpLogin(otp);
        }
    };

    return (
        <Container
            className="d-flex flex-column justify-content-center align-items-center mt-5"
            style={{ minHeight: "70vh" }}
        >
            <Row style={{ maxWidth: 1000 }}>
                <Col
                    lg={6}
                    className="mb-3 mb-lg-0 d-flex flex-column justify-content-between align-items-center"
                >
                    <Card
                        style={{
                            boxShadow: "none",
                            borderRadius: 0,
                            border: "none",
                            borderLeft: "1px solid #BDC1C6",
                        }}
                    >
                        <h4 className="inter-600">Client Signup</h4>
                        <div className="text-gray-600">
                            Find the best executable interior inspiration from the top designers using
                            the best products.
                        </div>
                    </Card>
                    <Image src={homeownersignup} alt="Homeowner signup" width="80%" />

                </Col>
                <Col lg={6}>
                    <Form
                        ref={form}
                        onSubmit={handleSubmit}
                    >
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1 body-text2 inter-600">
                                Full Name<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <Form.Control
                                required
                                className="py-2 body-text2"
                                type="text"
                                name="full_name"
                                placeholder="Enter your Full Name"
                                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                                disabled={showOtpFields}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1 body-text2 inter-600">
                                Phone Number<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text
                                    className="body-text2 inter-600"
                                    style={{
                                        backgroundColor: "white",
                                        border: "1px solid #BDC1C6",
                                        borderRadius: 3,
                                    }}
                                >
                                    +91
                                </InputGroup.Text>
                                <Form.Control
                                    className="border-start-0 p-2 body-text2"
                                    type="text"
                                    name="mobile"
                                    required
                                    placeholder="9090909090"
                                    maxLength={10}
                                    style={{
                                        border: "1px solid #BDC1C6",
                                        borderRadius: "0px 3px 3px 0px",
                                    }}
                                    pattern="[0-9]{10}" // Ensures exactly 6 digits
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, ""); // Removes non-numeric characters
                                    }}
                                    disabled={showOtpFields}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1 body-text2 inter-600">
                                Email<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text
                                    style={{
                                        backgroundColor: "white",
                                        border: "1px solid #BDC1C6",
                                        borderRadius: 3,
                                    }}
                                >
                                    <LuMail size={20} color="#1A1C1F" />
                                </InputGroup.Text>
                                <Form.Control
                                    className="border-start-0 p-2 body-text2"
                                    type="email"
                                    name="email"
                                    required
                                    placeholder="example@gmail.com"
                                    style={{
                                        border: "1px solid #BDC1C6",
                                        borderRadius: "0px 3px 3px 0px",
                                    }}
                                    // disabled={showOtpFields}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>
                        {showOtpFields && (
                            <>
                                <Form.Group className="my-3">
                                    <Form.Label className="mb-1 body-text2 inter-600">
                                        OTP<sup style={{ color: "red" }}>*</sup>
                                    </Form.Label>
                                    <Row>
                                        {[0, 1, 2, 3].map((index) => (
                                            <Col key={index} xs="3">
                                                <Form.Control
                                                    required
                                                    id={`otp-${index}`}
                                                    maxLength="1"
                                                    pattern="[0-9]{1}" // Ensures exactly 6 digits
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/\D/g, ""); // Removes non-numeric characters
                                                    }}
                                                    value={otp[index] || ""}
                                                    onChange={(e) => handleOtpChange(e, index)}
                                                    style={{
                                                        textAlign: "center",
                                                        border: "1px solid #BDC1C6",
                                                        borderRadius: 3,
                                                    }}
                                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                    <Form.Text className="text-success">
                                        OTP has been sent to the email address
                                    </Form.Text>
                                </Form.Group>
                                <div className="text-center mb-3">
                                    {isResendDisabled ? (
                                        <span className="text-muted">
                                            Resend OTP in {resendTimer} seconds
                                        </span>
                                    ) : (
                                        <span
                                            className="text-primary cursor-pointer"
                                            onClick={(e) => handleUserSignUp(e)}
                                            style={{ textDecoration: "underline" }}
                                        >
                                            Resend OTP
                                        </span>
                                    )}
                                </div>
                            </>
                        )}
                        <div className="d-flex mt-5 w-100">
                            <Button
                                className="w-100 p-2"
                                type="submit"
                                style={{ borderRadius: 3 }}
                                disabled={loading}
                            >
                                {loading && <Spinner animation="border" size="sm" className="me-2" />}
                                {showOtpFields ? "Verify OTP" : "Sign Up"}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default UserSignup;
