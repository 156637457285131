import React from "react";
import { Card, Image } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { removeUnderscoreAndCapatilise } from "utilities/removeUnderscoreAndCapatilise";

function PreviewPropertyCard({
  property_name,
  builder,
  project_type,
  flats,
  layout_types,
  country,
  state,
  pincode,
  city,
  property_year,
  construction_status,
  property_segment,
  property_img,
  setCurrentStep,
  possession_month,
  possession_year,
  phone_number,
  name,
  address,
}) {
  // const medium = useMediaQuery({ query: "(min-width: 768px)" });
  // const large = useMediaQuery({ query: "(min-width: 992px)" });

  console.log(project_type);

  return (
    <Card className="p-0 d-flex flex-column text-start my-4 shadow-none border-0">
      <div className="mb-3 border text-center" style={{ borderRadius: 6 }}>
        <Image src={property_img} style={{ height: 300, width: "100%" }} />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="fw-bold mb-0">Property Details</h4>
        <div className="cursor-pointer" onClick={() => setCurrentStep(1)}>
          <CiEdit size={20} />
        </div>
      </div>
      <Card.Body className="w-100 p-0 body-text2">
        <div className="my-1">
          Property Name: {property_name} ({builder})
        </div>
        <div className="my-1">Number of Flats: {flats}</div>
        <div className="my-1">
          Construction Status:{" "}
          {removeUnderscoreAndCapatilise(construction_status)}
        </div>
        <div className="my-1">Possession month: {possession_month}</div>
        <div className="my-1">Possession year: {possession_year}</div>
        {project_type && (
          <div className="my-1">Property Type: {project_type}</div>
        )}
        <div className="my-1">
          Property Segment: {removeUnderscoreAndCapatilise(property_segment)}
        </div>
        <div className="my-1">Adress: {address}</div>
        <div className="my-1">Country: {country}</div>
        <div className="my-1">State: {state}</div>
        <div className="my-1">City: {city}</div>
        <div className="my-1">Pincode: {pincode}</div>
        <hr/>
        <h6 className="body-title">Point of Contact</h6>
        <div className="my-1">Name: {name}</div>
        <div className="my-1">Phone number: {phone_number}</div>
        <hr />
        <h4 className="fw-bold mb-0">Layout Types</h4>
        {layout_types?.map((lay) => {
          return (
            <div className="my-1">
              {lay["layout"]}: {lay["area"]} sft. - {lay['totalFlats']} Flats
            </div>
          );
        })}
      </Card.Body>
    </Card>
  );
}

export default PreviewPropertyCard;
