import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AdCard = ({ ad }) => {
    const navigate = useNavigate();
    const navigateToUrl = ad?.url.startsWith('http') ? new URL(ad.url).pathname : ad.url;
    return (
        <div className="position-relative my-4" style={{ height: '500px' }}>
            <div className="w-100 h-100">
                <img className="img-fluid w-100 h-100" src={ad?.image} alt="Ad" />
            </div>
            <div className="position-absolute" style={{ top: 10, right: 10 }}>
                <Button variant="info" className="text-gray-900 fw-bold" onClick={() => navigate(navigateToUrl)}>
                    AD
                </Button>
            </div>
        </div>
    );
};

export default AdCard;
