import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { FiFile } from "react-icons/fi"; // For a generic document icon
import { IoDownload } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

const BrochureModal = ({
  showBrochureModal,
  setShowBrochureModal,
  brandDetails,
  fileUrl, 
  fileName, 
}) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });

  const handleDownload = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(fileUrl, {
        responseType: "blob",
      });

      // The response data is already a blob since responseType is "blob"
      const blob = response.data;

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Use the fileName prop (e.g., "brochure.zip")
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      // Close the modal after download
      setShowBrochureModal(false);
    } catch (error) {
      console.error("Error downloading the file", error);
      //   openNotification(
      //     "An error occurred while downloading the file.",
      //     null,
      //     "error"
      //   );
    } finally {
      //   setLoading(false);
    }
  };

  return (
    <Modal
      className="d-flex justify-content-center align-items-center"
      dialogClassName="custom-width-modal"
      show={showBrochureModal}
      onHide={() => setShowBrochureModal(false)}
    >
      <div className="py-3 px-4">
        <Modal.Header
          className="px-0 pb-0 position-relative"
          style={{ border: 0 }}
        >
          <div>
            <h3 className="">Download Brochure</h3>
          </div>
          <div
            className="cursor-pointer position-absolute icon-hover"
            style={{ right: 0, top: 10 }}
            onClick={() => setShowBrochureModal(false)}
          >
            <IoClose size={24} />
          </div>
        </Modal.Header>
        <Modal.Body className="py-5 text-center">
          <ul className="list-group">
            <li className="list-group-item">
              <div className="row align-items-center">
                {/* Left side: Document Icon */}
                {small && (
                  <div className="col-2 d-flex justify-content-center align-items-center">
                    <FiFile size={20} color="#d32f2f" />
                  </div>
                )}

                {/* Middle: Brochure Name */}
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <p className="mt-3 mb-2 font-weight-bold text-truncate">
                    {fileName || "Brochure"}
                  </p>
                </div>

                {/* Right side: Download Icon */}
                <div className="col-4 d-flex justify-content-end align-items-center">
                  {fileUrl && (
                    <div onClick={handleDownload}>
                      <IoDownload
                        className="cursor-pointer"
                        aria-label="Download Brochure"
                        size={24}
                        color="#d32f2f"
                      />
                    </div>
                  )}
                </div>
              </div>
            </li>
          </ul>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default BrochureModal;