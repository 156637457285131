import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { addDeletedImageId } from "reduxFiles/reducer/projectSlice";
import useFetchMedia from "utilities/customHooks/useFetchMedia";

const RoomMediaCard = ({
  item,
  index,
  setImagesForDeleteButton,
  newFilesArray,
  selectedRoomtypes,
  imagesForDeleteButton,
}) => {
  const dispatch = useDispatch();
  const deletedImageIds = useSelector((state) => state.project?.deleteImageIds);
  const {
    image: fetchedMedia,
    loading,
    error,
  } = useFetchMedia("project", "room", item?.id, true);

  const fetchedMediaArray = Array.isArray(fetchedMedia) ? fetchedMedia : [];

  // Only update imagesForDeleteButton when needed - don't clear it on room selection
  useEffect(() => {
    if (fetchedMediaArray.length > 0) {
      // Filter out any deleted media
      const updatedMediaArr = fetchedMediaArray.filter(
        (media) => !deletedImageIds.includes(media?.id)
      );

      // Update media for this specific room type without clearing others
      setImagesForDeleteButton((prev) => {
        // Create a merged array with existing media from other rooms
        return [...updatedMediaArr];
      });
    }
  }, [fetchedMediaArray, deletedImageIds, setImagesForDeleteButton]);

  // Filter media by deleted ids
  const media = ((item?.id && fetchedMedia) || []).filter(
    (media) => !deletedImageIds?.includes(media?.id)
  );

  const onRemoveRoomMedia = (id) => {
    dispatch(addDeletedImageId(id));
  };

  // Function to determine if a file is a video
  const isVideoFile = (file) => {
    // Check file extension
    if (typeof file === "string") {
      return file.match(/\.(mp4|webm|mov|avi|wmv)$/i);
    }
    // Check mime type if available
    return file?.media_type === "video" || file?.type?.startsWith("video/");
  };

  return (
    <>
      {media.map((mediaItem, idx) => {
        const isVideo = isVideoFile(mediaItem?.file);

        return (
          <Col key={`${index}-${idx}`} className="p-2 mb-2">
            <div
              className="d-flex flex-column rounded-start bg-white w-100 border border-grey position-relative"
              style={{
                overflow: "hidden",
                boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
              }}
            >
              <div className="d-flex justify-content-center align-items-center">
                {isVideo ? (
                  <video
                    src={mediaItem?.file}
                    controls
                    className="img-fluid w-100"
                    style={{
                      objectFit: "contain",
                      minHeight: "160px",
                      maxHeight: "300px",
                    }}
                  />
                ) : (
                  <img
                    src={mediaItem?.file}
                    alt={`Room Preview ${idx}`}
                    className="img-fluid w-100"
                    style={{
                      objectFit: "contain",
                      minHeight: "160px",
                      maxHeight: "300px",
                    }}
                  />
                )}
              </div>
              <div
                className="position-absolute"
                style={{
                  top: 1,
                  right: 1,
                  zIndex: 1,
                }}
              >
                <div
                  className="d-flex justify-content-center align-items-center bg-white rounded-circle cursor-pointer"
                  style={{
                    width: 36,
                    height: 36,
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  }}
                  onClick={() => onRemoveRoomMedia(mediaItem?.id, "old", idx)}
                >
                  <MdDelete
                    className="cursor-pointer"
                    size={22}
                    color="#5A5A5A"
                  />
                </div>
              </div>

            </div>
          </Col>
        );
      })}
    </>
  );
};

export default RoomMediaCard;