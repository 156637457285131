import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Modal,
} from "react-bootstrap";
import { FaRegHeart, FaCheck, FaStar } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Review from "components/Review/Review";
import ShareModal from "components/General/ShareModal";
import * as authActions from "reduxFiles/actions/authActions";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "utilities/notifications/Notify";
import RatingStarsView from "components/General/RatingStarsView";
import LoginModal from "components/Auth/LoginModal";
import builderPageImage from "../../assets/builderpageimage.png";
import userImg from "../../assets/navbar/user.webp";
import propertyImg from "../../assets/products/post-placeholder.webp";
import BuilderEnquiryForm from "components/Forms/BuilderEnquiryForm";
import ProjectCard from "components/Cards/ProjectCard";
import MobileProjectCard from "components/Cards/MobileProjectCard";
import TeamMember from "components/TeamMember/TeamMember";
import Gallery from "components/Gallery/Gallery";
import { Image } from "react-bootstrap";
import axios from "axios";

const BuilderDetails = () => {
  const navigate = useNavigate();
  const { detailedSlug } = useParams();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [rating, setRating] = useState(5);
  const [loading, setLoading] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [allProjects, setAllProjects] = useState([]); // Store all projects fetched initially
  const [totalPages, setTotalPages] = useState(null);
  const [builderDetails, setBuilderDetails] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [showShareProject, setShowShareProject] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [activeTab, setActiveTab] = useState("about-us");
  const [showAll, setShowAll] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [media, setMedia] = useState([]);
  const [showEnquiry, setShowEnquiry] = useState(false);
  const [currentProperty, setCurrentProperty] = useState({
    id: 0,
    property_name: "All Projects",
  });
  const [showBrochureModal, setShowBrochureModal] = useState(false);
  const [brochures, setBrochures] = useState([]);
  const [properties, setProperties] = useState([]);
  const [pendingAction, setPendingAction] = useState(null); // Track the action after login
  const location = useLocation();
  const form = useRef(null);
  const aboutUsRef = useRef(null);
  const projectsRef = useRef(null);
  const reviewsRef = useRef(null);
  const tabsRef = useRef(null);
  const teamRef = useRef(null);
  const galleryRef = useRef(null);
  const sliderRef = useRef(null);
  const dispatch = useDispatch();

  const visibleReviews = showAll ? reviews : reviews.slice(0, 5);

  const [pendingReview, setPendingReview] = useState({
    text: "",
    rating: 5,
    shouldReopen: false
  });

  const [isSubmittingReview, setIsSubmittingReview] = useState(false);

  const tabsData = [
    {
      key: "about-us",
      label: "About Us",
      data: builderDetails?.builder_thoughts,
      ref: aboutUsRef,
    },
    {
      key: "properties-created",
      label: `Properties Created (${properties?.length})`,
      data: properties?.length > 0,
      ref: projectsRef,
    },
    { key: "team", label: "Team", data: teamMembers?.length > 0, ref: teamRef },
    {
      key: "gallery",
      label: "Gallery",
      data: media?.length > 0,
      ref: galleryRef,
    },
    {
      key: "reviews-and-ratings",
      label: "Reviews and Ratings",
      data: true,
      ref: reviewsRef,
    },
  ];

  const visibleTabs = tabsData.filter((tab) => tab.data);

  const fetchData = async () => {
    setProjectsLoading(true);
    try {
      const resBuilders = await authActions.fetchBuildersByTypeSlug(detailedSlug);
      if (resBuilders?.data.results) {
        if (resBuilders?.data?.results.length <= 0) {
          setError("No Builders found. Please try again!");
        }
        let tempBuilderDetails = resBuilders.data.results.length > 0 ? resBuilders.data.results[0] : null;
        if (tempBuilderDetails) {
          const response = await authActions.fetchProjectsByBuilderUser(tempBuilderDetails.id); // Initial fetch with original API
          if (response.data?.results) {
            setProjects(response.data?.results);
            setAllProjects(response.data?.results); // Store all projects
            setTotalPages(Math.ceil(response.data.count / 12));
          }
          const propertyResponse = await authActions.fetchPropertiesByBuilderNoPagination(tempBuilderDetails?.user_data?.id);
          setProperties(propertyResponse?.data || []);
          setProjectsLoading(false);
          const resReviews = await authActions.fetchReviewsByArchitectUser(tempBuilderDetails.id);
          if (resReviews?.data?.results) {
            setReviews(resReviews.data.results);
          }
          setBuilderDetails(tempBuilderDetails);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setProjectsLoading(false);
    }
  };

  const handlePropertyClick = (property) => {
    setProjectsLoading(true);
    setCurrentProperty({
      id: property.id,
      property_name: property.property_name,
    });
    if (property.id === 0) {
      // Show all projects
      setProjects(allProjects);
    } else {
      // Filter projects by selected property using project_property field
      const filteredProjects = allProjects.filter(
        (project) => project.project_property === property.id
      );
      setProjects(filteredProjects);
    }
    setProjectsLoading(false);
  };

  const fetchTeamMembers = async (id) => {
    try {
      const response = await authActions.fetchTeamMembers({ user_id: id });
      if (response) setTeamMembers(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGalleries = async (id) => {
    try {
      const response = await authActions.fetchGallery({ user_id: id });
      if (response) setMedia(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const submitReview = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      // Save the current review state
      const reviewText = form.current.elements.review.value;
      setPendingReview({
        text: reviewText,
        rating: rating,
        shouldReopen: true
      });

      // Close review modal and open login modal
      setShow(false);
      setTimeout(() => setShowLoginModal(true), 200);
      return;
    }

    setIsSubmittingReview(true);

    const formData = new FormData(form.current);
    formData.append("for_user", builderDetails?.user_data?.id);
    formData.append("by_user", user?.id);
    formData.append("rating", rating);
    if (formData.get("review") && formData.get("review").trim() !== "") {
      const response = await authActions.addReview(formData);
      if (response.data) {
        notify("Review submitted successfully!", "success");
        fetchReviews(builderDetails?.user_data?.id);
        form.current.reset();
        setIsSubmittingReview(false);
        setShow(!show);
        setPendingReview({
          text: "",
          rating: 5,
          shouldReopen: false
        });

        setRating(5);
      }
    } else {
      setIsSubmittingReview(false);
      notify("Review cannot be empty!", "error");
    }
  };

  const fetchReviews = async (id) => {
    setLoading(true);
    const resReviews = await authActions.fetchReviewsByArchitectUser(id);
    if (resReviews?.data?.results) setReviews(resReviews.data.results);
    setLoading(false);
  };

  const checkProfessionalIfSaved = async () => {
    try {
      const response = await authActions.checkProfessionalIfSaved(user.id, builderDetails.user);
      if (response) setIsLiked(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProfessional = async () => {
    try {
      const response = await authActions.saveProfessional(user.id, builderDetails.user);
      if (response.data) {
        setIsLiked(true);
        notify("Professional saved successfully", "success");
      } else {
        notify("Failed to save professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProfessional = async () => {
    try {
      const response = await authActions.unsaveProfessional(user.id, builderDetails.user);
      if (response) {
        setIsLiked(false);
        notify("Professional unsaved successfully", "success");
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    const targetRef = tabsData.find((t) => t.key === tab)?.ref;
    if (targetRef?.current) {
      window.scrollTo({
        top: targetRef.current.offsetTop - 90,
        behavior: "smooth",
      });
    }
  };

  const slideLeft = () => {
    if (sliderRef.current) sliderRef.current.scrollLeft -= 300;
  };

  const slideRight = () => {
    if (sliderRef.current) sliderRef.current.scrollLeft += 300;
  };

  const truncateText = (text, maxLength) =>
    text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight / 2;
    let newActiveTab = "about-us";
    visibleTabs.forEach((tab) => {
      const sectionRef = tab.ref?.current;
      if (sectionRef) {
        const sectionTop = sectionRef.offsetTop - 90;
        const sectionBottom = sectionTop + sectionRef.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition <= sectionBottom) {
          newActiveTab = tab.key;
        }
      }
    });
    setActiveTab(newActiveTab);
  };

  const fetchBrochures = async (builderId) => {
    const token = localStorage.getItem("authToken");
    console.log("Token in fetchBrochures:", token);
    try {
      const response = await authActions.fetchBuilderBrochures({ builder_ids: [builderId] });
      console.log("fetchBrochures response:", response.data);
      if (response.data && response.data.brochures) {
        setBrochures(response.data.brochures);
      } else {
        setBrochures([]);
        notify("No brochures found for this builder.", "info");
      }
    } catch (error) {
      console.error("Error fetching brochures:", error.response?.data || error);
      if (error.response?.data?.detail === "Invalid token.") {
        notify("Authentication required or session expired. Please log in for full access.", "warning");
      } else {
        notify("Failed to fetch brochures. Please try again.", "error");
      }
    }
  };

  const downloadBrochure = (builderId) => {
    fetchBrochures(builderId);
    setShowBrochureModal(true);
  };

  const handleDownloadFile = async (url, fileName) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const blobUrl = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", fileName || "brochure.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
      notify("Download started!", "success");
    } catch (error) {
      console.error("Error downloading the file", error);
      notify("An error occurred while downloading the file.", "error");
    }
  };

  const handleDownloadBrochureClick = (builderId) => {
    if (!isAuthenticated) {
      setPendingAction(() => () => downloadBrochure(builderId)); // Store the action to execute after login
      setShowLoginModal(true);
    } else {
      downloadBrochure(builderId);
    }
  };

  const handleLoginSuccess = () => {
    if (pendingAction) {
      pendingAction(); // Execute the stored action (e.g., downloadBrochure)
      setPendingAction(null); // Clear the pending action
    }
  };

  useEffect(() => {
    if (detailedSlug) fetchData();
    else navigate(-1);
  }, [detailedSlug]);

  useEffect(() => {
    if (builderDetails) {
      fetchReviews(builderDetails?.user_data?.id);
      fetchTeamMembers(builderDetails?.user_data?.id);
      fetchGalleries(builderDetails?.user_data?.id);
    }
  }, [builderDetails]);

  useEffect(() => {
    if (builderDetails?.id) checkProfessionalIfSaved();
  }, [builderDetails]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, [visibleTabs]);

  useEffect(() => {
    // Check if user just logged in and there's a pending review
    if (isAuthenticated && pendingReview.shouldReopen) {
      // Reopen the review modal
      setShow(true);

      // We need to wait for the modal to open and the form to be in the DOM
      setTimeout(() => {
        if (form.current) {
          // Set the form values from the saved state
          form.current.elements.review.value = pendingReview.text;
          setRating(pendingReview.rating);
        }

        // Reset the "shouldReopen" flag but keep the text and rating
        setPendingReview(prev => ({
          ...prev,
          shouldReopen: false
        }));
      }, 300);
    }
  }, [isAuthenticated, pendingReview.shouldReopen]);

  return (
    <>
      {error ? (
        <Container
          fluid
          className="my-5 p-0 py-4 d-flex justify-content-center align-items-center"
          style={{ minHeight: "80vh" }}
        >
          <div
            className="h2 text-primary-500 inter-600 p-5"
            style={{ backgroundColor: "#F8F9FA" }}
          >
            {error}
          </div>
        </Container>
      ) : (
        <Container fluid className="mt-5 pt-4 px-0">
          <div>
            <img
              src={builderDetails?.builder_banner || builderPageImage}
              alt="Builder Banner"
              className="img-fluid d-block w-100"
              style={{
                objectFit: "contain",
              }}
            />
          </div>
          <div className="container-fluid px-lg-5 px-md-3 px-2 titleimgtext">
            <div className="container px-xl-3 px-0">
              <div className="d-flex justify-content-center geist w-100 m-0 p-0">
                <div className="container-fluid w-100 d-flex align-items-start py-3 px-xl-3 px-0 titletextbar mt-4">
                  <div className="d-flex flex-wrap align-items-start w-100 m-0 p-0">
                    <div className="d-flex justify-content-center align-items-center bg-white overflow-hidden titleimg">
                      <img
                        src={builderDetails?.user_data.profile_picture || userImg}
                        alt="Builder's Profile"
                        className="img-fluid w-100 h-100"
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                    <div className="titletextbtnbox">
                      <div className="titletext flex-grow-1">
                        <div className="h2 inter-600 text-capitalize mt-1 d-flex align-items-center flex-wrap">
                          <div className="me-3 text-uppercase fw-semibold fs-4 lh-sm text-dark">
                            {builderDetails?.company_name ?? detailedSlug?.split("-").join(" ")}
                          </div>
                          <div
                            className="d-flex align-items-center bg-danger text-white abs p-1 p-sm-2 p-md-3 gap-2 rounded-3"
                            style={{ height: "26px" }}
                          >
                            <FaStar className="d-block" style={{ width: "16px", height: "16px" }} />
                            <span className="fs-6 d-block" style={{ width: "16px", height: "16px" }}>
                              {reviews?.length > 0
                                ? (reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length).toFixed(1)
                                : 0}
                            </span>
                          </div>
                        </div>
                        <div className="body-text inter-500 fs-6">Builder</div>
                        {builderDetails?.city && (
                          <div className="mt-1">
                            {builderDetails.city}
                            {builderDetails.state ? `, ${builderDetails.state}` : ""}
                          </div>
                        )}
                        <div className="d-flex gap-3 mt-3 flex-wrap">
                          <div
                            className="d-flex align-items-center p-2 border"
                            style={{ backgroundColor: "#F1F3F4", color: "#2E3134" }}
                          >
                            {builderDetails?.years_of_experience ?? 0} Years Experience
                          </div>
                          {builderDetails?.expertise_type && (
                            <div
                              className="d-flex align-items-center p-2 border"
                              style={{ backgroundColor: "#F1F3F4", color: "#2E3134" }}
                            >
                              Expertise: {builderDetails.expertise_type}
                            </div>
                          )}
                          <div
                            className="d-flex align-items-center p-2 border"
                            style={{ backgroundColor: "#F1F3F4", color: "#2E3134" }}
                          >
                            {builderDetails?.project_count ?? 0} Properties Created
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-start align-items-center titletextbtn">
                        <Button
                          className="d-flex justify-content-center align-items-center social-btn py-2 me-2"
                          style={{ backgroundColor: "#F1F3F4", border: "none" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowShareProject(true);
                          }}
                        >
                          <FiSend color="#000000" size={18} />
                        </Button>
                        {user?.user_type !== "builder" &&
                          user?.user_type !== "architect" &&
                          user?.user_type !== "brand" && (
                            <Button
                              className={`d-flex justify-content-center align-items-center social-btn py-2 ${isLiked ? "bg-primary text-white" : ""}`}
                              style={{ backgroundColor: "#F1F3F4", border: "none" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (!user) setShowLoginModal(true);
                                else isLiked ? handleUnsaveProfessional() : handleSaveProfessional();
                              }}
                            >
                              <FaRegHeart color="#000000" size={18} />
                            </Button>
                          )}
                        <Button
                          className="btn text-white bg-danger border-danger py-2 px-4 ms-2 rounded fw-bold d-inline-flex justify-content-center align-items-center"
                          onClick={() => handleDownloadBrochureClick(builderDetails?.user_data.id)}
                        >
                          Download Brochures
                        </Button>
                        <Button
                          className="ms-3 btn text-white bg-danger border-danger py-2 px-4 rounded fw-bold d-inline-flex justify-content-center align-items-center"
                          onClick={() => {
                            if (!isAuthenticated) setShowLoginModal(true);
                            else setShowEnquiry(true);
                          }}
                        >
                          Send Enquiry
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Container fluid className="p-0 m-0 interotabssection">
                <Row className="gx-5">
                  <Col xs={12} xl={8}>
                    <Container className="my-3 interotabspart">
                      {visibleTabs.length > 0 && (
                        <div
                          ref={tabsRef}
                          className="d-flex border-bottom bg-white"
                          style={{
                            borderBottom: "1px solid #DADCE0",
                            position: "sticky",
                            top: "70px",
                            zIndex: "999",
                          }}
                        >
                          {visibleTabs
                            .filter((tab) => tab.key !== "reviews-and-ratings" || window.innerWidth < 1200)
                            .map(({ key, label }) => (
                              <button
                                key={key}
                                className="border-0 bg-transparent px-sm-3 py-2 position-relative"
                                style={{
                                  fontFamily: "Inter",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  lineHeight: "18px",
                                  textAlign: "center",
                                  color: activeTab === key ? "#C53D3D" : "#80868B",
                                  borderBottom: "2px solid transparent",
                                }}
                                onClick={() => handleTabClick(key)}
                              >
                                {label}
                                {activeTab === key && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: "0",
                                      left: "0",
                                      width: "100%",
                                      height: "3px",
                                      background: "#C53D3D",
                                    }}
                                  />
                                )}
                              </button>
                            ))}
                        </div>
                      )}
                      {builderDetails?.builder_thoughts && (
                        <div ref={aboutUsRef} className="mt-3">
                          <Row style={{ border: "1px solid #D1D1D1", borderRadius: "6px", margin: "0" }}>
                            <Col xs={12} md={2} className="mb-4 mb-md-0 px-0 w-100">
                              {/\.(mp4|webm|ogg)$/i.test(builderDetails?.builder_image) ? (
                                <div style={{ height: "418px" }}>
                                  <video
                                    src={builderDetails?.builder_image}
                                    className="img-fluid"
                                    style={{ width: "100%", height: "100%", borderRadius: "6px" }}
                                    controls
                                    autoPlay
                                    muted
                                  />
                                </div>
                              ) : (
                                <div style={{ height: "418px" }}>
                                  <img
                                    src={builderDetails?.builder_image}
                                    alt="Builder Media"
                                    className="img-fluid"
                                    style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "6px" }}
                                  />
                                </div>
                              )}
                            </Col>
                            <Col xs={12} md={10} className="py-2 text-start d-flex flex-column justify-content-center col-md-12">
                              <div className="body-text1 inter-400 text-gray-600">
                                {builderDetails?.builder_thoughts}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}

                      {properties?.length > 0 && (
                        <div ref={projectsRef} className="pt-5 mt-5 pb-3">
                          <div className="h3 inter-600 mb-4">
                            Properties Created ({properties?.length})
                          </div>
                          <div className="mb-3 d-block">
                            {properties?.length > 0 ? (
                              <div
                                className="d-flex align-items-center position-relative"
                                style={{ gap: "16px", paddingBottom: "10px" }}
                              >
                                {small && properties?.length > 5 && (
                                  <Button
                                    className="position-absolute z-index-top rounded-circle"
                                    style={{
                                      zIndex: 10,
                                      background: "rgba(0, 0, 0, 0.5)",
                                      color: "#fff",
                                      border: "none",
                                      width: "40px",
                                      height: "40px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    onClick={slideLeft}
                                  >
                                    <MdKeyboardArrowLeft size={20} />
                                  </Button>
                                )}
                                <div
                                  ref={sliderRef}
                                  className="d-flex flex-nowrap overflow-auto gap-3 py-2 px-4"
                                  style={{ scrollBehavior: "smooth" }}
                                >
                                  <div
                                    className="d-flex flex-column align-items-center body-text3 nav-link cursor-pointer"
                                    onClick={() =>
                                      handlePropertyClick({
                                        id: 0,
                                        property_name: "All Projects",
                                      })
                                    }
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        width: "132px",
                                        height: "124px",
                                      }}
                                    >
                                      <Image
                                        src={builderDetails?.user_data.profile_picture || userImg}
                                        alt="All Projects"
                                        className="small-img"
                                        width="132px"
                                        height="124px"
                                      />
                                      <div
                                        className={
                                          currentProperty?.property_name === "All Projects"
                                            ? "d-none"
                                            : "white-filter"
                                        }
                                      ></div>
                                    </div>
                                    <div
                                      className={`p-1 ${currentProperty?.property_name === "All Projects"
                                        ? "text-primary"
                                        : ""
                                        }`}
                                    >
                                      All Projects
                                    </div>
                                  </div>

                                  {properties?.map((item, index) => (
                                    <div
                                      key={index}
                                      className="d-flex flex-column align-items-center body-text3 nav-link cursor-pointer"
                                      onClick={() => handlePropertyClick(item)}
                                    >
                                      <div
                                        style={{
                                          position: "relative",
                                          width: "132px",
                                          height: "124px",
                                        }}
                                      >
                                        <Image
                                          src={item?.property_img || propertyImg}
                                          alt={item?.property_name}
                                          className={`small-image ${currentProperty?.id === item.id
                                            ? "slider-active"
                                            : "white-filter"
                                            }`}
                                          width="132px"
                                          height="124px"
                                        />
                                        <div
                                          className={
                                            currentProperty.id === item.id
                                              ? "d-none"
                                              : "white-filter"
                                          }
                                        ></div>
                                      </div>
                                      <div
                                        className={`p-1 ${currentProperty.id === item.id
                                          ? "text-primary"
                                          : ""
                                          }`}
                                      >
                                        {truncateText(item?.property_name, 13)}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                {small && properties?.length > 5 && (
                                  <Button
                                    className="position-absolute end-0 z-index-top rounded-circle"
                                    style={{
                                      zIndex: 10,
                                      background: "rgba(0, 0, 0, 0.5)",
                                      color: "#fff",
                                      border: "none",
                                      width: "40px",
                                      height: "40px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    onClick={slideRight}
                                  >
                                    <MdKeyboardArrowRight size={20} />
                                  </Button>
                                )}
                              </div>
                            ) : (
                              <div
                                style={{ minHeight: "20vh" }}
                                className="text-center text-muted py-3"
                              >
                                No properties available at the moment.
                              </div>
                            )}
                          </div>

                          {projectsLoading ? (
                            <div
                              className="d-flex justify-content-center align-items-center w-100"
                              style={{ minHeight: "50vh" }}
                            >
                              <Spinner />
                            </div>
                          ) : projects.length > 0 ? (
                            <Row>
                              {projects.map((item, index) => (
                                <Col xs={12} sm={6} key={index} className="p-2">
                                  {small ? (
                                    <ProjectCard
                                      {...item}
                                      id={item.id}
                                      project_name={item?.project_name}
                                      roomId={item?.rooms[0]?.id}
                                      type={`${item?.bhk}, ${item?.flat_area}`}
                                      architect={item?.company_name}
                                      location={item?.property_data?.location}
                                      builders={item?.builder_name}
                                      // is_sample_flat={item?.is_sample_flat}
                                      zIndex = { 99 }
                                      
                                    />
                                  ) : (
                                    <MobileProjectCard
                                      {...item}
                                      id={item.id}
                                      project_name={item?.project_name}
                                      roomId={item?.rooms[0]?.id}
                                      type={`${item?.bhk}, ${item?.flat_area}`}
                                      builder_name={item?.builder_name}
                                      architect={item?.company_name}
                                      // is_sample_flat={item?.is_sample_flat}
                                      className="w-100 mobile-card"
                                      zIndex = { 99 }
                                    />
                                  )}
                                </Col>
                              ))}
                            </Row>
                          ) : (
                            <div
                              style={{ minHeight: "20vh" }}
                              className="text-center text-muted py-3"
                            >
                              No inspirations available for this property.
                            </div>
                          )}
                        </div>
                      )}

                      {teamMembers?.length > 0 && (
                        <div ref={teamRef} className="mb-4 pt-4">
                          <div className="h4 inter-600">Meet the Team!</div>
                          <div className="py-3">
                            <div className="row">
                              {teamMembers.map((teamMember, index) => (
                                <div key={index} className="col-5 col-sm-6 col-md-2 mb-3">
                                  <TeamMember {...teamMember} />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      {media?.length > 0 && (
                        <div ref={galleryRef} className="mb-4">
                          <div className="h4 inter-600 pb-2">Get to Know Us!</div>
                          <Gallery
                            items={media}
                            fetchGalleries={() => fetchGalleries(builderDetails?.user_data.id)}
                          />
                        </div>
                      )}
                    </Container>
                  </Col>

                  <Col xs={12} xl={4}>
                    <Container
                      ref={reviewsRef}
                      className="pt-4 pb-3 border reviewpart mt-5"
                      style={{ borderColor: "#E8EAED", borderWidth: "1px", position: "sticky", top: "110px" }}
                    >
                      <div className="h4 inter-600 text-center mb-4">
                        <span className="text-danger">{reviews?.length ?? 0}</span> Reviews and Ratings
                      </div>
                      <div className="h4 inter-600 text-center text-danger d-flex justify-content-center align-items-center gap-2">
                        <FaStar className="text-danger" />
                        {reviews?.length > 0
                          ? (reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length).toFixed(1)
                          : 0}
                      </div>
                      <div className="h6 inter-600 text-center pt-1 pb-1">Average Rating</div>
                      <div className="w-100 my-3 d-flex justify-content-center">
                        <Button className="text-white" onClick={() => setShow(true)}>
                          Add Your Review
                        </Button>
                      </div>
                      <Modal show={show} onHide={() => setShow(false)} centered>
                        <Modal.Header closeButton>
                          <Modal.Title className="h3 inter-600 text-center w-100">Add Review</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="d-flex justify-content-center mb-3">
                            <div style={{ transform: "scale(0.85)" }}>
                              <RatingStarsView rating={rating} setRating={setRating} />
                            </div>
                          </div>
                          <Form ref={form} onSubmit={submitReview}>
                            <Form.Group>
                              <Form.Control
                                name="review"
                                as="textarea"
                                rows={5}
                                placeholder="Give your feedback for builder.."
                                style={{ resize: "none" }}
                                defaultValue={pendingReview.text}
                              />
                            </Form.Group>
                            <div className="w-100 my-3 d-flex justify-content-center">
                              <Button
                                className="text-white"
                                type="submit"
                                disabled={isSubmittingReview}
                                style={{ fontSize: "0.875rem", padding: "6px 12px" }}
                              >
                                {isSubmittingReview ? (
                                  <>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      className="me-1"
                                    />
                                    {" "}Submitting...
                                  </>
                                ) : (
                                  "Submit Review"
                                )}
                              </Button>
                            </div>
                          </Form>
                        </Modal.Body>
                      </Modal>
                      {(showAll ? reviews : reviews.slice(0, 2)).length > 0 ? (
                        (showAll ? reviews : reviews.slice(0, 2)).map((review, index) => <Review key={index} {...review} for_user={builderDetails?.user_data?.id}
                          onReviewUpdate={() => fetchReviews(builderDetails?.user_data?.id)} />)
                      ) : (
                        <div
                          className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                          style={{ height: 160 }}
                        >
                          No reviews available
                        </div>
                      )}
                      {reviews?.length > 2 && !showAll && (
                        <div className="text-center mt-3">
                          <button
                            className="btn"
                            onClick={() => setShowAll(true)}
                            style={{ color: "#C53D3D", border: "none", textDecoration: "underline" }}
                          >
                            See All
                          </button>
                        </div>
                      )}
                    </Container>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <BuilderEnquiryForm
            builderId={builderDetails?.user_data.id}
            show={showEnquiry}
            onHide={() => setShowEnquiry(false)}
            projects={projects}
            builderDetails={builderDetails}
          />
          <ShareModal
            show={showShareProject}
            onHide={() => setShowShareProject(false)}
            urlPath={`/professionals/builders/${detailedSlug}`}
          />
          <LoginModal
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            isHomeOwner={isHomeOwner}
            setIsHomeOwner={setIsHomeOwner}
            onLoginSuccess={handleLoginSuccess} // Use the generic handler for both actions
            currentPathname={location.pathname}
          />
          <Modal show={showBrochureModal} onHide={() => setShowBrochureModal(false)} centered size="lg">
            <Modal.Header closeButton>
              <Modal.Title className="h3 inter-600 text-center w-100">Builder Brochures</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {brochures.length > 0 ? (
                <div className="d-flex flex-column gap-3">
                  {brochures.map((brochure, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-center p-2 border rounded"
                    >
                      <div>
                        <strong>{brochure.property_name}</strong>
                        <p className="mb-0 text-muted">{brochure.temp_file_name}</p>
                      </div>
                      <Button
                        variant="outline-primary"
                        onClick={() => handleDownloadFile(brochure.url, brochure.temp_file_name)}
                      >
                        Download
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-3">No brochures available for this builder.</div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowBrochureModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      )}
    </>
  );
};

export default BuilderDetails;