import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { notify } from "utilities/notifications/Notify";
import { Modal, ProgressBar, Button, Spinner } from "react-bootstrap";

const CHUNK_SIZE = 1024 * 1024; // 1MB chunks
const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB

const MultiProductBrochureUploader = ({
    file,
    show,
    setShow,
    productIds = [], // Array of product IDs
    onUploadComplete,
    onUploadCancel,
}) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [confirmingCancel, setConfirmingCancel] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [errorState, setErrorState] = useState(null);

    // Association states
    const [associating, setAssociating] = useState(false);
    const [associationComplete, setAssociationComplete] = useState(false);
    const [associationError, setAssociationError] = useState(null);

    const cancelTokensRef = useRef([]);
    const isMounted = useRef(true);
    const isCanceling = useRef(false);
    const uploadStateRef = useRef({
        canceled: false,
        inProgress: false,
        completed: false,
        fileIdUploaded: null,
        productIds: []
    });

    // Set product IDs in ref
    useEffect(() => {
        uploadStateRef.current.productIds = productIds;
    }, [productIds]);

    // Clean up on unmount
    useEffect(() => {
        return () => {
            isMounted.current = false;
            // Attempt to cancel if component unmounts during upload
            if (uploadStateRef.current.inProgress && !uploadStateRef.current.completed) {
                cancelUpload();
            }
        };
    }, []);

    // Start upload when modal is shown and we have the necessary data
    useEffect(() => {
        if (
            file &&
            productIds.length > 0 &&
            show &&
            !uploadStateRef.current.inProgress &&
            !uploadStateRef.current.completed
        ) {
            // Use a short delay to ensure the modal is fully visible first
            const timer = setTimeout(() => {
                handleUpload();
            }, 300);

            return () => clearTimeout(timer);
        }
    }, [productIds, file, show]);

    // Start association when upload completes
    useEffect(() => {
        if (uploadComplete && !associating && !associationComplete && !associationError) {
            handleAssociation();
        }
    }, [uploadComplete]);

    const cancelUpload = () => {
        // Update our state tracker
        uploadStateRef.current.canceled = true;

        // Cancel all active upload requests
        cancelTokensRef.current.forEach(cancelToken => {
            if (cancelToken && cancelToken.cancel) {
                cancelToken.cancel("Operation canceled by the user.");
            }
        });
        cancelTokensRef.current = [];
    };

    const handleClose = () => {
        if (associating) {
            // Don't allow closing during association
            return;
        }

        if (uploading && !uploadComplete) {
            // If file is still uploading, show confirmation dialog
            setConfirmingCancel(true);
        } else if (uploadComplete && associationComplete) {
            // If upload and association are complete, close the modal and trigger navigation
            setShow(false);
            if (onUploadComplete && uploadStateRef.current.fileIdUploaded) {
                onUploadComplete(uploadStateRef.current.fileIdUploaded);
            }
        } else if (uploadComplete && associationError) {
            // Upload complete but association failed
            // Allow retry or continue without brochure
            setConfirmingCancel(true);
        } else {
            // Just close if not started
            setShow(false);
            // If we're closing without uploading, trigger the cancel callback
            if (onUploadCancel) {
                onUploadCancel();
            }
        }
    };

    const confirmCancel = async () => {
        if (isCanceling.current) return; // Prevent double-cancellation
        isCanceling.current = true;

        try {
            if (!uploadComplete) {
                // First, mark as canceled and stop ongoing uploads
                cancelUpload();
            }

            setConfirmingCancel(false);
            setShow(false);
            setUploading(false);
            uploadStateRef.current.inProgress = false;

            if (onUploadCancel) {
                onUploadCancel();
            }
        } catch (error) {
            console.error('Error in cancel process:', error);
        } finally {
            isCanceling.current = false;
        }
    };

    const handleAssociation = async () => {
        if (!uploadStateRef.current.fileIdUploaded ||
            associationComplete ||
            !uploadStateRef.current.productIds.length) {
            return;
        }

        setAssociating(true);
        setAssociationError(null);

        try {
            // File ID in URL path, product IDs in request body
            const fileId = uploadStateRef.current.fileIdUploaded;
            await axios.post(`/product/products/${fileId}/associate_with_products/`, {
                productIds: uploadStateRef.current.productIds
            });

            setAssociationComplete(true);
        } catch (error) {
            console.error("Error associating brochure:", error);
            setAssociationError("Failed to associate brochure with products. Please try again.");
        } finally {
            setAssociating(false);
        }
    };

    const handleUpload = async () => {
        if (uploadStateRef.current.inProgress) return; // Prevent double-upload

        // Validate file size
        if (file.size > MAX_FILE_SIZE) {
            setErrorState(`File size exceeds the 100MB limit. Your file is ${(file.size / (1024 * 1024)).toFixed(2)}MB.`);
            return;
        }

        setUploading(true);
        uploadStateRef.current.inProgress = true;

        // Reset progress for UI
        setUploadProgress(0);

        try {
            // Start uploading the file
            if (!isMounted.current || uploadStateRef.current.canceled) {
                console.log("Upload stopped - component unmounted or canceled");
                return;
            }

            const fileId = await uploadFileInChunks(file);

            if (fileId) {
                uploadStateRef.current.fileIdUploaded = fileId;
                console.log(`Brochure uploaded successfully with ID: ${fileId}`);

                uploadStateRef.current.completed = true;
                setUploadComplete(true);

                if (isMounted.current) {
                    setUploading(false);
                    notify("Brochure uploaded successfully!", "success");
                }
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log("Upload process canceled");
            } else {
                console.error("Upload error:", error);
                setErrorState("Upload failed: " + (error.message || "Unknown error"));
                notify("Upload failed. Please try again.", "error");
            }
        } finally {
            if (isMounted.current) {
                uploadStateRef.current.inProgress = false;
                setUploading(false);
            }
        }
    };

    const uploadFileInChunks = async (file) => {
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        let fileId = null;
        let uploadedChunks = 0;

        try {
            for (let i = 0; i < totalChunks; i++) {
                if (!isMounted.current || uploadStateRef.current.canceled) {
                    throw new axios.Cancel("Upload canceled");
                }

                const start = i * CHUNK_SIZE;
                const end = Math.min(start + CHUNK_SIZE, file.size);
                const chunk = file.slice(start, end);

                if (i === 0) {
                    const hash = await computeHash(file);
                    // Upload first chunk without parentId for multi-product case
                    fileId = await uploadFirstChunk(chunk, file.name, totalChunks, hash);
                    if (totalChunks === 1) {
                        await uploadNextChunk(chunk, fileId, i);
                    }
                } else {
                    await uploadNextChunk(chunk, fileId, i);
                }

                uploadedChunks++;

                if (isMounted.current) {
                    setUploadProgress(Math.round((uploadedChunks / totalChunks) * 100));
                }
            }

            return fileId;
        } catch (error) {
            if (axios.isCancel(error)) {
                throw error; // Re-throw cancel errors
            } else {
                console.error(`Error uploading brochure in chunks:`, error);
                if (isMounted.current) {
                    setUploadProgress(-1); // Use -1 to indicate error
                }
                throw error;
            }
        }
    };

    const uploadFirstChunk = async (chunk, fileName, totalChunks, hash) => {
        const source = axios.CancelToken.source();
        cancelTokensRef.current.push(source);

        const uniqueFileName = generateUniqueFileName(fileName);
        const formData = new FormData();
        formData.append("file", chunk);
        formData.append("file_name", fileName);
        formData.append("total_chunks", totalChunks);
        formData.append("hash", hash);
        // No parentId for multiple products
        // We'll associate after full upload
        formData.append("is_multi_product", "true");

        try {
            const response = await axios.post(
                "/uploads/upload/first_chunk/",
                formData,
                {
                    cancelToken: source.token,
                    timeout: 30000 // 30 second timeout
                }
            );

            // Remove from active tokens after successful request
            cancelTokensRef.current = cancelTokensRef.current.filter(token => token !== source);

            return response.data.file_id;
        } catch (error) {
            // Remove from active tokens on error
            cancelTokensRef.current = cancelTokensRef.current.filter(token => token !== source);
            throw error;
        }
    };

    const uploadNextChunk = async (chunk, fileId, chunkIndex) => {
        const source = axios.CancelToken.source();
        cancelTokensRef.current.push(source);

        const formData = new FormData();
        formData.append("file", chunk);
        formData.append("file_id", fileId);
        formData.append("chunk_index", chunkIndex);

        try {
            await axios.post(
                "/uploads/upload/next_chunk/",
                formData,
                {
                    cancelToken: source.token,
                    timeout: 30000 // 30 second timeout
                }
            );

            // Remove from active tokens after successful request
            cancelTokensRef.current = cancelTokensRef.current.filter(token => token !== source);
        } catch (error) {
            // Remove from active tokens on error
            cancelTokensRef.current = cancelTokensRef.current.filter(token => token !== source);
            throw error;
        }
    };

    const generateUniqueFileName = (fileName) => {
        // Trim the file name to remove leading/trailing spaces
        fileName = fileName.trim();

        // Find the last dot to separate the extension
        const lastDotIndex = fileName.lastIndexOf(".");
        let baseName, extension;

        if (lastDotIndex !== -1) {
            baseName = fileName.substring(0, lastDotIndex);
            extension = fileName.substring(lastDotIndex);
        } else {
            baseName = fileName;
            extension = "";
        }

        // Remove spaces, underscores, and dashes from the base name
        baseName = baseName.replace(/[\s_-]+/g, "");
        // Remove any accidental spaces from the extension and make it lowercase
        extension = extension.replace(/\s+/g, "").toLowerCase();

        // Generate an 8-character random string
        const randomString = Math.random().toString(36).substring(2, 10);

        return `${baseName}_${Date.now()}_${randomString}${extension}`;
    };

    const computeHash = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const wordArray = CryptoJS.lib.WordArray.create(reader.result);
                const hash = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex);
                resolve(hash);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    };

    // Get progress color based on status
    const getProgressVariant = (progress) => {
        if (progress === -1) return "danger"; // Error
        if (progress === 100) return "success"; // Complete
        return "primary"; // In progress
    };

    return (
        <>
            <Modal
                size="lg"
                show={show}
                backdrop="static"
                onHide={handleClose}
                aria-labelledby="uploading-multi-brochure-modal"
                centered
            >
                <Modal.Header closeButton={!associating}>
                    <Modal.Title>
                        <h3>
                            {errorState ? "Upload Error" :
                                associationError ? "Association Error" :
                                    associating ? "Associating Brochure..." :
                                        associationComplete ? "Upload Complete" :
                                            uploadComplete ? "Processing..." :
                                                "Uploading Brochure..."}
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorState && (
                        <div className="alert alert-danger mb-3">
                            {errorState}
                        </div>
                    )}

                    <div className="mb-3">
                        <p><strong>File:</strong> {file ? file.name : 'No file selected'}</p>
                        <p><strong>Size:</strong> {file ? `${(file.size / (1024 * 1024)).toFixed(2)} MB` : '-'}</p>
                        <p><strong>Products:</strong> {productIds.length} items</p>
                    </div>

                    <div className="mb-3">
                        <ProgressBar
                            striped={uploadProgress !== 100}
                            variant={getProgressVariant(uploadProgress)}
                            now={uploadProgress > 0 ? uploadProgress : 0}
                            label={uploadProgress === -1 ? "Error" :
                                uploadProgress === 100 ? "Complete" :
                                    `${uploadProgress || 0}%`}
                            style={{ height: '30px' }}
                        />
                        {uploadProgress === -1 && (
                            <small className="text-danger">Failed to upload. Please try again.</small>
                        )}
                    </div>

                    {/* Association status */}
                    {uploadComplete && (
                        <div className="mt-3">
                            {associationError && (
                                <div className="alert alert-danger">
                                    {associationError}
                                    <button className="btn btn-sm btn-outline-danger ms-2" onClick={handleAssociation}>
                                        Retry
                                    </button>
                                </div>
                            )}

                            {associating && (
                                <div className="d-flex align-items-center">
                                    <Spinner animation="border" size="sm" className="me-2" />
                                    <span>Associating brochure with products...</span>
                                </div>
                            )}

                            {associationComplete && (
                                <div className="alert alert-success">
                                    Brochure successfully associated with all products!
                                </div>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {associationComplete ? (
                        <Button variant="primary" onClick={handleClose}>
                            Done
                        </Button>
                    ) : associating ? (
                        <Button variant="primary" disabled>
                            Processing...
                        </Button>
                    ) : uploading ? (
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel Upload
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            <Modal
                show={confirmingCancel}
                onHide={() => setConfirmingCancel(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {uploadComplete && associationError
                            ? "Continue Without Brochure?"
                            : "Cancel Upload?"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {uploadComplete && associationError ? (
                        <p>The brochure was uploaded but couldn't be associated with the products.
                            Do you want to cancel completely (this will delete the created products) or
                            continue without the brochure?</p>
                    ) : (
                        <p>Your brochure is currently uploading. Canceling will remove any partially uploaded data and
                            delete the created products. This operation cannot be undone.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {uploadComplete && associationError ? (
                        <>
                            <Button variant="secondary" onClick={() => setConfirmingCancel(false)}>
                                Try Again
                            </Button>
                            <Button
                                variant="danger"
                                onClick={confirmCancel}
                                disabled={isCanceling.current}
                            >
                                {isCanceling.current ? (
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                                        Canceling...
                                    </>
                                ) : (
                                    "Cancel & Delete Products"
                                )}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button variant="secondary" onClick={() => setConfirmingCancel(false)}>
                                Continue Uploading
                            </Button>
                            <Button
                                variant="danger"
                                onClick={confirmCancel}
                                disabled={isCanceling.current}
                            >
                                {isCanceling.current ? (
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                                        Canceling...
                                    </>
                                ) : (
                                    "Cancel Upload"
                                )}
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MultiProductBrochureUploader;