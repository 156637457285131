import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdOutlineArrowForward,
  MdArrowForward,
  MdArrowForwardIos,
} from "react-icons/md";
import Slider from "react-slick";

import Banners from "./Banners";
import ServiceCard from "./ServiceCard";
import ProjectCard from "components/Cards/ProjectCard";
import TestimonialCard from "components/Cards/TestimonialCard";
import VerticalSlider from "./VerticalSlider";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

import { ArchitectsForDisplay, HowItWorks } from "components";
import { Breakpoint } from "react-socks";
import {
  archilabs,
  ayushi_j,
  builder_partner_1,
  builder_partner_2,
  builder_partner_3,
  builder_partner_4,
  builder_why_choose_us,
  career_choice,
  client_leads,
  dharmesh_shah,
  for_professionals_1,
  for_professionals_2,
  joi_property,
  managalam_tarang,
  meera_hoya,
  partner_img_1,
  partner_img_2,
  partner_img_3,
  partner_img_4,
  partner_img_5,
  partner_img_bg,
  partner_img_builder,
  builder_partner_5,
  partner_profile1,
  piyush_rai,
  priya_joshi,
  priyanka_rai,
  product_dev,
  recreating_seating,
  referral_code,
  relationship_logo,
  show_your_work1,
  show_your_work2,
  show_your_work3,
  shree_residence,
  somani_house,
  suncity_avenue,
  testimonial_pic,
  wait_is_over,
  walk_through,
  web_visibility,
  model_mobile_bg,
  builder_mobile_model,
  builder_mobile_card_img,
  grand_property,
  list_property,
  list_property2,
  shree_residence2,
} from "assets";
import CustomAccordion from "components/CustomAccordion/CustomAccordion";
import LoginModal from "components/Auth/LoginModal";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import BuilderMobileSlider from "components/Partner/partner-builder-mobile-slider";
import CustomSliderForBuilder from "components/Partner/partner-builder-mobile-slider";
import StepsCarousel from "components/Partner/builder-steps-slider";

const ProfessionalBuilder = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const navigate = useNavigate();

  const [brands, setBrands] = useState(null);
  const [recommendedProjects, setRecommendedProjects] = useState([]);
  const [recommendedArchitects, setRecommendedArchitects] = useState([]);
  const [clientTestimonials, setClientTestimonials] = useState(null);
  const [architectTestimonials, setArchitectTestimonials] = useState(null);
  const [builderTestimonials, setBuilderTestimonials] = useState(null);
  const [brandTestimonials, setBrandTestimonials] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeItemId, setActiveItemId] = useState("step_1");
  const [hovered, setHovered] = useState(null);
  // const [showLoginModal, setShowLoginModal] = useState(false);
  // const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [activeKey, setActiveKey] = useState("0");

  const stats = [
    {
      value: "403%",
      text: `More inquiries are generated through home images and videos compared to a bare shell.`,
    },
    {
      value: "94%",
      text: `Buyers check online presence before making property buying decisions in 2024.`,
    },
    {
      value: "68.2 Cr",
      text: `Searches happened in “Home Decor”, “interior” on Pinterest in 2024.`,
    },


    {
      value: "74%",
      text: `Of recent buyers get home interior inspirations through the internet.`,
    },
  ];
  const faqData = [
    {
      id: "0",
      question:
        "How does InterioKeys help builders connect with high-intent clients?",
      answer:
        "InterioKeys provides builders with direct access to a network of architects, designers, and clients actively seeking premium, ready-to-purchase properties, streamlining the sales process.",
    },
    {
      id: "1",
      question: "What types of properties can I list on InterioKeys?",
      answer:
        "Builders can list various types of properties, including residential, commercial, luxury, and sustainable projects. InterioKeys supports showcasing both completed projects and those under development.",
    },
    {
      id: "2",
      question:
        "How does InterioKeys ensure the quality of architects and designers on the platform?",
      answer:
        "InterioKeys verifies each architect and designer through background checks, portfolio reviews, and client feedback, ensuring builders work with trusted professionals.",
    },
    {
      id: "3",
      question: "Can I control who views my property listings on InterioKeys?",
      answer:
        "Yes, builders can set visibility preferences for their listings, choosing who can view specific projects or restrict access to select clients or project collaborators.",
    },
    {
      id: "4",
      question:
        "How does InterioKeys help me expand my business into new markets?",
      answer:
        "InterioKeys offers access pan India, allowing builders to reach new client bases without extensive marketing costs, making it easier to expand regionally and nationally.",
    },
    {
      id: "5",
      question:
        "What project management tools does InterioKeys provide for builders?",
      answer:
        "InterioKeys includes tools for communication, task tracking, and milestone setting, allowing builders to manage project timelines and collaborate effectively with architects and designers.",
    },
    {
      id: "6",
      question:
        "How does InterioKeys handle intellectual property rights for project designs?",
      answer:
        "Builders retain ownership of designs, and InterioKeys requires client permission before sharing any sensitive or proprietary project information, ensuring data protection.",
    },
    {
      id: "7",
      question:
        "Can InterioKeys assist with pricing strategies for different markets?",
      answer:
        "Yes, InterioKeys offers market insights and analytics to help builders set competitive prices based on regional demand and property type.",
    },
    {
      id: "8",
      question:
        "How does InterioKeys vet the brands and products listed on the platform?",
      answer:
        "InterioKeys carefully reviews each brand and product to ensure quality standards are met. This ensures that builders have access to reliable and high-quality materials for their projects.",
    },
    {
      id: "9",
      question:
        "What measures does InterioKeys take to prevent unauthorized access to my listings?",
      answer:
        "InterioKeys uses encryption and secure access controls to protect listings. Access is granted only to verified users, ensuring privacy and data security.",
    },
    {
      id: "10",
      question:
        "How does InterioKeys ensure fair competition among builders on the platform?",
      answer:
        "InterioKeys promotes equal visibility opportunities, using client preferences and project relevancy to match builders with suitable clients, ensuring a balanced platform.",
    },
    // {
    //   id: "11",
    //   question:
    //     "Can InterioKeys assist with legal requirements across different regions?",
    //   answer:
    //     "Yes, InterioKeys offers guidance on region-specific building codes and connects builders with local experts to ensure compliance with regulations in various cities.",
    // },
    {
      id: "11",
      question:
        "How does InterioKeys handle disputes between builders and clients?",
      answer:
        "InterioKeys is reliable in dispute resolution support, conflicts that may aries between both parties.",
    },
    {
      id: "12",
      question:
        "What kind of performance insights does InterioKeys provide about my listings?",
      answer:
        "Builders can access analytics on listing views, engagement rates, and client inquiries, helping them optimize listings and respond to client preferences.",
    },
    // {
    //   id: "14",
    //   question:
    //     "How often is the product library updated, and can I suggest additions?",
    //   answer:
    //     "The product library is updated monthly with the latest offerings, and builders can recommend products or materials they’d like to see added.",
    // },
    {
      id: "13",
      question:
        "Does InterioKeys offer any professional development resources for builders?",
      answer:
        "Yes, InterioKeys hosts webinars, workshops, and industry updates, helping builders stay informed about the latest market trends and best practices.",
    },
    {
      id: "14",
      question: "Are there any fees for using InterioKeys' payment system?",
      answer:
        "Currently it's free for all users till september 25th. After that it will a commission based model, details will be shared later. ",
    },
    {
      id: "15",
      question:
        "Can InterioKeys help me connect with other industry professionals?",
      answer:
        "Yes, builders can collaborate with architects, designers, and brand partners directly through InterioKeys, facilitating comprehensive project management and partnerships.",
    },
    {
      id: "16",
      question:
        "How does InterioKeys ensure project inspirations are fairly distributed among builders?",
      answer:
        "Designers are assigned based on builder expertise, project needs, and client preferences, ensuring fair and relevant matches for all builders.",
    },
    {
      id: "17",
      question: "What kind of marketing support does InterioKeys provide?",
      answer:
        "InterioKeys offers brand promotions, featured listings, podcast , mechins and social media support to increase project visibility and drive engagement.",
    },
    {
      id: "18",
      question: "How can InterioKeys help me stay updated on industry trends?",
      answer:
        "InterioKeys provides access to industry reports, trend insights, and design inspiration updates, helping builders align with current client preferences and market expectations.",
    },
    // {
    //   id: "21",
    //   question:
    //     "Does InterioKeys provide insurance or protection in case of project issues?",
    //   answer:
    //     "InterioKeys offers optional insurance coverage for project disputes and liabilities, giving builders peace of mind during engagements.",
    // },
    {
      id: "19",
      question:
        "How does InterioKeys manage client feedback and reviews for builders?",
      answer:
        "Client feedback and ratings are displayed on builder profiles, with the option for builders to respond, fostering transparency and building credibility.",
    },
    // {
    //   id: "23",
    //   question:
    //     "Can I find short-term or specific project opportunities on InterioKeys?",
    //   answer:
    //     "Yes, InterioKeys supports both long-term and short-term project listings, providing builders with flexibility in the types of engagements they can pursue.",
    // },
    {
      id: "20",
      question: "How does InterioKeys support eco-friendly building practices?",
      answer:
        "InterioKeys promotes sustainable construction by offering a curated selection of green building materials and supporting eco-friendly projects, attracting clients focused on sustainability.",
    },
    {
      id: "21",
      question: "What data security measures does InterioKeys have in place?",
      answer:
        "InterioKeys implements industry-standard encryption, restricted access protocols, and regular security audits to ensure data security and user privacy.",
    },
    // {
    //   id: "26",
    //   question: "How can InterioKeys help me price my projects competitively?",
    //   answer:
    //     "InterioKeys offers market insights on pricing trends and regional demand, helping builders set competitive, profitable rates aligned with industry standards.",
    // },
    {
      id: "22",
      question:
        "Does InterioKeys support virtual tours or 3D model presentations?",
      answer:
        "Yes, builders can create and share 3D models and virtual tours to give clients an immersive preview of their properties, enhancing client engagement and interest.",
    },
    // {
    //   id: "28",
    //   question:
    //     "How does InterioKeys help with compliance to regional building regulations?",
    //   answer:
    //     "InterioKeys connects builders with compliance experts and provides resources on city-specific regulations, ensuring that all projects meet local standards.",
    // },
    {
      id: "23",
      question:
        "Can InterioKeys assist with client communication throughout the project?",
      answer:
        "Yes, InterioKeys includes client communication tools, enabling builders to update clients on only interiar related projects, timelines, and any necessary adjustments, maintaining aligned expectations.",
    },
  ];

  const portfolioSteps = [
    {
      id: 1,
      title: "Create your professional account",
      description:
        "Showcase all your work, details and awards with a single click. Let clients find you!",
    },
    {
      id: 2,
      title: "Badges to promote your presence",
      description:
        "To let your efforts be known, we assign you badges when you reach each milestone on the Interiokeys platform.",
    },
    {
      id: 3,
      title: "Badges to promote your presence",
      description:
        "To let your efforts be known, we assign you badges when you reach each milestone on the Interiokeys platform.",
    },
  ];

  const accordionItems = [
    {
      id: "step_1",
      title: "List Your Properties",
      content:
        "List your properties with all relevant files (brochures, floor pans, AutoCAD files  etc.) for designers to create inspirations of your properties.",
      step: "Step 1",
    },
    {
      id: "step_2",
      title: "Attract InterioAlly",
      content:
        "Now InterioAlly which is designers and brands will create real inspirations for your apartments to showcase their creativity and attract potential buyers to work with them.",
      step: "Step 2",
    },
    {
      id: "step_3",
      title: "Standout from the Crowd",
      content:
        "Thousands of potential customers view your property through design inspirations grabbing attention globally.",
      step: "Step 3",
    },
    // {
    //   id: "step_4",
    //   title: "Share your Work And Much More..",
    //   content:
    //     "Share all the work you do, your awards, your company, everything that makes you special!",
    //   step: "Step 4",
    // },
  ];

  const stepImages = [
    {
      id: "step_1",
      images: [
        {
          url: `${grand_property}`,
          title: "Grand Apartments Suncity Builders",
          layout: "Layouts: 4BHK (2,800Sft), 5BHK (3,500Sft) ",
          location: "Location: Vaishali Nagar, Jaipur, India",
        },
        {
          url: `${list_property}`,
          // title: "Mangalam Tarang/Mangalam Group",
          // layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft)",
          // location: "Location: Vaishali Nagar, Jaipur, India",
        },
        {
          url: `${list_property2}`,
          // title: "Suncity Avenue/Suncity Builders",
          // layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft)",
          // location: " Location: Vaishali Nagar, Jaipur, India",
        },
      ],
    },
    {
      id: "step_3",
      images: [
        {
          url: `${joi_property}`,
          title: "Jewel of India/Suncity Builders",
          layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft) ",
          location: "Location: Vaishali Nagar, Jaipur, India",
        },
        {
          url: `${managalam_tarang}`,
          title: "Mangalam Tarang/Mangalam Group",
          layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft)",
          location: "Location: Vaishali Nagar, Jaipur, India",
        },
        {
          url: `${suncity_avenue}`,
          title: "Mangalam Tarang/Mangalam Group",
          layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft)",
          location: "Location: Vaishali Nagar, Jaipur, India",
        },
      ],
    },
    {
      id: "step_2",
      images: [
        {
          url: `${somani_house}`,
          title: "Somani House/3BHK/Melodia Apartments",
          architect: "Architects: DCJ Architects",
          builders: "Builders: Melodia Group",
        },
        {
          url: `${shree_residence}`,
          title: "Shree Residence/2BHK/Jewel of India",
          architect: "Architects: ArchiLabs",
          builders: "Builders: Suncity Builders",
        },
        {
          url: `${shree_residence2}`,
          title: "Boho House/3.5BHK/Mangalam Radiance",
          architect: "Architects: ArchiLabs",
          builders: "Builders: Suncity Builders",
        },
      ],
    },
  ];

  const profilePics = [
    { img: `${partner_profile1}`, name: "Piyush Rai", role: "Co-Founder" },
    { img: `${priya_joshi}`, name: "Priya Joshi", role: "Co-Founder" },
    { img: `${dharmesh_shah}`, name: "Dharmesh Shah", role: "Founder" },
    { img: `${piyush_rai}`, name: "Piyush Rai", role: "Co-Founder" },
    { img: `${meera_hoya}`, name: "Meera hoya", role: "Founder" },
    { img: `${ayushi_j}`, name: "Ayushi J", role: "Founder" },
    { img: `${priyanka_rai}`, name: "Priyanka Rai", role: "Founder" },
  ];

  const cardData = [
    {
      id: 1,
      icon: `${relationship_logo}`,
      title: "List Properties Seamlessly",
      description:
        "Showcase your projects directly to architects and designers, making it easier to find the right creative collaborators.",
    },
    {
      id: 2,
      icon: `${web_visibility}`,
      title: "Build Relationships",
      description:
        "Engage with top designers who can bring unique value to your properties, making them more appealing to buyers.",
    },
    {
      id: 3,
      icon: `${product_dev}`,
      title: "Increase Visibility",
      description:
        "Gain exposure among a community of design professionals looking for properties to create and buyers how are actively looking for new homes. ",
    },
  ];

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const fetchBrands = async () => {
    try {
      const response = await authActions.fetchRecommendedBrands();
      if (response.data) {
        setBrands(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const toggleAccordion = (id) => {
    setActiveKey(activeKey === id ? null : id);
  };
  const fetchRecommendedProjects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setRecommendedProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchRecommendedArchitects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedArchitects();
      if (response.data) {
        setRecommendedArchitects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchTestimonialsWithType = async () => {
    try {
      const response = await authActions.fetchTestimonialsWithType();
      if (response.data) {
        setClientTestimonials(response.data?.client_testimonials);
        setArchitectTestimonials(response.data?.architect_testimonials);
        setBuilderTestimonials(response.data?.builder_testimonials);
        setBrandTestimonials(response.data?.brand_testimonials);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBrands();
    fetchRecommendedProjects();
    fetchRecommendedArchitects();
    fetchTestimonialsWithType();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-next-arrow"}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={35} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-prev-arrow"}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={35} />
      </div>
    );
  }

  const sliderSettings = {
    customPaging: function (i) {
      return <div className="custom-dot mt-3" />;
    },
    dotsClass: "slick-dots",
    dots: small ? false : true,
    arrows: false,
    infinite: true,
    autoplay: large ? false : true,
    speed: 500,
    cssEase: "linear",
    slidesToShow: large ? 3 : small ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleActiveItemId = (id) => {
    setActiveItemId(id);
  };

  const renderCardsForActiveStep = () => {
    const step = stepImages.find((item) => item.id === activeItemId);

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "15px",
          width: "100%",
          // maxWidth: "821.53px",
          // margin: "auto",
        }}
      >
        {/* Left Side - Large Image */}
        <div
          style={{
            textAlign: "left",
            maxWidth: "61%",
          }}
        >
          <img
            src={step.images[0].url}
            alt={step.images[0].title}
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "393.87px",
              objectFit: "cover",
              borderRadius: "3.82px",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "6px",
              backgroundColor: "white",
            }}
          >
            {/* Left Side */}
            <div style={{ textAlign: "left", flex: 1 }}>
              <div className="body-text1 inter-600 text-gray-800 wrap">
                {step.images[0].title}
              </div>
            </div>

            {/* Right Side */}
            <div style={{ textAlign: "right", flex: 1 }}>
              <div className="body-text2 inter-400 text-gray-800">
                {step.images[0].layout}
              </div>
              <div className="body-text2 inter-400 text-gray-800">
                {step.images[0].location}
              </div>
            </div>
          </div>
        </div>

        {/* Right Side - Two Stacked Images */}
        <div
          className="flex-xl-column flex-row"
          style={{
            // flex: "1 1 38%",
            minWidth: "230px",
            display: "flex",
            gap: "6.37px",
          }}
        >
          {step.images.slice(1).map((image, index) => (
            <div key={index} style={{ width: "100%", textAlign: "left" }}>
              <img
                src={image.url}
                alt={image.title || "Property Image"}
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "228.16px",
                  maxHeight: "218.78px",
                  objectFit: "cover",
                  borderRadius: "3.82px",
                }}
              />
              {image.title && (
                <div
                  style={{
                    padding: "6.37px",
                    backgroundColor: "white",
                    borderRadius: "3.82px",
                  }}
                >
                  <div className="body-text1 inter-600 text-gray-800 wrap">
                    {image.title}
                  </div>
                  <div className="body-text2 inter-400 text-gray-800">
                    {image.layout}
                  </div>
                  <div className="body-text2 inter-400 text-gray-800">
                    {image.location}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderCardsForStep2 = () => {
    const step = stepImages.find((item) => item.id === activeItemId); // Find step by activeItemId

    return step.images.map((image, index) => (
      <div className="step3tabboxitem col-4"
        key={index} 
        style={{textAlign: "left", }}
      >
        <img
          src={image.url}
          alt={image.title}
          style={{ 
            borderRadius: "6px",
          }}
        />
        <div style={{ padding: "6px", backgroundColor: "white" }}>
          <div className="body-text1 inter-600 text-gray-800 wrap">
            {image.title}
          </div>
          <div className="body-text2 inter-400 text-gray-800">
            {image.layout}
          </div>
          <div className="body-text2 inter-400 text-gray-800">
            {image.location}
          </div>
        </div>
      </div>
    ));
  };

  const renderCardsForStep3 = () => {
    const step = stepImages.find((item) => item.id === activeItemId); // Find step by activeItemId

    return step.images.map((image, index) => (
      <div
        key={index}
        style={{
          minWidth: "279px",
          textAlign: "left",
          margin: "16px",
        }}
      >
        <img
          src={image.url}
          alt={image.title}
          style={{
            width: "279px",
            height: "384px",
            objectFit: "cover",
            borderRadius: "6px",
          }}
        />
        <div
          style={{ padding: "6px", backgroundColor: "white", width: "279px" }}
        >
          <div
            className="body-text1 inter-600 text-gray-800 wrap"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1, // Truncate to one line
              WebkitBoxOrient: "vertical",
            }}
          >
            {image.title}
          </div>
          <div className="body-text2 inter-400 text-gray-800">
            {image.architect}
          </div>
          <div className="body-text2 inter-400 text-gray-800">
            {image.builders}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <Container fluid className="px-0 arctectpage">
      {small ? (
        <Container
          fluid
          className="d-flex flex-column align-items-center text-center px-0"
          style={{ minHeight: "100vh" }}
        >
          <Container fluid style={{ background: "#F8F9FA" }}>
           
              <div className="pageTitle text-center sfadsadsadadad">
                <h1 className={` ${screenHeight <= 800 ? "mt-5" : ""}`}>
                  Attract <b style={{ color : "#C53D3D"}}>SuperNatural</b> customers on your 
                  <b style={{ color : "#C53D3D"}}> properties</b> through interior inspirations designed by
                  top  designers from the globe.
                </h1>
              </div>
              {/* <div className="text-gray-600 body-title inter-400 my-3">
            Gain an edge over competitors by collaborating with Interiokeys, where we give you support from start to finish.
          </div> */}
           
          </Container>
          {/* <div className="d-flex justify-content-center bg-brand-500-half w-100 mx-0">
          <div className="mx-2">
            <Image src={for_professionals_1} alt="For Professioanls image 1" className="w-100" />
          </div>
          <div className="mx-2">
            <Image src={for_professionals_2} alt="For Professioanls image 1" className="w-100" />
          </div>
        </div> */}
          <Container fluid className="py-5 px-0 architectbanner">
            <Container className="pt-5 px-0" style={{ width: "1350px" }}>
              <Row >
                {/* Left Column (3 units) */}
                <Col xs={4} className="d-flex flex-column ">
                  <Row className="mb-2">
                    <Col>
                      <div className="text-start partner-card-border architectimg">
                        <img
                          src={builder_partner_1}
                          alt="Shree Residence/5BHK/Jewel of India"
                          className="img-fluid rounded-top "
                        />
                        <p className="ps-2 py-2 body-text1 inter-600 text-gray-800 m-0">
                          ARG One/ ARG Builders
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="text-start partner-card-border architectimg">
                        <img
                          src={builder_partner_2}
                          alt="Room 1"
                          className="img-fluid rounded-top "
                          style={{}}
                        />
                        <p className="ps-3 py-2 body-text1 inter-600 text-gray-800 m-0">
                          Mangalam Radiance/Mangalam Group
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>

                {/* Middle Column (6 units) */}
                <Col
                  xs={4}
                  className="d-flex flex-column justify-content-end align-items-center"
                >
                  <div className="architectmainimg"
                    style={{
                      backgroundImage: `url(${partner_img_bg})`,
                      backgroundSize: "contain",
                      backgroundPosition: "bottom",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <img
                      src={builder_partner_5}
                      alt="Partner Image"
                      className="img-fluid"
                    />
                  </div>
                </Col>

                {/* Right Column (3 units) */}
                <Col xs={4} className="d-flex flex-column">
                  <Row className="mb-2">
                    <Col>
                      <div className="text-start border rounded architectimg ">
                        <img
                          src={builder_partner_3}
                          alt="Room 3"
                          className="img-fluid rounded-top "
                        />
                        <p className="ps-2 py-2 body-text1 inter-600 text-gray-800 m-0">
                          Divinity Trimurthy/ Trimurthy Builders
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="text-start partner-card-border architectimg">
                        <img
                          src={builder_partner_4}
                          alt="Room 4"
                          className="img-fluid rounded-top "
                          style={{}}
                        />
                        <p className="ps-2 py-2 body-text1 inter-600 text-gray-800 m-0">Infinia Park/Infinity Builders </p>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <div className="col-12 mt-4">
                  <Button
                    className="brand-primary-500 text-white body-text-1 mt-4"
                    style={{
                      height: "42px",
                      padding: "8px 14px",
                      gap: "9px",
                      borderRadius: "3px",
                    }}
                    onClick={() => {
                      navigate("/signup#professional|builder");
                    }}
                  >
                    Become an InterioKeys Builder Partner <MdArrowForwardIos />
                  </Button>
                </div>
              </Row>
            </Container>
          </Container>

          <Container
            fluid
            className="border-t border-gray-300 py-5 px-0"
            style={{
             
            }}
          >
            <div className="serchessection px-5">
              {stats.map((stat, index) => (
                <div className="serchesbox"
                  key={index}
                 
                 
                >
                  <span
                    style={{
                      color: "#C53D3D",
                      fontFamily: "Inter",
                      fontWeight: 600,
                      lineHeight: "1",
                      letterSpacing: "0%",
                    }}
                  >
                    {stat.value}
                  </span>
                  <p 
                    style={{
                      color: "#27201B",
                      fontFamily: "Inter",
                      fontWeight: 300,
                      lineHeight: "1.4",
                      letterSpacing: "0%",
                    }}
                  >
                    {stat.text}
                  </p>
                </div>
              ))}
            </div>
          </Container>

          <Container fluid className="p-5 bg-gray-25">
            <Row>
              {/* Left Section */}
              <Col xl={4} xs={12} className="text-start">
                <div className="mb-4 h2 inter-600">How it Works</div>
                <CustomAccordion
                  accordionItems={accordionItems}
                  onToggle={handleActiveItemId}
                />
                <Button
                  className="px-3 text-start btn-primary mt-3 d-flex justify-content-between align-items-center"
                  onClick={() => {
                    navigate("/signup#professional|builder");
                  }}
                >
                  Become an InteriorKeys Builder Partner <MdArrowForwardIos />{" "}
                </Button>
              </Col>

              <Col
                xl={8}
                className="mt-3 mt-xl-0 d-flex align-items-center justify-content-around"
              >
                {activeItemId === "step_1" && (
                  <div
                    className=" py-3 px-lg-4 px-2 d-flex align-items-center rounded justify-content-between w-100"
                    style={{ backgroundColor: "#FFFFFF" }}
                  >
                    {renderCardsForActiveStep()}
                  </div>
                )}

                {activeItemId === "step_3" && (
                  <div
                    className="py-3 px-lg-4 px-2 step3tabbox row"
                    style={{ backgroundColor: "#FFFFFF" }}
                  >
                    {renderCardsForStep2()}
                  </div>
                )}

                {/* {activeItemId === "step_3" && (
                  <div className="d-flex align-items-center justify-content-between">
                    {renderCardsForStep3()}
                  </div>
                )} */}

                {activeItemId === "step_2" && (
                  <div
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "6px",
                      width: "100%",
                    }}
                  >
                    <div className="mb-2 py-3 px-lg-4 px-2">
                      <Row className="align-items-center justify-content-center">
                        <div className="col-xl-9">
                          <Image
                            className="rounded"
                            src={somani_house}
                            alt="Main Content Image"
                            fluid
                            style={{
                              width: "100%",
                              height: "306px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <Col xl={3} className="mt-3 mt-xl-0">
                          <Row className="mt-3 mt-md-0 ">
                            <Col xl={12} xs={6} className="mb-2"> 
                              <Image
                                className="rounded w-100"
                                src={shree_residence}
                                alt="Sidebar Image 1"
                                style={{
                                  // width: '200px',
                                  height: "147px",
                                  objectFit: "cover",
                                }}
                                fluid
                              />
                            </Col>
                            <Col xl={12} xs={6} className="">
                              <Image
                                src={shree_residence2}
                                alt="Sidebar Image 2"
                                className="rounded w-100"
                                style={{
                                  // width: '200px',
                                  height: "147px",
                                  objectFit: "cover",
                                }}
                                fluid
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>

                    <div className=" px-2">
                      <div className=" designerbox">
                        {profilePics.map((member, index) => (
                          <div
                            key={index}
                            // md={1}
                            // xs={6}
                            className="designeritem"
                          >
                            <Image
                              src={member.img}
                              alt={member.name}
                              style={{
                                objectFit: "cover",
                                // width: "67px",
                                // height: "67px",
                                // borderRadius: "50%",
                              }}
                              fluid
                            />
                            <div
                              className="mt-2 body-title inter-600 text-gray-900"
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {member.name}
                            </div>
                            <div className="text-muted body-text2 inter-400">
                              {member.role}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          <Container fluid className="p-5 my-5 mb-0">
            <Row>
              {cardData.map((card) => (
                <Col key={card.id} xs={12} md={6} lg={4} className="mb-4">
                  <div
                    className="p-3 text-start"
                    style={{ border: "1px solid #E8EAED", borderRadius: "6px" }}
                  >
                    <img
                      src={card.icon}
                      alt={card.title}
                      style={{ width: "48px" }}
                    />
                    <h5
                      className="body-title inter-600 mt-3"
                      style={{
                        color: hovered === card.id ? "#C53D3D" : "#1A1C1F",
                      }}
                      onMouseEnter={() => setHovered(card.id)}
                      onMouseLeave={() => setHovered(null)}
                    >
                      {card.title}
                    </h5>
                    <p className="body-text1 inter-400 text-gray-600">
                      {card.description}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
          <Container fluid className="p-5 my-5 mt-0">
            <div className="h2 inter-600 text-gray-900 text-center mb-5">
              Why Should You Choose Us?
            </div>
            <Row
              className=""
              style={{ border: "1px solid #D1D1D1", borderRadius: "6px" }}
            >
              {/* Image Section */}
              <Col xs={12} md={3} className="mb-4 mb-md-0 px-0">
                <img
                  src={builder_why_choose_us}
                  alt="Mandeep and Nisha"
                  className="img-fluid"
                  style={{
                    width: "100%",
                    maxHeight: "280px",
                    objectFit: "cover",
                  }}
                />
              </Col>
              {/* Text Section */}
              <Col
                xs={12}
                md={9}
                className="text-start d-flex flex-column justify-content-between"
              >
                <div className="mt-4 ms-2">
                  <div className="h3 inter-600 text-gray-900">
                    Building better spaces, one inspiring collaboration at a
                    time!{" "}
                  </div>
                  <div className="body-title inter-400 text-gray-600">
                    "InterioKeys has been a game-changer for our projects. The
                    platform connects us directly with talented architects and
                    designers who transform our properties into inspiring
                    spaces. The ease of listing and the visibility we gain among
                    design professionals is unmatched. It's an essential tool
                    for anyone in the construction business."
                  </div>
                </div>
                <div className="mb-4 ms-2">
                  <div
                    className="inter-600 text-gray-900"
                    style={{ fontSize: "20px" }}
                  >
                    Kedia Homes
                  </div>
                  <div
                    className="inter-400 text-gray-600"
                    style={{ fontSize: "16px" }}
                  >
                    InterioKeys Builder Partner
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="h2 inter-600 text-gray-900 text-start mb-2">
            Frequently Asked Questions
          </div>

          {/* {/* <Container fluid className="p-5 my-5"> */}
          <div
            className="container mt-4 mb-5 qandasection"
            style={{
              width: "90%",
              maxWidth: "1200px",
              minHeight: "auto",
              padding: "3% 5%",
              borderRadius: "12px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#EBEBEB",
            }}
          >
            <Accordion activeKey={activeKey}>
              {faqData.map((faq) => (
                <Card
                  key={faq.id}
                  className="mb-2"
                  style={{
                    backgroundColor:
                      activeKey === faq.id ? "#EBEBEB" : "transparent",
                  }}
                >
                                    <span className="qnumber"> Q{parseInt(faq.id) + 1}.</span>

                  <Card.Header
                    className="pt-0"
                    onClick={() => toggleAccordion(faq.id)}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      textAlign: "left",
                      backgroundColor:
                        activeKey === faq.id ? "#EBEBEB" : "transparent",
                      cursor: "pointer",
                      padding: "10px 15px",
                    }}
                  >
                    <div style={{ fontWeight: "bold", flex: 1 }}>
                      {faq.question}
                    </div>
                    <Button
                      variant="light"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleAccordion(faq.id);
                      }}
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                    >
                      {activeKey === faq.id ? "−" : "+"}
                    </Button>
                  </Card.Header>
                  <Accordion.Collapse eventKey={faq.id}>
                    <Card.Body
                      className="text-muted pt-0"
                      style={{
                        backgroundColor:
                          activeKey === faq.id ? "#EBEBEB" : "transparent",
                      }}
                    >
                      {faq.answer.split("\n").map((line, index) =>
                        line.startsWith("- ") ? (
                          <ul key={index} style={{ marginLeft: "20px" }}>
                            <li>{line.substring(2)}</li>
                          </ul>
                        ) : (
                          <p key={index} style={{ marginBottom: "8px" }}>
                            {line}
                          </p>
                        )
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </div>
        </Container>
      ) : (
        <Container
          fluid
          className="d-flex flex-column align-items-center text-center"
          style={{ minHeight: "100vh", padding: "45px 0px 0px", width: "100%" }}
        >
          <div
            style={{ marginTop: "65px" }}
            className="d-flex flex-column align-items-center mb-3"
          >
            <span className="h2 text-gray-900 inter-600">
              <h1 className={` ${screenHeight <= 800 ? "mt-5" : ""}`}>
                Attract <b style={{ color : "#C53D3D"}}>SuperNatural</b> customers on your
                <b style={{ color : "#C53D3D"}}> properties</b> through interior inspirations designed by top
                designers from the globe.
              </h1>
            </span>
          </div>

          <div
            className="w-full h-full min-h-screen"
            style={{
              backgroundImage: `url(${model_mobile_bg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* Main content container */}
            <div className="flex flex-col items-center px-2 ">
              {/* Model image */}
              <div>
                <img
                  src={builder_mobile_model}
                  alt="Partner representative"
                  className="w-100 h-auto object-contain"
                />
              </div>

              {/* Text content */}
              {/* <div className="text-center mt-4">
                <p className="body-title inter-500 text-gray-900 mb-2">
                  Partner with Us!
                </p>
                <p className="body-text1 text-gray-600 inter-400">
                  InterioKeys boosts your property visibility, connects <br />{" "}
                  you with top brands and designers, and delivers <br />{" "}
                  stunning designs for homeowners.
                </p>
              </div> */}
            </div>
          </div>
          <Container className="mb-4 ">
            <CustomSliderForBuilder />
            <Card
              style={{
                borderRadius: "6px",
                border: "1px solid #E8EAED",
                padding: "8px 8px 0px 8px",
              }}
            >
              <Card.Img
                variant="top"
                src={builder_mobile_card_img}
                className="rounded-top"
                style={{
                  height: "200px",
                  objectFit: "cover",
                }}
              />
              <Card.Body className="p-2">
                <Card.Title className="d-flex align-items-center mb-0">
                  <span
                    className="inter-500 text-gray-800"
                    style={{ fontSize: "14px" }}
                  >
                    3BHK, Jewel of India
                  </span>
                </Card.Title>
              </Card.Body>
            </Card>
          </Container>
          <Button
            className="px-3 text-start btn-primary mt-3 d-flex justify-content-between align-items-center"
            onClick={() => {
              navigate("/signup#professional|builder");
            }}
          >
            Become an InteriorKeys Builder Partner <MdArrowForwardIos />{" "}
          </Button>
          <Container
            fluid
            className="border-t border-gray-300 py-5"
            style={{
              maxWidth: "100%", // Ensure it spans the full width
              height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "40px",
              padding: "40px 0", // Remove horizontal padding
            }}
          >
            <div
              className="d-flex flex-wrap justify-content-center"
              style={{ gap: "40px" }}
            >
              {stats.map((stat, index) => (
                <div
                  key={index}
                  className="d-flex flex-column align-items-center text-center"
                  style={{ minWidth: "150px", maxWidth: "250px" }}
                >
                  <span
                    style={{
                      color: "#C53D3D",
                      fontFamily: "Inter",
                      fontWeight: 600,
                      fontSize: "clamp(32px, 5vw, 52px)",
                      lineHeight: "1",
                      letterSpacing: "0%",
                    }}
                  >
                    {stat.value}
                  </span>
                  <p
                    style={{
                      color: "#27201B",
                      fontFamily: "Inter",
                      fontWeight: 400,
                      fontSize: "clamp(16px, 2.5vw, 21px)",
                      lineHeight: "1.4",
                      letterSpacing: "0%",
                      marginTop: "8px",
                    }}
                  >
                    {stat.text}
                  </p>
                </div>
              ))}
            </div>
          </Container>

          <Container className="mb-4">
            <p className="body-title inter-500 text-gray-800 text-start">
              How it Work
            </p>
            <StepsCarousel />
          </Container>

          <Container className="mb-4">
            <p className="body-title inter-500 text-gray-800 text-center">
              Why Should You Choose Us?
            </p>

            <Card className="shadow-sm" style={{ borderRadius: "3px" }}>
              <Card.Body className="p-0 text-start">
                {/* Heading */}
                <p className="body-title text-gray-900 inter-600">
                  Building better spaces, one inspiring collaboration at a time!
                </p>

                {/* Quote */}
                <blockquote className="body-text1 inter-400 text-gray-600">
                  "InterioKeys is a game-changer, connecting us with top
                  architects and designers to transform properties. Its seamless
                  listing and unmatched visibility make it essential for the
                  construction business."
                </blockquote>

                <div
                  className="w-100 mb-2"
                  style={{ borderTop: "1px solid #E8EAED" }}
                ></div>
                {/* Footer */}
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    {/* Company Info */}
                    <div>
                      <p className="body-text1 inter-500 text-gray-900 mb-0">
                        Kedia Homes
                      </p>
                      <p className="body-text1 inter-400 text-gray-600 mb-0">
                        InterioKeys Builder Partner
                      </p>
                    </div>

                    {/* Logo Section */}
                    <div className="d-flex align-items-center">
                      {/* Vertical Separator */}
                      <div
                        className="vr me-3"
                        style={{ height: "30px", borderColor: "#E8EAED" }}
                      ></div>
                      {/* Logo */}
                      <img
                        src={builder_why_choose_us}
                        alt="Kedia logo"
                        width="80px"
                        height="30px"
                        className="object-fit-cover"
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Container>

          <div className="h2 inter-600 text-gray-900 text-start mt-5 mb-2">
            Frequently Asked Questions
          </div>

          {/* {/* <Container fluid className="p-5 my-5"> */}
          <div
            className="container mt-4 mb-5 qandasection"
            style={{
              width: "90%",
              maxWidth: "1200px",
              minHeight: "auto",
              padding: "3% 5%",
              borderRadius: "12px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#EBEBEB",
            }}
          >
            <Accordion activeKey={activeKey}>
              {faqData.map((faq) => (
                <Card
                  key={faq.id}
                  className="mb-2"
                  style={{
                    backgroundColor:
                      activeKey === faq.id ? "#EBEBEB" : "transparent",
                  }}
                >
                                                      <span className="qnumber"> Q{parseInt(faq.id) + 1}.</span>

                  <Card.Header
                    className="pt-0"
                    onClick={() => toggleAccordion(faq.id)}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor:
                        activeKey === faq.id ? "#EBEBEB" : "transparent",
                      cursor: "pointer",
                      padding: "10px 15px",
                    }}
                  >
                    <div style={{ fontWeight: "bold", flex: 1 }}>
                      {faq.question}
                    </div>
                    <Button
                      variant="light"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleAccordion(faq.id);
                      }}
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                    >
                      {activeKey === faq.id ? "−" : "+"}
                    </Button>
                  </Card.Header>
                  <Accordion.Collapse eventKey={faq.id}>
                    <Card.Body
                      className="text-muted pt-0"
                      style={{
                        backgroundColor:
                          activeKey === faq.id ? "#EBEBEB" : "transparent",
                      }}
                    >
                      {faq.answer.split("\n").map((line, index) =>
                        line.startsWith("- ") ? (
                          <ul key={index} style={{ marginLeft: "20px" }}>
                            <li>{line.substring(2)}</li>
                          </ul>
                        ) : (
                          <p key={index} style={{ marginBottom: "8px" }}>
                            {line}
                          </p>
                        )
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </div>
        </Container>
      )}

      <Container
        fluid
        className="px-0 bg-brand-500 text-center d-flex justify-content-center align-items-center "
        style={{ minHeight: small ? "400px" : "300px" }}
      >
        <div>
          <div className="h1 inter-600 text-white">
            Become An InterioKeys Builder Partner
          </div>
          <div className="body-title inter-400 text-white mb-3 mb-lg-5 px-3 px-lg-0">
            Get ahead of the curve and join InterioKeys as a partner. Let us
            help you become the top professional of your industry!
          </div>
          <button
            className="text-center mt-3 text-gray-700 inter-500"
            style={{
              width: small ? "auto" : "90%",
              backgraoundColor: "#f1f3f4",
              borderRadius: "3px",
              border: "none",
              padding: "8px 14px",
            }}
            onClick={() => {
              navigate("/signup#professional|builder");
            }}
          >
            {small ? (
              <>
                Become an Interiorkeys Builder Partner <MdArrowForwardIos />{" "}
              </>
            ) : (
              <span className="text-primary-500">Partner with InterioKeys</span>
            )}
          </button>
        </div>
      </Container>
      
      {/* <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      /> */}
    </Container>
  );
};
export default ProfessionalBuilder;
