import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";

const MultiSelect = ({
  name,
  value,
  onChange,
  categories,
  validate,
  disabled = false,
  required = false,
}) => {
  const [error, setError] = useState(false);
  const selectRef = useRef(null);

  // Handle change in selected options
  const handleChange = (selectedOptions) => {
    const newValue = selectedOptions || []; // Ensure it's an array
    onChange(newValue); // Pass the new value directly to the parent
    if (validate) {
      setError(newValue.length === 0);
    }
  };

  // Validate on mount or when validate state changes
  useEffect(() => {
    if (validate) {
      setError(value.length === 0);
    }
  }, [validate, value]);

  return (
    <div className="mb-3">
      <label className="mb-1 body-text2 inter-600">
        Brand Categories
        <sup style={{ color: "red" }}>{required ? "*" : ""}</sup>
      </label>
      {categories.length > 0 ? (
        <>
          <Select
            isMulti
            name={name}
            options={categories.map((category) => ({
              value: category.id,
              label: category.name,
            }))}
            value={value} // Pass the value directly (array of { value, label })
            onChange={handleChange}
            placeholder="Select brand categories..."
            isClearable
            classNamePrefix="react-select"
            ref={selectRef}
            isDisabled={disabled}
            closeMenuOnSelect={false} // Keep dropdown open after selection
            hideSelectedOptions={false} // Show selected options in the dropdown
          />

          {/* Hidden input for native form validation */}
          <input
            type="text"
            value={value.length > 0 ? "valid" : ""}
            required={required}
            onInvalid={(e) => setError(true)}
            style={{ opacity: 0, height: 0, position: "absolute" }}
            disabled={disabled}
          />
          {error && (
            <div className="text-danger body-text2">
              Please select at least one brand category.
            </div>
          )}
        </>
      ) : (
        <div
          className="py-2 placeholder-glow"
          style={{
            border: "1px solid #BDC1C6",
            borderRadius: 3,
            height: "38px",
          }}
        >
          <span className="placeholder col-12 h-100"></span>
        </div>
      )}
    </div>
  );
};

export default MultiSelect;