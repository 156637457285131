import * as authActions from "reduxFiles/actions/authActions";
import ProductCard from "components/Cards/ProductCard";
import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { notify } from "utilities/notifications/Notify";
import {Modal} from "react-bootstrap";

function ProductEnquiryForm({ productId, show, onHide, setShow }) {
  const form = useRef();
  const [requestType, setRequestType] = useState(null);
  const [product, setProduct] = useState(null);

  const fetchProduct = async () => {
    try {
      const response = await authActions.fetchProduct(productId);
      if (response.data) {
        setProduct(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (productId) {
      fetchProduct();
    }
  }, [productId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    formData.append("product", productId);
    formData.append("request_type", requestType);
    let data = {};
    formData.forEach((value, key) => (data[key] = value));
    console.log(data);
    try {
      const response = await authActions.addProductEnquiry(data);
      if (response.data) {
        notify("Enquiry Sent!", "success");
        setShow(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      show={show}
      className="overflow-hidden"
      dialogClassName="shareModal"
      onHide={onHide}
    >
      <Modal.Body className="p-2">

        <Container>
          <div className="mb-3">
            <ProductCard
              {...product}
              image={product?.media[0]?.file}
              productTypeImage={product?.product_category_data?.image}
              isEnquiry={true}
            />
          </div>
          <Form ref={form} onSubmit={handleSubmit}>
            <Form.Group controlId="formName" className="body-text2 inter-600 mb-2">
              <Form.Label>
                Name<sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Full name here.."
                name="name"
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="body-text2 inter-600 mb-2">
              <Form.Label>
                Company Email<sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="yourcompanyname@email.com"
                name="email"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPincode" className="body-text2 inter-600 mb-2">
              <Form.Label>
                Pincode<sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="000000"
                name="pincode"
                required
              />
            </Form.Group>

            <Form.Group className="body-text2 inter-600 mb-2">
              <Form.Label>
                Request Type<sup className="text-danger">*</sup>
              </Form.Label>
              <div>
                <Button
                  variant={requestType === "Quote" ? "primary" : "info"}
                  onClick={() => setRequestType("Quote")}
                >
                  Price/Quote
                </Button>{" "}
                <Button
                  variant={requestType === "Catalogue" ? "primary" : "info"}
                  onClick={() => setRequestType("Catalogue")}
                >
                  Catalogue
                </Button>{" "}
                <Button
                  variant={requestType === "Information" ? "primary" : "info"}
                  onClick={() => setRequestType("Information")}
                >
                  Information
                </Button>
              </div>
            </Form.Group>

            <Form.Group controlId="formMessage" className="body-text2 inter-600 mb-2">
              <Form.Label>
                Message<sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Hello BoConcept, I would like...."
                name="message"
                required
                style={{ resize: 'none' }}
              />
            </Form.Group>

            <Button variant="danger" type="submit" block className="my-3">
              Send Enquiry
            </Button>
          </Form>
        </Container>
      </Modal.Body>

    </Modal>
  );
}

export default ProductEnquiryForm;
