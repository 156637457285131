import { useEffect, useState } from "react";
import AllProfessionalCard from "components/Cards/AllProfessionalCard";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as authActions from "reduxFiles/actions/authActions";
import SpacesSliderView from "pages/Projects/SpacesSliderView";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import UserProfileProjectCard from "components/Cards/UserProfileProjectCard";
import UserProfileSpaceCard from "components/Cards/UserProfileSpaceCard";
import ProductListCard from "components/Cards/ProductListCard";
import ListedPropertyCard from "components/Cards/ListedPropertyCard";
import BrandCard from "components/Cards/BrandCard";

export const Library = ({
  showAllRecords = false,
  showInspiration = true,
  showSpace = true,
  showProfessional = true,
  showRooms = true,
  showProducts = true,
  showProperties = true,
  showBrands = true,
  context = "home",
}) => {
  const navigate = useNavigate();
  const locationPath = useLocation();

  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);

  const [projects, setProjects] = useState([]);
  const [singleRowProjects, setSingleRowProjects] = useState([]);
  const [projectLength, setProjectLength] = useState(0);
  const [rooms, setRooms] = useState([]);
  const [singleRowRooms, setSingleRowRooms] = useState([]);
  const [roomLength, setRoomLength] = useState(0);
  const [professionals, setProfessionals] = useState([]);
  const [singleRowProfessionals, setSingleRowProfessionals] = useState([]);
  const [professionalLength, setProfessionalLength] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isProjectLiked, setProjectIsLiked] = useState(false);
  const [isSpaceLiked, setIsSpaceLiked] = useState(false);
  const [products, setProducts] = useState([]);
  const [singleRowProducts, setSingleRowProducts] = useState([]);
  const [productLength, setProductLength] = useState(0);
  const [properties, setProperties] = useState([]);
  const [singleRowProperties, setSingleRowProperties] = useState([]);
  const [propertiesLength, setPropertiesLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showSpaceView, setShowSpaceView] = useState(false);
  const [activeSpaceIndex, setActiveSpaceIndex] = useState(0);
  const [roomData, setRoomData] = useState(null);
  const [brands, setBrands] = useState([]);
  const [singleRowBrands, setSingleRowBrands] = useState([]);
  const [brandsLength, setBrandsLength] = useState(0);

  const itemsInRow = xlarge ? 3 : large ? 3 : medium ? 2 : 1;

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchSavedDataByUser({
        user: user.id,
        inspirations: showInspiration,
        spaces: showSpace,
        professionals: showProfessional,
        rooms: showRooms,
        products: showProducts,
        properties: showProperties,
        brands: showBrands,
      });
      if (response.data) {
        if (showInspiration) {
          setProjectLength(response.data["saved_projects"].length);
          setProjects(response.data["saved_projects"]);
          setSingleRowProjects(response.data["saved_projects"].slice(0, itemsInRow));
        }
        if (showSpace) {
          setRoomLength(response.data["saved_rooms"].length);
          setRooms(response?.data["saved_rooms"]);
          setSingleRowRooms(response.data["saved_rooms"].slice(0, itemsInRow));
        }
        if (showProfessional) {
          setProfessionalLength(response.data["saved_professionals"].length);
          setProfessionals(response.data["saved_professionals"]);
          setSingleRowProfessionals(response.data["saved_professionals"].slice(0, itemsInRow));
        }
        if (showProducts) {
          setProducts(response.data["saved_products"]);
          setSingleRowProducts(response.data["saved_products"].slice(0, itemsInRow));
        }
        if (showProperties) {
          setProperties(response.data["saved_properties"]);
          setSingleRowProperties(response.data["saved_properties"].slice(0, itemsInRow));
        }
        if (showBrands) {
          setBrandsLength(response.data["saved_brands"].length);
          setBrands(response.data["saved_brands"]);
          setSingleRowBrands(response.data["saved_brands"].slice(0, itemsInRow));
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const notInterestedAction = (index) => {
    const temp = [...properties];
    setProperties([...temp.slice(0, index), ...temp.slice(index + 1), temp[index]]);
  };

  useEffect(() => {
    fetchData();
  }, [showInspiration, showSpace, showProfessional, showProducts, showProperties, showBrands]);

  console.log(singleRowBrands, "singleRowBrands");
  console.log(brands, "brands");

  // Navigation handler with context-based state for all sections
  const handleViewAll = (path, section) => {
    const navigationState = context === "profile" 
      ? { state: { fromProfile: true, fromSavedSection: section } } 
      : { state: { fromSavedSection: section } };
    navigate(path, navigationState);
  };

  return (
    <Container className="py-4 px-0">
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "50vh" }}>
          <Spinner />
        </div>
      ) : (
        <div>
          {showInspiration && (
            <div className="mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="h4 inter-600 mb-0 ms-3">
                  Saved Inspirations ({!loading && projectLength})
                </div>
                {!showAllRecords && projects.length > 3 && (
                  <div
                    className="body-text2 text-gray-900 inter-500 cursor-pointer"
                    onClick={() => handleViewAll(`/user/saved#inspirations`, "inspirations")}
                  >
                    View all
                  </div>
                )}
              </div>
              <Row className={`${singleRowProjects.length > 0 ? "mx-2" : "px-3"}`}>
                {singleRowProjects?.length > 0 ? (
                  (showAllRecords ? projects : singleRowProjects)?.map((project, index) => (
                    <Col key={index} lg={4} md={6} className="d-flex justify-content-around my-3">
                      <UserProfileProjectCard
                        {...project}
                        id={project?.project_data?.id}
                        slug={project?.project_data?.slug}
                        isLiked={isProjectLiked}
                        setIsLiked={setProjectIsLiked}
                        project_name={project?.project_data?.project_name}
                        roomId={project?.project_data?.rooms[0]?.id}
                        bhk={project?.project_data?.bhk}
                        property_name={project?.project_data?.property_name}
                        architect={project?.project_data?.company_name}
                        builder_name={project?.project_data?.builder_name}
                        projects={projects}
                        setProjects={setProjects}
                        setProjectLength={setProjectLength}
                        setSingleRowProjects={setSingleRowProjects}
                        context={context}
                      />
                    </Col>
                  ))
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 mt-4"
                    style={{ height: "60vh" }}
                  >
                    No projects available
                  </div>
                )}
              </Row>
            </div>
          )}
          {showSpace && (
            <div className="mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="h4 inter-600 mb-0 ms-3">
                  Saved Spaces ({!loading && roomLength})
                </div>
                {!showAllRecords && rooms.length > 3 && (
                  <div
                    className="body-text2 text-gray-900 inter-500 cursor-pointer"
                    onClick={() => handleViewAll(`/user/saved#spaces`, "spaces")}
                  >
                    View all
                  </div>
                )}
              </div>
              <Row className={`${singleRowRooms.length > 0 ? "mx-2" : "px-3"}`}>
                {singleRowRooms?.length > 0 ? (
                  (showAllRecords ? rooms : singleRowRooms)?.map((room, index) => (
                    <Col key={index} lg={4} md={6} className="d-flex justify-content-around my-3">
                      <UserProfileSpaceCard
                        {...room?.room_data}
                        id={room?.room_data?.id}
                        isLiked={isSpaceLiked}
                        setRoomLength={setRoomLength}
                        setIsLiked={setIsSpaceLiked}
                        project={room?.room_data?.project}
                        roomId={room?.room_data?.id}
                        title={`${room?.room_data?.room_type_data?.title}/${room?.room_data?.project_data?.property_name}`}
                        type={`${room?.room_data?.project_data?.bhk}, ${room?.room_data?.project_data?.flat_area}`}
                        setShow={setShowSpaceView}
                        setRooms={setRooms}
                        rooms={rooms}
                        setSingleRowRooms={setSingleRowRooms}
                        context={context} // Pass context
                      />
                    </Col>
                  ))
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 mt-4"
                    style={{ height: "60vh" }}
                  >
                    No spaces available
                  </div>
                )}
              </Row>
              <SpacesSliderView
                ativeRoomIndex={activeSpaceIndex}
                setActiveRoomIndex={setActiveSpaceIndex}
                show={showSpaceView}
                setShow={setShowSpaceView}
                roomData={roomData}
              />
            </div>
          )}
          {showProfessional && (
            <div className="mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="h4 inter-600 mb-3 ms-3">
                  Saved Professionals ({!loading && professionalLength})
                </div>
                {!showAllRecords && professionals.length > 3 && (
                  <div
                    className="body-text2 text-gray-900 inter-500 cursor-pointer"
                    onClick={() => handleViewAll(`/user/saved#professionals`, "professionals")}
                  >
                    View all
                  </div>
                )}
              </div>
              <Row className={`${singleRowProfessionals.length > 0 ? "mx-2" : "px-3"}`}>
                {singleRowProfessionals?.length > 0 ? (
                  (showAllRecords ? professionals : singleRowProfessionals)?.map((professional, index) => (
                    <Col key={index} lg={4} md={6} className="d-flex justify-content-around m-0">
                      <AllProfessionalCard
                        key={index}
                        {...professional}
                        setProfessionalLength={setProfessionalLength}
                        setProfessionals={setProfessionals}
                        setSingleRowProfessionals={setSingleRowProfessionals}
                        professionals={professionals}
                        name={professional.professional_data?.full_name}
                        professional_id={professional?.professional_data?.id}
                        isLiked={isLiked}
                        setIsLiked={setIsLiked}
                        slug = { professional?.architect_data?.slug ??
                          professional?.builder_data?.slug ??
                          professional?.brand_data?.slug 
                        }
                        company_name={
                          professional?.architect_data?.company_name ??
                          professional?.builder_data?.company_name ??
                          professional?.brand_data?.company_name ??
                          ""
                        }
                        location={
                          professional?.architect_data?.location ??
                          professional?.builder_data?.location ??
                          ""
                        }
                        rating={
                          professional?.architect_data?.rating ??
                          professional?.builder_data?.rating ??
                          ""
                        }
                        project_count={
                          professional?.architect_data?.project_count ??
                          professional?.builder_data?.project_count ??
                          ""
                        }
                        expertise={
                          professional?.architect_data?.expertise ??
                          professional?.builder_data?.expertise ??
                          "-"
                        }
                        project_names={
                          professional?.architect_data?.project_names ??
                          professional?.builder_data?.project_names ??
                          ""
                        }
                        type={professional.professional_data?.user_type}
                        image={professional.professional_data?.profile_picture}
                        context={context} 
                      />
                    </Col>
                  ))
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 mt-4"
                    style={{ height: "60vh" }}
                  >
                    No professional available
                  </div>
                )}
              </Row>
            </div>
          )}
          {showProducts && user?.user_type !== "customer" && (
            <div className="mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="h4 inter-600 mb-0 ms-3">
                  Saved Products ({!loading && products?.length})
                </div>
                {!showAllRecords && products.length > 3 && (
                  <div
                    className="body-text2 text-gray-900 inter-500 cursor-pointer"
                    onClick={() => handleViewAll(`/user/saved#products`, "products")}
                  >
                    View all
                  </div>
                )}
              </div>
              <Row className={`${products.length > 0 ? "mx-3" : "px-3"}`}>
                {products?.length > 0 ? (
                  (showAllRecords ? products : singleRowProducts)?.map((product, index) => (
                    <Col
                      key={index}
                      lg={4}
                      md={6}
                      sm={12}
                      className={`d-flex flex-column py-2 mb-2 justify-content-start ${
                        large ? (index % 3 === 0 ? "ps-0" : index % 3 === 2 ? "pe-0" : "") :
                        medium ? (index % 2 === 0 ? "ps-0" : "pe-0") : "p-0"
                      }`}
                    >
                      <ProductListCard
                        {...product?.saved_product_data}
                        productId={product?.product}
                        product_type={product?.product_category_data?.title}
                        products={products}
                        setProducts={setProducts}
                        setSingleRowProducts={setSingleRowProducts}
                        className={`cursor-pointer`}
                        context={context} // Pass context
                      />
                    </Col>
                  ))
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 mt-4"
                    style={{ height: "40vh" }}
                  >
                    No saved products!
                  </div>
                )}
              </Row>
            </div>
          )}
          {showProperties && user?.user_type !== "customer" && (
            <div className="mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="h4 inter-600 mb-0 ms-3">
                  Saved Properties ({!loading && properties?.length})
                </div>
                {!showAllRecords && properties.length > 3 && (
                  <div
                    className="body-text2 text-gray-900 inter-500 cursor-pointer"
                    onClick={() => handleViewAll(`/user/saved#properties`, "properties")}
                  >
                    View all
                  </div>
                )}
              </div>
              <Row className={`${propertiesLength > 0 ? "mx-2" : "px-3"}`}>
                {properties?.length > 0 ? (
                  (showAllRecords ? properties : singleRowProperties)?.map((property, index) => (
                    <ListedPropertyCard
                      key={index}
                      index={index}
                      {...property?.saved_property_data}
                      builder={property?.saved_property_data?.builder_data?.company_name}
                      project_type={property?.saved_property_data?.project_type_data?.title}
                      setProperties={setProperties}
                      properties={properties}
                      setSingleRowProperties={setSingleRowProperties}
                      notInterestedAction={notInterestedAction}
                      locationPath={locationPath}
                      context={context} // Pass context
                    />
                  ))
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 mt-4 mx-3"
                    style={{ height: "40vh" }}
                  >
                    No saved properties!
                  </div>
                )}
              </Row>
            </div>
          )}
          {showBrands && (
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="h4 inter-600 mb-0 ms-3">
                  Saved Brands ({!loading && brandsLength})
                </div>
                {!showAllRecords && brands.length > 3 && (
                  <div
                    className="body-text2 text-gray-900 inter-500 cursor-pointer"
                    onClick={() => handleViewAll(`/user/saved#brands`, "brands")}
                  >
                    View all
                  </div>
                )}
              </div>
              {singleRowBrands?.length > 0 ? (
                <div className="d-flex flex-column align-items-center w-100">
                  {(showAllRecords ? brands : singleRowBrands)?.map((brand, index) => (
                    <BrandCard
                      key={index}
                      {...brand?.saved_brand_data}
                      id={brand?.brand}
                      slug={brand?.saved_brand_data?.slug}
                      companyName={brand?.saved_brand_data?.user_data?.full_name}
                      image={brand?.saved_brand_data?.user_data?.profile_picture}
                      idToUnSave={brand?.id}
                      brandCategories={brand?.brand_category_data}
                      warranty={brand?.saved_brand_data?.warranty}
                      rating={brand?.saved_brand_data?.rating}
                      averagePricing={brand?.average_pricing}
                      minPricing={brand?.saved_brand_data?.min_pricing}
                      maxPricing={brand?.saved_brand_data?.max_pricing}
                      is_saved={brand?.saved_brand_data?.is_saved}
                      setBrands={setBrands}
                      setSingleRowBrands={setSingleRowBrands}
                      brands={brands}
                      setBrandsLength={setBrandsLength}
                      context={context} // Pass context
                    />
                  ))}
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                  style={{ height: 160 }}
                >
                  No brands available
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

export default Library;