import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Spinner,
} from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowForward,
} from "react-icons/io";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { IoFolderOpenOutline } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Banners from "./Banners";
import HeroBanner from "./HeroBanner";
import ServiceCard from "./ServiceCard";
import ProjectCard from "components/Cards/ProjectCard";
import SearchBarInspiration from "components/Navbar/SearchBarInspiration";
import ProjectCardShimmer from "components/Cards/ProjectCardShimmer";
import ShimmerSlider from "./ShimmerSlider";
import MobileProjectCard from "components/Cards/MobileProjectCard";
import { truncateText } from "utilities/truncateText";
import { all_properties, general_inspirations } from "assets";
import propertyImg from "../../assets/products/post-placeholder.webp";
import * as authActions from "reduxFiles/actions/authActions";
import useVideoPlayer from "utilities/customHooks/useVideoPlayer";


const Home = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const { navbarToggle } = useSelector((state) => state.auth);
  const currentLocation = useSelector(
    (state) => state.location.currentLocation
  );

  // Use our custom video player hook
  const { registerVideoRef, handleVideoPlay } = useVideoPlayer();

  const [brands, setBrands] = useState([]);
  const [recommendedProjects, setRecommendedProjects] = useState([]);
  const [recommendedArchitects, setRecommendedArchitects] = useState([]);
  const [clientTestimonials, setClientTestimonials] = useState(null);
  const [architectTestimonials, setArchitectTestimonials] = useState(null);
  const [builderTestimonials, setBuilderTestimonials] = useState(null);
  const [brandTestimonials, setBrandTestimonials] = useState(null);
  const [projects, setProjects] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [noNextItem, setNoNextItem] = useState(true);
  const [properties, setProperties] = useState(null);
  const [currentProperty, setCurrentProperty] = useState({
    id: 0,
    property_name: "All Properties",
    slug: "",
  });
  const [next, setNext] = useState("");
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [searchText, setSearchText] = useState("");
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  useEffect(() => {
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  // Check for overflow and scroll state
  const updateScrollState = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
      setIsOverflowing(scrollWidth > clientWidth);
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.addEventListener("scroll", updateScrollState);
      updateScrollState();
    }
    return () => {
      if (slider) {
        slider.removeEventListener("scroll", updateScrollState);
      }
    };
  }, [properties]);

  // Recalculate overflow on window resize
  useEffect(() => {
    const handleResize = () => {
      updateScrollState();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const card_info = [
    {
      id: 1,
      title: "Real Inspirations",
      description:
        "You can choose from a variety of upcoming and completed interior projects by InterioKeys",
    },
    {
      id: 2,
      title: "No intermediaries",
      description:
        "InterioKeys maintains a direct connection between itself, the craftsmen, manufacturers and the end consumers.",
    },
    {
      id: 3,
      title: "Cost Reduction by 20-30%",
      description:
        "Ready-made designs and multiple brands working directly to reduce interior costs.",
    },
  ];

  const fetchBrands = async () => {
    try {
      const response = await authActions.fetchRecommendedBrands();
      if (response.data) {
        setBrands(response.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadProjects = async (next) => {
    try {
      // Check if general inspirations is selected
      let isGeneralInspiration = false;
      if (currentProperty?.property_name === "General Inspirations") {
        isGeneralInspiration = true;
      }

      // If next URL is provided, use it directly 
      // The next URL will already have the correct parameters
      let response;
      if (next) {
        response = await authActions.fetchProjects(next);
      } else {
        // If no next URL, build it from scratch
        response = await authActions.fetchProjects(
          `/project/projects/?status=APPROVED`,
          null,
          null,
          currentProperty?.property_name === "General Inspirations" ? null : currentProperty?.slug,
          currentLocation === "All Location" ? "" : currentLocation,
          null,
          null,
          null,
          null,
          isGeneralInspiration
        );
      }

      setCount(response?.count);
      setNext(response?.next);
      if (Array.isArray(response?.results)) {
        setProjects((prevProjects) => [...prevProjects, ...response?.results]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecommendedProjects = async () => {
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setRecommendedProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecommendedArchitects = async () => {
    try {
      const response = await authActions.fetchRecommendedArchitects();
      if (response.data) {
        setRecommendedArchitects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTestimonialsWithType = async () => {
    try {
      const response = await authActions.fetchTestimonialsWithType();
      if (response.data) {
        setClientTestimonials(response.data?.client_testimonials);
        setArchitectTestimonials(response.data?.architect_testimonials);
        setBuilderTestimonials(response.data?.builder_testimonials);
        setBrandTestimonials(response.data?.brand_testimonials);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProperties = async () => {
    setLoading(true);
    try {
      const filteredLocation =
        currentLocation === "All Location" ? "" : currentLocation;
      const response = await authActions.fetchAllProperties(filteredLocation);
      if (response.data) {
        setProperties(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const loadPaginatedProjects = async (location) => {
    setLoading(true);
    try {
      let url = `/project/projects/?status=APPROVED`;
      let params = [];

      // Handle location filtering
      const filteredLocation =
        location && location !== "All Location" ? location : "";

      if (filteredLocation) {
        params.push(`location=${filteredLocation}`);
      }

      // Handle property filtering
      if (currentProperty?.property_name === "General Inspirations") {
        // For General Inspirations, set the flag
        params.push(`is_general_inspiration=true`);
      } else if (currentProperty?.property_name !== "All Properties" && currentProperty?.slug) {
        // For specific properties, filter by property slug
        params.push(`project_property__slug__icontains=${currentProperty.slug}`);
      }

      // Combine URL and parameters
      if (params.length > 0) {
        url += `&${params.join("&")}`;
      }

      const response = await authActions.fetchProjects(url);

      if (response) {
        setNext(response.next);
        setCount(response.count);
        setProjects(response.results);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBrands();
    fetchRecommendedProjects();
    fetchRecommendedArchitects();
    fetchTestimonialsWithType();
  }, []);

  useEffect(() => {
    fetchProperties();
    setProperties("load");
  }, [currentLocation]);

  useEffect(() => {
    if (currentLocation !== "All Location") {
      setProjects("load");
      loadPaginatedProjects(currentLocation);
    } else {
      setProjects("load");
      loadPaginatedProjects("");
    }
  }, [currentLocation, currentProperty]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-next-arrow"}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowRight size={45} /> */}
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-prev-arrow"}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowLeft size={35} /> */}
      </div>
    );
  }

  const sliderSettings = {
    customPaging: function (i) {
      return <div className="custom-dot mt-3" />;
    },
    dotsClass: "slick-dots",
    dots: small ? false : true,
    arrows: false,
    infinite: true,
    autoplay: large ? false : true,
    speed: 500,
    cssEase: "linear",
    slidesToShow: large ? 3 : small ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const slideLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -sliderRef.current.offsetWidth * 0.2,
        behavior: "smooth",
      });
    }
  };

  const slideRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: sliderRef.current.offsetWidth * 0.2,
        behavior: "smooth",
      });
    }
  };

  const handleLoadmore = async () => {
    if (next) {
      try {
        const response = await authActions.fetchProjects(next);

        if (response) {
          setNext(response.next);
          setCount(response.count);
          // Append the new results to existing projects
          if (Array.isArray(response.results)) {
            setProjects((prevProjects) => [...prevProjects, ...response.results]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Container fluid className="mt-5 px-3">
      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center text-center first_section_home_page"
        style={{
          minHeight: !small ? "40vh" : "40vh",
          padding: !small ? "26px 16px" : "0px",
        }}
      >
        <Container className={`p-0 ${!small ? "mt-3" : "mt-5"}`}>
          <div className="h1 px-lg-5 inter-600" style={{ color: "#C53D3D" }}>
            We connect professionals for your dream interior.
          </div>
          <div className="text-gray-600 body-title inter-400 my-3">
            Find real inspiration, pros, and products to make it happen,
            Hasslefree!{" "}
          </div>
        </Container>
      </Container>
      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center text-center"
        style={{
          minHeight: !small ? "90vh" : "100vh",
          padding: !small ? "26px 16px" : "0px",
        }}
      >
        <Container className={`p-0 ${!small ? "mt-3" : "mt-5"}`}>
          <div className="mb-3 d-block">
            <div className="h3 px-lg-5 inter-600">
              Real interior inspirations for{" "}
              <span className="text-primary">
                {currentProperty.property_name}
              </span>{" "}
            </div>
            {properties?.length > 0 ? (
              Array.isArray(properties) ? (
                <div
                  className="d-flex align-items-center position-relative"
                  style={{ gap: "16px", paddingBottom: "10px" }}
                >
                  {small && isOverflowing && canScrollLeft && (
                    <Button
                      className="position-absolute z-index-top rounded-circle"
                      style={{
                        zIndex: 10,
                        background: "rgba(0, 0, 0, 0.5)",
                        color: "#fff",
                        border: "none",
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={slideLeft}
                    >
                      <MdKeyboardArrowLeft size={20} />
                    </Button>
                  )}
                  <div
                    ref={sliderRef}
                    className="d-flex flex-nowrap overflow-auto gap-3 py-2 "
                  >
                    {/* General Inspirations Option */}
                    <div
                      className={`d-flex flex-column align-items-center body-text3 nav-link cursor-pointer`}
                      onClick={() => {
                        setCurrentProperty({
                          id: -1,
                          property_name: "General Inspirations",
                          slug: "", // Empty slug for general inspirations
                          is_general_inspiration: true
                        });
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "132px",
                          height: "124px",
                        }}
                      >
                        <Image
                          src={general_inspirations}
                          alt={"General Inspirations"}
                          className={`small-img`}
                          width="132px"
                          height="124px"
                        />
                        <div
                          className={
                            currentProperty?.property_name == "General Inspirations"
                              ? "d-none"
                              : "white-filter"
                          }
                        ></div>
                      </div>
                      <div
                        className={`p-1 ${currentProperty?.property_name == "General Inspirations"
                          ? "text-primary"
                          : ""
                          }`}
                      >
                        General Inspirations
                      </div>
                    </div>

                    <div
                      style={{
                        minHeight: "143px",
                        width: "120px",
                        alignSelf: "center",
                        borderRight: "1px solid #BDC1C6",
                      }}
                    />

                    {/* All Properties Option */}
                    <div
                      className={`d-flex flex-column align-items-center body-text3 nav-link cursor-pointer`}
                      onClick={() => {
                        setCurrentProperty({
                          id: 0,
                          property_name: "All Properties",
                        });
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "132px",
                          height: "124px",
                        }}
                      >
                        <Image
                          src={all_properties || propertyImg}
                          alt={"All Properties"}
                          className={`small-img`}
                          width="132px"
                          height="124px"
                        />
                        <div
                          className={
                            currentProperty?.property_name == "All Properties"
                              ? "d-none"
                              : "white-filter"
                          }
                        ></div>
                      </div>
                      <div
                        className={`p-1 ${currentProperty?.property_name == "All Properties"
                          ? "text-primary"
                          : ""
                          }`}
                      >
                        All Listed Properties
                      </div>
                    </div>

                    {/* Individual Properties */}
                    {Array.isArray(properties) &&
                      properties?.map((item, index) => (
                        <div
                          key={index}
                          className={`d-flex flex-column align-items-center body-text3 nav-link cursor-pointer`}
                          onClick={() => {
                            setCurrentProperty(item);
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "132px",
                              height: "124px",
                              filter: navbarToggle ? "brightness(75%)" : "none",
                            }}
                          >
                            <Image
                              src={item?.property_img || propertyImg}
                              alt={item?.property_name || item?.title}
                              className={`small-image ${currentProperty?.id == item.id
                                ? "slider-active"
                                : "white-filter"
                                }`}
                              width="132px"
                              height="124px"
                            />
                            <div
                              className={
                                currentProperty.id == item.id || navbarToggle
                                  ? "d-none"
                                  : "white-filter"
                              }
                            ></div>
                          </div>
                          <div
                            className={`p-1 ${currentProperty.id == item.id
                              ? "text-primary"
                              : ""
                              }`}
                          >
                            {truncateText(
                              item?.property_name || item?.title,
                              13
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                  {small && isOverflowing && canScrollRight && (
                    <Button
                      className="position-absolute end-0 z-index-top rounded-circle"
                      style={{
                        zIndex: 10,
                        background: "rgba(0, 0, 0, 0.5)",
                        color: "#fff",
                        border: "none",
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={slideRight}
                    >
                      <MdKeyboardArrowRight size={20} />
                    </Button>
                  )}
                </div>
              ) : (
                <ShimmerSlider />
              )
            ) : (
              <div
                style={{ minHeight: "20vh" }}
                className="text-center text-muted py-3 d-flex flex-column align-items-center justify-content-center"
              >
                <IoFolderOpenOutline size={30} className="mb-2" />
                No properties available at the moment.
              </div>
            )}
          </div>

          <Row className="">
            {loading || projects === "load" ? (
              Array.from({ length: 6 }).map((_, index) => (
                <Col className="mb-4" key={index} lg={4} md={6} sm={12}>
                  <ProjectCardShimmer />
                </Col>
              ))
            ) : projects?.length > 0 ? (
              projects.map((item, index) => (
                <Col
                  key={index}
                  lg={4}
                  md={6}
                  sm={12}
                  className={`d-flex flex-column py-2 mb-2 justify-content-start ${small ? "" : "p-0"}`}
                >
                  {small ? (
                    <ProjectCard
                      {...item}
                      id={item.id}
                      project_name={item?.project_name}
                      roomId={item?.rooms[0]?.id}
                      type={`${item?.bhk}, ${item?.project_area}`}
                      is_sample_flat={item?.is_sample_flat}
                      architect={item?.company_name}
                      location={item?.property_data?.location}
                      builders={item?.property_data?.builder_data?.company_name}
                      className="mx-0 flex-grow"
                      // Pass our video management functions from the hook
                      videoRefCallback={registerVideoRef}
                      onVideoPlay={handleVideoPlay}
                    />
                  ) : (
                    <MobileProjectCard
                      {...item}
                      id={item.id}
                      project_name={item?.project_name}
                      roomId={item?.rooms[0]?.id}
                      type={`${item?.bhk}, ${item?.project_area}`}
                      is_sample_flat={item?.is_sample_flat}
                      builder_name={
                        item?.property_data?.builder_data?.company_name
                      }
                      architect={item?.company_name}
                      className=""
                    />
                  )}
                </Col>
              ))
            ) : (
              <>
                {projects?.length === 0 && !loading && (
                  <div className="empty-state">
                    <IoFolderOpenOutline size={50} className="icon" />
                    No Projects available at the moment.
                  </div>
                )}
              </>
            )}
          </Row>
        </Container>

        {next && currentLocation === "All Location" && (
          <>
            <Button
              className="d-flex justify-content-center align-items-center text-center border-0 mt-1 p-2"
              style={{
                borderRadius: "50%",
                background: "#F1F3F4",
                marginBottom: `${small ? "2rem" : ""}`,
              }}
              onClick={handleLoadmore}
            >
              <IoIosArrowDown size={20} color="#3C4043" />
            </Button>
          </>
        )}
      </Container>
    </Container>
  );
};

export default Home;