import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Form, InputGroup, Row, Col, Spinner } from 'react-bootstrap';
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

/**
 * Component that renders dynamic product fields based on selected product sub-type
 * 
 * @param {string} productSubType - The ID of the selected product sub-type
 * @param {function} onChange - Function to handle field value changes
 * @param {object} values - Object containing current field values
 * @param {object} invalidFields - Object containing validation errors
 * @param {function} setInvalidFields - Function to update invalid fields
 * @returns {JSX.Element}
 */
const DynamicProductFields = forwardRef(({
    productSubType,
    onChange,
    values = {},
    invalidFields = {},
    setInvalidFields,

}, ref) => {
    const [productFields, setProductFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [units, setUnits] = useState({});

    // useImperativeHandle(ref, () => ({
    //     getFieldUnits: () => {
    //         console.log("Called getFieldUnits, product fields:", productFields);

    //         // Get unique unit IDs from all fields
    //         const unitIds = [...new Set(
    //             productFields
    //                 .filter(field => field.unit)
    //                 .map(field => field.unit)
    //         )];
    //         console.log("Unit IDs extracted:", unitIds);

    //         // Log the units state
    //         console.log("Available units:", units);

    //         // Return the unit objects for these IDs
    //         const unitObjects = unitIds.map(id => units[id]).filter(Boolean);
    //         console.log("Returning unit objects:", unitObjects);
    //         return unitObjects;
    //     }
    // }));

    useImperativeHandle(ref, () => ({
        getFieldUnits: () => {
            console.log("Called getFieldUnits, product fields:", productFields);

            // Safety check to prevent errors when data isn't loaded
            if (!productFields || productFields.length === 0) {
                console.log("No product fields available yet");
                return [];
            }

            if (!units || Object.keys(units).length === 0) {
                console.log("Units data not loaded yet");
                return [];
            }

            // Extract unit IDs from fields
            const unitIds = productFields
                .filter(field => field.unit)
                .map(field => field.unit);

            console.log("Unit IDs from fields:", unitIds);

            // Get unique IDs
            const uniqueUnitIds = [...new Set(unitIds)];
            console.log("Unique unit IDs:", uniqueUnitIds);

            // Map IDs to actual unit objects
            const unitObjects = uniqueUnitIds
                .map(id => units[id])
                .filter(Boolean); // Remove any undefined/null values

            console.log("Returning unit objects:", unitObjects);
            return unitObjects;
        }
    }));

    // Fetch the custom fields for the selected product sub-type
    useEffect(() => {
        const fetchProductFields = async () => {
            if (!productSubType) return;

            setLoading(true);
            try {
                const response = await authActions.fetchProductFields(productSubType);
                if (response?.data) {
                    setProductFields(response.data.results);

                    // Initialize any default values
                    const initialValues = {};
                    response.data.results.forEach(field => {
                        if (!values[`custom_field_${field.id}`] && field.default_value) {
                            initialValues[`custom_field_${field.id}`] = field.default_value;
                        }
                    });

                    if (Object.keys(initialValues).length > 0) {
                        Object.entries(initialValues).forEach(([key, value]) => {
                            onChange({
                                target: {
                                    name: key,
                                    value: value
                                }
                            });
                        });
                    }
                }
            } catch (e) {
                console.log('Error fetching product fields:', e);
                notify('Failed to load custom product fields', 'error');
                setProductFields([]);
            } finally {
                setLoading(false);
            }
        };

        // Fetch units for displaying with fields
        const fetchUnits = async () => {
            try {
                const response = await authActions.fetchUnits();
                if (response?.data) {
                    const unitsMap = {};
                    response.data.forEach(unit => {
                        unitsMap[unit.id] = unit;
                    });
                    setUnits(unitsMap);
                }
            } catch (e) {
                console.log('Error fetching units:', e);
            }
        };

        fetchProductFields();
        fetchUnits();
    }, [productSubType]);

    // Handle changes for custom fields
    const handleCustomFieldChange = (fieldId, value, fieldType) => {
        // Validate input based on field type
        if ((fieldType === 'integerfield' || fieldType === 'floatfield') && value !== '' && value < 0) {
            notify(`Value should not be negative`, "error");
            return;
        }

        onChange({
            target: {
                name: `custom_field_${fieldId}`,
                value
            }
        });

        // Clear validation error for this field if any
        if (invalidFields[`custom_field_${fieldId}`]) {
            setInvalidFields(prev => ({
                ...prev,
                [`custom_field_${fieldId}`]: false
            }));
        }
    };

    // Render the appropriate input based on field type
    const renderFieldInput = (field) => {
        const fieldId = field.id;
        const fieldName = `custom_field_${fieldId}`;
        const fieldValue = values[fieldName] || '';
        const isInvalid = invalidFields[fieldName];
        const unit = field.unit ? units[field.unit] : null;

        switch (field.field_type) {
            case 'integerfield':
                return (
                    <InputGroup>
                        <Form.Control
                            type="number"
                            className="py-2 body-text2"
                            value={fieldValue}
                            onChange={(e) => handleCustomFieldChange(fieldId, e.target.value, field.field_type)}
                            style={{
                                border: isInvalid ? "1px solid red" : "1px solid #BDC1C6",
                                borderRadius: 3
                            }}
                            step="1"
                            min="0"
                        />
                        {unit && (
                            <InputGroup.Text className='body-text1'>{unit.short_code}</InputGroup.Text>
                        )}
                    </InputGroup>
                );

            case 'floatfield':
                return (
                    <InputGroup>
                        <Form.Control
                            type="number"
                            className="py-1 body-text2"
                            value={fieldValue}
                            onChange={(e) => handleCustomFieldChange(fieldId, e.target.value, field.field_type)}
                            style={{
                                border: isInvalid ? "1px solid red" : "1px solid #BDC1C6",
                                borderRadius: 3
                            }}
                            step="0.01"
                            min="0"
                        />
                        {unit && (
                            <InputGroup.Text className='body-text1'>{unit.short_code}</InputGroup.Text>
                        )}
                    </InputGroup>
                );

            case 'choicefield':
                const choices = field.choices ? field.choices.split(',').map(c => c.trim()) : [];
                return (
                    <Form.Select
                        className="py-2 body-text2"
                        value={fieldValue}
                        onChange={(e) => handleCustomFieldChange(fieldId, e.target.value, field.field_type)}
                        style={{
                            border: isInvalid ? "1px solid red" : "1px solid #BDC1C6",
                            borderRadius: 3
                        }}
                    >
                        <option value="">Select</option>
                        {choices.map((choice, idx) => (
                            <option key={idx} value={choice}>
                                {choice}
                            </option>
                        ))}
                    </Form.Select>
                );

            case 'datefield':
                return (
                    <div className="react-datepicker-wrapper">
                        <DatePicker
                            selected={fieldValue ? new Date(fieldValue) : null}
                            onChange={(date) => {
                                if (date) {
                                    // Format date as YYYY-MM-DD using local time instead of UTC
                                    const year = date.getFullYear();
                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                    const day = String(date.getDate()).padStart(2, '0');
                                    const formattedDate = `${year}-${month}-${day}`;

                                    handleCustomFieldChange(fieldId, formattedDate, field.field_type);
                                } else {
                                    handleCustomFieldChange(fieldId, '', field.field_type);
                                }
                            }}
                            dateFormat="dd-MM-yyyy"
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}
                            className={`form-control py-2 body-text2 ${isInvalid ? 'is-invalid' : ''}`}
                            placeholderText="Select date"
                            customInput={
                                <Form.Control
                                    style={{
                                        border: isInvalid ? "1px solid red" : "1px solid #BDC1C6",
                                        borderRadius: 3
                                    }}
                                />
                            }
                        />
                    </div>
                );

            case 'charfield':
            default:
                return (
                    <Form.Control
                        type="text"
                        className="py-2 body-text2"
                        value={fieldValue}
                        onChange={(e) => handleCustomFieldChange(fieldId, e.target.value, field.field_type)}
                        style={{
                            border: isInvalid ? "1px solid red" : "1px solid #BDC1C6",
                            borderRadius: 3
                        }}
                    />
                );
        }
    };

    if (loading) {
        return (
            <div className="text-center py-3">
                <Spinner animation="border" size="sm" className="me-2" />
                <span className="body-text2">Loading product specifications...</span>
            </div>
        );
    }

    if (!productSubType || productFields.length === 0) {
        return null;
    }

    // Group fields by attribute type for better organization
    const groupedFields = productFields.reduce((acc, field) => {
        if (!acc[field.attribute_type]) {
            acc[field.attribute_type] = [];
        }
        acc[field.attribute_type].push(field);
        return acc;
    }, {});

    return (
        <div>
            <p className="inter-600 body-text1 mb-0">Product Specifications</p>
            {Object.entries(groupedFields).map(([attributeType, fields]) => (
                <div key={attributeType} className="mb-4">
                    <Row>
                        {fields.map((field) => (
                            <Col key={field.id} xs={12} md={6} lg={4}>
                                <Form.Group>
                                    <Form.Label className="mb-1 body-text2 inter-500">
                                        {field.label}
                                    </Form.Label>
                                    {renderFieldInput(field)}
                                    {invalidFields[`custom_field_${field.id}`] && (
                                        <Form.Text className="text-danger">
                                            This field is required
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}
        </div>
    );
});

export default DynamicProductFields;