import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import * as authActions from "reduxFiles/actions/authActions"; // Import the authActions
import useFetchMedia from 'utilities/customHooks/useFetchMedia';

const EditProductModal = ({ show, onHide, product, onSave, setProducts }) => {
  // Form state
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [features, setFeatures] = useState('');
  const [price, setPrice] = useState('');
  const [unit, setUnit] = useState('Per Sft');
  const [discountUpto, setDiscountUpto] = useState('');

  // Data for dropdowns
  const [brandOptions, setBrandOptions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');

  const [productCategories, setProductCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState('');

  const [productSubTypeOptions, setProductSubTypeOptions] = useState([]);
  const [selectedProductSubType, setSelectedProductSubType] = useState('');

  // Product fields (specifications)
  const [productFields, setProductFields] = useState([]);
  const [dynamicFieldValues, setDynamicFieldValues] = useState({});

  // Files
  const [newImages, setNewImages] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [deletedImageIds, setDeletedImageIds] = useState([]);
  const [newBrochure, setNewBrochure] = useState(null);
  const [existingBrochure, setExistingBrochure] = useState(null);

  // Loading state
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);

  // Fetch media with the custom hook only when modal is shown and product id exists
  const {
    image: productMedia,
    loading: loadingMedia,
    error: errorMedia
  } = useFetchMedia(
    show && product?.id ? 'product' : null,
    show && product?.id ? 'product' : null,
    show ? product?.id : null,
    true
  );

  const pricingUnits = ["Per Sft", "Per Sqm", "Per Unit"];

  const fetchProducts = async () => {
    try {
      const response = await authActions.fetchProductsPagewise("/product/products/?page=1");
      if (response?.data) {
        setProducts(response.data?.results);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Clear media state when modal closes
  useEffect(() => {
    if (!show) {
      setExistingImages([]);
      setExistingBrochure(null);
    }
  }, [show]);

  // Initialize form with product data when modal is opened
  useEffect(() => {
    if (product && show) {
      resetForm();
      populateFormWithProductData();
      fetchInitialData();
    }
  }, [product, show]);

  // Process media files (separate images and brochure)
  useEffect(() => {
    if (productMedia && !loadingMedia) {
      if (Array.isArray(productMedia)) {
        // Separate brochure and images
        const brochureFile = productMedia.find(item =>
          item.file.toLowerCase().endsWith('.pdf')
        );

        const imageFiles = productMedia.filter(item =>
          !item.file.toLowerCase().endsWith('.pdf')
        );

        // Format the images to match the expected structure
        const formattedImages = imageFiles.map(img => ({
          id: img.id,
          url: img.file
        }));

        setExistingImages(formattedImages);

        // Set brochure if found
        if (brochureFile) {
          setExistingBrochure(brochureFile.file);
        }
      }
    }
  }, [productMedia, loadingMedia]);

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setFeatures('');
    setPrice('');
    setUnit('Per Sft');
    setDiscountUpto('');
    setSelectedBrand('');
    setSelectedCategory('');
    setSelectedProductType('');
    setSelectedProductSubType('');
    setDynamicFieldValues({});
    setNewImages([]);
    setExistingImages([]);
    setDeletedImageIds([]);
    setNewBrochure(null);
    setExistingBrochure(null);
  };

  const populateFormWithProductData = () => {
    if (!product) return;

    // Set basic product information
    setTitle(product.title || '');
    setDescription(product.description || '');
    setFeatures(product.features || '');
    setDiscountUpto(product.discount_upto || '');

    // Parse cost field if it exists (format: "100 Per Sft")
    if (product.cost) {
      const [priceValue, unitValue] = parseCost(product.cost);
      setPrice(priceValue);
      setUnit(unitValue || 'Per Sft');
    }

    // Set selected values for dropdowns
    setSelectedBrand(product.brand || '');
    setSelectedCategory(product.product_category || '');
    setSelectedProductType(product.product_type || '');
    setSelectedProductSubType(product.product_sub_type || '');

    // Set dynamic field values if they exist
    if (product.product_fields) {
      setDynamicFieldValues(product.product_fields);
    }
  };

  const parseCost = (costStr) => {
    if (!costStr) return ['', 'Per Sft'];

    const index = costStr.indexOf(" ");
    if (index !== -1) {
      const price = costStr.slice(0, index);
      const unit = costStr.slice(index + 1); // everything after the first space
      return [price, unit];
    }
    return [costStr, "Per Sft"];
  };

  // Fetch all necessary data for the form
  const fetchInitialData = async () => {
    setIsFetchingData(true);
    try {
      await Promise.all([
        fetchProductCategories()
      ]);

      // If we already have category, type, and subtype, fetch their dependent options
      if (product.product_category) {
        await fetchProductTypes(product.product_category);

        if (product.product_type) {
          await fetchProductSubTypes(product.product_type);

          if (product.product_sub_type) {
            await fetchProductFields(product.product_sub_type);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    } finally {
      setIsFetchingData(false);
    }
  };

  const fetchProductCategories = async () => {
    try {
      const response = await axios.get(`/product/product-category/categories_no_pagination/`);
      if (response.status === 200) {
        setProductCategories(response.data);
        return response.data;
      }
      return [];
    } catch (error) {
      console.error("Error fetching product categories:", error);
      return [];
    }
  };

  const fetchProductTypes = async (categoryId) => {
    if (!categoryId) return [];

    try {
      const response = await axios.get(`/product/product-types/${categoryId}/producttypes_no_pagination/`);
      if (response.status === 200) {
        setProductTypeOptions(response.data);
        return response.data;
      }
      return [];
    } catch (error) {
      console.error("Error fetching product types:", error);
      return [];
    }
  };

  const fetchProductSubTypes = async (typeId) => {
    if (!typeId) return [];

    try {
      const response = await axios.get(`/product/product-sub-types/${typeId}/productsubtypes_no_pagination/`);
      if (response.status === 200) {
        setProductSubTypeOptions(response.data);
        return response.data;
      }
      return [];
    } catch (error) {
      console.error("Error fetching product subtypes:", error);
      return [];
    }
  };

  const fetchProductFields = async (subTypeId) => {
    if (!subTypeId) return [];

    try {
      const response = await axios.get(`/product/product-fields/?sub_type=${subTypeId}`);
      if (response.status === 200) {
        setProductFields(response.data.results || []);
        return response.data.results || [];
      }
      return [];
    } catch (error) {
      console.error("Error fetching product fields:", error);
      return [];
    }
  };

  const handleCategoryChange = async (e) => {
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);

    // Reset dependent dropdowns
    setSelectedProductType('');
    setSelectedProductSubType('');
    setProductTypeOptions([]);
    setProductSubTypeOptions([]);
    setProductFields([]);
    setDynamicFieldValues({});

    if (categoryId) {
      await fetchProductTypes(categoryId);
    }
  };

  const handleProductTypeChange = async (e) => {
    const typeId = e.target.value;
    setSelectedProductType(typeId);

    // Reset dependent dropdowns
    setSelectedProductSubType('');
    setProductSubTypeOptions([]);
    setProductFields([]);
    setDynamicFieldValues({});

    if (typeId) {
      await fetchProductSubTypes(typeId);
    }
  };

  const handleProductSubTypeChange = async (e) => {
    const subTypeId = e.target.value;
    setSelectedProductSubType(subTypeId);

    // Reset dynamic fields
    setProductFields([]);
    setDynamicFieldValues({});

    if (subTypeId) {
      await fetchProductFields(subTypeId);
    }
  };

  const handleDynamicFieldChange = (fieldId, value) => {
    setDynamicFieldValues(prev => ({
      ...prev,
      [fieldId]: value
    }));
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setNewImages(prev => [...prev, ...files]);
  };

  const handleRemoveNewImage = (index) => {
    setNewImages(prev => prev.filter((_, i) => i !== index));
  };

  const handleRemoveExistingImage = (imageId) => {
    setExistingImages(prev => prev.filter(img => img.id !== imageId));
    setDeletedImageIds(prev => [...prev, imageId]);
  };

  const handleBrochureUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewBrochure(file);
    }
  };

  const handleRemoveBrochure = () => {
    setNewBrochure(null);
    setExistingBrochure(null);
  };

  // Modify the handleSubmit method in the EditProductModal component
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title) {
      alert("Product title is required");
      return;
    }

    setIsLoading(true);

    try {
      // Prepare form data for submission
      const formData = new FormData();

      // Add basic fields
      formData.append('title', title);
      formData.append('description', description || '');
      formData.append('features', features || '');
      formData.append('discount_upto', discountUpto || '');
      formData.append('brand', selectedBrand);
      formData.append('product_category', selectedCategory);
      formData.append('product_type', selectedProductType);
      formData.append('product_sub_type', selectedProductSubType);

      // Add cost (price + unit)
      if (price) {
        formData.append('cost', `${price} ${unit}`);
      }

      // Correctly handle product fields by flattening the object
      Object.keys(dynamicFieldValues).forEach(fieldId => {
        const value = dynamicFieldValues[fieldId];
        // Append each field as product_fields[fieldId]
        formData.append(`product_fields[${fieldId}]`,
          value !== null && value !== undefined
            ? String(value)
            : ''
        );
      });

      // Add new images
      newImages.forEach((image, index) => {
        formData.append(`image-${index}`, image);
      });

      // Add deleted image IDs
      if (deletedImageIds.length > 0) {
        formData.append('deleteImages', JSON.stringify(deletedImageIds));
      }

      // Add new brochure if it exists
      if (newBrochure) {
        formData.append('brochure', newBrochure);
      }

      // If existing brochure was removed but no new one added
      if (!existingBrochure && !newBrochure) {
        formData.append('remove_brochure', 'true');
      }

      // Call the onSave prop with the form data
      await onSave(product.id, formData);
      fetchProducts()
      onHide();
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Failed to update product. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Render dynamic fields based on product subtype
  const renderDynamicFields = () => {
    if (!productFields.length) return null;

    return (
      <>
        <h5 className="mt-4 mb-3">Product Specifications</h5>
        {productFields.map((field) => {
          const fieldValue = dynamicFieldValues[field.id] || "";

          let fieldInput;

          switch (field.field_type) {
            case 'charfield':
              fieldInput = (
                <Form.Control
                  type="text"
                  value={fieldValue}
                  onChange={(e) => handleDynamicFieldChange(field.id, e.target.value)}
                  placeholder={`Enter ${field.label}`}
                />
              );
              break;

            case 'integerfield':
              fieldInput = (
                <Form.Control
                  type="number"
                  step="1"
                  value={fieldValue}
                  onChange={(e) => handleDynamicFieldChange(field.id, e.target.value)}
                  placeholder={`Enter ${field.label}`}
                />
              );
              break;

            case 'floatfield':
              fieldInput = (
                <Form.Control
                  type="number"
                  step="0.01"
                  value={fieldValue}
                  onChange={(e) => handleDynamicFieldChange(field.id, e.target.value)}
                  placeholder={`Enter ${field.label}`}
                />
              );
              break;

            case 'choicefield':
              fieldInput = (
                <Form.Select
                  value={fieldValue}
                  onChange={(e) => handleDynamicFieldChange(field.id, e.target.value)}
                >
                  <option value="">Select {field.label}</option>
                  {field.choices_list?.map((choice) => (
                    <option key={choice} value={choice}>{choice}</option>
                  ))}
                </Form.Select>
              );
              break;

            case 'datefield':
              fieldInput = (
                <Form.Control
                  type="date"
                  value={fieldValue}
                  onChange={(e) => handleDynamicFieldChange(field.id, e.target.value)}
                />
              );
              break;

            default:
              fieldInput = (
                <Form.Control
                  type="text"
                  value={fieldValue}
                  onChange={(e) => handleDynamicFieldChange(field.id, e.target.value)}
                  placeholder={`Enter ${field.label}`}
                />
              );
          }

          return (
            <Form.Group key={field.id} className="mb-3">
              <Form.Label>
                {field.label}
                {field.unit_short_code ? ` (${field.unit_short_code})` : ''}
              </Form.Label>
              {fieldInput}
            </Form.Group>
          );
        })}
      </>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="edit-product-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="edit-product-modal">
          Edit Product: {product?.title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isFetchingData || loadingMedia ? (
          <div className="text-center py-4">
            <FaSpinner className="fa-spin" size={24} />
            <p className="mt-2">Loading product data...</p>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Product Name*</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter product name"
                required
              />
            </Form.Group>

            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Product Category*</Form.Label>
                  <Form.Select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    required
                  >
                    <option value="">Select Category</option>
                    {productCategories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Product Type*</Form.Label>
                  <Form.Select
                    value={selectedProductType}
                    onChange={handleProductTypeChange}
                    required
                    disabled={!selectedCategory}
                  >
                    <option value="">Select Product Type</option>
                    {productTypeOptions.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Product Subtype*</Form.Label>
                  <Form.Select
                    value={selectedProductSubType}
                    onChange={handleProductSubTypeChange}
                    required
                    disabled={!selectedProductType}
                  >
                    <option value="">Select Product Subtype</option>
                    {productSubTypeOptions.map((subType) => (
                      <option key={subType.id} value={subType.id}>
                        {subType.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter product description"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Features</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={features}
                onChange={(e) => setFeatures(e.target.value)}
                placeholder="Enter product features"
              />
            </Form.Group>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Price</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>₹</InputGroup.Text>
                    <Form.Control
                      type="number"
                      min="0"
                      step="0.01"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      placeholder="Enter price"
                    />
                    <Form.Select
                      value={unit}
                      onChange={(e) => setUnit(e.target.value)}
                    >
                      {pricingUnits.map((unitOption, index) => (
                        <option key={index} value={unitOption}>
                          {unitOption}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Discount Upto (%)</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    max="100"
                    value={discountUpto}
                    onChange={(e) => setDiscountUpto(e.target.value)}
                    placeholder="Enter discount percentage"
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Dynamic fields based on product subtype */}
            {renderDynamicFields()}

            <Form.Group className="mb-3 mt-4">
              <Form.Label>Product Images</Form.Label>

              {/* Display existing images */}
              {existingImages.length > 0 && (
                <div className="mb-3">
                  <p className="text-muted small">Existing Images:</p>
                  <div className="d-flex flex-wrap gap-2">
                    {existingImages.map((img) => (
                      <div key={img.id} className="position-relative border rounded p-1">
                        <img
                          src={img.url}
                          alt="Product"
                          className="img-thumbnail"
                          style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                        />
                        <Button
                          variant="danger"
                          size="sm"
                          className="position-absolute top-0 end-0"
                          onClick={() => handleRemoveExistingImage(img.id)}
                        >
                          &times;
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Display new images to be uploaded */}
              {newImages.length > 0 && (
                <div className="mb-3">
                  <p className="text-muted small">New Images to Upload:</p>
                  <div className="d-flex flex-wrap gap-2">
                    {newImages.map((img, index) => (
                      <div key={index} className="position-relative border rounded p-1">
                        <img
                          src={URL.createObjectURL(img)}
                          alt="Product"
                          className="img-thumbnail"
                          style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                        />
                        <Button
                          variant="danger"
                          size="sm"
                          className="position-absolute top-0 end-0"
                          onClick={() => handleRemoveNewImage(index)}
                        >
                          &times;
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <Form.Control
                type="file"
                accept="image/*"
                multiple
                onChange={handleImageUpload}
              />
              <Form.Text className="text-muted">
                Upload additional product images
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Brochure (PDF)</Form.Label>

              {/* Display existing brochure */}
              {existingBrochure && !newBrochure && (
                <div className="d-flex align-items-center mb-2 gap-2">
                  <a href={existingBrochure} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary">
                    View Current Brochure
                  </a>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={handleRemoveBrochure}
                  >
                    Remove
                  </Button>
                </div>
              )}

              {/* Display new brochure to be uploaded */}
              {newBrochure && (
                <div className="d-flex align-items-center mb-2 gap-2">
                  <span className="badge bg-secondary p-2">{newBrochure.name}</span>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={handleRemoveBrochure}
                  >
                    Remove
                  </Button>
                </div>
              )}

              {(!existingBrochure || newBrochure) && (
                <Form.Control
                  type="file"
                  accept="application/pdf"
                  onChange={handleBrochureUpload}
                />
              )}
              <Form.Text className="text-muted">
                Upload a PDF brochure for this product (max 100MB)
              </Form.Text>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={isLoading || isFetchingData || loadingMedia}
        >
          {isLoading ? (
            <>
              <FaSpinner className="fa-spin me-2" />
              Saving Changes...
            </>
          ) : (
            'Update Product'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProductModal;