import { useEffect, useState } from "react";
import * as authActions from "reduxFiles/actions/authActions";

const useFetchMedia = (entityType, subEntityType, entityId, hasMultipleImg) => {
    const [image, setImage] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMedia = async () => {
            try {
                const response = await authActions.getMediaFiles(
                    entityType,
                    subEntityType,
                    entityId
                );

                if (response.length > 0) {
                    if (hasMultipleImg) {
                        setImage(response);
                    } else {
                        setImage(response[0]?.file);
                    }

                } else {
                    setImage("");
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        if (entityId) {
            fetchMedia();
        }
    }, [entityType, subEntityType, entityId]);
    return { image, loading, error };
};

export default useFetchMedia;
