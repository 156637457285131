import { useEffect, useRef } from "react";

const useInactivityTimer = (logoutCallback, timeout = 30 * 60 * 1000, isAuthenticated) => {
  const timerRef = useRef(null); 

  const resetTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);

    if (isAuthenticated) {
      timerRef.current = setTimeout(() => {
        logoutCallback();
      }, timeout);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      if (timerRef.current) clearTimeout(timerRef.current);
      return;
    }

    const handleActivity = () => {
      resetTimer();
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("scroll", handleActivity);

    resetTimer(); // Start timer

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [isAuthenticated]); 

  return resetTimer;
};

export default useInactivityTimer;
