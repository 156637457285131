import { project_drawing_only, turnkey_solution } from "assets";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FaRegStar } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notify } from "utilities/notifications/Notify";

const ConsultationRequirement = ({ serviceType, setServiceType }) => {
  const navigate = useNavigate();
  const { serviceTypeGlobal } = useSelector((state) => state.project);

  const solutions = [
    {
      id: 1,
      image: turnkey_solution,
      title: "Turnkey (Complete) Interior Solution",
      slug: "turnkey",
      description:
        "Complete hassle-free execution of your home interiors from ideation to handover, while maintaining transparency every step of the way.",
      turnkey: true,
    },
    {
      id: 2,
      image: project_drawing_only,
      title: "Project Drawings Only",
      slug: "customised",
      description:
        "Final construction drawings and architect support till release of final drawings. Project execution not included.",
      turnkey: false,
    },
  ];

  const handleContinueClick = () => {
    if (!serviceTypeGlobal && !serviceType) {
      notify("Please select a service type before continuing.");
      return;
    }
    navigate(`/consultation#${serviceType || serviceTypeGlobal}`);
  };

  return (
    <Row className={`d-flex flex-wrap my-2 mb-0`}>
      {/* <div className="text-gray-900 body-text2 inter-500 mb-1">Selected Service</div> */}
      {solutions.map((solution) => {
        return (
          <Col lg={6} className="mb-3 mb-lg-0">
            <Card
              key={solution.id}
              className={`p-0 shadow-none ${
                serviceType === solution.slug ? "" : "cursor-pointer"
              }`}
              style={{
                position: "relative",
                height: "100%",
                borderRadius: 6,
                border:
                  serviceType === solution.slug ||
                  serviceTypeGlobal === solution.slug
                    ? "1.5px solid #C53D3D"
                    : "1px solid #E8EAED",
              }}
              onClick={() => {
                setServiceType(solution.slug);
              }}
            >
              {/* {solution.turnkey && <Button
                className="d-flex align-items-center position-absolute text-gray-900 bg-info border-0 inter-600"
                style={{ top: "10px", left: "10px" }}
              >
                <FaRegStar className="me-1" /> Top Rated
              </Button>} */}
              {!(serviceType === solution.slug) && (
                <div
                  className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center"
                  style={{ borderRadius: 6 }}
                />
              )}
              <Card.Img
                className="img-fluid"
                variant="top"
                src={solution.image}
                style={{
                  height: 200,
                  objectFit: "cover",
                  borderRadius: "6px 6px 0 0",
                }}
              />
              <Card.Body
                className="d-flex justify-content-center py-2"
                style={{
                  color: serviceType === solution.slug ? "#C53D3D" : "",
                }}
              >
                <div className="body-text1 inter-500">{solution.title}</div>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
      <div className="mt-0 mt-lg-4 d-flex">
        <Button
          className="d-flex align-items-center text-white inter-500 body-text2 me-2"
          style={{ borderRadius: 3 }}
          onClick={handleContinueClick}
        >
          Continue
        </Button>
      </div>
    </Row>
  );
};

export default ConsultationRequirement;
