import { career_women, careers_page_1, collaboration, quality, security } from "assets";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";

const CareerPage = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const values = [
    {
      icon: `${collaboration}`,
      title: "Collaboration & Connection",
      description: <>Facilitate meaningful collaboration among architects, <br /> interior designers, builders, and brands to seamlessly<br /> deliver high-quality interior designs to homeowners.</>
    },
    {
      icon: `${security}`,
      title: "Transparency & Trust",
      description: <>Ensure accuracy and transparency in design, product,<br /> and property information while fostering fair and clear<br /> interactions between professionals and homeowners.</>
    },
    {
      icon: `${quality}`,
      title: "Quality & Excellence",
      description: <>Prioritize high-quality inspirations, verified professionals, {!small && <br />} and premium products while maintaining exceptional<br /> standards in project showcases and product listings.</>
    }
  ];

  const jobData = {
    title: "Business Development Manager",
    type: "Full time",
    location: "In-Office",
    city: "Jaipur, India",
    description: "Drive growth by building partnerships, expanding market presence, and strengthening relationships with architects, designers, and brands at InteriorKeys.",
    department: "Business"
  };

  const jobs = [jobData, jobData];

  return (
    <Container
      fluid
      className="mt-5 px-0"
      style={{ minHeight: "80vh" }}
    >
      <Container
        fluid
        className="py-5 px-0 d-flex flex-column justify-content-center align-items-center text-center first_section_home_page"
        style={{
          minHeight: "20vh",
          // padding: !small ? "26px 16px" : "0px",
        }}
      >
        <Container className="px-2 px-md-6">
          <div className="h1 px-lg-5 pt-5 inter-600 text-gray-900">
            Careers
          </div>
          <div className="text-gray-700 body-title inter-400 my-3">
            <span>InterioKeys is building the future of the interior design industry, and we're proud to say there's no one quite like us.</span>
            {large && <br />}
            <span> Here’s what it’s like to work with us!</span>
          </div>
        </Container>
      </Container>
      <Container className="my-5 py-5 px-2">
        <Row className="align-items-center">
          <Col xs={12} md={6} className="mx-0">
            <Image
              src={careers_page_1}
              alt="Team celebrating with raised hands"
              fluid
              className="shadow-sm"
              style={{
                borderRadius: '6px'
              }}
            />
          </Col>
          <Col xs={12} md={6} className="mt-4 mt-md-0 px-2 px-md-0">
            <h2 className="h3 inter-600 test-gray-900 px-2 px-lg-0">Our Mission and Culture</h2>
            <p className="body-title inter-400 text-gray-600 px-2 px-lg-0">
              InteriorKeys aims to empower architects, interior designers, builders, and
              brands by providing a collaborative platform that streamlines the design
              inspiration, product showcase, and product discovery process. It
              bridges the gap between professionals and consumers, helping them
              create and access high-quality interior inspirations while enabling
              seamless project execution and product sourcing.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="my-5 py-5 mx-0 px-0 px-md-5 bg-brand-25 d-flex flex-column justify-content-center align-items-center">
        <span className="h3 inter-600 text-gray-900 mb-5">Our Values</span>
        <Row className="w-100 mx-0">
          {values.map((value, index) => (
            <Col md={4} className="text-center mb-4 mb-md-0">
              <div className="d-flex flex-column align-items-center">
                <div className="rounded-circle bg-danger bg-opacity-10 p-2 mb-4">
                  <Image src={value.icon} size={60} />
                </div>
                <p className="h3 inter-600 text-gray-900">{value.title}</p>
                <p className="body-title inter-400 text-gray-600">{value.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Container className="my-5 py-5 px-2 px-md-0">
        <Row className="align-items-center mx-0">
          <Col xs={12} md={6}>
            <Image
              src={career_women}
              alt="Women seated in front of Laptop"
              fluid
              className="shadow-sm"
              style={{
                borderRadius: '6px'
              }}
            />
          </Col>
          <Col xs={12} md={6} className="mt-4 mt-md-0">
            <h2 className="h3 inter-600 test-gray-900">Supporting your success</h2>
            <p className="body-title inter-400 text-gray-600">
              At InterioKeys, we believe that our team's success drives the company's success. We foster a collaborative, innovative, and growth-focused work environment where your ideas matter, and your skills can flourish. With opportunities for continuous learning, professional development, and impactful contributions, we ensure that every team member thrives while shaping the future of interior design and architecture.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="px-0 bg-gray-25">
        <Container fluid className="p-5 d-flex flex-column align-items-center">
          <span className="h1 inter-600 text-gray-900">Open Roles {!small && <br />} @ InterioKeys</span>
          <span className="body-title inter-400 text-gray-500 text-center">Join InterioKeys and be part of a collaborative, innovative, and growth-driven environment. Work {small && <br />} alongside industry experts and shape the future of interior design and architecture.</span>
        </Container>
        <Container fluid className="p-5 mb-4">
          <span className="h3 inter-600 text-gray-900 pb-4">Business</span>
          <Row className="pt-4 mx-0">
            {jobs.map((job, index) => (
              <Card className="mb-4" style={{
                padding: '12px',
                borderRadius: '9px',
                border: '1px solid #e8eaed'
              }}>
                <Card.Body className="p-0">
                  <div className="d-flex justify-content-between align-items-start mb-2">
                    <div>
                      <Card.Title className="h4 inter-600 text-gray-900 mb-2">{job.title}</Card.Title>
                      <div className="d-flex gap-2 body-text1 text-gray-600 inter-t00">
                        <span>{job.type}</span>
                        <span>•</span>
                        <span>{job.location}</span>
                        <span>•</span>
                        <span>{job.city}</span>
                      </div>
                    </div>
                    <span className="body-title text-gray-600 inter-500">{job.department}</span>
                  </div>

                  <Card.Text className="d-flex flex-column body-title text-gray-600 inter-400 mb-3">
                    <span> Role Description:</span>
                    <span>{job.description}</span>
                  </Card.Text>

                  <div className="d-flex justify-content-end mt-auto">
                    <Button
                      className="d-flex align-items-center gap-2 inter-500 body-text1 bg-brand-500"
                      style={{
                        padding: '8px 14px'
                      }}
                    >
                      Apply
                      <BsArrowRight size={16} />
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </Row>
        </Container>
        <Container fluid className="p-5">
          <span className="h3 inter-600 text-gray-900 pb-4">Sales</span>
          <Row className="pt-4 mx-0">
            {jobs.map((job, index) => (
              <Card className="mb-4" style={{
                padding: '12px',
                borderRadius: '9px',
                border: '1px solid #e8eaed'
              }}>
                <Card.Body className="p-0">
                  <div className="d-flex justify-content-between align-items-start mb-2">
                    <div>
                      <Card.Title className="h4 inter-600 text-gray-900 mb-2">{job.title}</Card.Title>
                      <div className="d-flex gap-2 body-text1 text-gray-600 inter-t00">
                        <span>{job.type}</span>
                        <span>•</span>
                        <span>{job.location}</span>
                        <span>•</span>
                        <span>{job.city}</span>
                      </div>
                    </div>
                    <span className="body-title text-gray-600 inter-500">{job.department}</span>
                  </div>

                  <Card.Text className="d-flex flex-column body-title text-gray-600 inter-400 mb-3">
                    <span> Role Description:</span>
                    <span>{job.description}</span>
                  </Card.Text>

                  <div className="d-flex justify-content-end mt-auto">
                    <Button
                      className="d-flex align-items-center gap-2 inter-500 body-text1 bg-brand-500"
                      style={{
                        padding: '8px 14px'
                      }}
                    >
                      Apply
                      <BsArrowRight size={16} />
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </Row>
        </Container>
      </Container>
    </Container>
  );
};

export default CareerPage;
