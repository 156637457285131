import React, { useEffect, useRef, useState } from "react";
import "./drop-file-input.css";
import { FaUpload, FaXmark } from "react-icons/fa6";
import { RiUpload2Fill } from "react-icons/ri";
import { notify } from "utilities/notifications/Notify";

const DropFileInputProduct = ({
  onFileChange,
  fileList = [],
  allowMultiple = true,
  showFiles = true,
  className,
  style,
}) => {
  const wrapperRef = useRef(null);

  // const [fileList, setFileList] = useState([]);
  const [localFileList, setLocalFileList] = useState(fileList);

  useEffect(() => {
    setLocalFileList(fileList); // Sync with parent prop when it changes
  }, [fileList]);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  /* const onFileDrop = (e) => {
    const temp = allowMultiple ? e.target.files : [e.target.files[0]];
    if (temp) {
      const updatedList = [...localFileList, ...temp];
      setLocalFileList(updatedList);
      onFileChange(updatedList);
    }
  }; */

  const onFileDrop = (e) => {
    const temp = allowMultiple
      ? Array.from(e.target.files)
      : [e.target.files[0]];
    if (!temp) return;

    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 5 * 1024 * 1024; // 5MB

    const validFiles = [];
    let errorMessage = "";

    temp.forEach((file) => {
      if (!allowedTypes.includes(file.type)) {
        errorMessage = "Only JPG, JPEG, and PNG files are allowed.";
      } else if (file.size > maxSize) {
        errorMessage = "Image size must be less than 5MB.";
      } else {
        validFiles.push(file);
      }
    });

    if (errorMessage) {
      notify(errorMessage); 
      return;
    }

    const updatedList = [...localFileList, ...validFiles];
    setLocalFileList(updatedList);
    onFileChange(updatedList);
  };

  const fileRemove = (file) => {
    const updatedList = [...localFileList];
    updatedList.splice(localFileList.indexOf(file), 1);
    setLocalFileList(updatedList);
    onFileChange(updatedList);
  };

  return (
    <>
      {((!allowMultiple && fileList.length == 0) || allowMultiple) && (
        <div
          ref={wrapperRef}
          className={`drop-file-input position-relative d-flex justify-content-center align-items-center border border-dashed ${className}`}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          style={{
            width: "100%",
            height: 240,
            borderRadius: 8,
            ...style,
          }}
        >
          <div className="drop-file-input__label d-flex flex-column justify-content-center align-items-center body-text2">
            <RiUpload2Fill size={24} className="text-dark mb-3" />
            <p>
              Drag & Drop your files here or{" "}
              <u className="text-primary">click to upload</u>
            </p>
          </div>
          <input
            type="file"
            value=""
            multiple={allowMultiple}
            onChange={onFileDrop}
            className="cursor-pointer position-absolute top-0 start-0 w-100 h-100"
            style={{ opacity: 0 }}
            accept="image/*"
          />
        </div>
      )}
      {showFiles && fileList.length > 0 ? (
        <div className="drop-file-preview mt-3">
          {fileList.map((item, index) => (
            <div
              key={index}
              className="drop-file-preview__item d-flex justify-content-between align-items-start w-100"
            >
              {/* <img src={ ImageConfig[item.type.split("/")[1]] || ImageConfig["default"] } alt="" style={{ width: 50}} /> */}
              {/* <FaUpload size={24} className="text-dark" /> */}
              <div
                className="rounded overflow-hidden border me-2"
                style={{ height: 76, maxWidth: 100 }}
              >
                <img
                  src={item ? URL.createObjectURL(item) : null}
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover" }}
                />
              </div>
              <div className="w-100">{item.name}</div>
              {/* <p>{item.size / 1000}kb</p> */}
              <div
                // className="drop-file-preview__item__del border"
                onClick={() => fileRemove(item)}
                className="drop-file-preview__item__del cursor-pointer bg-gray text-center rounded-circle ms-2"
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: 40, height: 40 }}
                >
                  <div className="w-100 mb-1">
                    <FaXmark size={18} className="text-dark" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default DropFileInputProduct;
