import * as authActions from "reduxFiles/actions/authActions";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Image, Row } from "react-bootstrap";
import { notify } from "utilities/notifications/Notify";
import { Modal } from "react-bootstrap";
import userImg from "../../assets/navbar/user.webp";

function BrandEnquiryForm({ brandId, show, onHide, setShow }) {
  const form = useRef();
  const [requestType, setRequestType] = useState(null);
  const [brand, setBrand] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false); // State to track if the full text is shown

  const fetchBrand = async () => {
    try {
      const response = await authActions.fetchBrandInfo(brandId);
      if (response.data) {
        setBrand(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (brandId) {
      fetchBrand();
    }
  }, [brandId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(form.current);
    formData.append("brand", brand?.user_data?.id);
    formData.append("request_type", requestType);
    let data = {};
    formData.forEach((value, key) => (data[key] = value));
    console.log(data);
    try {
      const response = await authActions.addBrandEnquiry(data);
      if (response.data) {
        notify("Enquiry Sent!", "success");
        setRequestType(null);
        onHide();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // Function to toggle the expanded state
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  // Approximate character limit for two lines (adjust based on your design)
  const CHAR_LIMIT = 100; // Adjust based on your font size and container width

  // Check if the brand_thoughts text exceeds the character limit
  const isLongText = brand?.brand_thoughts?.length > CHAR_LIMIT;

  return (
    <Modal
      show={show}
      className="overflow-hidden"
      dialogClassName="shareModal"
      onHide={onHide}
    >
      <Modal.Body
        className="p-2"
        style={{
          maxHeight: "70vh", // Set a maximum height (70% of viewport height)
          overflowY: "auto", // Enable vertical scrolling
        }}
      >
        <Container>
          <Row className="py-3">
            <Col xs={3}>
              <Card.Img
                src={brand?.image || userImg}
                alt="Product Image"
                className="rounded"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Col>
            <Col xs={9}>
              <h5>{brand?.company_name}</h5>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  className="text-muted mb-0"
                  style={{
                    maxHeight: isExpanded ? "none" : "3em",
                    lineHeight: "1.5em",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: isExpanded ? "none" : 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {brand?.brand_thoughts}
                </p>
                {isLongText && (
                  <span
                    onClick={toggleReadMore}
                    style={{
                      color: "blue",
                      cursor: "pointer",
                      marginTop: "5px",
                      alignSelf: "flex-start",
                    }}
                  >
                    {isExpanded ? "Read Less" : "Read More"}
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Form ref={form} onSubmit={handleSubmit}>
            <Form.Group controlId="formName" className="body-text2 inter-600 mb-2">
              <Form.Label>
                Name<sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Full name here.."
                name="name"
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="body-text2 inter-600 mb-2">
              <Form.Label>
                Company Email<sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="yourcompanyname@email.com"
                name="email"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPincode" className="body-text2 inter-600 mb-2">
              <Form.Label>
                Pincode<sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="000000"
                name="pincode"
                maxLength="6"
                required
                pattern="[0-9]{6}"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                }}
              />
            </Form.Group>

            <Form.Group controlId="formPhone" className="body-text2 inter-600 mb-2">
              <Form.Label>
                Phone Number<sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="tel"
                placeholder="9123456780"
                name="phone"
                pattern="[0-9]{10}"
                maxLength="10"
                required
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                }}
              />
            </Form.Group>

            <Form.Group className="body-text2 inter-600 mb-2">
              <Form.Label>
                Request Type<sup className="text-danger">*</sup>
              </Form.Label>
              <div>
                <Button
                  variant={requestType === "Quote" ? "primary" : "info"}
                  onClick={() => setRequestType("Quote")}
                  className="me-2"
                >
                  Price/Quote
                </Button>
                <Button
                  variant={requestType === "Catalogue" ? "primary" : "info"}
                  onClick={() => setRequestType("Catalogue")}
                  className="me-2"
                >
                  Catalogue
                </Button>
                <Button
                  variant={requestType === "Information" ? "primary" : "info"}
                  onClick={() => setRequestType("Information")}
                >
                  Information
                </Button>
              </div>
            </Form.Group>

            <Form.Group controlId="formMessage" className="body-text2 inter-600 mb-2">
              <Form.Label>
                Message<sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Hello BoConcept, I would like...."
                name="message"
                required
                style={{ resize: "none" }}
              />
            </Form.Group>

            <Button variant="danger" type="submit" className="my-3 w-100" disabled={loading}>
              {loading ? "Sending..." : "Send Enquiry"}
            </Button>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default BrandEnquiryForm;