import { useEffect, useRef, useState } from "react";
import * as authActions from "reduxFiles/actions/authActions";
import ProjectCard from "components/Cards/ProjectCard";
import SpaceCategoryCard from "components/Cards/SpaceCategoryCard";
import {
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ShareModal from "components/General/ShareModal";
import { general_inspirations, project_switch_card } from "assets";

import { FiRefreshCcw } from "react-icons/fi";
import { CgSortAz } from "react-icons/cg";
import { IoClose } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { useScrollBottom, InfiniteScroll } from "components";
import MobileProjectCard from "components/Cards/MobileProjectCard";
import AllProfessionalCard from "components/Cards/AllProfessionalCard";
import ProfessionalCard from "components/Cards/ProfessionalCard";
import BrandCard from "components/Cards/BrandCard";
import ProfessionalDetails from "pages/Professionals/ProfessionalDetails";
import { FaBuilding, FaChevronDown, FaChevronUp, FaFilter, FaFolderOpen, FaHammer, FaLightbulb } from "react-icons/fa6";
import Slider from "react-slick";
import ProjectCategoryCard from "components/Cards/ProjectCategoryCard";
import ProjectCardShimmer from "components/Cards/ProjectCardShimmer";
import ShimmerSlider from "pages/Home/ShimmerSlider";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentLocation } from "reduxFiles/reducer/locationSlice";
import { FaDraftingCompass } from "react-icons/fa";

const ProjectList = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const selectedLocation = useSelector(
    (state) => state.location.currentLocation
  );

  // const xsmall = useMediaQuery({ query: "(min-width: 400px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });
  // const xxxlarge = useMediaQuery({ query: "(min-width: 1600px)" });

  let params = new URLSearchParams(document.location.search);
  let projectType = params.get("project_type");
  let property = params.get("property");
  let project_name = params.get("project_name")
  let bhk = params.get("bhk");
  let sortBy = params.get("sort_by");
  let location =
    params.get("location") ||
    (selectedLocation === "All Location" ? "" : selectedLocation);
  let professionals = params.get("professionals");
  let search = params.get("search");
  let isGeneralInspiration = params.get("is_general_inspiration");
  const dropdownRef = useRef(null);
  const [switchPage, setSwitchPage] = useState(false);
  const [properties, setProperties] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [reviews, setReviews] = useState([]);

  const [showShareProject, setShowShareProject] = useState(false);
  const [professionalsResult, setProfessionalsResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPropertyName, setSelectedPropertyName] = useState("");
  const [viewMode, setViewMode] = useState("projects");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const [noNextItem, setNoNextItem] = useState(true);
  const bottom = useScrollBottom();

  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasFetchedProducts, setHasFetchedProducts] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const layouts = [
    { id: 1, label: "2 BHK", value: "2BHK" },
    { id: 2, label: "2.5 BHK", value: "2.5BHK" },
    { id: 3, label: "3 BHK", value: "3BHK" },
    { id: 4, label: "3.5 BHK", value: "3.5BHK" },
    { id: 5, label: "4 BHK", value: "4BHK" },
    { id: 7, label: "5 BHK", value: "5BHK" },
    { id: 9, label: "6 BHK", value: "6BHK" },
    { id: 11, label: "7 BHK", value: "7BHK" },
    { id: 13, label: "8 BHK", value: "8BHK" },
  ];

  const filteredProperties = properties.filter((property) =>
    property.property_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (isGeneralInspiration === "true") {
      setViewMode("inspirations");
    } else {
      setViewMode("projects"); // Default view
    }
  }, [isGeneralInspiration]);

  const handleViewModeChange = (mode) => {
    if (mode === "spaces") {
      navigate("/spaces");
    } else {
      // For switching between projects and inspirations
      const currentParams = new URLSearchParams(window.location.search);

      if (mode === "inspirations") {
        currentParams.set("is_general_inspiration", "true");
      } else {
        currentParams.delete("is_general_inspiration");
      }

      navigate(`/projects?${currentParams.toString()}`);
    }
    setViewMode(mode);
    setShowDropdown(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const viewModeProps = {
    projects: {
      title: "Projects",
      icon: <FaFolderOpen className="ms-2" size={16} />,
      image: project_switch_card
    },
    inspirations: {
      title: "Inspirations",
      icon: <FaLightbulb className="ms-2" size={16} />,
      image: general_inspirations || project_switch_card
    },
    spaces: {
      title: "Spaces",
      icon: <FiRefreshCcw className="ms-2" size={16} />,
      image: project_switch_card
    }
  };

  const fetchAllLocations = async () => {
    try {
      const response = await authActions.fetchAllCities();
      if (response.data) {
        setAllLocations(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjectTypes = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjectTypes();
      if (response.data) {
        setProjectTypes(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProperties = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchAllProperties();
      if (response.data) {
        setProperties(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onChangeFilters = (value, type) => {
    if (!value && type === "location") {
      dispatch(updateCurrentLocation(""));
    }
    const filterParams = {
      project_type: type === "project_type" ? value : projectType,
      property: type === "property" ? value : property,
      bhk: type === "bhk" ? value : bhk,
      sort_by: type === "sort_by" ? value : sortBy,
      location: type === "location" ? value : location,
    };

    // Remove filters with null or empty values
    const queryString = Object.entries(filterParams)
      .filter(([key, val]) => val) // Only include filters with non-null, non-empty values
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");

    // Navigate to the updated URL
    navigate(`/projects?${queryString}`);
  };

  const loadPaginatedProjects = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjectsInProjectList(
        `/project/projects/?status=APPROVED`,
        null,
        projectType,
        property,
        location,
        bhk,
        search,
        sortBy,
        project_name,
        isGeneralInspiration === "true" ? true : null
      );
      if (response) {
        setProjects(response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setHasFetchedProducts(true);
  };

  const loadProfessionals = async () => {
    try {
      const response = await authActions.fetchAllProfessionalsByText(
        professionals
      );
      if (response) {
        setProfessionalsResult(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const loadMoreProjects = async () => {
    const projectList = projects.results;
    if (projects.next) {
      setNoNextItem(false);
      const newProjects = await authActions.fetchProjectsInProjectList(projects.next);
      if (newProjects) {
        const newResults = projectList.concat(newProjects.results);
        newProjects.results = newResults;
        setProjects(newProjects);
      }
    } else {
      setNoNextItem(true);
    }
  };

  useEffect(() => {
    fetchProjectTypes();
    fetchProperties();
    fetchAllLocations();
  }, []);

  useEffect(() => {
    loadProfessionals();
  }, [professionals]);

  useEffect(() => {
    loadPaginatedProjects();
  }, [projectType, property, location, bhk, search, sortBy, project_name, isGeneralInspiration]);

  // useEffect(() => {
  //   loadPaginatedProjects();
  // }, []);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow-spaces`}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowLeft size={25} color="black" /> */}
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow-spaces`}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowRight size={25} color="black" /> */}
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: xxlarge
      ? 8.5
      : xlarge
        ? 7
        : large
          ? 6
          : medium
            ? 4
            : small
              ? 2.5
              : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    if (bottom) {
      loadMoreProjects();
    }
  }, [bottom]);

  if (slug) {
    return <Outlet />;
  }

  return (
    <Container fluid>
      <Container
        className={`mt-5 pt-5 ${professionals ? "d-none" : "d-block"}`}
      >
        {small && (
          <>
            <div className="d-flex" style={{ position: "relative" }}>
              <div className="pe-3 position-relative" ref={dropdownRef}>
                {/* Toggle card */}
                <Card
                  className={`border-0 p-0 text-start ${viewMode === "projects" || viewMode === "inspirations" ? "bg-brand-500 text-white" : "bg-light"} shadow-none cursor-pointer`}
                  style={{ width: 128, borderRadius: 5 }}
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <Card.Img
                    className="img-fluid"
                    variant="top"
                    src={viewModeProps[viewMode].image}
                    style={{
                      width: 128,
                      maxHeight: "90px",
                      minHeight: "100px",
                      borderRadius: "",
                    }}
                  />
                  <Card.Body className="px-0 py-2 text-center">
                    <Card.Title className="body-text2 m-0 d-flex justify-content-center align-items-center">
                      <span className="me-1">{viewModeProps[viewMode].title}</span>
                      <FaChevronDown size={10} />
                    </Card.Title>
                  </Card.Body>
                </Card>

                {/* Custom dropdown menu with absolute positioning */}
                {showDropdown && (
                  <div
                    className="position-absolute shadow-sm bg-white"
                    style={{
                      top: "100%",
                      left: 0,
                      width: 128,
                      zIndex: 1050,
                      borderRadius: "0 0 5px 5px",
                      border: "1px solid rgba(0,0,0,0.15)"
                    }}
                  >
                    {Object.keys(viewModeProps).map(mode => {
                      if (mode === viewMode) return null; // Skip current selection

                      return (
                        <div
                          key={mode}
                          className="py-2 px-3 cursor-pointer hover-bg-light"
                          onClick={() => handleViewModeChange(mode)}
                          style={{
                            borderBottom: "1px solid rgba(0,0,0,0.05)",
                            transition: "background-color 0.15s ease-in-out"
                          }}
                          onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#f8f9fa"}
                          onMouseOut={(e) => e.currentTarget.style.backgroundColor = "transparent"}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <span>{mode === "inspirations" ? "General Inspirations" : viewModeProps[mode].title}</span>
                            {viewModeProps[mode].icon}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="d-flex" style={{ overflowX: "scroll" }}>
                {properties.length > 0 ? (
                  <>
                    <div
                      style={{
                        width: large ? "100%" : medium || small ? "100%" : "50%",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                      }}
                    >
                      <Slider {...settings}>
                        {properties?.map((property, index) => (
                          <ProjectCategoryCard
                            key={index}
                            {...property}
                            selected={projectType}
                            onClick={() => {
                              if (projectType === property.slug) {
                                onChangeFilters(null, "property");
                              } else {
                                onChangeFilters(property.slug, "property");
                              }
                            }}
                          />
                        ))}
                      </Slider>
                    </div>
                  </>
                ) : (
                  <ShimmerSlider />
                )}
              </div>
            </div>

            {/* Keep the existing header for search results */}
            <div className="body-title my-4">
              {location && location !== "null" && (
                <div>
                  Search results for{" "}
                  <span className="inter-600">{location}</span>
                </div>
              )}
              {search && search !== "null" && (
                <div>
                  Search results for <span className="inter-600">{search}</span>
                </div>
              )}
              {project_name && project_name !== "null" && (
                <div>
                  Search results for <span className="inter-600">{project_name}</span>
                </div>
              )}
              {isGeneralInspiration === "true" && (
                <div>
                  <span className="inter-600">General Inspirations</span>
                </div>
              )}
              <span className="inter-600">{projects?.count} {isGeneralInspiration === "true" ? "inspirations" : "real projects"}</span>
            </div>
          </>
        )}
        <>
          {/* Mobile Filter Icon */}
          <div className="d-block d-md-none">
            <FaFilter
              size={20}
              className="cursor-pointer my-2"
              onClick={handleToggleFilters}
            />
          </div>

          {/* Filters (Visible on desktop and toggled on mobile) */}
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${showFilters ? "" : "d-none d-md-flex" // Show filters when clicked on mobile or always on desktop
              }`}
          >
            <div className="row g-2 mb-3">
              <div className="col-12 col-md-auto">
                {property && property !== "null" ? (
                  <div
                    className="d-flex justify-content-center align-items-center flex-grow bg-transparent filter-container text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {properties.filter((prop) => prop.slug === property)
                        .length > 0
                        ? properties.filter((prop) => prop.slug === property)[0]
                          .property_name
                        : property}
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() => onChangeFilters(null, "property")}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 cursor-pointer"
                    style={{
                      border: "1px solid #BDC1C6",
                      borderRadius: 3,
                      height: "40px",
                    }}
                    onChange={(e) =>
                      onChangeFilters(e.target.value, "property")
                    }
                    value={property && property !== "null" ? property : ""}
                  >
                    <option value="" disabled>
                      Property Name
                    </option>
                    {properties.map((property, index) => {
                      return (
                        <option key={index + 1} value={property.slug}>
                          {property.property_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                )}
              </div>

              <div className="col-12 col-md-auto">
                {bhk && bhk !== "null" ? (
                  <div
                    className="d-flex justify-content-center filter-container align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {layouts.find((loc) => loc.value === bhk)?.label}
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() => onChangeFilters(null, "bhk")}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 cursor-pointer"
                    style={{
                      border: "1px solid #BDC1C6",
                      borderRadius: 3,
                      height: "40px",
                    }}
                    onChange={(e) => onChangeFilters(e.target.value, "bhk")}
                    value={bhk && bhk !== "null" ? bhk : ""}
                  >
                    <option value="" disabled>
                      Floor Layout
                    </option>
                    {layouts.map((layout, index) => {
                      return (
                        <option key={index + 1} value={layout.value}>
                          {layout.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                )}
              </div>

              <div className="col-12 col-md-auto">
                {location && location !== "null" ? (
                  <div
                    className="d-flex justify-content-center filter-container align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {
                        allLocations.find((loc) => loc.title === location)
                          ?.title ?? location
                      }
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() => onChangeFilters(null, "location")}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 cursor-pointer"
                    style={{
                      border: "1px solid #BDC1C6",
                      borderRadius: 3,
                      height: "40px",
                    }}
                    onChange={(e) =>
                      onChangeFilters(e.target.value, "location")
                    }
                    value={location && location !== "null" ? location : ""}
                  >
                    <option value="" disabled>
                      Select Location
                    </option>
                    {allLocations.map((loc, index) => (
                      <option key={index} value={loc.title}>
                        {loc.title}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </div>
              {isGeneralInspiration === "true" && (
                <div className="col-12 col-md-auto">
                  <div
                    className="d-flex justify-content-center filter-container align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">General Inspirations</span>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        // Remove the is_general_inspiration parameter and navigate
                        const newParams = new URLSearchParams(window.location.search);
                        newParams.delete('is_general_inspiration');
                        navigate(`/projects?${newParams.toString()}`);
                      }}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-12 col-md-auto">
              <div className="custom-select-wrapper d-flex">
                <Form.Select
                  className="bg-transparent text-gray-700 cursor-pointer custom-select"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                    height: "40px",
                  }}
                  onChange={(e) => onChangeFilters(e.target.value, "sort_by")}
                  value={sortBy || ""}
                >
                  <option value="" disabled>
                    Sort By
                  </option>
                  <option value="-created_at">Featured (New to Old)</option>
                  <option value="created_at">Featured (Old to New)</option>
                </Form.Select>

                {sortBy ? (
                  <IoClose
                    size={24}
                    className="cursor-pointer ms-2 custom-close"
                    onClick={() => onChangeFilters(null, "sort_by")}
                  />
                ) : (
                  <CgSortAz size={24} className="custom-dropdown-icon" />
                )}
              </div>
            </div>
          </div>
        </>

        <div className={`mb-3 ${professionals ? "d-none" : "d-block"}`}>
          {loading ? (
            <div className="d-flex flex-column align-items-center w-100">
              <Row className="w-100">
                {[...Array(6)].map((_, index) => (
                  <Col key={index} lg={4} md={6} sm={12} className="py-2 mb-2">
                    <ProjectCardShimmer />
                  </Col>
                ))}
              </Row>
            </div>
          ) : hasFetchedProducts && projects?.count > 0 ? (
            <div className="mb-3">
              {projects?.results?.length > 0 && (
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ minHeight: "80vh" }}
                >
                  <Row className="w-100">
                    {projects?.results?.map((item, index) => (
                      <Col
                        key={index}
                        lg={4}
                        md={6}
                        sm={12}
                        className={`d-flex flex-column ps-lg-0 py-2 mb-2 justify-content-start ${small ? "" : "p-0"
                          }`}
                      >
                        {small ? (
                          <ProjectCard
                            {...item}
                            id={item.id}
                            project_name={item?.project_name}
                            image={item?.file}
                            type={`${item?.bhk}, ${item?.project_area}`}
                            roomId={item?.rooms[0]?.id}
                            architect={item?.company_name}
                            location={item?.property_data?.location}
                            is_sample_flat={item?.is_sample_flat}
                            builders={
                              item?.property_data?.builder_data?.company_name
                            }
                            className="mx-0 flex-grow"
                          />
                        ) : (
                          <MobileProjectCard
                            {...item}
                            id={item.id}
                            project_name={item?.project_name}
                            roomId={item?.rooms[0]?.id}
                            type={`${item?.bhk}, ${item?.project_area}`}
                            builder_name={
                              item?.property_data?.builder_data?.company_name
                            }
                            is_sample_flat={item?.is_sample_flat}
                            architect={item?.company_name}
                            className="w-100 mobile-card"
                          />
                        )}
                      </Col>
                    ))}
                    <InfiniteScroll bottom={bottom} noNextItem={noNextItem} />
                  </Row>
                </div>
              )}
            </div>
          ) : hasFetchedProducts ? (
            <div
              className="d-flex justify-content-center align-items-center my-2 w-100"
              style={{ height: "300px", backgroundColor: "lightgray" }}
            >
              <div className="text-center">
                <FaFolderOpen size={50} className="mb-2 text-muted" />
                <h4>No Project Found</h4>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center w-100">
              <Row className="w-100">
                {[...Array(6)].map((_, index) => (
                  <Col key={index} lg={4} md={6} sm={12} className="py-2 mb-2">
                    <ProjectCardShimmer />
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </div>
      </Container>
      {/* <Container
        className={`mt-5 pt-5 ${professionals ? "d-block" : "d-none"}`}
      >
        {professionalsResult?.architects?.length > 0 && (
          <>
            <div className="body-title text-gray-900 my-4">
              <span className="inter-600">Architects</span> found
            </div>
            {professionalsResult?.architects?.map((architect) => {
              return (
                <ProfessionalCard
                  key={architect.id}
                  {...architect}
                  project_img={""}
                  type={"Architect"}
                  profile_img={architect.user_data?.profile_picture}
                  onClick={() =>
                    navigate(`/professionals/architects/${architect.slug}`)
                  }
                />
              );
            })}
          </>
        )}
        {professionalsResult?.builders?.length > 0 && (
          <>
            <div className="body-title text-gray-900 my-4">
              <span className="inter-600">Builders</span> found
            </div>
            {professionalsResult?.builders?.map((builder) => {
              return (
                <AllProfessionalCard
                  key={builder.id}
                  id={builder.id}
                  slug={builder.slug}
                  company_name={builder.company_name}
                  image={builder.user_data.profile_picture}
                  onClick={() =>
                    navigate(`/professionals/builders/${builder.slug}`)
                  }
                />
              );
            })}
          </>
        )}
        {professionalsResult?.brands?.length > 0 && (
          <>
            <div className="body-title text-gray-900 my-4">
              <span className="inter-600">Brands</span> found
            </div>
            {professionalsResult?.brands?.map((brand) => {
              return (
                <BrandCard
                  key={brand.id}
                  id={brand.user_data.id}
                  slug={brand.slug}
                  companyName={brand.company_name}
                  image={brand?.image}
                  warranty={brand.warranty}
                  rating={brand.rating}
                  averagePricing={brand.averagePricing}
                />
              );
            })}
          </>
        )}
      </Container> */}

      <Container className={`mt-5 pt-5 ${professionals ? "d-block" : "d-none"}`}>
        {/* Architects */}
        {professionalsResult?.architects ? (
          professionalsResult.architects.length > 0 ? (
            <>
              <div className="body-title text-gray-900 my-4">
                <span className="inter-600">Architects</span> found
              </div>
              {professionalsResult.architects.map((architect) => (
                <ProfessionalCard
                  key={architect.id}
                  {...architect}
                  architect_id={architect?.id}
                  architect_user={architect?.user}
                  project_img={""}
                  type={"Architect"}
                  profile_img={architect.user_data?.profile_picture}
                  onClick={() =>
                    navigate(`/professionals/architects/${architect.slug}`)
                  }
                />
              ))}
            </>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center my-2 w-100"
              style={{ height: "300px", backgroundColor: "lightgray" }}
            >
              <div className="text-center">
                <FaDraftingCompass size={50} className="mb-2 text-muted" />
                <h4>No Architect Found</h4>
              </div>
            </div>
          )
        ) : null}

        {/* Builders */}
        {professionalsResult?.builders ? (
          professionalsResult.builders.length > 0 ? (
            <>
              <div className="body-title text-gray-900 my-4">
                <span className="inter-600">Builders</span> found
              </div>
              {professionalsResult.builders.map((builder) => (
                <AllProfessionalCard
                  key={builder.id}
                  id={builder.id}
                  slug={builder.slug}
                  company_name={builder.company_name}
                  image={builder.user_data.profile_picture}
                  onClick={() =>
                    navigate(`/professionals/builders/${builder.slug}`)
                  }
                />
              ))}
            </>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center my-2 w-100"
              style={{ height: "300px", backgroundColor: "lightgray" }}
            >
              <div className="text-center">
                <FaHammer size={50} className="mb-2 text-muted" />
                <h4>No Builder Found</h4>
              </div>
            </div>
          )
        ) : null}

        {/* Brands */}
        {professionalsResult?.brands ? (
          professionalsResult.brands.length > 0 ? (
            <>
              <div className="body-title text-gray-900 my-4">
                <span className="inter-600">Brands</span> found
              </div>
              {professionalsResult.brands.map((brand) => (
                <BrandCard
                  key={brand.id}
                  id={brand.user_data.id}
                  slug={brand.slug}
                  companyName={brand.company_name}
                  image={brand?.image}
                  warranty={brand.warranty}
                  rating={brand.rating}
                  averagePricing={brand.averagePricing}
                />
              ))}
            </>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center my-2 w-100"
              style={{ height: "300px", backgroundColor: "lightgray" }}
            >
              <div className="text-center">
                <FaBuilding size={50} className="mb-2 text-muted" />
                <h4>No Brand Found</h4>
              </div>
            </div>
          )
        ) : null}
      </Container>


    </Container>
  );
};

export default ProjectList;
