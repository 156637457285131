import { blog_1, blog_2, blog_3, blog_4, blog_5, blog_6 } from "assets";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { VscCircleFilled } from "react-icons/vsc";


const blogPosts = [
  {
    id: 1,
    image: `${ blog_1 }`,
    imageAlt: "Interior Design Trends",
    title: "Top interior design trends of 2025",
    readTime: "5-mins read",
    category: "Design",
    date: "24th Feb 2025",
    description: "Every year, new trends emerge that redefine interior design. This blog explores popular color palettes, furniture styles, a..."
  },
  {
    id: 2,
    image: `${blog_2}`,
    imageAlt: "Choose Right Architect",
    title: "How to Choose the Right Architect or Interior De...",
    readTime: "5-mins read",
    category: "Project Management",
    date: "24th Feb 2025",
    description: "Finding the right expert can be overwhelming. This guide walks homeowners through the key factors to consider, inc..."
  },
  {
    id: 3,
    image: ` ${blog_3}`,
    imageAlt: "Smart Home Design",
    title: "Smart Home Design: Integrating Technology wit...",
    readTime: "5-mins read",
    category: "Tech in Architecture",
    date: "24th Feb 2025",
    description: "The future of interior design is smart and connected. This blog explores how automation, AI-powered devices, and io..."
  },
  {
    id: 4,
    image: `${blog_4}`,
    imageAlt: "Luxury vs Budget Interiors",
    title: "Luxury vs. Budget Interiors: Making the Right Ch...",
    readTime: "5-mins read",
    category: "Estimation",
    date: "24th Feb 2025",
    description: "Luxury interior design doesn't always have to come with a hefty price tag. This blog compares high-end luxury design..."
  },
  {
    id: 5,
    image:`${blog_5}`,
    imageAlt: "Sustainable Interior Design",
    title: "Sustainable Interior Design: Eco-Friendly Choice...",
    readTime: "5-mins read",
    category: "Sustainability",
    date: "24th Feb 2025",
    description: "Sustainability is becoming a key focus in home design. This blog discusses eco-friendly materials, such as bamboo floo..."
  },
  {
    id: 6,
    image: `${blog_6}`,
    imageAlt: "Behind the Scenes",
    title: "Behind the Scenes: How Designers Create Stun...",
    readTime: "5-mins read",
    category: "Design",
    date: "24th Feb 2025",
    description: "Ever wondered how top designers bring their ideas to life? This blog takes you through the step-by-step process of fi..."
  }
];


const BlogCard = ({ post }) => (
  <Col xs={12} md={6} lg={4} className="mb-4">
    <Card className="h-100 shadow-sm p-1" style={{borderRadius:'9px', border:'1px solid #e8eaed'}}>
      <Card.Img
        variant="top"
        src={post.image}
        alt={post.imageAlt}
        style={{ height: '200px', objectFit: 'cover' }}
      />
      <Card.Body className="">
        <Card.Title className="h3 inter-600 text-gray-900">{post.title}</Card.Title>
        <div className="d-flex align-items-center mb-2">
          <span className="body-text1 inter-400 text-gray-900">{post.readTime}</span><VscCircleFilled className="mx-1 text-gray-300" size={10}/>
          <span className="body-text1 inter-400 text-gray-900">{post.category}</span><VscCircleFilled className="mx-1 text-gray-300" size={10}/>
          <span className="body-text1 inter-400 text-gray-900">{post.date}</span>
        </div>
        <Card.Text className="mb-2 body-text1 inter-400 text-gray-600">
          {post.description}
        </Card.Text>
        <div
          className="bg-gray-50"
          style={{ 
            display: 'inline-block',
            padding: '8px 14px',
            cursor: 'pointer',
            borderRadius:'3px'
          }}
        >
          Read more
        </div>
      </Card.Body>
    </Card>
  </Col>
);


const BlogsPage = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <Container
      fluid
      className="mt-5 p-0"
      style={{ minHeight: "80vh" }}
    >
      <Container
        fluid
        className="py-5 d-flex flex-column justify-content-center align-items-center text-center first_section_home_page"
        style={{
          minHeight: "20vh",
          padding: !small ? "26px 16px" : "0px",
        }}
      >
        <Container className={`px-6`}>
          <div className="h1 px-lg-5 pt-5 inter-600 text-gray-900">
          InterioKeys Blog
          </div>
          <div className="text-gray-700 body-title inter-400 my-3">
            <span>Get updated with the latest design trends, industry insights, and expert tips in architecture and interiors. Explore inspiring ideas,</span>
            {large && <br />}
            <span>innovative solutions, and success stories from top professionals in the field. Stay informed and inspired with InterioKeys!</span>
          </div>
        </Container>
      </Container>
      <Container className="mt-4 py-4">
        <Row>
          {blogPosts.map(post => (
            <BlogCard key={post.id} post={post} />
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default BlogsPage;
