import { useEffect, useState } from "react";
import { Button, Dropdown, Form, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";

const EditProjectsForm = ({
  projectInfoRef,
  projectDetails,
  properties,
  setSelectedProperty,
  setProjectDetails,
  setShowPropertyModal,
  setSelectedTheme,
  selectedTheme,
  isGeneralInspiration,
  setIsGeneralInspiration,
}) => {
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState(
    projectDetails?.property_data
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [pincodeDetails, setPincodeDetails] = useState({});

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  const [startDate, setStartDate] = useState(
    projectDetails?.project_year
      ? parseDate(projectDetails.project_year)
      : new Date()
  );

  useEffect(() => {
    if (projectDetails?.project_year) {
      setStartDate(parseDate(projectDetails.project_year));
    }
  }, [projectDetails?.project_year]);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Add pincode validation and autofetch functions
  const validatePincode = (pincode) => {
    const pincodeRegex = /^\d{6}$/;
    if (pincode && !pincodeRegex.test(pincode)) {
      notify("Pincode must be exactly 6 digits", "error");
      return false;
    }
    return true;
  };

  const getPincode = async (pincode) => {
    pincode = pincode.replace(/\D/g, "").slice(0, 6);

    if (pincode.length < 6) {
      setPincodeDetails({});
      setProjectDetails((prevDetails) => ({
        ...prevDetails,
        country: "",
        city: "",
        state: "",
        project_country: "",
        project_city: "",
        project_state: "",
      }));
      return;
    }

    try {
      const response = await authActions.fetchPincode(pincode);
      if (!response || !response.Pincode) {
        notify(
          "Invalid pincode. Please enter a valid 6-digit pincode.",
          "error"
        );
        setPincodeDetails({});
        setProjectDetails((prevDetails) => ({
          ...prevDetails,
          country: "",
          city: "",
          state: "",
          project_country: "",
          project_city: "",
          project_state: "",
        }));
        return;
      }

      setPincodeDetails(response);
      setProjectDetails((prevDetails) => ({
        ...prevDetails,
        pincode: response?.Pincode || "",
        country: response?.Country || "",
        city: response?.District || "",
        state: response?.State || "",
        project_pincode: response?.Pincode || "",
        project_country: response?.Country || "",
        project_city: response?.District || "",
        project_state: response?.State || "",
      }));
    } catch (error) {
      console.log(error);
      notify(
        "Error fetching location data. Please try a different pincode.",
        "error"
      );
      setPincodeDetails({});
      setProjectDetails((prevDetails) => ({
        ...prevDetails,
        country: "",
        city: "",
        state: "",
        project_country: "",
        project_city: "",
        project_state: "",
      }));
    }
  };

  const handlePincodeBlur = (e) => {
    const pincode = e.target.value;
    if (pincode && !validatePincode(pincode)) {
      e.target.value = "";
      setProjectDetails((prev) => ({ ...prev, pincode: "" }));
    }
  };

  const handlePincodeKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      notify("Pincode must contain only digits", "error");
    }
  };

  const projectSegments = [
    {
      id: 1,
      label: "Affordable (₹2,500 to ₹4,000)",
      value: "AFFORDABLE_RESIDENTIAL",
    },
    {
      id: 3,
      label: "Mid-Range (₹4,000 to ₹7,000)",
      value: "MID_RANGE_RESIDENTIAL",
    },
    {
      id: 5,
      label: "Luxury (₹7,000 to ₹12,000)",
      value: "LUXURY_RESIDENTIAL",
    },
    {
      id: 7,
      label: "Ultra-Luxury (₹12,000+)",
      value: "ULTRA_LUXURY_RESIDENTIAL",
    },
  ];

  const defaultLayouts = [
    { id: 1, layout: "2BHK" },
    { id: 2, layout: "2.5BHK" },
    { id: 3, layout: "3BHK" },
    { id: 4, layout: "3.5BHK" },
    { id: 5, layout: "4BHK" },
    { id: 7, layout: "5BHK" },
    { id: 9, layout: "6BHK" },
    { id: 11, layout: "7BHK" },
    { id: 13, layout: "8BHK" },
  ];

  const layouts = properties.find(
    (property) => property.id === projectDetails?.project_property
  );

  // console.log(layouts, "layouts")

  const handleThemeSelect = (theme) => {
    setSelectedTheme(theme.value);
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      project_theme: theme.value,
    }));
    setShowDropdown(false);
  };

  // const projectThemes = [
  //   { value: "modern", label: "Modern" },
  //   { value: "contemporary", label: "Contemporary" },
  //   { value: "art_moderne", label: "Art Moderne" },
  //   { value: "mid_century", label: "Mid-Century" },
  //   { value: "minimalist", label: "Minimalist" },
  //   { value: "scandinavian", label: "Scandinavian" },
  //   { value: "shabby_chic", label: "Shabby Chic" },
  //   { value: "eclectic", label: "Eclectic" },
  //   { value: "industrial", label: "Industrial" },
  //   { value: "farmhouse", label: "Farmhouse" },
  //   { value: "art_deco", label: "Art Deco" },
  //   { value: "boho", label: "Boho – Bohemian" },
  //   { value: "coastal", label: "Coastal" },
  //   { value: "french_country", label: "French Country" },
  //   { value: "hollywood_glam", label: "Hollywood Glam" },
  //   { value: "japandi", label: "Japandi" },
  //   { value: "mediterranean", label: "Mediterranean" },
  //   { value: "asian_decor", label: "Asian Décor" },
  //   { value: "rustic", label: "Rustic" },
  //   { value: "traditional", label: "Traditional" },
  //   { value: "transitional", label: "Transitional" },
  //   { value: "maximalist", label: "Maximalist" },
  //   { value: "1970s_revival", label: "1970s Revival" },
  //   { value: "english_country", label: "English Country" },
  //   { value: "cottagecore", label: "Cottagecore" },
  //   { value: "grandmillennial", label: "Grandmillennial" },
  //   { value: "moroccan", label: "Moroccan" },
  // ];

  const projectThemes = [
    { value: "MODERN", label: "Modern" },
    { value: "CONTEMPORARY", label: "Contemporary" },
    { value: "ART_MODERNE", label: "Art Moderne" },
    { value: "MID_CENTURY", label: "Mid-Century" },
    { value: "MINIMALIST", label: "Minimalist" },
    { value: "SCANDINAVIAN", label: "Scandinavian" },
    { value: "SHABBY_CHIC", label: "Shabby Chic" },
    { value: "ECLECTIC", label: "Eclectic" },
    { value: "INDUSTRIAL", label: "Industrial" },
    { value: "FARMHOUSE", label: "Farmhouse" },
    { value: "ART_DECO", label: "Art Deco" },
    { value: "BOHO", label: "Boho – Bohemian" },
    { value: "COASTAL", label: "Coastal" },
    { value: "FRENCH_COUNTRY", label: "French Country" },
    { value: "HOLLYWOOD_GLAM", label: "Hollywood Glam" },
    { value: "JAPANDI", label: "Japandi" },
    { value: "MEDITERRANEAN", label: "Mediterranean" },
    { value: "ASIAN_DECOR", label: "Asian Décor" },
    { value: "RUSTIC", label: "Rustic" },
    { value: "TRADITIONAL", label: "Traditional" },
    { value: "TRANSITIONAL", label: "Transitional" },
    { value: "MAXIMALIST", label: "Maximalist" },
    { value: "REVIVAL_1970S", label: "1970s Revival" },
    { value: "ENGLISH_COUNTRY", label: "English Country" },
    { value: "COTTAGECORE", label: "Cottagecore" },
    { value: "GRANDMILLENNIAL", label: "Grandmillennial" },
    { value: "MOROCCAN", label: "Moroccan" },
  ];

  const filteredThemes = projectThemes.filter((theme) =>
    theme.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Form ref={projectInfoRef}>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1 body-text2 inter-500">
            {location.pathname.includes("builder")
              ? "Sample Flat Name"
              : "Project Name"}
            <sup style={{ color: "red" }}>*</sup>
          </Form.Label>
          <Form.Control
            required
            className="py-2 body-text2"
            type="text"
            name="project_name"
            defaultValue={projectDetails?.project_name || ""}
            style={{
              border: "1px solid #BDC1C6",
              borderRadius: 3,
            }}
          />
        </Form.Group>

        {!location.pathname.includes("builder") && (
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              id="isGeneralInspiration"
              label="Is this a general inspiration?"
              checked={isGeneralInspiration}
              onChange={(e) => {
                setIsGeneralInspiration(e.target.checked);
                if (e.target.checked) {
                  setSelectedProperty(null);
                  setProjectDetails((prev) => ({
                    ...prev,
                    project_property: "",
                    is_general_inspiration: true,
                  }));
                } else {
                  setProjectDetails((prev) => ({
                    ...prev,
                    is_general_inspiration: false,
                  }));
                }
              }}
            />
          </Form.Group>
        )}

        <Form.Group className="mb-3">
          <Form.Label className="mb-1 body-text2 inter-500">
            Property Name<sup style={{ color: "red" }}>*</sup>
          </Form.Label>
          {properties?.length > 0 ? (
            <ReactSelect
              name="project_property"
              isMulti={false}
              options={properties}
              getOptionLabel={(e) => e.property_name}
              getOptionValue={(e) => e.id}
              value={properties.find(
                (property) => property.id === projectDetails?.project_property
              )}
              isDisabled={isGeneralInspiration}
              onChange={(selectedProperty) => {
                if (selectedProperty?.id !== "add") {
                  setShowPropertyModal(false);
                  setSelectedProperty(selectedProperty);
                  setProjectDetails({
                    ...projectDetails,
                    project_property: selectedProperty?.id,
                    // Update the property_data with the full selected property details
                    property_data: selectedProperty,
                    // Update the pincode (and address if needed) from the new property's details
                    pincode: selectedProperty?.pincode,
                    address: selectedProperty?.address,
                  });
                } else {
                  setShowPropertyModal(true);
                  setSelectedProperty(null);
                }
              }}
              filterOption={(option, inputValue) => {
                if (option.value === "add") return true;
                const propertyName = option.label || "";
                return propertyName
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              }}
              isClearable
              placeholder={
                isGeneralInspiration
                  ? "Not required for inspiration"
                  : "Select Property"
              }
              className="body-text2"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: "1px solid #BDC1C6",
                  borderRadius: 3,
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  borderBottom: "1px solid #DADCE0",
                  backgroundColor:
                    state.data.id === "add"
                      ? "#FDF3F3"
                      : state.isFocused
                        ? "#f0f0f0"
                        : baseStyles.backgroundColor,
                  color: state.data.id === "add" ? "#C53D3D" : baseStyles.color,
                  fontWeight:
                    state.data.id === "add" ? 500 : baseStyles.fontWeight,
                }),
              }}
            />
          ) : (
            <div>Please wait while we are loading the properties...</div>
          )}
        </Form.Group>
        <div className="d-flex w-100 mb-3">
          <Form.Group className="w-50">
            <Form.Label className="mb-1 body-text2 inter-500">
              Layout<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Select
              required
              className="py-2 body-text2"
              name="bhk"
              onChange={(e) => {
                const selectedLayout = e.target.value;
                // Choose the correct layout array based on inspiration type
                const layoutArray = isGeneralInspiration
                  ? defaultLayouts
                  : layouts?.layout_type_data;
                // Find the layout object by matching the layout name
                const selectedLayoutData = layoutArray?.find(
                  (item) => item.layout === selectedLayout
                );
                setProjectDetails({
                  ...projectDetails,
                  bhk: selectedLayout,
                  // Auto-set flat_area using the selected layout’s area
                  flat_area: selectedLayoutData?.area || "",
                });
              }}
              value={projectDetails?.bhk || ""}

              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
              }}
            >
              <option value="">Select</option>
              {isGeneralInspiration
                ? defaultLayouts?.map((type, index) => (
                  <option key={index} value={type.layout}>
                    {type.layout}
                  </option>
                ))
                : layouts?.layout_type_data?.map((type, index) => (
                  <option key={index} value={type.layout}>
                    {type.layout}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="w-50 ms-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Flat Area<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            {isGeneralInspiration ? (
              <Form.Control
                required
                className="py-2 body-text2"
                type="text"
                placeholder="Enter Flat Area"
                name="flat_area"
                value={projectDetails?.flat_area || ""}
                onChange={(e) =>
                  setProjectDetails({
                    ...projectDetails,
                    flat_area: e.target.value,
                  })
                }
                style={{
                  border: "1px solid #BDC1C6",
                  borderRadius: 3,
                }}
              />
            ) : (
              <Form.Select
                required
                className="py-2 body-text2"
                name="flat_area"
                value={projectDetails?.flat_area || ""}
                disabled
                style={{
                  border: "1px solid #BDC1C6",
                  borderRadius: 3,
                  backgroundColor: "#f0f0f0",
                }}
              >
                <option value="">Select</option>
                {layouts?.layout_type_data?.map((type, index) => (
                  <option key={index} value={type.area}>
                    {type?.area ? `${type.area} sq.ft.` : ""}
                  </option>
                ))}
              </Form.Select>
            )}
          </Form.Group>
        </div>

        <div className="d-flex w-100 mb-3">
          <Form.Group className="w-50">
            <Form.Label className="mb-1 body-text2 inter-500">
              {location.pathname.includes("builder")
                ? "Sample Flat Segment"
                : "Project Segment"}
            </Form.Label>
            <Form.Select
              className="py-2 body-text2"
              name="property_segment"
              value={projectDetails?.property_segment || ""}
              onChange={(e) =>
                setProjectDetails({
                  ...projectDetails,
                  property_segment: e.target.value,
                })
              }
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
              }}
            >
              <option value="">Select</option>
              {projectSegments?.map((type, index) => (
                <option key={index} value={type.value}>
                  {type.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="w-50 ms-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              BOQ Cost
            </Form.Label>
            <InputGroup
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
              }}
            >
              <InputGroup.Text>₹</InputGroup.Text>
              <Form.Control
                required
                className="py-2 body-text2 border-0"
                type="number"
                name="project_cost"
                placeholder="Enter Amount"
                value={projectDetails?.project_cost}
                onChange={(e) =>
                  setProjectDetails({
                    ...projectDetails,
                    project_cost: e.target.value,
                  })
                }
                style={{
                  border: "1px solid #BDC1C6",
                  borderRadius: 3,
                  width: "40%",
                }}
              />
              <Form.Select
                className="body-text2 border-0"
                name="cost_unit"
                value={projectDetails?.cost_unit || "Lakhs"}
                onChange={(e) =>
                  setProjectDetails({
                    ...projectDetails,
                    cost_unit: e.target.value,
                  })
                }
              >
                <option value="Lakhs">Lakhs</option>
                <option value="Crores">Crores</option>
              </Form.Select>
            </InputGroup>
          </Form.Group>
        </div>

        <div className=" w-100 mb-3">
          <Form.Group className="w-100 mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Designed Date
            </Form.Label>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                setProjectDetails({
                  ...projectDetails,
                  project_year: formatDate(date),
                });
              }}
              className="form-control py-2 body-text2 w-100"
              dateFormat="dd-MM-yyyy"
              name="project_year"
              wrapperClassName="w-100"
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
              }}
            />
          </Form.Group>
        </div>

        <div className="w-100 mb-3">
          <Form.Group>
            <Form.Label className="mb-1 body-text2 inter-500">
              Address
            </Form.Label>
            <Form.Control
              required={!isGeneralInspiration}
              readOnly={!isGeneralInspiration}
              className="py-2 body-text2"
              type="text"
              name="address"
              placeholder="Enter address ..."
              value={
                isGeneralInspiration
                  ? projectDetails?.project_address || projectDetails?.address || ""
                  : projectDetails?.property_data?.address ||
                  projectDetails?.address ||
                  ""
              }
              onChange={(e) => {
                if (isGeneralInspiration) {
                  setProjectDetails({
                    ...projectDetails,
                    address: e.target.value,
                    project_address: e.target.value
                  });
                }
              }}
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
                backgroundColor: isGeneralInspiration ? "" : "#F0F0F0",
                color: isGeneralInspiration ? "" : "#6C757D",
              }}
            />
          </Form.Group>
        </div>
        <Form.Group className="mb-3 d-flex">
          <div className="w-100">
            <Form.Label className="mb-1 body-text2 inter-500">
              Pincode
            </Form.Label>
            <Form.Control
              required={!isGeneralInspiration}
              readOnly={!isGeneralInspiration}
              className="py-2 body-text2"
              type="text"
              // onChange={(e) => getPincode(e.target.value)}
              placeholder="Enter pincode"
              name="pincode"
              value={
                isGeneralInspiration
                  ? projectDetails?.project_pincode || projectDetails?.pincode || ""
                  : projectDetails?.property_data?.pincode ||
                  projectDetails?.pincode ||
                  ""
              }
              onChange={(e) => {
                if (isGeneralInspiration) {
                  setProjectDetails({
                    ...projectDetails,
                    pincode: e.target.value,
                    project_pincode: e.target.value
                  });

                  // Only call the API when we have 6 digits
                  if (e.target.value.length === 6) {
                    getPincode(e.target.value);
                  }
                }
              }}
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
                backgroundColor: isGeneralInspiration ? "" : "#F0F0F0",
                color: isGeneralInspiration ? "" : "#6C757D",
              }}
            />
          </div>

          <div className="ms-2 w-100">
            <Form.Label className="mb-1 body-text2 inter-500">
              Country
            </Form.Label>
            <Form.Control
              required
              readOnly
              className="py-2 body-text2"
              type="text"
              placeholder="Enter country"
              name="country"
              value={
                isGeneralInspiration
                  ? projectDetails?.project_country || projectDetails?.country || ""
                  : projectDetails?.property_data?.country ||
                  projectDetails?.country ||
                  ""
              }
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
                backgroundColor: "#F0F0F0",
                color: "#6C757D",
              }}
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 d-flex">
          <div className="w-100">
            <Form.Label className="mb-1 body-text2 inter-500">City</Form.Label>
            <Form.Control
              required
              readOnly
              className="py-2 body-text2"
              type="text"
              placeholder="Enter city"
              name="city"
              // defaultValue={
              //   projectDetails?.property_data?.city || projectDetails?.city
              // }
              value={
                isGeneralInspiration
                  ? projectDetails?.project_city || projectDetails?.city || ""
                  : projectDetails?.property_data?.city ||
                  projectDetails?.city ||
                  ""
              }
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
                backgroundColor: "#F0F0F0",
                color: "#6C757D",
              }}
            />
          </div>

          <div className="ms-2 w-100">
            <Form.Label className="mb-1 body-text2 inter-500">State</Form.Label>
            <Form.Control
              required
              className="py-2 body-text2"
              readOnly
              type="text"
              placeholder="Enter state"
              name="state"
              // defaultValue={
              //   projectDetails?.property_data?.state || projectDetails?.state
              // }
              value={
                isGeneralInspiration
                  ? projectDetails?.project_state || projectDetails?.state || ""
                  : projectDetails?.property_data?.state ||
                  projectDetails?.state ||
                  ""
              }
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
                backgroundColor: "#F0F0F0",
                color: "#6C757D",
              }}
            />
          </div>
        </Form.Group>

        <div className="mb-3">
          <Form.Label className="mb-1 body-text2 inter-500">
            {location.pathname.includes("builder")
              ? "Sample Flat Theme"
              : "Project Theme"}
            <sup style={{ color: "red" }}>*</sup>
          </Form.Label>
          <Dropdown
            show={showDropdown}
            onToggle={(isOpen) => setShowDropdown(isOpen)}
          >
            <Dropdown.Toggle
              className="bg-transparent text-gray-700 body-text2 inter-500 cursor-pointer w-100 d-flex justify-content-between align-items-center"
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
                textAlign: "left",
                padding: "8px 12px",
              }}
            >
              <span>
                {selectedTheme
                  ? projectThemes.find(
                    (theme) =>
                      theme.value.toLowerCase() ===
                      selectedTheme.toLowerCase()
                  )?.label
                  : projectDetails?.project_theme
                    ? projectThemes.find(
                      (theme) =>
                        theme.value.toLowerCase() ===
                        projectDetails.project_theme.toLowerCase()
                    )?.label
                    : "Select a theme"}
              </span>
              <span className="ms-auto">
                <i className="bi bi-chevron-down"></i> {/* Bootstrap Icon */}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                maxHeight: "250px",
                maxWidth: "100%",
                overflowY: "auto",
              }}
            >
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search themes..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                  }}
                />
              </InputGroup>

              {filteredThemes.length > 0 ? (
                filteredThemes.map((theme, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleThemeSelect(theme)}
                    style={{
                      whiteSpace: "normal", // Allow wrapping
                      wordWrap: "break-word", // Break long words
                      overflowWrap: "break-word", // Handle wrapping for long strings
                    }}
                  >
                    {theme.label}
                  </Dropdown.Item>
                ))
              ) : (
                <Dropdown.Item disabled>No themes found</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Project Description */}
        <Form.Group className="mb-3">
          <Form.Label className="mb-1 body-text2 inter-500">
            {location.pathname.includes("builder")
              ? "Sample Flat Description"
              : "Project Description"}
          </Form.Label>
          <Form.Control
            required
            className="py-2 body-text2"
            as="textarea"
            rows={6}
            name="project_description"
            placeholder="Describe the project..."
            value={projectDetails?.project_description || ""}
            onChange={(e) =>
              setProjectDetails({
                ...projectDetails,
                project_description: e.target.value,
              })
            }
            style={{
              border: "1px solid #BDC1C6",
              borderRadius: 3,
              resize: "none",
            }}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default EditProjectsForm;
