import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentLocation: "",
};

const locationSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        updateCurrentLocation: (state, action) => {
            state.currentLocation = action.payload;
        },
    },
});

export const { updateCurrentLocation } = locationSlice.actions;

export default locationSlice.reducer;
