import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { ad_1 } from "assets";
import ArchitectProjectCard from "components/Cards/ArchitectProjectCard";
import { FaPlus } from "react-icons/fa6";
import { MdDesignServices } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { useScrollBottom, InfiniteScroll } from "components";
import { useSelector } from "react-redux";
import MultipleAds from "components/Advertisements/MultipleAds";
import ArchitectProjectShimmer from "components/Cards/ArchitectProjectShimmer";

function MyProjects({
  companyName,
  projects,
  setProjects,
  loading,
  hasProjectLoaded,
  setLoading,
  projectType,
  constructionStatus,
  propertySegment,
  sortBy,
  location,
  q,
  setProjectsCount,
  setNextProject,
  nextProject,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const bottom = useScrollBottom();
  const [noNextItem, setNoNextItem] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const loadMoreActiveProjects = async () => {
    if (nextProject) {
      const newProject = await authActions.loadProjects(nextProject);
      setNextProject(newProject?.next);
      setProjects((prev) => [...prev, ...newProject?.results]);
    }
  };

  useEffect(() => {
    if (bottom) {
      loadMoreActiveProjects();
    }
  }, [bottom]);

  if (!loading && projects?.length <= 0) {
    return (
      <div
        className="py-4 d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "70vh" }}
      >
        <div
          className="d-flex justify-content-center align-items-center my-3"
          style={{
            backgroundColor: "#E8EAED",
            width: 100,
            height: 100,
            borderRadius: 6,
          }}
        >
          <MdDesignServices size={30} />
        </div>
        {pathname !== "/builder/properties" ? <div>
          <div className="h3 inter-600">No properties designed yet.</div>
          <div className="body-title text-gray-600">
            Add your first project to start building your portfolio!
          </div>
        </div> : (
          <div>
            <div className="h3 inter-600">No sample flats created yet!</div>
          </div>
        )}
      </div>
    );
  }

  return (
    <Row>
      <Col lg={pathname !== "/builder/properties" ? 9 : 12}>
        <>
          {!hasProjectLoaded || loading ? (
            <div>
              {[...Array(4)].map((_, index) => (
                <div key={index}>
                  <ArchitectProjectShimmer />
                </div>
              ))}
            </div>
          ) : (
            projects?.map((project, index) => (
              <>
                <ArchitectProjectCard
                  key={project.id}
                  project={project}
                  setProjects={setProjects}
                />
              </>
            ))
          )}

          <InfiniteScroll bottom={bottom} noNextItem={noNextItem} />
        </>
      </Col>
      {pathname !== "/builder/properties" && (
        <Col lg={3}>
          <MultipleAds
            endPoint={
              user?.user_type === "brand" ? "brand_ads" : "architect_ads"
            }
          />
        </Col>
      )}
    </Row>
  );
}

export default MyProjects;
