import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Terms = () => {
  return (
    <Container
      fluid
      className="mt-5 pt-4 text-start"
      style={{ minHeight: "90vh" }}
    >
      <div style={{ marginTop: '16px', textAlign: 'justify' }}>
        <span className="h3 inter-600 text-black p-4 mb-6" >Interiokeys Terms and Conditions</span>
        <div className="p-4 inter-400 body-title">
  <p className="mb-4">
  Please read these Terms & Conditions carefully before using the http://www.interiokeys.com website or InterioKeys mobile application operated and managed by Star Unity Ecom Pvt Ltd.
  Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms and conditions. These Terms and conditions apply to all visitors, users and others who access, download, register and use InterioKeys website/application. By accessing, downloading or using the InterioKeys website/application you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access website/application.
  </p>

  <b className="mb-0">Intellectual Property Rights</b>
  <p>
  InterioKeys is owned and managed under copyright act and trademark act by Star Unity Ecom Pvt Ltd. All aspects of InterioKeys website and application, such as the designs, transactional flow, look and feel, contents, graphics and images including logo are protected under copyright act and trademark act. As the law enforces, users are restricted from downloading, reusing, reconfiguring or imitating any of the proprietary material. Any such activity is expressly prohibited without prior permission of InterioKeys or written consent of owner. If InterioKeys identifies that any user engaged in such activities; appropriate legal action may be taken, including without limitation civil, criminal and injunctive redress.
  </p>

  <b className="mb-0">Use of InterioKeys Platforms (Web/App)</b>
  <p>
  The InterioKeys is a common platform for customers and service providers that may contain profiles, email facility, chat facility, blogs, job posting, advertisements and other communication channels to communicate with each other or to avail the services. Hence, users may use these facilities only to send and receive messages that are relevant and appropriate to the intended use. Violation of this term may force InterioKeys to take appropriate legal action against the user, including without limitation civil, criminal and injunctive redress.
  </p>

  <b className="mb-0">Customer Information</b>
  <p>
  InterioKeys, during the new account registration, may ask customer to share the personal information which is required to deliver the service. InterioKeys assumes customer provides the accurate information about his/her identity, contact information. The given information will be verified during the registration process.  InterioKeys only makes a sincere attempt to provide best service to its customers and hence does not take full responsibility of suitability, reliability, timeliness, or accuracy of the Services requested and provided by customers. InterioKeys does not confirm the identity of customers who they claim to be. If InterioKeys finds any information wrong or any malpractice, agreement with customer will be terminated with immediate effect.
  </p>

  <b className="mb-0">Service Provider Information</b>
  <p>
  InterioKeys performs the background verification of service providers to ensure the safety and security of customers. InterioKeys enters into Service Level Agreement (SLA) with every service provider during the on-boarding process. This attempt is a sincere effort to ensure the best and effective service to our customers. InterioKeys does not take full responsibility suitability, reliability, timeliness, or accuracy of the services provided.
  </p>

  <b className="mb-0">Account/Password Protection</b>
  <p>
  Customer is solely responsible for maintaining the confidentiality of password and service account provided by InterioKeys for accessing the offered service. Customer is solely and fully responsible for all activities that occur under the password or account. InterioKeys has no control and expressly disclaims any liability derived therefrom over the use of any customer’s account. If the customer is suspicious about the activities under the account or breach of account, he/she should immediately reach InterioKeys to block the account.
  </p>

  <b className="mb-0">Termination of Service</b>
  <p>
  InterioKeys.in reserves the right to suspend or terminate your association with us for violation of any of our policies, posted on the website time to time. The termination will be with prior notice via mail and it will be effective immediately after the delivery of notice. InterioKeys also reserves the right to take legal action, including without limitation pursuing civil, criminal, and injunctive redress, for inappropriate or offensive behavior. Breach or violation of any of the policies will enforce the termination or expiration of the agreement with InterioKeys. The termination of agreement will also enforce the cancellation of ongoing requested services, refunds and other disputes by the customer. Any additional charges asked by service provider must be informed to customer care to  <a href="mailto:support@InterioKeys.com"> support@InterioKeys.com</a> before making such payments.
  </p>

  <b className="mb-0">Billing and Payment Policy</b>
  <p>
  InterioKeys offers its customer to pay the service provider either mobile wallet, online using netbanking/ debit card or by cash. InterioKeys expressly claims no responsibility of refund for payment made by cash. Customer is liable to pay the invoiced amount immediately after the work completion.  InterioKeys takes no responsibility for money paid to Service Provider without our knowledge or we have no control over payments made in cash to Service Provider other than what is agreed between InterioKeys and customer. Hence it is important that payments to be made directly to InterioKeys via online payment options availiable on the platform. We discourage any tips or reward to any of our Service Provider however if any customer is doing so is completely based on their own will and decision.
  If the money gets deducted from the user account and the payment is not reflected in the application, you are requested to contact the InterioKeys customer care support at  <a href="mailto:support@InterioKeys.com"> support@InterioKeys.com</a> Your payment will be reflected within 48 hours if the transaction is successfull at the payment gateway service provider. If your payment status is not updated in the given time frame, please contact the bank for further enqueries, InterioKeys does not take responsibility on such cases and payment will reflect in your bank account as per Bank's TAT.
  </p>

  <b className="mb-0">Links to Other Websites</b>
  <p>
  InterioKeys may contain links to third-party websites or services that are not owned or controlled by Star Unity Ecom Pvt Ltd. InterioKeys Services has no control over and assumes no responsibility for, the content, privacy policies, or practices of any third party websites or services. You further acknowledge and agree that InterioKeys Services shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such websites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.
  </p>

  <b className="mb-0">Service Delivery</b>
  <p>
  InterioKeys strongly discourages any direct contact with our Service Provider or any service requested directly by the Customer with the InterioKeys Service Provider, as in such cases has no control or takes no responsibility on whatsoever situation or condition. InterioKeys does not provide or deal with material or spare parts required for delivering the service. Customers are requested to buy the material their own to carry out the service. Service provider is solely responsible/ liable for damages caused during service delivery, in such events customer is requested to first contact the customer support team and then may lodge a complaint against the service provider, if the damage is resolved with mutual understanding. Customer is requested to report/inform InterioKeys immediately for any misconduct or unsatisfied behavior of any of our service provider so that we can take necessary action in order to resolve incidents and prevent the occurrence of such incidence in future. However, if the misconduct is falling in the category of breach of law then the customer may deal with that as per the applicable laws directly with the service provider and InterioKeys will not be responsible in such case. Also, if the service provider involves in any criminal activities during the service delivery or at any stage of service cycle the customer, at its own will can take direct action under the laws of the country and also keep us informed on full details of the same. InterioKeys will not be party to any such action.
  </p>

  <b className="mb-0">Governing Law</b>
  <p>
  These Terms shall be governed and construed in accordance with the laws of Country without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.
  </p>

  <b className="mb-0">Dispute Resolution Management</b>
  <p>
  InterioKeys reserves the right to manage, resolve or decline the disputes with customer. Any disputes raised by customer will be processed by the InterioKeys team and notify the customer on acceptance of dispute, via mail along with complaint number.  InterioKeys team will enquire the complaint and based on the findings customer will be receiving the response from InterioKeys team within 7 working days from the day of complaint. If the customer is not satisfied with the resolution, the parties agree to resolve the matter amicably vide arbitration under the Indian Arbitration & Conciliation Act, 1996. The venue of the arbitration shall be Jaipur, Rajasthan, India. The language of the arbitration shall be English.
  </p>

  <b className="mb-0">Changes to the 'Terms & Conditions' and Other Policies</b>
  <p>
  InterioKeys reserves the right, at our sole discretion, to modify, delete or replace the terms and conditions or any other policies at any time, with or without prior notice to the customer. What constitutes a material change will be determined at our sole discretion. We ensure our sincere effort to bring the changes via mail and application to accept the changes. We request the customers and service providers to visit the policies and terms & conditions at a certain frequency to update the same. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
  </p>

  <b className="mb-0">Contact Us</b>
  <p>
  If you have any queries regarding our services, please write to us on  
  <a href="mailto:support@InterioKeys.com"> support@InterioKeys.com</a> @Star Unity Ecom Pvt Ltd.
  </p>

  <p className="mb-0">
    Please review these Terms carefully. Your use of InterioKeys signifies your acceptance of these Terms and any future updates.
  </p>
</div>
      </div>
    </Container>
  );
};

export default Terms;
