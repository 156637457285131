import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { LuEyeOff, LuEye, LuMail } from "react-icons/lu";
import { designersignup } from "assets";
import { useLocation, useNavigate } from "react-router-dom";

const DesignerSignup = ({
  form,
  requiredSubtypes,
  step,
  setStep,
  handleSignUp,
  verifyOtpLogin,
  loading,
  emailError,
  setEmailError,
  setUsername,
  otp,
  setOtp,
  setIsResendDisabled,
  isResendDisabled,
  showOtpFields,
  setShowOtpFields,
  resendTimer,
  setResendTimer,
}) => {
  const [password, setPassword] = useState("");
  // const [showOtpFields, setShowOtpFields] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_subtype: "",
    full_name: "",
    company_name: "",
    mobile: "",
    email: "",
    agree_terms: false,
  });

  useEffect(() => {
    let timer;
    if (showOtpFields && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setIsResendDisabled(false);
    }
    return () => clearInterval(timer);
  }, [showOtpFields, resendTimer]);

  useEffect(() => {
    if (requiredSubtypes?.length > 0) {
      setFormData((prev) => ({
        ...prev,
        user_subtype: requiredSubtypes[0].id,
      }));
    }
  }, [requiredSubtypes]);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    const otpArray = otp.split("");

    if (value.match(/^\d$/)) {
      otpArray[index] = value;
      setOtp(otpArray.join(""));

      // Move to the next input if there is one
      if (index < 3) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
    else if (value === "") {
      otpArray[index] = "";
      setOtp(otpArray.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    // alert(e.key);
    const otpArray = otp.split("");
    console.log(otpArray);
    if (e.key === "Backspace" && (otpArray[index] === "" || otpArray.length < (index + 1)) && index > 0) {
      // Move focus to previous field and clear its value
      let x = document.getElementById(`otp-${index - 1}`);
      console.log(x);
      x.focus();
      setOtp((prev) => {
        const otpArray = prev.split("");
        otpArray[index - 1] = "";
        return otpArray.join("");
      });
    }
  };


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // For email fields, convert to lowercase
    const finalValue = name === 'email' ? value.toLowerCase() : (type === "checkbox" ? checked : value);
    
    setFormData((prev) => ({
      ...prev,
      [name]: finalValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!showOtpFields) {
      const submissionData = {
        ...formData,
        email: formData.email.toLowerCase()
      };
      handleSignUp(submissionData);
    } else {
      verifyOtpLogin(otp);
    }
  };

  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center mt-5"
      style={{ minHeight: "70vh" }}
    >
      <Row style={{ maxWidth: 1000 }}>
        <Col
          lg={6}
          className="mb-3 mb-lg-0 d-flex flex-column justify-content-between align-items-center"
        >
          <Card
            style={{
              boxShadow: "none",
              borderRadius: 0,
              border: "none",
              borderLeft: "1px solid #BDC1C6",
            }}
          >
            <h4 className="text-gray-900 inter-600">Designer Registration</h4>
            <div className="text-gray-500">
              Showcase your projects to potential clients while you design for
              the top properties in India.
            </div>
          </Card>
          <Image
            src={designersignup}
            alt="Designer signup"
            className="mt-5"
            width="80%"
          />
        </Col>
        <Col lg={6}>
          <Form ref={form} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 body-text2 inter-500">
                Designer Category<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              {requiredSubtypes?.length > 0 ? (
                <Form.Select
                  className="py-2 body-text2"
                  placeholder="-Select Category-"
                  name="user_subtype"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  required
                  onChange={handleChange}
                  disabled={showOtpFields}
                >
                  {requiredSubtypes.map((type, index) => (
                    <option key={index} value={type.id}>
                      {type.title}
                    </option>
                  ))}
                </Form.Select>
              ) : (
                <div
                  className="py-2 placeholder-glow"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                    height: "38px", // Match the height of the dropdown
                  }}
                >
                  <span className="placeholder col-12 h-100"></span>
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 body-text2 inter-500">
                Name<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control
                required
                className="py-2 body-text2"
                type="text"
                name="full_name"
                placeholder="Enter your Full Name"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                onChange={handleChange}
                disabled={showOtpFields}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 body-text2 inter-500">
                Company Name<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control
                required
                className="py-2 body-text2"
                type="text"
                name="company_name"
                placeholder="Enter your Company Name"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                onChange={handleChange}
                disabled={showOtpFields}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 body-text2 inter-500">
                Phone Number<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text
                  className="body-text2 inter-500"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #BDC1C6",
                    borderRadius: "3px 0px 0px 3px",
                  }}
                >
                  +91
                </InputGroup.Text>
                <Form.Control
                  className="border-start-0 p-2 body-text2"
                  type="text"
                  name="mobile"
                  required
                  placeholder="9090909090"
                  maxLength={10}
                  pattern="[0-9]{10}" // Ensures exactly 6 digits
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, ""); // Removes non-numeric characters
                  }}
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: "0px 3px 3px 0px",
                  }}
                  onChange={handleChange}
                  disabled={showOtpFields}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 body-text2 inter-500">
                Company Email<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #BDC1C6",
                    borderRadius: "3px 0px 0px 3px",
                  }}
                >
                  <LuMail size={20} color="#1A1C1F" />
                </InputGroup.Text>
                <Form.Control
                  className="border-start-0 p-2 body-text2"
                  type="email"
                  name="email"
                  required
                  placeholder="example@gmail.com"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: "0px 3px 3px 0px",
                  }}
                  // disabled={showOtpFields}
                  onChange={(e) => {
                    // Ensure email is lowercase when setting username
                    if (e.target.name === 'email') {
                      setUsername(e.target.value.toLowerCase());
                    } else {
                      setUsername(e.target.value);
                    }
                    handleChange(e);
                  }}
                  onFocus={() => setEmailError("")}
                />
              </InputGroup>
              <div className="d-flex flex-column">
                {emailError && (
                  <span className="body-text3 text-danger inter-400">
                    {emailError}
                  </span>
                )}
                <span className="body-text3 text-gray-700 inter-400">
                  Note: Your Email ID cannot be changed later. Choose one
                  accordingly.
                </span>
              </div>
            </Form.Group>
            {showOtpFields && (
              <>
                <Form.Group className="my-3">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    OTP<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Row>
                    {[0, 1, 2, 3].map((index) => (
                      <Col key={index} xs="3">
                        <Form.Control
                          required
                          id={`otp-${index}`}
                          maxLength="1"
                          pattern="[0-9]{1}" // Ensures exactly 6 digits
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, ""); // Removes non-numeric characters
                          }}
                          value={otp[index] || ""}
                          onChange={(e) => handleOtpChange(e, index)}
                          style={{
                            textAlign: "center",
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                      </Col>
                    ))}
                  </Row>
                  <Form.Text className="text-success">
                    OTP has been sent to the email address
                  </Form.Text>
                </Form.Group>
                <div className="text-center mb-3">
                  {isResendDisabled ? (
                    <span className="text-muted">
                      Resend OTP in {resendTimer} seconds
                    </span>
                  ) : (
                    <span
                      className="text-primary cursor-pointer"
                      onClick={(e) => handleSignUp(e)}
                      style={{ textDecoration: "underline" }}
                    >
                      Resend OTP
                    </span>
                  )}
                </div>
              </>
            )}

            <Form.Group className="mb-1">
              <Form.Check
                type="checkbox"
                label={
                  <div className="body-text2 inter-400">
                    Agree to{" "}
                    <span
                      className="text-primary-500 cursor-pointer"
                      onClick={() => window.open("/terms", "_blank")}
                    >
                      Terms and conditions
                    </span>{" "}
                    &{" "}
                    <span
                      className="text-primary-500 cursor-pointer"
                      onClick={() => window.open("/privacy", "_blank")}
                    >
                      Privacy Policy
                    </span>
                  </div>
                }
                required
              />
            </Form.Group>

            <div className="d-flex mt-5 w-100">
              <Button
                className="px-3 py-2 me-3 text-gray-900 bg-white border-gray-200"
                style={{ borderRadius: 3 }}
                onClick={() => setStep(step - 1)}
                disabled={loading || showOtpFields}
              >
                Back
              </Button>
              <Button
                className="w-100 p-2"
                type="submit"
                style={{ borderRadius: 3 }}
                disabled={loading}
              >
                {loading && (
                  <Spinner animation="border" size="sm" className="me-2" />
                )}
                {showOtpFields ? "Verify OTP" : "Register"}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default DesignerSignup;
