import { builder_partner_1, builder_partner_2, builder_partner_3, builder_partner_4 } from "assets";
import React from "react";
import { Card, Carousel, Col, Row } from "react-bootstrap";

const CustomSliderForBuilder = () => {
    const builderData = [
        {
            id: 1,
            image: `${builder_partner_1}`,
            title: "Jewel of India"
        },
        {
            id: 2,
            image: `${builder_partner_2}`,
            title: "Mahima Shubh Nilay"
        },
        {
            id: 3,
            image: `${builder_partner_3}`,
            title: "Divinity Trimurthy"
        },
        {
            id: 4,
            image: `${builder_partner_4}`,
            title: "Infinia Park"
        }
    ];

    const pairedData = [];
    for (let i = 0; i < builderData.length; i += 2) {
        pairedData.push(builderData.slice(i, i + 2));
    }

    return (
        <Carousel
            controls={false}
            indicators={false}
            interval={null}
            touch={true}
            className="builder-carousel py-2"
        >
            {pairedData.map((pair, index) => (
                <Carousel.Item key={index} className="py-2">
                    <Row className="">
                        {pair.map((item) => (
                            <Col xs={6} key={item.id}>
                                <Card className="p-0" style={{
                                    maxWidth: '100%'
                                }}>
                                    <Card.Img
                                        variant="top"
                                        src={item.image}
                                        style={{
                                            height: '100px',
                                            objectFit: 'fill'
                                        }}
                                    />
                                    <Card.Body className="p-2">
                                        <Card.Title className="body-title inter-400 text-truncate mb-0">
                                            {item.title}
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Carousel.Item>
            ))}
        </Carousel>
    );

};
export default CustomSliderForBuilder;