import { AccountEditForm, ChangePasswordForm } from "components";
import { Container, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import SavedProperties from "./SavedProperties";
import ArchitectThoughtForm from "components/Forms/ArchitectThoughtForm";
import { useEffect, useState } from "react";
import {
  fetchBrandsThought,
  fetchThoughts,
  fetchTeamMembers,
  fetchGallery,
} from "reduxFiles/actions/authActions";
import { useLocation } from "react-router-dom";
import BrandThoughtForm from "components/Forms/BrandThoughtForm";
import SavedProducts from "./SavedProducts";
import Gallery from "components/Gallery/Gallery";
import TeamMemberList from "components/TeamMember/TeamMemberList";
import { notify } from "utilities/notifications/Notify";
import * as authActions from "reduxFiles/actions/authActions";
import { FaEye } from "react-icons/fa";
import ProfilePreview from "./ProfilePreview";

const ProfessionalSettings = () => {
  const user = useSelector((state) => state.auth.user);
  const [thoughts, setThoughts] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);
  const [media, setMedia] = useState([]);
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { pathname } = useLocation();
  const [showProfilePreview, setShowProfilePreview] = useState(false); // Add this state

  // Fetch thoughts
  useEffect(() => {
    const fetchData = async () => {
      const response = pathname.includes("brand")
        ? await fetchBrandsThought(user.id)
        : await fetchThoughts(user.id);
      setThoughts(response.data.results[0]);
    };
    fetchData();
  }, [user.id, pathname]);

  // Fetch team members
  useEffect(() => {
    const fetchTeamData = async () => {
      const response = await fetchTeamMembers({ user_id: user.id });
      if (response) setTeamMembers(response.data);
    };
    fetchTeamData();
  }, [user.id]);

  // Fetch gallery media
  const fetchGalleryData = async () => {
    const response = await fetchGallery({ user_id: user.id });
    if (response) setMedia(response.data);
  };

  useEffect(() => {
    fetchGalleryData();
  }, [user.id]);

  // Handle adding a team member
  const handleAddTeamMember = async (formData) => {
    setIsSubmitting(true);
    try {
      formData.append("user_id", user.id);
      const response = await authActions.addTeamMember(formData);
      if (response.status === 201) {
        notify("Team member added successfully", "success");
        setShowAddTeamModal(false);
        const teamResponse = await fetchTeamMembers({ user_id: user.id });
        if (teamResponse) setTeamMembers(teamResponse.data);
      } else {
        notify("Failed to add team member", "error");
      }
    } catch (error) {
      console.error("Error while adding team member:", error);
      notify("Error while adding team member", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle editing a team member
  const handleEditTeamMember = async (id, formData) => {
    setIsSubmitting(true);
    try {
      const response = await authActions.updateTeamMember(id, formData);
      if (response.status === 200) {
        notify("Team member updated successfully", "success");
        const teamResponse = await fetchTeamMembers({ user_id: user.id });
        if (teamResponse) setTeamMembers(teamResponse.data);
      } else {
        notify("Failed to update team member", "error");
      }
    } catch (error) {
      console.error("Error while updating team member:", error);
      notify("Error while updating team member", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle deleting a team member
  const handleDeleteMember = async (id) => {
    setIsSubmitting(true);
    try {
      const response = await authActions.deleteTeamMember(id);
      if (response.status === 204) {
        notify("Team member deleted successfully", "success");
        const teamResponse = await fetchTeamMembers({ user_id: user.id });
        if (teamResponse) setTeamMembers(teamResponse.data);
      } else {
        notify("Failed to delete team member", "error");
      }
    } catch (error) {
      console.error("Error while deleting team member:", error);
      notify("Error while deleting team member", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle view public profile - replace the existing function with this
  const handleViewPublicProfile = () => {
    setShowProfilePreview(true);
    // Scroll to top of page for better user experience
    window.scrollTo(0, 0);
  };

  return (
    <Container fluid className="mt-5 pt-4 px-0" style={{ minHeight: "100vh" }}>
      {showProfilePreview ? (
        <ProfilePreview 
          user={user} 
          onBack={() => setShowProfilePreview(false)} 
        />
      ) : (
        <Container>
          <div className="d-flex justify-content-between align-items-center py-5 mb-0">
            <h3 className="inter-600 text-gray-900">Settings</h3>
            <Button 
              variant="outline-primary" 
              className="d-flex align-items-center gap-2"
              onClick={handleViewPublicProfile}
            >
              <FaEye /> View Public Profile
            </Button>
          </div>

          <AccountEditForm user={thoughts} />
          {pathname.includes("brand") ? (
            <BrandThoughtForm thoughts={thoughts} user={user} />
          ) : (
            <ArchitectThoughtForm thoughts={thoughts} user={user} />
          )}
          {/* <SavedProperties context="editProfile" showAllRecords={false} />
          <SavedProducts context="editProfile" showAllRecords={false} /> */}

          {/* Team Members Section */}
          <div className="mb-4">
            <div className="h4 inter-600 mt-3">Our Team</div>
            <TeamMemberList
              teamMembers={teamMembers}
              handleDeleteMember={handleDeleteMember}
              handleAddTeamMember={handleAddTeamMember}
              handleEditTeamMember={handleEditTeamMember}
              showAddTeamModal={showAddTeamModal}
              setShowAddTeamModal={setShowAddTeamModal}
              isSubmitting={isSubmitting}
              enableEdit={
                user?.user_type === "brand" ||
                user?.user_type === "architect" ||
                user?.user_type === "builder"
              }
            />
          </div>

          {/* Gallery Section */}
          <div className="mb-4">
            <div className="h4 inter-600">Gallery</div>
            <Gallery
              items={media}
              fetchGalleries={fetchGalleryData}
              enableEdit={
                user?.user_type === "brand" ||
                user?.user_type === "architect" ||
                user?.user_type === "builder"
              }
            />
          </div>
        </Container>
      )}
    </Container>
  );
};

export default ProfessionalSettings;