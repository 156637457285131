import { mobile_joi_property, walk_through, somani_house } from 'assets';
import React from 'react';
import { Carousel, Card } from 'react-bootstrap';

const StepsCarousel = () => {
    const accordionItems = [
        {
            id: "step_1",
            title: "List Your Properties",
            image: `${mobile_joi_property}`,
            // property_title: "Jewel of India/Suncity Builders",
            // layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft) ",
            // location: "Location: Vaishali Nagar, Jaipur, India",
            content: 
            "List your properties with all relevant files (brochures, floor pans, AutoCAD files  etc.) for designers to create inspirations of your properties.",
            step: "Step 1",
        },
        {
            id: "step_2",
            title: "Attract InterioAlly",
            image: `${walk_through}`,
            // property_title: "Mangalam Tarang/Mangalam Group",
            // layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft)",
            // location: "Location: Vaishali Nagar, Jaipur, India",
            content:
            "Now InterioAlly which is designers and brands will create real inspirations for your apartments to showcase their creativity and attract potential buyers to work with them.",
            step: "Step 2",
        },
        {
            id: "step_3",
            title: "Standout from the Crowd",
            image: `${somani_house}`,
            // property_title: "Suncity Avenue/Suncity Builders",
            // layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft)",
            // location: " Location: Vaishali Nagar, Jaipur, India",
            content: 
            "Thousands of potential customers view your property through design inspirations grabbing attention globally.",
            step: "Step 3",
        },
    ];

    return (
        <Carousel
            controls={false}
            indicators={true}
            interval={null}
            touch={true}
            className="builder-carousel w-100"
        >
            {accordionItems.map((item) => (
                <Carousel.Item key={item.id}>
                    <div className="mb-4 w-100">
                        <Card className="shadow-sm w-100 text-start" style={{ padding: '12px', borderRadius: '6px'}}>
                            <div className="px-0">
                                <div className="mb-2">
                                    <p className="text-gray-500 inter-400" style={{ fontSize: '12px', lineHeight: '15px' }}>{item.step}</p>
                                </div>

                                <p className="text-primary-500 inter-400 mb-0" style={{ fontSize: '14px' }}>{item.title}</p>

                                <p className="text-gray-900 inter-400" style={{ fontSize: '12px' }}>
                                    {item.content}
                                </p>

                                <Card.Img
                                    variant="top"
                                    src={item.image}
                                    className="mb-0"
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        objectFit: 'cover',
                                        borderRadius: '6px'
                                    }}
                                />

                                <div style={{ padding: '6px' }}>
                                    <p className="body-title inter-600 text-gray-800 mb-0">{item.property_title}</p>
                                    <div className="body-text1 inter-400 text-gray-800">
                                        <p className="mb-0">{item.layout}</p>
                                        <p className="mb-0">{item.location}</p>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default StepsCarousel;