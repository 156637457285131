import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Slider from "react-slick";

import Banners from "pages/AboutUs/Banners";
import HeroBanner from "pages/AboutUs/HeroBanner";
import ServiceCard from "pages/AboutUs/ServiceCard";
import ProjectCard from "components/Cards/ProjectCard";
import TestimonialCard from "components/Cards/TestimonialCard";
import VerticalSlider from "pages/AboutUs/VerticalSlider";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import BrandOnInspirations from "pages/AboutUs/BrandOnInspirations";

import { ArchitectsForDisplay, HowItWorks } from "components";
import { Breakpoint } from "react-socks";
import TestimonialCardMobile from "components/Cards/TestimonialCardMobile";

const AboutUs = () => {
  const small = useMediaQuery({
    query: "(min-width: 576px) and (max-width: 991px)",
  });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const navigate = useNavigate();
  const targetRef = useRef(null);

  const [brands, setBrands] = useState([]);
  const [recommendedProjects, setRecommendedProjects] = useState([]);
  const [recommendedArchitects, setRecommendedArchitects] = useState([]);
  const [clientTestimonials, setClientTestimonials] = useState(null);
  const [architectTestimonials, setArchitectTestimonials] = useState(null);
  const [builderTestimonials, setBuilderTestimonials] = useState(null);
  const [brandTestimonials, setBrandTestimonials] = useState(null);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const card_info = [
    {
      id: 1,
      title: "Real Inspirations",
      description:
        "You can choose from a variety of upcoming and completed interior projects by InterioKeys",
    },
    {
      id: 2,
      title: "No intermediaries",
      description:
        "InterioKeys maintains a direct connection between itself, the craftsmen, manufacturers and the end consumers.",
    },
    {
      id: 3,
      title: "Cost Reduction",
      description:
        "Pre-made designs and direct brands dealing to reduce interior costs.",
    },
  ];

  const fetchBrands = async () => {
    try {
      const response = await authActions.fetchRecommendedBrands();
      if (response.data) {
        setBrands(response.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecommendedProjects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setRecommendedProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchRecommendedArchitects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedArchitects();
      if (response.data) {
        setRecommendedArchitects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchTestimonialsWithType = async () => {
    try {
      const response = await authActions.fetchTestimonialsWithType();
      if (response.data) {
        setClientTestimonials(response.data?.client_testimonials);
        setArchitectTestimonials(response.data?.architect_testimonials);
        setBuilderTestimonials(response.data?.builder_testimonials);
        setBrandTestimonials(response.data?.brand_testimonials);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBrands();
    fetchRecommendedProjects();
    fetchRecommendedArchitects();
    fetchTestimonialsWithType();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-next-arrow"}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={35} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-prev-arrow"}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={35} />
      </div>
    );
  }

  const sliderSettings = {
    customPaging: function (i) {
      return <div className="custom-dot mt-3" />;
    },
    dotsClass: "slick-dots",
    dots: small ? false : true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    slidesToShow: large ? 3 : small ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  /* const sliderSettings = {
    autoplay: true, // Ensure autoplay is true
    autoplaySpeed: 3000, // Reduce speed for better testing
    speed: 500, // Transition speed
    cssEase: "linear",
    dots: !small, // Adjust as per need
    infinite: true,
    slidesToShow: large ? 3 : small ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }; */

  return (
    <Container fluid className="mt-5 px-0">
      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center text-center"
        style={{
          minHeight: !small ? "90vh" : "100vh",
          padding: !small ? "26px 16px" : "0px",
        }}
      >
        <Container className={`p-0 ${!small ? "mt-3" : "mt-5"}`}>
          <div className="h1 px-lg-5 inter-600">
            Discover the Finest Real Designs for India's Premium Spaces, Crafted
            by Top Designers{" "}
          </div>
          <div className="text-gray-600 body-title inter-400 my-3">
            Explore High-Quality Designs Featuring Top Brands, Expertly Tailored
            to Your Unique Taste
          </div>
          <Button
            className="text-white body-text2 inter-600 mb-4"
            style={{ borderRadius: 3 }}
            onClick={() => navigate("/projects")}
          >
            Explore Inspirations!
          </Button>
          {card_info && (
            <HeroBanner
              items={recommendedProjects.slice(0, 3)}
              card_info={card_info}
            />
          )}
        </Container>
        {small && (
          <>
            <Button
              className="d-flex justify-content-center align-items-center text-center border-0 mt-1 p-2"
              style={{ borderRadius: "50%", background: "#F1F3F4" }}
              ref={targetRef}
              onClick={() =>
                targetRef.current?.scrollIntoView({ behavior: "smooth" })
              }
            >
              <IoIosArrowDown size={20} color="#3C4043" />
            </Button>
          </>
        )}
      </Container>
      <Container fluid className="bg-gray-25 py-lg-5 py-3">
        <Container
          className="d-flex flex-column justify-content-center align-items-center text-center"
          style={{ minHeight: large ? "90vh" : "70vh" }}
        >
          <div
            className="h2 text-center inter-600 m-0 mb-4"
            // style={{ padding: "0 10%" }}
          >
            Explore multiple brands and there products under different
            categories <br></br>that matches your requirements best{" "}
          </div>
          {/* <div className="text-gray-600 body-title inter-400 my-3">
            Reducing premium interior cost by 20-30% to give you the best brands
            at great prices!
          </div> */}
          <div>
            <Button
              className="body-text2 inter-500 text-white mb-4"
              style={{ borderRadius: 3 }}
              onClick={() => navigate("/brands")}
            >
              Explore Our Brands Partners!
            </Button>
          </div>
          <Breakpoint large up>
            {brands.length > 0 && (
              <Banners
                items={brands.map((item) => ({
                  id: item.id,
                  title: item.company_name,
                  image: item.image,
                }))}
                speed={2000}
              />
            )}
          </Breakpoint>

          <BrandOnInspirations recommendedProjects={recommendedProjects} />
          <Breakpoint large down>
            <Row className="mt-3">
              {brands &&
                brands?.slice(0, 4)?.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      className={`${index % 2 === 0 ? "pe-1" : "ps-1"}`}
                    >
                      <Card
                        className="d-flex justify-content-center align-items-center my-1 shadow-none border-gray-100"
                        style={{ height: 42, borderRadius: 3 }}
                        onClick={() => navigate(`/brands/${item.slug}`)}
                      >
                        <Image
                          src={item?.image}
                          alt={item?.title}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </Card>
                    </Col>
                  );
                })}
            </Row>
            <div className="text-gray-600 body-title">and many more..</div>
          </Breakpoint>
        </Container>
      </Container>
      <ArchitectsForDisplay recommendedArchitects={recommendedArchitects} />
      <HowItWorks />
      <Container className="my-5 py-5 d-flex flex-column justify-content-center align-items-center">
        <div className="h2 inter-600 text-center mb-0">
          Streamline your home interior process through InterioKeys!
        </div>
        <div className="text-gray-600 body-title inter-400 text-center my-3">
          At InterioKeys, you can choose from the 2 types of services provided.
        </div>
        <ServiceCard />
      </Container>
      <Container fluid className="bg-gray-25 py-5 my-5">
        <Container className="d-flex flex-column justify-content-center align-items-center text-center">
          <div className="h2 inter-600 text-center mb-0">
            Trending Projects in your City!
          </div>
          {/* <div className="text-gray-600 body-title inter-400 text-center my-3">
            Check out the projects trending with our homeowners.
          </div> */}
          {/* <Button
            variant="info"
            className="d-inline-flex align-items-center inter-600 text-primary py-2 px-3 my-3"
            style={{ borderRadius: 3, cursor: "default" }}
          >
            <FaStar size={20} className="me-2" />
            Interiokeys Recommended
          </Button> */}
          {recommendedProjects && (
            <div className="w-100">
              <Slider {...sliderSettings}>
                {recommendedProjects?.map((project, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-center py-1 px-2"
                  >
                    <ProjectCard
                      id={project.id}
                      slug={project.slug}
                      project_name={project.project_name}
                      // image={project.rooms[0]?.media[0]?.file}
                      roomId={project?.rooms[0]?.id}
                      is_sample_flat={project?.is_sample_flat}
                      type={`${project.bhk}, ${project.project_area}`}
                      architect={project?.company_name}
                      builders={
                        project.property_data?.builder_data?.company_name
                      }
                      boxShadow="none"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          )}
          {/* <Button
          className="body-title inter-600 text-white py-2 px-3"
          style={{ borderRadius: 3 }}
          onClick={() => navigate("/projects")}
        >
          Inspirations From Your City
        </Button> */}
        </Container>
      </Container>
      <Container className="d-flex justify-content-center align-items-center my-5 py-5">
        <div className="w-100">
          <h2
            className={`inter-600 mb-4 ${small ? "text-start" : "text-center"}`}
          >
            Listen to What Our Clients Say!
          </h2>
          <h3 className={`inter-600 ${!small ? "d-none" : ""}`}>
          InterioKeys Clients
          </h3>
          <Row className="mb-4">
            {clientTestimonials && clientTestimonials.length > 0 ? (
              <>
                {small ? (
                  // Render all testimonials for larger screens
                  clientTestimonials.slice(0, 3).map((testimonial, index) => (
                    <Col key={index} md={4} className="mb-3">
                      <TestimonialCard testimonial={testimonial} />
                    </Col>
                  ))
                ) : (
                  // Render only the first testimonial card on mobile screens
                  <Col className="mb-3">
                    <TestimonialCardMobile
                      testimonial={clientTestimonials[0]}
                    />
                  </Col>
                )}
              </>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                style={{ height: 140 }}
              >
                No testimonials available
              </div>
            )}
          </Row>
          <h3 className={`inter-600 mb-lg-2 ${!small ? "text-center" : ""}`}>
          InterioKeys Partners
          </h3>
          <Row>
            {small ? (
              // Render VerticalSlider for larger screens (576px and above)
              <div
                className="d-flex justify-content-center"
                style={{ overflowX: "hidden" }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {builderTestimonials && (
                    <VerticalSlider items={builderTestimonials} rtl={false} />
                  )}
                  <span className="inter-600 mt-4">Builders</span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {architectTestimonials && (
                    <VerticalSlider items={architectTestimonials} rtl={true} />
                  )}
                  <span className="inter-600 mt-4">Architects</span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {brandTestimonials && (
                    <VerticalSlider items={brandTestimonials} rtl={false} />
                  )}
                  <span className="inter-600 mt-4">Brands</span>
                </div>
              </div>
            ) : (
              // Render only one card for each category on smaller screens
              <div>
                <div className="p-0 d-flex flex-column justify-content-center align-items-center mb-4">
                  <span className="inter-600 mt-2 mb-2">Builders</span>
                  {builderTestimonials && builderTestimonials[0] && (
                    <TestimonialCardMobile
                      testimonial={builderTestimonials[0]}
                    />
                  )}
                </div>
                <div className="p-0 d-flex flex-column justify-content-center align-items-center mb-4">
                  <span className="inter-600 mt-2 mb-2">Architects</span>
                  {architectTestimonials && architectTestimonials[0] && (
                    <TestimonialCardMobile
                      testimonial={architectTestimonials[0]}
                    />
                  )}
                </div>
                <div className="p-0 d-flex flex-column justify-content-center align-items-center">
                  <span className="inter-600 mt-2 mb-2">Brands</span>
                  {brandTestimonials && brandTestimonials[0] && (
                    <TestimonialCardMobile testimonial={brandTestimonials[0]} />
                  )}
                </div>
              </div>
            )}
          </Row>
        </div>
      </Container>
    </Container>
  );
};

export default AboutUs;
