import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const WelcomeBanner = ({ activeTab, pageType }) => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const styles = {
    container: {
      width: "100%",
      paddingTop: "42px",
      paddingRight: "48px",
      paddingBottom: "42px",
      paddingLeft: "48px",
      backgroundColor: "#F6F6F6",
      textAlign: "center",
      fontFamily: "sans-serif",
      borderRadius: "4px",
      margin: "0 auto",
      maxWidth: "100%",
    },
    heading: {
      fontSize: "clamp(1.2rem, 4vw, 1.5rem)",
      fontWeight: "600",
      lineHeight: "38px",
      letterSpacing: "-3%",
      color: "#333",
      marginBottom: "2rem",
    },
    username: {
      color: "#C53D3D",
      fontWeight: "600",
    },
    description: {
      fontSize: "clamp(0.9rem, 3vw, 1rem)",
      color: "#3C4043",
      maxWidth: "1200px",
      lineHeight: "20.8px",
      margin: "0 auto 1rem",
    },
    linkContainer: {
      marginTop: "16px",
      weight: "500",
      fontSize: "20px",
    },
    link: {
      color: "#C53D3D",
      weight: "700",
      fontSize: "20px",
      display: "inline-flex",
      alignItems: "center",
      gap: "4px",
    },
  };
  const [heading, setHeading] = useState(null);
  const [subHeading, setSubHeading] = useState(null);
  const [descriptions, setDescriptions] = useState("");
  const [subDescription, setSubDescription] = useState("");

  const tutorialLink =
    pageType === "builder"
      ? "https://youtu.be/EdoaeqJweM8"
      : pageType === "architect"
      ? "https://youtu.be/GWwrTkTX1QE"
      : "#";

  useEffect(() => {
    setHeading(
      location?.pathname.includes("builder") ? (
        <>
          Welcome to InterioKeys,{" "}
          <span style={styles.username}>{user?.full_name}</span>!
        </>
      ) : location?.pathname.includes("brand") ? (
        <>
          Welcome Back, <span style={styles.username}>{user?.full_name}</span>
        </>
      ) : location?.pathname.includes("architect") ? (
        <>
          Welcome Back, <span style={styles.username}>{user?.full_name}</span>
        </>
      ) : (
        ""
      )
    );

    setSubHeading(
      location?.pathname.includes("builder") ? (
        <>
          Welcome to InterioKeys,{" "}
          <span style={styles.username}>{user?.full_name}</span>!
        </>
      ) : location?.pathname.includes("brand") ? (
        <>
          Hey <span style={styles.username}>{user?.full_name}</span>, inspire us!
        </>
      ) : location?.pathname.includes("architect") ? (
        <>
          Hey <span style={styles.username}>{user?.full_name}</span>, inspire us!
        </>
      ) : (
        ""
      )
    );

    setDescriptions(
      location?.pathname.includes("builder")
        ? `List your properties with all relevant files (brochures, floor plans,
          AutoCAD files, etc.) for designers to create inspirations for your
          properties sample flat.`
        : location?.pathname.includes("brand")
        ? `The Property listing page features builder-listed properties with design files. Listings move to "My designed Properties" after submission.`
        : location?.pathname.includes("architect")
        ? `The property listing page features builder-listed properties with design files. Listings move to "My Designed Properties" after inspiration submission.`
        : ""
    );

    setSubDescription(
      location?.pathname.includes("builder")
        ? `The Archived Property page stores removed projects, hiding them from
          designers and brands. Re-list anytime for design access.`
        : location?.pathname.includes("brand")
        ? "My designed properties show all the listings with your submitted inspiration. Click any listing to view your projects."
        : location?.pathname.includes("architect")
        ? "My designed properties show all the listings with your submitted inspirations. Click any listing to view your projects."
        : ""
    );
  }, [location?.pathname]);

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>
        {activeTab === "listed" ? heading : subHeading}
      </h1>
      {activeTab === "listed" ? (
        <p style={styles.description}>{descriptions}</p>
      ) : activeTab === "sample" ? (
        <p style={styles.description}>
          Find all your property sample flat designs listed here.
        </p>
      ) : (
        <p>{subDescription}</p>
      )}
      <div style={styles.linkContainer}>
        Curious to know how InterioKeys works? Watch{" "}
        {activeTab === "sample" ? (
          <span style={styles.link}>Tutorial Video</span>
        ) : (
          <a
            href={tutorialLink}
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            Tutorial Video
          </a>
        )}
      </div>
    </div>
  );
};

export default WelcomeBanner;
