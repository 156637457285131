import React, { useEffect, useRef, useState, useMemo } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import ReactSelect from "react-select";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FaCircleMinus, FaXmark } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import BrandCards from "./BrandCards";
import { notify } from "utilities/notifications/Notify";
import { formatNumber } from "utilities/numberFormatter";
import AddPropertyModal from "./AddPropertyModal";
import LoadingOverlay from "components/General/LoadingOverlay";
import DropFileInputProject from "components/DragInput/DropFileInputProject";
import AddProductsModal from "./AddProductsModal";
import * as authActions from "reduxFiles/actions/authActions";
import { MdDelete } from "react-icons/md";
import DropFileInputProjectNew from "components/DragInput/DropFileInputProjectNew";
import AddProjectDetails from "./AddProjectDetails";
import AddProjectForm from "./AddProjectForm";
import AddProjectRoomSelector from "./AddProjectRoomSelector";
import AddBrandsModel from "./AddBrandsModel";

function AddProject() {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const { propertyId } = useParams();
  const { status } = useParams();
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const isSampleFlat = location.pathname.includes("builder/addproject")
    ? true
    : false;
  const projectInfoRef = useRef();
  const [brandInfo, setBrandInfo] = useState(null);
  const [architectInfo, setArchitectInfo] = useState(null);
  const [builderInfo, setBuilderInfo] = useState(null);
  const [projectDetails, setProjectDetails] = useState({});
  const defaultAddProperty = {
    id: "add",
    property_name: "Non Listed Property",
  };
  const [properties, setProperties] = useState([]);
  const [propertyName, setPropertyName] = useState("");
  const [builderPropertyName, setBuilderPropertyName] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedRoomtypes, setSelectedRoomtypes] = useState([]);
  const [roomsToDisplay, setRoomsToDisplay] = useState(null);
  const [roomtypeIndex, setRoomTypeIndex] = useState(0);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [customProperty, setCustomProperty] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roomTypes, setRoomTypes] = useState([]);
  const [selectedLayout, setSelectedLayout] = useState("");
  const [activeRoomIndex, setActiveRoomIndex] = useState(0);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [temps, setTemps] = useState([]);
  const [displaySelectedBrands, setDisplaySelectedBrands] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [invalidFields, setInvalidFields] = useState({});
  const [isGeneralInspiration, setIsGeneralInspiration] = useState(false);

  // Extract fromListedPropertyCard from location state
  const fromListedPropertyCard = location?.state?.fromListedPropertyCard || false;

  const fetchProperties = async () => {
    try {
      const response = await authActions.fetchAllProperties();
      if (response?.data) {
        setProperties([...response.data]);
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPropertiesByBuilderNoPagination = async () => {
    try {
      const response = await authActions.fetchPropertiesByBuilderNoPagination(
        user?.id
      );
      if (response?.data) {
        setProperties([...response?.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (customProperty) {
      setProperties((prev) => [...prev, customProperty]);
    }
  }, [customProperty]);

  const validateCost = (cost) => {
    if (parseFloat(cost) < 0) {
      notify("BOQ Cost cannot be negative", "error");
      return false;
    }
    return true;
  };

  const toggleBrandSelection = (brandId) => {
    setSelectedBrands((prevSelected) =>
      prevSelected.includes(brandId)
        ? prevSelected.filter((id) => id !== brandId)
        : [...prevSelected, brandId]
    );

    setDisplaySelectedBrands((prevDisplay) => {
      const isAlreadySelected = prevDisplay.some(
        (brand) => brand.brand_data.id === brandId
      );

      if (isAlreadySelected) {
        return prevDisplay.filter((brand) => brand.brand_data.id !== brandId);
      } else {
        const brandToAdd = allBrands.find((brand) => brand.id === brandId);
        if (brandToAdd) {
          return [...prevDisplay, { brand_data: brandToAdd }];
        }
      }

      return prevDisplay;
    });
  };

  const removeBrandDisplaySelection = (id) => {
    setDisplaySelectedBrands((prevDisplay) =>
      prevDisplay?.filter((brand) => brand?.brand_data?.id !== id)
    );

    setSelectedBrands((prevSelected) =>
      prevSelected.filter((selectedBrand) => selectedBrand !== id)
    );
  };

  const addSelectedRoomtype = (types) => {
    setProcessing(true);
    if (selectedRoomtypes.length < types.length) {
      if (types?.length > 0) {
        const item = types[types.length - 1];
        const newRoomtype = {
          id: item.id,
          title: item.title,
          image: item.image,
          rooms: [],
        };

        const updatedRoomtypes = [...selectedRoomtypes, newRoomtype];
        setSelectedRoomtypes(updatedRoomtypes);

        // Set focus to the newly added room type
        setRoomTypeIndex(updatedRoomtypes.length - 1);
      }
    } else {
      const rtypesIds = new Set(types.map((item) => item.id));
      const temp = [];
      for (const item of selectedRoomtypes) {
        if (rtypesIds.has(item.id)) {
          temp.push(item);
        }
      }
      setSelectedRoomtypes(temp);

      // Adjust the index if the currently selected type was removed
      if (temp.length === 0) {
        setRoomTypeIndex(0);
      } else if (roomtypeIndex >= temp.length) {
        setRoomTypeIndex(temp.length - 1);
      }
    }
    setProcessing(false);
  };

  const fetchRoomTypes = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchRoomTypes();
      if (response.data) {
        setRoomTypes(response?.data);
        const masterBedroom = response.data?.filter(
          (item) => item?.title?.toLowerCase() === "master bedroom"
        )[0];
        if (masterBedroom) {
          addSelectedRoomtype([masterBedroom]);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  /* const fetchPropertyById = async (propertyId) => {
    try {
      const response = await authActions.fetchPropertyById(propertyId);
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }; */

  const fetchArchitectByUser = async () => {
    try {
      const response = await authActions.fetchArchitectByUser(user.id);
      if (response.data) {
        if (response.data?.results[0]) {
          setArchitectInfo(response.data?.results[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBrandByUser = async () => {
    try {
      const response = await authActions.fetchBrandByUser(user?.id);
      if (response.data) {
        if (response.data?.results[0]) {
          setBrandInfo(response.data?.results[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBuildersDataByUser = async () => {
    try {
      const response = await authActions.fetchBuildersDataByUser(user?.id);
      if (response.data) {
        if (response.data?.results[0]) {
          setBuilderInfo(response.data?.results[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoToPreview = () => {
    const emptyRoomsObject = selectedRoomtypes.find(
      (roomType) => roomType.rooms.length === 0
    );

    if (emptyRoomsObject) {
      notify("Please add Room Image", "error");
      return;
    }

    /* if (!selectedBrands.length) {
      notify("Please tag product brands used", "error");
      return;
    } */
    setCurrentStep(3);
  };

  const handleFileButtonClick = (roomId) => {
    if (roomId) {
      document.getElementById(`fileInput-${roomId}`).click();
    }
  };

  const validateFields = (data) => {
    const requiredFields = {
      project_name: isSampleFlat ? "Sample Flat Name" : "Project Name",
      bhk: "Layout",
      project_area: "Flat Area",
      project_theme: isSampleFlat ? "Sample Flat Theme" : "Project Theme",
    };
    const newInvalidFields = {};

    if (!data.isGeneralInspiration) {
      requiredFields["property_name"] = "Property Name";
      requiredFields["pincode"] = "Pincode";
      requiredFields["city"] = "City";
      requiredFields["state"] = "State";
      requiredFields["country"] = "Country";
    }

    Object.keys(requiredFields).forEach((field) => {
      const value = data[field] || "";
      newInvalidFields[field] = !value || value.trim() === "";
    });

    setInvalidFields(newInvalidFields);

    const missingFields = Object.keys(newInvalidFields)
      .filter((field) => newInvalidFields[field])
      .map((field) => requiredFields[field]);

    return missingFields.length === 0 ? null : missingFields;
  };

  const handleProjectDetails = () => {
    const formData = new FormData(projectInfoRef.current);
    let data = { ...projectDetails };

    formData.forEach((value, key) => {
      if (
        key === "project_cost" &&
        value.trim() &&
        !validateCost(value.trim())
      ) {
        return;
      }
      data[key] = value.trim();
    });

    data.isGeneralInspiration = isGeneralInspiration;

    const missingFields = validateFields(data);
    if (missingFields) {
      const errorMessage = `Please fill in: ${missingFields.join(", ")}`;
      notify(errorMessage, "error");
      return;
    }

    setSelectedLayout(data.bhk);
    setProjectDetails(data);
    setInvalidFields({});
    setCurrentStep(2);
  };

  const handleProjectSubmit = async () => {
    setSubmitLoading(true);
    const costUnit = projectDetails?.cost_unit || "Lakhs";
    let projectCost = projectDetails.project_cost;

    const missingFields = validateFields(projectDetails);
    if (missingFields) {
      const errorMessage = `Please fill in: ${missingFields.join(", ")}`;
      notify(errorMessage, "error");
      setSubmitLoading(false);
      return;
    }

    let data = {
      is_new_project: true,
      is_general_inspiration: projectDetails.isGeneralInspiration,
      project_property: customProperty?.id || selectedProperty?.id || "",
      project_name: projectDetails.project_name,
      project_type: projectDetails.project_property?.project_type_data?.id,
      project_description: projectDetails.project_description,
      bhk: projectDetails.bhk,
      block: projectDetails.block,
      flat_area: projectDetails.project_area,
      country: projectDetails.country,
      state: projectDetails.state,
      city: projectDetails.city,
      pincode: projectDetails.pincode,
      project_theme: projectDetails.project_theme,
      address: projectDetails.address,
      project_cost: parseFloat(projectCost),
      project_cost_unit: costUnit,
      is_sample_flat: isSampleFlat,
      property_segment: projectDetails.property_segment, 
    };

    if (projectDetails.project_year) {
      data["project_year"] = projectDetails.project_year;
    }

    if (architectInfo?.id) {
      data = { ...data, architect: architectInfo.id };
    } else if (brandInfo?.id) {
      data = { ...data, brand: brandInfo.id };
    } else if (builderInfo?.id) {
      data = { ...data, builder: builderInfo.id };
    }

    if (data?.architect || data?.brand || data?.builder) {
      const formData = new FormData();
      selectedRoomtypes.forEach((roomType, roomIndex) => {
        roomType.rooms.forEach((room, fileIndex) => {
          formData.append(`fileInput-${roomIndex}-${fileIndex + 1}`, room.file);
        });
      });
      formData.append("project_data", JSON.stringify(data));
      formData.append("room_types", JSON.stringify(selectedRoomtypes));
      formData.append("brand_ids", JSON.stringify(selectedBrands));
      formData.append("user", user.id);
      try {
        const response = await authActions.createProject(formData);
        if (response.status === 201) {
          notify("Project submitted successfully. Approval in progress...", "success");
          navigate("/");
        }
      } catch (error) {
        notify(error.response.data.msg || "An error occurred!", "error", 1);
      }
    } else {
      notify(`Failed to identify this ${user?.user_type ?? "user"}`, "error");
    }
    setSubmitLoading(false);
  };

  useEffect(() => {
    let temp = [];
    if (selectedRoomtypes?.length > 0) {
      selectedRoomtypes.forEach((rt) => {
        if (rt?.rooms.length > 0) {
          rt?.rooms.forEach((r) => {
            temp.push(r);
          });
        }
      });
    }
    setRoomsToDisplay(temp);
  }, [selectedRoomtypes]);

  useEffect(() => {
    if (status) {
      setCurrentStep(2);
    }
  }, [status]);


  /* useEffect(() => {
    if (propertyId && properties?.length > 0) {
      const temp = {
        ...projectDetails,
        property: propertyId,
      };
      setProjectDetails(temp);
      setSelectedProperty(properties.find((item) => item?.id === propertyId));
      setPropertyName(
        properties.find((item) => item?.id === propertyId)?.property_name
      );
    } else {
      setProjectDetails({});
    }
  }, [properties, propertyId]); */

  useEffect(() => {
    // Check if we have a property id from the URL or from the location state
    const propId = propertyId || location?.state?.selectedProperty?.id;
    console.log(location, "location");
    console.log(propId, "propId");
    console.log(propertyId, "propertyId");
    console.log(location?.state?.selectedProperty?.id, "location?.state?.selectedProperty?.id");
    console.log(properties, "proper");
    console.log(properties.find((item) => item?.id === propId));

    if (propId && properties?.length > 0) {
      // Try to find the property in your properties list
      // If not found, fallback to the one from location.state
      const selectedProp =
        properties.find((item) => item?.id === propId) ||
        location?.state?.selectedProperty;
      setBuilderPropertyName(location?.state?.selectedProperty);
      const temp = {
        ...projectDetails,
        property: propId,
        property_name: selectedProp?.property_name,
      };

      setProjectDetails(temp);
      setSelectedProperty(selectedProp);
      setPropertyName(selectedProp?.property_name);
    } else {
      setProjectDetails({});
    }
  }, [properties, propertyId, location]);

  useEffect(() => {
    if (user?.user_type === "architect") {
      fetchArchitectByUser();
      fetchProperties();
    } else if (user?.user_type === "brand") {
      fetchBrandByUser();
      fetchProperties();
    } else if (user?.user_type === "builder") {
      fetchPropertiesByBuilderNoPagination();
      fetchBuildersDataByUser();
    }
  }, [user]);

  useEffect(() => {
    fetchRoomTypes();
  }, []);

  return (
    <>
      <AddBrandsModel
        show={showProductsModal}
        setShow={setShowProductsModal}
        selectedRoomtypes={selectedRoomtypes}
        setSelectedBrands={setSelectedBrands}
        setAllBrands={setAllBrands}
        roomtypeIndex={roomtypeIndex}
        activeRoomIndex={activeRoomIndex}
        setActiveRoomIndex={setActiveRoomIndex}
        toggleBrandSelection={toggleBrandSelection}
        selectedBrands={selectedBrands}
      />
      <Container
        fluid
        className="mt-5 pt-4 px-0 d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "60vh" }}
      >
        <div className="w-100 bg-info d-flex justify-content-center align-items-center">
          <h1 className="h2 text-primary inter-600 py-3">
            Add a new{" "}
            {location.pathname.includes("builder/addproject")
              ? "Sample Flat"
              : "Project"}
          </h1>
        </div>
        {processing && (
          <LoadingOverlay isLoading={processing} setLoading={setProcessing} />
        )}
        <div
          className="d-flex flex-column flex-lg-row my-md-4"
          style={large ? { maxWidth: 1000, width: "100%" } : { width: "100%" }}
        >
          <div
            className="d-flex justify-content-center"
            style={{ minWidth: 250 }}
          >
            <div className="d-flex flex-lg-column mt-2 mt-md-4 mt-lg-3 ml-2 mb-1 px-3 px-lg-0">
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${currentStep === 1
                    ? "bg-black text-white"
                    : currentStep === 2 || currentStep === 3
                      ? "bg-success text-white"
                      : "bg-gray-25"
                    }`}
                >
                  {currentStep === 1 ? "1" : <FaCheck size={12} />}
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${currentStep !== 1 ? "text-success" : ""
                    }`}
                >
                  {location.pathname.includes("builder/addproject")
                    ? "Sample Flat Details"
                    : "Project Details"}
                </div>
              </div>
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${currentStep === 2
                    ? "bg-black text-white"
                    : currentStep === 3
                      ? "bg-success text-white"
                      : "text-gray-300 bg-gray-25 border border-gray-100"
                    }`}
                >
                  {currentStep === 1 || currentStep === 2 ? (
                    "2"
                  ) : (
                    <FaCheck size={12} />
                  )}
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${currentStep !== 1 && currentStep !== 2
                    ? "text-success"
                    : currentStep === 2
                      ? ""
                      : "text-gray-300"
                    }`}
                >
                  {location.pathname.includes("builder/addproject")
                    ? "Sample Flat Images"
                    : "Project Images"}
                </div>
              </div>
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${currentStep === 3
                    ? "bg-black text-white"
                    : "text-gray-300 bg-gray-25 border border-gray-100"
                    }`}
                >
                  3
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${currentStep === 3 ? "text-gray-900" : "text-gray-300"
                    }`}
                >
                  {location.pathname.includes("builder/addproject")
                    ? "Sample Flat Review"
                    : "Project Review"}
                </div>
              </div>
            </div>
          </div>
          <div className="p-3">
            <div className="p-3 border" style={{ borderRadius: 6 }}>
              {currentStep === 1 && (
                <div style={large ? { minWidth: 600 } : {}}>
                  <h4 className="inter-600">
                    {currentStep}.{" "}
                    {location.pathname.includes("builder/addproject")
                      ? "Sample Flat Details"
                      : "Project Details"}
                  </h4>
                  <div className="mt-4">
                    <AddProjectForm
                      projectInfoRef={projectInfoRef}
                      projectDetails={projectDetails}
                      properties={properties}
                      setProjectDetails={setProjectDetails}
                      selectedProperty={selectedProperty}
                      setPropertyName={setPropertyName}
                      builderPropertyName={builderPropertyName}
                      setShowPropertyModal={setShowPropertyModal}
                      setSelectedProperty={setSelectedProperty}
                      setSelectedLayout={setSelectedLayout}
                      propertyName={propertyName}
                      selectedLayout={selectedLayout}
                      invalidFields={invalidFields}
                      isGeneralInspiration={isGeneralInspiration}
                      setIsGeneralInspiration={setIsGeneralInspiration}
                      fromListedPropertyCard={fromListedPropertyCard}
                    />
                    <div className="d-flex">
                      <Button
                        className="d-flex align-items-center text-white body-text2 me-2"
                        style={{ borderRadius: 3 }}
                        onClick={handleProjectDetails}
                      >
                        Continue
                      </Button>
                      <Button
                        className="bg-transparent border-gray-200 text-gray-900 body-text2"
                        style={{ borderRadius: 3 }}
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {currentStep === 2 && (
                <div style={large ? { minWidth: 600 } : {}}>
                  <h4 className="inter-600">
                    {currentStep}. Add{" "}
                    {location.pathname.includes("builder/addproject")
                      ? "Sample Flat "
                      : "Project "}
                    Images
                  </h4>
                  {!loading ? (
                    <AddProjectRoomSelector
                      selectedRoomtypes={selectedRoomtypes}
                      setSelectedRoomtypes={setSelectedRoomtypes}
                      setProcessing={setProcessing}
                      setLoading={setLoading}
                      roomtypeIndex={roomtypeIndex}
                      setRoomTypeIndex={setRoomTypeIndex}
                      roomTypes={roomTypes}
                      addSelectedRoomtype={addSelectedRoomtype}
                      setTemps={setTemps}
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: 240 }}
                    >
                      <Spinner />
                    </div>
                  )}
                  <p
                    className="text-primary cursor-pointer"
                    onClick={() => {
                      setRoomTypeIndex(roomtypeIndex);
                      setShowProductsModal(!showProductsModal);
                    }}
                  >
                    Tag Product Brands Used &gt;
                  </p>
                  <Row>
                    {displaySelectedBrands.map((brand) => (
                      <Col className="mb-1" key={brand.id} xs={12} md={4}>
                        <BrandCards
                          brand={brand}
                          removeBrandDisplaySelection={
                            removeBrandDisplaySelection
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                  <div className="mt-4 d-flex">
                    <Button
                      className="d-flex align-items-center text-white body-text2 me-2"
                      style={{ borderRadius: 3 }}
                      onClick={() => {
                        handleGoToPreview();
                      }}
                    >
                      Go to Preview
                    </Button>
                    <Button
                      className="bg-transparent border-gray-200 text-gray-900 body-text2"
                      style={{ borderRadius: 3 }}
                      onClick={() => setCurrentStep(1)}
                      disabled={submitLoading}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              )}
              {currentStep === 3 && (
                <div style={large ? { width: 600 } : {}}>
                  <AddProjectDetails
                    projectDetails={projectDetails}
                    status={status}
                    setCurrentStep={setCurrentStep}
                    user={user}
                    currentStep={currentStep}
                    roomsToDisplay={roomsToDisplay}
                    temps={temps}
                    displaySelectedBrands={displaySelectedBrands}
                  />
                  <div className="d-flex">
                    <Button
                      className="d-flex align-items-center text-white body-text2 me-2"
                      style={{ borderRadius: 3 }}
                      onClick={handleProjectSubmit}
                      disabled={submitLoading}
                    >
                      {submitLoading ? (
                        <>
                          <Spinner
                            animation="border"
                            size="sm"
                            className="me-2"
                          />{" "}
                          Saving...
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                    <Button
                      className="bg-transparent border-gray-200 text-gray-900 body-text2"
                      style={{ borderRadius: 3 }}
                      onClick={() => setCurrentStep(2)}
                      disabled={submitLoading}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <AddPropertyModal
          show={showPropertyModal}
          setShow={setShowPropertyModal}
          setCustomProperty={setCustomProperty}
          setSelectedProperty={setSelectedProperty}
        />
      </Container>
    </>
  );
}

export default AddProject;
